import {JsonObject, JsonProperty, Any} from 'json2typescript';
import { DateConverter } from '../services/global/date-converter';

@JsonObject('BlueUnit')
export class BlueUnit {
  @JsonProperty('blue_unit_id', String, true)
  blueUnitId: string = undefined;
  @JsonProperty('blue_unit_name', String, true)
  blueUnitName: string = undefined;
  @JsonProperty('blue_unit_code', String, true)
  blueUnitCode: string = undefined;
  @JsonProperty('pennant', String, true)
  pennant: string = undefined;
  @JsonProperty('sconum', String, true)
  sconum: string = undefined;
  @JsonProperty('ccode', String, true)
  cCode: string = undefined;
  @JsonProperty('created_by', String, true)
  createdBy: string = undefined;
  @JsonProperty('create_ts', DateConverter, true)
  dateCreated: Date = undefined;

}
