import { LookupApiService } from './../api-services/lookup-api.service';
import { Injectable } from '@angular/core';
import { JsonConverter } from '../global/json-converter';
import { Observable, empty, timer } from 'rxjs';
import { LookupFilter} from '@models/lookup-filter'
import { PicklistPreferences} from '@models/picklist-preferences.model'
import { map, catchError, shareReplay, switchMap, take } from 'rxjs/operators';

// Only replay last retrieved data
const CACHE_BUFFER_SIZE = 1;
// Interval to refresh the cache on. Configurable through the environment.
//const REFRESH_INTERVAL = environment.cache_refresh_interval;

@Injectable()
export class LookUpService {
    private jsonConvert: JsonConverter;
    //private caches: Object;
    // Interval to refresh the cache on. Configurable through the environment.
    //private REFRESH_INTERVAL;

    constructor(private lookUpApiService: LookupApiService) {
        this.jsonConvert = new JsonConverter();
        //this.caches = {};
        //this.REFRESH_INTERVAL = appInitService.getConfig().lookups_cache_refresh_interval;
    }

    public getLookupByType(type: any, refresh: boolean = false): Observable<any> {
        return this.lookUpApiService.getLookupByType(type.lookupName())
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, type);
                })
            );
    }
    public getFilteredLookups(type: any, ownerId): Observable<any> {
        return this.lookUpApiService.getFilteredLookups(type.lookupName(), ownerId)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, type);
                })
            );
    }    
    
    public saveFilteredLookups(data: any): Observable<any> {
        return this.lookUpApiService.saveFilteredLookups(this.jsonConvert.serializeArray(data, LookupFilter))
            .pipe(
                map((data) => {
                    return 'ok';
                })
            );
    }
    // the above two are OBE if using new tables in FOMNG-662
    
    public getPicklistPreferences(table, ownerId): Observable<any> {
        return this.lookUpApiService.getPicklistPreferences(table, ownerId)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, PicklistPreferences);
                })
            );
    }    

        //return this.caches[type.lookupName()];
    

    // if the caller needs the freshest data pass true as a second parameter
    // public getLookupByType(type: any, refresh: boolean = false): Observable<any> {
    //     if (!this.caches[type.lookupName()] || refresh) {
    //         const timer$ = timer(0, this.REFRESH_INTERVAL);
    //         this.caches[type.lookupName()] = timer$.pipe(
    //             switchMap(_ => {
    //                 return this.lookUpApiService.getLookupByType(type.lookupName()).pipe(take(1))
    //                     .pipe(
    //                         map((data) => {
    //                             return this.jsonConvert.deserializeArray(data, type);
    //                         })
    //                         // ,
    //                         // catchError((err, caught) => {
    //                         //     console.error(err);
    //                         //     return empty();
    //                         // })
    //                     );
    //             }),
    //             shareReplay(CACHE_BUFFER_SIZE)
    //         );
    //     }

    //     return this.caches[type.lookupName()];
    // }

    // receives the name that was edited, created, or deleted and clears the cache of that type.
    // it's kinda loose, but called from edit-modal-base-component
    // public clearCache(name) {
    //     if (this.caches[name]) {
    //         delete this.caches[name]
    //     }
    // }

}
