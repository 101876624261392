import { isNull } from '@angular/compiler/src/output/output_ast';
import { isDefined } from '@angular/compiler/src/util';
import { JsonConverter, JsonCustomConvert } from 'json2typescript';
import * as moment from 'moment';

@JsonConverter
export class DateConverter implements JsonCustomConvert<Date> {
    serialize(date: Date): any {
        if (typeof date === "undefined") {
            throw new Error("DateConverter.serialize:undefined date");
            return null;
          }  
        var nDate = moment(date);
        if (nDate instanceof Date && !isNaN(nDate.valueOf())) {
        // will not execute
           throw new Error("DateConverter.serialize:Invalid date");
           return null;
        }
      return(nDate.toISOString());
    }

    //TODO: This won't handle RFC2822 dates
    deserialize(date: any): Date {
        if (typeof date === "undefined") {
            throw new Error("DateConverter.deserialize:Invalid or undefined date");
            return null;
          }
          if (date instanceof Date && !isNaN(date.valueOf())) {
            // will not execute
            throw new Error("DateConverter.deserialize:Invalid date");
            return null;
          }
        return new Date(date);
    }
}