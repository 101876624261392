import { Component, Input, OnInit, Output, EventEmitter, SimpleChange, SimpleChanges } from '@angular/core';
import { PicklistPreferences } from '@models/picklist-preferences.model';
import { CountryService } from '@services/data-services/country.service';
import { LookUpService } from '@services/data-services/lookup.service';
import { PicklistPrefService } from '@services/data-services/picklist-pref.service';
import { UtilityService } from '@services/utility.service';
import { take } from 'rxjs/operators';
import { LookoupValuesMapService } from '@services/lookup-values-map.service';
import { SortingService } from '@services/sorting.service';

@Component({
  selector: 'app-lookup-select',
  templateUrl: './lookup-select.component.html',
  styleUrls: ['./lookup-select.component.css']
})
export class LookupSelectComponent implements OnInit {

  lookupMap = this.lookupValuesMapService.getLookupMap();
  
  lookups: any[];
  @Input() typeName: string
  @Input() flagProperty: string
  @Input() keyProperty: string

  @Input() itemPK: string
  @Input() itemId: string
  @Input() disabled: boolean
  @Input() trigger: any

  @Input() ownerId: string = null

  // allow parent to send in a class to be applied
  // if none applied it will default to this, which cam from the Unit Update screen (90%) width
  // other screens may not want this
  @Input() selectClass: string = 'default-width'
  fleetId: string = null
  opAreaId: string = null

  picklistPreferences: PicklistPreferences[] = []

  @Output() selectedValueChanged = new EventEmitter<any>();
  @Output() isValid = new EventEmitter<boolean>();

  selectedItem: any

  secondFilter: any = null

  constructor(public lookupService: LookUpService,
    private picklistPrefService: PicklistPrefService,
    public utilities: UtilityService,
    private countryService: CountryService,
    private lookupValuesMapService: LookoupValuesMapService,
    private sorter: SortingService) {
  }

  ngOnInit() {
    let clearObject = {}
    clearObject[this.keyProperty] = null

    // if you want a custom, filtered list, pass the owner id
    // if no ownerId then a NIL guid is used (internally in the API)
    Promise.resolve().then(() => {
      const filter = this.ownerId ? {owner_id: this.ownerId} : {owner_id : null}
      this.picklistPrefService.getAll(filter).pipe(take(1)).subscribe( preferences => {
        this.picklistPreferences = preferences;
        this.lookupService.getLookupByType(this.utilities.getTypeFromName(this.typeName), true).pipe(take(1)).subscribe(async entities => {
          // normalize the type name given in the primary key of the app-lookup-select to the description in the picklist type table
          const type = this.lookupMap.find(mapping => mapping.type === this.utilities.getTypeFromName(this.typeName));
          // sort list based on picklist preferences
          entities = this.sorter.sortList(entities, this.picklistPreferences, type.defaultSort, type.lookupId, false)

          this.lookups = [clearObject].concat(entities)
          setTimeout(() => {
            // ok, we've loaded the lookups array. now, why are we here and which
            // value needs to be set?
              let found = this.lookups.find(x => x[this.itemPK] == this.itemId)
              if (found) {
                this.selectedItem = found
              }
          })
        })
        //console.log(preferences)
      })
    })
  }

 // Whenever the data in the parent changes, this method gets triggered. You 
 // can act on the changes here. You will have both the previous value and the 
 // current value here.

 // so we want to see the parent changing the selected value. 
  // this is primarily important when the selection is loaded externally
  ngOnChanges(changes: SimpleChanges){
    let clearObject = {}
    clearObject[this.keyProperty] = null
    // Extract changes to the input property by its name
    // this would be simpler if unit update did not change many instances of this component
    let trigger: SimpleChange = changes['trigger']; 
    if (trigger && trigger.currentValue) {
        this.ownerId = trigger.currentValue.ownerId
        Promise.resolve().then(() => {
          this.loadLookups(this.ownerId, trigger)
        })
    }
 }

 loadLookups(ownerId, trigger) {
    let clearObject = {}
    clearObject[this.keyProperty] = null
    const filter = ownerId ? {owner_id: ownerId} : {owner_id: null}
    this.picklistPrefService.getAll(filter).pipe(take(1)).subscribe( preferences => {
      this.picklistPreferences = preferences;
      this.lookupService.getLookupByType(this.utilities.getTypeFromName(this.typeName), true).pipe(take(1)).subscribe(async entities => {
        // normalize the type name given in the primary key of the app-lookup-select to the description in the picklist type table
        const type = this.lookupMap.find(mapping => mapping.type === this.utilities.getTypeFromName(this.typeName));
        // sort list based on picklist preferences
        entities = this.sorter.sortList(entities, this.picklistPreferences, type.defaultSort, type.lookupId, false)
        
        // if the unit update set a country filter, move the items to the top
        if (this.secondFilter) {
          switch (this.secondFilter.name) {
            case 'OpArea':
              if (this.secondFilter.filter.name) {
                const top = entities.filter(x => x.remarks == this.secondFilter.filter.name)
                const bottom = entities.filter(x => x.remarks != this.secondFilter.filter.name)
                entities = top.concat(bottom)
              }
              break;
            case 'Fleet':
              if (this.secondFilter.filter.name) {
                const top = entities.filter(x => x.remarks == this.secondFilter.filter.name)
                const bottom = entities.filter(x => x.remarks != this.secondFilter.filter.name)
                entities = top.concat(bottom)
              }
              break;
            }
        }
        this.lookups = [clearObject].concat(entities)
        setTimeout(() => {
          // ok, we've loaded the lookups array. now, why are we here and which
          // value needs to be set?
          for (let key in trigger.currentValue) {
            let found = this.lookups.find(x => x[this.itemPK] == trigger.currentValue[key])
            if (found) {
              this.selectedItem = found
            }
          }
        })
      })
    })

}

  // the parent component can opt to do whatever here
  selectionChanged(selection: any) {
    if (selection) {
      this.selectedValueChanged.emit(selection.value)
    }
  }
  // a little hack to get the OpArea combined string into the drop down
  getDisplayValue(item) {
    if (item && !this.keyProperty && item.areaRemarks) {
      //console.log(item)
      return item.areaRemarks()
    }
  }

  getFlag(country){
    return this.countryService.getFlagUrl(country)
  }
}
