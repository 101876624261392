<div class="narrow-card">
<mat-card class="form-card">
  <mat-card-title-group>
    <mat-card-title>{{initService.getConfig().titlePrefix}} {{feed.title}}</mat-card-title>
    <mat-card-subtitle>{{feed.description}}</mat-card-subtitle>
    <a routerLink="/products/current-nob" title="Current NOB">
    <img mat-card-sm-image height="80" width="80" src="assets/images/FDT-M.png" title="Current NOB"/>
    </a>
  </mat-card-title-group>
  <mat-card-content>
    <p class="refresh"><a href="javascript: void(0)"><span (click)="userRefresh()">refresh </span></a>(auto refresh every {{feed.ttl}} minutes). location updates
      within the past 25 hours.
    </p>
    <div *ngFor="let item of feed.item">
      <hr/>
      <h3> {{item.title}} </h3>
      <!-- <p class="date"><i>entered {{item.pubDate | date:'ddHHmm\'Z\' LLL yyyy'}}</i></p> -->
      <p class="date"><i>entered {{item.pubDate | zulu1Pipe}}</i></p>
      <p class="description"> {{item.description}} </p>
      <p><a href={{item.link}} target="_blank">Underway KML</a></p>
    </div>
  </mat-card-content>
</mat-card>
</div>
