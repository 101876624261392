import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthenticationService } from '@services/data-services/authentication.service';
import { LoggingService } from './services/logging.service';
import { ErrorService } from './services/error.service';
import { UserNotificationService } from './services/notification.service';
import { Router } from '@angular/router';

@Injectable()
export class ClientErrorHandler implements ErrorHandler {

  constructor(private injector: Injector, private router: Router) {}

  handleError(error) {
    const logger = this.injector.get(LoggingService);
    const errorService = this.injector.get(ErrorService);
    const notifier = this.injector.get(UserNotificationService);
    const authService = this.injector.get(AuthenticationService);

    let serverError;
    let message = '';
    let stackString;
    let stackObject;

    if (error instanceof HttpErrorResponse || (error.rejection && error.rejection instanceof HttpErrorResponse)) {
          // Server Error
          const err = error.rejection || error;
          // get the basic message from the server
          serverError = errorService.getServerMessage(err);

          console.log(`handling server error: ${serverError}`);

          if (err.status === 401) {
            // message = '401 Unauthorized. Invalid Credentials or Session has expired.'
            message = err.error.message;
          } else if (err.status === 403) {
            message = "Sorry, we've encountered an error; please contact your administrator";
            authService.sessionTearDown();
            this.router.navigateByUrl('/home')
          } else {
            stackString = errorService.getServerStack(err);
            if (stackString) {
              stackObject = JSON.parse(stackString);
              if (stackObject && stackObject.Message) {
                message += `Message: ${stackObject.Message} `;
              }
              if (stackObject && stackObject.ErrorCode) {
                message += `ErrorCode: ${stackObject.ErrorCode} `;
              }
              if (stackObject && stackObject.Details) {
                message += `Details: ${stackObject.Details} `;
              }
              if (stackObject && stackObject.StackTrace) {
                console.log(`Server Error Stack Trace: ${stackObject.StackTrace}`);
              }
            }
            if (message.length === 0) {
              message = serverError;
            }
          }
          // show the user
          notifier.showError(message);
      } else {
          // Client Error
          message = errorService.getClientMessage(error);
          console.log(`globally handling: ${message}`);
          stackString = errorService.getClientStack(error);
          notifier.showError(message);
      }

    logger.logError(message, stackString);
  }
}
