import { Component, OnInit } from '@angular/core';
import { PicklistTypeService } from '@services/data-services/picklist-type.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { EditModalBaseComponent } from '@fom-module/base-classes/edit-modal-base.component';
import { LookUpService } from '@services/data-services/lookup.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-picklist-type-modal',
  templateUrl: './edit-picklist-type-modal.component.html',
  styleUrls: ['./edit-picklist-type-modal.component.css']
})
export class EditPicklistTypeModalComponent extends EditModalBaseComponent implements  OnInit {

  editForm: FormGroup;

  constructor(public picklistTypeService: PicklistTypeService,
    public lookUpService: LookUpService,
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<EditPicklistTypeModalComponent>,
    public messageBusService: MessageBusService) {
      super(picklistTypeService, dialogRef);
    }

  ngOnInit() {
    this.headerString = this.isNew ? 'Add Picklist Type' : 'Edit Picklist Type'
    this.editForm = this.formBuilder.group({
      description: [this.entity.description],
      tableName: [this.entity.tableName],
      primaryKeyName: [this.entity.primaryKeyName]
    });
  }
}
