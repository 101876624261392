import { JsonObject, JsonProperty, Any } from 'json2typescript';
import { Country } from '@models/country.model';
import { Command } from '@models/command.model';

@JsonObject('Area')
export class Area {
  @JsonProperty('op_area_id', String, true)
  opAreaId: string = undefined;
  @JsonProperty('op_area', String, true)
  opArea: string = undefined;
  @JsonProperty('report_group', String, true)
  reportGroup: string = undefined;
  @JsonProperty('remarks', String, true)
  remarks: string = undefined;
  @JsonProperty('country', Country, true)
  country: Country = undefined;
  @JsonProperty('command_id', String, true)
  commandId: string = undefined;
  @JsonProperty('command', Command, true)
  command: Command = undefined;
  @JsonProperty('created_by', String, true)
  createdBy: string = undefined;
  @JsonProperty('lookup_filter_id', String, true)
  lookupFilterId: string = undefined;
  @JsonProperty('sort_sequence', String, true)
  sortSequence: number = undefined;

  static lookupName(): string {
    return 'areas';
  }

  static primaryKey(): string {
    return 'opAreaId'
  }
  groupProperty(): string {
    return this.areaWithRemarks()
  }
  areaRemarks(): string {
    return `${this.remarks} - ${this.opArea}`;
  }
  areaWithRemarks(): string {
    return `${this.opArea} (${this.remarks})`;
  }
}
