import { ShipClass } from '@models/ship-class.model';
import { ShipClassApiService } from '@services/api-services/ship-class-api.service';
import { Injectable } from '@angular/core';
import { JsonConverter } from '@services/global/json-converter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ShipClassService {
    private jsonConvert: JsonConverter;

    constructor(private shipClassApiService: ShipClassApiService) {
        this.jsonConvert = new JsonConverter();
    }

    public getShipClass(params: any): Observable<ShipClass[]> {
        return this.shipClassApiService.getShipClass(params)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, ShipClass);
                })
            );
    }

    public getAll(): Observable<ShipClass[]> {
        return this.shipClassApiService.getAllShipClasses()
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, ShipClass);
                })
            );
    }

    public getShipClassByShipClassId(shipClassId: string): Observable<ShipClass> {
        return this.shipClassApiService.getShipClassByShipClassId(shipClassId)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, ShipClass);
                })
            );
    }

    public update(shipClass: ShipClass): Observable<ShipClass> {
        return this.shipClassApiService.updateShipClass(this.jsonConvert.serializeObject(shipClass))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, ShipClass);
                })
            );
    }

    public create(shipClass: ShipClass): Observable<ShipClass> {
        return this.shipClassApiService.createShipClass(this.jsonConvert.serializeObject(shipClass))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, ShipClass);
                })
            );
    }

    public delete(shipClass: ShipClass): Observable<ShipClass> {
        return this.shipClassApiService.deleteShipClass(this.jsonConvert.serializeObject(shipClass))
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }
}
