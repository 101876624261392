import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from './api.config';

@Injectable()
export class VesselTypeApiService {
    SERVER_URL;

    constructor(
        private http: HttpClient, apiConfig: APIConfig) {
        this.SERVER_URL = apiConfig.TARGET_URL;
    }

    public getVesselType(params: any): Observable<any> {
        return this.http.post(
            `${this.SERVER_URL}/vessel-types/vessel-type`, params);
    }

    public getAllVesselTypes(): Observable<any> {
        return this.http.get(
            `${this.SERVER_URL}/vessel-types`);
    }

    public getVesselTypeByVesselTypeId(vesselTypeId: string): Observable<any> {
        return this.http.get(
            `${this.SERVER_URL}/vessel-types/${vesselTypeId}`);
    }

    public updateVesselType(vesselType: any): Observable<any> {
        return this.http.put(
            `${this.SERVER_URL}/vessel-types/${vesselType.vessel_type_id}`, vesselType);
    }

    public createVesselType(vesselType: any): Observable<any> {
        return this.http.post(
            `${this.SERVER_URL}/vessel-types`, vesselType);
    }

    public deleteVesselType(vesselType: any): Observable<any> {
        return this.http.delete(
            `${this.SERVER_URL}/vessel-types/${vesselType.vessel_type_id}`);
    }
}
