import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from './api.config';
import {of} from  'rxjs' 
import { BlueUnit } from '@models/blue.model';

@Injectable()
export class BlueLocationApiService {

  SERVER_URL;

  constructor(
    private http: HttpClient, apiConfig: APIConfig) {
    this.SERVER_URL = apiConfig.TARGET_URL;
  }

  public getAll(filters = {}): Observable<any> {
    let httpParams = new HttpParams();

    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        httpParams = httpParams.set(key, filters[key]);
      }
    }
    return this.http.get(
      `${this.SERVER_URL}/blue/getBlueLocations`, {params: httpParams});
  }

  public getCurrentLocations(filters = {}): Observable<any> {
    let httpParams = new HttpParams();

    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        httpParams = httpParams.set(key, filters[key]);
      }
    }
    return this.http.get(
      `${this.SERVER_URL}/blue/getCurrentLocations`, {params: httpParams});
  }

  public getLocationsPage(filters = {}): Observable<any> {
    return this.http.put(
      `${this.SERVER_URL}/blue/getLocationsPage`, filters);
  }

}
