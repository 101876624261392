
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { Component, ViewChild, OnInit } from '@angular/core';
import { ClassMarkingCategory } from '@models/class-marking-category.model';
import { ClassMarkingCategoryService } from '@services/data-services/class-marking-category.service';
import { ClassMarkingRelationship } from '@models/class-marking-relationship.model';
import { ClassMarkingRelationshipService } from '@services/data-services/class-marking-relns.service';
import { Router } from '@angular/router';
import { GridBaseComponent } from '@fom-module/base-classes/grid-base.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { EditClassMarkRelationshipModalComponent } from '@fom-module/edit-modals/edit-classification-marking-relationship-modal/edit-class-mark-relationship-modal.component';
import { take } from 'rxjs/operators';
import { MatSort, Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { AppInitService } from '../../../config/init.service';
import * as _ from 'lodash';


@Component({
  selector: 'classification-marking-relationship',
  templateUrl: './classification-marking-relationship.component.html',
  styleUrls: ['./classification-marking-relationship.component.css', '../base-classes/grid-base.component.css'],
  // providers: [TdFileService]
})
export class ClassificationMarkingRelationship extends GridBaseComponent implements OnInit {

  paginator: MatPaginator;
  sort: MatSort;
  data: any;

  BOTTOM_NAV_BAR: any;

  categories: ClassMarkingCategory[] = [];
  selectedCategory: ClassMarkingCategory = null;
  entities: any[] = [];
 
  displayedColumns = [/*'edit',*/ 'markingCategory', 'primaryMarking'/*, 'relatedMarking', 'delete'*/];
  constructor(
    public classMarkingCategoryService: ClassMarkingCategoryService,
    public classMarkingRelationshipService: ClassMarkingRelationshipService,
    private dialog: MatDialog,
    public messageBusService: MessageBusService,
    public route: Router,
    public initService: AppInitService) {
    super(messageBusService, classMarkingRelationshipService, route);
    this.data = {};
    this.data.isAllSelected = false;
    this.data.isAllCollapsed = false;

    //List object having hierarchy of parents and its children
    this.data.ParentChildchecklist = []
      
  }


  ngOnInit() {

    this.listenToMessageBus();
    this.classMarkingCategoryService.getAll().pipe(take(1)).subscribe(entities => {
      this.categories = entities;
    });
    this.loadEntityData();
  }

  onCategoryChange(event) {
    this.selectedCategory = event.value || null;
    this.loadEntityData();
  }


  loadEntityData() {
    this.classMarkingRelationshipService.getAllClassMarkingRelnByCategory(this?.selectedCategory?.classMarkingCategoryId || undefined).pipe(take(1)).subscribe(entities => {
      this.entities = entities;
      if (entities.length === 0) {
        throw 'No Classification Marking Relationships found for selected marking category.'
      }
      this.convertEntityData2Tree();
      this.dataSource = new MatTableDataSource<object>(this.entities);
      this.dataSource.sortingDataAccessor = this.dataAccessor;
      this.dataSource.paginator = this.paginator;
     
    });
  }
  //return name of primary or related marking name of Object and id and name {objectName: "objectName", idName: "idName", nameName: "nameName"}
  getObjectPrimaryNameNKeys(element: any) {
     
      if (element?.primaryMarkClassification) {
        return ({objectName: "primaryMarkClassification", idName: "classification_id", nameName: "classification_name"})
      }
      if (element?.primaryMarkSci) {
        return ({objectName: "primaryMarkSci", idName: "sci_control_id", nameName: "control_mark"})
      }
      if (element?.primaryMarkFgi) {
        return ({objectName: "primaryMarkFgi", idName: "fgi_id", nameName: "fgi_name"})
      }
      if (element?.primaryMarkControl) {
        return ({objectName: "primaryMarkControl", idName: "control_id", nameName: "control_name"})
      }
      if (element?.primaryMarkReleasability) {
        return ({objectName: "primaryMarkReleasability", idName: "idName", nameName: "releasability_name"})
      }
  }

  //return name of primary or related marking name of Object and id and name {objectName: "objectName", idName: "idName", nameName: "nameName"}
  getObjectRelatedNameNKeys(element: any) {
     
    if (element?.relatedMarkClassification) {
      return ({objectName: "relatedMarkClassification", idName: "classification_id", nameName: "classification_name"})
    }
    if (element?.relatedMarkSci) {
      return ({objectName: "relatedMarkSci", idName: "sci_control_id", nameName: "control_mark"})
    }
    if (element?.relatedMarkFgi) {
      return ({objectName: "relatedMarkFgi", idName: "fgi_id", nameName: "fgi_name"})
    }
    if (element?.relatedMarkControl) {
      return ({objectName: "relatedMarkControl", idName: "control_id", nameName: "control_name"})
    }
    if (element?.relatedMarkReleasability) {
      return ({objectName: "relatedMarkReleasability", idName: "idName", nameName: "releasability_name"})
    }
}

getRelatedItemChildVal(element: any)
{
  let metaName = this.getObjectRelatedNameNKeys(element)
  let theVal = element[metaName.objectName][metaName.nameName]
  return theVal
}

getFullPrimaryName(element: any) {
  let metaName = this.getObjectPrimaryNameNKeys(element)
   let result = metaName.objectName + "." + metaName.nameName
   return result

}

  convertEntityData2Tree() {
    //transpose the result set into a parent child tree
    //we want to shuffle the results around into parent child-child list - 3 levels: category->primary->related
    //we need to do a few nestings to make this work
    try {
      const group = (data) => {
        return _(data)
          .groupBy('classMarkingCategory.classMarkingName')
          .map((categoryItems, classMarkingName) => ({
              classMarkingName,
              categoryDetail: _(categoryItems)
                .groupBy(this.getFullPrimaryName(categoryItems[0]))
                .map((primaryItems, primaryMarkingId) => ({
                    primaryMarkingId,
                    primaryDetail: _(primaryItems)
                        .map(item => _.omit(item, ['classMarkingCategoryId', `this.getFullPrimaryName(categoryItems[0]`]))
                        .value()
                }))
                .value()
          }))
          .value();
      }
      const result = group(this.entities)
         
      this.data.ParentChildchecklist = result;
      this.entities = result;
    } catch (err) {
      throw (err)
    }
  }
 
  handleDelete(relationship: any) {
    super.handleDelete(relationship, 'Delete Marking Relationship', `Select "Delete" to delete the ${relationship.primaryDetail[0].classMarkingCategory.classMarkingName} map for ${relationship.primaryMarkingId}`, 'Delete')
  }

  edit(relationship: any) {
    const dialogRef = this.dialog.open(EditClassMarkRelationshipModalComponent, {
      width: '820px'
    });
    dialogRef.componentInstance.isNew = false;
    dialogRef.componentInstance.entity = relationship;
   
    dialogRef.componentInstance.selectedCategory = relationship.primaryDetail[0].classMarkingCategory;

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      this.loadEntityData()
    });

  }
  add() {
    const dialogRef = this.dialog.open(EditClassMarkRelationshipModalComponent, {
      width: '820px'
    });
    dialogRef.componentInstance.isNew = true;
    dialogRef.componentInstance.entity = new ClassMarkingRelationship();
    dialogRef.componentInstance.selectedCategory = this.selectedCategory || new ClassMarkingCategory();

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      this.loadEntityData()
    })
  }
  sortData(sort: Sort) {
    let data
    if (!sort.active || sort.direction === '') {
      data = this.entities
    }
    else {
      data = this.entities.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        return this.compare(a, b, isAsc, sort.active);
      })
    }
    this.dataSource.data = data;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = this.dataAccessor;
  }
  compare(a, b, isAsc, active) {
    return (this.dataAccessor(a, active) < this.dataAccessor(b, active) ? -1 : 1) * (isAsc ? 1 : -1);
  }


  //Click event on master select
  selectUnselectAll(obj) {
    obj.isAllSelected = !obj.isAllSelected;
    for (var i = 0; i < obj.ParentChildchecklist.length; i++) {
      obj.ParentChildchecklist[i].isSelected = obj.isAllSelected;
      for (var j = 0; j < obj.ParentChildchecklist[i].childList.length; j++) {
        obj.ParentChildchecklist[i].childList[j].isSelected = obj.isAllSelected;
      }
    }
  }

  //Expand/Collapse event on each parent
  expandCollapse(obj) {
    obj.isClosed = !obj.isClosed;
  }

  //Master expand/ collapse event
  expandCollapseAll(obj) {
    for (var i = 0; i < obj.ParentChildchecklist.length; i++) {
      obj.ParentChildchecklist[i].isClosed = !obj.isAllCollapsed;
    }
    obj.isAllCollapsed = !obj.isAllCollapsed;
  }

}
