import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from '@services/api-services/api.config';

@Injectable()
export class DataTagApiService {
  SERVER_URL;

  constructor(
    private http: HttpClient, apiConfig: APIConfig) {
    this.SERVER_URL = apiConfig.TARGET_URL;
  }

  public getAllDataTags(): Observable<any> {
    return this.http.get(
      `${this.SERVER_URL}/datatags`);
  }

  public getDataTagByDataTagId(dataTagId: string): Observable<any> {
    return this.http.get(
      `${this.SERVER_URL}/datatags/${dataTagId}`);
  }


  public updateDataTag(dataTag: any): Observable<any> {
    return this.http.put(
      `${this.SERVER_URL}/datatags/${dataTag.data_tag_id}`, dataTag);
  }

  public createDataTag(dataTag: any): Observable<any> {
    return this.http.post(
      `${this.SERVER_URL}/datatags`, dataTag);
  }

  public deleteDataTag(dataTag: any): Observable<any> {
    return this.http.delete(
      `${this.SERVER_URL}/datatags/${dataTag.data_tag_id}`);
  }

 /* public uploadFile(formData: any) {
    return this.http.post(
      `${this.SERVER_URL}/slides`, formData).pipe(take(1)).subscribe(data => {
        console.log('File Uploaded Successfully');
      });
  }*/
}
