import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from '@services/api-services/api.config';

@Injectable()
export class SlideApiService {
  SERVER_URL;

  constructor(
    private http: HttpClient, apiConfig: APIConfig) {
    this.SERVER_URL = apiConfig.TARGET_URL;
  }

  public getAllSlides(): Observable<any> {
    return this.http.get(
      `${this.SERVER_URL}/slides`);
  }

  public getSlideBySlideId(slideId: string): Observable<any> {
    return this.http.get(
      `${this.SERVER_URL}/slides/${slideId}`);
  }

  // "slide" has been changed to a FormData object to enable file upload
  public updateSlide(slideId: string, slide: any): Observable<any> {
    return this.http.put(
      `${this.SERVER_URL}/slides/${slideId}`, slide);
  }

  // "slide" is really a FormData object to enable file upload
  public createSlide(slide: any): Observable<any> {
    return this.http.post(
      `${this.SERVER_URL}/slides`, slide);
  }

  public deleteSlide(slide: any): Observable<any> {
    return this.http.delete(
      `${this.SERVER_URL}/slides/${slide.slide_id}`);
  }

  public getImage(slideId: string): Observable<any> {
    return this.http.get(
      `${this.SERVER_URL}/slides/image/${slideId}`,
      {responseType: 'blob'});
  } 
 /* public uploadFile(formData: any) {
    return this.http.post(
      `${this.SERVER_URL}/slides`, formData).pipe(take(1)).subscribe(data => {
        console.log('File Uploaded Successfully');
      });
  }*/
}
