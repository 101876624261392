import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from './api.config';
import { Elnot } from '@models/elnot.model';

@Injectable()
export class ElnotApiService {
  SERVER_URL;
  RESOURCE_URI = 'elnots';


  constructor(
    private http: HttpClient, apiConfig: APIConfig) {
    this.SERVER_URL = apiConfig.TARGET_URL;
  }

  public getAllElnots(): Observable<any> {
    return this.http.get(
      `${this.SERVER_URL}/${this.RESOURCE_URI}`);
  }

  public getElnotByElnotId(id: string): Observable<any> {
    return this.http.get(
      `${this.SERVER_URL}/${this.RESOURCE_URI}/${id}`);
  }

  public updateElnot(elnot: Elnot): Observable<any> {
    return this.http.put(
      `${this.SERVER_URL}/${this.RESOURCE_URI}/${elnot.el_not_id}`, elnot);
  }

  public createElnot(elnot: any): Observable<any> {
    return this.http.post(
      `${this.SERVER_URL}/${this.RESOURCE_URI}`, elnot);
  }

  public deleteElnot(elnot: Elnot): Observable<any> {
    return this.http.delete(
      `${this.SERVER_URL}/${this.RESOURCE_URI}/${elnot.el_not_id}`);
  }
}
