import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hasCommand',
  pure: false
})
export class HasCommandPipe implements PipeTransform {

  transform(value: any, input: any): any {
    // let's allow the commandId to come from a unit via port
    // or some other object
    let commandId = input && input.port ? input.port.commandId :
      input && input.commandId ? input.commandId : null
    if (!value || !commandId) {
      return
    }
    return value.filter(function(element) {
      return element.commandId == commandId
    })
  }

}
