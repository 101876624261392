import { UnitOtherName } from '@models/unit-other-name.model'
import { Injectable } from '@angular/core';
import { JsonConverter } from '../global/json-converter';
import { Observable, empty, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { UnitOtherNameApiService } from '@services/api-services/unit-other-name-api.service';


@Injectable()
export class UnitOtherNameService {
    private jsonConvert: JsonConverter;

    constructor(private unitOtherNameApiService: UnitOtherNameApiService) {
        this.jsonConvert = new JsonConverter();
    }

    // Add withCount param to handle paged requests without breaking old requests (such as in unit update)
    public getAll(): Observable<any> {
        return this.unitOtherNameApiService.getAllOtherNames()
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data , UnitOtherName);
                })
            );
    }

    public getUnitOtherNames(unitId: string): Observable<UnitOtherName[]> {
        return this.unitOtherNameApiService.getUnitOtherNames(unitId)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, UnitOtherName);
                })
            );
    }

    // a very specific search
    public findOtherNames(value, filters = {}, limit = 20, offset = 0): Observable<UnitOtherName[]> {
        if (!value) {
            return of([]);
        }

        // copy paste may have blanks?
        if (typeof value === 'string' || value instanceof String) {
            value = value.toString().trim();
        } else {
            return of([]);
        }

        return this.unitOtherNameApiService.findOtherNames(value, filters, limit, offset)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, UnitOtherName);
                })
            );
    }

    public getOtherNameById(otherNameId): Observable<UnitOtherName> {
        return this.unitOtherNameApiService.getOtherNameById(otherNameId)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, UnitOtherName);
                })
            );
    }

    public update(otherName: UnitOtherName): Observable<UnitOtherName> {
        return this.unitOtherNameApiService.updateOtherName(this.jsonConvert.serializeObject(otherName))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, UnitOtherName);
                })
            );
    }

    public create(otherName: UnitOtherName): Observable<UnitOtherName> {
        return this.unitOtherNameApiService.createOtherName(this.jsonConvert.serializeObject(otherName))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, UnitOtherName);
                })
            );
    }

    public updateParserEntries(): Observable<UnitOtherName> {
        return this.unitOtherNameApiService.updateParserEntries()
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, UnitOtherName);
                })
            );
    }

    public delete(otherName: UnitOtherName): Observable<UnitOtherName> {
        return this.unitOtherNameApiService.deleteOtherName(this.jsonConvert.serializeObject(otherName))
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }
}
