import { Component, OnInit } from '@angular/core';
import { User } from '@models/user.model';
import { Command } from '@models/command.model';
import { CommandHierarchy } from '@models/command-hierarchy.model';
import { LookUpService } from '@services/data-services/lookup.service';
import { take } from 'rxjs/operators';
import { GridBaseComponent } from '@fom-module/base-classes/grid-base.component';
import { Router } from '@angular/router';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { CdkDragDrop, copyArrayItem, moveItemInArray, CdkDrag, CdkDragEnd, CdkDragMove } from '@angular/cdk/drag-drop';
import * as cloneDeep from 'lodash/cloneDeep';
import { CommandService } from '@services/data-services/command.service';
import { CommandHierarchyService } from '@services/data-services/command-hierarchy.service';
import { CurrentUserService } from '@services/current-user-service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppInitService } from '../../../config/init.service';


@Component({
  selector: 'app-command-graph',
  templateUrl: './command-graph.component.html',
  styleUrls: ['./command-graph.component.css', '../base-classes/grid-base.component.css']
})

export class CommandGraphComponent extends GridBaseComponent implements OnInit {

  // targetNode: any
  user: User;
  commands: [];

  // unified commands
  unified = [];
  versionNum;
  // subordinate commands
  // subordinates = []

  // runningList = []

  isDirty = false;

  constructor(private lookupService: LookUpService,
    public messageBusService: MessageBusService,
    private commandService: CommandService,
    private commandHierarchyService: CommandHierarchyService,
    private currentUserService: CurrentUserService,
    private snackBar: MatSnackBar,
    public route: Router,
    public initService: AppInitService) {
    super(messageBusService, null, route);
  }

  ngOnInit() {
    this.user = this.currentUserService.getCurrentUser();
    this.loadData();
  }

  loadData() {
    // get all of the Commands and place into left hand column for selection
    this.lookupService.getLookupByType(Command, true).pipe(take(1)).subscribe(entities => {
      entities.sort((a,b) => {
        return a.name < b.name ? -1: 1
      })
      this.commands = entities
      // ok commands are loaded, now are there any stored AOR relationships
      this.commandHierarchyService.getAll({ sort: 'version_num', limit: 1}).pipe(take(1)).subscribe(results => {
        this.unified = this.formatCommandGraphJSON(results)
      })

    })
  }
  formatCommandGraphJSON(json) {
    this.isDirty = false
    this.versionNum = null
    if (json && Array.isArray(json) && json.length) {
      this.versionNum = json[0].versionNum
      return JSON.parse(json[0].treeObject)
    }
    return []
  }

  loadLogo(command){
    // in case the Graph and Command list are out of sync, Use command as source of truth
    const refCommand = this.commands.find( comm => comm["commandId"] === command["commandId"] )
    if(refCommand){
      return this.commandService.getLogoUrl(refCommand)
    }else{
      return "assets/images/Blank.png"
    }
  }

  save() {
    if (this.isDirty) {
      const ch = new CommandHierarchy();
      ch.treeObject = JSON.stringify(this.unified);
      this.commandHierarchyService.create(ch).pipe(take(1)).subscribe(results => {
        this.formatCommandGraphJSON([results]);
        this.snackBar.open('Command Graph updated.', 'OK', { duration: 3000 });
      });
    }
  }

  add() {
    if (this.user.isSystemAdmin()) {
      throw 'Drag Command to this space to add it';
    }
  }

  // drag drop
  dropUnified(event: CdkDragDrop<string[]>) {
    this.isDirty = true
    const found = this.unified.find(x => {return x.commandId == event.item.data.commandId})
    if (found) {
      return
    }
    let unify = cloneDeep(event.item.data)
    unify.children = []
    this.unified.push(unify)
  }

  dropSubordinate(event: CdkDragDrop<string[]>, unify) {
    this.isDirty = true
    const found = unify.children.find(x => {return x.commandId == event.item.data.commandId})
    if (found) {
      return
    }
    let subordinate = cloneDeep(event.item.data)
    subordinate.parentCommandId = unify.commandId
    subordinate.children = []
    unify.children.push(subordinate)
  }

  remove(command, list) {
    this.isDirty = true
    const index = list.indexOf(command)
    if (index != -1) {
      list.splice(index,1)
    }
  }

  dragPreview(command) {
    return command.name
  }

  // Validates whether user can edit the command graph.
  canEdit(): boolean {
    return this.user.isSystemAdmin();
  }
}
