<mat-card class="form-card">
  <div class="grid-container">
    <div fxLayout fxLayoutAlign="center center" class="simple-grid-title">
      <span class="grid-title">
        {{initService.getConfig().titlePrefix}} FDT-M Parser Queue ({{ itemCount | i18nPlural:
        pluralMapping }})
      </span>
    </div>

    <div fxLayout fxLayoutAlign="center center">
      <button mat-mini-fab color="primary" (click)="loadParserQueueEntries(true)"
        matTooltip="Refresh Parser Queue Entries">
        <mat-icon>refresh</mat-icon>
      </button>
      <mat-form-field *ngIf="jfmccs.length" class="groupSelect">
        <mat-select placeholder="JFMCC View" (selectionChange)='changeJfmccView($event)' [(value)]="selectedJfmcc">
          <mat-option [value]="'All'">All</mat-option>
          <mat-option *ngFor="let option of jfmccs" [value]="option.id">
            {{option.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="groupSelect">
        <mat-select [(ngModel)]="grouping" placeholder="Group By" (selectionChange)='changeGrouping($event, grouping)'>
          <mat-option *ngFor="let option of groupingList" [value]="option">
            {{option.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
     
      <mat-slide-toggle [(ngModel)]="this.showUnMatch" *ngIf="!this.DISABLE_PARSER_QMATCH"
                      (change)="toggleMatch($event)" matTooltip="Show/Hide Unmatched Messages"
                      style="margin-right: 10px;" color="primary">
                      {{ this.showUnMatch ? 'Show Unmatched' : 'Hide Unmatched' }}
      </mat-slide-toggle>

    <!--- <div fxFlex="30%" class="unit-input-field same-line">
        <mat-radio-group class="noscroll-bordered same-line" fxLayout="column" [(ngModel)]="fullMatch" placeholder="Match" (change)="matchChange($event)">
          <mat-radio-button class="smallRadio" [value]="true">
            Full Match
        </mat-radio-button>
        <mat-radio-button class="smallRadio" [value]="false">
          Partial Match
      </mat-radio-button>
    </mat-radio-group> -->
        <!-- <input matInput type="text" placeholder="Filter by Unit Name (class type pennant)" [matAutocomplete]="auto"
          [formControl]="unitCtrl">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayUnit" (optionsScroll)="onUnitOptionsScroll()">
          <mat-option *ngFor="let unit of filteredUnits$ | async" [value]="unit">
            <span matTooltip="{{ unit.country?.name }}">
            <img style="vertical-align:middle;" [src]="utilities.getFlag(unit)" height="20" >&nbsp;
          </span>
          <span matTooltip="{{ unit.fullName() }}">{{ unit.fullName() }}</span>
          <mat-option *ngIf="isLoading" class="is-loading">
            <mat-spinner diameter="30"></mat-spinner>
          </mat-option>
          </mat-option>
        </mat-autocomplete> -->
     <!-- </div> -->
      <!-- <span class="search-element">
        <button mat-raised-button color="primary" (click)="search()">Find Tracks</button>
      </span> -->
    </div>

    <div class="mat-elevation-z8">
      <div class="table-actions">
        <div class="collapsible-actions">
          <button mat-raised-button *ngIf="hasAnyExpandedGroups()" (click)="changeAllGroups(false)">
            <mat-icon>unfold_less</mat-icon> Collapse All
          </button>
          <button mat-raised-button *ngIf="!hasAnyExpandedGroups()" (click)="changeAllGroups(true)">
            <mat-icon>unfold_more</mat-icon> Expand All
          </button>
        </div>

        <div class="bulk-actions">
          <button mat-raised-button (click)="matchUnitOtherNames()">Auto-Match Unit Other Names</button>
        </div>
        <div class="bulk-actions">
          <button mat-raised-button (click)="selectedDiscard()" [disabled]="selection.selected.length < 1">Delete Selected </button>
          <button mat-raised-button (click)="bulkDiscard()">Bulk Discard</button>
        </div>
      </div>

      <mat-paginator [pageSizeOptions]="[10, 50, 100, 500, 1000]" [pageSize]="100" [length]="itemCount"
        (page)="onPaginateChange($event)" showFirstLastButtons></mat-paginator>
        <app-table-wrapper [facets]="facets" [filters]="filters" [dateFilters]="dateFilters" [resultsCount]="queueCount" (paginatorEvent)="handlePageEvent()" 
          (searchQuery)="setSearchQuery($event)">
      <cdk-virtual-scroll-viewport tvsItemSize="49" headerHeight="57">
        <mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
          <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef>
              Select
              <mat-checkbox (change)="$event ? toggleSelectAll() : null"
                [checked]="selection.hasValue() && isAllSelected()" [disabled]="!hasAnyExpandedGroups()"
                [indeterminate]="selection.hasValue() && !isAllSelected()">
              </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <mat-checkbox [disabled]="!canEdit(element)" (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(element) : null" [checked]="selection.isSelected(element)">
              </mat-checkbox>
            </mat-cell>
          </ng-container>

          <!-- <ng-container matColumnDef="needsReview">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Needs Review</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span [matBadge]="element.needsReview ? '!' : ''" matBadgeColor="warn">
                {{element.needsReview ? 'YES' : 'NO'}}
              </span>
            </mat-cell>
          </ng-container> -->

          <ng-container matColumnDef="classification">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Classification</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.classification}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
            <mat-cell [ngStyle]="{'color':isNoMatch(element) ? '#d66c0a':''}" *matCellDef="let element; cell">
              <img [src]="utilities.getFlag(element.unit)" height="16">
              {{element.name}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="geo">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Geo</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.geo}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="locTimeTs">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Time</mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{element.locTimeTs | date: 'ddHHmm\'Z\' MMM YYYY':'UTC'}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="latitude">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Latitude</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.latitude}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="longitude">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Longitude</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.longitude}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="elnot">
            <mat-header-cell *matHeaderCellDef mat-sort-header>ELNOT</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.elnot}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="points">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Points</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.points}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="originator">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Originator</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.originator}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="GCCS ID">
            <mat-header-cell *matHeaderCellDef mat-sort-header>GCCS ID</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.uid}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="source">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Int Source</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.source}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="sourceCode">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Source Code</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.sourceCode}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="forceCode">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Force Code</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element?.forceCode}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="confidenceCode">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Confidence</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element?.confidenceCode}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span *ngIf="element.needsReview" [matBadge]="'!'"
                matBadgeColor="warn" matBadgePosition="below before" matBadgeOverlap="false">
              </span>
              <button mat-button title="Open Parser Queue Entry" (click)="$event.stopPropagation()"
                (click)="open(element)">
                <mat-icon>launch</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="groupHeader">
            <mat-cell class="hyper" matTooltip="Click to show/hide group items" colspan="999"
              *matCellDef="let group; grouping">
             <div class="groupLabel">
                {{grouping.name}}: {{group.name}}
              </div>
              <div class="groupIcon">
                <mat-icon *ngIf="group.expanded">expand_more</mat-icon>
                <mat-icon *ngIf="!group.expanded">expand_less</mat-icon>
              </div>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
          <mat-row class="table-row" *matRowDef="let row; columns: displayedColumns;"></mat-row>
          <mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup" (click)="toggleGroup(row)"
            class="groupHeader"></mat-row>
        </mat-table>
      </cdk-virtual-scroll-viewport>
    </app-table-wrapper>
    </div>
  </div>
</mat-card>