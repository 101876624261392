import {JsonObject, JsonProperty, Any} from 'json2typescript';
import { Country } from './country.model';

@JsonObject('LocatorData')
export class LocatorData {
  @JsonProperty('unit_id', String, true)
  unitId: string = undefined;
  @JsonProperty('unit_name', String, true)
  unitName: string = undefined;
  @JsonProperty('port_name', String, true)
  portName: string = undefined;
  @JsonProperty('last_loc', String, true)
  lastLoc: string = undefined;
  @JsonProperty('loc_time_ts', String, true)
  locTimeTS: string = undefined;
  @JsonProperty('classification_name', String, true)
  classificationName: string = undefined;
  @JsonProperty('classification_description', String, true)
  classificationDescription: string = undefined;
  @JsonProperty('sconum', String, true)
  sconum: string = undefined;
  @JsonProperty('country', Country, true)
  country: Country = undefined;
  @JsonProperty('op_area_title', String, true)
  opAreaTitle: string = undefined;
  @JsonProperty('activity_category_code', String, true)
  activityCategoryCode: string = undefined;
  @JsonProperty('category_description', String, true)
  categoryDescription: string = undefined;
  @JsonProperty('country_name', String, true)
  countryName: string = undefined;
  @JsonProperty('nob_remove_ind', Boolean, true)
  isRemovedFromNob: boolean = undefined;
}
