import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { JsonConverter } from '@services/global/json-converter';
import { ParserQueueEntry } from '@models/parser-queue-entry.model';
import { ParserQueueApiService } from '@services/api-services/parser-queue-api.service';
import { map } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { ParserRawMessage } from '@models/parser-raw-message.model';

@Injectable()
export class ParserQueueService {
    private jsonConvert: JsonConverter;

    constructor(private parserQueueApiService: ParserQueueApiService) {
        this.jsonConvert = new JsonConverter();
    }

    public getAll(params: any): Observable<any> {
        return this.parserQueueApiService.getAllParserQueueEntries(params)
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    public getParserByParserId(parserId): Observable<any> {
        return this.parserQueueApiService.getParserByParserId(parserId)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, ParserQueueEntry);
                })
            );
    }

    public getKML(entry: ParserQueueEntry): Observable<HttpResponse<Object>> {
        return this.parserQueueApiService.getKML(this.jsonConvert.serializeObject(entry))
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    public getRawMessage(entry: ParserQueueEntry): Observable<any> {
        return this.parserQueueApiService.getRawMessage(this.jsonConvert.serializeObject(entry))
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }
    public getRawMessageParsed(entry: ParserQueueEntry): Observable<any> {
        return this.parserQueueApiService.getRawMessage(this.jsonConvert.serializeObject(entry))
            .pipe(
                map((data) => {
                    return ParserRawMessage.parse(data);
                })
            );
    }
    public ingest(entry: ParserQueueEntry): Observable<any> {
        return this.parserQueueApiService.ingestEntry(this.jsonConvert.serializeObject(entry))
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    public delete(entry: ParserQueueEntry): Observable<any> {
        return this.parserQueueApiService.deleteEntry(this.jsonConvert.serializeObject(entry))
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    public bulkDelete(params: any): Observable<any> {
        return this.parserQueueApiService.bulkDeleteEntries(params)
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    public selectedDelete(entryIds: string[]): Observable<any> {
        return this.parserQueueApiService.selectedDeleteEntries(entryIds)
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }
}
