import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from './api.config';

@Injectable()
export class FgiApiService {
  SERVER_URL;

  constructor(
    private http: HttpClient, apiConfig: APIConfig) {
    this.SERVER_URL = apiConfig.TARGET_URL;
  }

  public getAllFgis(): Observable<any> {
    return this.http.get(
      `${this.SERVER_URL}/fgis`);
  }

  public getFgiByFgiId(fgiId: string): Observable<any> {
    return this.http.get(
      `${this.SERVER_URL}/fgis/${fgiId}`);
  }

  public updateFgi(fgi: any): Observable<any> {
    return this.http.put(
      `${this.SERVER_URL}/fgis/${fgi.fgi_id}`, fgi);
  }

  public createFgi(fgi: any): Observable<any> {
    return this.http.post(
      `${this.SERVER_URL}/fgis`, fgi);
  }

  public deleteFgi(fgi: any): Observable<any> {
    return this.http.delete(
      `${this.SERVER_URL}/fgis/${fgi.fgi_id}`);
  }
}
