<app-classification-bar class="top-modal-classification-bar"></app-classification-bar>
<app-fdtm-modal-header [headerString]="headerString"></app-fdtm-modal-header>
<div mat-dialog-content>
<form [formGroup]="editForm">
  <mat-form-field class="input-full-width">
    <input matInput (input)="checkDups($event, 'description')" autocomplete="off"
      placeholder="Control Name" maxlength="6" required formControlName="description">
    <mat-error *ngIf="editForm?.controls?.description?.hasError('duplicate entry')">duplicate entry</mat-error>
  </mat-form-field>
  <br>
  <p *ngIf="isDelete" class="mat-color-warn">
    Deleting an Other Control Marking may cause serious issues with existing records!
    If you choose to delete this record the system will search for possible errors and may reject the request. 
    This is no guarantee that data corruption may still be present.  
  </p>
  <p *ngIf="!isNew && !isDelete" class="mat-color-warn">
    Editing an Other Control Marking may cause serious issues with existing records!
    If you choose to edit this marking the changes will be applied to all existing records in the database.  
  </p>
  <div mat-dialog-actions>
  <button *ngIf="!isDelete" [disabled]="!editForm.valid" mat-raised-button color="primary" (click)="handleSubmit()">Submit</button>
  <button *ngIf="isDelete" mat-raised-button color="warn" (click)="localDelete()">Delete</button>
  <button mat-raised-button (click)="handleCancel()">Cancel</button>
  </div>
</form>
</div>
<app-classification-bar class="bottom-modal-classification-bar"></app-classification-bar>