import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { EditModalBaseComponent } from '@fom-module/base-classes/edit-modal-base.component';
import { ReleasabilityService } from '@services/data-services/releasability.service';
import { FriendlyErrorService } from '@services/friendly-error.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-edit-releasability-modal',
  templateUrl: './edit-releasability-modal.component.html',
  styleUrls: ['./edit-releasability-modal.component.css']
})
export class EditReleasabilityModalComponent extends EditModalBaseComponent implements OnInit {

  editForm: FormGroup;

  constructor(public releasabilityService: ReleasabilityService,
    public formBuilder: FormBuilder, private friendlyErrorService: FriendlyErrorService,
    public dialogRef: MatDialogRef<EditReleasabilityModalComponent>) {
    super(releasabilityService, dialogRef);
  }

  ngOnInit() {
    this.headerString = this.isNew ? 'Add Releasability' : this.isDelete ? 'Delete Releasability' : 'Edit Releasability'
    this.editForm = this.formBuilder.group({
      releasabilityName: [{ value: this.entity.releasabilityName, disabled: this.isDelete }],
      nsaName: [{ value: this.entity.nsaName, disabled: this.isDelete }],
      twoDigit: [{ value: this.entity.twoDigit, disabled: this.isDelete }],
      sourceCode: [{ value: this.entity.sourceCode, disabled: this.isDelete }],
    });
  }

  localDelete() {
    this.releasabilityService.delete(this.entity).pipe(take(1)).subscribe(res => {
      this.dialogRef.close(res)
      //this.messageBusService.publishMessage(new GlobalBusMessage(GlobalMessageTriggers.CLOSE_MODAL));
    }, err => {
      this.friendlyErrorService.processError(err);
    });
  }


}
