import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Activity } from '@models/activity.model';
import { Area } from '@models/area.model';
import { Category } from '@models/category.model';
import { Classification } from '@models/classification.model';
import { Command } from '@models/command.model';
import { Country } from '@models/country.model';
import { Fleet } from '@models/fleet.model';
import { Port } from '@models/port.model';
import { ShipType } from '@models/ship-type.model';
import { Source } from '@models/source.model';
import { Threat } from '@models/threat.model';
import { LookUpService } from '@services/data-services/lookup.service';
import { count, take } from 'rxjs/operators'

@Component({
  selector: 'app-unit-filters',
  templateUrl: './unit-filters.component.html',
  styleUrls: ['./unit-filters.component.css']
})
export class UnitFiltersComponent implements OnInit {

  emptyFilters = {port_id: [], threat_id: [], category_id: [], ship_type_id: [], country_id: []}
  filters: any
  user
  ports: [Port]
  threats: [Threat]
  categories: [Category]
  shipTypes: [ShipType]
  countries: [Country]

  opAreaSelections = new SelectionModel<Area>(true, []);

  constructor(private lookupService: LookUpService,
    public dialogRef: MatDialogRef<UnitFiltersComponent>) { }

  ngOnInit() {
    this.filters = JSON.parse(sessionStorage.getItem('unitFilters')) || this.emptyFilters;
    this.user = JSON.parse(sessionStorage.getItem('user'))
    Promise.resolve().then(() => {
      this.loadLookups(); 
    })
  }

  loadLookups() {
      this.lookupService.getLookupByType(Port, true).pipe(take(1)).subscribe(results => {
        this.ports = results
      });
      this.lookupService.getLookupByType(Threat, true).pipe(take(1)).subscribe(results => {
        this.threats = results
      });
      this.lookupService.getLookupByType(Category, true).pipe(take(1)).subscribe(results => {
        this.categories = results
      });
      this.lookupService.getLookupByType(ShipType, true).pipe(take(1)).subscribe(results => {
        this.shipTypes = results
      });
      this.lookupService.getLookupByType(Country, true).pipe(take(1)).subscribe(results => {
        this.countries = results
      });
  }
  cancel() {
    this.dialogRef.close()
  }
  clear() {
    this.filters = this.emptyFilters
  }
  apply() {
    sessionStorage.setItem('unitFilters', JSON.stringify(this.filters))
    this.dialogRef.close(this.filters)
  }

  // select or deselect all items in the list
  // the selections list comes from the UI, defined above below the model array
  all(name, list, key) {
    if (Array.isArray(this.filters[name])) {
      if (this.filters[name].length) {
        this.filters[name] = []
      }
      else {
        // so... when I pass in the array directly it loses the reference? use the this.filters reference
        let items = []
        list.forEach(item => {
          items.push(item[key])
        });
        // gotta wait a tick for the UI control to update
        setTimeout(() => {
          this.filters[name] = items
        })
      }
    }
  }
}
