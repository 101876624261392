<div class="admin-work-area">
  <div fxLayout fxLayoutAlign="center center" class="simple-grid-title">
    <span class="grid-title">{{configInfo.getConfig().titlePrefix}} FDT-M System Connections</span>
    <button mat-mini-fab class="add-button" (click)="add()">
      <img class="light-icon" src="./assets/icons/ic_add_white_24px.svg" matTooltip="Add System Connection" />
    </button>
  </div>


  <div fxLayout fxLayoutAlign="center center" fxLayoutGap="20px">
    <button mat-mini-fab color="primary" (click)="refreshEntityData()" matTooltip="Refresh System Connections">
      <mat-icon>refresh</mat-icon>
    </button>

    <!-- Filter Chips -->
    <mat-form-field class="header-filter-input-field" appearance="fill">
      <mat-chip-list #chipList>
        <mat-chip *ngFor="let owner of owners" (removed)="removeChip(owner)">
          {{owner}}
          <button matChipRemove class="chip-remove" fxLayoutAlign="center center">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
        <input placeholder="Filter by owner..." #ownerInput [formControl]="ownerCtrl" [matAutocomplete]="auto"
          [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="addChip($event)">
      </mat-chip-list>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let owner of filteredOwners | async" [value]="owner">
          {{owner}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <!-- End Filter Chips -->
  </div>

  <!--Owner-->
  <ng-container *ngFor="let owner of connections">
    <mat-accordion>
      <mat-expansion-panel multi [expanded]="true">
        <mat-expansion-panel-header matTooltip="Expand/Collapse" matTooltipPosition="before">
          <mat-card-title>{{owner.ownerName}}</mat-card-title>
        </mat-expansion-panel-header>

        <div fxLayout="row-reverse">
          <button mat-mini-fab class="grey-button" (click)="add(owner.ownerId)"
            matTooltip="Add System Connection for {{ owner.ownerName }}">
            <mat-icon>add</mat-icon>
          </button>
        </div>
        <!-- Direction -->
        <div fxLayout="row" fxLayout.lt-lg="column">
          <ng-container *ngFor="let direction of owner.directions">
            <mat-card fxFlex="33" fxFlex.lt-lg="100" class="subcard">
              <mat-card-title>{{direction.direction}}</mat-card-title>
              <!-- System Connection -->
              <div *ngFor="let connection of direction.connections">
                <mat-card-title-group class="card-header">
                  <mat-card-title style="margin-right: 5px;">{{connection.systemName}}</mat-card-title>
                  <div fxLayoutAlign="center center">
                    <mat-slide-toggle [id]="connection.systemConnectionId" [checked]="connection.enabled"
                      (change)="toggleEnabled($event)" matTooltip="Enable/Disable System Connection"
                      style="margin-right: 10px;" color="accent"></mat-slide-toggle>
                    <button mat-icon-button (click)="addMessageConfig(connection)" matTooltip="Add a Message Config">
                      <mat-icon>add</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="Edit Connection" (click)="edit(connection)">
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="Delete Connection" (click)="handleDelete(connection)">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                </mat-card-title-group>
                <!-- Message Configs -->
                <mat-card-content *ngFor="let messageConfig of connection.messageConfigs">
                  <div fxLayoutAlign="left center">
                    <span class="message-config-title"
                      [ngClass]="{ 'faded' : !connection.enabled }">{{messageConfig.messageType}}</span>
                    <mat-slide-toggle [id]="messageConfig.messageConfigId" [checked]="messageConfig.enabled"
                      (change)="toggleEnabled($event)" matTooltip="Enable/Disable Message Configuration"
                      style="margin-right: 10px;" color="primary">
                    </mat-slide-toggle>
                    <button mat-icon-button (click)="editMessageConfig(messageConfig, connection)"
                      matTooltip="Edit Message Config">
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button (click)="handleMessageConfigDelete(messageConfig)"
                      matTooltip="Delete Message Config">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                </mat-card-content>
                <!-- End Message Configs -->
              </div>
              <!-- End System Connection -->
            </mat-card>
          </ng-container>
        </div>
        <!-- End Direction -->
      </mat-expansion-panel>
    </mat-accordion>
  </ng-container>
  <!--End Owner-->
</div>
