import { JsonObject, JsonProperty, Any } from 'json2typescript';

@JsonObject('Control')
export class Control {
  @JsonProperty('sci_control_id', String, true)
  sciControlId: string = undefined;
  @JsonProperty('control_mark', String, true)
  controlMark: string = undefined;
  @JsonProperty('source_code', String, true)
  sourceCode: string = undefined;
  @JsonProperty('created_by', String, true)
  createdBy: string = undefined;

  static lookupName(): string {
    return 'controls';
  }
}
