import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { APIConfig } from '@services/api-services/api.config';

@Injectable()
export class UnitOtherNameApiService {
  SERVER_URL;

  constructor(
    private http: HttpClient, apiConfig: APIConfig) {
    this.SERVER_URL = apiConfig.TARGET_URL;
  }

  // public getAllOtherNames(queryParams: Object): Observable<any> {
  //   let httpParams = new HttpParams();

  //   for (const key in queryParams) {
  //     if (queryParams.hasOwnProperty(key)) {
  //       httpParams = httpParams.set(key, queryParams[key]);
  //     }
  //   }

  //   return this.http.get(`${this.SERVER_URL}/unit-other-names`, { params: httpParams });
  // }

  public findOtherNames(value, filters = {}, limit = 20, offset = 0): Observable<any> {
    // so... the ui control wants to send another find when user selects...
    // could not find how to prevent, just eat it
    if (typeof value != 'string') {
      return of<Response>();
    }

    let httpParams = new HttpParams();
    // add query string, limit, and offset
    httpParams = httpParams.set('queryString', value).set('limit', limit).set('offset', offset);

    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        httpParams = httpParams.set(key, filters[key]);
      }
    }

    return this.http.get(`${this.SERVER_URL}/unit-other-names/findOtherNames`, {params: httpParams});
  }

  public getAllOtherNames(): Observable<any> {
    let httpParams = new HttpParams();
    return this.http.get(`${this.SERVER_URL}/unit-other-names`);
  }

  public getUnitOtherNames(unitId: string): Observable<any> {
    return this.http.get(
      `${this.SERVER_URL}/unit-other-names/unitOtherNames/${unitId}`);
  }

  public getOtherNameById(otherNameId: string): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/unit-other-names/${otherNameId}`);
  }

  public updateOtherName(otherName: any): Observable<any> {
    return this.http.put(`${this.SERVER_URL}/unit-other-names/${otherName.unit_other_name_id}`, otherName);
  }

  public createOtherName(otherName: any): Observable<any> {
    return this.http.post(`${this.SERVER_URL}/unit-other-names`, otherName);
  }

  public updateParserEntries(): Observable<any> {
    return this.http.post(`${this.SERVER_URL}/unit-other-names/update-parser-entries`, {});
  }

  public deleteOtherName(otherName: any): Observable<any> {
    return this.http.delete(
      `${this.SERVER_URL}/unit-other-names/${otherName.aternative_name_id}`, { body: otherName });
  }
}
