import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FriendlyErrorService {

  constructor() { 
  }

  // TODO, move the parameters into a structure / object for expandability
  public processError(error) {
  // just step carefully
    if (error instanceof HttpErrorResponse && 
        error.error && 
        error.error.status == 403) {
      throw `${error.error.message || 'Server 403 error'}. Code ${error.error.code || 'n/a'} `
    }
    else {
      throw error;
    }
  }
}
