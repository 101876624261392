import { JsonConvert } from 'json2typescript';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, empty } from 'rxjs';
import { AuthorizationService} from '@services/data-services/authorization.service';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class AuthorizationGuardService implements CanActivate {
    constructor(private authorizationService: AuthorizationService, private router: Router) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        const featureId: string = state.url.split('/').pop();

        return this.authorizationService.getPermissionByFeatureId(featureId)
            .pipe(
                map((data) => {
                    if (!data.authorized) {
                        this.router.navigate(['/home']);
                    }
                    return data.authorized;
                })
            );
    }
}