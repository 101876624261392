import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, empty } from 'rxjs';
import { APIConfig } from './api.config';

@Injectable()
export class AuthenticationApiService {
  SERVER_URL;

  constructor(
    private http: HttpClient, apiConfig: APIConfig) {
    this.SERVER_URL = apiConfig.TARGET_URL;
  }

  public login(loginInfo: object): Observable<any> {
    return this.http.post(
      `${this.SERVER_URL}/auth/login`, loginInfo);
  }

  public logout(reason): Observable<any> {
    return this.http.post(
      `${this.SERVER_URL}/auth/logout`, {reason});
  }

  public certLogin(loginInfo: string): Observable<any> {
    return this.http.post(
      `${this.SERVER_URL}/auth/saml`,
      {
        codedString: loginInfo,
        user: sessionStorage['currentUserId'] ? sessionStorage.getItem('currentUserId') : null
      }
    );
  }
}
