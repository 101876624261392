import { Injectable } from '@angular/core';
import { JsonConverter } from '../global/json-converter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ElintFunctionTypeApiService } from '@services/api-services/elint-function-type-api.service';
import { ElintFunctionType } from '@models/elint-function-type.model';


@Injectable()
export class ElintFunctionTypeService {
    private jsonConvert: JsonConverter;

    constructor(private apiService: ElintFunctionTypeApiService) {
        this.jsonConvert = new JsonConverter();
    }

    public getAll(): Observable<ElintFunctionType[]> {
        return this.apiService.getAllElintFunctionTypes()
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, ElintFunctionType);
                })
            );
    }

    public getCategoriesByCatId(id): Observable<ElintFunctionType> {
        return this.apiService.getElintFunctionTypeByElintFunctionTypeId(id)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, ElintFunctionType);
                })
            );
    }

    public update(elintfunctiontype: ElintFunctionType): Observable<ElintFunctionType> {
        return this.apiService.updateElintFunctionType(this.jsonConvert.serializeObject(elintfunctiontype))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, ElintFunctionType);
                })
            );
    }

    public create(elintfunctiontype: ElintFunctionType): Observable<ElintFunctionType> {
        return this.apiService.createElintFunctionType(this.jsonConvert.serializeObject(elintfunctiontype))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, ElintFunctionType);
                })
            );
    }

    public delete(elintfunctiontype: ElintFunctionType): Observable<ElintFunctionType> {
        return this.apiService.deleteElintFunctionType(this.jsonConvert.serializeObject(elintfunctiontype))
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

}
