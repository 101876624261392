import { FomPageBaseComponent } from './../base-classes/fom-page-base.component';
import { Router } from '@angular/router';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent extends FomPageBaseComponent implements OnInit {

  constructor(public messageBusService: MessageBusService, public route: Router) {
    super(messageBusService, route);
  }

  ngOnInit() {
    super.ngOnInit();
  }

}
