import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from './api.config';

@Injectable()
export class UserApiService {
  SERVER_URL;

  constructor(
    private http: HttpClient, apiConfig: APIConfig) {
    this.SERVER_URL = apiConfig.TARGET_URL;
  }

  public getAllUsers(): Observable<any> {
    return this.http.get(
      `${this.SERVER_URL}/users`);
  }

  public getUserByUserId(userId: string): Observable<any> {
    return this.http.get(
      `${this.SERVER_URL}/users/${userId}`);
  }

  public updateUser(user: any): Observable<any> {
    return this.http.put(
      `${this.SERVER_URL}/users/${user.user_id}`, user);
  }

  public createUser(user: any): Observable<any> {
    return this.http.post(
      `${this.SERVER_URL}/users`, user);
  }

  public changePassword(user: any): Observable<any> {
    return this.http.put(
      `${this.SERVER_URL}/users/changePassword/${user.user_id}`, user);
  }

  public deleteUser(user: any): Observable<any> {
    return this.http.delete(
      `${this.SERVER_URL}/users/${user.user_id}`);
  }
  
  public getAllUsersWithPki(): Observable<any> {
    return this.http.get(
      `${this.SERVER_URL}/users/pki`);
  }

  public addUserPki(user: any, distinguishedName: string): Observable<any> {
    let HttpParams = {user: user, distinguished_name: distinguishedName}
    return this.http.post(
      `${this.SERVER_URL}/users/pki`, HttpParams);
  }

  public deleteUserPki(pki: any): Observable<any> {
    console.log(pki)
    return this.http.delete(
      `${this.SERVER_URL}/users/pki/${pki.user_pki_id}`);
  }
}
