import { FomPageBaseComponent } from '@fom-module/base-classes/fom-page-base.component';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent extends FomPageBaseComponent implements OnInit {
  constructor(public messageBusService: MessageBusService, public route: Router) {
    super(messageBusService, route);
  }

  ngOnInit() {
    super.ngOnInit();
  }

}


