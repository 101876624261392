import { Historical } from '@models/historical.model';
import { HistoricalApiService } from '@services/api-services/historical-api.service';
import { Injectable } from '@angular/core';
import { JsonConverter } from '@services/global/json-converter';
import { Observable, empty } from 'rxjs';
import { map, catchError } from 'rxjs/operators';


@Injectable()
export class HistoricalService {
    private jsonConvert: JsonConverter;

    constructor(private historicalApiService: HistoricalApiService) {
        this.jsonConvert = new JsonConverter();
    }

    public getAll(queryParams: Object, filters = {}): Observable<Historical[]> {
        return this.historicalApiService.getAllUnits(queryParams, filters)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, Historical);
                })
            );
    }

    public getUnitByUnitId(unitId): Observable<Historical> {
        return this.historicalApiService.getUnitByUnitId(unitId)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Historical);
                })
            );
    }

    public getUnits(from: string, to: string) {
        return this.historicalApiService.getUnits(from, to)
        .pipe(
          map((data) => {
              return data;
          })
        )
        // .pipe(
        //     catchError((err, caught) => {
        //         console.error(err);
        //         return empty();
        //       })
        // );
    }
}
