import { Category } from '@models/category.model';
import { CategoryApiService } from '../api-services/category-api.service';
import { Injectable } from '@angular/core';
import { JsonConverter } from '../global/json-converter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable()
export class CategoryService {
    private jsonConvert: JsonConverter;

    constructor(private categoryApiService: CategoryApiService) {
        this.jsonConvert = new JsonConverter();
    }

    public getAll(): Observable<Category[]> {
        return this.categoryApiService.getAllCategories()
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, Category);
                })
            );
    }

    public getCategoriesByCatId(catId): Observable<Category> {
        return this.categoryApiService.getCategoryByCatId(catId)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Category);
                })
            );
    }

    public update(cat: Category): Observable<Category> {
        return this.categoryApiService.updateCategory(this.jsonConvert.serializeObject(cat))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Category);
                })
            );
    }

    public create(cat: Category): Observable<Category> {
        return this.categoryApiService.createCategory(this.jsonConvert.serializeObject(cat))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Category);
                })
            );
    }

    public delete(cat: Category): Observable<Category> {
        return this.categoryApiService.deleteCategory(this.jsonConvert.serializeObject(cat))
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

}
