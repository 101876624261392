import { AfterContentInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@services/data-services/authentication.service';
import { UserNotificationService } from '@services/notification.service';

@Component({
  selector: 'app-cert-login',
  templateUrl: './cert-login.component.html',
  styleUrls: ['./cert-login.component.css']
})
export class CertLoginComponent implements OnInit, AfterContentInit {
  info: any;
  message: string;

  constructor(private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: UserNotificationService) {
  }

  ngOnInit() {
    this.info = this.route.snapshot.paramMap.get('encodedString');
    this.message = 'Syncing Authentication...';
  }

  ngAfterContentInit() {
    this.authenticationService.certLogin(this.info).subscribe(result => {
        if (result) {
          this.message = 'Authentication Complete';
          this.router.navigateByUrl(`/home`);
        } else {
          this.message = 'Error Authenticating, Please login with username and password';
          this.router.navigateByUrl(`/home`);
        }
    }, error => {
      const message = error.error.message ? error.error.message : 'Failed to authenticate, please contact your administrator';
      this.notificationService.showError(message, 120_000);
      this.router.navigateByUrl('/home');
    });
  }
}
