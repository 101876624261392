import { Parameter } from '@models/parameter.model';
import { ParameterApiService } from '@services/api-services/parameter-api.service';
import { Injectable } from '@angular/core';
import { JsonConverter } from '@services/global/json-converter';
import { Observable, empty } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Form } from '@angular/forms';

@Injectable()
export class ParameterService {
    private jsonConvert: JsonConverter;

    constructor(private parameterApiService: ParameterApiService) {
        this.jsonConvert = new JsonConverter();
    }

    public getAll(): Observable<Parameter[]> {
        return this.parameterApiService.getAllParameters()
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, Parameter);
                })
            );
    }

    public getParameterByParameterId(parameterId: string): Observable<Parameter> {
        return this.parameterApiService.getParameterByParameterId(parameterId)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Parameter);
                })
            );
    }

    public getBlob(parameterId: string) {
        return this.parameterApiService.getBlob(parameterId)
        .pipe(
            map((data) => {
                return data;
            })
          )
          .pipe(
              catchError((err, caught) => {
                  console.error(err);
                  return empty();
                })
          );
      }

    public update(parameterId, formData: FormData): Observable<Parameter> {
        return this.parameterApiService.updateParameter(parameterId, formData)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Parameter);
                })
            );
    }

    public create(formData: FormData): Observable<Parameter> {
        return this.parameterApiService.createParameter(formData)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Parameter);
                })
            );
    }

    public delete(parameter: Parameter): Observable<Parameter> {
        return this.parameterApiService.deleteParameter(this.jsonConvert.serializeObject(parameter))
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }
}
