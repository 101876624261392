import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('ParametersTemplate')
export class ParametersTemplate {
  @JsonProperty('parameters_template_id', String, true)
  parametersTemplateId: string = undefined;
  @JsonProperty('parameters_type', String, true)
  parametersType: string = undefined;
  @JsonProperty('template', Object, true)
  template: any = undefined;
}
