import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter } from '../services/global/date-converter';
import { Command } from '@models/command.model';

@JsonObject('ScanType')
export class ScanType {
  @JsonProperty('scan_type_id', String, true)
  scan_type_id: string = undefined;
  @JsonProperty('scan_type_code', String, true)
  scan_type_code: string = undefined;
  @JsonProperty('scan_type_description', String, true)
  scan_type_description: string = undefined;
  @JsonProperty('create_ts', DateConverter, true)
  dateCreated: Date = undefined;
  @JsonProperty('created_by', String, true)
  createdBy: string = undefined;
  @JsonProperty('update_ts', String, true)
  dateUpdated: Date = undefined;
  @JsonProperty('updated_by', Command, true)
  updatedBy: string = undefined;
  @JsonProperty('delete_ts', DateConverter, true)
  dateDeleted: Date = undefined;
  @JsonProperty('deleted_by', String, true)
  deletedBy: string = undefined;

  static lookupName(): string {
    return 'scantypes';
  }
}
