import { FormBuilder } from '@angular/forms';
import { EditModalBaseComponent } from '@fom-module/base-classes/edit-modal-base.component';
import { SourceService } from '@services/data-services/source.service';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { Component, OnInit } from '@angular/core';
import { Source } from '@models/source.model';
import { LookUpService } from '@services/data-services/lookup.service';
import { Command } from '@models/command.model';
import { MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { CommandHierarchyService } from '@services/data-services/command-hierarchy.service';
import { User } from '@models/user.model';
import { CurrentUserService } from '@services/current-user-service';
import { CommandHierarchy } from '@models/command-hierarchy.model';

@Component({
  selector: 'app-edit-source-modal',
  templateUrl: './edit-source-modal.component.html',
  styleUrls: ['./edit-source-modal.component.css']
})
export class EditSourceModalComponent extends EditModalBaseComponent implements OnInit {
  commandOptions: Command[];
  user: User;
  commandHierarchy: CommandHierarchy;

  constructor(public sourceService: SourceService,
    public lookUpService: LookUpService,
    public formBuilder: FormBuilder,
    private commandHierarchyService: CommandHierarchyService,
    public dialogRef: MatDialogRef<EditSourceModalComponent>,
    public messageBusService: MessageBusService,
    private currentUserService: CurrentUserService) {
    super(sourceService, dialogRef);
  }

  ngOnInit() {
    this.headerString = this.isNew ? 'Add Source' :
    this.isDelete ? 'Delete Source' :
    'Edit Source'
    this.user = this.currentUserService.getCurrentUser();
    if (this.isNew) {
      this.entity.commandId = this.user.currentJFMCC ? this.user.currentJFMCC.commandId : this.user.commandId;
    }
    this.editForm = this.formBuilder.group({
      commandId: [{ value: this.entity.commandId, disabled: this.isDelete }],
      sourceName: [{ value: this.entity.sourceName, disabled: this.isDelete }],
      nobCode: [{ value: this.entity.nobCode, disabled: this.isDelete }],
      shortCode: [{ value: this.entity.shortCode, disabled: this.isDelete }]
    });
    Promise.resolve().then(() => {
      this.loadLookups();
    });
  }

  loadLookups() {
    this.lookUpService.getLookupByType(Command, true).pipe(take(1)).subscribe(result => {
      this.commandHierarchyService.getAll({ sort: 'version_num', limit: 1 }).pipe(take(1)).subscribe(json => {
        if (json && Array.isArray(json) && json.length) {
          this.commandHierarchy = json[0];

          let jfmccs;
          if (this.user.isSystemAdmin()) {
            jfmccs = this.commandHierarchy.getJFMCCs();
          } else {
            jfmccs = this.commandHierarchy.getJFMCCIds(this.user.command);
          }

          this.commandOptions = result.filter(item => {
            return jfmccs.includes(item.commandId);
          });
        } else {
          this.commandOptions = result;
        }
      });
    });
  }
}
