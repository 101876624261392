import { Injectable } from '@angular/core';
import { Country } from '@models/country.model';
import { JsonConverter } from '@services/global/json-converter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CountryApiService } from '@services/api-services/country-api.service';

@Injectable(
//   {
//   providedIn: 'root'
// }
)
export class CountryService {
  private jsonConvert: JsonConverter;

  constructor(private countryApiService: CountryApiService) {
    this.jsonConvert = new JsonConverter();
  }

  public getAll(): Observable<Country[]> {
    return this.countryApiService.getAllCountries()
      .pipe(
        map((data) => {
          return this.jsonConvert.deserializeArray(data, Country);
        })
      );
  }

  public getCountryByCountryId(countryId: string): Observable<Country> {
    return this.countryApiService.getCountryByCountryId(countryId)
      .pipe(
        map((data) => {
          return this.jsonConvert.deserializeObject(data, Country);
        })
      );
  }
/** Flag Servies **/
    public getFlag(id: string): Observable<any> {
      return this.countryApiService.getFlag(id)
        .pipe(
          map((data) => {
            return data;
          })
        );
    }
    public getFlagUrl(country: Country){
      if(!country || country.flagImagePath === "" || country.flagImagePath === null){
        return "assets/images/Blank.png"
      }else{
        return this.countryApiService.getFlagURL(country.countryId)
      }
    }
/** End Flag Service */
  public create(country: Country): Observable<Country> {
    return this.countryApiService.createCountry(this.jsonConvert.serializeObject(country))
      .pipe(
        map((data) => {
          return this.jsonConvert.deserializeObject(data, Country);
        })
      );
  }

  public update(country: Country): Observable<Country> {
    return this.countryApiService.updateCountry(this.jsonConvert.serializeObject(country))
      .pipe(
        map((data) => {
          return this.jsonConvert.deserializeObject(data, Country);
        })
      );
  }

  public delete(country: Country): Observable<any> {
    return this.countryApiService.deleteCountry(this.jsonConvert.serializeObject(country))
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  public createFlag(imageFile, countryId): Observable<any> {
    return this.countryApiService.createFlag(imageFile, countryId)
      .pipe( map( data => { return data } ))
  }

  public updateFlag(imageFile, countryId): Observable<any> {
    return this.countryApiService.updateFlag(imageFile, countryId)
      .pipe( map( data => { return data } ))
  }

  public deleteFlag(countryId): Observable<any> {
    return this.countryApiService.deleteFlag(countryId)
      .pipe( map( data => { return data } ))
  }
}
