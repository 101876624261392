import { Injectable } from '@angular/core';
import { JsonConverter } from '../global/json-converter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FreqTypeApiService } from '@services/api-services/freq-type-api.service';
import { FreqType } from '@models/freq-type.model';


@Injectable()
export class FreqTypeService {
    private jsonConvert: JsonConverter;

    constructor(private apiService: FreqTypeApiService) {
        this.jsonConvert = new JsonConverter();
    }

    public getAll(): Observable<FreqType[]> {
        return this.apiService.getAllFreqTypes()
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, FreqType);
                })
            );
    }

    public getCategoriesByCatId(id): Observable<FreqType> {
        return this.apiService.getFreqTypeByFreqTypeId(id)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, FreqType);
                })
            );
    }

    public update(freqType: FreqType): Observable<FreqType> {
        return this.apiService.updateFreqType(this.jsonConvert.serializeObject(freqType))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, FreqType);
                })
            );
    }

    public create(freqType: FreqType): Observable<FreqType> {
        return this.apiService.createFreqType(this.jsonConvert.serializeObject(freqType))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, FreqType);
                })
            );
    }

    public delete(freqType: FreqType): Observable<FreqType> {
        return this.apiService.deleteFreqType(this.jsonConvert.serializeObject(freqType))
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

}
