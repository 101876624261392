
import { FDOMapService } from '@services/data-services/fdo-map.service';
import { SourceService } from '@services/data-services/source.service';
import { CommandService } from '@services/data-services/command.service';
import { ClassificationService } from '@services/data-services/classification.service';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { Component, ViewChild, OnInit } from '@angular/core';
import { FDOMap } from '@models/fdo-map.model';
import { Command } from '@models/command.model';
import { Router } from '@angular/router';
import { GridBaseComponent } from '@fom-module/base-classes/grid-base.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { EditFDOMapModalComponent } from '@fom-module/edit-modals/edit-fdo-map-modal/edit-fdo-map-modal.component';
import { take } from 'rxjs/operators';
import { MatSort, Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { AppInitService } from '../../../config/init.service';
import { CommandHierarchyService } from '@services/data-services/command-hierarchy.service';
import { CurrentUserService } from '@services/current-user-service';
import { User } from '@models/user.model';
import { CommandHierarchy } from '@models/command-hierarchy.model';

@Component({
  selector: 'app-fdo-map',
  templateUrl: './fdo-map.component.html',
  styleUrls: ['./fdo-map.component.css', '../base-classes/grid-base.component.css'],
  // providers: [TdFileService]
})
export class FDOMapComponent extends GridBaseComponent implements OnInit {

  paginator: MatPaginator;
  sort: MatSort;

  displayedColumns = ['edit', 'command.name', 'source.sourceName', 'srcClassificationString', 'tgtClassificationString', 'delete'];

  commands: Command[] = [];
  selectedCommandId: string = null;
  entities: any[] = [];
  user: User;
  commandHierarchy: CommandHierarchy;

  constructor(public fdoMapService: FDOMapService,
    public sourceService: SourceService,
    public commandService: CommandService,
    public classificationService: ClassificationService,
    private dialog: MatDialog,
    public messageBusService: MessageBusService,
    public route: Router,
    public initService: AppInitService,
    private commandHierarchyService: CommandHierarchyService,
    private currentUserService: CurrentUserService) {
    super(messageBusService, fdoMapService, route);
  }

  ngOnInit() {

    this.listenToMessageBus();
    this.user = this.currentUserService.getCurrentUser();
    this.loadLookups();
    this.selectedCommandId = 'All';
    this.loadEntityData();


    // super.ngOnInit();
  }

  onCommandChange(event) {
    this.selectedCommandId = event.value || 'All';

    this.loadEntityData();
  }


  loadLookups() {
    // this.lookUpService.getLookupByType(Command, true).pipe(take(1)).subscribe(result => {
    this.commandService.getAll().pipe(take(1)).subscribe(result => {
      this.commandHierarchyService.getAll({ sort: 'version_num', limit: 1 }).pipe(take(1)).subscribe(json => {
        if (json && Array.isArray(json) && json.length) {
          this.commandHierarchy = json[0];

          let jfmccs;
          if (this.user.isSystemAdmin()) {
            jfmccs = this.commandHierarchy.getJFMCCs();
          } else {
            jfmccs = this.commandHierarchy.getJFMCCIds(this.user.command);
          }
          this.commands = result.filter(item => {
            return jfmccs.includes(item.commandId);
          });
        } else {
          this.commands = result;
        }
      });
    });
  }


  loadEntityData() {
    this.fdoMapService.getAllByCommandId(this.selectedCommandId).pipe(take(1)).subscribe(entities => {
      this.entities = entities;
      this.dataSource = new MatTableDataSource<object>(this.entities);
      this.dataSource.sortingDataAccessor = this.dataAccessor;
      this.dataSource.paginator = this.paginator;
      if (entities.length === 0) {
        throw 'No FDO mappings found for selected command.'
      }
      const sort: Sort = {
        active: 'command.name',
        direction: 'asc'
      };
      this.sortData(sort);
    });
  }

  handleDelete(entity: FDOMap) {
    super.handleDelete(entity, 'Delete FDO Mapping', `Select "Delete" to delete FDO mapping for source: ${entity.source ? entity.source.sourceName : null}`, 'Delete')
  }

  edit(fdoMap: any) {
    const dialogRef = this.dialog.open(EditFDOMapModalComponent, {
      width: '820px'
    });
    dialogRef.componentInstance.isNew = false;
    dialogRef.componentInstance.entity = fdoMap;


    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      this.loadEntityData()
    });

  }
  add() {
    const dialogRef = this.dialog.open(EditFDOMapModalComponent, {
      width: '820px'
    });
    dialogRef.componentInstance.isNew = true;
    dialogRef.componentInstance.entity = new FDOMap();

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      this.loadEntityData()
    })
  }

  info() {
    const dialogRef = this.dialog.open(EditFDOMapModalComponent, {
      width: '820px'
    });
    dialogRef.componentInstance.isNew = true;
    dialogRef.componentInstance.entity = new FDOMap();

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      this.loadEntityData()
    })
  }
  sortData(sort: Sort) {
    let data
    if (!sort.active || sort.direction === '') {
      data = this.entities
    }
    else {
      data = this.entities.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        return this.compare(a, b, isAsc, sort.active);
      })
    }
    this.dataSource.data = data;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = this.dataAccessor;
  }
  compare(a, b, isAsc, active) {
    return (this.dataAccessor(a, active) < this.dataAccessor(b, active) ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
