import { EditModalBaseComponent } from '@fom-module/base-classes/edit-modal-base.component';
import { SlideService } from '@services/data-services/slide.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Slide } from '@models/slide.model';
import { Fleet } from '@models/fleet.model';
import { Country } from '@models/country.model';
import { LookUpService } from '@services/data-services/lookup.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { UtilityService } from '@services/utility.service'
import { take } from 'rxjs/operators'

@Component({
  selector: 'app-edit-slide-modal',
  templateUrl: './edit-slide-modal.component.html',
  styleUrls: ['./edit-slide-modal.component.css']
})
export class EditSlideModalComponent extends EditModalBaseComponent implements OnInit {
  //editForm: FormGroup;

  entity: Slide;
  isNew: boolean
  // Dropdown options
  fleetOptions: Fleet[];
  countryOptions: Country[] = [];
  country: Country = new Country()
  // file stuff
  link: any;
  selectedFile: File;
  fileSelectMsg: String = 'No file selected yet.';
  fileUploadMsg: String = 'No file uploaded yet.';

  constructor(public slideService: SlideService,
    public utilities: UtilityService,
    public lookUpService: LookUpService,
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<EditSlideModalComponent>) {
      super(slideService, dialogRef);
    }

  ngOnInit() {
    this.headerString = this.isNew ? 'Add Slide' :
    this.isDelete ? 'Delete Slide' :
    'Edit Slide';
    // entity will an initialized (new) object or retrieved from DB
    this.editForm = this.formBuilder.group({
      //fleetId: [this.entity.fleetId],
      slideName: [this.entity.slideName],
      // ok, so the database holds decimal lat/lon, the UI expects DDMM(SS) w/N,S,E,W 
      lat1: this.entity.lat1 ? [this.utilities.getDMS(this.entity.lat1, 'lat'), this.utilities.latValidator] : ['', this.utilities.latValidator],
      lon1: this.entity.lon1 ? [this.utilities.getDMS(this.entity.lon1, 'lon'), this.utilities.lonValidator] : ['', this.utilities.lonValidator],
      lat2: this.entity.lat2 ? [this.utilities.getDMS(this.entity.lat2, 'lat'), this.utilities.latValidator] : ['', this.utilities.latValidator],
      lon2: this.entity.lon2 ? [this.utilities.getDMS(this.entity.lon2, 'lon'), this.utilities.lonValidator] : ['', this.utilities.lonValidator],
      image: [null],
      fleetId: [this.entity.fleetId],
      link: [this.entity.link],
      countryId: [this.entity.country ? this.entity.country.countryId : null]
    });
    Promise.resolve().then(() => {
      this.loadLookups();
    });
  }

  loadLookups() {
    this.lookUpService.getLookupByType(Fleet).pipe(take(1)).subscribe(result => {
      this.fleetOptions = result;
    })
    this.lookUpService.getLookupByType(Country).pipe(take(1)).subscribe(result => {
      result.forEach(country => {
        (country as any).flagImage = this.utilities.getCountryFlag(country)
      });
      this.countryOptions = result;
      this.country = this.entity.countryId ? this.countryOptions.find(x => x.countryId == this.entity.countryId) : null
    })
  }
  onCountryChange(event) {
    // event value is the selected country's id
    if (event.value) {
      this.entity.countryId = event.value
      this.country = this.countryOptions.find(x => x.countryId == event.value)
    }
  }

  cancel() {
    this.dialogRef.close()
  }

  onFileChanged(event) {
    this.selectedFile = <File>event.target.files[0];
  }

  onUpload() {
    const fd = new FormData();
    this.link = this.selectedFile.name;
    fd.append('image', this.selectedFile, this.selectedFile.name);
    console.log(fd);
    // this.slideApiService.uploadFile(fd);
    console.log('file uploaded');
  }
  uploadEvent(file: File): void {
    this.fileUploadMsg = file.name;
  }

  cancelEvent(): void {
    this.fileSelectMsg = 'No file selected yet.';
    this.fileUploadMsg = 'No file uploaded yet.';
    this.link = null;
    this.selectedFile = null;
  }

  handleSubmit() {
    if (this.editForm.valid) {
      this.updateEntity();
      this.slideService.create(this.entity).pipe(take(1)).subscribe(res => {
        this.dialogRef.close()
      });
    }
  }
  // adding ability to edit the values and/or replace the image file. 
  // if replacing the image file, the api will remove the old file
  update() {
    if (this.editForm.valid) {
      this.updateEntity();
      this.slideService.update(this.entity).pipe(take(1)).subscribe(res => {
        this.dialogRef.close()
      });
    }
  }
  updateEntity() {
    for (const key in this.editForm.controls) {
      if (this.entity.hasOwnProperty(key.toString())) {
        this.entity[key.toString()] = this.editForm.controls[key]['value'];
      }
    }
    // storing as decimal, convert or use the user's entry
    const latitude1 = this.editForm.controls['lat1']['value'].toString();
    if (latitude1.indexOf('.') == -1) {
      this.entity.lat1 = this.utilities.decimalLatitude(latitude1);
    }
    else {
      this.entity.lat1 = latitude1
    }

    const longitude1 = this.editForm.controls['lon1']['value'].toString();
    if (longitude1.indexOf('.') == -1) {
      this.entity.lon1 = this.utilities.decimalLongitude(longitude1);
    }
    else {
      this.entity.lon1 = longitude1
    }

    const latitude2 = this.editForm.controls['lat2']['value'].toString();
    if (latitude2.indexOf('.') == -1) {
      this.entity.lat2 = this.utilities.decimalLatitude(latitude2);
    }
    else {
      this.entity.lat2 = latitude2
    }

    const longitude2 = this.editForm.controls['lon2']['value'].toString();
    if (longitude2.indexOf('.') == -1) {
      this.entity.lon2 = this.utilities.decimalLongitude(longitude2);
    }
    else {
      this.entity.lon2 = longitude2
    }

    this.entity.image = this.selectedFile ? { contents: this.selectedFile, name: this.selectedFile.name } : null
  }

}
