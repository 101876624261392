<h1 mat-dialog-title>{{user.user_name}} ABAC Settings</h1>
<div mat-dialog-content>
  <p>Command(s) {{currentUserABACPrefs?.command_id.length}} of {{commands?.length}} selected</p>
  <mat-form-field>
    <mat-select [(ngModel)]="currentUserABACPrefs.command_id" multiple name="commands" (selectionChange)="commandsChanged($event)">
       <mat-option *ngFor="let command of commands" [value]="command.commandId"> {{command.name}}</mat-option>
    </mat-select>
    <button mat-icon-button (click)="allCommands($event)" matSuffix title="Select all Commands">
      <mat-icon class="color-green">sentiment_very_satisfied</mat-icon>
    </button>
  </mat-form-field>
  <mat-error *ngIf="!currentUserABACPrefs.command_id.length">Select one or more Commands.</mat-error>
  <p>
    OpArea(s) {{currentUserABACPrefs?.op_area_id? currentUserABACPrefs?.op_area_id?.length : 0}} of {{opAreas?.length}} selected.
  </p>
  <mat-form-field>
    <mat-select [(ngModel)]="currentUserABACPrefs.op_area_id" multiple name="opArea">
        <!-- <mat-option *ngFor="let area of opAreas | commandFilter : currentUserABACPrefs.command_id" [value]="area.opAreaId"> {{area.remarks}} {{area.opArea}}</mat-option> -->
        <mat-option *ngFor="let area of opAreas" [disabled]="notAvailable(area.commandId)" [value]="area.opAreaId"> {{area.remarks}} {{area.opArea}} ({{area.commandName}})</mat-option>
    </mat-select>
    <button mat-icon-button (click)="allOpAreas($event)" matSuffix title="Select all available OpAreas">
        <mat-icon class="color-green">sentiment_very_satisfied</mat-icon>
    </button>
    <button mat-icon-button matSuffix title="Availability based on selected Command(s)"><mat-icon class="color-blue">help</mat-icon></button>
   </mat-form-field>
  <p>
    Port(s) {{currentUserABACPrefs?.port_id? currentUserABACPrefs?.port_id?.length : 0}} of {{ports?.length}} selected.
  </p>
  <mat-form-field>
    <mat-select [(ngModel)]="currentUserABACPrefs.port_id" multiple name="port">
        <!-- <mat-option *ngFor="let port of ports | commandFilter : currentUserABACPrefs.command_id" [value]="port.portId"> {{port.name}}</mat-option> -->
        <mat-option *ngFor="let port of ports"  [disabled]="notAvailable(port.commandId)" [value]="port.portId"> {{port.name}} ({{port.commandName}})</mat-option>
      </mat-select>
    <button mat-icon-button (click)="allPorts($event)" matSuffix title="Select all available Ports">
        <mat-icon class="color-green">sentiment_very_satisfied</mat-icon>
    </button>
    <button mat-icon-button matSuffix title="Availability based on selected Command(s)"><mat-icon class="color-blue">help</mat-icon></button>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancel()">Cancel</button>
  <button mat-button (click)="reset()">Reset</button>
  <button mat-button (click)="update()" cdkFocusInitial [disabled]="!currentUserABACPrefs.command_id.length">Update</button>
</div>
