import { ServicesApiService } from '@services/api-services/services-api.service';
import { Injectable } from '@angular/core';
import { JsonConverter } from '@services/global/json-converter';
import { Observable, empty } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class ServicesService {
    private jsonConvert: JsonConverter;

    constructor(private servicesApiService: ServicesApiService) {
        this.jsonConvert = new JsonConverter();
    }

    // ask the api server to ask the microservice for its status
    // this will return some json
    public getStatus(name: string, type: string): Observable<any> {
        return this.servicesApiService.getStatus(name, type)
        .pipe(
          map((data) => {
              return data;
          })
        );
    }

    public getLogFiles(name: string, type: string): Observable<any> {
      return this.servicesApiService.getLogFiles(name, type)
      .pipe(
        map((data) => {
            return data;
        })
      );
    }

    public getLogFile(name: string, type: string, filename: string): Observable<any> {
      return this.servicesApiService.getLogFile(name, type, filename)
      .pipe(
        map((data) => {
            return data;
        })
      );
    }

    // pause the service
    public suspend(name: string, type: string): Observable<any> {
      return this.servicesApiService.suspend(name, type)
      .pipe(
        map((data) => {
            return data;
        })
      );
    }

    // resume the service
    public resume(name: string, type: string): Observable<any> {
      return this.servicesApiService.resume(name, type)
      .pipe(
        map((data) => {
            return data;
        })
      );
    }
    
  }
