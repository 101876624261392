import { Injectable } from '@angular/core';
import { UserApiService } from '../api-services/user-api.service';
import { JsonConverter } from '../global/json-converter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '@models/user.model';
import { AppInitService } from '../../../../config/init.service';
import { UserPki } from '@models/user-pki.model';

@Injectable()
export class UserService {
    private jsonConvert: JsonConverter;

    constructor(private userApiService: UserApiService, public initService: AppInitService) {
        this.jsonConvert = new JsonConverter();
    }

    public getAll(): Observable<User[]> {
        return this.userApiService.getAllUsers()
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, User);
                })
            );
    }

    public getUserByUserId(userId): Observable<User> {
        return this.userApiService.getUserByUserId(userId)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, User);
                })
            );
    }

    public update(user: User): Observable<User> {
        return this.userApiService.updateUser(this.jsonConvert.serializeObject(user))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, User);
                })
            );
    }

    public changePassword(user: User): Observable<User> {
        return this.userApiService.changePassword(this.jsonConvert.serializeObject(user))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, User);
                })
            );
    }

    public create(user: User): Observable<User> {
        return this.userApiService.createUser(this.jsonConvert.serializeObject(user))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, User);
                })
            );
    }

    public delete(user: User): Observable<User> {
        return this.userApiService.deleteUser(this.jsonConvert.serializeObject(user))
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    public getAllWithPkis(): Observable<User[]> {
        return this.userApiService.getAllUsersWithPki()
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, User);
                })
            );
    }

    public addPki(user: User, distinguishedName: string): Observable<User> {
        return this.userApiService.addUserPki(this.jsonConvert.serializeObject(user), distinguishedName)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, User);
                })
            );
    }

    public deletePki(userPki: UserPki): Observable<UserPki> {
        console.log(this.jsonConvert.serializeObject(userPki))
        return this.userApiService.deleteUserPki(userPki)
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }
}