import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter } from '../services/global/date-converter';
import { Command } from '@models/command.model'

@JsonObject('pointOfContact')
export class pointOfContact {
  @JsonProperty('poc_id', String, true)
  pocId: string = undefined;

  @JsonProperty('phone', String, true)
  phone: string = undefined;

  @JsonProperty('command_id', String, true)
  commandId: string = undefined;

  @JsonProperty('contact_name', String, true)
  contactName: string = undefined;

  @JsonProperty('contact_position', String, true)
  contactPosition: string = undefined;

  @JsonProperty('email', String, true)
  email: string = undefined;

  @JsonProperty('link', String, true)
  link: string = undefined;

  @JsonProperty('logo_location', String, true)
  logoLocation: undefined;

  @JsonProperty('support_hours', String, true)
  supportHours: string = undefined;

  @JsonProperty('supporting_commands', String, true)
  supportingCommands: string = undefined;

  @JsonProperty('command', Command, true)
  command: Command = undefined;

  @JsonProperty('command_name', String, true)
  commandName: string = undefined;

  @JsonProperty('created_by', String, true)
  createdBy: string = undefined;

  @JsonProperty('create_ts', DateConverter, true)
  dateCreated: Date = undefined;

  static lookupName(): string {
    return 'poc';
  }
}
