import {JsonObject, JsonProperty, Any} from 'json2typescript';
import { DateConverter } from '../services/global/date-converter';
import { Permission } from '@models/permission.model';
import { Unit } from '@models/unit.model'
import { User } from './user.model';

@JsonObject('CPage')
export class CPage {
  @JsonProperty('cpage_id', String, true)
  cpageId: string = undefined;
  @JsonProperty('doc_name', String, true)
  docName: string = undefined;
  @JsonProperty('doc_type', String, true)
  docType: string = undefined;
  @JsonProperty('doc_size', String, true)
  docSize: Number = undefined;
  @JsonProperty('link', String, true)
  link: string = undefined;
  @JsonProperty('first_name', String, true)
  firstName: string = undefined;
  @JsonProperty('last_name', String, true)
  lastName: string = undefined;
  @JsonProperty('email', String, true)
  email: string = undefined;
  @JsonProperty('comments', String, true)
  comments: string = undefined;
  @JsonProperty('classification', String, true)
  classification: string = undefined;
  @JsonProperty('unit_id', String, true)
  unitId: string = undefined;
  @JsonProperty('unit', Unit, true)
  unit: Unit = undefined;
  @JsonProperty('user', User, true)
  user: User = undefined;
  @JsonProperty('ver_code', String, true)
  verCode: string = undefined;
  @JsonProperty('verified_ind', String, true)
  verInd: string = undefined;
  @JsonProperty('image', Object, true)
  image: object = undefined;
  @JsonProperty('create_ts', DateConverter, true)
  dateCreated: Date = undefined;
  @JsonProperty('permission_id', String, true)
  permissions: string = undefined;
  @JsonProperty('permission', Permission, true)
  permission: Permission = undefined;
  @JsonProperty('created_by', String, true)
  createdBy: string = undefined;

}
