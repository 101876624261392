import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FomPageBaseComponent } from '@fom-module/base-classes/fom-page-base.component';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UtilityService } from '@services/utility.service';
import { AppInitService } from '../../../../config/init.service';

@Component({
  selector: 'app-grafana',
  templateUrl: './grafana.component.html',
  styleUrls: ['./grafana.component.css']
})
export class GrafanaComponent extends FomPageBaseComponent implements OnInit {

    grafanaDashboardPublicURL: SafeResourceUrl;

  constructor(
    public utilityService: UtilityService,
    public snackBar: MatSnackBar,
    public messageBusService: MessageBusService,
    public route: Router,
    public initService: AppInitService,
    private sanitizer: DomSanitizer,) {
    super(messageBusService, route);
  }

  async ngOnInit() {
    let url = this.initService.getConfig().grafanaDashboardPublicURL || '';
    this.grafanaDashboardPublicURL = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
