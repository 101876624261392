export class ParserRawMessage {
    message: string = undefined;
    ctc: string = undefined
    xposes: string[] = []
    tracks: any[] = []
    
    static parse(data: string) {
        let rawMessage = new ParserRawMessage()
        const lines = data.split('\n')
        const index = lines.findIndex(x=>x.startsWith('MSGID'))
        rawMessage.message = lines[index]
        rawMessage.ctc = lines[index+1]
        rawMessage.xposes = [].concat(lines.slice(index+2)).filter(x=>x != 'ENDAT')
        let items = []
        rawMessage.xposes.forEach((xpos,index)=>{
            const fields = xpos.split('/') 
            let track = new ParserTrack()
            track.type = fields[0]
            track.decimal = fields[1]
            track.date1 = fields[2]
            track.ll = fields[3]
            track.source = fields[4]
            track.track = fields[5]
            track.track2 = fields[6]
            track.track3 = fields[7]
            track.source2 = fields[11]
            track.source3 = fields[13]
            items.push(track)
        })
        rawMessage.tracks = items
        return rawMessage
    }
}
export class ParserTrack {
    type: string = '';
    decimal: string = '';
    date1: string = '';
    ll: string = '';
    source: string = '';
    track: string = '';
    track2: string = '';
    track3: string = '';
    source2: string = '';
    source3: string = '';
    lat: string = '';
    lon: string = '';
}




