import { JsonObject, JsonProperty } from 'json2typescript';
import { Command } from '@models/command.model';
import { User } from './user.model';

@JsonObject('CommandHierarchy')
export class CommandHierarchy {
  @JsonProperty('command_hierarchy_id', String, true)
  commandHierarchyId: string = undefined;
  @JsonProperty('tree_object', String, true)
  treeObject: string = undefined;
  @JsonProperty('version_num', Number, true)
  versionNum: number = undefined;
  @JsonProperty('version_ts', String, true)
  versionTs: string = undefined;
  @JsonProperty('created_by', String, true)
  createdBy: string = undefined;

  // Cache for map of JFMCC IDs to subordinate command IDs.
  map: Object = undefined;

  static lookupName(): string {
    return 'command-hierarchy';
  }

  // Parses the Hierarchy Tree to create a map of JFMCCs to commands that fall under them. Caches the value on
  // the instance and will return cached map if already determined.
  public getJFMCCMap(): Object {
    if (this.map != null) {
      return this.map;
    } else {
      const parsed = JSON.parse(this.treeObject);
      this.map = {};

      parsed.map(unified => {
        const jfmccs = unified.children.map(jfmcc => {
          return {
            commandId: jfmcc['commandId'],
            subordinates: jfmcc['children'].map(command => command['commandId'])
          };
        });

        jfmccs.forEach(jfmcc => {
          if (this.map[jfmcc.commandId] == null) {
            this.map[jfmcc.commandId] = jfmcc.subordinates;
          } else {
            this.map[jfmcc.commandId] = [...this.map[jfmcc.commandId], ...jfmcc.subordinates].filter((id, index, self) => {
              return self.indexOf(id) === index;
            });
          }
        });
      });

      return this.map;
    }
  }

  // Returns all JFMCC IDs from the command hierarchy
  public getJFMCCs(): string[] {
    const jfmccCommands = this.getJFMCCMap();

    return Object.keys(jfmccCommands);
  }

  // Returns the set of JFMCC Command IDs that the provided command supports.
  public getJFMCCIds(command: Command): string[] {
    const jfmccCommands = this.getJFMCCMap();
    const result = [];

    for (const [jfmccId, commands] of Object.entries(jfmccCommands)) {
      if (jfmccId === command.commandId) {
        result.push(jfmccId);
      } else {
        if (commands.indexOf(command.commandId) >= 0) {
          result.push(jfmccId);
        }
      }
    }
    return result;
  }

  // Returns the set of supporting Command IDs for a JFMCC command.
  public getJFMCCSupportingCommandIds(jfmcc: Command): string[] {
    const jfmccCommandsMap = this.getJFMCCMap();

    if (jfmccCommandsMap[jfmcc.commandId] != null) {
      return [...jfmccCommandsMap[jfmcc.commandId]];
    } else {
      return [];
    }
  }

  // Returns the set of Command IDs a user supports based on their JFMCC(s). Commands not part of the
  // command hierarchy will not be returned.
  public getSupportingCommandIds(user: User): string[] {
    const jfmccCommandsMap = this.getJFMCCMap();
    const jfmccs = this.getJFMCCIds(user.command);
    const commands = [...jfmccs];

    jfmccs.forEach(jfmcc => {
      commands.push(...jfmccCommandsMap[jfmcc]);
    });

    // Ensure no duplicates.
    return commands.filter((id, index, self) => {
      return self.indexOf(id) === index;
    });
  }
}
