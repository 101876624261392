import { Pipe, PipeTransform } from '@angular/core';
import { Unit } from '@models/unit.model'

@Pipe({
  name: 'unitNamePipe'
})
// simple pipe to use in html {{}} evaluations
export class UnitNamePipe implements PipeTransform {

  transform(value: any): any {
    if (!value || !(value instanceof Unit)) {
      return ''
    }
    return value.fullName()
    //return null;
  }

}
