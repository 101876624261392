<div class="same-line">
    <span style="display:flex;" class="ml-10 half-width">
        <span class="vertical-align-center" style="font-size: 10px">
            <mat-chip-list #filterChipGrid aria-label="Filter Selection" *ngIf="searchFilters.length > 0">
                <!-- {{filter.label}}-->
                <span *ngFor="let filter of searchFilters">
                <mat-chip class="mat-chip-small" (click)="removeFilter(filter)"
                    [attr.aria-label]="'remove ' + filter.value" *ngIf="filter?.value != null">
                    {{filter.value}}
                    <mat-icon class="material-icon-small">close</mat-icon>
                </mat-chip>
                </span>
            </mat-chip-list>
        </span>
        <span *ngFor="let group of facets;trackBy:childFacetTrackBy" class="vertical-align-center"
            style="font-size: 10px">
            <mat-chip-list #facetChipGrid aria-label="Filter Selection" *ngIf="group.selectedFacets.length > 0">
              <!--   {{group.groupName}}: -->
                <mat-chip class="mat-chip-small" *ngFor="let facet of group.selectedFacets"
                    (click)="removeFacet(facet, group)" [attr.aria-label]="'remove ' + facet.label">
                    {{facet.label}}
                    <mat-icon class="material-icon-small">close</mat-icon>
                </mat-chip>
            </mat-chip-list>
        </span>
        <span class="vertical-align-center" style="font-size: 10px">
            <ng-container  *ngFor="let filter of dateFilters">
            <mat-chip class="mat-chip-small" *ngIf="filter.start != null"
                (click)="removeDateFilter(filter)" [attr.aria-label]="'remove ' + filter.label">
                {{filter.label}} {{filter.start}} {{filter.end}}
                <mat-icon class="material-icon-small">close</mat-icon>
            </mat-chip>
        </ng-container>
        </span>
    </span>
    <mat-paginator #paginator *ngIf="showPaginator" [pageSizeOptions]="[20, 50, 100, 200, 500]"
        [pageSize]="defaultPageSize" [length]="resultsCount" showFirstLastButtons (page)="handlePageEvent($event)">
    </mat-paginator>
</div>
<mat-drawer-container>
    <mat-drawer #filterDrawer class="pl-5" mode="over" (closedStart)="updatedSelectedFilters()">
        <form name="searchForm" ngForm>
            <div class="full-width pull-right vertical-align-center">
                <button mat-icon-button (click)="filterDrawer.toggle()" class="button-medium material-icon-small mr-5"
                    matTooltip="Close filters" onfocus="this.blur()">
                    <mat-icon>chevron_left</mat-icon>
                </button>
            </div>
            <mat-form-field *ngFor="let filter of dateFilters" class="pl-5">
                <mat-label>{{filter.startLabel}}</mat-label>
                <input matInput [ngxMatDatetimePicker]="startPicker" placeholder="Start date" [(ngModel)]="filter.start" name="start">
                <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #startPicker></ngx-mat-datetime-picker>
            </mat-form-field>
            <mat-form-field *ngFor="let filter of dateFilters" class="pl-5">
                <mat-label>{{filter.endLabel}}</mat-label>
                <input matInput [ngxMatDatetimePicker]="endPicker" placeholder="End date" [(ngModel)]="filter.end" name="end">
                <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #endPicker></ngx-mat-datetime-picker>
            </mat-form-field>
            <mat-form-field *ngFor="let filter of filters" class="pl-5">
                <input matInput type="text" [(ngModel)]="filter.value" name="{{filter.property}}Filter" autocomplete="off" placeholder="{{filter.label}}">
                <button mat-icon-button (click)="filter.value = null" matSuffix>
                    <mat-icon class="material-icon-small">close</mat-icon>
                </button>
            </mat-form-field>            
            <mat-selection-list class="p-0 pb-10" *ngFor="let group of facets;trackBy:childFacetTrackBy"
                name="facetGroup{{group.property}}" [(ngModel)]="group.selectedFacets"
                (selectionChange)="updatedSelectedFacets()">
                <div mat-subheader class="facet-group-header">{{group.groupName}}</div>
                <div id="facetList{{group.property}}">
                    <mat-list-option checkboxPosition="before" [value]="facet"
                        [selected]="(group.selectedFacets | filter:facet.label:'label').length > 0"
                        *ngFor="let facet of group.facetList | sort: 'label' ;let i = index">
                        {{facet.label}}
                    </mat-list-option>
                </div>
            </mat-selection-list>
        </form>
    </mat-drawer>
    <mat-drawer-content class="same-line align-items-top">
        <div>
            <button mat-icon-button (click)="filterDrawer.toggle()" matTooltip="Refine results"
                class="filter-button mat-drawer-content" onclick="this.blur()">
                <mat-icon class="material-icon-small">filter_alt</mat-icon>

                <!-- <span class="material-icons-outlined">
                    filter_alt
                </span> -->
            </button>
        </div>
        <div class="scroll-table full-width">
        <ng-content></ng-content>
        </div>
    </mat-drawer-content>
</mat-drawer-container>