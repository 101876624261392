<app-classification-bar class="top-modal-classification-bar"></app-classification-bar>
<app-fdtm-modal-header [headerString]="headerString"></app-fdtm-modal-header>
<div mat-dialog-content>
<form [formGroup]="editForm">
  <mat-form-field class="input-full-width">
    <input matInput (keyup)="checkDups($event, 'shipClass')" placeholder="Ship Class" required  autocomplete="off"
      formControlName="shipClass">
    <mat-error *ngIf="editForm?.controls?.shipClass?.hasError('duplicate entry')">duplicate entry</mat-error>
  </mat-form-field>
  <br>
  <mat-form-field class="input-full-width">
    <input matInput (keyup)="checkDups($event, 'shipClassDescription')" placeholder="Ship Description" required  autocomplete="off"
      formControlName="shipClassDescription">
    <mat-error *ngIf="editForm?.controls?.shipClassDescription?.hasError('duplicate entry')">duplicate entry</mat-error>
  </mat-form-field>
  <br>
  <mat-form-field class="input-full-width">
    <mat-select placeholder="Command" required formControlName="commandId">
      <mat-option *ngFor="let option of commandOptions" [value]="option.commandId">{{option.name}}</mat-option>
    </mat-select>
  </mat-form-field>
  <br>
  <div mat-dialog-actions>
  <button *ngIf="!isDelete" [disabled]="!editForm.valid" mat-raised-button color="primary"
    (click)="handleSubmit()">Submit</button>
  <button *ngIf="isDelete" mat-raised-button color="warn" (click)="handleDelete()">Delete</button>
  <button mat-raised-button (click)="handleCancel()">Cancel</button>
  </div>
</form>
</div>
<app-classification-bar class="bottom-modal-classification-bar"></app-classification-bar>