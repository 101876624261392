<app-classification-bar class="top-modal-classification-bar"></app-classification-bar>

<app-fdtm-modal-header [headerString]="headerString"></app-fdtm-modal-header>
<mat-card-content>
    <h2>Bulk Delete</h2>
    <form [formGroup]="bulkDeleteForm">
        <br>
            Doing this will delete all items in the filtered view.
        <br>
        <p>Are you sure you want to delete {{numberEntries}} entries?</p>
    </form>
</mat-card-content>

<div class="modal-buttons">
  <button mat-flat-button color="warn" (click)="confirmDelete()">Confirm Delete</button>
  <button mat-flat-button (click)="cancel()">Cancel</button>
</div>
<br>
<app-classification-bar class="bottom-modal-classification-bar"></app-classification-bar>
