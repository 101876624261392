<app-classification-bar class="top-modal-classification-bar"></app-classification-bar>
<app-fdtm-modal-header [headerString]="'Unit Details'"></app-fdtm-modal-header>

<div mat-dialog-content>
    <div *ngIf="unit">
        <div class="section">
            <h3>Unit Details</h3>
            <div class="section-row">

                <div class="section-column">
                    <div>
                        <span class="label">Command: </span>
                        <span>{{ unit.port?.command?.name }}</span>
                    </div>
                    <div>
                        <span class="label">Name: </span>
                        <span>{{ unit.fullName() }}</span>
                    </div>
                    <div>
                        <span class="label">Threat: </span>
                        <span>{{ unit.threat?.threatName }}</span>
                    </div>
                    <div>
                        <span class="label">Category: </span>
                        <span>{{ unit.category?.category }}</span>
                    </div>
                </div>

                <div class="section-column">
                    <div>
                        <span class="label">SCONUM: </span>
                        <span>{{ unit.sconum }}</span>
                    </div>
                    <div>
                        <span class="label">MIDB ID: </span>
                        <span>{{ unit.midb }}</span>
                    </div>
                    <div>
                        <span class="label">Ship Type Rank: </span>
                        <span>{{ unit.ship?.legacyRank }}</span>
                    </div>
                    <div>
                        <span class="label">Ship Type: </span>
                        <span>{{unit.ship?.shipClass?.shipClass}} {{ unit.ship?.vesselType?.vesselType }}</span>
                    </div>
                    <div *ngIf="unit.borrowerCommand">
                        <span class="label" matTooltip="Command that has been temporarily granted permission to update this unit's track">Delegated Command: </span>
                        <span>{{ unit.borrowerCommand.name }}</span>
                    </div>
                </div>
            </div>
        </div>

        <mat-divider></mat-divider>

        <div class="section">
            <h3>Country Details</h3>
            <div class="section-row">
                <div class="section-column">
                    <div>
                        <span class="label">Country: </span>
                        <span><img [src]="getFlag(unit.country)" height="16">&nbsp;{{ unit.country?.name }}</span>
                    </div>
                    <div>
                        <span class="label">Fleet: </span>
                        <span>{{ unit.fleet?.fleetCode }}</span>
                    </div>
                </div>

                <div class="section-column">
                    <div>
                        <span class="label">Homeport: </span>
                        <span>{{ unit.port?.name }}</span>
                    </div>
                </div>
            </div>
        </div>

        <mat-divider></mat-divider>

        <div class="section">
            <h3>Track Information</h3>
            <div class="section-row">
                <div class="section-column">
                    <div>
                        <span class="label">Last OpArea: </span>
                        <span>{{ lastLocation?.opArea?.opArea || 'None' }}</span>
                    </div>
                    <div>
                        <span class="label">Last Location Time: </span>
                        <span>{{ (lastLocation?.locTime | zulu1Pipe ) || 'Never' }}</span>
                    </div>
                    <div>
                        <span class="label">Last U/W DTG: </span>
                        <span>{{ (lastLocation?.dtgImaged | zulu1Pipe) || 'Never' }}</span>
                    </div>
                </div>

                <div class="section-column">
                    <div>
                        <span class="label">Last Updated By: </span>
                        <span>{{ lastLocation?.creator?.username }}</span>
                    </div>
                    <div>
                        <span class="label">Last Updated At: </span>
                        <span>{{ (lastLocation?.createTs | zulu1Pipe) || 'Never' }}</span>
                    </div>
                </div>
            </div>
        </div>

        <mat-divider></mat-divider>

        <div class="section">
            <h3>Unit Metadata</h3>
            <div class="section-row">
                <div class="section-column">
                    <div>
                        <span class="label">Created By: </span>
                        <span>{{ unit.creator?.username }}</span>
                    </div>
                    <div>
                        <span class="label">Created At: </span>
                        <span>{{ unit.dateCreated | zulu1Pipe }}</span>
                    </div>
                </div>

                <div class="section-column">
                    <div>
                        <span class="label">Updated By: </span>
                        <span>{{ unit.updater?.username }}</span>
                    </div>
                    <div>
                        <span class="label">Updated At: </span>
                        <span>{{ unit.updateTs | zulu1Pipe }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div mat-dialog-actions>
    <button mat-raised-button matTooltip="Click to Update this Unit's Location" (click)="navigateUnitUpdate()">
        <mat-icon>add_location</mat-icon> Update Location
    </button>
    <button mat-raised-button *ngIf="canEdit()" matTooltip="Click to Edit this Unit" (click)="edit()">
        <mat-icon>edit</mat-icon> Edit Unit
    </button>
    <button mat-raised-button *ngIf="canEdit()" color="warn" matTooltip="Click to Delete this Unit" (click)="delete()">
        <mat-icon>delete</mat-icon> Delete
    </button>
    <button mat-raised-button (click)="close()">Close</button>
</div>

<app-classification-bar class="bottom-modal-classification-bar"></app-classification-bar>