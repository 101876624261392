import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from './api.config';

@Injectable()
export class ServicesApiService {
    SERVER_URL;

    constructor(
        private http: HttpClient, apiConfig: APIConfig) {
        this.SERVER_URL = apiConfig.TARGET_URL;
    }

    public getStatus(name: string, type: string): Observable<any> {
        return this.http.get(
            `${this.SERVER_URL}/services/status?name=${name}&type=${type}`);
    }
    public getLogFiles(name: string, type: string): Observable<any> {
        return this.http.get(
            `${this.SERVER_URL}/services/logFiles?name=${name}&type=${type}`);
    }

    public getLogFile(name: string, type: string, filename: string): Observable<any> {
        return this.http.get(
            `${this.SERVER_URL}/services/logFile?name=${name}&type=${type}&filename=${filename}`,
            {responseType: 'blob'});
    }

    public suspend(name: string, type: string): Observable<any> {
        return this.http.put(
            `${this.SERVER_URL}/services/suspend?name=${name}&type=${type}`, {});
    }
    public resume(name: string, type: string): Observable<any> {
        return this.http.put(
            `${this.SERVER_URL}/services/resume?name=${name}&type=${type}`, {});
    }
}
