<div class="admin-work-area">
    <div fxLayout fxLayoutAlign="center center" class="simple-grid-title">
        <span class="grid-title">{{initService.getConfig().titlePrefix}} FDT-M FDO Mapping</span>
        <!-- Add Unit Floating Action Button -->
        <button mat-mini-fab class="add-button" (click)="add()" *ngIf="selectedCommandId">
          <img class="light-icon" src="./assets/icons/ic_add_white_24px.svg" matTooltip="Add FDO Entry" />
        </button>
        <mat-icon matTooltip="These entries designate how the classification marking for an Int Source of inbound data (FROM) will be reclassified (TO) on FDT-M generated products.">help</mat-icon>
    </div>
    <div fxLayout fxLayoutAlign="center center">
      <mat-form-field class="groupSelect">
        <mat-select [(ngModel)]="selectedCommandId" placeholder="Filter Command" (selectionChange)="onCommandChange($event)">
          <mat-option [value]="'All'">All</mat-option>
          <mat-option *ngFor="let command of commands" [value]="command.commandId">
            {{command.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
      <div class="mat-elevation-z8" *ngIf="entities.length > 0">
      <!-- Paginator -->
      <mat-paginator [pageSizeOptions]="[10, 20, 50, 100, 1000]" [pageSize]="20" [length]="dataSource?.data?.length" showFirstLastButtons></mat-paginator>
      <table mat-table [dataSource]="dataSource" matSort matSortStart="desc" (matSortChange)="sortData($event)">

            <!-- Edit Action Column -->
            <ng-container matColumnDef="edit">
              <th mat-header-cell *matHeaderCellDef>
                  Edit
              </th>
              <td mat-cell *matCellDef="let row">
                <button mat-button class="delete-button" (click)="$event.stopPropagation()" (click)="edit(row)">
                  <mat-icon>edit</mat-icon>
                </button>
              </td>
            </ng-container>

            <!-- Data Columns -->
            <ng-container matColumnDef="command.name">
              <th mat-header-cell mat-sort-header *matHeaderCellDef> Command </th>
              <td mat-cell *matCellDef="let element"> {{element.commandId ? element.command.name : null}} </td>
            </ng-container>

            <ng-container matColumnDef="source.sourceName">
              <th mat-header-cell mat-sort-header *matHeaderCellDef> Int Source </th>
              <td mat-cell *matCellDef="let element"> {{element.source ? element.source.sourceName : null}} </td>
            </ng-container>

            <ng-container matColumnDef="srcClassificationString">
              <th mat-header-cell mat-sort-header *matHeaderCellDef> From Classification </th>
              <td mat-cell *matCellDef="let element"> {{element.srcClassificationString}} </td>
            </ng-container>

            <ng-container matColumnDef="tgtClassificationString">
              <th mat-header-cell mat-sort-header *matHeaderCellDef> To Classification </th>
              <td mat-cell *matCellDef="let element"> {{element.tgtClassificationString}} </td>
            </ng-container>

            <!-- Delete Action Column -->
            <ng-container matColumnDef="delete">
              <th mat-header-cell *matHeaderCellDef>
                  Delete
              </th>
              <td mat-cell *matCellDef="let row">
                <button mat-button class="delete-button" (click)="$event.stopPropagation()" (click)="handleDelete(row)">
                  <img src="./assets/icons/ic_delete_gray_24px.svg" />
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr class="hyper" matTooltip="Edit FDO Mapping" matTooltipPosition="before" mat-row *matRowDef="let row; columns: displayedColumns;" (click)="edit(row)"></tr>
          </table>


    </div>
</div>
