<div class="admin-work-area">
  <div fxLayout fxLayoutAlign="center center">
    <span class="grid-title">{{initService.getConfig().titlePrefix}} FDT-M Lookup Maintenance</span>
  </div>
  <div fxLayout fxLayoutAlign="center center">
    <mat-form-field class="selectors">
      <mat-select [(ngModel)]="lookup" placeholder="Select Lookup" (selectionChange)="lookupChanged($event)">
        <mat-option *ngFor="let lookup of lookups" [value]="lookup">
          {{lookup.display}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="selectors">
      <mat-select [(ngModel)]="owner" placeholder="Select Owner" (selectionChange)="selectionChanged($event)">
        <mat-option *ngFor="let owner of owners" [value]="owner">
          {{owner.ownerName}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <span class="selectors" matTooltip="{{saveTooltip()}}">
      <button [disabled]="!isDirty || filterValue.length > 0" mat-raised-button color="primary" (click)="save()">
        Save
      </button>
    </span>
    <span class="selectors">
      <button [disabled]="!isDirty" matTooltip="Clear changes and refresh the screen" mat-raised-button
        (click)="cancel()">Cancel</button>
    </span>
    <mat-form-field *ngIf="lookup && owner" class="selectors">
      <mat-select placeholder="Preview Changes" [(ngModel)]="previewItem">
        <!-- <mat-select-trigger *ngIf="previewItem && (lookup.flagProperty == lookup.keyProperty)">
                    <img  [src]="getFlag(previewItem[lookup.keyProperty])" height="16">&nbsp;
                    {{previewItem[lookup.keyProperty]}}
                </mat-select-trigger> -->
        <mat-option *ngFor="let item of dataSource?.data" [disabled]="item.isDisabled" [value]="item">
          <img *ngIf="lookup.flagProperty" [src]="getFlag(item[lookup.flagProperty])" height="16">&nbsp;
          {{item[lookup.keyProperty]}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button *ngIf="lookup" mat-mini-fab class="add-button" (click)="add()">
      <img class="light-icon" src="./assets/icons/ic_add_white_24px.svg" matTooltip="Add Lookup" />
    </button>
  </div>
  <div *ngIf="lookup && owner && dataSource" class="mat-elevation-z8">
    <div fxLayout fxLayoutAlign="space-evenly center">
      <mat-form-field fxFlex="30%">
        <input matInput [(ngModel)]="filterValue" type="text" (keyup)="doFilter($event.target.value)"
          placeholder="Filter by Lookup Properties (if Filtered, Save is disabled)">
      </mat-form-field>
      <mat-slide-toggle [(ngModel)]="this.showDisabled" (change)="toggleDisabled($event)"
        matTooltip="Show/Hide Disabled Values" style="margin-right: 10px;" color="primary">
        {{ this.showDisabled ? 'Showing Disabled' : 'Hiding Disabled' }}
      </mat-slide-toggle>
    </div>
    <div class="white-bg" fxLayout fxLayoutAlign="space-between left">
      <button class="export-button" mat-raised-button (click)="getLookupExcel(this.lookup.tableName)"><mat-icon>file_download</mat-icon>Export to Excel</button>
      <mat-paginator [pageSizeOptions]="[100, 1000]" [pageSize]="100" [length]="dataSource?.data?.length"
        showFirstLastButtons></mat-paginator>
    </div>
    <table mat-table [dataSource]="dataSource" cdkDropList #dropTable class="lookup-table"
      [cdkDropListData]="dataSource?.data" (cdkDropListDropped)="drop($event)" matSort
      (matSortChange)="sortData($event)">
      <ng-container matColumnDef="edit">
        <th style="width:40px" mat-header-cell *matHeaderCellDef> Edit </th>
        <td class="edit" mat-cell *matCellDef="let element">
          <mat-icon *ngIf="user.canEditLookup(element.commandId)" class="hyper" matTooltip="Click to edit"
            matTooltipPosition="above" (click)="edit(element)">
            edit
          </mat-icon>
          <mat-icon *ngIf="!user.canEditLookup(element.commandId)" matTooltip="Only owner may edit this lookup"
            matTooltipPosition="above">
            not_interested
          </mat-icon>
        </td>
      </ng-container>
      <ng-container matColumnDef="isDisabled">
        <th mat-header-cell *matHeaderCellDef> Disable </th>
        <td mat-cell *matCellDef="let element" matTooltip="Enable / Disable for owner" matTooltipPosition="above">
          <mat-checkbox (click)="isDirty = true;$event.stopPropagation()" [(ngModel)]="element.isDisabled"
            [checked]="element.isDisabled">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- Data Columns -->
      <ng-container *ngFor="let attribute of lookup.attributes" matColumnDef="{{attribute.property}}">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!attribute.sortable">
          {{ attribute.columnName }}
        </th>
        <td mat-cell *matCellDef="let element">
          <img *ngIf="attribute.showFlag" [src]="getFlag(element[attribute.property])" height="16">&nbsp;
          {{ element[attribute.property] }}
        </td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th style="width:40px" mat-header-cell *matHeaderCellDef> Delete </th>
        <td class="delete" mat-cell *matCellDef="let element">
          <mat-icon *ngIf="user.canEditLookup(element.commandId)" class="hyper" matTooltip="Delete lookup"
            matTooltipPosition="above" (click)="delete(element)">
            delete
          </mat-icon>
          <mat-icon *ngIf="!user.canEditLookup(element.commandId)" matTooltip="Only owner may delete this lookup"
            matTooltipPosition="above">
            not_interested
          </mat-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="allColumns; sticky: true"></tr>
      <tr cdkDrag [cdkDragData]="row" class="hyper" matTooltip="Drag to new position" matTooltipPosition="before"
        [matTooltipDisabled]="dragDisabled" class="lookup-item" mat-row
        *matRowDef="let row;index as i; columns: allColumns;">
        <span class="moving" *cdkDragPreview>Drag-n-Drop {{row[lookup.keyProperty]}}</span>
      </tr>
    </table>

  </div>

</div>