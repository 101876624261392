import { Component, OnInit } from '@angular/core';
import { CountryService } from '@services/data-services/country.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { EditModalBaseComponent } from '@fom-module/base-classes/edit-modal-base.component';
import { LookUpService } from '@services/data-services/lookup.service';
import { Command } from '@models/command.model';
import { MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { CommandHierarchyService } from '@services/data-services/command-hierarchy.service';
import { User } from '@models/user.model';
import { CurrentUserService } from '@services/current-user-service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommandHierarchy } from '@models/command-hierarchy.model';

@Component({
  selector: 'app-edit-country-modal',
  templateUrl: './edit-country-modal.component.html',
  styleUrls: ['./edit-country-modal.component.css']
})
export class EditCountryModalComponent extends EditModalBaseComponent implements OnInit {
  // Dropdown options
  commandOptions: Command[];
  user: User;
  editForm: FormGroup;

  commandHierarchy: CommandHierarchy;
  flag: any;
  tmpImg: any;
  currentImage: any;

  constructor(public countryService: CountryService,
    public lookUpService: LookUpService,
    public formBuilder: FormBuilder,
    private commandHierarchyService: CommandHierarchyService,
    public dialogRef: MatDialogRef<EditCountryModalComponent>,
    public messageBusService: MessageBusService,
    private currentUserService: CurrentUserService,
    private matSnack: MatSnackBar) {
    super(countryService, dialogRef);
  }

  ngOnInit() {
    this.headerString = this.isNew ? 'Add Country' :
    this.isDelete ? 'Delete Country' :
    'Edit Country'
    this.user = this.currentUserService.getCurrentUser();
    if (this.isNew) {
      this.entity.commandId = this.user.currentJFMCC ? this.user.currentJFMCC.commandId : this.user.commandId;
    }
    this.editForm = this.formBuilder.group({
      commandId: [{ value: this.entity.commandId, disabled: this.isDelete }],
      name: [{ value: this.entity.name, disabled: this.isDelete }],
      triGraph: [{ value: this.entity.triGraph, disabled: this.isDelete }],
      legacyId: [{ value: this.entity.legacyId, disabled: this.isDelete }]
    });

    Promise.resolve().then(() => {
      this.loadLookups();
    });
    this.flagPreview();
  }

  loadLookups() {
    this.lookUpService.getLookupByType(Command, true).pipe(take(1)).subscribe(result => {
      this.commandHierarchyService.getAll({ sort: 'version_num', limit: 1 }).pipe(take(1)).subscribe(json => {
        if (json && Array.isArray(json) && json.length) {
          this.commandHierarchy = json[0];

          let jfmccs;
          if (this.user.isSystemAdmin()) {
            jfmccs = this.commandHierarchy.getJFMCCs();
          } else {
            jfmccs = this.commandHierarchy.getJFMCCIds(this.user.command);
          }

          this.commandOptions = result.filter(item => {
            return jfmccs.includes(item.commandId);
          });
        } else {
          this.commandOptions = result;
        }
      });
    });
  }

  imageChange(e) {
    this.flag = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(this.flag);
    reader.onload = (_event) => {
      this.tmpImg = reader.result;
      this.currentImage = false;
    };
  }

  // getALogo(){
  //   this.countryService.getFlag(this.entity.coutryId).subscribe( () => {})
  // }

  flagPreview() {
    if (this.entity.flagImagePath === null || this.entity.flagImagePath === '') {
      this.tmpImg = false;
      this.currentImage = false;
    } else {
      this.currentImage = this.countryService.getFlagUrl(this.entity);
      this.tmpImg = false;
    }
  }

  submitFlag(response) {
    const formData = new FormData();
    formData.append('file', this.flag);
    if (this.isNew || this.entity.flagImagePath === null || this.entity.flagImagePath === '') {
      this.countryService.createFlag(formData, response.countryId).subscribe(data => {
        if (data['error']) {
          this.matSnack.open(`${data['message']}`, 'Close');
          this.dialogRef.close();
        } else {
          this.matSnack.open('Image Uploaded!', 'Close');
          this.dialogRef.close(data);
        }
      });
    } else {
      this.countryService.updateFlag(formData, this.entity.countryId).subscribe(data => {
        if (data['error']) {
          this.matSnack.open(`${data['message']}`, 'Close');
          this.dialogRef.close();
        } else {
          this.matSnack.open('Image Uploaded!', 'Close');
          this.dialogRef.close(data);
        }
      });
    }
  }

  submit() {
    // Manual add because form group not mapping to country for some reason
    this.entity.name = this.editForm.value['name'];
    this.entity.triGraph = this.editForm.value['triGraph'];
    if (this.isNew) {
      this.countryService.create(this.entity).subscribe(data => {
        if (data['error']) {
          this.matSnack.open(`${data['message']}`, 'Close');
          this.dialogRef.close();
          return;
        } else {
          // is there a flag? send it up, otherwise close
          if (this.flag) {
            this.matSnack.open('Data Submitted! uploading image...', 'Close');
            this.submitFlag(data);
          }
          else {
            this.matSnack.open('New Country Created', 'Close');
            this.dialogRef.close();
          }
        }
      });
    } else {
      this.countryService.update(this.entity).subscribe(data => {
        if (data['error']) {
          this.matSnack.open(`${data['message']}`, 'Close');
          this.dialogRef.close();
        } else {
          if (this.flag) {
            this.matSnack.open('Data submitted, uploading image...', 'Close');
            this.submitFlag(data);
          }
          else {
            this.matSnack.open('Changes Submitted', 'Close');
            this.dialogRef.close();
          }
        }
      });
    }
  }

  hasFlag() {
    return this.entity.flagImagePath === null || this.entity.flagImagePath === '' ? false : true;
  }

  removeFlag(event) {
    if (confirm(`Are you sure you want to delete the ${this.entity.name} flag?`)) {
      this.countryService.deleteFlag(this.entity.countryId).subscribe(res => {
        if (!res['error']) {
          this.entity.flagImagePath = res.flag_image_path;
          this.tmpImg = false;
          this.currentImage = false;
          this.matSnack.open('Image Removed Successfully', 'Close');
          this.dialogRef.close(res);
        }
      });
    } else {
      event.preventDefault();
    }
  }
}
