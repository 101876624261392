import { JsonObject, JsonProperty, Any } from 'json2typescript';

@JsonObject('Releasability')
export class Releasability {
  @JsonProperty('releasability_id', String, true)
  releasibilityId: string = undefined;
  @JsonProperty('releasability_name', String, true)
  releasabilityName: string = undefined;
  @JsonProperty('nsa_name', String, true)
  nsaName: string = undefined;
  @JsonProperty('two_digit', String, true)
  twoDigit: string = undefined;
  @JsonProperty('source_code', String, true)
  sourceCode: string = undefined;
  @JsonProperty('created_by', String, true)
  createdBy: string = undefined;

  static lookupName(): string {
    return 'disseminations';
  }
}
