import { Component, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { EditModalBaseComponent } from "@fom-module/base-classes/edit-modal-base.component";
import { Activity } from "@models/activity.model";
import { Area } from "@models/area.model";
import { Classification } from "@models/classification.model";
import { Control } from "@models/control.model";
import { Fgi } from "@models/fgi.model";
import { Fleet } from "@models/fleet.model";
import { OtherControl } from "@models/other-control.model";
import { Releasability } from "@models/releasability.model";
import { Source } from "@models/source.model";
import { Unit } from "@models/unit.model";
import { User } from "@models/user.model";
import { ClassificationDescriptionService } from "@services/classification-description.service";
import { CurrentUserService } from "@services/current-user-service";
import { GeoRefService } from "@services/data-services/geo-ref.service";
import { LocationService } from "@services/data-services/location.service";
import { LookUpService } from "@services/data-services/lookup.service";
import { PicklistPrefService } from "@services/data-services/picklist-pref.service";
import { SortingService } from "@services/sorting.service";
import { UtilityService } from "@services/utility.service";
import { Observable, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { AppInitService } from "../../../../config/init.service";
import { Elint } from "@models/elint.model";
import { Elnot } from "@models/elnot.model";

// List of form controls that aren't considered a change to the location. For example, only changing
// rationale is not considered a change to the location.
const IGNORED_CHANGED_ATTRIBUTES = [
    'rationale'
];

// Classification Control names mapped to cached list from entity. Used to determine if
// Classification controls have changed.
const CLASSIFICATION_CONTROLS = {
    sciControls: 'entitySciIds',
    fgiControls: 'entityFgiIds',
    otherControls: 'entityOtherIds',
    disseminations: 'entityDissemIds'
};

@Component({
    selector: 'app-edit-location-modal',
    templateUrl: './edit-location-modal.component.html',
    styleUrls: ['./edit-location-modal.component.css']
})
export class EditLocationModalComponent extends EditModalBaseComponent implements OnInit, OnDestroy {
    entityType = 'Location';

    // arrays to hold the list of ids for each classification control type, so the
    // complicated list of ids doesn't need broken down multiple times.
    entitySciIds: string[] = [];
    entityFgiIds: string[] = [];
    entityOtherIds: string[] = [];
    entityDissemIds: string[] = [];

    user: User;
    allowFutureDates: boolean;
    maxDate: Date;

    // Lookups
    activityCategories: Activity[] = [];
    classifications: Classification[] = [];
    disseminations: Releasability[] = [];
    fgiControls: Fgi[] = [];
    fleets: Fleet[] = [];
    opAreas: Area[] = [];
    otherControls: OtherControl[] = [];
    sciControls: Control[] = [];
    sources: Source[] = [];
    elNots: Elnot[] = []; //ELINT data
    // providerIds: Elint[] = []; //ELINT data
    elints: Elint[] = [];

    //toggle activity writable field
    activityReadOnly: boolean;
    mfiReadOnly: boolean;

    hasChanges = false;
    changeSubscription: Subscription;

    fromParser: boolean;

    constructor(
        private currentUserService: CurrentUserService,
        public dialogRef: MatDialogRef<EditLocationModalComponent>,
        private classDescriptionService: ClassificationDescriptionService,
        private formBuilder: FormBuilder,
        private geoRefService: GeoRefService,
        private initService: AppInitService,
        private locationService: LocationService,
        private lookupService: LookUpService,
        private picklistPrefService: PicklistPrefService,
        private sortingService: SortingService,
        private utilities: UtilityService,
    ) {
        super(locationService, dialogRef);
    }

    ngOnInit() {
        let prefix;
        if (this.isNew) {
            prefix = 'Add';
        } else {
            prefix = this.isDelete ? 'Delete' : 'Edit';
        }
        this.headerString = `${prefix} ${this.entityType} for ${this.entity.finalName}`;
        this.user = this.currentUserService.getCurrentUser();
        this.allowFutureDates = this.initService.getConfig().allowFutureDates;
        this.maxDate = new Date();
        this.activityReadOnly = true;
        this.mfiReadOnly = this.entity.mfiInd;

        Promise.resolve().then(() => {
            this.loadLookups();
        });

        this.initializeForm();
    }

    ngOnDestroy(): void {
        this.changeSubscription.unsubscribe();
    }

    async loadLookups() {
        this.activityCategories = await this.lookupService.getLookupByType(Activity).pipe(take(1)).toPromise();
        this.fleets = await this.lookupService.getLookupByType(Fleet).pipe(take(1)).toPromise();
        this.opAreas = await this.lookupService.getLookupByType(Area).pipe(take(1)).toPromise();
        this.sources = await this.lookupService.getLookupByType(Source).pipe(take(1)).toPromise();
        this.elNots = await this.lookupService.getLookupByType(Elnot).pipe(take(1)).toPromise();

        this.sciControls = await this.lookupService.getLookupByType(Control).pipe(take(1)).toPromise();
        this.fgiControls = await this.lookupService.getLookupByType(Fgi).pipe(take(1)).toPromise();
        this.otherControls = await this.lookupService.getLookupByType(OtherControl).pipe(take(1)).toPromise();
        this.disseminations = await this.lookupService.getLookupByType(Releasability).pipe(take(1)).toPromise();
        this.classifications = await this.lookupService.getLookupByType(Classification).pipe(take(1)).toPromise();
        this.classifications.sort((a, b) => a.sortBy < b.sortBy ? 1 : -1);

        const filter = { owner_id: this.user.unitOwnerId(null) };
        this.picklistPrefService.getAll(filter).pipe(take(1)).subscribe(preferences => {
            this.sortingService.sortList(this.activityCategories, preferences, 'description');
            this.sortingService.sortList(this.fleets, preferences, 'fleetCode');
            this.sortingService.sortList(this.opAreas, preferences, 'opArea');
            this.sortingService.sortList(this.sources, preferences, 'sourceName');
            this.sortingService.sortList(this.elNots, preferences, 'el_not_code');
        });


        this.entitySciIds = this.getSciControls();
        this.entityFgiIds = this.getFgiControls();
        this.entityOtherIds = this.getOtherControls();
        this.entityDissemIds = this.getDisseminations();

        if(this.activityCategories == null){
            this.activityReadOnly = true;
        }else{
            this.activityReadOnly = false;
        }
    }

    initializeForm(): void {
        //check if the track is from parser
        if (this.entity.parser_msg_id == null){
            this.fromParser = false;
        }else{
            this.fromParser = true;
        }

        this.editForm = this.formBuilder.group({
            classificationId: [{ value: this.entity.classificationId, disabled: this.isDelete || this.fromParser }],
            sciControls: [{ value: this.getSciControls(), disabled: this.isDelete || this.fromParser }],
            fgiControls: [{ value: this.getFgiControls(), disabled: this.isDelete || this.fromParser }],
            otherControls: [{ value: this.getOtherControls(), disabled: this.isDelete || this.fromParser }],
            disseminations: [{ value: this.getDisseminations(), disabled: this.isDelete || this.fromParser }],
            fleetId: [{ value: this.entity.fleetId, disabled: this.isDelete || this.fromParser }],
            dtgImaged: [{ value: this.entity.dtgImaged, disabled: this.isDelete || this.fromParser }],
            mfiTs: [{ value: this.entity.mfiTs, disabled: this.isDelete || this.fromParser }],
            latitude: [{ value: this.entity.latitude, disabled: this.isDelete || this.fromParser }, this.utilities.latValidator],
            longitude: [{ value: this.entity.longitude, disabled: this.isDelete || this.fromParser }, this.utilities.lonValidator],
            lastLoc: [{ value: this.entity.lastLoc, disabled: this.isDelete || this.fromParser }],
            lastLocSrc: [{ value: this.entity.lastLocSrc, disabled: this.isDelete}],
            srcOrig: [{ value: this.entity.srcOrig, disabled: this.isDelete || this.fromParser}],
            locTime: [{ value: this.entity.locTime, disabled: this.isDelete || this.fromParser }],
            activity: [{ value: this.entity.activity, disabled: this.isDelete }],
            activityCategoryId: [{ value: this.entity.activityCategoryId, disabled: this.isDelete }],
            opAreaId: [{ value: this.entity.opAreaId, disabled: this.isDelete }],
            patCandInd: [{ value: this.entity.patCandInd, disabled: this.isDelete }],
            papaCase: [{ value: this.entity.papaCase, disabled: this.isDelete }],
            tunnelInd: [{ value: this.entity.tunnelInd, disabled: this.isDelete }],
            nobRemoveInd: [{ value: this.entity.nobRemoveInd, disabled: this.isDelete }],
            el_not: [{ value: this.entity.elint ? this.entity.elint.el_not : null, disabled: this.isDelete }],
            elint: [{ value: this.entity.elint ? this.entity.elint : null, disabled: this.isDelete }],
            provider: [{ value: this.entity.elint ? this.entity.elint.provider : null, disabled: this.isDelete }],
            freq: [{ value: this.entity.elint ? this.entity.elint.freq : null, disabled: this.isDelete }],
            band: [{ value: this.entity.elint ? this.entity.elint.band : null, disabled: this.isDelete }],
            func: [{ value: this.entity.elint ? this.entity.elint.func : null, disabled: this.isDelete }],
            pulDur: [{ value: this.entity.elint ? this.entity.elint.pulDur : null, disabled: this.isDelete }],
            pri: [{ value: this.entity.elint ? this.entity.elint.pri : null, disabled: this.isDelete }],
            scanRate: [{ value: this.entity.elint ? this.entity.elint.scanRate : null, disabled: this.isDelete }],
            scanMeasure: [{ value: this.entity.elint ? this.entity.elint.scanMeasure : null, disabled: this.isDelete }],
            scanType: [{ value: this.entity.elint ? this.entity.elint.scanType : null, disabled: this.isDelete }],
            toiTs: [{ value: this.entity.elint ? this.entity.elint.toiTs : null, disabled: this.isDelete }],
            parserMsgId: [{ value: this.entity.parser_msg_id, disabled: this.isDelete || this.fromParser}],
            mfiInd: [{ value: this.entity.mfiInd, disabled: this.isDelete }],
            portFacility: [{ value: this.entity.portFacility, disabled: this.isDelete }],
            rationale: [],
        },
        { validators: this.utilities.locTimeAfterDTGImagedValidator });

        // Require changes to the Location fields, otherwise Location can't be updated.
        this.changeSubscription = this.editForm.valueChanges.subscribe(values => {
            this.hasChanges = Object.keys(values).some(key => {
                if (CLASSIFICATION_CONTROLS.hasOwnProperty(key)) {
                    // avoid issues of re-adding same values in same order.
                    const entityControls = this[CLASSIFICATION_CONTROLS[key]].sort();
                    const newControls = values[key].sort();

                    // Simplistic check if arrays contain same elements since each is array of controlled string IDs...
                    return JSON.stringify(entityControls) != JSON.stringify(newControls);
                } else {
                    return this.entity.hasOwnProperty(key) && !IGNORED_CHANGED_ATTRIBUTES.includes(key) && values[key] !== this.entity[key];
                }
            });
        });
    }

    submit(): void {
        this.updateEntity();

        if (this.isDelete) {
            if (confirm('Are you sure you want to delete this location?')) {
                this.locationService.delete(this.entity).pipe(take(1)).subscribe(response => {
                    this.dialogRef.close(response);
                });
            }
        } else {
            this.locationService.update(this.entity).pipe(take(1)).subscribe(response => {
                this.dialogRef.close(response);
            });
        }
    }

    cancel(): void {
        this.dialogRef.close();
    }

    updateEntity(): void {
        for (let key in this.editForm.controls) {
            if (this.entity.hasOwnProperty(key.toString())) {
                this.entity[key.toString()] = this.editForm.controls[key].value;
            }
        }

        // Reconstruct classificationDescription
        this.entity.classificationDescription = this.classDescriptionService
            .getClassificationDescription(this.editForm.controls.classificationId.value,
                                          this.editForm.controls.sciControls.value,
                                          this.editForm.controls.fgiControls.value,
                                          this.editForm.controls.otherControls.value,
                                          this.editForm.controls.disseminations.value);

        // Add decimal values for latitude and longitude
        const latitude = this.entity.latitude;
        if (latitude.indexOf('.') === -1) {
            this.entity.decLat = this.utilities.decimalLatitude(latitude);
        } else {
            this.entity.decLat = latitude;
            this.entity.latitude = this.utilities.getDMS(latitude, 'lat');
        }

        const longitude = this.entity.longitude;
        if (longitude.indexOf('.') === -1) {
            this.entity.decLon = this.utilities.decimalLongitude(longitude);
        } else {
            this.entity.decLon = longitude;
            this.entity.longitude = this.utilities.getDMS(longitude, 'lon');
        }
    }

    getSciControls(): string[] {
        return this.entity.classificationDescription.split('/')[1].split(',').filter(Boolean);
    }

    getFgiControls(): string[] {
        return this.entity.classificationDescription.split('/')[2].split(',').filter(Boolean);
    }

    getOtherControls(): string[] {
        return this.entity.classificationDescription.split('/')[3].split(',').filter(Boolean);
    }

    getDisseminations(): string[] {
        return this.entity.classificationDescription.split('/')[4].split(',').filter(Boolean);
    }

    canCalculateLastLoc() {
        const latControl = this.editForm.get('latitude');
        const lonControl = this.editForm.get('longitude');

        return latControl.valid && lonControl.valid;
    }

    calculateLastLoc(): void {
        if (this.canCalculateLastLoc()) {
            const latitude = this.editForm.get('latitude').value;
            const longitude = this.editForm.get('longitude').value;

            this.geoRefService.getNearestGeoRef(latitude, longitude).pipe(take(1)).subscribe(results => {
                if (results) {
                    this.editForm.get('lastLoc').setValue(results['nearest_geo_ref']);
                }
            });
        }
    }

    selectActivity(val){
        if(val){
          this.activityReadOnly = false;
        }else{
          this.activityReadOnly = true;
        }
      }
}
