import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from './api.config';
import { FeatureType } from '@models/feature-type.model';

@Injectable({ providedIn: 'root' })
export class FeatureTypeApiService {
    SERVER_URL;

    constructor(
        private http: HttpClient, apiConfig: APIConfig) {
        this.SERVER_URL = apiConfig.TARGET_URL;
    }

    public getAll(): Observable<any> {
        return this.http.get(
            `${this.SERVER_URL}/featureTypes`);
    }

    public getAllByName(featureTypeName: string): Observable<any> {
        return this.http.get(
            `${this.SERVER_URL}/featureTypes/name/${featureTypeName}`);
    }

    public getAllByCategory(featureTypeCategory: string): Observable<any> {
        return this.http.get(
            `${this.SERVER_URL}/featureTypes/category/${featureTypeCategory}`);
    }

    public getById(parameterId: string): Observable<any> {
        return this.http.get(
            `${this.SERVER_URL}/featureTypes/${parameterId}`);
    }

    public update(parameterId: any, formData: FormData): Observable<any> {
        return this.http.put(
            `${this.SERVER_URL}/featureTypes/${parameterId}`, formData);
    }

    public create(featureType: any): Observable<any> {
        return this.http.post(
            `${this.SERVER_URL}/featureTypes`, featureType);
    }

    public delete(parameter: any): Observable<any> {
        return this.http.delete(
            `${this.SERVER_URL}/featureTypes/${parameter.parameter_id}`);
    }
}
