import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from './api.config';

@Injectable()
export class LookupApiService {
  SERVER_URL;

  constructor(
    private http: HttpClient, apiConfig: APIConfig) {
    this.SERVER_URL = apiConfig.TARGET_URL;
  }

  public getLookupByType(type: string): Observable<any> {
    return this.http.get(
      `${this.SERVER_URL}/lookups?type=${type}`);
  }
  public getFilteredLookups(type: string, ownerId: string): Observable<any> {
    if (!ownerId || !ownerId.length) {
      ownerId = ''
    }
    return this.http.get(
      `${this.SERVER_URL}/lookups/filtered?type=${type}&ownerId=${ownerId}`);
  }

  public saveFilteredLookups(data: any): Observable<any> {
    return this.http.post(
      `${this.SERVER_URL}/lookups/saveFiltered`, data);
  }

  public getPicklistPreferences(table, ownerId): Observable<any> {
    if (!ownerId || !ownerId.length) {
      ownerId = ''
    }
    return this.http.get(
      `${this.SERVER_URL}/lookups/filtered?table=${table}&ownerId=${ownerId}`);
  }

}
