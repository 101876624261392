import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('SlideChoice')
export class SlideChoice {
    @JsonProperty('slide_id', String, true)
    slideId: string = undefined;
    @JsonProperty('slide_name', String, true)
    slideName: string = undefined;
    @JsonProperty('country_name', String, true)
    countryName: string = undefined;
    @JsonProperty('count_units', Number, true)
    countUnits: number = undefined;
    @JsonProperty('original_file_name', String, true)
    originalFileName: string = undefined;

}
