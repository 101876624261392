<app-classification-bar class="top-modal-classification-bar"></app-classification-bar>
<app-fdtm-modal-header [headerString]="headerString"></app-fdtm-modal-header>
<div mat-dialog-content>
<form [formGroup]="editForm">
  <mat-form-field class="input-full-width">
    <input matInput (input)="checkDups($event, 'controlMark')" autocomplete="off"
    placeholder="Control Mark" maxlength="3" required formControlName="controlMark">
    <mat-error *ngIf="editForm?.controls?.controlMark?.hasError('duplicate entry')">duplicate entry</mat-error>
  </mat-form-field>
  <br>
  <mat-form-field class="input-full-width">
    <input matInput placeholder="Source Code" autocomplete="off" required maxlength="2" formControlName="sourceCode">
  </mat-form-field>
  <div mat-dialog-actions>
    <p *ngIf="isDelete"  class="mat-color-warn">
      Deleting a Control Marking may cause serious issues with existing records!
      If you choose to delete this record the system will search for possible errors and may reject the request. 
      This is no guarantee that data corruption may still be present.  
    </p>
    <p *ngIf="!isNew && !isDelete"  class="mat-color-warn">
      Editing a Control Marking may cause serious issues with existing records!
      If you choose to edit this marking the changes will be applied to all existing records in the database.  
    </p>
    <button *ngIf="!isDelete" [disabled]="!editForm.valid" mat-raised-button color="primary" (click)="handleSubmit()">Submit</button>
    <button *ngIf="isDelete" mat-raised-button color="warn" (click)="localDelete()">Delete</button>
    <button mat-raised-button (click)="handleCancel()">Cancel</button>
  </div>
</form>
</div>
<app-classification-bar class="bottom-modal-classification-bar"></app-classification-bar>