
import { GlobalMessageTriggers } from '@services/global/message-bus/global-message-triggers.enum';
import { DataTagService } from '@services/data-services/data-tags.service';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { Component, ViewChild, OnInit } from '@angular/core';
import { DataTag } from '@models/data-tags.model';
import { GlobalBusMessage } from '@services/global/message-bus/global-bus-message.model';
import { Router } from '@angular/router';
import { GridBaseComponent } from '@fom-module/base-classes/grid-base.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmationModalComponent } from '@core-module/app-components/confirmation-modal/confirmation-modal.component';
import { EditDataTagModalComponent } from '@edit-modals/edit-data-tag-modal/edit-data-tag-modal.component';
import { take } from 'rxjs/operators';
import { MatSort, Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { AppInitService } from '../../../config/init.service';

@Component({
  selector: 'app-data-tags',
  templateUrl: './data-tags.component.html',
  styleUrls: ['./data-tags.component.css', '../base-classes/grid-base.component.css'],
  // providers: [TdFileService]
})
export class DataTagsComponent extends GridBaseComponent implements OnInit {

  paginator: MatPaginator;
  sort: MatSort;

  BOTTOM_NAV_BAR: any;

  disabled: Boolean = false;

  displayedColumns = ['classificationString', 'sourceName', 'dataTag', 'modRemarks', 'username', 'delete'];
  constructor(public dataTagService: DataTagService,
    private dialog: MatDialog,
    public messageBusService: MessageBusService,
    public route: Router,
    public initService: AppInitService) {
      super(messageBusService, dataTagService, route);
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {

    this.listenToMessageBus();
    this.loadEntityData();
    super.ngOnInit();
  }


  loadEntityData() {
    this.dataTagService.getAll().pipe(take(1)).subscribe(entities => {
      this.entities = entities;
      this.dataSource = new MatTableDataSource<object>(this.entities);
      this.dataSource.sortingDataAccessor = this.dataAccessor;
      this.dataSource.paginator = this.paginator;
    });
  }

  handleDelete(entity: DataTag) {
    super.handleDelete(entity, 'Delete Data Tag', `Select "Delete" to delete ${entity.dataTag}`, 'Delete')
  }
  
  edit(dataTag: any) {
    const dialogRef = this.dialog.open(EditDataTagModalComponent, {
      width: '820px'
    });
    dialogRef.componentInstance.isNew = false;
    dialogRef.componentInstance.entity = dataTag

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
        this.loadEntityData()
    });

  }
  add() {
    const dialogRef = this.dialog.open(EditDataTagModalComponent, {
      width: '820px'
    });
    dialogRef.componentInstance.isNew = true;
    dialogRef.componentInstance.entity = new DataTag()

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      this.loadEntityData()
    })
  }
  sortData(sort: Sort) {
    // console.log(sort)
    let data
    if (!sort.active || sort.direction === '') {
      data = this.entities
    }
    else {
      data = this.entities.sort((a,b) => {
        const isAsc = sort.direction === 'asc';
        return this.compare(a, b, isAsc, sort.active);
      })
    }
    this.dataSource.data = data;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = this.dataAccessor;
    //this.dataSource.filterPredicate = this.customFilterPredicate.bind(this);
    //this.dataSource.sort = this.sort;

  }
  compare(a, b, isAsc, active) {
    return (this.dataAccessor(a, active) < this.dataAccessor(b,active) ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
