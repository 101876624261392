import { FormBuilder, Validators } from '@angular/forms';
import { EditModalBaseComponent } from '@fom-module/base-classes/edit-modal-base.component';
import { FDOMapService } from '@services/data-services/fdo-map.service';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { Component, OnInit } from '@angular/core';
import { LookUpService } from '@services/data-services/lookup.service';
import { MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators'
import { Classification } from '@models/classification.model';
import { Fgi } from '@models/fgi.model';
import { Control } from '@models/control.model';
import { Releasability } from '@models/releasability.model';
import { OtherControl } from '@models/other-control.model';
import { Source } from '@models/source.model';
import { UtilityService } from '@services/utility.service';
import { CommandHierarchyService } from '@services/data-services/command-hierarchy.service';
import { CurrentUserService } from '@services/current-user-service';
import { User } from '@models/user.model';
import { Command } from '@models/command.model';
import { CommandHierarchy } from '@models/command-hierarchy.model';
import { CommandService } from '@services/data-services/command.service';
import { LookupSelectComponent } from '@fom-module/reusable/lookup-select/lookup-select.component';


@Component({
  selector: 'app-edit-fdo-map-modal',
  templateUrl: './edit-fdo-map-modal.component.html',
  styleUrls: ['./edit-fdo-map-modal.component.css']
})
export class EditFDOMapModalComponent extends EditModalBaseComponent implements OnInit {
  constructor(public fdoMapService: FDOMapService,
    public lookUpService: LookUpService,
    public currentUserService: CurrentUserService,
    public formBuilder: FormBuilder, public utilities: UtilityService,
    public dialogRef: MatDialogRef<EditFDOMapModalComponent>,
    public messageBusService: MessageBusService,
    public commandHierarchyService: CommandHierarchyService,
    public commandService: CommandService) {
      super(fdoMapService, dialogRef);
    }

      // RadioButton Options
  classificationOptions: Classification[];

  // Checkbox Options
  sciControlsOptions: Control[];
  fgiControlOptions: Fgi[];
  otherControlsOptions: OtherControl[];
  releasabilityOptions: Releasability[];
  sourceOptions: Source[];
  commandOptions: Command[];

  // User
  user: User;
  commandHierarchy: CommandHierarchy;
  selectedCommandId: string
  selectedSourceId: string

  ngOnInit() {
    this.headerString = this.isNew ? 'Add FDO Mapping' :
    this.isDelete ? 'Delete FDO Mapping' :
    'Edit FDO Mapping';
    // ok, let's unstring the classification into it's components
    // `${classification}/${sci}/${fgi}/${control}/${dissemination}`;
    let srcClassificationId = null;
    let srcSci = [] 
    let srcFgi = []
    let srcOther = []
    let srcDissem = []
    const srcParts = this.entity.srcClassificationCode ? this.entity.srcClassificationCode.split('/') : null
    // gonna be picky about the string...
    if (srcParts && srcParts.length == 5) {
      srcClassificationId = srcParts[0]
      srcSci = srcParts[1].split(',')
      srcFgi = srcParts[2].split(',')
      srcOther = srcParts[3].split(',')
      srcDissem = srcParts[4].split(',')
    }
    let tgtClassificationId = null;
    let tgtSci = [] 
    let tgtFgi = []
    let tgtOther = []
    let tgtDissem = []
    const tgtParts = this.entity.tgtClassificationCode ? this.entity.tgtClassificationCode.split('/') : null
    // gonna be picky about the string...
    if (tgtParts && tgtParts.length == 5) {
      tgtClassificationId = tgtParts[0]
      tgtSci = tgtParts[1].split(',')
      tgtFgi = tgtParts[2].split(',')
      tgtOther = tgtParts[3].split(',')
      tgtDissem = tgtParts[4].split(',')
    }
    
    this.editForm = this.formBuilder.group({
      srcClassificationId: [srcClassificationId, Validators.required],
      srcSciControl: [srcSci],
      srcFgiControl: [srcFgi],
      srcOtherControl: [srcOther],
      srcDissemination: [srcDissem],

      tgtClassificationId: [tgtClassificationId, Validators.required],
      tgtSciControl: [tgtSci],
      tgtFgiControl: [tgtFgi],
      tgtOtherControl: [tgtOther],
      tgtDissemination: [tgtDissem],
    });
    this.selectedCommandId = this.entity?.commandId
    this.selectedSourceId = this.entity?.sourceId
    //this.entity.commandId = this.selectedCommandId;
    // get current user
    this.user = this.currentUserService.getCurrentUser();

    Promise.resolve().then(() => {
      this.loadLookups()
    })
  }

  loadLookups() {
    this.lookUpService.getLookupByType(Classification).pipe(take(1)).subscribe(result => {
      this.classificationOptions = result;
      // descending order fomng-840
      this.classificationOptions.sort((a,b) => {
        return a.sortBy < b.sortBy ? 1 : -1
      })
    });

    this.lookUpService.getLookupByType(Fgi, true).pipe(take(1)).subscribe(entities => {
      this.fgiControlOptions = entities;
    });

    this.lookUpService.getLookupByType(Control).pipe(take(1)).subscribe(result => {
      this.sciControlsOptions = result;
    });

    this.lookUpService.getLookupByType(OtherControl).pipe(take(1)).subscribe(result => {
      this.otherControlsOptions = result;
    });

    this.lookUpService.getLookupByType(Releasability).pipe(take(1)).subscribe(result => {
      this.releasabilityOptions = result;
    });
    //use simpler option as reuse class is adding unneeded label
    this.lookUpService.getLookupByType(Source, true).pipe(take(1)).subscribe(entities => {
      this.sourceOptions = entities;
    });

    //add the command lookup; limit to JFMCCs commands only
   this.commandService.getAll().pipe(take(1)).subscribe(result => {
      this.commandHierarchyService.getAll({ sort: 'version_num', limit: 1 }).pipe(take(1)).subscribe(json => {
        if (json && Array.isArray(json) && json.length) {
          this.commandHierarchy = json[0];

          let jfmccs;
          if (this.user.isSystemAdmin()) {
            jfmccs = this.commandHierarchy.getJFMCCs();
          } else {
            jfmccs = this.commandHierarchy.getJFMCCIds(this.user.command);
          }
          this.commandOptions = result.filter(item => {
            return jfmccs.includes(item.commandId);
          });
        } else {
          this.commandOptions = result;
        }
      });
    });
  }

 

  updateEntity() {
    for (const key in this.editForm.controls) {
      if (this.entity.hasOwnProperty(key.toString())) {
        this.entity[key.toString()] = this.editForm.controls[key]['value'];
      }
    }
    // Join the classification fields into 5-part value
    this.entity.srcClassificationCode = this.buildDescription('srcClassificationId', 'srcSciControl', 'srcFgiControl', 'srcOtherControl', 'srcDissemination');
    this.entity.tgtClassificationCode = this.buildDescription('tgtClassificationId', 'tgtSciControl', 'tgtFgiControl', 'tgtOtherControl', 'tgtDissemination');

   // this.entity.commandId = this.selectedCommandId;
  }
  // Creates the joined string of all selected values for the 5-part classification
  private buildDescription(classificationControlName: string, sciControlName: string, fgiControlName: string, otherControlName: string, disseminationControlName: string): string {
    const classification = this.editForm.controls[classificationControlName]['value'];
    const sci = this.editForm.controls[sciControlName]['value'].join(',');
    const fgi = this.editForm.controls[fgiControlName]['value'].join(',');
    const control = this.editForm.controls[otherControlName]['value'].join(',');
    const dissemination = this.editForm.controls[disseminationControlName]['value'].join(',');

    return `${classification}/${sci}/${fgi}/${control}/${dissemination}`;
  }

  submit() {
    this.updateEntity()
    if (this.isNew) {
      this.entity.createdBy = this.user ? this.user.userId : null;
      this.fdoMapService.create(this.entity).pipe(take(1)).subscribe(response =>  {
          this.dialogRef.close(response)
        })
      }
      else {
        this.entity.dateUpdated = new Date();
        this.entity.updatedBy = this.user ? this.user.userId : null;
        this.fdoMapService.update(this.entity).pipe(take(1)).subscribe(response =>  {
            this.dialogRef.close(response)
        })
      }
  }
  cancel() {
    this.dialogRef.close()
  }
}
