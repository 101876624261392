import { OwnerType } from '@models/owner-type.model';
import { OwnerTypeApiService } from '@services/api-services/owner-type-api.service';
import { Injectable } from '@angular/core';
import { JsonConverter } from '@services/global/json-converter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class OwnerTypeService {
    private jsonConvert: JsonConverter;

    constructor(private ownerTypeApiService: OwnerTypeApiService) {
        this.jsonConvert = new JsonConverter();
    }

    public getAll(): Observable<OwnerType[]> {
        return this.ownerTypeApiService.getAllOwnerTypes()
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, OwnerType);
                })
            );
    }

    public getActivityByActivityId(ownerTypeId: string): Observable<OwnerType> {
        return this.ownerTypeApiService.getOwnerTypeByOwnerTypeId(ownerTypeId)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, OwnerType);
                })
            );
    }

    public update(ownerType: OwnerType): Observable<OwnerType> {
        return this.ownerTypeApiService.updateOwnerType(this.jsonConvert.serializeObject(ownerType))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, OwnerType);
                })
            );
    }

    public create(ownerType: OwnerType): Observable<OwnerType> {
        return this.ownerTypeApiService.createOwnerType(this.jsonConvert.serializeObject(ownerType))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, OwnerType);
                })
            );
    }

    public delete(ownerType: OwnerType): Observable<OwnerType> {
        return this.ownerTypeApiService.deleteOwnerType(this.jsonConvert.serializeObject(ownerType))
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }
}
