
<app-classification-bar class="top-modal-classification-bar"></app-classification-bar>

<app-fdtm-modal-header></app-fdtm-modal-header>
<mat-card-content align="center">
    <h2>Edit Other Names</h2>
    <div align="end">
        <button mat-mini-fab class="add-other-name" (click)="add()">
            <img class="light-icon" src="./assets/icons/ic_add_white_24px.svg" matTooltip="Add Other Name" />
        </button>
    </div>
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="select">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->
      
        <ng-container matColumnDef="other_name">
          <th mat-header-cell *matHeaderCellDef> Other Name </th>
          <td mat-cell *matCellDef="let element"> {{element.otherName}} </td>
        </ng-container>

        <!-- <ng-container matColumnDef="command">
          <th mat-header-cell *matHeaderCellDef> Command </th>
          <td mat-cell *matCellDef="let element"> {{element.command}} </td>
        </ng-container> -->
      
        <!-- Symbol Column -->
        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef> Edit </th>
          <td mat-cell *matCellDef="let element">
            <button mat-button title="Edit Other Names" class="delete-button" (click)="$event.stopPropagation()"
              (click)="edit(element)">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- Symbol Column -->
        <!-- <td mat-cell *matCellDef="let row">
          <button mat-button class="delete-button" (click)="$event.stopPropagation()" (click)="handleDelete(row)">
            <img src="./assets/icons/ic_delete_gray_24px.svg" />
          </button>
        </td> -->
        
        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef> Delete </th>
          <td mat-cell *matCellDef="let row">
            <button mat-button class="delete-button" (click)="$event.stopPropagation()" (click)="handleDelete(row)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </ng-container>
    </table>
  <mat-paginator [pageSizeOptions]="[5, 10]"
    showFirstLastButtons
    aria-label="Select page of Other Names">
    </mat-paginator>
</mat-card-content>

<div class="modal-buttons">
  <!-- <button mat-flat-button color="warn" (click)="confirmDelete()">Confirm Delete</button> -->
  <button mat-flat-button (click)="close()">Close</button>
</div>
<br>
<app-classification-bar class="bottom-modal-classification-bar"></app-classification-bar>
