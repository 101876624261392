import { FeatureType } from '@models/feature-type.model';
import { FeatureTypeApiService } from '@services/api-services/feature-type-api.service';
import { Injectable } from '@angular/core';
import { JsonConverter } from '@services/global/json-converter';
import { Observable, empty } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Form } from '@angular/forms';

@Injectable({
    providedIn: 'root'
  })
export class FeatureTypeService {
    private jsonConvert: JsonConverter;

    constructor(private preferenceApiService: FeatureTypeApiService) {
        this.jsonConvert = new JsonConverter();
    }

    public getAll(): Observable<FeatureType[]> {
        return this.preferenceApiService.getAll()
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, FeatureType);
                })
            );
    }

    public getAllByName(featureTypeName: string): Observable<FeatureType[]> {
        return this.preferenceApiService.getAllByName(featureTypeName)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, FeatureType);
                })
            );
    }
    
    public getAllByCategory(featureTypeCategory: string): Observable<FeatureType[]> {
        return this.preferenceApiService.getAllByCategory(featureTypeCategory)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, FeatureType);
                })
            );
    }

    public getById(parameterId: string): Observable<FeatureType> {
        return this.preferenceApiService.getById(parameterId)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, FeatureType);
                })
            );
    }


    public update(parameterId, formData: FormData): Observable<FeatureType> {
        return this.preferenceApiService.update(parameterId, formData)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, FeatureType);
                })
            );
    }

    public create(featureType: FeatureType): Observable<FeatureType> {
        return this.preferenceApiService.create(this.jsonConvert.serializeObject(featureType))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, FeatureType);
                })
            );
    }

    public delete(parameter: FeatureType): Observable<FeatureType> {
        return this.preferenceApiService.delete(this.jsonConvert.serializeObject(parameter))
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }
}
