import { FormBuilder, Validators } from '@angular/forms';
import { EditModalBaseComponent } from '@fom-module/base-classes/edit-modal-base.component';
import { DataTagService } from '@services/data-services/data-tags.service';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { Component, OnInit } from '@angular/core';
import { LookUpService } from '@services/data-services/lookup.service';
import { MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators'
import { Classification } from '@models/classification.model';
import { Fgi } from '@models/fgi.model';
import { Control } from '@models/control.model';
import { Releasability } from '@models/releasability.model';
import { OtherControl } from '@models/other-control.model';
import { Source } from '@models/source.model';
import { UtilityService } from '@services/utility.service';
import { PicklistPrefService } from '@services/data-services/picklist-pref.service';


@Component({
  selector: 'app-edit-data-tag-modal',
  templateUrl: './edit-data-tag-modal.component.html',
  styleUrls: ['./edit-data-tag-modal.component.css']
})
export class EditDataTagModalComponent extends EditModalBaseComponent implements OnInit {
  constructor(public dataTagService: DataTagService,
    public lookUpService: LookUpService,
    private picklistPrefService: PicklistPrefService,
    public formBuilder: FormBuilder, public utilities: UtilityService,
    public dialogRef: MatDialogRef<EditDataTagModalComponent>,
    public messageBusService: MessageBusService) {
      super(dataTagService, dialogRef);
    }

      // RadioButton Options
  classificationOptions: Classification[];

  // Checkbox Options
  sciControlsOptions: Control[];
  fgiControlOptions: Fgi[];
  otherControlsOptions: OtherControl[];
  releasabilityOptions: Releasability[];
  sourceOptions: Source[];

  ngOnInit() {
    this.headerString = this.isNew ? 'Add Data Tag' :
    this.isDelete ? 'Delete Data Tag' :
    'Edit Data Tag';
    // ok, let's unstring the classification into it's components
    // `${classification}/${sci}/${fgi}/${control}/${dissemination}`;
    let sci = [] 
    let fgi = []
    let other = []
    let dissem = []
    const parts = this.entity.classificationDescription ? this.entity.classificationDescription.split('/') : null
    // gonna be picky about the string...
    if (parts && parts.length == 5) {
      sci = parts[1].split(',')
      fgi = parts[2].split(',')
      other = parts[3].split(',')
      dissem = parts[4].split(',')
    }
    this.editForm = this.formBuilder.group({
      classificationId: [this.entity.classificationId, Validators.required],
      sciControl: [sci],
      fgiControl: [fgi],
      otherControl: [other],
      dissemination: [dissem],
      //sourceId: [this.entity.sourceId, Validators.required],
      dataTag: [this.entity.dataTag, Validators.required],
      modRemarks: [this.entity.modRemarks, Validators.required]
    });
    Promise.resolve().then(() => {
      this.loadLookups()
    })
  }
  loadLookups() {
    this.lookUpService.getLookupByType(Classification).pipe(take(1)).subscribe(result => {
      this.classificationOptions = result;
      // descending order fomng-840
      this.classificationOptions.sort((a,b) => {
        return a.sortBy < b.sortBy ? 1 : -1
      })
    });

    this.lookUpService.getLookupByType(Fgi, true).pipe(take(1)).subscribe(entities => {
      this.fgiControlOptions = entities;
    });

    this.lookUpService.getLookupByType(Control).pipe(take(1)).subscribe(result => {
      this.sciControlsOptions = result;
    });

    this.lookUpService.getLookupByType(OtherControl).pipe(take(1)).subscribe(result => {
      this.otherControlsOptions = result;
    });

    this.lookUpService.getLookupByType(Releasability).pipe(take(1)).subscribe(result => {
      this.releasabilityOptions = result;
    });
    // this.lookUpService.getLookupByType(Source).pipe(take(1)).subscribe(result => {
    //   this.sourceOptions = result;
    // });
  }

  updateEntity() {
    for (const key in this.editForm.controls) {
      if (this.entity.hasOwnProperty(key.toString())) {
        this.entity[key.toString()] = this.editForm.controls[key]['value'];
      }
    }
    // // have no idea why source name is part of the data model, but it could conflict with source_id
    // // the UI loads the source id correctly, let's just grab the source name for fun...
    // update: nevermind, modifed the update endpoint in API to build it using the 
    // same code as create endpoint
    // if (this.entity.sourceId && this.entity.sourceId.length) {
    //   let source = this.sourceOptions.find(x => x.sourceId == this.entity.sourceId)
    //   if (source) {
    //     this.entity.sourceName = source.sourceName
    //   }
    // }
      // Join the classification fields into 5-part value
    this.entity.classificationDescription = this.buildDescription();

    // // create the classification string here, since update cannot handle it
    // update: nevermind, modifed the update endpoint in API to build it using the 
    // same code as create endpoint
    // this.entity.classificationString = this.buildString()
  }
  // Creates the joined string of all selected values for the 5-part classification
  private buildDescription(): string {
    const classification = this.editForm.controls['classificationId']['value'];
    const sci = this.editForm.controls['sciControl']['value'].join(',');
    const fgi = this.editForm.controls['fgiControl']['value'].join(',');
    const control = this.editForm.controls['otherControl']['value'].join(',');
    const dissemination = this.editForm.controls['dissemination']['value'].join(',');

    return `${classification}/${sci}/${fgi}/${control}/${dissemination}`;
  }

  // Creates the joined string of all selected display values for the 5-part classification
  // private buildString(): string {
  // }

  submit() {
    this.updateEntity()
    if (this.isNew) {
      // really awkward -- the API data-tag controller will get the source name 
      // and build the classification_string before it creates the record
      // but the update function merely calls standardMethods to do the updaet --
      // which means the source name and classification string must be set aheadof time....
      // update: nevermind, modifed the update endpoint in API to build it using the 
      // same code as create endpoint
      this.dataTagService.create(this.entity).pipe(take(1)).subscribe(response =>  {
          this.dialogRef.close(response)
        })
      }
      else {
        this.dataTagService.update(this.entity).pipe(take(1)).subscribe(response =>  {
            this.dialogRef.close(response)
        })
      }
  }
  cancel() {
    this.dialogRef.close()
  }
}
