import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ReportingService } from '@services/data-services/reporting.service';
import { MessageService } from '@services/data-services/message.service';
import { FomPageBaseComponent } from '@fom-module/base-classes/fom-page-base.component';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { LoadingService } from '@services/global/loading/loading.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, Sort } from '@angular/material/sort';
import { UnitInfoComponent } from '../../unit-info/unit-info.component';
import { MatDialog } from '@angular/material/dialog';
import { UtilityService } from '@services/utility.service';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { CountryService } from '@services/data-services/country.service';
import { AppInitService } from '../../../../config/init.service';
import { CurrentUserService } from '@services/current-user-service';
import { User } from '@models/user.model';
import { PicklistPrefService } from '@services/data-services/picklist-pref.service';
import { PicklistTypeService } from '@services/data-services/picklist-type.service';
import { LookUpService } from '@services/data-services/lookup.service';
import { SortingService } from '@services/sorting.service';
import { Area } from '@models/area.model';
import { Fleet } from '@models/fleet.model';
import { Port } from '@models/port.model';
import { Source } from '@models/source.model';
import { Category } from '@models/category.model';
import { Activity } from '@models/activity.model';
import { Elnot } from '@models/elnot.model';
import { MatSelect } from '@angular/material/select';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { Subject } from 'rxjs';
import { GridGroupingService, Group } from '@services/grid-grouping.service';

const DEFAULT_SORT: Sort = {
  active: 'rank',
  direction: 'asc'
};

@Component({
  selector: 'app-current-nob',
  templateUrl: './current-nob.component.html',
  styleUrls: ['./current-nob.component.css']
})
export class CurrentNobComponent extends FomPageBaseComponent implements OnInit {

  dataSource = new TableVirtualScrollDataSource<any | Group>();
  @ViewChild(MatSelect, { static: true }) groupingSelect: MatSelect;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  // to debounce the filter input
  filterTextChanged: Subject<string> = new Subject<string>();

  constructor(
    private reportingService: ReportingService,
    private dialog: MatDialog,
    public utilityService: UtilityService,
    private messageService: MessageService,
    private loadingService: LoadingService,
    public snackBar: MatSnackBar,
    private countryService: CountryService,
    public messageBusService: MessageBusService,
    public route: Router,
    private cd: ChangeDetectorRef,
    private picklistTypeService: PicklistTypeService,
    private picklistPrefService: PicklistPrefService,
    private currentUserService: CurrentUserService,
    private lookupService: LookUpService,
    public initService: AppInitService,
    private gridGroupingService: GridGroupingService,
    private sorter: SortingService) {
    super(messageBusService, route);
    this.filterTextChanged
      .pipe(debounceTime(1000), // wait 1 sec after the last event before emitting last event
        distinctUntilChanged()) // only emit if value is different from previous value
      .subscribe(model => {
        // Expand all groups before applying filter to ensure all matching groups are expanded after filtering.
        this.changeAllGroups(true);
        // pull in the ngModel value to the dataSourceFilter
        // this.globalUnitFilter(model);
        this.unitFilter(model);
      });
  }
  // when this is set to the STRING 'true' the ticker will know to start up
  startTicker = '';

  now = new Date();
  nob: any;
  user: User;

  allColumns: string[]
  displayedColumns: any;

  // filter string for unit name
  filterValue = '';
  lastFilter = null;
  filterColumns = ['final_name'];

  // these objects represent the columns that can be grouped
  groupItems = [
    {
      display: 'Op Area',
      value: 'op_area_title',
      column_name: 'op_area_title',
      lookupId: 'opAreaId',
      table_name: 'op_area',
      type: Area,
      getSortSequence: function (row) {
        // data contains op_area_id, so can match to the picklist pref.
        const found = (this as any).picklistPrefs.find(pref => {
          return pref.sourcePicklistId === row.op_area_id;
        });
        return found ? found.sortSequence : null;
      }
    },
    {
      display: 'Fleet',
      value: 'fleet_code',
      column_name: 'fleet_code',
      table_name: 'fleet',
      type: Fleet,
      getSortSequence: function (row) {
        // data has fleet_id
        const found = (this as any).picklistPrefs.find(pref => {
          return pref.sourcePicklistId === row.fleet_id;
        });

        return found ? found.sortSequence : null;
      }
    },
    {
      display: 'ELNOT',
      value: 'el_not_code',
      column_name: 'el_not_code',
      table_name: 'el_not_code',
      type: Elnot,
      getSortSequence: function (row) {
        // data has fleet_id
        const found = (this as any).picklistPrefs.find(pref => {
          return pref.sourcePicklistId === row.el_not_id;
        });

        return found ? found.sortSequence : null;
      }
    },
    {
      display: 'Home Port',
      value: 'port_name',
      column_name: 'port_name',
      table_name: 'port',
      type: Port,
      getSortSequence: function (row) {
        // data only has name...
        const port = (this as any).lookups.find(lookup => {
          /// grrrr... no code in dataset, have to use name
          return lookup.name === row.port_name;
        });
        if (!port) {
          return null;
        }

        const found = (this as any).picklistPrefs.find(pref => {
          return pref.sourcePicklistId === port.portId;
        });

        return found ? found.sortSequence : null;
      }
    },
    {
      display: 'Source',
      value: 'source',
      column_name: 'source',
      table_name: 'source',
      type: Source,
      getSortSequence: function (row) {
        const found = (this as any).picklistPrefs.find(pref => {
          return pref.sourcePicklistId === row.source_id;
        });

        return found ? found.sortSequence : null;
      }
    },
    { display: 'Originator', value: 'originator', column_name: 'originator', table_name: null },
    { display: 'Last Location', value: 'last_loc', column_name: 'last_loc', table_name: null },
    {
      display: 'Category',
      value: 'category_description',
      column_name: 'category_description',
      table_name: 'category',
      type: Category,
      getSortSequence: function (row) {
        const category = (this as any).lookups.find(lookup => {
          return lookup.category === row.category_description;
        });
        if (!category) {
          return null;
        }

        const found = (this as any).picklistPrefs.find(pref => {
          return pref.sourcePicklistId === category.categoryId;
        });

        return found ? found.sortSequence : null;
      }
    },
    {
      display: 'Activity Category',
      value: 'activity_category_name',
      column_name: 'activity_category_name',
      table_name: 'activity_category',
      type: Activity,   // null since not displayed in grid
      getSortSequence: function (row) {
        const found = (this as any).picklistPrefs.find(pref => {
          return pref.sourcePicklistId === row.activity_category_id;
        });

        return found ? found.sortSequence : null;
      }
    },
    { display: 'No Grouping', value: 'All', column_name: null, table_name: null, }
  ];
  // the current (default) group will be the first one in the list
  public grouping = this.groupItems[0];
  groupByColumns: string[] = [this.grouping.value];
  groupCount = 0;

  // if the row is in this array, the send to cop is still active...
  rowsSending = [];
  // disable the NOBKML button when request is sent
  NetworkLinkKMLPending = false;
  // disable the NOBKML button when request is sent
  NOBKMLPending = false;

  // grouping order by picklist
  picklistTypes: any;
  picklistPreferences: any;
  // country map for flag
  countryMap = {};

  async ngOnInit() {
    this.allColumns = Object.keys(this.utilityService.getNOBColumnHeaders());
    this.allColumns.unshift('info')
    this.allColumns.push('send')
    this.user = this.currentUserService.getCurrentUser();
    // so... data binding not forgiving if column is missing, trying to hide it
    if (this.user.permission.permissionLevel < 3) {
      const index = this.allColumns.indexOf('send');
      if (index > -1) {
        this.allColumns.splice(index, 1);
      }
    }

    this.displayedColumns = this.allColumns.filter(value => value !== this.grouping.column_name);
    const filters = JSON.parse(sessionStorage.getItem('locationFilters')) || {};

    this.dataSource.filterPredicate = this.customFilterPredicate.bind(this);

    this.picklistPreferences = await this.picklistPrefService.getAll({
      owner_id: this.user.currentJFMCC ? this.user.currentJFMCC.commandId : null
    }).toPromise();

    // merge the lookups objects in the above code with the picklist types
    this.picklistTypes = await this.picklistTypeService.getAll().toPromise();
    this.groupItems.forEach(async item => {
      // Add PicklistType to grouping
      const found = this.picklistTypes.find(x => {
        return x.tableName === item.table_name;
      });

      if (found) {
        (item as any).picklistType = found;

        // Add any PicklistPrefs to grouping
        (item as any).picklistPrefs = this.picklistPreferences.filter(pref => {
          return pref.picklistTypeId === found.picklistTypeId;
        });
        // Load lookups for that type
        (item as any).lookups = await this.lookupService.getLookupByType(item.type, true).toPromise();
      } else {
        (item as any).picklistPrefs = [];
      }
    });

    // get all the countries and create a map
    this.countryService.getAll().pipe(take(1)).subscribe(countries => {
      countries.forEach(c => {
        this.countryMap[c.name] = c;
      });
      this.getCurrentNOB(filters);
    });
    super.ngOnInit();
  }

  // when the user is typing the filter action will wait a sec before applying
  onFilterChange(event) {
    this.filterTextChanged.next(event);
  }

  // unit selected from the nob-ticker. let's trigger the filter
  unitSelected(unit) {
    this.filterValue = unit.final_name;
    this.unitFilter(unit.final_name);
    // this.globalUnitFilter(unit.final_name);
  }

  getLat(element)
  {
    if(element.mfi_ind) {
     // return element?.port_facility?.substring(0,3) || element.latitude
     return element?.loc_port_name ? 'DPT ' : element.latitude
    }
    else {
      return element.latitude
    }
  }

  getLon(element)
  {
    if(element.mfi_ind) {
      //return element?.port_facility?.substring(3) || element.longitude
      return element?.loc_port_name ? element?.loc_port_name : element.latitude
    }
    else {
      return element.longitude
    }
  }

  // use the map since we only have country name
  getFlag(country) {
    const countryObject = this.countryMap[country];
    return countryObject ? this.countryService.getFlagUrl(countryObject) : null;
  }

  onKMLHistorySelected(unit: any, item: string) {
    if (item) {
      this.NetworkLinkKMLPending = true;
      // open the snackbar, get the reference, and set the action to be a Cancel
      const snackBarReference = this.snackBar.open('Historic KML Request sent to server. please wait...', 'Cancel');
      // we are going to send the message, and possible cancel the subscription
      const request = this.reportingService.getHistoricKML(unit.unit_id, item);
      const subscription = request.subscribe(response => {
        this.NetworkLinkKMLPending = false;
        snackBarReference.dismiss();
        const filename = `${unit.final_name}_(${item}).kml`;
        const attachment = response.headers.get('Content-Disposition');
        this.downloadNOBKML(response.body, filename);
      }, error => {
        this.NetworkLinkKMLPending = true;
        this.snackBar.open(`Server error: status ${error.status} ${error.statusText}`, 'OK', { duration: 5000 });
        subscription.unsubscribe();
      });

      // if the snackBar is cancelled, cancel the request
      snackBarReference.onAction().pipe(take(1)).subscribe(() => {
        this.NetworkLinkKMLPending = false;
        subscription.unsubscribe();
      });
    }
  }

  // from an external event to refresh (ticker)
  public refreshNOB() {
    this.startTicker = 'refresh';
    this.cd.detectChanges();
    // this.globalClearFilter('');
    this.clearFilter();
    this.getCurrentNOB(null);
  }

  public getCurrentNOB(filters) {
    this.reportingService.getCurrentNOB(filters).pipe(take(1)).subscribe(async nob => {
      // the api returns the data sorted by rank.
      // this sort order needs to be retained whenever displayed, within the current grouping
      // // ensure sorted by op area
      // const sorted = nob.sort((a, b) => {
      //   return (a.op_area_title < b.op_area_title ? -1 : 1);  //          /? -1 : 1
      // });

      // get the class once so that change detection is not looking
      nob.forEach(element => {
        element.rowClass = this.rowClassifier(element);
        element.latencyClass = this.latencyClassifier(element);
      });

      this.nob = nob; // stash it away

      // data should already be sorted by rank, do not sort again
      const sort: Sort = { active: null, direction: '' };
      this.sortData(sort)

      // trigger the ticker to get going
      this.startTicker = 'true';
      this.cd.detectChanges();
    });
  }
  // this event is emitted from the search filters when the user has applied new filters
  locationFiltersChanged(filters) {
    this.getCurrentNOB(filters);
  }

  // implement the <tr> rules from FOM 2.0 here
  colClassifier(record): string {
    // first, if it is a TS looking, make it orange
    // if (isClassification) {
    //   if (record.classification_name.includes('TOP SECRET')) {
    //       return 'siTag';
    //   }
    // }
    // now, regardless the column, look for RTP, INPT
    if (record.activity_category_code && record.activity_category_code.trim() === 'RTP' ||
      record.activity_category_code && record.activity_category_code.trim() === 'INPT') {
      return 'unitGreen';
    } else if (record.category_description === 'SUB') {
      return 'unitRed';
    }
    return 'unitWhite';
  }

  // adding a class to the mat-row in a single row by row call at time of data load
  // so that the digest loop doesn't waste so many resources
  rowClassifier(record): string {
    // now, regardless the column, look for RTP, INPT
    if (record.activity_category_code && record.activity_category_code.trim() === 'RTP' ||
      record.activity_category_code && record.activity_category_code.trim() === 'INPT') {
      return 'unitGreen';
    } else if (record.category_description === 'SUB') {
      return 'unitRed';
    }
    return 'unitWhite';
  }

  latencyClassifier(record): string {
    if (record.latency <= 12) {
      return 'green-latency';
    } else if (record.latency <= 24) {
      return ('yellow-latency');
    } else {
      return 'red-latency';
    }
  }

  handleSend(row) {
    if (this.rowsSending.indexOf(row) !== -1) {
    return
    }
    if(row.mfi_ind) {
      this.snackBar.open('MFI track cannot be sent for ' + row.final_name , 'OK', { duration: 5000 });
      return
    }
    // disable the row's send2Cop button by placing it into the array
    this.rowsSending.push(row);
    // open the snackbar, get the reference, and set the action to be a Cancel
    const snackBarReference = this.snackBar.open('Request sent to server for ' + row.final_name + ' please wait...', 'Cancel');
    // we are going to send the message, and possible cancel the subscription
    const request = this.messageService.send2Cop(row.ship_location_id);
    const subscription = request.subscribe(data => {
      this.rowsSending.splice(this.rowsSending.indexOf(row), 1);
      // let's scratch in a simple message. it worked or it did not
      const success = data.response.success;
      snackBarReference.dismiss();
      this.snackBar.open((success ? 'Message sent successfully for ' : 'One or more errors sending for ') +
        row.final_name, 'OK', { duration: 5000 });
    }, error => {
      this.rowsSending.splice(this.rowsSending.indexOf(row), 1);
      this.snackBar.open(`Server error: status ${error.status} ${error.statusText} + ${row.final_name}`, 'OK', { duration: 5000 });
      subscription.unsubscribe();
    });
    // if the snackBar is cancelled, cancel the request
    snackBarReference.onAction().pipe(take(1)).subscribe(() => {
      this.rowsSending.splice(this.rowsSending.indexOf(row), 1);
      subscription.unsubscribe();
      // subscription.error({status: 'Cancelled', statusText: 'Operation Cancelled'})
    });
  }

  handleBulkSend() {
    const request = this.messageService.bulkSend2Cop();
    const subscription = request.subscribe(data => {
      try {
        const success = data.response.success;
      } catch (e) {
        console.log(e);
      }
      subscription.unsubscribe();
    });
  }

  sendActive(row) {
    return this.rowsSending.indexOf(row) !== -1;
  }

  // request a download of the NetworkLink NOB KML report
  getNetworkLinkKML() {
    this.NetworkLinkKMLPending = true;
    // open the snackbar, get the reference, and set the action to be a Cancel
    const snackBarReference = this.snackBar.open('NetworkLink KML Request sent to server. please wait...', 'Cancel');
    // we are going to send the message, and possible cancel the subscription
    const request = this.reportingService.getNetworkLinkKML();
    const subscription = request.subscribe(response => {
      this.NetworkLinkKMLPending = false;
      snackBarReference.dismiss();
      const filename = 'FDT-M.kml';
      const attachment = response.headers.get('Content-Disposition');
      this.downloadNOBKML(response.body, filename);
    }, error => {
      this.NetworkLinkKMLPending = true;
      this.snackBar.open(`Server error: status ${error.status} ${error.statusText}`, 'OK', { duration: 5000 });
      subscription.unsubscribe();
    });

    // if the snackBar is cancelled, cancel the request
    snackBarReference.onAction().pipe(take(1)).subscribe(() => {
      this.NetworkLinkKMLPending = false;
      subscription.unsubscribe();
    });
  }

  downloadNOBKML(report: any, filename: string) {
    const file = new Blob([report]);
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(file, filename);
    } else {
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(file); // xhr.response is a blob
      a.style.display = 'none';
      a.download = filename;
      document.body.appendChild(a);
      a.click();
    }
  }

  generateNOBKML() {
    // console.log('generate NOB KML');
    this.NOBKMLPending = true;
    // open the snackbar, get the reference, and set the action to be a Cancel
    const snackBarReference = this.snackBar.open('NOB KML Request sent to server. please wait...', 'Cancel');
    // we are going to send the message, and possible cancel the subscription
    const request = this.reportingService.getNOBKML();
    const subscription = request.subscribe(response => {
      this.NOBKMLPending = false;
      snackBarReference.dismiss();
    }, error => {
      this.NOBKMLPending = true;
      this.snackBar.open(`Server error: status ${error.status} ${error.statusText}`, 'OK', { duration: 5000 });
      subscription.unsubscribe();
    });
    // if the snackBar is cancelled, cancel the request
    snackBarReference.onAction().pipe(take(1)).subscribe(() => {
      this.NOBKMLPending = false;
      subscription.unsubscribe();
    });
  }

  unitFilter(value: string) {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  clearFilter() {
    this.filterValue = '';
    this.dataSource.filter = '';
  }

  // grouping
  isGroup(_index, item): boolean {
    return item.isGroup;
  }

  groupHeaderClick(row) {
    row.expanded = !row.expanded;
    this.lastFilter = performance.now().toString();
    this.dataSource.filter = this.lastFilter;
  }

  groupingChanged(sort, grouping) {
    this.groupByColumns = [grouping.value];
    this.displayedColumns = this.allColumns.filter((value) => {
      return value !== grouping.column_name;
    });

    this.sortData(sort);
  }

  sortData(sort) {
    // If sort gets cycled, reset to rank sort.
    if (!sort.active || sort.direction === '') {
      sort = DEFAULT_SORT;
    }

    const isAsc = sort.direction === 'asc';
    const data = this.nob.sort((a, b) => {
      return this.compare(a, b, isAsc, sort.active);
    });

    this.dataSource.data = this.gridGroupingService.buildDataSourceData(data, this.groupByColumns[0]);
  }

  customFilterPredicate(data: any | Group, filter: string): boolean {
    const triggered = filter === this.lastFilter ? true : false;
    if (data instanceof Group) {
      if (triggered && !data.expanded) {
        // if a group row was clicked, and the group is collapsed, show the group row.
        return true;
      } else {
        // otherwise check if the group has any items that match the filter
        return data.items.some(x => this.matchesFilter(x, this.filterValue));
      }
    } else {
      if (triggered && !data.group.expanded) {
        // if triggered by a group row click and group is collapse always hide the data row.
        return false;
      } else {
        // triggered by new input to filterValue
        return this.matchesFilter(data, this.filterValue);
      }
    }
  }

  matchesFilter(item: any, filter: string): boolean {
    return item.final_name.toLowerCase().indexOf(filter.toLowerCase()) > -1;
  }

  compare(a, b, isAsc, active) {
    // sort by grouped column (honoring picklist preferences) first, then the active sort column.

    if (this.grouping.getSortSequence && this.grouping.getSortSequence(a) != null && this.grouping.getSortSequence(b) != null) {
      const aSequence = this.grouping.getSortSequence(a);
      const bSequence = this.grouping.getSortSequence(b);

      if (aSequence !== bSequence) {
        return aSequence - bSequence;
      }
    } else {
      if (a[this.grouping.value] > b[this.grouping.value]) {
        return 1;
      }

      if (a[this.grouping.value] < b[this.grouping.value]) {
        return -1;
      }
    }

    return (a[active] < b[active] ? -1 : 1) * (isAsc ? 1 : -1);
  }

  showInfo(unit) {
    // let's grab some more information...
    unit.dec_lat = this.utilityService.decimalLatitude(unit.latitude)
    unit.dec_lon = this.utilityService.decimalLongitude(unit.longitude)
    unit.row_class = this.rowClassifier(unit)
    unit.col_class = this.colClassifier(unit)
    unit.latency_class = this.latencyClassifier(unit)
    
    // use the map since we only have country name
    unit.country = this.countryMap[unit.country_name];
    
    const dialogRef = this.dialog.open(UnitInfoComponent, {
      width: '644px'
    });
    // push the unit into the modal component
    dialogRef.componentInstance.unit = unit;
    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      // there may be some request, such as track data, coming back from the modal
      // but the close button in the upper right will return true
      if (result && result.days) {
        this.onKMLHistorySelected(unit, result.days);
      }
    });
  }

  hasMultipleGroups(): boolean {
    return this.dataSource.filteredData.filter((e, index) => this.isGroup(index, e)).length > 1;
  }

  hasAnyExpandedGroups(): boolean {
    return this.dataSource.filteredData.filter((e: any, index) => this.isGroup(index, e) && e.expanded).length > 0;
  }

  changeAllGroups(expanded: boolean): void {
    this.dataSource.filteredData.filter((e, index) => this.isGroup(index, e)).forEach((e: any) => e.expanded = expanded);

    // Force the filter to re-run so view is updated.
    this.lastFilter = performance.now().toString();
    this.dataSource.filter = this.lastFilter;
  }
}
