<app-classification-bar class="top-modal-classification-bar"></app-classification-bar>
<app-fdtm-modal-header [headerString]="headerString"></app-fdtm-modal-header>
<form #form="ngForm">
  <div>
    <mat-form-field appearance="fill">
      <mat-label>Category</mat-label>
      <mat-select required name="category" [(ngModel)]="featureCategory">
        <mat-option value="REPORT">Report</mat-option>
        <mat-option value="CONFIG">Config</mat-option>
        <mat-option value="SCREEN">Screen</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="input-full-width" appearance="fill">
      <mat-label>Feature Preference Name</mat-label>
      <input matInput required name="name" [(ngModel)]="featureTypeName">
    </mat-form-field>
    <mat-form-field class="input-full-width" appearance="fill">
      <mat-label>Feature Type Schema</mat-label>
      <textarea matInput required rows="4" cols="50" name="schema" [(ngModel)]="schema" placeholder="Enter your text"> </textarea>
    </mat-form-field>
    <mat-form-field class="input-full-width" appearance="fill">
      <mat-label>Feature Type Definition</mat-label>
      <textarea matInput required rows="4" cols="50" name="schemaDefinition" [(ngModel)]="schemaDefinition" placeholder="Enter your text"></textarea>
    </mat-form-field>
    <div *ngIf="featureCategory === 'REPORT'">
      <mat-form-field class="input-full-width" appearance="fill">
        <mat-label>Report Template Name</mat-label>
        <input matInput required name="template name" [(ngModel)]="reportTemplateName">
      </mat-form-field>
      <mat-form-field class="input-full-width" appearance="fill">
        <mat-label>Report Template (HTML)</mat-label>
        <textarea matInput required rows="4" cols="50" name="reportTemplate" [(ngModel)]="reportTemplate" placeholder="Enter your text"></textarea>
      </mat-form-field>
    </div>
  </div>

  <div mat-dialog-actions>
    <button color="primary" mat-raised-button [disabled]="!form.valid" (click)="submit()">Submit</button>
    <button mat-raised-button (click)="cancel()">Cancel</button>
  </div>

</form>
<app-classification-bar class="bottom-modal-classification-bar"></app-classification-bar>
