import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from '@services/api-services/api.config';

@Injectable()
export class HistoricalApiService {
  SERVER_URL;

  constructor(
    private http: HttpClient, apiConfig: APIConfig) {
    this.SERVER_URL = apiConfig.TARGET_URL;
  }

  public getUnits(from: string, to: string) {
    return this.http.get(
        `${this.SERVER_URL}/historicals/unit-search?from=` + from + `&to=` + to
    );
}
  public getAllUnits(queryParams: Object, filters = {}): Observable<any> {
    let httpParams = new HttpParams();

    for (const key in queryParams) {
      if (queryParams.hasOwnProperty(key)) {
        httpParams = httpParams.set(key, queryParams[key]);
      }
    }
    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        httpParams = httpParams.set(key, filters[key]);
      }
    }
    return this.http.get(
      `${this.SERVER_URL}/historicals`, { params: httpParams });
  }

  public getUnitByUnitId(unitId: string): Observable<any> {
    return this.http.get(
      `${this.SERVER_URL}/historicals/${unitId}`);
  }
}
