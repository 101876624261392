import { Injectable } from '@angular/core';
import { UtilityService } from '@services/utility.service';
@Injectable({
  providedIn: 'root'
})

export class SortingService {

  constructor(public utilities: UtilityService) {
  }

  public sortList(list, preferences, defaultSort, id = 'sourceId', showDisabled = false) {
    let index = 0;
    list.map(item => {
      const found = preferences.find(x => {
        return x.sourcePicklistId == item[id]
      })
      if (found) {
        index++;
        (item as any).picklistPreference = found;
        (item as any).sortSequence = found.sortSequence;
        (item as any).isDisabled = found.disabled ? true : false;
      }
    })
    if (index > 0) {
      list.sort((a, b) => {
        return a.sortSequence - b.sortSequence
      })
    } else {
      list.sort((a, b) => {
        const aValue = typeof a[defaultSort] === 'string' ? a[defaultSort].toUpperCase() : a[defaultSort];
        const bValue = typeof b[defaultSort] === 'string' ? b[defaultSort].toUpperCase() : b[defaultSort];

        if (aValue < bValue) { return -1; }
        if (aValue > bValue) { return 1; }
        return 0;
      })
    }
    if (!showDisabled) {
      list = list.filter(item => !item.isDisabled)
    }
    return list;
  }

}