import { UnitService } from '@services/data-services/unit.service';
import { UnitApiService } from '@services/api-services/unit-api.service';
import { BlueService } from '@services/data-services/blue.service';
import { BlueApiService } from '@services/api-services/blue-api.service';
import { BlueLocationService } from '@services/data-services/blue-location.service';
import { BlueLocationApiService } from '@services/api-services/blue-location-api.service';
import { CategoryApiService} from '@services/api-services/category-api.service';
import { CategoryService} from '@services/data-services/category.service';
import { ElnotService } from '@services/data-services/elnot.service';
import { FreqTypeService } from '@services/data-services/freq-type.service';
import { ScanTypeService } from '@services/data-services/scan-type.service';
import { ElintFunctionTypeService } from '@services/data-services/elint-function-type.service';
//import { AuthorizationInterceptor } from './interceptors/authorization.interceptor';
import { AuthenticationService } from '@services/data-services/authentication.service';
import { ConfirmationModalComponent } from './app-components/confirmation-modal/confirmation-modal.component';
import { DummyDataService } from '@services/data-services/dummy-data.service';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { APIConfig } from '@services/api-services/api.config';
import { UserService } from '@services/data-services/user.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserApiService } from '@services/api-services/user-api.service';
import { DummyApiService } from '@services/api-services/dummy-api.service';
import { LookupApiService } from '@services/api-services/lookup-api.service';
import { LookUpService } from '@services/data-services/lookup.service';
import { DynamicComponentComponent } from './app-components/dynanmic-component/dynanmic-component.component';
import { AuthenticationApiService } from '@services/api-services/authentication-api.service';
import { ActivityService } from '@services/data-services/activity.service';
import { ClassificationService } from '@services/data-services/classification.service';
import { ClassificationApiService } from '@services/api-services/classification-api.service';
import { ControlService } from '@services/data-services/control.service';
import { ControlApiService } from '@services/api-services/control-api.service';
import { OtherControlService } from '@services/data-services/other-control.service';
import { OtherControlApiService } from '@services/api-services/other-control-api.service';
import { ReleasabilityService } from '@services/data-services/releasability.service';
import { ReleasabilityApiService } from '@services/api-services/releasability-api.service';
import { PortService } from '@services/data-services/port.service';
import { PortApiService } from '@services/api-services/port-api.service';
import { ParameterService } from '@services/data-services/parameter.service';
import { FeaturePreferenceService } from '@services/data-services/feature-preference.service';
import { ParameterApiService } from '@services/api-services/parameter-api.service';
import { FeaturePreferenceApiService } from '@services/api-services/feature-preference-api.service';
import { ActivityApiService } from '@services/api-services/activity-api.service';
import { FgiApiService } from '@services/api-services/fgi-api.service';
import { FgiService} from '@services/data-services/fgi.service';
import { CPageService} from '@services/data-services/cpage.service';
import { CPageApiService } from '@services/api-services/cpage-api.service';
import { CommandApiService } from '@services/api-services/command-api.service';
import { CommandService } from '@services/data-services/command.service';
import { CommandHierarchyService } from '@services/data-services/command-hierarchy.service';
import { CommandHierarchyApiService } from '@services/api-services/command-hierarchy-api.service';
import { OwnerApiService } from '@services/api-services/owner-api.service';
import { OwnerService } from '@services/data-services/owner.service';
import { OwnerTypeApiService } from '@services/api-services/owner-type-api.service';
import { OwnerTypeService } from '@services/data-services/owner-type.service';
import { PicklistTypeService} from '@services/data-services/picklist-type.service'
import { PicklistTypeApiService} from '@services/api-services/picklist-type-api.service'
import { PicklistPrefService} from '@services/data-services/picklist-pref.service'
import { PicklistPrefApiService} from '@services/api-services/picklist-pref-api.service'
import { LocationApiService } from '@services/api-services/location-api.service';
import { LocationService } from '@services/data-services/location.service';
import { UnitOtherNameService } from '@services/data-services/unit-other-name-service';
import { UnitOtherNameApiService } from '@services/api-services/unit-other-name-api.service';
import { ReportingService } from '@services/data-services/reporting.service';
import { ReportingApiService } from '@services/api-services/reporting-api.service';
import { MessageService } from '@services/data-services/message.service';
import { MessageApiService } from '@services/api-services/message-api.service';
import { DataCardsService } from '@services/data-services/datacards.service';
import { DataCardsApiService } from '@services/api-services/datacards-api.service';
import { HistoricalApiService } from '@services/api-services/historical-api.service';
import { HistoricalService } from '@services/data-services/historical.service';
import { OptempoService } from '@services/data-services/optempo.service';
import { OptempoApiService } from '@services/api-services/optempo-api.service';
import { AreaApiService } from '@services/api-services/area-api.service';
import { AreaService} from '@services/data-services/area.service';
import { AuditUnitBorrowedApiService} from '@services/api-services/audit-unit-borrowed-api.service';
import { AuditUnitBorrowedService} from '@services/data-services/audit.service';
import { CountryService } from '@services/data-services/country.service';
import { CountryApiService } from '@services/api-services/country-api.service';
import { SourceService } from '@services/data-services/source.service';
import { SourceApiService } from '@services/api-services/source-api.service';
import { FleetApiService } from '@services/api-services/fleet-api.service';
import { FleetService } from '@services/data-services/fleet.service';
import { ShipTypeApiService } from '@services/api-services/ship-type-api.service';
import { ShipTypeService } from '@services/data-services/ship-type.service';
import { ShipClassService } from '@services/data-services/ship-class.service';
import { VesselTypeService } from '@services/data-services/vessel-type.service';
import { GeoRefApiService } from '@services/api-services/geo-ref-api.service';
import { GeoRefService } from '@services/data-services/geo-ref.service';
import { AuthorizationApiService } from '@services/api-services/authorization-api.service';
import { AuthorizationService } from '@services/data-services/authorization.service';
import { AuthorizationGuardService } from '@services/data-services/authorization-guard.service';
import { NotifierApiService } from '@services/api-services/notifier-api.service';
import { NotifierEmailService } from '@services/data-services/notifier.service';
import { SlideApiService } from '@services/api-services/slide-api.service';
import { SlideService } from '@services/data-services/slide.service';
import { SlideGeneratorApiService } from '@services/api-services/slide-generator-api.service';
import { SlideGeneratorService } from '@services/data-services/slide-generator.service';
import { ParserQueueApiService } from '@services/api-services/parser-queue-api.service';
import { ParserQueueService } from '@services/data-services/parser-queue.service';
import { DataTagService } from '@services/data-services/data-tags.service';
import { DataTagApiService } from '@services/api-services/data-tags-api.service';
import { ServicesService } from '@services/data-services/services.service'
import { ServicesApiService } from '@services/api-services/services-api.service'
import { CertLoginComponent } from "./app-components/login/cert-login.component";

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorModalComponent } from '@core-module/app-components/error-modal/error-modal.component';
import { fromEventPattern } from 'rxjs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AccessAgreementComponent } from './app-components/access-agreement/access-agreement.component';
import { AbacPreferencesComponent } from './app-components/abac-preferences/abac-preferences.component';
import { CommandFilterPipe } from './pipes/command-filter.pipe';
import { TimeoutWarningComponent } from './app-components/timeout-warning/timeout-warning.component';
import { PocService } from '@services/data-services/point-of-contact-data.service';
import { PocApiService } from '@services/api-services/point-of-contact-api.service';
import { RequestAccountModalComponent } from '@fom-module/edit-modals/request-account-modal/request-account-modal.component';
import { AccountRequestService } from '@services/data-services/account-request.service';
import { AccountRequestApiService } from '@services/api-services/account-request-api.service';
import { EditOwnerTypeModalComponent} from '@fom-module/edit-modals/edit-owner-type-modal/edit-owner-type-modal.component'
import { PermissionService } from '@services/data-services/permission.service';
import { PermissionApiService } from '@services/api-services/permission-api.service';
import { ClassificationBarComponent } from './app-components/classification-bar/classification-bar.component';
import { ConnectionsService } from '@services/data-services/connections.service';
import { ConnectionApiService } from '@services/api-services/connection-api.service';
import { MessageConfigService } from '@services/data-services/message-config.service';
import { MessageConfigApiService } from '@services/api-services/message-config-api.service';
import { ParametersTemplateService } from '@services/data-services/parameters-template.service';
import { ParametersTemplateApiService } from '@services/api-services/parameters-template-api.service';
import { ReviewComponent } from '@fom-module/review/review.component';
import { OptionsScrollDirective } from './directives/options-scroll.directive';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ElnotApiService } from '@services/api-services/elnot-api.service';
import { FreqTypeApiService } from '@services/api-services/freq-type-api.service';
import { ScanTypeApiService } from '@services/api-services/scan-type-api.service';
import { ElintFunctionTypeApiService } from '@services/api-services/elint-function-type-api.service';
import { ShipClassApiService } from '@services/api-services/ship-class-api.service';
import { VesselTypeApiService } from '@services/api-services/vessel-type-api.service';
import { FDOMapService } from '@services/data-services/fdo-map.service';
import { FDOMapApiService } from '@services/api-services/fdo-maps-api.service';
import { ClassMarkingCategoryApiService } from '@services/api-services/class-marking-category-api.service';
import { ClassMarkingCategoryService } from '@services/data-services/class-marking-category.service';
import { ClassMarkingRelationshipApiService } from '@services/api-services/class-marking-relns-api.service';
import { ClassMarkingRelationshipService } from '@services/data-services/class-marking-relns.service';
import { ConfidenceCodeApiService } from '@services/api-services/confidence-code-api.service';
import { ConfidenceCodeService } from '@services/data-services/confidence-code.service';
//import { FileSizePipe } from './pipes/file-size.pipe';
//import { HasCommandPipe } from './pipes/has-command.pipe';

@NgModule({
  // entryComponents: [DynamicComponentComponent,
  //   ConfirmationModalComponent,
  //   ErrorModalComponent,
  //   LoginModalComponent,
  //   CertLoginComponent,
  //   RequestAccountModalComponent
  // ],
  imports: [
    CommonModule,
    FormsModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    BrowserAnimationsModule,
  ],
  declarations: [ErrorModalComponent, AccessAgreementComponent,
    AbacPreferencesComponent, CommandFilterPipe, TimeoutWarningComponent, ClassificationBarComponent, ReviewComponent, OptionsScrollDirective],
  providers: [
    ActivityService,
    ActivityApiService,
    ClassificationService,
    ClassificationApiService,
    ControlService,
    ControlApiService,
    ConnectionsService,
    ConnectionApiService,
    MessageConfigService,
    MessageConfigApiService,
    ParametersTemplateService,
    ParametersTemplateApiService,
    OtherControlService,
    OtherControlApiService,
    ReleasabilityService,
    ReleasabilityApiService,
    APIConfig,
    //AuthorizationInterceptor,
    AuthenticationService,
    AuthenticationApiService,
    AuthorizationGuardService,
    CategoryApiService,
    CategoryService,
    ElnotService,
    ElnotApiService,
    CommandApiService,
    CommandService,
    CommandHierarchyService,
    CommandHierarchyApiService,
    OwnerService,
    OwnerApiService,
    OwnerTypeService,
    OwnerTypeApiService,
    PermissionService,
    PermissionApiService,
    PicklistTypeService,
    PicklistTypeApiService,
    PicklistPrefService,
    PicklistPrefApiService,
    DummyApiService,
    DummyDataService,
    FormBuilder,
    LookupApiService,
    LookUpService,
    MessageBusService,
    UnitService,
    UnitApiService,
    BlueService,
    BlueApiService,
    BlueLocationService,
    BlueLocationApiService,
    UserApiService,
    UserService,
    FgiService,
    CPageService,
    CPageApiService,
    FgiApiService,
    LocationApiService,
    LocationService,
    UnitOtherNameService,
    UnitOtherNameApiService,
    HistoricalApiService,
    HistoricalService,
    ReportingService,
    ReportingApiService,
    MessageService,
    MessageApiService,
    DataCardsService,
    DataCardsApiService,
    OptempoService,
    OptempoApiService,
    PortService,
    PortApiService,
    ParameterService,
    FeaturePreferenceService,
    FeaturePreferenceApiService,
    ParameterApiService,
    AreaApiService,
    AreaService,
    AuditUnitBorrowedApiService,
    AuditUnitBorrowedService,
    CountryService,
    CountryApiService,
    SourceService,
    SourceApiService,
    FleetApiService,
    FleetService,
    ShipTypeApiService,
    ShipTypeService,
    ShipClassApiService,
    ShipClassService,
    VesselTypeApiService,
    VesselTypeService,
    GeoRefApiService,
    GeoRefService,
    AuthorizationApiService,
    AuthorizationService,
    NotifierEmailService,
    NotifierApiService,
    SlideApiService,
    SlideService,
    ServicesService,
    ServicesApiService,
    SlideGeneratorApiService,
    SlideGeneratorService,
    ParserQueueApiService,
    ParserQueueService,
    DataTagApiService,
    DataTagService,
    PocService,
    PocApiService,
    AccountRequestService,
    AccountRequestApiService,
    FDOMapService,
    FDOMapApiService,
    ClassMarkingCategoryApiService,
    ClassMarkingCategoryService,
    ClassMarkingRelationshipApiService,
    ClassMarkingRelationshipService,
    FreqTypeService,
    FreqTypeApiService,
    ScanTypeService,
    ScanTypeApiService,
    ElintFunctionTypeService,
    ElintFunctionTypeApiService,
    ConfidenceCodeApiService,
    ConfidenceCodeService,
  ],
  exports: [ClassificationBarComponent, ReviewComponent, OptionsScrollDirective]
})
export class CoreModule { }
