<div class="admin-work-area">
  <div fxLayout fxLayoutAlign="center center">
    <span class="grid-title">{{initService.getConfig().titlePrefix}} FDT-M Parameters</span>
    <!-- Add Unit Floating Action Button -->
    <button mat-mini-fab class="add-button" (click)="add()">
      <img class="light-icon" src="./assets/icons/ic_add_white_24px.svg" matTooltip="Add Parameter" />
    </button>
  </div>
    <div fxLayout fxLayoutAlign="center center" class="filter-select">
            <mat-radio-group class="bordered" fxLayout="row" (change)="radioChange($event)" [(ngModel)]="showOptions">
                <mat-radio-button [value]="'all'">Show All</mat-radio-button>
                <mat-radio-button [value]="'system'">System</mat-radio-button>
                <mat-radio-button [value]="'command'">Command</mat-radio-button>
            </mat-radio-group>
            <mat-form-field>
            <mat-select [disabled]="showOptions != 'command'" placeholder="Command" [(ngModel)]="commandId" (selectionChange)="commandChange($event)">
                <mat-option *ngFor="let command of commands" [value]="command.commandId">{{command.name}}</mat-option>
            </mat-select>
            </mat-form-field>
    </div>
    <div class="mat-elevation-z8">
          <!-- Paginator -->
          <mat-paginator [pageSizeOptions]="[10, 20, 50, 100, 1000]" [pageSize]="20" [length]="dataSource?.data?.length" showFirstLastButtons></mat-paginator>
          <table mat-table [dataSource]="dataSource" matSort matSortStart="desc">
        <ng-container matColumnDef="level">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Level </th>
            <td mat-cell *matCellDef="let element"> {{element.level}} </td>
          </ng-container>

          <ng-container matColumnDef="command">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Command </th>
            <td mat-cell *matCellDef="let element"> {{getCommand(element)}} </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>

          <ng-container matColumnDef="component">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Component </th>
            <td mat-cell *matCellDef="let element"> {{element.componentName}} </td>
          </ng-container>

          <ng-container matColumnDef="string">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> String Value </th>
            <td mat-cell *matCellDef="let element"> {{element.stringValue}} </td>
          </ng-container>

          <ng-container matColumnDef="number">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Number Value </th>
            <td mat-cell *matCellDef="let element"> {{element.numberValue}} </td>
          </ng-container>

          <ng-container matColumnDef="datetime">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Value </th>
            <td mat-cell *matCellDef="let element"> {{element.datetimeValue | date: 'mediumDate'}} </td>
          </ng-container>

          <ng-container matColumnDef="blob_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Filename Value </th>
            <td mat-cell *matCellDef="let element"> <a href="javascript:void(0)" (click)="$event.stopPropagation()" (click)="download(element)">{{element.blobName}}</a></td>
          </ng-container>

        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef>
            <div>
              Delete
            </div>
          </th>
          <td mat-cell *matCellDef="let row" title="Delete Parameter">
            <button title="Delete Parameter" mat-button class="delete-button" (click)="$event.stopPropagation()" (click)="handleDelete(row)">
              <img src="./assets/icons/ic_delete_gray_24px.svg" />
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr class="hyper" matTooltip="Edit Parameter" matTooltipPosition="before" mat-row *matRowDef="let row; columns: displayedColumns;" (click)="edit(row)"></tr>
      </table>
    </div>
  </div>
