import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { EditModalBaseComponent } from '@fom-module/base-classes/edit-modal-base.component';
import { ActivityService } from '@services/data-services/activity.service';
import { Component, OnInit } from '@angular/core';
import { Activity } from '@models/activity.model';
import { Command } from '@models/command.model';
import { LookUpService } from '@services/data-services/lookup.service';
import { MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { CommandHierarchyService } from '@services/data-services/command-hierarchy.service';
import { User } from '@models/user.model';
import { CurrentUserService } from '@services/current-user-service';
import { CommandHierarchy } from '@models/command-hierarchy.model';

@Component({
  selector: 'app-edit-activity-modal',
  templateUrl: './edit-activity-modal.component.html',
  styleUrls: ['./edit-activity-modal.component.css']
})
export class EditActivityModalComponent extends EditModalBaseComponent implements OnInit {
  // Dropdown options
  commandOptions: Command[];
  user: User;
  commandHierarchy: CommandHierarchy;
  editForm: FormGroup;

  constructor(public activityService: ActivityService,
    public lookUpService: LookUpService,
    private commandHierarchyService: CommandHierarchyService,
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<EditActivityModalComponent>,
    private currentUserService: CurrentUserService) {
    super(activityService, dialogRef);
  }

  ngOnInit() {
    this.headerString = this.isNew ? 'Add Activity' :
      this.isDelete ? 'Delete Activity' :
      'Edit Activity'
    this.user = this.currentUserService.getCurrentUser();
    if (this.isNew) {
      this.entity.commandId = this.user.currentJFMCC ? this.user.currentJFMCC.commandId : this.user.commandId;
    }
    this.editForm = this.formBuilder.group({
      commandId: [{ value: this.entity.commandId, disabled: this.isDelete }],
      description: [{ value: this.entity.description, disabled: this.isDelete }],
      code: [{ value: this.entity.code, disabled: this.isDelete }],
      slideDescription: [{ value: this.entity.slideDescription, disabled: this.isDelete }]
    });
    Promise.resolve().then(() => {
      this.loadLookups();
    });
  }

  loadLookups() {
    this.lookUpService.getLookupByType(Command, true).pipe(take(1)).subscribe(result => {
      this.commandHierarchyService.getAll({ sort: 'version_num', limit: 1 }).pipe(take(1)).subscribe(json => {
        if (json && Array.isArray(json) && json.length) {
          this.commandHierarchy = json[0];

          let jfmccs;
          if (this.user.isSystemAdmin()) {
            jfmccs = this.commandHierarchy.getJFMCCs();
          } else {
            jfmccs = this.commandHierarchy.getJFMCCIds(this.user.command);
          }

          this.commandOptions = result.filter(item => {
            return jfmccs.includes(item.commandId);
          });
        } else {
          this.commandOptions = result;
        }
      });
    });
  }
}
