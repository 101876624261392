import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as jwt_decode from 'jwt-decode';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { GlobalStore } from '@services/global/message-bus/global-store.model';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
    constructor(private messageBusService: MessageBusService) {}
    intercept(req: HttpRequest<any>,
              next: HttpHandler): Observable<HttpEvent<any>> {

        const idToken = sessionStorage.getItem('token');

        if (idToken && !this.isTokenExpired(idToken)) {
            const cloned = req.clone({
                headers: req.headers.set('Authorization',
                    'Bearer ' + idToken)
            });

            return next.handle(cloned);
        }else if(idToken && this.isTokenExpired(idToken)){
            sessionStorage.clear()
            this.messageBusService.updateStore(new GlobalStore({isLoggedIn: false}));
            return next.handle(req)
        } else {
            return next.handle(req);
        }
    }

    isTokenExpired(token: string): boolean {
      const decoded = jwt_decode(token);
      let tokenDate = new Date(0)
      tokenDate.setUTCSeconds(decoded.exp)
      if (decoded.exp === undefined){ return true }; //<- Check for expiration value
      if (new Date(0).setUTCSeconds(decoded.exp) === undefined){ return true }; //<- check format of exp
      return (tokenDate < new Date())
    }
}
