import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from './api.config';

@Injectable()
export class PortApiService {
    SERVER_URL;

    constructor(
        private http: HttpClient, apiConfig: APIConfig) {
        this.SERVER_URL = apiConfig.TARGET_URL;
    }

    public getAllPorts(): Observable<any> {
        return this.http.get(
            `${this.SERVER_URL}/ports`);
    }

    public getPortByPortId(portId: string): Observable<any> {
        return this.http.get(
            `${this.SERVER_URL}/ports/${portId}`);
    }

    public updatePort(port: any): Observable<any> {
        return this.http.put(
            `${this.SERVER_URL}/ports/${port.port_id}`, port);
    }

    public createPort(port: any): Observable<any> {
        return this.http.post(
            `${this.SERVER_URL}/ports`, port);
    }

    public deletePort(port: any): Observable<any> {
        return this.http.delete(
            `${this.SERVER_URL}/ports/${port.port_id}`);
    }
}
