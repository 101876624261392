import { FomPageBaseComponent } from './fom-page-base.component';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DummyDataService } from '@services/data-services/dummy-data.service';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableDataSource } from '@angular/material/table';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { GlobalMessageTriggers } from '@services/global/message-bus/global-message-triggers.enum';
import { GlobalBusMessage } from '@services/global/message-bus/global-bus-message.model';
import { Router } from '@angular/router';
import { ConfirmationModalComponent } from '@core-module/app-components/confirmation-modal/confirmation-modal.component';
import { take } from 'rxjs/operators'

@Component({
  template: '<div></div>'
})
export abstract class GridBaseComponent extends FomPageBaseComponent implements OnInit {
  dataSource: any;
  entities: Array<any>;
  BOTTOM_NAV_BAR: any;
  EDIT_MODAL_COMPONENT: any;
  RELOAD_MESSAGE: GlobalMessageTriggers;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static:  true}) sort: MatSort;

  constructor(public messageBusService: MessageBusService,
    public entityService: DummyDataService,
    public route: Router) {
      super(messageBusService, route);
    }

  ngOnInit() {
    this.listenToMessageBus();
    this.loadEntityData();
    super.ngOnInit();
  }

  listenToMessageBus() {
    this.messageBusService.globalMessage$.subscribe(message => {
      if (message.trigger === this.RELOAD_MESSAGE) {
        this.loadEntityData();
      }
    });
  }

  loadEntityData() {
    (this.entityService as any).getAll().pipe(take(1)).subscribe(entities => {
      this.setDataSource(entities)
    });
  }

  // a common function that can be called from this, the base class, or from classes implementing this
  // some (most) edit lists will sort the entities and then call this to setup the dataSource
  setDataSource(entities) {
    this.entities = entities;
    this.dataSource = new MatTableDataSource<object>(this.entities);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = this.dataAccessor;
}
  // a custom getter for properties. if a property is displayed using dot notation 
  // because it is a property of a linked object, the getter needs to traverse the object

  dataAccessor = (item, property) => {
    if (property.includes('.')) {
      return property.split('.').reduce((o, p) => o && o[p], item)
    }
    return item ? item[property] : null;
  }


  // this is really no good since the data objects have non-displayed items (like guids)
  // that can bring positive results
  // need specific filterPredicates
  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  /*
  * This function is passed local context so it can be run
  * within another component. It is called by handleDelete()
  **/
  deleteEntity(context, args) {
    context.entityService.delete(args[0]).pipe(take(1)).subscribe(res => {
      context.loadEntityData();
      context.messageBusService.publishMessage(new GlobalBusMessage(GlobalMessageTriggers.CLOSE_MODAL));
    });
  }

  /* HTML Event Functions Below: **/

  handleRowClick(entity: any) {
    const editEntityModalData = {
      component: this.EDIT_MODAL_COMPONENT,
      data: {
        entity: entity
      }
    };
    const launchEditModalGlobalMessage: GlobalBusMessage = new GlobalBusMessage(GlobalMessageTriggers.LOAD_MODAL, editEntityModalData);
    this.messageBusService.publishMessage(launchEditModalGlobalMessage);
  }

  handleAddEntity() {
    const addEntityModalData = {
      component: this.EDIT_MODAL_COMPONENT,
      data: {
        isNew: true
      }
    };
    const launchEditModalGlobalMessage: GlobalBusMessage = new GlobalBusMessage(GlobalMessageTriggers.LOAD_MODAL, addEntityModalData);
    this.messageBusService.publishMessage(launchEditModalGlobalMessage);
  }

  handleDelete(entity: any, headerString = 'Delete Record?', 
    message = 'Select "Confirm" to delete this record', buttonText = 'Confirm') {
    this.messageBusService.publishMessage(
      new GlobalBusMessage(GlobalMessageTriggers.LOAD_MODAL,
                           {
                              component: ConfirmationModalComponent,
                              data: {
                                message: message,
                                headerString: headerString,
                                buttonText: buttonText,
                                action: this.deleteEntity,
                                context: this,
                                args: [entity]
                              }
                            })
    );
  }
}
