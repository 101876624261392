import { Injectable } from '@angular/core';
import { JsonConverter } from '@services/global/json-converter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FleetApiService } from '@services/api-services/fleet-api.service';
import { Fleet } from '@models/fleet.model';

@Injectable(
//   {
//   providedIn: 'root'
// }
)
export class FleetService {
  private jsonConvert: JsonConverter;

  constructor(private fleetApiService: FleetApiService) {
    this.jsonConvert = new JsonConverter();
  }

  public getAll(filters = {}): Observable<Fleet[]> {
    return this.fleetApiService.getAllFleets(filters)
      .pipe(
        map((data) => {
          return this.jsonConvert.deserializeArray(data, Fleet);
        })
      );
  }

  public getFleetByFleetId(fleetId: string): Observable<Fleet> {
    return this.fleetApiService.getFleetByFleetId(fleetId)
      .pipe(
        map((data) => {
          return this.jsonConvert.deserializeObject(data, Fleet);
        })
      );
  }

  public create(fleet: Fleet): Observable<Fleet> {
    return this.fleetApiService.createFleet(this.jsonConvert.serializeObject(fleet))
      .pipe(
        map((data) => {
          return this.jsonConvert.deserializeObject(data, Fleet);
        })
      );
  }

  public update(fleet: Fleet): Observable<Fleet> {
    return this.fleetApiService.updateFleet(this.jsonConvert.serializeObject(fleet))
      .pipe(
        map((data) => {
          return this.jsonConvert.deserializeObject(data, Fleet);
        })
      );
  }

  public delete(fleet: Fleet): Observable<any> {
    return this.fleetApiService.deleteFleet(this.jsonConvert.serializeObject(fleet))
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
}
