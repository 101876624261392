import { Component, OnInit } from '@angular/core';
import { ParameterService } from '@services/data-services/parameter.service';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { GlobalMessageTriggers } from '@services/global/message-bus/global-message-triggers.enum';
import { EditParameterModalComponent } from '@edit-modals/edit-parameter-modal/edit-parameter-modal.component';
import { GridBaseComponent } from '@fom-module/base-classes/grid-base.component';
import { Command } from '@models/command.model';
import { Parameter } from '@models/parameter.model';
import { LookUpService} from '@services/data-services/lookup.service';
import { take } from 'rxjs/operators';
import { AppInitService } from '../../../config/init.service';

@Component({
  selector: 'app-parameters',
  templateUrl: './parameters.component.html',
  styleUrls: ['./parameters.component.css', '../base-classes/grid-base.component.css']
})
export class ParametersComponent extends GridBaseComponent implements OnInit {
  showOptions = 'all';
  commandId: string = null;
  allParameters: [];

  displayedColumns = ['level', 'command', 'name', 'component', 'string', 'number', 'datetime', 'blob_name', 'delete'];

  paginator: MatPaginator;
  sort: MatSort;

  commands: [] = [];

  constructor(public messageBusService: MessageBusService,
    private lookupService: LookUpService,
    private dialog: MatDialog,
    public parameterService: ParameterService,
    public route: Router,
    public initService: AppInitService) {
      super(messageBusService, parameterService, route);
      this.RELOAD_MESSAGE = GlobalMessageTriggers.RELOAD_PORT_DATA;
      this.EDIT_MODAL_COMPONENT = EditParameterModalComponent;
  }

  radioChange(event) {
    this.filterParameters()
  }
  commandChange(event) {
    this.filterParameters()
  }
  filterParameters() {
    switch (this.showOptions) {
      case 'all':
        this.setDataSource(this.allParameters)
        break;
      case 'system':
        this.setDataSource(this.allParameters.filter(item => {
          return (item as any).level == 'System'
        }))
        break;
      case 'command':
        this.setDataSource(this.allParameters.filter(item => {
          return (item as any).level == 'Command' && (item as any).commandId == this.commandId
        }))
        break;
    }
  }

  ngOnInit() {
    //super.ngOnInit();
    this.lookupService.getLookupByType(Command, true).pipe(take(1)).subscribe(commands => {
      this.commands = commands
      this.loadEntityData();
    })
  }

  loadEntityData() {
    this.lookupService.getLookupByType(Parameter, true).pipe(take(1)).subscribe(entities => {
      this.allParameters = entities
      this.setDataSource(entities)
    });
  }
  setDataSource(entities) {
    this.entities = entities;
    this.dataSource = new MatTableDataSource<object>(this.entities);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
}

  getCommand(item) {
    let found = this.commands.find(element => {
      return (element as Command).commandId == item.commandId
    })
    if (found) {
      return (found as Command).name
    }
    else {
      return ''
    }
  }
  edit(parameter: any) {
    const dialogRef = this.dialog.open(EditParameterModalComponent, {
      width: '560px'
    });
    dialogRef.componentInstance.parameter = parameter
    dialogRef.componentInstance.isNew = false;

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
        this.loadEntityData()
    });

  }
  add() {
    const dialogRef = this.dialog.open(EditParameterModalComponent, {
      width: '560px'
    });
    dialogRef.componentInstance.parameter = new Parameter()
    dialogRef.componentInstance.isNew = true;

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      // in order to get complete data for the new Parameter
        this.loadEntityData()
    })
  }
  download(row) {
    const filename = row.blobName
    this.parameterService.getBlob(row.parameterId).pipe(take(1)).subscribe(response => {
      const file = new Blob([response]);
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(file); // xhr.response is a blob
      a.style.display = 'none';
      a.download = filename;
      document.body.appendChild(a);
      a.click();
    })
  }
}
