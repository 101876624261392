import { GlobalMessageTriggers } from '@services/global/message-bus/global-message-triggers.enum';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { AuthenticationService } from '@services/data-services/authentication.service';
import { DynamicComponentInterface } from '../../../core/app-components/dynanmic-component/dynamic-component.interface';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { GlobalBusMessage } from '@services/global/message-bus/global-bus-message.model';
import { GlobalStore } from '@services/global/message-bus/global-store.model';
import { AppInitService } from '../../../../config/init.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.css']
})
export class LoginModalComponent implements OnInit, DynamicComponentInterface {
  dynamicData: any;
  public loginForm: FormGroup;
  public authServiceURL = '';

  constructor(private AuthenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private messageBusService: MessageBusService,
    public init: AppInitService,
    private dialog: MatDialogRef<any>) {
    }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: [''],
      password: ['']
    });

    this.authServiceURL = this.init.getConfig().PKI.authServiceRedirectURL;
  }

  resolveDynamicComponentClass() {
    return LoginModalComponent;
  }

  handleLoginClick() {
    this.AuthenticationService.login(this.loginForm.controls['username'].value,
      this.loginForm.controls['password'].value)
      .subscribe(result => {
        this.dialog.close(true)
    });
  }

  handleCancel() {
    if (sessionStorage.getItem('token') ) { sessionStorage.removeItem('token'); }
    if (sessionStorage.getItem('currentUserId') ) { sessionStorage.removeItem('currentUserId'); }
    this.messageBusService.updateStore(new GlobalStore({isLoggedIn: false}));
    this.messageBusService.publishMessage(new GlobalBusMessage(GlobalMessageTriggers.CLOSE_MODAL));
  }

  loginWithCert() {
    window.open(this.authServiceURL, '_self')
  }
}
