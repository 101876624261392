import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from './api.config';

@Injectable()
export class ConfidenceCodeApiService {
    SERVER_URL;

    constructor(
        private http: HttpClient, apiConfig: APIConfig) {
        this.SERVER_URL = apiConfig.TARGET_URL;
    }

    public getConfidenceCode(params: any): Observable<any> {
        return this.http.post(
            `${this.SERVER_URL}/confidence-codes/confidence-code`, params);
    }

    public getAllConfidenceCodes(): Observable<any> {
        return this.http.get(
            `${this.SERVER_URL}/confidence-codes`);
    }

    public getConfidenceCodeByConfidenceCodeId(confidenceCodeId: string): Observable<any> {
        return this.http.get(
            `${this.SERVER_URL}/confidence-codes/${confidenceCodeId}`);
    }

    public updateConfidenceCode(confidenceCode: any): Observable<any> {
        return this.http.put(
            `${this.SERVER_URL}/confidence-codes/${confidenceCode.confidence_code_id}`,confidenceCode);
    }

    public createConfidenceCode(confidenceCode: any): Observable<any> {
        return this.http.post(
            `${this.SERVER_URL}/confidence-codes`, confidenceCode);
    }

    public deleteConfidenceCode(confidenceCode: any): Observable<any> {
        return this.http.delete(
            `${this.SERVER_URL}/confidence-codes/${confidenceCode.confidence_code_id}`);
    }
}
