<div class="narrow-card">
<mat-card class="form-card">
  <div fxLayout fxLayoutAlign="center center" class="simple-grid-title">
    <span class="grid-title">Download {{initService.getConfig().titlePrefix}} Naval Order of Battle</span>
  </div>
    <div *ngIf="status == 'in progress'">
      <mat-progress-bar
          class="nob-margin"
          [color]="'primary'"
          [mode]="'indeterminate'"
      >
      </mat-progress-bar>
    </div>
    <div fxLayout fxLayoutAlign="center center">
      <button [disabled]="status == 'in progress'" mat-raised-button color="primary" (click)="getNobReport()">Get NOB</button>
      &nbsp;
      <button *ngIf="status == 'in progress'" mat-raised-button color="warn" (click)="cancel()">Cancel</button>
    </div>
</mat-card>
</div>
