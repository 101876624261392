import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter } from '../services/global/date-converter';

@JsonObject('Threat')
export class Threat {
  @JsonProperty('threat_id', String, true)
  threatId: string = undefined;
  @JsonProperty('threat_name', String, true)
  threatName: string = undefined;
  @JsonProperty('created_by', String, true)
  createdBy: string = undefined;
  @JsonProperty('create_ts', DateConverter, true)
  dateCreated: Date = undefined;

  static lookupName(): string {
    return 'threats';
  }
}
