import { PicklistPreferences } from '@models/picklist-preferences.model';
import { PicklistPrefApiService } from '@services/api-services/picklist-pref-api.service';
import { Injectable } from '@angular/core';
import { JsonConverter } from '@services/global/json-converter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class PicklistPrefService {
    private jsonConvert: JsonConverter;

    constructor(private picklistPrefApiService: PicklistPrefApiService) {
        this.jsonConvert = new JsonConverter();
    }

    public getAll(params = {}): Observable<PicklistPreferences[]> {
        return this.picklistPrefApiService.getAllPicklistPrefs(params)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, PicklistPreferences);
                })
            );
    }
    public savePreferences(data: any): Observable<any> {
        return this.picklistPrefApiService.savePreferences(this.jsonConvert.serializeArray(data, PicklistPreferences))
            .pipe(
                map((data) => {
                    return 'ok';
                })
            );
    }
    public getPicklistTypeByPicklistTypeId(picklistPrefId: string): Observable<PicklistPreferences> {
        return this.picklistPrefApiService.getPicklistPrefsByPicklistPrefsId(picklistPrefId)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, PicklistPreferences);
                })
            );
    }

    public update(picklistPref: PicklistPreferences): Observable<PicklistPreferences> {
        return this.picklistPrefApiService.updatePicklistPrefs(this.jsonConvert.serializeObject(picklistPref))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, PicklistPreferences);
                })
            );
    }

    public create(picklistPref: PicklistPreferences): Observable<PicklistPreferences> {
        return this.picklistPrefApiService.createPicklistType(this.jsonConvert.serializeObject(picklistPref))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, PicklistPreferences);
                })
            );
    }

    public delete(picklistPref: PicklistPreferences): Observable<PicklistPreferences> {
        return this.picklistPrefApiService.deletePicklistType(this.jsonConvert.serializeObject(picklistPref))
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }
}
