import { JsonObject, JsonProperty, Any } from 'json2typescript';
import { DateConverter } from '../services/global/date-converter';

@JsonObject('Historical')
export class Historical {
  @JsonProperty('unit_id', String, true)
  unit: string = undefined;
  @JsonProperty('activity', String, true)
  activityName: string = undefined;
  @JsonProperty('activity_category_id', String, true)
  activity: string = undefined;
  @JsonProperty('country_id', String, true)
  country: string = undefined;
  @JsonProperty('country_name', String, true)
  countryName: string = undefined;
  @JsonProperty('classification_description', String, true)
  classificationDescription: string = undefined;
  @JsonProperty('classification_name', String, true)
  classification: string = undefined;
  @JsonProperty('classification_string', String, true)
  classificationString: string = undefined;
  @JsonProperty('fleet_code', String, true)
  fleet: string = undefined;
  @JsonProperty('unit_name', String, true)
  unitName: string = undefined;
  @JsonProperty('days_out', Number, true)
  daysOut: number = undefined;
  @JsonProperty('final_name', String, true)
  finalName: string = undefined;
  @JsonProperty('dtg_imaged_ts', DateConverter, true)
  dtgImaged: Date = undefined;
  @JsonProperty('last_loc', String, true)
  lastLoc: string = undefined;
  @JsonProperty('latitude', String, true)
  latitude: string = undefined;
  @JsonProperty('longitude', String, true)
  longitude: string = undefined;
  @JsonProperty('last_loc_src', String, true)
  lastLocSrc: string = undefined;
  @JsonProperty('source_name', String, true)
  sourceName: string = undefined;
  @JsonProperty('port_name', String, true)
  portName: string = undefined;
  @JsonProperty('src_orig', String, true)
  srcOrig: string = undefined;
  @JsonProperty('loc_time_ts', DateConverter, true)
  locTime: Date = undefined;
  @JsonProperty('activity_category_name', String, true)
  activityCategoryName: String = undefined;
  @JsonProperty('activity_category_code', String, true)
  activityCategoryCode: String = undefined;
  @JsonProperty('sconum', String, true)
  sconum: String = undefined;
  @JsonProperty('uw_code', String, true)
  uwCode: String = undefined;
  @JsonProperty('op_area_with_remarks', String, true)
  opArea: String = undefined;
  @JsonProperty('new_days', Number, true)
  newDays: number = undefined;
  @JsonProperty('latency', Number, true)
  latency: number = undefined;
  @JsonProperty('category_description', String, true)
  category: String = undefined;
  @JsonProperty('command_name', String, true)
  commandName: String = undefined;
  @JsonProperty('create_ts', DateConverter, true)
  createTimestamp: Date = undefined;
  @JsonProperty('confidence_code', String, true)
  confidenceCode: String = undefined;
  /*@JsonProperty('history_id', String, true)
  historyId: string = undefined;*/
  /*@JsonProperty('archive_ts', String, true)
  archive: Date = undefined;*/
  /*@JsonProperty('created_by', String, true)
  createdBy: string = undefined;*/
}
