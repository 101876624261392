<div class="narrow-card">
<mat-card class="form-card">
  <div class="grid-container">
    <div fxLayout fxLayoutAlign="center center" class="simple-grid-title">
      <span class="grid-title">{{initService.getConfig().titlePrefix}} FDT-M Google Earth History (KML)</span>
    </div>
    <div class="mat-elevation-z8">
   <mat-card-content>
     <div fxFlex="row">
      <div fxFlex="80%" class="panel">
        <h4>Search Parameters</h4>
        <form [formGroup]="googleEarthHistoryForm">
          <mat-card-content class="fom-card-content">
            <mat-form-field>
              <input matInput [owlDateTime]="fromDate" class="shadow-input"
                tabindex="1"
                formControlName="fromDate" required
                placeholder="Start Time"
              >
              <input [owlDateTimeTrigger]="fromDate" readonly
                  [value]="filterValue('fromDate')"
                  class="shadow-trigger mat-input-element"
              >
              <owl-date-time #fromDate></owl-date-time>
            </mat-form-field>
            <br>
            <mat-form-field>
              <input matInput [owlDateTime]="toDate" class="shadow-input"
                tabindex="2"
                formControlName="toDate" required
                placeholder="End Time"
              >
              <input [owlDateTimeTrigger]="toDate" readonly
                  [value]="filterValue('toDate')"
                  class="shadow-trigger mat-input-element"
              >
              <owl-date-time #toDate></owl-date-time>
              <!-- <input matInput placeholder="YYYY-MM-DD HH:MM" required formControlName="toDate"> -->
            </mat-form-field>
          </mat-card-content>
          <button [disabled]="!googleEarthHistoryForm.valid" mat-raised-button color="primary" (click)="getKML()">Get KML</button>
        </form>

    </div>
    </div>
   </mat-card-content>
  </div>
</div>
</mat-card>
</div>





