import { PicklistType } from '@models/picklist-type.model';
import { PicklistTypeApiService } from '@services/api-services/picklist-type-api.service';
import { Injectable } from '@angular/core';
import { JsonConverter } from '@services/global/json-converter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class PicklistTypeService {
    private jsonConvert: JsonConverter;

    constructor(private picklistTypeApiService: PicklistTypeApiService) {
        this.jsonConvert = new JsonConverter();
    }

    public getAll(): Observable<PicklistType[]> {
        return this.picklistTypeApiService.getAllPicklistTypes()
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, PicklistType);
                })
            );
    }

    public getPicklistTypeByPicklistTypeId(picklistTypeId: string): Observable<PicklistType> {
        return this.picklistTypeApiService.getPicklistTypeByPicklistTypeId(picklistTypeId)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, PicklistType);
                })
            );
    }

    public update(picklistType: PicklistType): Observable<PicklistType> {
        return this.picklistTypeApiService.updatePicklistType(this.jsonConvert.serializeObject(picklistType))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, PicklistType);
                })
            );
    }

    public create(picklistType: PicklistType): Observable<PicklistType> {
        return this.picklistTypeApiService.createPicklistType(this.jsonConvert.serializeObject(picklistType))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, PicklistType);
                })
            );
    }

    public delete(picklistType: PicklistType): Observable<PicklistType> {
        return this.picklistTypeApiService.deletePicklistType(this.jsonConvert.serializeObject(picklistType))
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }
}
