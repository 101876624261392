import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ErrorService {

    constructor() {}

    getClientMessage(error: Error): string {
        if (!navigator.onLine) {
            return 'No Internet Connection';
        }
        return error.message ? error.message : error.toString();
    }

    getClientStack(error: Error): string {
        return error.stack;
    }

    getServerMessage(error: HttpErrorResponse): string {
        let message = error.message;

        // Add message from the response body if present.
        if (error.error.message) {
            message += ` ${error.error.message}`;
        }

        return message;
    }

    getServerStack(error: HttpErrorResponse): string {
        try {
            return this.getErrorCode(error.headers);
        } catch {
            return null;
        }
    }

    getErrorCode(headers) {
        if (headers.get('ErrorHeader')) {
            return headers.get('ErrorHeader');
        }
        return null;
    }
}
