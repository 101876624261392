import { LookUpService } from '@services/data-services/lookup.service';
import { ElnotService } from '@services/data-services/elnot.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Category } from '@models/category.model';
import { Command } from '@models/command.model';
import { EditModalBaseComponent } from '@fom-module/base-classes/edit-modal-base.component';
import { MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators'
import { CommandHierarchyService } from '@services/data-services/command-hierarchy.service';
import { User } from '@models/user.model';
import { CurrentUserService } from '@services/current-user-service';
import { CommandHierarchy } from '@models/command-hierarchy.model';

@Component({
  selector: 'app-edit-elnot-modal',
  templateUrl: './edit-elnot-modal.component.html',
  styleUrls: ['./edit-elnot-modal.component.css']
})
export class EditElnotModalComponent extends EditModalBaseComponent implements OnInit {
  // Dropdown options
  commandOptions: Command[];
  user: User;
  editForm: FormGroup;
  commandHierarchy: CommandHierarchy;
  constructor(public elnotService: ElnotService,
    public lookUpService: LookUpService,
    private commandHierarchyService: CommandHierarchyService,
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<EditElnotModalComponent>,
    private currentUserService: CurrentUserService) {
    super(elnotService, dialogRef);
  }

  ngOnInit() {
    this.headerString = this.isNew ? 'Add ELNOT' :
    this.isDelete ? 'Delete ELNOT' :
    'Edit ELNOT'
    this.user = this.currentUserService.getCurrentUser();

    if (this.isNew) {
      this.entity.commandId = this.user.currentJFMCC ? this.user.currentJFMCC.commandId : this.user.commandId;
    }
    this.editForm = this.formBuilder.group({
      commandId: [{ value: this.entity.commandId, disabled: this.isDelete }],
      el_not_code: [{ value: this.entity.el_not_code, disabled: this.isDelete }]
    });
    Promise.resolve().then(() => {
      this.loadLookups();
    });
  }
  loadLookups() {
    this.lookUpService.getLookupByType(Command, true).pipe(take(1)).subscribe(result => {
      this.commandHierarchyService.getAll({ sort: 'version_num', limit: 1 }).pipe(take(1)).subscribe(json => {
        if (json && Array.isArray(json) && json.length) {
          this.commandHierarchy = json[0];

          let jfmccs;
          if (this.user.isSystemAdmin()) {
            jfmccs = this.commandHierarchy.getJFMCCs();
          } else {
            jfmccs = this.commandHierarchy.getJFMCCIds(this.user.command);
          }

          this.commandOptions = result.filter(item => {
            return jfmccs.includes(item.commandId);
          });
        } else {
          this.commandOptions = result;
        }
      });
    });
  }
}
