import { FomPageBaseComponent } from '@fom-module/base-classes/fom-page-base.component';
import { Router } from '@angular/router';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.css']
})
export class AnalysisComponent extends FomPageBaseComponent implements OnInit {
  constructor(public messageBusService: MessageBusService, public route: Router) {
    super(messageBusService, route);
  }

  ngOnInit() {
    super.ngOnInit();
  }

}
