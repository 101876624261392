import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserComponent } from './user/user.component';
import { HomeComponent } from './home/home.component';
import { UnitsComponent } from './units/units.component';
import { CommandsComponent } from './commands/commands.component';
import { UnitUpdateComponent } from './unit-update/unit-update.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ChartsModule } from 'ng2-charts';
import { CovalentFileModule } from '@covalent/core/file';
import { CovalentLayoutModule } from '@covalent/core/layout';
import { CovalentStepsModule } from '@covalent/core/steps';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { MatFormFieldModule } from '@angular/material/form-field';
import {CdkTableModule} from '@angular/cdk/table';
import {DragDropModule} from '@angular/cdk/drag-drop';

import { AnalysisComponent } from './analysis/analysis.component';
import { ProductsComponent } from './products/products.component';
import { EditUnitModalComponent } from './edit-modals/edit-unit-modal/edit-unit-modal.component';
import { EditUserModalComponent } from './edit-modals/edit-user-modal/edit-user-modal.component';
import { EditParserQueueModalComponent } from './edit-modals/edit-parser-queue-modal/edit-parser-queue-modal.component';
import { EditCategoryModalComponent} from './edit-modals/edit-category-modal/edit-category-modal.component';
import { EditCommandModalComponent } from './edit-modals/edit-command-modal/edit-command-modal.component';
import { EditOwnerTypeModalComponent } from './edit-modals/edit-owner-type-modal/edit-owner-type-modal.component';
import { EditPicklistTypeModalComponent } from './edit-modals/edit-picklist-type-modal/edit-picklist-type-modal.component';
import { EditActivityModalComponent } from './edit-modals/edit-activity-modal/edit-activity-modal.component';
import { EditClassificationModalComponent } from './edit-modals/edit-classification-modal/edit-classification-modal.component';
import { EditDataTagModalComponent } from './edit-modals/edit-data-tag-modal/edit-data-tag-modal.component';
import { EditSourceModalComponent } from './edit-modals/edit-source-modal/edit-source-modal.component';
import { EditSlideModalComponent } from './edit-modals/edit-slide-modal/edit-slide-modal.component';
import { EditFgiModalComponent } from './edit-modals/edit-fgi-modal/edit-fgi-modal.component';
import { EditLocationModalComponent } from './edit-modals/edit-location-modal/edit-location-modal.component';
import { EditSystemConnectionModalComponent } from './edit-modals/edit-system-connection-modal/edit-system-connection-modal.component';
import { EditMessageConfigModalComponent } from './edit-modals/edit-message-config-modal/edit-message-config-modal.component';
import { KMLComponent } from './analysis/kml/kml.component';
import { UnitSearchComponent } from './analysis/unit-search/unit-search.component';
import { CurrentNobExcelComponent } from './products/current-nob-excel/currentNobExcel.component';
import { RecordMessageComponent } from './products/record-message/recordMessage.component';
import { CurrentNobComponent } from './products/current-nob/current-nob.component';
import { DataCardComponent } from './analysis/data-cards/data-cards.component';
import { OptempoComponent } from './analysis/optempo/optempo.component';
import { LocatorComponent } from './products/locator/locator.component';
import { RSSComponent } from './products/rss/rss.component';
import { EditPortModalComponent} from './edit-modals/edit-port-modal/edit-port-modal.component';
import { NobTickerComponent } from './products/nob-ticker/nob-ticker.component';
import { EditAreaModalComponent} from './edit-modals/edit-area-modal/edit-area-modal.component';
import { EditCountryModalComponent } from './edit-modals/edit-country-modal/edit-country-modal.component';
import { EditFleetModalComponent } from './edit-modals/edit-fleet-modal/edit-fleet-modal.component';
import { EditShipTypeModalComponent } from './edit-modals/edit-ship-type-modal/edit-ship-type-modal.component';
import { EditShipClassModalComponent } from './edit-modals/edit-ship-class-modal/edit-ship-class-modal.component';
import { EditVesselTypeModalComponent } from './edit-modals/edit-vessel-type-modal/edit-vessel-type-modal.component';
import { EditFreqTypeModalComponent } from "./edit-modals/edit-freq-type-modal/edit-freq-type-modal.component";
import { EditScanTypeModalComponent } from "./edit-modals/edit-scan-type-modal/edit-scan-type-modal.component";
import { EditElintFunctionTypeModalComponent } from "./edit-modals/edit-elint-function-type-modal/edit-elint-function-type-modal.component";
import { EditConfidenceCodeModalComponent } from './edit-modals/edit-confidence-code-modal/edit-confidence-code-modal.component';
import { GeoRefsComponent } from './geo-refs/geo-refs.component';
import { EditGeoRefModalComponent } from './edit-modals/edit-geo-ref-modal/edit-geo-ref-modal.component';
import { ParserQueueComponent } from './parser-queue/parser-queue.component';
import { COIComponent } from './coi/coi.component';
import { ServiceDashboardComponent } from './service-dashboard/service-dashboard.component';
import { HasCommandPipe } from '../core/pipes/has-command.pipe';
import { ParametersComponent } from './parameters/parameters.component';
import { EditParameterModalComponent } from './edit-modals/edit-parameter-modal/edit-parameter-modal.component';
import { FileSizePipe } from '../core/pipes/file-size.pipe';
import { Zulu1Pipe } from '../core/pipes/zulu1.pipe';
import { LocatorPipe } from '@core-module/pipes/locator.pipe';
import { UnitNamePipe } from '@core-module/pipes/unit-name.pipe';
import { UnitOtherNamePipe } from '@core-module/pipes/unit-other-name.pipe';
import { PacmisComponent } from './products/pacmis/pacmis.component';
import { UnitInfoComponent } from './unit-info/unit-info.component';
import { LocationFiltersComponent } from '../core/app-components/location-filters/location-filters.component'
import { UnitFiltersComponent } from '../core/app-components/unit-filters/unit-filters.component'
import { AboutComponent } from '@core-module/app-components/about-component/about.component';
import { PocComponent } from './point-of-contacts/point-of-contact.component';
import { EditPocModalComponent } from './edit-modals/edit-poc-modal/edit-poc-modal.component';
import { RequestAccountModalComponent } from './edit-modals/request-account-modal/request-account-modal.component';
import { AccountRequestComponent } from './account-request/account-request.component'
import { EditAccountRequestModalComponent } from './edit-modals/edit-account-request-modal/edit-account-request.component';
import { LookupSelectComponent } from './reusable/lookup-select/lookup-select.component';
import { SearchFilterControlComponent } from '@core-module/app-components/search-filter-control/search-filter-control.component';
import { OwnerTypeComponent } from './owner-type/owner-type.component'
import { PicklistTypeComponent} from './picklist-type/picklist-type.component';
import { CommandGraphComponent } from './command-graph/command-graph.component';
import { DenyAccountRequestModalComponent } from './edit-modals/deny-account-request-modal/deny-account-request.component';
import { CollaborationPortalComponent } from './collaboration-portal/collaboration-portal.component';
import { EditCollaborationModalComponent } from './edit-modals/edit-collaboration-modal/edit-collaboration-modal.component';
import { EditControlModalComponent } from './edit-modals/edit-control-modal/edit-control-modal.component';
import { EditOtherControlModalComponent } from './edit-modals/edit-other-control-modal/edit-other-control-modal.component';
import { EditReleasabilityModalComponent } from './edit-modals/edit-releasability-modal/edit-releasability-modal.component';
//import { GridBaseComponent } from './base-classes/grid-base.component';
import { FomPageBaseComponent } from './base-classes/fom-page-base.component';
import { EditModalBaseComponent } from './base-classes/edit-modal-base.component';
//import { Idle } from 'idlejs';
import { BlueSearchComponent } from './analysis/blue-search/blue-search.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { FdtmModalHeaderComponent } from './edit-modals/fdtm-modal-header/fdtm-modal-header.component';
import { LoginModalComponent } from './edit-modals/login-modal/login-modal.component';
// date time picker...
import {  OwlDateTimeModule, OwlNativeDateTimeModule,
  OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE, MomentDateTimeAdapter, OwlMomentDateTimeModule,
  OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS } from '@danielmoncada/angular-datetime-picker';
import { CoreModule } from '@core-module/core.module';
import { TrackMaintenanceComponent } from './track-maintenance/track-maintenance.component';
import { VersionHistoriesModalComponent } from './edit-modals/version-histories-modal/version-histories-modal.component';
import { BulkEditLocationModalComponent } from './edit-modals/bulk-edit-location-modal/bulk-edit-location-modal.component';
import { BulkDeleteModal } from './edit-modals/bulk-delete-modal/bulk-delete-modal.component';
import { OtherNameTableModal } from './other-name-table-modal/other-name-table-modal.component';
import { EditOtherNameModalComponent } from './edit-modals/edit-other-name-modal/edit-other-name-modal.component';
import { UnitDetailsComponent } from './unit-details/unit-details.component';
import { PreferencesComponent } from './admin/admin-screens/preferences/preferences.component';
import { EditFeaturePreferenceModalComponent } from './edit-modals/edit-feature-preference-modal/edit-feature-preference-modal.component';
import { RecordMessagePopupModalComponent } from '@fom-module/edit-modals/recordmessage-popup-modal/recordmessage-popup-modal.component';
import { ParserQueueBComponent } from './parser-queue-b/parser-queue-b.component';
import { TableWrapperComponent} from './parser-queue/table-wrapper/template/table-wrapper.component'
import { ArraySortPipe } from '@core-module/pipes/sort.pipe';
import { ArrayFilterPipe } from '@core-module/pipes/filter.pipe';
import { EditElnotModalComponent } from './edit-modals/edit-elnot-modal/edit-elnot-modal.component'
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule} from '@angular-material-components/datetime-picker';
import { EditFDOMapModalComponent } from './edit-modals/edit-fdo-map-modal/edit-fdo-map-modal.component';
import { EditClassMarkRelationshipModalComponent } from './edit-modals/edit-classification-marking-relationship-modal/edit-class-mark-relationship-modal.component';


export const ZULU_CUSTOM_FORMATS = {
  parseInput: 'DD MMM GGGG HHmm[Z]',
  fullPickerInput: 'DD MMM GGGG HHmm[Z]',
  // datePickerInput: 'LL',
  // timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};
@NgModule({
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    ChartsModule,
    CovalentFileModule,
    CovalentLayoutModule,
    CovalentStepsModule,
    MatFormFieldModule,
    OwlDateTimeModule,
    OwlMomentDateTimeModule,
    CdkTableModule,
    DragDropModule,
    ScrollingModule,
    TableVirtualScrollModule,
    CoreModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
  ],
  declarations: [
    UserComponent,
    HomeComponent,
    AboutComponent,
    UnitsComponent,
    CommandsComponent,
    AnalysisComponent,
    ProductsComponent,
    EditUnitModalComponent,
    EditUserModalComponent,
    EditActivityModalComponent,
    EditClassificationModalComponent,
    EditParserQueueModalComponent,
    EditDataTagModalComponent,
    EditFDOMapModalComponent,
    EditClassMarkRelationshipModalComponent,
    EditFgiModalComponent,
    EditCategoryModalComponent,
    EditCommandModalComponent,
    EditOwnerTypeModalComponent,
    EditPicklistTypeModalComponent,
    EditSystemConnectionModalComponent,
    EditMessageConfigModalComponent,
    UnitUpdateComponent,
    KMLComponent,
    CurrentNobComponent,
    CurrentNobExcelComponent,
    RecordMessageComponent,
    UnitSearchComponent,
    DataCardComponent,
    OptempoComponent,
    LocatorComponent,
    RSSComponent,
    EditPortModalComponent,
    NobTickerComponent,
    LookupSelectComponent,
    EditAreaModalComponent,
    EditCountryModalComponent,
    EditSourceModalComponent,
    EditSlideModalComponent,
    EditFleetModalComponent,
    EditShipTypeModalComponent,
    EditShipClassModalComponent,
    EditVesselTypeModalComponent,
    EditFreqTypeModalComponent,
    EditScanTypeModalComponent,
    EditElintFunctionTypeModalComponent,
    EditConfidenceCodeModalComponent,
    GeoRefsComponent,
    EditGeoRefModalComponent,
    ParserQueueComponent,
    COIComponent,
    ServiceDashboardComponent,
    HasCommandPipe,
    ParametersComponent,
    EditParameterModalComponent,
    PocComponent,
    EditPocModalComponent,
    FileSizePipe,
    Zulu1Pipe,
    UnitNamePipe,
    UnitOtherNamePipe,
    PacmisComponent,
    UnitInfoComponent,
    LocationFiltersComponent,
    UnitFiltersComponent,
    SearchFilterControlComponent,
    OwnerTypeComponent,
    PicklistTypeComponent,
    RequestAccountModalComponent,
    AccountRequestComponent,
    EditAccountRequestModalComponent,
    CommandGraphComponent,
    DenyAccountRequestModalComponent,
    CollaborationPortalComponent,
    EditCollaborationModalComponent,
    EditControlModalComponent,
    EditOtherControlModalComponent,
    EditReleasabilityModalComponent,
    //GridBaseComponent,
    FomPageBaseComponent,
    EditModalBaseComponent,
    BlueSearchComponent,
    LoginModalComponent,
    FdtmModalHeaderComponent,
    TrackMaintenanceComponent,
    EditLocationModalComponent,
    VersionHistoriesModalComponent,
    BulkEditLocationModalComponent,
    BulkDeleteModal,
    OtherNameTableModal,
    EditOtherNameModalComponent,
    LocatorPipe,
    UnitDetailsComponent,
    PreferencesComponent,
    EditFeaturePreferenceModalComponent,
    RecordMessagePopupModalComponent,
    ParserQueueBComponent,
    TableWrapperComponent,
    ArraySortPipe,
    ArrayFilterPipe,
    EditElnotModalComponent
  ],
  exports: [
    UserComponent,
    LoginModalComponent,
    FdtmModalHeaderComponent
  ],
  providers: [
   //Idle,
   FormBuilder,
   Zulu1Pipe,
   UnitNamePipe,
   UnitOtherNamePipe,
   LocatorPipe,
   ArraySortPipe,
   ArrayFilterPipe,
   { provide: OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS, useValue: { useUtc: true } },
   {provide: MomentDateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE, OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS]},

   {provide: OWL_DATE_TIME_FORMATS, useValue: ZULU_CUSTOM_FORMATS},
  ]
})
export class FomModule { }
