import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter } from '../services/global/date-converter';
import { Command } from '@models/command.model';

@JsonObject('GeoRef')
export class GeoRef {
    @JsonProperty('geo_ref_id', String, true)
    geoRefId: string = undefined;
    @JsonProperty('geo_ref_name', String, true)
    name: string = undefined;
    @JsonProperty('geo_ref_area', String, true)
    area: string = undefined;
    @JsonProperty('geo_ref_latitude', String, true)
    latitude: string = undefined;
    @JsonProperty('geo_ref_longitude', String, true)
    longitude: string = undefined;
    @JsonProperty('command_id', String, true)
    commandId: string = undefined;
    @JsonProperty('command', Command, true)
    command: Command = undefined;
    @JsonProperty('create_ts', DateConverter, true)
    dateCreated: Date = undefined;
    @JsonProperty('created_by', String, true)
    createdBy: string = undefined;
}
