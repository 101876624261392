import {JsonObject, JsonProperty, Any} from 'json2typescript';

@JsonObject('Permission')
export class Permission {
  @JsonProperty('permission_id', String, true)
  permissionId: string = undefined;
  @JsonProperty('permission_level', Number, true)
  permissionLevel: number = undefined;
  @JsonProperty('permission_description', String, true)
  permissionDescription: string = undefined;
  @JsonProperty('created_by', String, true)
  createdBy: string = undefined;

  static lookupName(): string {
    return 'permissions';
  }
}
