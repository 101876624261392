<app-classification-bar class="top-modal-classification-bar"></app-classification-bar>
<app-fdtm-modal-header [headerString]="'Deny Account Request'"></app-fdtm-modal-header>
<div mat-dialog-content>
    <mat-form-field class="deny-form">
      <mat-label>Reason for denial</mat-label>
      <input matInput name="reason" minlength="5"
             required
             [(ngModel)]="reason"
             matTooltip="This message will be sent to the user"
             class="deny-input">
      <mat-hint>Enter a message to be sent to the requester (5 or more characters)</mat-hint>       
    </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" [disabled]="reason.length < 5"
    matTooltip="The request will be removed from queue and message sent to the user" (click)="deny()">Deny</button>
  <button mat-raised-button (click)="cancel()">Cancel</button>
</div>
<app-classification-bar class="bottom-modal-classification-bar"></app-classification-bar>

