import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from './api.config';
import {of} from  'rxjs'
import { Unit } from '@models/unit.model';

@Injectable()
export class UnitApiService {

  SERVER_URL;

  constructor(
    private http: HttpClient, apiConfig: APIConfig) {
    this.SERVER_URL = apiConfig.TARGET_URL;
  }

  public createCOI(params: any): Observable<any> {
    return this.http.post(
      `${this.SERVER_URL}/units/coi`, params);
  }

  public find(queryParams: any): Observable<any> {
    let httpParams = new HttpParams();

    for (const key in queryParams) {
      if (queryParams.hasOwnProperty(key)) {
        httpParams = httpParams.set(key, queryParams[key]);
      }
    }

    return this.http.get(
      `${this.SERVER_URL}/units/find`, { params: httpParams });
  }

  // build queries based on unit values
  uniqueFieldsSearch(unit: Unit): Observable<any> {
    return this.http.get(
      `${this.SERVER_URL}/units/uniqueFields?class=${unit.class}&type=${unit.type}&pennant=${unit.pennant}&sconum=${unit.sconum}&midb=${unit.midb}&limit=20`);
  }

  // the query string will be partials of the unit name
  public findUnits(value, filters = {}, limit = 20, offset = 0): Observable<any> {
    // so... the ui control wants to send another find when user selects...
    // could not find how to prevent, just eat it
    if (typeof value != 'string') {
      return of<Response>();
    }

    let httpParams = new HttpParams();
    // add query string, limit, and offset
    httpParams = httpParams.set('queryString', value).set('limit', limit).set('offset', offset);

    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        httpParams = httpParams.set(key, filters[key]);
      }
    }

    return this.http.get(`${this.SERVER_URL}/units/findUnits`, {params: httpParams});
  }

  public getAllUnits(filters = {}): Observable<any> {
    let httpParams = new HttpParams();

    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        httpParams = httpParams.set(key, filters[key]);
      }
    }
    return this.http.get(
      `${this.SERVER_URL}/units`, {params: httpParams});
  }

  public getUnitOtherNames(unitId: string): Observable<any> {
    return this.http.get(
      `${this.SERVER_URL}/units/unitOtherNames/${unitId}`);
  }

  public getCommandUnits(commandId: string): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('userCommandId', commandId);

    return this.http.get(
      `${this.SERVER_URL}/units/getCommandUnits`, {params: httpParams});
  }

  public getUnitByUnitId(unitId: string, params: any = {}): Observable<any> {
    let httpParams = new HttpParams();

    for (const key in params) {
      httpParams = httpParams.set(key, params[key]);
    }

    return this.http.get(
      `${this.SERVER_URL}/units/${unitId}`, { params: httpParams });
  }

  public updateUnit(unit: any): Observable<any> {
    // console.log(unit);
    return this.http.put(
      `${this.SERVER_URL}/units/${unit.unit_id}`, unit);
  }

  public createUnit(unit: any): Observable<any> {
    return this.http.post(
      `${this.SERVER_URL}/units`, unit);
  }

  public deleteUnit(unit: any): Observable<any> {
    return this.http.delete(
      `${this.SERVER_URL}/units/${unit.unit_id}`);
  }

  public getMIDBUnit(name: string, flag: string): Observable<any> {
    return this.http.get(
      `${this.SERVER_URL}/units/getMIDBUnit?name=${name}&country=${flag}`);
  }

  public getByUnitSearchFilters(filters = {}): Observable<any> {
    let httpParams = new HttpParams();
    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        httpParams = httpParams.set(key, filters[key]);
      }
    }
    return this.http.get(
      `${this.SERVER_URL}/units/unitFilter`, {params: httpParams});
  }
}
