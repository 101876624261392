import { AuditUnitBorrowed } from '@models/audit-unit-borrowed.model';
import { AuditUnitBorrowedApiService } from '@services/api-services/audit-unit-borrowed-api.service';
import { Injectable } from '@angular/core';
import { JsonConverter } from '@services/global/json-converter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AuditUnitBorrowedService {
    private jsonConvert: JsonConverter;

    constructor(private auditApiService: AuditUnitBorrowedApiService) {
        this.jsonConvert = new JsonConverter();
    }

    public getAll(): Observable<AuditUnitBorrowed[]> {
        return this.auditApiService.getAllAudits()
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, AuditUnitBorrowed);
                })
            );
    }

    public getAuditByAuditId(id: string): Observable<AuditUnitBorrowed> {
        return this.auditApiService.getAuditByAuditId(id)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, AuditUnitBorrowed);
                })
            );
    }

    public create(audit: AuditUnitBorrowed): Observable<AuditUnitBorrowed> {
        return this.auditApiService.createAudit(this.jsonConvert.serializeObject(audit))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, AuditUnitBorrowed);
                })
            );
    }

}
