<app-classification-bar class="top-modal-classification-bar"></app-classification-bar>
<app-fdtm-modal-header [headerString]="headerString"></app-fdtm-modal-header>
<div mat-dialog-content>
<form [formGroup]="editForm">
  <mat-form-field class="input-90-width">
    <mat-select placeholder="Command" required formControlName="commandId">
      <mat-option *ngFor="let option of commandOptions" [value]="option.commandId">{{option.name}}</mat-option>
    </mat-select>
  </mat-form-field>
  <br>
  <mat-form-field class="input-90-width">
    <input matInput (keyup)="checkDups($event, 'name')" matInput placeholder="Flag Name" required
      formControlName="name">
    <mat-error *ngIf="editForm?.controls?.name?.hasError('duplicate entry')">duplicate entry</mat-error>
  </mat-form-field>
  <br>
  <mat-form-field class="input-90-width">
    <input matInput placeholder="Trigraph" required formControlName="triGraph">
  </mat-form-field>
  <br>
  <label for="logo"><span>Upload a Flag</span></label>
  <input type="file" #fileInput name="file" id="file"  [accept]="['.jpg', '.png', '.jpeg', '.svg']" (change)="imageChange($event)"><br>
  <img *ngIf="tmpImg" [src]="tmpImg" alt="" width="200" height="200">
  <img *ngIf="currentImage" [src]="currentImage" alt="entity.name" width="200" height="200">
  <div mat-dialog-actions>
    <button *ngIf="!isDelete" [disabled]="!editForm.valid" mat-raised-button color="primary"
      (click)="submit()">Submit</button>
    <button *ngIf="isDelete" mat-raised-button color="warn" (click)="handleDelete()">Delete</button>
    <button mat-raised-button *ngIf="hasFlag()" (click)="removeFlag($event)">Delete Flag</button>
    <button mat-raised-button (click)="handleCancel()">Cancel</button>
  </div>
</form>
</div>
<app-classification-bar class="bottom-modal-classification-bar"></app-classification-bar>