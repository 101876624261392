import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from '@services/api-services/api.config';

@Injectable()
export class DataCardsApiService {
    SERVER_URL;

    constructor(
        private http: HttpClient, apiConfig: APIConfig) {
        this.SERVER_URL = apiConfig.TARGET_URL;
    }

    public getTotalDays(queryParams: Object): Observable<any> {
        let httpParams = new HttpParams();

        for (const key in queryParams) {
            if (queryParams.hasOwnProperty(key)) {
              httpParams = httpParams.set(key, queryParams[key]);
            }
          }
        return this.http.get(
          // Underway History data
          // `${this.SERVER_URL}/datacards?unit_id=${id},...
          `${this.SERVER_URL}/datacards`, { params: httpParams});
    }
}
