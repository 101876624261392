import { Injectable } from '@angular/core';
import { JsonConverter } from '@services/global/json-converter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MessageConfig } from '@models/message-config.model';
import { MessageConfigApiService } from '@services/api-services/message-config-api.service';

@Injectable()
export class MessageConfigService {
  private jsonConvert: JsonConverter;

  constructor(private messageConfigApiService: MessageConfigApiService) {
    this.jsonConvert = new JsonConverter();
  }

  public create(messageConfig: MessageConfig): Observable<MessageConfig> {
    return this.messageConfigApiService.createMessageConfig(this.jsonConvert.serializeObject(messageConfig))
      .pipe(
        map((data) => {
          return this.jsonConvert.deserializeObject(data, MessageConfig);
        })
      );
  }

  public update(messageConfig: MessageConfig): Observable<MessageConfig> {
    return this.messageConfigApiService.updateMessageConfig(this.jsonConvert.serializeObject(messageConfig))
      .pipe(
        map((data) => {
          return this.jsonConvert.deserializeObject(data, MessageConfig);
        })
      );
  }

  public delete(messageConfig: MessageConfig): Observable<MessageConfig> {
    return this.messageConfigApiService.deleteMessageConfig(this.jsonConvert.serializeObject(messageConfig))
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
}