<div class="admin-work-area">
    <div fxLayout fxLayoutAlign="center center">
      <span class="grid-title">FDT-M Classification Markings Maintenance</span>
    </div>
      <div fxLayout fxLayoutAlign="center center">
        <mat-form-field class="selectors">
            <mat-select [(ngModel)]="lookup"  placeholder="Select Markings Table" (selectionChange)="lookupChanged($event)">
              <mat-option *ngFor="let lookup of lookups" [value]="lookup">
                      {{lookup.display}}
              </mat-option>
            </mat-select>
        </mat-form-field>
        <button *ngIf="lookup" mat-mini-fab class="add-button" (click)="add()">
          <img class="light-icon" src="./assets/icons/ic_add_white_24px.svg" matTooltip="Add Classification Marking" />
        </button>
      </div>
        <div *ngIf="lookup && dataSource" class="mat-elevation-z8">
              <!-- Paginator -->
        <mat-paginator [pageSizeOptions]="[20,50,100]" [pageSize]="100" [length]="dataSource?.data?.length" showFirstLastButtons></mat-paginator>

        <table mat-table [dataSource]="dataSource" class="lookup-table">
          <ng-container matColumnDef="edit">
              <th style="width:40px" mat-header-cell *matHeaderCellDef> Edit </th>
              <td class="edit" mat-cell *matCellDef="let element">
                <mat-icon class="hyper" matTooltip="Click to edit" matTooltipPosition="above" (click)="edit(element)">edit</mat-icon>
              </td>
          </ng-container>
           <!-- Data Columns -->
          <ng-container *ngFor="let property of lookup.properties; let i=index" matColumnDef="{{property}}">
            <th mat-header-cell *matHeaderCellDef> {{lookup.columns[i]}} </th>
            <td mat-cell *matCellDef="let element">
                {{element[property]}}
            </td>
          </ng-container>
          <ng-container matColumnDef="delete">
            <th style="width:40px" mat-header-cell *matHeaderCellDef> Delete </th>
            <td class="delete" mat-cell *matCellDef="let element">
              <mat-icon class="hyper" matTooltip="Delete Classification Marking" matTooltipPosition="above" (click)="delete(element)">delete</mat-icon>
            </td>
        </ng-container>

          <tr mat-header-row *matHeaderRowDef="allColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row;index as i; columns: allColumns;">
          </tr>
        </table>

      </div>

    </div>
