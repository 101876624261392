import { VesselType } from '@models/vessel-type.model';
import { VesselTypeApiService } from '@services/api-services/vessel-type-api.service';
import { Injectable } from '@angular/core';
import { JsonConverter } from '@services/global/json-converter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class VesselTypeService {
    private jsonConvert: JsonConverter;

    constructor(private vesselTypeApiService: VesselTypeApiService) {
        this.jsonConvert = new JsonConverter();
    }

    public getVesselType(params: any): Observable<VesselType[]> {
        return this.vesselTypeApiService.getVesselType(params)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, VesselType);
                })
            );
    }

    public getAll(): Observable<VesselType[]> {
        return this.vesselTypeApiService.getAllVesselTypes()
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, VesselType);
                })
            );
    }

    public getVesselTypeByVesselTypeId(vesselTypeId: string): Observable<VesselType> {
        return this.vesselTypeApiService.getVesselTypeByVesselTypeId(vesselTypeId)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, VesselType);
                })
            );
    }

    public update(vesselType: VesselType): Observable<VesselType> {
        return this.vesselTypeApiService.updateVesselType(this.jsonConvert.serializeObject(vesselType))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, VesselType);
                })
            );
    }

    public create(vesselType: VesselType): Observable<VesselType> {
        return this.vesselTypeApiService.createVesselType(this.jsonConvert.serializeObject(vesselType))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, VesselType);
                })
            );
    }

    public delete(vesselType: VesselType): Observable<VesselType> {
        return this.vesselTypeApiService.deleteVesselType(this.jsonConvert.serializeObject(vesselType))
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }
}
