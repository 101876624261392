import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from './api.config';
import { FreqType } from '@models/freq-type.model';

@Injectable()
export class FreqTypeApiService {
  SERVER_URL;
  RESOURCE_URI = 'freqtypes';


  constructor(
    private http: HttpClient, apiConfig: APIConfig) {
    this.SERVER_URL = apiConfig.TARGET_URL;
  }

  public getAllFreqTypes(): Observable<any> {
    return this.http.get(
      `${this.SERVER_URL}/${this.RESOURCE_URI}`);
  }

  public getFreqTypeByFreqTypeId(id: string): Observable<any> {
    return this.http.get(
      `${this.SERVER_URL}/${this.RESOURCE_URI}/${id}`);
  }

  public updateFreqType(freqType: FreqType): Observable<any> {
    return this.http.put(
      `${this.SERVER_URL}/${this.RESOURCE_URI}/${freqType.freq_type_id}`, freqType);
  }

  public createFreqType(freqType: any): Observable<any> {
    return this.http.post(
      `${this.SERVER_URL}/${this.RESOURCE_URI}`, freqType);
  }

  public deleteFreqType(freqType: FreqType): Observable<any> {
    return this.http.delete(
      `${this.SERVER_URL}/${this.RESOURCE_URI}/${freqType.freq_type_id}`);
  }
}
