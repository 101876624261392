import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { EditClassificationModalComponent } from '@fom-module/edit-modals/edit-classification-modal/edit-classification-modal.component';
import { EditControlModalComponent } from '@fom-module/edit-modals/edit-control-modal/edit-control-modal.component';
import { EditFgiModalComponent } from '@fom-module/edit-modals/edit-fgi-modal/edit-fgi-modal.component';
import { EditOtherControlModalComponent } from '@fom-module/edit-modals/edit-other-control-modal/edit-other-control-modal.component';
import { EditReleasabilityModalComponent } from '@fom-module/edit-modals/edit-releasability-modal/edit-releasability-modal.component';
import { Classification } from '@models/classification.model';
import { Control } from '@models/control.model';
import { Fgi } from '@models/fgi.model';
import { OtherControl } from '@models/other-control.model';
import { Releasability } from '@models/releasability.model';
import { LookUpService } from '@services/data-services/lookup.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-classification-maintenance',
  templateUrl: './classification-maintenance.component.html',
  styleUrls: ['./classification-maintenance.component.css']
})
export class ClassificationMaintenanceComponent implements OnInit {

  lookup: any;
  dataSource: any;
  allColumns: string[] = []
  entities: Array<any>;

  lookups = [ 
    {type: Classification,
    editor: EditClassificationModalComponent,
    display: 'Classifications',
    columns: ['Classification', 'Short Name', 'SACC', 'Source Code', 'Sort By'],
    properties: ['classificationName', 'shortName', 'sacc', 'sourceCode', 'sortBy']
    },
    {type: Control,
      editor: EditControlModalComponent,
      display: 'SCI Controls',
      columns: ['Control Mark', 'Source Code'],
      properties: ['controlMark', 'sourceCode']
    },
    {type: Fgi,
      editor: EditFgiModalComponent,
      display: 'FGIs',
      columns: ['FGI Name'],
      properties: ['name']
    },
    {type: OtherControl,
      editor: EditOtherControlModalComponent,
      display: 'Other Controls',
      columns: ['Control Name'],
      properties: ['description']
    },
    {type: Releasability,
      editor: EditReleasabilityModalComponent,
      display: 'Releasability',
      columns: ['Releasability Name','NSA Name', '2 Digit', 'Source Code'],
      properties: ['releasabilityName', 'nsaName', 'twoDigit', 'sourceCode']
    }
  ]
  constructor(private lookupService: LookUpService,
    private dialog: MatDialog) { }

  ngOnInit() {
  }

  lookupChanged(event) {
    if (!event || !event.value) {
      return
    }
    this.lookup = this.lookups.find(x => x.display == event.value.display)
    if (this.lookup) {
      this.allColumns = ['edit'].concat(this.lookup.properties).concat(['delete'])
      this.lookupService.getLookupByType(this.lookup.type, true).pipe(take(1)).subscribe(results => {
        this.entities = results
        this.dataSource = new MatTableDataSource<object>(results);
      }) 
    }
  }
  add() {
    // gotta make sure the lookups are loaded, add is enabled early, just when the type is selected
    if (this.lookup) {
      this.lookupService.getLookupByType(this.lookup.type, true).pipe(take(1)).subscribe(entities => {
      //this.pickistPrefService.getAll().pipe(take(1)).subscribe(entities => {
        this.entities = entities
        this.edit(null)
      })
    }
  }
  edit(lookup, isDelete = false) {
    let isNew = false
    if (!lookup) {
      lookup = new this.lookup.type()
      isNew = true
    }
    const dialogRef = this.dialog.open(this.lookup.editor, {
      width: '420px'
    });
    (dialogRef.componentInstance as any).isNew = isNew;
    (dialogRef.componentInstance as any).entity = lookup;
    (dialogRef.componentInstance as any).isDelete = isDelete;
    (dialogRef.componentInstance as any).items = this.entities;

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
        this.lookupChanged({value:this.lookup}) // force the reload
    });    
  }

  delete(lookup) {
    this.edit(lookup, true)
  }
}
