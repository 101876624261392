import { JsonObject, JsonProperty, Any } from 'json2typescript';

@JsonObject('OtherControl')
export class OtherControl {
  @JsonProperty('control_id', String, true)
  controlId: string = undefined;
  @JsonProperty('control_name', String, true)
  description: string = undefined;
  @JsonProperty('created_by', String, true)
  createdBy: string = undefined;

  static lookupName(): string {
    return 'otherControls';
  }
}
