import { Classification } from '@models/classification.model';
import { ClassificationApiService } from '../api-services/classification-api.service';
import { Injectable } from '@angular/core';
import { JsonConverter } from '../global/json-converter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable()
export class ClassificationService {
    private jsonConvert: JsonConverter;

    constructor(private apiService: ClassificationApiService) {
        this.jsonConvert = new JsonConverter();
    }

    public getAll(): Observable<Classification[]> {
        return this.apiService.getAllClassifications()
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, Classification);
                })
            );
    }

    public getClassificationByClassificationId(id): Observable<Classification> {
        return this.apiService.getClassificationByClassificationId(id)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Classification);
                })
            );
    }

    public update(item: Classification): Observable<Classification> {
        return this.apiService.updateClassification(this.jsonConvert.serializeObject(item))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Classification);
                })
            );
    }

    public create(item: Classification): Observable<Classification> {
        return this.apiService.createClassification(this.jsonConvert.serializeObject(item))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Classification);
                })
            );
    }

    public delete(item: Classification): Observable<Classification> {
        return this.apiService.deleteClassification(this.jsonConvert.serializeObject(item))
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

}
