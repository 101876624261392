<div class="admin-work-area">
    <div fxLayout fxLayoutAlign="center center" class="simple-grid-title">
        <span class="grid-title">{{initService.getConfig().titlePrefix}} FDT-M Data Tags</span>
        <!-- Add Unit Floating Action Button -->
        <button mat-mini-fab class="add-button" (click)="add()">
          <img class="light-icon" src="./assets/icons/ic_add_white_24px.svg" matTooltip="Add Data Tag" />
        </button>
    </div>
      <div class="mat-elevation-z8">
      <!-- Paginator -->
      <mat-paginator [pageSizeOptions]="[10, 20, 50, 100, 1000]" [pageSize]="20" [length]="dataSource?.data?.length" showFirstLastButtons></mat-paginator>
      <table mat-table [dataSource]="dataSource" matSort matSortStart="desc" (matSortChange)="sortData($event)">

            <!-- Data Columns -->
            <ng-container matColumnDef="classificationString">
              <th mat-header-cell mat-sort-header *matHeaderCellDef> Classification String </th>
              <td mat-cell *matCellDef="let element"> {{element.classificationString}} </td>
            </ng-container>

            <ng-container matColumnDef="sourceName">
              <th mat-header-cell mat-sort-header *matHeaderCellDef> Source </th>
              <td mat-cell *matCellDef="let element"> {{element.sourceName}} </td>
            </ng-container>

            <ng-container matColumnDef="dataTag">
              <th mat-header-cell mat-sort-header *matHeaderCellDef> Tag </th>
              <td mat-cell *matCellDef="let element"> {{element.dataTag}} </td>
            </ng-container>

            <ng-container matColumnDef="modRemarks">
              <th mat-header-cell mat-sort-header *matHeaderCellDef> Remarks </th>
              <td mat-cell *matCellDef="let element"> {{element.modRemarks}} </td>
            </ng-container>

            <ng-container matColumnDef="username">
              <th mat-header-cell mat-sort-header *matHeaderCellDef> Author </th>
              <td mat-cell *matCellDef="let element"> {{element.user?.username}} </td>
            </ng-container>

            <!-- Delete Action Column -->
            <ng-container matColumnDef="delete">
              <th mat-header-cell *matHeaderCellDef>
                  Delete
              </th>
              <td mat-cell *matCellDef="let row">
                <button mat-button class="delete-button" (click)="$event.stopPropagation()" (click)="handleDelete(row)">
                  <img src="./assets/icons/ic_delete_gray_24px.svg" />
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr class="hyper" matTooltip="Edit Data Tag" matTooltipPosition="before" mat-row *matRowDef="let row; columns: displayedColumns;" (click)="edit(row)"></tr>
          </table>


    </div>
</div>
