<app-classification-bar class="top-modal-classification-bar"></app-classification-bar>
<app-fdtm-modal-header [headerString]="headerString"></app-fdtm-modal-header>
<div mat-dialog-content>
<div><p *ngIf="message"><span class="green" >{{message}}</span></p></div>
<form [formGroup]="editForm">
  <mat-form-field class="input-full-width">
    <input matInput placeholder="Command Name" required formControlName="name" autocomplete="off">
  </mat-form-field>
  <br>
<br>


<!-- <form *ngIf="!isNew" #form [action]="'/commands/'+entity.commandId+'/logo'" [method]="noLogo()" enctype="multipart/form-data" (ngSubmit)="uploadFile($event)"> -->
  <label for="logo"><span>Upload a Logo</span></label>
  <input type="file" #fileInput name="logo" id="logo" (change)="imageChange($event)" accept="image/*" >
  <img *ngIf="tmpImg" [src]="tmpImg" alt="" width="200" height="200">
  <img *ngIf="currentImage" [src]="currentImage" alt="entity.name" width="200" height="200">
  <!-- <div *ngIf="!tmpImg || !currentImage"  style="width: 200px;height: 200px;" id="drop_zone" (ondrop)="dropHandler($event)" (ondragover)="dragOverHandler($event)">
    Not Working Quite Right, Come Back later
    <p>Drag and Drop a Logo Here...</p>
  </div> -->
  <br>
    <div mat-dialog-actions>
    <button [disabled]="!editForm.valid" mat-raised-button color="primary" value="Upload" (click)="uploadForm($event)">Submit</button>
    <button mat-raised-button id="delete-img" *ngIf="noLogo() === 'put'" (click)="removeLogo($event)">Delete Logo</button>
    <button mat-raised-button (click)="handleCancel()">Cancel</button>
  </div>
  <!-- </div> -->

</form>
</div>
<app-classification-bar class="bottom-modal-classification-bar"></app-classification-bar>
