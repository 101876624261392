<mat-card class="form-card">
    <div fxLayoutAlign="center" class="nob-header">
      <div class="card">
        <img class="fomImage" height="74px" width="74px" src="assets/images/FDT-M.png" />
      </div>
      <div class="nob-header-middle">
        <div class="grid-title nob-title">
          <span>{{initService.getConfig().titlePrefix}} Force Disposition Tracker - Maritime</span>
        </div>
      </div>
      <div class="card" fxLayoutAlign="right">
        <img class="fomImage" height="74px" width="74px" src="assets/images/FDT-M.png" />
      </div>
    </div>

    <iframe [src]="grafanaDashboardPublicURL" style="width:100%; height:850px"></iframe>
  </mat-card>