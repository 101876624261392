import { JsonObject, JsonProperty, Any } from 'json2typescript';

@JsonObject('Owner')
export class Owner {
  @JsonProperty('owner_id', String, true)
  ownerId: string = undefined;
  @JsonProperty('owner_name', String, true)
  ownerName: string = undefined;
  @JsonProperty('owner_type_id', String, true)
  ownerTypeId: string = undefined;
  @JsonProperty('owner_tablename', Number, true)
  ownerTablename: number = undefined;
  @JsonProperty('deleted_ts', String, true)
  deletedTs: string = undefined;

  static lookupName(): string {
    return 'owner';
  }
}
