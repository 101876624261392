import { JsonObject, JsonProperty, Any } from 'json2typescript';

@JsonObject('LookupFilter')
export class LookupFilter {
  @JsonProperty('lookup_filter_id', String, true)
  lookupFilterId: string = undefined;
  @JsonProperty('owner_id', String, true)
  ownerId: string = undefined;
  @JsonProperty('lookup_id', String, true)
  lookupId: string = undefined;
  @JsonProperty('sort_sequence', Number, true)
  sortSequence: number = undefined;
  @JsonProperty('created_by', String, true)
  createdBy: string = undefined;

  static lookupName(): string {
    return 'lookupFilter';
  }
}
