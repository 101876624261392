
<!-- hiding the filters for now -->

<div *ngIf="false" class="basket">
<div>
    <button mat-button matTooltip="{{filterType}} Filters" (click)="openFilters()" class="icon"
            [ngClass]="{'filters-on' : filtersOn(), 'filters-off' : !filtersOn()}">
        <mat-icon aria-hidden="false" aria-label="Filters">filter_list</mat-icon>
    </button>
</div>
<div [ngClass]="{'filters-on' : filtersOn(), 'filters-off' : !filtersOn()}" class="tiny">({{filtersOn() ? 'ON' : 'OFF'}})</div>
</div>