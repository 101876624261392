import { Control } from '@models/control.model';
import { ControlApiService } from '../api-services/control-api.service';
import { Injectable } from '@angular/core';
import { JsonConverter } from '../global/json-converter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable()
export class ControlService {
    private jsonConvert: JsonConverter;

    constructor(private apiService: ControlApiService) {
        this.jsonConvert = new JsonConverter();
    }

    public getAll(): Observable<Control[]> {
        return this.apiService.getAllControls()
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, Control);
                })
            );
    }

    public getControlByControlId(id): Observable<Control> {
        return this.apiService.getControlByControlId(id)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Control);
                })
            );
    }

    public update(item: Control): Observable<Control> {
        return this.apiService.updateControl(this.jsonConvert.serializeObject(item))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Control);
                })
            );
    }

    public create(item: Control): Observable<Control> {
        return this.apiService.createControl(this.jsonConvert.serializeObject(item))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Control);
                })
            );
    }

    public delete(item: Control): Observable<Control> {
        return this.apiService.deleteControl(this.jsonConvert.serializeObject(item))
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

}
