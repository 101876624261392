import { GlobalMessageTriggers } from '@services/global/message-bus/global-message-triggers.enum';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { DynamicComponentInterface } from '@core-module/app-components/dynanmic-component/dynamic-component.interface';
import { Component, OnInit } from '@angular/core';
import { GlobalBusMessage } from '@services/global/message-bus/global-bus-message.model';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.css']
})
export class ConfirmationModalComponent implements OnInit, DynamicComponentInterface {
  headerString: string;
  buttonText: string;
  dynamicData: any;
  message: string;
  action: any;
  args: any[];
  context: any;

  constructor(private messageBusService: MessageBusService) { }

  ngOnInit() {
    this.message = this.dynamicData.message;
    this.action = this.dynamicData.action;
    this.headerString = this.dynamicData.headerString;
    this.buttonText = this.dynamicData.buttonText;
    this.args = this.dynamicData.args;
    this.context = this.dynamicData.context;
  }
  resolveDynamicComponentClass() {
    return ConfirmationModalComponent;
  }

  handleConfirm() {
    this.action(this.context, this.args);
  }

  handleCancel() {
    this.messageBusService.publishMessage(new GlobalBusMessage(GlobalMessageTriggers.CLOSE_MODAL));
  }

}
