<h1 mat-dialog-title>Location Filters</h1>
<div mat-dialog-content>
  <mat-form-field>
    <mat-select [(ngModel)]="filters.op_area_id" multiple name="opArea" placeholder="OpAreas">
        <mat-option *ngFor="let area of opAreas" [disabled]="false" [value]="area.opAreaId"> {{area.remarks}} {{area.opArea}} ({{area.commandName}})</mat-option>
    </mat-select>
    <button mat-icon-button (click)="$event.stopPropagation();all('op_area_id', opAreas, 'opAreaId')" matSuffix title="Select/Deselect all available OpAreas">
        <mat-icon>select_all</mat-icon>
    </button>
    <span class="small-text" matSuffix>({{filters?.op_area_id? filters?.op_area_id?.length : 0}} of {{opAreas?.length}} selected)</span>
 </mat-form-field>
 <br>
 <mat-form-field>
    <mat-select [(ngModel)]="filters.fleet_id" multiple name="fleet" placeholder="Fleets">
        <mat-option *ngFor="let fleet of fleets" [disabled]="false" [value]="fleet.fleetId"> {{fleet.fleetCode}} {{fleet.remarks}}</mat-option>
    </mat-select>
    <button mat-icon-button (click)="$event.stopPropagation();all('fleet_id', fleets, 'fleetId')" matSuffix title="Select/Deselect all available Fleets">
        <mat-icon>select_all</mat-icon>
    </button>
    <span class="small-text" matSuffix>({{filters?.fleet_id? filters?.fleet_id?.length : 0}} of {{fleets?.length}} selected)</span>
 </mat-form-field>
 <br>
 <mat-form-field>
    <mat-select [(ngModel)]="filters.classification_id" multiple name="classification" placeholder="Classifications">
        <mat-option *ngFor="let classification of classifications" [disabled]="false" [value]="classification.classificationId"> {{classification.classificationName}}</mat-option>
    </mat-select>
    <button mat-icon-button (click)="$event.stopPropagation();all('classification_id', classifications, 'classificationId')" matSuffix title="Select/Deselect all available Classifications">
        <mat-icon>select_all</mat-icon>
    </button>
    <span class="small-text" matSuffix>({{filters?.classification_id? filters?.classification_id?.length : 0}} of {{classifications?.length}} selected)</span>
 </mat-form-field>
 <br>
 <mat-form-field>
  <mat-select [(ngModel)]="filters.source_id" multiple name="source" placeholder="Sources">
      <mat-option *ngFor="let source of sources" [disabled]="false" [value]="source.sourceId"> {{source.sourceName}}</mat-option>
  </mat-select>
  <button mat-icon-button (click)="$event.stopPropagation();all('source_id', sources, 'sourceId')" matSuffix title="Select/Deselect all available Sources">
      <mat-icon>select_all</mat-icon>
  </button>
  <span class="small-text" matSuffix>({{filters?.source_id? filters?.source_id?.length : 0}} of {{sources?.length}} selected)</span>
  </mat-form-field>
  <br>
  <mat-form-field>
    <mat-select [(ngModel)]="filters.activity_category_id" multiple name="activityCategory" placeholder="Activity Categories">
        <mat-option *ngFor="let activity of activities" [disabled]="false" [value]="activity.activityId"> {{activity.description}}</mat-option>
    </mat-select>
    <button mat-icon-button (click)="$event.stopPropagation();all('activity_category_id', activities, 'activityId')" 
        matSuffix title="Select/Deselect all available Activity Categories">
        <mat-icon>select_all</mat-icon>
    </button>
    <span class="small-text" matSuffix>({{filters?.activity_category_id? filters?.activity_category_id?.length : 0}} of {{activities?.length}} selected)</span>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="warn" (click)="cancel()">Cancel</button>
  <button mat-raised-button color="accent" (click)="clear()">Clear</button>
  <button mat-raised-button color="primary" (click)="apply()" cdkFocusInitial>Apply</button>
</div>
