import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LocationFiltersComponent } from '@core-module/app-components/location-filters/location-filters.component';
import { take } from 'rxjs/operators';
import { UnitFiltersComponent } from '../unit-filters/unit-filters.component';

@Component({
  selector: 'app-search-filter-control',
  templateUrl: './search-filter-control.component.html',
  styleUrls: ['./search-filter-control.component.css']
})
export class SearchFilterControlComponent implements OnInit {

  @Input() filterType: string = ''

  @Output() filtersChanged = new EventEmitter<any>();
  constructor(private dialog: MatDialog) { }

  filters: any
  ngOnInit() {
    this.filters = this.filterType == 'Location' ? JSON.parse(sessionStorage.getItem('locationFilters')) || {} :
    JSON.parse(sessionStorage.getItem('unitFilters')) || {}
  }

  openFilters() {
    const dialogRef = this.filterType == 'Location' ? 
      this.dialog.open(LocationFiltersComponent, {
        width: '644px'
      }) : 
      this.dialog.open(UnitFiltersComponent, {
      width: '644px'
    });
    dialogRef.afterClosed().pipe(take(1)).subscribe(filters => {
      // there may be some request, such as track data, coming back from the modal
      if (filters) {
        this.filters = filters
        this.filtersChanged.emit(this.filters)
      };
    })
  }
  // need to tell the user if filters are in use
  filtersOn() {
    let on = false;
    Object.keys(this.filters).forEach(item => {
      //console.log(this.filters[item])
      if (Array.isArray(this.filters[item]) && this.filters[item].length) {
        on = true
      }
    })
    return on
  }
}
