import { Injectable } from '@angular/core';
import { BehaviorSubject ,  Subject } from 'rxjs';
import { HttpRequest } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private _requests = []
  public isLoading = new BehaviorSubject(false);
  public message = new BehaviorSubject('Accessing Server, Please Wait...');
  public requests = new BehaviorSubject([])
  constructor() { }

  setMessage(value: string) {
    this.message.next(value);
  }

  addRequest(request: HttpRequest<any>) {
    this._requests.push(request)
    this.requests.next(this._requests)
  }
  removeRequest(request: HttpRequest<any>) {
    var index = this._requests.indexOf(request)
    if (index != -1) {
      this._requests.splice(index,1);
      this.requests.next(this._requests)
    }
    return this._requests.length
  }
  cancelRequests() {
    var i= this._requests.length;
    while (i--) {
      this._requests.splice(i,1)
    }
    this.requests.next(this._requests)
    this.isLoading.next(false)
  }
}
