<app-classification-bar class="top-modal-classification-bar"></app-classification-bar>
<app-fdtm-modal-header [headerString]="headerString"></app-fdtm-modal-header>
<div mat-dialog-content>
  <form [formGroup]="editForm">
    <div *ngIf="accountRequest" fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
      <span class="mat-color-warn">An Account Request exists for this User</span>
    </div>
    <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
      <mat-form-field>
        <mat-select placeholder="Command" required formControlName="commandId">
          <mat-option *ngFor="let option of commandOptions" [value]="option.commandId">{{option.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
      <mat-form-field>
        <input matInput placeholder="First Name" required formControlName="firstName">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Last Name" required formControlName="lastName">
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
      <mat-form-field>
        <input matInput placeholder="Username" required formControlName="username">
      </mat-form-field>
      <mat-form-field *ngIf="isNew">
        <input matInput type="password" placeholder="Password" required formControlName="password">
        <mat-error *ngIf="editForm?.controls?.password?.hasError('length')">invalid length</mat-error>
        <mat-error *ngIf="editForm?.controls?.password?.hasError('complexity')">must contain uppercase, lowercase,
          numbers, and symbols</mat-error>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
    <mat-form-field>
      <input matInput placeholder="Email" type="email" name="email" id="email" email required formControlName="email">
    </mat-form-field>
    <h4>Whitelist: </h4>
    <mat-radio-group placeholder="Whitelist" formControlName="whitelisted">
      <mat-radio-button class="smallRadio" color="primary"
        [checked]="!entity.whitelisted" value=false>No&nbsp;&nbsp;
      </mat-radio-button>
      <mat-radio-button class="smallRadio" color="primary"
        [checked]="entity.whitelisted" value=true>Yes</mat-radio-button>
    </mat-radio-group>
  </div>
    <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
      <mat-form-field>
        <mat-select placeholder="Permission level" required formControlName="permissions">
          <mat-option *ngFor="let option of permissionOptions" [value]="option.permissionId">
            {{option.permissionDescription}}</mat-option>
        </mat-select>
      </mat-form-field>
      <h4>Enabled: </h4>
      <mat-radio-group placeholder="Enabled" formControlName="disabled">
        <mat-radio-button class="smallRadio" color="primary"
          [checked]="entity.disabled || entity.isInactive(daysUntilInactive)" value=true>No&nbsp;&nbsp;
        </mat-radio-button>
        <mat-radio-button class="smallRadio" color="primary"
          [checked]="!entity.disabled && !entity.isInactive(daysUntilInactive)" value=false>Yes</mat-radio-button>
      </mat-radio-group>
    </div>
    <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
      <mat-form-field>
        <input matInput placeholder="Justification" required formControlName="justification">
      </mat-form-field>
    </div>
    <h4>Distinguished Name(s): </h4>
    <mat-option *ngFor="let pki of userPkis" [value]="pki" style="padding: 0 16px">
      <div style="display:flex; float:left">
        <span style="display:block; white-space: normal; line-height: normal; font-size: 12pt;">{{pki.distinguished_name}}</span>
        <span (click)="delete(pki)" style="line-height: normal; padding-left: 10px; float: right; position: absolute; right: 0px">
          <img _ngcontent-dut-c268=""
            src="./assets/icons/ic_delete_gray_24px.svg">
        </span>
      </div>
    </mat-option>
    <mat-form-field style="padding: 0 16px; width: 95%">
      <mat-label>Add Distinguished Name</mat-label>
      <input matInput type="text" #distinguishedName>
      <button matSuffix mat-icon-button aria-label="Add" (click)="addDistinguishedName()" style="right: 0px">
        <span class="mat-button-wrapper">
          <mat-icon style="color: #626262; transform: scale(1.5); padding: 10px">add_box</mat-icon>
        </span>
      </button>
    </mat-form-field>
    <div mat-dialog-actions>
      <button mat-raised-button color="primary" [disabled]="!editForm.valid" (click)="submit()">Submit</button>
      <button mat-raised-button (click)="cancel()">Cancel</button>
    </div>
  </form>
</div>
<app-classification-bar class="bottom-modal-classification-bar"></app-classification-bar>