import { FormBuilder } from '@angular/forms';
import { EditModalBaseComponent } from '@fom-module/base-classes/edit-modal-base.component';
import { ShipClassService } from '@services/data-services/ship-class.service';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { Component, OnInit } from '@angular/core';
import { LookUpService } from '@services/data-services/lookup.service';
import { Command } from '@models/command.model';
import { MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { UtilityService } from '@services/utility.service';
import { CommandHierarchyService } from '@services/data-services/command-hierarchy.service';
import { User } from '@models/user.model';
import { CurrentUserService } from '@services/current-user-service';
import { CommandHierarchy } from '@models/command-hierarchy.model';

@Component({
  selector: 'app-edit-ship-class-modal',
  templateUrl: './edit-ship-class-modal.component.html',
  styleUrls: ['./edit-ship-class-modal.component.css']
})
export class EditShipClassModalComponent extends EditModalBaseComponent implements OnInit {
  commandOptions: Command[];
  user: User;
  commandHierarchy: CommandHierarchy;

  flag: string = '';

  constructor(public shipClassService: ShipClassService,
    public lookUpService: LookUpService, public utilities: UtilityService,
    public formBuilder: FormBuilder,
    private commandHierarchyService: CommandHierarchyService,
    public dialogRef: MatDialogRef<EditShipClassModalComponent>,
    public messageBusService: MessageBusService,
    private currentUserService: CurrentUserService) {
    super(shipClassService, dialogRef);
  }

  ngOnInit() {
    this.headerString = this.isNew ? 'Add Ship Class' :
    this.isDelete ? 'Delete Ship Class' :
    'Edit Ship Class'
    this.user = this.currentUserService.getCurrentUser();
    if (this.isNew) {
      this.entity.commandId = this.user.currentJFMCC ? this.user.currentJFMCC.commandId : this.user.commandId;
    }
    this.editForm = this.formBuilder.group({
      shipClass: [{ value: this.entity.shipClass, disabled: this.isDelete }],
      shipClassDescription:  [{ value: this.entity.shipClassDescription, disabled: this.isDelete }],
      commandId: [{ value: this.entity.commandId, disabled: this.isDelete }]
    });
    Promise.resolve().then(() => {
      this.loadLookups();
    });
  }

  loadLookups() {
    this.lookUpService.getLookupByType(Command, true).pipe(take(1)).subscribe(result => {
      this.commandHierarchyService.getAll({ sort: 'version_num', limit: 1 }).pipe(take(1)).subscribe(json => {
        if (json && Array.isArray(json) && json.length) {
          this.commandHierarchy = json[0];

          let jfmccs;
          if (this.user.isSystemAdmin()) {
            jfmccs = this.commandHierarchy.getJFMCCs();
          } else {
            jfmccs = this.commandHierarchy.getJFMCCIds(this.user.command);
          }

          this.commandOptions = result.filter(item => {
            return jfmccs.includes(item.commandId);
          });
        } else {
          this.commandOptions = result;
        }
      });
    });
  }
}
