<app-classification-bar class="top-modal-classification-bar"></app-classification-bar>
<app-fdtm-modal-header [headerString]="headerString"></app-fdtm-modal-header>
<div mat-dialog-content>
<form [formGroup]="editForm">
  <mat-form-field class="input-full-width">
    <input matInput (input)="checkDups($event, 'releasabilityName')" autocomplete="off"
      placeholder="Releasability Name" required formControlName="releasabilityName">
    <mat-error *ngIf="editForm?.controls?.releasabilityName?.hasError('duplicate entry')">duplicate entry</mat-error>
  </mat-form-field>
  <br>
  <mat-form-field class="input-full-width">
    <input matInput placeholder="NSA Name" formControlName="nsaName" autocomplete="off">
  </mat-form-field>
  <br>
  <mat-form-field class="input-full-width">
    <input matInput placeholder="2 Digit" required maxlength="2" formControlName="twoDigit" autocomplete="off">
  </mat-form-field>
  <br>
  <mat-form-field class="input-full-width">
    <input matInput placeholder="Source Code" required maxlength="2" formControlName="sourceCode" autocomplete="off">
  </mat-form-field>
  <div mat-dialog-actions>
  <div *ngIf="isDelete" class="mat-color-warn">
    Deleting a Releasability Marking may cause serious issues with existing records!
    If you choose to delete this record the system will search for possible errors and may reject the request. 
    This is no guarantee that data corruption may still be present.  
  </div>
  <div *ngIf="!isNew && !isDelete" class="mat-color-warn">
    Editing a Releasability Marking may cause serious issues with existing records!
    If you choose to edit this marking the changes will be applied to all existing records in the database.  
  </div>
  <button *ngIf="!isDelete" [disabled]="!editForm.valid" mat-raised-button color="primary" (click)="handleSubmit()">Submit</button>
  <button *ngIf="isDelete" mat-raised-button color="warn" (click)="localDelete()">Delete</button>
  <button mat-raised-button (click)="handleCancel()">Cancel</button>
  </div>
</form>
</div>
<app-classification-bar class="bottom-modal-classification-bar"></app-classification-bar>