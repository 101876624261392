import { LookUpService } from '@services/data-services/lookup.service';
import { UnitService } from '@services/data-services/unit.service';
import { AuditUnitBorrowedService } from '@services/data-services/audit.service';
import { Component, OnInit } from '@angular/core';
import { Unit } from '@models/unit.model';
import { Fleet } from '@models/fleet.model';
import { Category } from '@models/category.model';
import { Port } from '@models/port.model';
import { Country } from '@models/country.model';
import { User } from '@models/user.model';
import { AuditUnitBorrowed } from '@models/audit-unit-borrowed.model';
import { Command } from '@models/command.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ShipType } from '@models/ship-type.model';
import { ShipClass } from '@models/ship-class.model';
import { VesselType } from '@models/vessel-type.model';
import { Threat } from '@models/threat.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { count, map, startWith, take } from 'rxjs/operators';
import { UtilityService } from '@services/utility.service';
import { CommandHierarchyService } from '@services/data-services/command-hierarchy.service';
import { CurrentUserService } from '@services/current-user-service';
import { CountryService } from '@services/data-services/country.service';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FriendlyErrorService } from '@services/friendly-error.service';
import { MessageService } from '@services/data-services/message.service';
import { Observable } from 'rxjs';
import {AsyncPipe} from '@angular/common';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';


@Component({
  selector: 'app-edit-unit-modal',
  templateUrl: './edit-unit-modal.component.html',
  styleUrls: ['./edit-unit-modal.component.css']
})
export class EditUnitModalComponent implements OnInit {

  flagControl = new FormControl('');
  portControl = new FormControl('');
  fleetControl = new FormControl('');
  categoryControl = new FormControl('');
  shipClassControl = new FormControl('');
  vesselTypeControl = new FormControl('');
  

  // passed in via call to modal
  unit: Unit;
  units: Unit[];
  isNew: boolean
  user: User
  initialUnit: any;

  // Dropdown Options
  //fleetOptions: Fleet[];
  fleetOptions: Fleet[] = [];
  fleetFilteredOptions: Observable<Object[]>;
  // hold all and use these to filter when country selected
  allFleets: Fleet[];
  // portOptions: Port[];
  portOptions: Port[] = [];
  portFilteredOptions: Observable<Object[]>;
  categoryOptions: Category[] = [];
  categoryFilteredOptions: Observable<Object[]>;
  commandOptions: Command[];
  shipClassOptions: ShipClass[] = [];
  shipClassFilteredOptions: Observable<Object[]>;
  vesselTypeOptions: VesselType[] = [];
  vesselTypeFilteredOptions: Observable<Object[]>;
  shipTypeOptions: ShipType[] = [];
  // hold all and use these to filter when country selected
  allShipTypes: ShipType[];
  threatOptions: Threat[];
  globalCountries: Country[] = [];
  flagFilteredOptions: Observable<Object[]>;

  // the user preferences passed in and out
  selectedCommandId: any;
  headerString: string;

  // create some named controls so that mat-errors can be triggered after data loaded
  shipTypeControl = new FormControl('');

  sconumError: boolean;

  mismatchedShipType: boolean = false;
  mismatchedFleet: boolean = false;

  constructor(
    public unitService: UnitService,
    public auditService: AuditUnitBorrowedService,
    public lookUpService: LookUpService,
    public countryService: CountryService,
    public utilities: UtilityService,
    private commandHierarchyService: CommandHierarchyService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<EditUnitModalComponent>,
    private currentUserService: CurrentUserService,
    private friendlyErrorService: FriendlyErrorService) {
  }

  async ngOnInit() {
    this.user = this.currentUserService.getCurrentUser();
    this.headerString = this.isNew ? `Add Unit for ${this.user.currentJFMCC ? this.user.currentJFMCC.name : this.user.command.name}` :
      `Edit Unit (${(this.unit as any).currentCommand.name})`
    // since we're concerned about the user's JFMCC (id) let's set it here explicitly rather than complex tests
    this.user.JFMCCId = this.user.currentJFMCC ? this.user.currentJFMCC.commandId : this.user.commandId;
    // new units default to active and underway
    if (this.isNew) {
      // Enforce unit is always set.
      this.unit = this.unit || new Unit();
      this.unit.isActive = this.unit.isUnderway = true
      this.unit.ship = new ShipType();
      this.unit.ship.shipClass = new ShipClass();
      this.unit.ship.vesselType = new VesselType();
      this.unit.country = new Country();
      this.unit.port = new Port();
      this.unit.fleet = new Fleet();
      this.unit.category = new Category();
    }
    // in case of cancel
    this.initialUnit = { ... this.unit };
    await Promise.resolve().then(() => this.loadLookups());
    this.checkUniqueSconum();
    this.autoCompleteArrays();
  }

  disableCommandControl() {
    // don't allow changes to the borrowerCommand if a new unit (cannot set)
    // or if this user's command is just borrowing the Unit
    return this.isNew || (this.user && !this.user.isSystemAdmin() && this.user.JFMCCId === this.initialUnit.borrowerCommandId);
  }

  isDirty() {
    return JSON.stringify(this.unit) != JSON.stringify(this.initialUnit)
  }

  loadLookups() {
    this.lookUpService.getLookupByType(Threat, true).pipe(take(1)).subscribe(result => {
      this.threatOptions = result
    });
    this.commandHierarchyService.getAll({ sort: 'version_num', limit: 1 }).pipe(take(1)).subscribe(json => {
      let JFMCCS
      if (json && Array.isArray(json) && json.length) {
        const commandGraph = JSON.parse(json[0].treeObject)
        JFMCCS = []
        commandGraph.forEach(unified => {
          (unified as any).children.forEach(jfmcc => {
            JFMCCS.push(jfmcc)
          })
        })
      }
      this.lookUpService.getLookupByType(Port, true).pipe(take(1)).subscribe(result => {
        this.portOptions = result;
        // limiting ports to just those associated with JFMCCs seems a little odd
        // the other way, with the app-select-lookup allowed all, basically did the same thing
        if (JFMCCS) {
          this.portOptions = this.portOptions.filter(item => {
            return JFMCCS.find(x => item.commandId == x.commandId)
          })
        }
        this.lookUpService.getLookupByType(Command, true).pipe(take(1)).subscribe(result => {
          if (JFMCCS) {
            result = result.filter(item => {
              return JFMCCS.find(x => item.commandId == x.commandId)
            })
          }
          this.commandOptions = [{ commandId: null, name: ' -- Select / Clear --', noSelection: true }, ...result];
        });
      })
    })
    // get all countries so that midb search can use country name
    this.lookUpService.getLookupByType(Country, true).pipe(take(1)).subscribe(result => {
      result.forEach(country => {
        country.flagImage = this.countryService.getFlagUrl(country)
      });
      this.globalCountries = result;
      this.lookUpService.getLookupByType(Fleet, true).pipe(take(1)).subscribe(result => {
        this.allFleets, this.fleetOptions = result;
        // this.setFleetOptions()
        //this.fleetControl.markAsTouched()
      });
      this.lookUpService.getLookupByType(ShipType, true).pipe(take(1)).subscribe(result => {
        this.allShipTypes, this.shipTypeOptions = result;
        // this.setShipTypeOptions()
        //this.shipTypeControl.markAsTouched()
      });
      this.lookUpService.getLookupByType(ShipClass, true).pipe(take(1)).subscribe(result => {
        this.shipClassOptions = result;
        // this.setShipTypeOptions()
        //this.shipTypeControl.markAsTouched()
      });
      this.lookUpService.getLookupByType(VesselType, true).pipe(take(1)).subscribe(result => {
        this.vesselTypeOptions = result;
        // this.setShipTypeOptions()
        //this.shipTypeControl.markAsTouched()
      });
    });
    this.lookUpService.getLookupByType(Category, true).pipe(take(1)).subscribe(result => {
      this.categoryOptions = result;
    });
  }

  // check for a country associated with the unit. if present then the shipTypeId and the fleetId must
  // have identifying fields (remarks?) that match the unit's Country name (no foreign key)
  setFleetOptions() {
    if (this.unit.country) {
      /*  this.fleetOptions = this.allFleets.filter(x => x.remarks == this.unit.country.name) 
        const fleet = this.fleetOptions.find(x => x.fleetId == this.unit.fleetId)
        if (this.unit.fleetId && !fleet) {
          this.unit.fleetId = null
          throw `Invalid fleet for country ${this.unit.country.name}. Please select a different fleet.`
        }*/
    }
  }

  setShipTypeOptions() {
    if (this.unit.country) {
      /* this.shipTypeOptions = this.allShipTypes.filter(x => x.remarks == this.unit.country.name)
       const shipType = this.shipTypeOptions.find(x => x.shipTypeId == this.unit.shipTypeId)
       if (this.unit.shipTypeId && !shipType) {
         this.unit.shipTypeId = null
         throw `Invalid ship type for country ${this.unit.country.name}. Please select a different ship type.`
       }*/
    }
  }

  countrySelected(country) {
    // ugh... the countryId gets set via ngModel but the flag that is displayed after the change
    // does not reflect the new country
    if (country.countryId) {
      this.unit.country = country; 
    }

    if ((this.unit.ship?.shipClassId) && (this.unit.ship?.vesselTypeId))
    {
      //lookup the ship type field
      const newShipType = this.getShipType();
    
     if (newShipType) {
        this.unit.shipTypeId = this.unit.ship.shipTypeId;
        this.unit.type = newShipType.vesselTypeName;
        this.unit.class = newShipType.shipClassName;
        this.mismatchedShipType = false;
      }
      else {
        this.mismatchedShipType = true;
        this.unit.shipTypeId = null;
        this.unit.type = null;
        this.unit.class = null;
      }
    }
    this.setFleetOptions()
    this.setShipTypeOptions()
  }

  getCountryName(id) {
    const found = this.globalCountries.find(x => x.countryId == id)
    return found ? found.name : ''
  }
  // only need an audit record if the user changed the borrowerCommand
  needAudit() {
    return this.unit.borrowerCommandId != this.initialUnit.borrowerCommandId
  }

  checkUnitName() {
    try {
      const unitName = this.unit.fullName().toLowerCase()
      const matches = this.units.filter((unit) => (unit.fullName().toLowerCase() === unitName && unit.unitId !== this.unit.unitId))
      return matches
    } catch (error) {
      console.error(`Error checking for unit name matches from units list for unit ${this.unit.fullName().toLowerCase()}${this.unit.unitId ? ' with ID ' + this.unit.unitId : ''}:\n`, error);
      return [];
    }
  }
  getShipType() {
    const shipClassName = this.unit.ship.shipClass?.shipClass.toLowerCase().trim()
    const vesselTypeName = this.unit.ship.vesselType?.vesselType.toLowerCase().trim()
    const countryName = this.getCountryName(this.unit.country?.countryId).toLowerCase().trim();
    const shipTypeName = shipClassName + vesselTypeName + countryName;
    const match = this.shipTypeOptions.find((shipType) =>
    (shipType?.shipClassName?.toLowerCase().trim() +
      shipType?.vesselTypeName?.toLowerCase().trim() +
      shipType?.remarks?.toLowerCase().trim()
      == shipTypeName
    )
    )
    return match
  }

  checkMidbId() {
    try {
      const midbMatches = this.units.filter((unit) => unit.midb == this.unit.midb)
      return midbMatches
    } catch (error) {
      console.error(`Error checking for MIDB ID matches from units list for unit ${this.unit.fullName().toLowerCase()}${this.unit.unitId ? ' with ID ' + this.unit.unitId : ''}:\n`, error);
      return [];
    }
  }

  formatUnits(unitMatches) {
    const units = unitMatches.filter((unit) => unit.unitId !== this.unit.unitId)
    return units.map((unit) => `Unit Name: ${unit.fullName()} \n Command: ${unit.port.command.name} \n Fleet: ${unit.fleet.fleetCode} \n Port: ${unit.port.name} \n SCONUM: ${unit.sconum} \n Country: ${unit.country.name} \n`)
  }

  checkUniqueSconum() {
    // No error on undefined or empty string SCONUM
    if (!this.unit.sconum || this.unit.sconum == "") {
      this.sconumError = false
    } else {
      const sconumArray = this.units
        .filter((unit) => unit.sconum && unit.sconum.trim() !== "")
        .map((unit) => {
          return unit.sconum.trim();
      });
      const unique = sconumArray.filter(x => x == this.unit.sconum.trim())
      if (this.isNew) {
        this.sconumError = unique && unique.length >= 1;
      } else {
        this.sconumError = unique && unique.length >= 2;
      }
    }
  }

  async submit() {
    const unitNameMatches = this.checkUnitName()
    const midbMatches = this.checkMidbId()
    this.formatUnits(unitNameMatches)
    let messages = []
    if (unitNameMatches.length) {
      messages.push(`There is already ${unitNameMatches.length} unit(s) with this name: \n ${this.formatUnits(unitNameMatches).join("")} Continuing to create or edit this unit could result in duplicate data.`)
    }
    if (midbMatches.length > 1 && this.unit.midb) {
      messages.push("The MIDB entered is already in use by other unit(s). These changes could result in non-unique values for MIDB ID.")
    }

    if (messages.length) {
      messages.push("Proceed?")
      if (confirm(messages.join("\n"))) {
        this.sendUpdate()
      }
    }
    else {
      this.sendUpdate()
    }
  }

  sendUpdate() {
    this.setFieldValuesToId();
    if (this.isNew) {
      //this.unit.commandId = (this.user as any).command_id
      this.unitService.create(this.unit).pipe(take(1)).subscribe(response => {
        this.dialogRef.close(response)
      }, err => {
        this.friendlyErrorService.processError(err);
      });
    }
    else {
      this.unitService.update(this.unit).pipe(take(1)).subscribe(response => {
        if (this.needAudit()) {
          let audit = new AuditUnitBorrowed()
          audit.commandId = response.commandId
          audit.unitId = response.unitId
          audit.borrowerCommandId = response.borrowerCommandId
          this.auditService.create(audit).pipe(take(1)).subscribe(auditResponse => {
            this.dialogRef.close(response)
          })
        }
        else {
          this.dialogRef.close(response)
        }
      }, err => {
        this.friendlyErrorService.processError(err);
      });
    }
  }

  cancel() {
    this.dialogRef.close(this.initialUnit)
  }

  fields = ['fleet', /*'class', 'type',*/ 'pennant', 'port', 'category', 'country', 'shipTypeId', 'threatId']
  needFields() {
    let need = false
    this.fields.forEach(field => {
      if (this.unit[field] == null || this.unit[field] == undefined || this.unit[field].length == 0) {
        need = true
      }
    })
    return need
  }

  // send a query to API to MIDB to see if this unit exists. If so, verify or update the MIDB ID
  getId() {
    const finalName = `${this.unit.class.trim()} ${this.unit.type.trim()} ${this.unit.pennant.trim()}`.trim()
    // gotta spin through countries if this is a new unit or changing country. country name would be wrong
    // MIDB query wants the country name but that is embedded in the app-lookup-select component, get it from globals
    const country = this.globalCountries.find(x => { return x.countryId == this.unit.countryId })
    this.unitService.getMIDBUnit(finalName, country.name).pipe(take(1)).subscribe(response => {
      // only going to accept the response if an exact hit, that means 'Unit' exists as an array of length 1 (midb microservice has setting to explicit array true)
      //this.unit.midb = response.GMIEntities && response.GMIEntities.Unit && response.GMIEntities.Unit.length == 1 ?
      //  response.GMIEntities.Unit[0].UnitId[0] : this.unit.midb
      // only going to accept the response if an exact hit, that means 'Unit' exists and is not an array of possible hits (midb microservice has setting to explicit array false)

      if (response.GMIEntities && response.GMIEntities.Unit && !Array.isArray(response.GMIEntities.Unit) && this.unit.midb == response.GMIEntities.Unit.UnitId) {
        this.snackBar.open(`${finalName} for flag ${country.name} MIDB ID is verified`, 'OK', { duration: 5000, panelClass: ['green-snackbar'] });
      }
      else if (response.GMIEntities && response.GMIEntities.Unit && !Array.isArray(response.GMIEntities.Unit)) {
        this.unit.midb = response.GMIEntities.Unit.UnitId
        this.snackBar.open(`MIDB ID has been updated. Please save changes`, 'OK', { duration: 5000, panelClass: ['green-snackbar'] });
      }
      else if (response.GMIEntities && response.GMIEntities.Unit && Array.isArray(response.GMIEntities.Unit)) {
        throw `${finalName} for flag ${country.name} is found more than once in the MIDB database`
      }
      else if (response.GMIEntities && !response.GMIEntities.Unit) {
        throw `${finalName} for flag ${country.name} is not found in the MIDB database`
      }
      else {
        this.snackBar.open(`MIDB Service is Offline, please contact an admin`, 'OK', { duration: 5000, panelClass: ['red-snackbar'] });
      }
    }, error => {
      // inform the user via red message
      throw (error)
    })
  }

  shipClassSelected(shipClass) {
    this.unit.ship.shipClassId = shipClass.shipClassId;
    this.unit.ship.shipClass = shipClass;
    if (this.unit.ship?.vesselTypeId) {
      //lookup the ship type field
      const newShipType = this.getShipType();
      if (newShipType) {
         // this.unit.ship.vesselType = newShipType.vesselType;
       // this.unit.ship.shipClass = newShipType.shipClass
        this.unit.shipTypeId = newShipType.shipTypeId;
        this.unit.type = newShipType.vesselTypeName;
        this.unit.class = newShipType.shipClassName;
        this.mismatchedShipType = false;
      }
      else {
        this.unit.shipTypeId = null;
        this.unit.type = null;
        this.unit.class = null;
        this.mismatchedShipType = true;
      }
    }
  }

  vesselTypeSelected(vesselType) {
    this.unit.ship.vesselTypeId = vesselType.vesselTypeId;
    this.unit.ship.vesselType = vesselType;
    if (this.unit.ship?.shipClassId) {
      //lookup the ship type field
      const newShipType = this.getShipType();
      if (newShipType) {
       // this.unit.ship.vesselType = newShipType.vesselType;
        //this.unit.ship.shipClass = newShipType.shipClass
        this.unit.shipTypeId = newShipType.shipTypeId;
        this.unit.type = newShipType.vesselTypeName;
        this.unit.class = newShipType.shipClassName;
        this.mismatchedShipType = false;
      }
      else {
        this.mismatchedShipType = true;
        this.unit.type = null;
        this.unit.class = null;
        this.unit.shipTypeId = null;
      }
    }
  }

  fleetSelected(fleet) {
    if (fleet?.remarks !== this.unit?.country?.name) {
      this.mismatchedFleet = true;
    }
  }

  // private _filter(value: string): Object[] {
  //   const filterValue = value.toLowerCase();

  //   return this.portOptions.filter(option => option.name.toLowerCase().includes(filterValue));
  // }

  autoCompleteArrays(){
    this.flagFilteredOptions = this.flagControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterArray(value || '', "flag"))
    );
    this.portFilteredOptions = this.portControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterArray(value || '', "port"))
    );
    this.fleetFilteredOptions = this.fleetControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterArray(value || '', "fleet"))
    );
    this.categoryFilteredOptions = this.categoryControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterArray(value || '', "category"))
    );
    this.shipClassFilteredOptions = this.shipClassControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterArray(value || '', "shipClass"))
    );
    this.vesselTypeFilteredOptions = this.vesselTypeControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterArray(value || '', "vesselType"))
    );
  }

  private _filterArray(value: string, type: string): Object[] {
    const filterValue = value ? value.toLowerCase() : '';
    switch (type) {
      case "flag":
        return this.globalCountries.filter(option => option.triGraph?.toLowerCase().includes(filterValue));
      case "port":
        return this.portOptions.filter(option => option.name.toLowerCase().includes(filterValue));
      case "fleet":
        return this.fleetOptions.filter(option => option.fleetCode.toLowerCase().includes(filterValue));
      case "category":
        return this.categoryOptions.filter(option => option.category.toLowerCase().includes(filterValue));
      case "shipClass":
        return this.shipClassOptions.filter(option => option.shipClass.toLowerCase().includes(filterValue));
      case "vesselType":
        return this.vesselTypeOptions.filter(option => option.vesselType.toLowerCase().includes(filterValue));
      default:
        return [];
    }
  }

  setFieldValuesToId(){ 
    this.unit.countryId = this.globalCountries.find(x => x.triGraph === this.flagControl.value).countryId;
    this.unit.portId = this.portOptions.find(x => x.name === this.portControl.value).portId;
    this.unit.fleetId = this.fleetOptions.find(x => x.fleetCode === this.fleetControl.value).fleetId;
    this.unit.categoryId = this.categoryOptions.find(x => x.category === this.categoryControl.value).categoryId;
    this.unit.ship.shipClass.shipClassId = this.shipClassOptions.find(x => x.shipClass === this.shipClassControl.value).shipClassId;
    this.unit.ship.shipClass.shipClass = this.shipClassControl.value;
    this.unit.ship.shipClassId = this.shipClassOptions.find(x => x.shipClass === this.shipClassControl.value).shipClassId;
    this.unit.class = this.shipClassControl.value;
    this.unit.ship.vesselType.vesselTypeId = this.vesselTypeOptions.find(x => x.vesselType === this.vesselTypeControl.value).vesselTypeId;
    this.unit.ship.vesselType.vesselType = this.vesselTypeControl.value;
    this.unit.type = this.vesselTypeControl.value;
    // this.unit.ship.shipClassId = shipClass.shipClassId;
    // this.unit.ship.shipClass = this.shipClassControl.value
    //mutate the fields back into values that the database needs. This was a workaround for matAutoComplete.
    // this.unit.fleetId = this.fleetOptions.find(x => x.fleetCode === this.editForm.controls['fleetId'].value).fleetId;
    // this.entity.activityCategoryId = this.activityCategoryOptions.find(x => x.description === this.editForm.controls['activityCategoryId'].value).activityId;
    // this.entity.opAreaId = this.opAreaOptions.find(x => x.areaRemarks() === this.editForm.controls['opAreaId'].value).opAreaId;
    
    // this.entity.lastLocSrc = this.sourceOptions.find(x => x.sourceName === this.editForm.controls['lastLocSrc'].value).sourceId;
    // this.entity.el_not_id = this.elnotOptions.find(x => x.el_not_code === this.editForm.controls['el_not_id'].value).el_not_id;
    // this.entity.scan_type_id = this.scanTypeOptions.find(x => x.scan_type_code === this.editForm.controls['scan_type_id'].value.substring(0, this.editForm.controls['scan_type_id'].value.indexOf(' '))).scan_type_id; //the html field "scan_type_id" is a misnomer. It's a combo of scan_type_code and scan_type_description. Here we cut the first part from the last to find a match in the scanTypeOptions array.
  }
}
