import { JsonObject, JsonProperty, Any } from 'json2typescript';
import { MessageConfig } from './message-config.model';

@JsonObject('SystemConnection')
export class SystemConnection {
  @JsonProperty('system_connection_id', String, true)
  systemConnectionId: string = undefined;
  @JsonProperty('system_name', String, true)
  systemName: string = undefined;
  @JsonProperty('host', String, true)
  host: string = undefined;
  @JsonProperty('port', Number, true)
  port: number = undefined;
  @JsonProperty('connection_params', String, true)
  connectionParams: string = undefined;
  @JsonProperty('enabled', Boolean, true)
  enabled: boolean = undefined;
  @JsonProperty('owner_id', String, true)
  ownerId: string = undefined;
  @JsonProperty('owner_type', String, true)
  ownerType: string = undefined;
  @JsonProperty('direction', String, true)
  direction: string = undefined;
  @JsonProperty('message_configs', [MessageConfig], true)
  messageConfigs: MessageConfig[] = [];
  @JsonProperty('owner_name', String, true)
  ownerName: any = undefined;
  @JsonProperty('environment', String, true)
  environment: string = undefined;
  @JsonProperty('protocol', String, true)
  protocol: string = undefined;
}
