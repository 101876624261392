import { MatDialogRef } from '@angular/material/dialog';
import { GlobalMessageTriggers } from '@services/global/message-bus/global-message-triggers.enum';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { DynamicComponentInterface } from '@core-module/app-components/dynanmic-component/dynamic-component.interface';
import { Component, OnInit } from '@angular/core';
import { GlobalBusMessage } from '@services/global/message-bus/global-bus-message.model';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.css']
})
export class ErrorModalComponent implements OnInit, DynamicComponentInterface {
  dynamicData
  constructor(private messageBusService: MessageBusService, private dialogRef: MatDialogRef<any>) { }

  ngOnInit() {
   
  }

  resolveDynamicComponentClass() {
    return ErrorModalComponent;
  }

  handleOk() {
    this.dialogRef.close(true);
  }
}
