import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PicklistType} from '@models/picklist-type.model';
import { EditPicklistTypeModalComponent } from '@edit-modals/edit-picklist-type-modal/edit-picklist-type-modal.component';
import { GridBaseComponent } from '@fom-module/base-classes/grid-base.component';
import { PicklistTypeService } from '@services/data-services/picklist-type.service';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { take } from 'rxjs/operators';
import { AppInitService } from '../../../config/init.service';

@Component({
  selector: 'app-licklist-type',
  templateUrl: './picklist-type.component.html',
  styleUrls: ['./picklist-type.component.css', '../base-classes/grid-base.component.css']
})
export class PicklistTypeComponent extends GridBaseComponent implements OnInit {
  displayedColumns = ['description', 'tableName', 'primaryKey', 'delete'];
  constructor(public messageBusService: MessageBusService,
    private picklistTypeService: PicklistTypeService,
    private dialog: MatDialog,
    public route: Router,
    public initService: AppInitService) {
      super(messageBusService, picklistTypeService, route);
    }

  ngOnInit() {
    this.loadEntityData();
  }

  edit(item: any) {
    const dialogRef = this.dialog.open(EditPicklistTypeModalComponent, {
      width: '320px'
    });
    dialogRef.componentInstance.isNew = false;
    dialogRef.componentInstance.entity = item;

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      if (result) {
        this.loadEntityData();
      }
    });
  }

  add() {
    const dialogRef = this.dialog.open(EditPicklistTypeModalComponent, {
      width: '320px'
    });
    dialogRef.componentInstance.isNew = true;
    dialogRef.componentInstance.entity = new PicklistType();

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      // in order to get complete data for the new Unit, go back to the DB
      this.loadEntityData();
    });
  }
}
