import { Component } from '@angular/core';
import { ReportingService } from '@services/data-services/reporting.service';
import { FormControl, FormGroup } from '@angular/forms';
import { FomPageBaseComponent } from '@fom-module/base-classes/fom-page-base.component';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Zulu1Pipe } from '../../../core/pipes/zulu1.pipe';
import { take } from 'rxjs/operators';
import { AppInitService } from '../../../../config/init.service';

@Component({
  selector: 'app-kml',
  templateUrl: './kml.component.html',
  styleUrls: ['./kml.component.css']
})
export class KMLComponent extends FomPageBaseComponent {
  // BOTTOM_NAV_BAR: any;
  statuses: Array<any>;

  constructor(
    private reportingService: ReportingService,
    public messageBusService: MessageBusService,
    public snackBar: MatSnackBar,
    private zulu1Pipe: Zulu1Pipe,
    public route: Router,
    public initService: AppInitService) {
    super(messageBusService, route);
  }

  googleEarthHistoryForm = new FormGroup({
    fromDate: new FormControl(),
    toDate: new FormControl()
  });

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {
    super.ngOnInit();
  }

  public getKML() {
    // console.log('click');
    let entity: any = {};
    for (const key in this.googleEarthHistoryForm.controls) {
      entity[key.toString()] = this.googleEarthHistoryForm.controls[key]['value'].toDate();
    }
    this.reportingService.getGoogleEarthHistory(entity.fromDate,
      entity.toDate).pipe(take(1)).subscribe(kml => {
        this.downloadKML(kml);
      });
  }

  downloadKML(data: any) {
    // tslint:disable-next-line:prefer-const
    let fileName = 'KML History.KML';
    // tslint:disable-next-line:prefer-const
    let file = new Blob([data]);
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(file, fileName);
    } else {
      // tslint:disable-next-line:prefer-const
      let a = document.createElement('a');
      a.href = window.URL.createObjectURL(file); // xhr.response is a blob
      a.style.display = 'none';
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      // window.URL.revokeObjectURL(a.href);
      // a.parentNode.removeChild(a);
    }
  }
  // a helper function to set the display string in the duplicate input control -- the date picker won't allow overriding the
  // format in its directive. we want YYYY-MM-DD HH:SS
  filterValue(name) {
    if (this.googleEarthHistoryForm.controls[name] && this.googleEarthHistoryForm.controls[name].value) {
      try {
        let date = new Date(this.googleEarthHistoryForm.controls[name].value)
        return this.zulu1Pipe.transform(date)
      }
      catch (e) {
        return ''
      }
    }
    else {
      return ''
    }
  }


}
