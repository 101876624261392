
import { JsonConvert, ValueCheckingMode } from 'json2typescript';

export class JsonConverter extends JsonConvert {

  constructor() {
    super();
    this.ignorePrimitiveChecks = true;
    this.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
  }
}
