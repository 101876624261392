<div class="narrow-card">
<mat-card class="form-card">
  <div fxLayout fxLayoutAlign="center center" class="simple-grid-title">
    <span class="grid-title">Download {{initService.getConfig().titlePrefix}} Record Message</span>
  </div>
    <div *ngIf="status == 'in progress'">
      <mat-progress-bar
          class="nob-margin"
          [color]="'primary'"
          [mode]="'indeterminate'"
      >
      </mat-progress-bar>
    </div>
    
    <div fxLayout fxLayoutAlign="center center">
      <mat-form-field appearance="fill">
        <mat-label>Select Option</mat-label>
        <mat-select #selectValue (selectionChange)="selectFeaturePref($event.value)">
          <mat-option *ngFor="let preference of featurePrefs" [value]="preference">
            {{ preference.featurePrefName }} (Version: {{preference.versionNum}})
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-icon-button color="primary" (click)="resetFeaturePrefSelection(selectValue)">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
    <div>
      <mat-form-field  class="input-full-width">
        <input matInput #inPortHour placeholder="In Port within X Hours" required 
        (keypress)="keyPressNumbers($event)"
        oninput="this.value = this.value.trim()">
      <mat-hint>Enter in number (>0) of hours for In Port Unit filter</mat-hint>
      </mat-form-field>
      <br>
    </div>
    &nbsp;
    <div fxLayout fxLayoutAlign="center center">
      <button *ngIf="pacfltRmgToggle" [disabled]="status == 'in progress' || selectedPrefType == 'RMG Report:MDOS' || selectedPrefType == 'RMG Report:MOS'" mat-raised-button color="primary" (click)="getRecordMessage('PACFLT', undefined, undefined)">Request RMG (PACFLT)</button>
      &nbsp;
      <button [disabled]="status == 'in progress' || selectedPrefType == 'RMG Report:MOS' || inPortHour.value == 0"  mat-raised-button color="primary" (click)="getRecordMessage('MDOS', selectValue.value, inPortHour.value)">Request RMG (MDOS)</button>
      &nbsp;
      <button [disabled]="status == 'in progress' || selectedPrefType == 'RMG Report:MDOS' || inPortHour.value == 0" mat-raised-button color="primary" (click)="getRecordMessage('MOS', selectValue.value, inPortHour.value)">Request RMG (MOS)</button>
      &nbsp;
    </div>
    &nbsp;
    <div>
      <button *ngIf="status == 'in progress'" mat-raised-button color="warn" (click)="cancel()">Cancel</button>
    </div>
</mat-card>
</div>
