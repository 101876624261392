<app-classification-bar class="top-modal-classification-bar"></app-classification-bar>
<app-fdtm-modal-header [headerString]="headerString"></app-fdtm-modal-header>
<div mat-dialog-content>
<form [formGroup]="editForm">
  <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
    <mat-form-field>
      <input required matInput type="text" formControlName="slideName" placeholder="Slide Name" tabindex="1">
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
    <mat-form-field>
      <mat-select formControlName="countryId" placeholder="Base Flag" tabindex="2" required  (selectionChange)="onCountryChange($event)">
        <mat-select-trigger>
          <img [src]="$any(country)?.flagImage" height="16">&nbsp;
          <span>{{country?.name}}</span>
        </mat-select-trigger>
        <mat-option *ngFor="let option of countryOptions" [value]="option.countryId">
          <img [src]="option.flagImage" height="16">&nbsp;
            {{option.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-select formControlName="fleetId" placeholder="Fleet Area" tabindex="3" required>
        <mat-option *ngFor="let option of fleetOptions" [value]="option.fleetId">
            {{option.fleetCode}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
    <mat-form-field class=fom-input-field>
      <input matInput
          formControlName="lat1" placeholder="Bottom Left Lat"  required
          tabindex="4">
          <mat-error *ngIf="editForm?.controls?.lat1?.hasError('format')">bad format</mat-error>
          <mat-error *ngIf="editForm?.controls?.lat1?.hasError('length')">improper length</mat-error>
          <mat-error *ngIf="editForm?.controls?.lat1?.hasError('range')">out of range</mat-error>
          <mat-error *ngIf="editForm?.controls?.lat1?.hasError('required')">required</mat-error>
     </mat-form-field>
     <mat-form-field class="fom-input-field">
      <input matInput formControlName="lon1" placeholder="Bottom Left Lon" required
          tabindex="5">
          <mat-error *ngIf="editForm?.controls?.lon1?.hasError('format')">bad format</mat-error>
          <mat-error *ngIf="editForm?.controls?.lon1?.hasError('length')">improper length</mat-error>
          <mat-error *ngIf="editForm?.controls?.lon1?.hasError('range')">out of range</mat-error>
          <mat-error *ngIf="editForm?.controls?.lon1?.hasError('required')">required</mat-error>
  </mat-form-field>  </div>
  <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
    <mat-form-field class=fom-input-field>
      <input matInput formControlName="lat2" placeholder="Top Right Lat" required
          tabindex="6">
          <mat-error *ngIf="editForm?.controls?.lat2?.hasError('format')">bad format</mat-error>
          <mat-error *ngIf="editForm?.controls?.lat2?.hasError('length')">improper length</mat-error>
          <mat-error *ngIf="editForm?.controls?.lat2?.hasError('range')">out of range</mat-error>
          <mat-error *ngIf="editForm?.controls?.lat2?.hasError('required')">required</mat-error>
    </mat-form-field>
    <mat-form-field>
      <input matInput formControlName="lon2" placeholder="Top Right Lon" required
          tabindex="7">
          <mat-error *ngIf="editForm?.controls?.lon2?.hasError('format')">bad format</mat-error>
          <mat-error *ngIf="editForm?.controls?.lon2?.hasError('length')">improper length</mat-error>
          <mat-error *ngIf="editForm?.controls?.lon2?.hasError('range')">out of range</mat-error>
          <mat-error *ngIf="editForm?.controls?.lon2?.hasError('required')">required</mat-error>
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
        <div>
          <input #fileInput type="file" tabindex="8"
            [accept]="['.jpg', '.png', '.emf', '.wmf', '.jpeg', '.jfif', '.jpe', '.bmp', '.dib', '.rle', '.gif', '.emz', '.wmz', '.tif', '.tiff', '.svg', '.ico']"
            (change)="onFileChanged($event)"
            (cancel)="cancelEvent()" />
        </div>
      </div>
  <div mat-dialog-actions>
    <button *ngIf="isNew" [disabled]="!editForm.valid || (isNew && !selectedFile)"
      mat-raised-button color="primary" (click)="handleSubmit()" tabindex="9">Submit</button>
    <button *ngIf="!isNew" [disabled]="!editForm.valid"
      mat-raised-button color="primary" (click)="update()" tabindex="9">Update</button>
    <button mat-raised-button (click)="handleCancel()" tabindex="10">Cancel</button>
  </div>
</form>
</div>
<app-classification-bar class="bottom-modal-classification-bar"></app-classification-bar>
