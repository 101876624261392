import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

@Injectable()
export class SetFiltersInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>,
              next: HttpHandler): Observable<HttpEvent<any>> {

        // const filters = sessionStorage.getItem('currentUserABACPrefs') ?
        //   JSON.parse(sessionStorage.getItem('currentUserABACPrefs')) : null;
        // if (filters) {
        //   const cloned = req.clone( {
        //     setParams: filters
        //   });

        //   return next.handle(cloned);
        // } else {
          return next.handle(req);
        // }
//        }
    }
}
