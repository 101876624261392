import { GlobalMessageTriggers } from '@services/global/message-bus/global-message-triggers.enum';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { DynamicComponentInterface } from './../dynanmic-component/dynamic-component.interface';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent {
  modalContent: DynamicComponentInterface;
  dynamicData: any;
  constructor(private dialogRef: MatDialogRef<any>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private messageBusService: MessageBusService) {

        this.modalContent = new data.component();
        this.dynamicData = data.data;
        this.listenToMessageBus();

  }

  listenToMessageBus() {
    this.messageBusService.globalMessage$.subscribe(message => {
      if (message.trigger === GlobalMessageTriggers.CLOSE_MODAL) {
        this.dialogRef.close(true);
      }
    });
  }

}
