import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from './api.config';
import { ElintFunctionType } from '@models/elint-function-type.model';

@Injectable()
export class ElintFunctionTypeApiService {
  SERVER_URL;
  RESOURCE_URI = 'elintfunctiontypes';


  constructor(
    private http: HttpClient, apiConfig: APIConfig) {
    this.SERVER_URL = apiConfig.TARGET_URL;
  }

  public getAllElintFunctionTypes(): Observable<any> {
    return this.http.get(
      `${this.SERVER_URL}/${this.RESOURCE_URI}`);
  }

  public getElintFunctionTypeByElintFunctionTypeId(id: string): Observable<any> {
    return this.http.get(
      `${this.SERVER_URL}/${this.RESOURCE_URI}/${id}`);
  }

  public updateElintFunctionType(ElintFunctionType: ElintFunctionType): Observable<any> {
    return this.http.put(
      `${this.SERVER_URL}/${this.RESOURCE_URI}/${ElintFunctionType.elint_function_id}`, ElintFunctionType);
  }

  public createElintFunctionType(ElintFunctionType: any): Observable<any> {
    return this.http.post(
      `${this.SERVER_URL}/${this.RESOURCE_URI}`, ElintFunctionType);
  }

  public deleteElintFunctionType(ElintFunctionType: ElintFunctionType): Observable<any> {
    return this.http.delete(
      `${this.SERVER_URL}/${this.RESOURCE_URI}/${ElintFunctionType.elint_function_id}`);
  }
}
