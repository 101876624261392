import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommandService } from '@services/data-services/command.service';
import { FormBuilder } from '@angular/forms';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { User } from '@models/user.model';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { EditOtherNameModalComponent } from '@fom-module/edit-modals/edit-other-name-modal/edit-other-name-modal.component';
import { take } from 'rxjs/operators';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { CurrentUserService } from '@services/current-user-service';
import { UnitOtherName } from '@models/unit-other-name.model';
import { MatPaginator } from '@angular/material/paginator';
import { UnitOtherNameService } from '@services/data-services/unit-other-name-service';
import { UnitService } from '@services/data-services/unit.service';
import { GridBaseComponent } from '@fom-module/base-classes/grid-base.component';
import { Router } from '@angular/router';
import { AppInitService } from '../../../config/init.service';

const DEFAULT_SORT: Sort = {
  active: 'create_ts',
  direction: 'desc'
}
@Component({
  selector: 'app-other-name-table-modal',
  templateUrl: './other-name-table-modal.component.html',
  styleUrls: ['./other-name-table-modal.component.css']
})
export class OtherNameTableModal extends GridBaseComponent implements OnInit, OnDestroy {
  paginator: MatPaginator;
  currentUser: boolean = false;
  public unitId: string;
  user: any;
  displayedColumns: string[] = ['other_name', 'edit', 'delete'];

  constructor(public otherNameService: UnitOtherNameService,
    public commandService: CommandService,
    public unitService: UnitService,
    public route: Router,
    public unitOtherNameService: UnitOtherNameService,
    private currentUserService: CurrentUserService,
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<OtherNameTableModal>,
    private dialog: MatDialog,
    public messageBusService: MessageBusService,
    public initService: AppInitService) {
      super(messageBusService, otherNameService, route);
    }

  ngOnInit() {
    
    this.user = this.currentUserService.getCurrentUser();
    this.isLoggedIn();
    setTimeout(() => {
      this.loadEntityData();
    }, 0);
  }

  loadEntityData() {
    this.unitOtherNameService.getUnitOtherNames(this.unitId).pipe(take(1)).subscribe(response => {
      this.dataSource = new MatTableDataSource<object>(response);
      this.dataSource.paginator = this.paginator;
    });
  }

  ngAfterViewInit() {
  }

  ngOnDestroy(): void {
  }

  close() {
    this.dialogRef.close();
  }

  add() {
    const dialogRef = this.dialog.open(EditOtherNameModalComponent, {
      width: '680px'
    });
    // dialogRef.componentInstance.unit = new Unit();
    dialogRef.componentInstance.isNew = true;
    dialogRef.componentInstance.unitId = this.unitId;
    // dialogRef.componentInstance.units = this.units;

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      this.loadEntityData()
    });
  }

  edit(unitOtherName: UnitOtherName) {
    const dialogRef = this.dialog.open(EditOtherNameModalComponent, {
      width: '450px'
    });
    dialogRef.componentInstance.isNew = false;
    dialogRef.componentInstance.unitOtherName = unitOtherName

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
        this.loadEntityData()
    });

  }

  handleDelete(unitOtherNameObj: UnitOtherName) {
    super.handleDelete(unitOtherNameObj, 'Delete Other Name', `Select "Delete" to delete ${unitOtherNameObj.otherName}`, 'Delete')
  }

  isLoggedIn(){
    if(sessionStorage.getItem('token') !== null){
      this.currentUser = true
      this.user = JSON.parse(sessionStorage.user)
    }else{
      this.currentUser = false
      this.user = new User
      this.user.command = {}
    }
  }
}

