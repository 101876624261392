import { CPage } from '@models/cpage.model';
import { CPageApiService } from './../api-services/cpage-api.service';
import { Injectable } from '@angular/core';
import { JsonConverter } from '../global/json-converter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable()
export class CPageService {
    private jsonConvert: JsonConverter;

    constructor(private cpageApiService: CPageApiService) {
        this.jsonConvert = new JsonConverter();
    }

    public getAll(params = {}): Observable<CPage[]> {
        return this.cpageApiService.getAllCPages(params)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, CPage);
                })
            );
    }

    public getCPageByCPageId(cpageId): Observable<CPage> {
        return this.cpageApiService.getCPageByCPageId(cpageId)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, CPage);
                })
            );
    }

    public update(cpage: CPage): Observable<CPage> {
        return this.cpageApiService.updateCPage(this.jsonConvert.serializeObject(cpage))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, CPage);
                })
            );
    }

    public create(cpage: CPage): Observable<CPage> {
        const fd = new FormData();
        if (cpage.image) {
            fd.append('image', cpage.image['contents'], cpage.image['name']);
        }
        fd.append('doc_name', cpage.docName);
        fd.append('doc_type', cpage.docType);
        fd.append('doc_size', cpage.docSize.toString());
        fd.append('first_name', cpage.firstName);
        fd.append('last_name', cpage.lastName);
        fd.append('email', cpage.email);
        fd.append('comments', cpage.comments);
        fd.append('classification', cpage.classification);
        fd.append('unit_id', cpage.unitId);
        fd.append('ver_code', cpage.verCode);
        //fd.append('link', slide.link);
        return this.cpageApiService.createCPage(fd)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, CPage);
                })
            );
    }

    public delete(cpage: CPage): Observable<CPage> {
        return this.cpageApiService.deleteCPage(this.jsonConvert.serializeObject(cpage))
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    public getFile(cpageId): Observable<any> {
        return this.cpageApiService.getFile(cpageId)
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }
}
