import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { CommandHierarchyService } from './data-services/command-hierarchy.service';

@Injectable({
  providedIn: 'root'
})

export class UserJfmccService {

  constructor(private commandHierarchyService: CommandHierarchyService) {
  }

  public async getJfmccs(user) {
    let jfmccs;

    try {
      const json = await this.commandHierarchyService.getAll({ sort: 'version_num', limit: 1 }).pipe(take(1)).toPromise()
      if(json[0]){
        const commandHierarchy = json[0];
        user.isSystemAdmin() ? jfmccs = commandHierarchy.getJFMCCs() : jfmccs = commandHierarchy.getJFMCCIds(user.command)
      }
    } catch(err) {
      throw err
    }

    return jfmccs;
  }

}