import { JsonObject, JsonProperty, Any, PropertyConvertingMode } from 'json2typescript';
import { DateConverter } from '../services/global/date-converter';
import { Activity } from './activity.model';
import { Source } from './source.model';
import { Elnot } from './elnot.model';
import { Unit } from './unit.model';
import { VersionHistory } from './version.model';
import { User } from './user.model';
import { Area } from './area.model';
import { Elint } from './elint.model';

@JsonObject('Location')
export class Location {
  @JsonProperty('ship_location_id', String, true)
  shipLocationId: string = undefined;
  @JsonProperty('unit_id', String, true)
  unitId: string = undefined;
  @JsonProperty('final_name', String, true)
  finalName: string = undefined;
  @JsonProperty('fleet_id', String, true)
  fleetId: string = undefined;
  @JsonProperty('days_out', Number, true)
  daysOut: number = undefined;
  @JsonProperty('total_days', Number, true)
  totalDays: number = undefined;
  @JsonProperty('dtg_imaged_ts', DateConverter, true)
  dtgImaged: Date = undefined;
  @JsonProperty('loc_time_ts', DateConverter, true)
  locTime: Date = undefined;
  @JsonProperty('last_loc', String, true)
  lastLoc: string = undefined;
  @JsonProperty('latitude', String, true)
  latitude: string = undefined;
  @JsonProperty('longitude', String, true)
  longitude: string = undefined;
  @JsonProperty('dec_lat', Number, true)
  decLat: number = undefined;
  @JsonProperty('dec_lon', Number, true)
  decLon: number = undefined;
  @JsonProperty('course', String, true)
  course: string = undefined;
  @JsonProperty('speed', String, true)
  speed: string = undefined;
  @JsonProperty('last_loc_src', String, true)
  lastLocSrc: string = undefined;
  @JsonProperty('src_orig', String, true)
  srcOrig: string = undefined;
  @JsonProperty('latency_id', String, true)
  latencyId: string = undefined;
  @JsonProperty('activity', String, true)
  activity: string = undefined;
  @JsonProperty('op_area_id', String, true)
  opAreaId: string = undefined;
  @JsonProperty('tunnel_ind', Boolean, true)
  tunnelInd: boolean = undefined;
  @JsonProperty('pat_cand_ind', Boolean, true)
  patCandInd: boolean = undefined;
  @JsonProperty('papa_case', String, true)
  papaCase: string = undefined;
  @JsonProperty('nob_remove_ind', Boolean, true)
  nobRemoveInd: boolean = undefined;
  @JsonProperty('reassessed_ind', Boolean, true)
  reassessedInd: boolean = undefined;
  @JsonProperty('prev_assess', String, true)
  prevAssess: string = undefined;
  @JsonProperty('classification_id', String, true)
  classificationId: string = undefined;
  @JsonProperty('classification_description', String, true)
  classificationDescription: string = undefined;
  @JsonProperty('is_ts_sci_ind', Boolean, true)
  isTsSciInd: boolean = undefined;
  // @JsonProperty('ssma_ts', String, true)
  // ssmaTs: Date = undefined;
  @JsonProperty('activity_category_id', String, true)
  activityCategoryId: string = undefined;
  @JsonProperty('hash_code', String, true)
  hashCode: string = undefined;
  @JsonProperty('event_code', String, true)
  eventCode: string = undefined;
  @JsonProperty('ltn', String, true)
  ltn: string = undefined;
  @JsonProperty('uid', String, true)
  uid: string = undefined;
  @JsonProperty('uw_code', String, true)
  uwCode: string = undefined;
  @JsonProperty('midb_update_ind', Boolean, true)
  midbUpdateInd: boolean = undefined;
  @JsonProperty('mfi_ts', DateConverter, true)
  mfiTs: Date = undefined;
  @JsonProperty('created_by', String, true)
  createdBy: string = undefined;
  // Attribute used to pass back to API that the last Location for the Unit removed it from NOB
  @JsonProperty('is_removed_from_nob', Boolean, true)
  isRemovedFromNob: boolean = undefined;
  // Attribute used to pass the new Unit ID to associate Locations with when update is a Reassessment
  @JsonProperty('new_unit_id', String, true)
  newUnitId: string = undefined;
  @JsonProperty('source', Source, true)
  source: Source = undefined;
  @JsonProperty('unit', Unit, true)
  unit: Unit = undefined;
  @JsonProperty('classification_string', String, true)
  classification: string = undefined;
  @JsonProperty('activity_category', Activity, true)
  activityCategory: Activity = undefined;
  @JsonProperty('create_ts', DateConverter, true)
  createTs: Date = undefined;
  @JsonProperty('update_ts', DateConverter, true)
  updateTs: Date = undefined;
  @JsonProperty('rationale', String, true)
  rationale: string = undefined;
  @JsonProperty('versions', [VersionHistory], true)
  versions: VersionHistory[] = [];
  @JsonProperty('creator', User, true)
  creator: User = undefined;
  @JsonProperty('updater', User, true)
  updater: User = undefined;
  @JsonProperty('op_area', Area, true)
  opArea: Area = undefined;
  @JsonProperty('el_not_id', String, true)
  el_not_id: string = undefined;
  @JsonProperty('el_not', String, true)
  el_not: string = undefined;
  @JsonProperty('provider', String, true)
  provider: string = undefined;
  @JsonProperty('freq', String, true)
  freq: string = undefined;
  @JsonProperty('band', String, true)
  band: string = undefined;
  @JsonProperty('func', String, true)
  func: string = undefined;
  @JsonProperty('elint_function_id', String, true)
  elint_function_id: string = undefined;
  @JsonProperty('pul_dur', Number, true)
  pul_dur: number = undefined;
  @JsonProperty('pri', Number, true)
  pri: number = undefined;
  @JsonProperty('scan_rate', Number, true)
  scan_rate: number = undefined;
  @JsonProperty('scan_measure', String, true)
  scan_measure: string = undefined;
  @JsonProperty('scan_type', String, true)
  scan_type: string = undefined;
  @JsonProperty('scan_type_id', String, true)
  scan_type_id: string = undefined;
  @JsonProperty('freq_type_id', String, true)
  freq_type_id: string = undefined;
  @JsonProperty('toi_ts', DateConverter, true)
  toi_ts: Date = undefined;
  @JsonProperty('elint', Elint, PropertyConvertingMode.PASS_NULLABLE)
  elint: Elint = undefined;
  @JsonProperty('parser_msg_id', String, true)
  parser_msg_id: string = undefined;
  @JsonProperty('parser_msg_pos_jpath', String, true)
  parser_msg_pos_jpath: string = undefined;
  @JsonProperty('mfi_ind', Boolean, true)
  mfiInd: boolean = undefined;
  @JsonProperty('port_facility', String, true)
  portFacility: string = undefined;
  @JsonProperty('port_id', String, true)
  port_id: string = undefined;
  @JsonProperty('source_code', String, true)
  sourceCode: string = undefined;
  @JsonProperty('days_out_ts', DateConverter, true)
  daysOutTs: Date = undefined;
  @JsonProperty('confidence_code_id', String, true)
  confidenceCodeId: string = undefined;
 /*
      in_port_ts: DataTypes.DATE,
      ooa_ind: DataTypes.BOOLEAN*/

  public static readonly READABLE_ATTRIBUTE_MAP = {
    activity: 'Activity',
    activity_category_id: 'Activity Category',
    classification_description: 'Classification',
    days_out: 'Days Out',
    dec_lat:'Decimal Latitude',
    dec_lon: 'Decimal Longitude',
    dtg_imaged_ts: "Last Imaged DTG",
    event_code: 'Event Code',
    final_name: 'Final Name',
    fleet_id: 'Fleet',
    hash_code: 'Hash Code',
    last_loc: 'Last Location',
    last_loc_src: 'Source',
    latitude: 'Latitude',
    loc_time_ts: 'Location Time',
    longitude: 'Longitude',
    mfi_ts: 'MFI DTG',
    nob_remove_ind: 'Remove from NOB View',
    op_area_id: 'U/W Area',
    papa_case: 'Case Number',
    pat_cand_ind: 'Patrol Candidate',
    prev_assess: 'Previous Assessment Pennant',
    src_orig: 'Originator',
    tunnel_ind: 'In Tunnel',
    unit_id: 'Unit',
    uw_code: 'UW Code',
    mfi_ind: 'MFI?',
    port_facility: 'Port Facility',
    source_code: 'Source Code',
    confidence_code_id: 'Confidence'
  }

  /** Initial plain implementation. Should at least be broken out into its own format function,
   * or bring in a date library to handle formatting dates. */

  /*2023-02-22:PMA: Is this dead code?
  formattedDtgImaged(): string {
    const date = new Date(this.dtgImaged);
    return date.getUTCFullYear() + '-' + ('0' + (date.getUTCMonth() + 1)).slice(-2) + '-' +
      ('0' + date.getUTCDate()).slice(-2) + ' ' + ('0' + date.getUTCHours()).slice(-2) + ':' +
      ('0' + date.getUTCMinutes()).slice(-2);

  }

  formattedMfiDtg(): string {
    const date = new Date(this.mfiTs);
    return date.getUTCFullYear() + '-' + ('0' + (date.getUTCMonth() + 1)).slice(-2) + '-' +
      ('0' + date.getUTCDate()).slice(-2) + ' ' + ('0' + date.getUTCHours()).slice(-2) + ':' +
      ('0' + date.getUTCMinutes()).slice(-2);
  }

  formattedLocTime(): string {
    const date = new Date(this.locTime);
    return date.getUTCFullYear() + '-' + ('0' + (date.getUTCMonth() + 1)).slice(-2) + '-' +
    ('0' + date.getUTCDate()).slice(-2) + ' ' + ('0' + date.getUTCHours()).slice(-2) + ':' +
    ('0' + date.getUTCMinutes()).slice(-2);
  } 
  End DEAD CODE*/
}
