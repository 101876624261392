import { ReportTemplate } from '@models/report-template.model';
import { ReportTemplateApiService } from '@services/api-services/report-template-api.service';
import { Injectable } from '@angular/core';
import { JsonConverter } from '@services/global/json-converter';
import { Observable, empty } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Form } from '@angular/forms';

@Injectable({
    providedIn: 'root'
  })
export class ReportTemplateService {
    private jsonConvert: JsonConverter;

    constructor(private preferenceApiService: ReportTemplateApiService) {
        this.jsonConvert = new JsonConverter();
    }

    public getAll(): Observable<ReportTemplate[]> {
        return this.preferenceApiService.getAll()
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, ReportTemplate);
                })
            );
    }

    public getAllByName(reportTemplateName: string): Observable<ReportTemplate[]> {
        return this.preferenceApiService.getAllByName(reportTemplateName)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, ReportTemplate);
                })
            );
    }

    public getById(parameterId: string): Observable<ReportTemplate> {
        return this.preferenceApiService.getById(parameterId)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, ReportTemplate);
                })
            );
    }


    public update(parameterId, formData: FormData): Observable<ReportTemplate> {
        return this.preferenceApiService.update(parameterId, formData)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, ReportTemplate);
                })
            );
    }

    public create(reportTemplate: ReportTemplate): Observable<ReportTemplate> {
        return this.preferenceApiService.create(this.jsonConvert.serializeObject(reportTemplate))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, ReportTemplate);
                })
            );
    }

    public delete(parameter: ReportTemplate): Observable<ReportTemplate> {
        return this.preferenceApiService.delete(this.jsonConvert.serializeObject(parameter))
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }
}
