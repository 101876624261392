import { Component, OnInit, ViewChild } from '@angular/core';
import { CommandService } from '@services/data-services/command.service';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { Router } from '@angular/router';
import { GlobalMessageTriggers } from '@services/global/message-bus/global-message-triggers.enum';
import { EditCommandModalComponent } from '@edit-modals/edit-command-modal/edit-command-modal.component';
import { GridBaseComponent } from '@fom-module/base-classes/grid-base.component';
import { User } from '@models/user.model';
import { Command } from '@models/command.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LookUpService } from '@services/data-services/lookup.service';
import { MatTableDataSource } from '@angular/material/table';
import { take } from 'rxjs/operators';
import { AppInitService } from '../../../config/init.service';
import { CurrentUserService } from '@services/current-user-service';

@Component({
  selector: 'app-commands',
  templateUrl: './commands.component.html',
  styleUrls: ['./commands.component.css', '../base-classes/grid-base.component.css']
})
export class CommandsComponent extends GridBaseComponent implements OnInit {
  displayedColumns = ['name', 'logo', 'users', 'units', 'edit', 'delete'];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource: MatTableDataSource<Command> = new MatTableDataSource<Command>();

  user: User;
  commands: [Command];
  apiUrl: any;
  users: any;
  units: any;
  unitCount: {};

  constructor(public messageBusService: MessageBusService,
    private lookupService: LookUpService,
    public commandService: CommandService,
    private currentUserService: CurrentUserService,
    private dialog: MatDialog,
    public configInfo: AppInitService,
    public route: Router) {
    super(messageBusService, commandService, route);
    this.RELOAD_MESSAGE = GlobalMessageTriggers.RELOAD_COMMAND_DATA;
    this.EDIT_MODAL_COMPONENT = EditCommandModalComponent;
  }

  ngOnInit() {
    this.user = this.currentUserService.getCurrentUser();
    super.listenToMessageBus();

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = (data: Command, filter: string) => data.name.trim().toLowerCase().indexOf(filter) != -1;

    this.loadEntityData();
  }

  loadEntityData() {
    this.commandService.getAll({ include: 'users, ports.units', fields: 'users: count' }).pipe(take(1)).subscribe(entities => {
      this.dataSource.data = entities;
      this.getUnitCount(entities)
    })
  }

  getUnitCount(commands) {
    let units = new Array
    let object = {}

    commands.forEach((command) => {
      object[command.commandId] = {
        count: 0,
        delegated: 0,
        loaned: 0
      }
      command.ports.forEach((port) => units.push(port.units))
    })
    units.flat().forEach((unit) => {
      if (unit.borrower_command_id) {
        object[unit.borrower_command_id]['delegated'] += 1
        object[unit.command_id]['loaned'] += 1
      }
      object[unit.command_id]['count'] += 1;
    })

    this.unitCount = object;
  }

  formatUnitCount(commandId) {
    const count = this.unitCount[commandId]?.count ? this.unitCount[commandId]?.count : 0
    const delegated = this.unitCount[commandId]?.delegated
    return `${count} ${delegated ? '(' + this.unitCount[commandId]?.delegated + ' delegated)' : ''}`
  }

  handleDelete(entity: Command) {
    super.handleDelete(entity, 'Delete Command', `Select "Delete" to delete ${entity.name}`, 'Delete');
  }

  edit(command: any) {
    const dialogRef = this.dialog.open(EditCommandModalComponent, {
      width: '400px'
    });
    dialogRef.componentInstance.isNew = false;
    dialogRef.componentInstance.entity = command;

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {

      // Only reload data when submitted. Dialog closes with undefined when canceled and true when
      // close button clicked.
      if (result && result !== true) {
        this.loadEntityData();
      }
    });
  }

  add() {
    const dialogRef = this.dialog.open(EditCommandModalComponent, {
      width: '400px'
    });
    dialogRef.componentInstance.isNew = true;
    dialogRef.componentInstance.entity = new Command();

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      // Only reload data when submitted. Dialog closes with undefined when canceled and true when
      // close button clicked.
      if (result && result !== true) {
        this.loadEntityData();
      }
    })
  }

  fetchCommandLogo(command) {
    return this.commandService.getLogoUrl(command);
  }
}
