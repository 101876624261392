import { Injectable } from '@angular/core';
import { JsonConverter } from '../global/json-converter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScanTypeApiService } from '@services/api-services/scan-type-api.service';
import { ScanType } from '@models/scan-type.model';


@Injectable()
export class ScanTypeService {
    private jsonConvert: JsonConverter;

    constructor(private apiService: ScanTypeApiService) {
        this.jsonConvert = new JsonConverter();
    }

    public getAll(): Observable<ScanType[]> {
        return this.apiService.getAllScanTypes()
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, ScanType);
                })
            );
    }

    public getCategoriesByCatId(id): Observable<ScanType> {
        return this.apiService.getScanTypeByScanTypeId(id)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, ScanType);
                })
            );
    }

    public update(scantype: ScanType): Observable<ScanType> {
        return this.apiService.updateScanType(this.jsonConvert.serializeObject(scantype))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, ScanType);
                })
            );
    }

    public create(scantype: ScanType): Observable<ScanType> {
        return this.apiService.createScanType(this.jsonConvert.serializeObject(scantype))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, ScanType);
                })
            );
    }

    public delete(scantype: ScanType): Observable<ScanType> {
        return this.apiService.deleteScanType(this.jsonConvert.serializeObject(scantype))
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

}
