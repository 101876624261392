<app-classification-bar class="top-modal-classification-bar" *ngIf="fromTrackMaint"></app-classification-bar>
<mat-card class="form-card">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="simple-grid-title">
        <span></span>
        <span class="grid-title">{{initService.getConfig().titlePrefix}} FDT-M Unit Update</span>
        <button mat-icon-button (click)="cancel()" *ngIf="fromTrackMaint">
            <mat-icon>close</mat-icon>
        </button>
        <span *ngIf="!fromTrackMaint"></span> <!-- Empty span to balance the space when button is not present -->
        <!-- <app-search-filter-control [filterType]="'Unit'" (filtersChanged)="unitFiltersChanged($event)"></app-search-filter-control> -->
    </div>
    <div *ngIf="fromTrackMaint && !isDelete && !fromParser" fxLayout="row" fxLayoutAlign="center center">
        <div class="mat-color-warn warning">
            NOTE: You are doing a track maintenance update. You must reclassify this track.<br>The previous
            classifiation of this track is: {{trackMaintEntity.classification}}
        </div>
    </div>
    <div *ngIf="fromTrackMaint && !isDelete && fromParser" fxLayout="row" fxLayoutAlign="center center">
        <div class="mat-color-warn warning">
            NOTE: You are doing a track maintenance update from a record that has been accepted through the parser
            queue. Some fields may not be editable.
        </div>
    </div>
    <form [formGroup]="editForm" #uuForm>
        <mat-card-content class="fom-card-content">
            <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px"
                fxLayoutGap.xs="0">
                <div fxFlex="20%">
                    <h4>* CLASSIFICATION:</h4>
                    <mat-radio-group class="noscroll-bordered" fxLayout="column" formControlName="classificationId"
                        placeholder="Classification">
                        <mat-radio-button validation="required" class="classification-option smallRadio"
                            *ngFor="let option of classificationOptions" [value]="option.classificationId">
                            {{option.classificationName}}
                        </mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="uuForm.submitted && editForm.controls.classificationId.invalid">Classification is
                        required.</mat-error>
                </div>
                <div fxFlex="20%">
                    <h4>CONTROL: </h4>
                    <mat-selection-list class="bordered" fxLayout="column" formControlName="sciControl">
                        <mat-list-option class="smallerList" checkboxPosition="before"
                            *ngFor="let option of sciControlsOptions" [value]="option.sciControlId">
                            {{option.controlMark}}
                        </mat-list-option>
                    </mat-selection-list>
                </div>

                <div fxFlex="20%">
                    <h4>FGI:</h4>
                    <mat-selection-list class="bordered" fxLayout="column" formControlName="fgiControl">
                        <mat-list-option class="smallerList" checkboxPosition="before" [disabled]="option.isDisabled"
                            *ngFor="let option of fgiControlOptions" [value]="option.fgiId">
                            {{option.name}}
                        </mat-list-option>
                    </mat-selection-list>
                </div>

                <div fxFlex="20%">
                    <h4>OTHER CONTROLS:</h4>
                    <mat-selection-list class="bordered" fxLayout="column" formControlName="otherControl">
                        <mat-list-option class="smallerList" checkboxPosition="before"
                            *ngFor="let option of otherControlsOptions" [value]="option.controlId">
                            {{option.description}}
                        </mat-list-option>
                    </mat-selection-list>
                </div>

                <div fxFlex="20%">
                    <h4>DISSEMINATION:</h4>
                    <mat-selection-list class="bordered" fxLayout="column" formControlName="dissemination">
                        <mat-list-option class="smallerList" checkboxPosition="before"
                            *ngFor="let option of releasabilityOptions" [value]="option.releasibilityId">
                            {{option.releasabilityName}}
                        </mat-list-option>
                    </mat-selection-list>
                </div>
            </div>
            <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px"
                fxLayoutGap.xs="0">

                <div fxFlex="20%" fxFlex.md="40%" fxFlex.lg="30%">
                    <span matPrefix>
                        <mat-select class="country-filter" [(value)]="filterByCountry"
                            (selectionChange)="countryFilterChange($event)">
                            <mat-select-trigger *ngIf="filterByCountry.name"
                                matTooltip="Find Units for {{filterByCountry.name}}">
                                <img [src]="getFlag(filterByCountry)" height="16">&nbsp;
                            </mat-select-trigger>
                            <mat-select-trigger *ngIf="!filterByCountry.name"
                                matTooltip="Select a Country to Filter Search">
                                <mat-icon style="vertical-align:middle;">flag</mat-icon>
                            </mat-select-trigger>
                            <mat-option [value]="noCountry">
                                <mat-icon style="vertical-align:middle;">flag</mat-icon> (clear)
                            </mat-option>
                            <mat-option *ngFor="let country of countries" [value]="country"
                                [disabled]="country.isDisabled">
                                <img [src]="getFlag(country)" height="16">&nbsp;{{country.name}}
                            </mat-option>
                        </mat-select>
                    </span>
                    <mat-form-field class="unit-input-field">
                        <span matPrefix *ngIf="utilities.getFlag(editForm.controls?.unit?.value)"><img
                                style="vertical-align:middle;" [src]="utilities.getFlag(editForm.controls?.unit?.value)"
                                height="20" />&nbsp;</span>
                        <input required matInput type="text" formControlName="unit" placeholder="Unit"
                            [matAutocomplete]="unitAutocomplete" tabindex="1"
                            matTooltip="{{editForm.controls?.unit?.value | unitNamePipe}}">
                        <mat-error *ngIf="!editForm.controls?.unit?.value?.unitId">Find and select Unit to enable
                            remaining fields</mat-error>
                    </mat-form-field>
                    <mat-autocomplete #unitAutocomplete="matAutocomplete" [displayWith]="displayUnit"
                        (optionSelected)="handleUnitChange($event)" showPanel="true"
                        (optionsScroll)="onUnitOptionsScroll()">
                        <ng-container>
                            <mat-option *ngFor="let option of filteredUnits$ | async" [value]="option"
                                matTooltip="{{option.fullName()}}">
                                <span matTooltip="{{option.country?.name}}">
                                    <img style="vertical-align:middle;" [src]="utilities.getFlag(option)"
                                        height="20" />&nbsp;
                                </span>
                                <span>{{option.fullName()}}</span>
                            </mat-option>
                        </ng-container>
                        <mat-option *ngIf="isLoading" class="is-loading">
                            <mat-spinner diameter="30"></mat-spinner>
                        </mat-option>
                    </mat-autocomplete>

                    <div class="mat-color-warn warning" *ngIf="user.unitUpdateStatus(unit)">
                        {{user.unitUpdateStatus(unit)}}
                        <span class="brsmall"></span>
                    </div>
                    <div class="mat-color-warn warning" *ngIf="entity.prevAssess">
                        * This unit was previously assessed with pennant {{entity.prevAssess}}. The previously assessed
                        pennant number will remain appended to this update.
                    </div>
                    <br>
                </div>

                <div fxFlex="20%" fxFlex.md="40%" fxFlex.lg="30%">
                    <mat-form-field class="fom-input-field" [floatLabel]="editForm?.controls?.locTime?.value?.length">
                        <input matInput [owlDateTime]="locTime" tabindex="19" required formControlName="locTime"
                            autocomplete="off" (dateTimeChange)="dateTimeChange($event, 'locTime')"
                            placeholder="Location Time (d M Y hm)" [max]="this.allowFutureDates ? undefined : maxDate"
                            [readonly]="!unit.unitId">
                        <owl-date-time [disabled]="!unit.unitId" #locTime></owl-date-time>
                        <span class="hyper" matSuffix [owlDateTimeTrigger]="locTime">
                            <mat-icon class="no-inline">calendar_today</mat-icon>
                        </span>

                    </mat-form-field>
                </div>
            </div>

            <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px"
                fxLayoutGap.xs="0">

                <!-- OLD WAY OF DOING A DROPDOWN SELECTION -->
                <!-- <div fxFlex="20%" fxFlex.md="40%" fxFlex.lg="30%">
                    <mat-form-field class="fom-input-field">
                        <mat-select formControlName="fleetId" placeholder="Fleet" required tabindex="2"
                            [disabled]="!unit.unitId">
                            <mat-option *ngFor="let option of fleetOptions" [disabled]="option.isDisabled"
                                [value]="option.fleetId">{{option.fleetCode}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div> -->

                <div fxFlex="20%" fxFlex.md="40%" fxFlex.lg="30%">
                    <mat-form-field class="fom-input-field">
                        <input type="text" placeholder="Fleet" matInput required tabindex="2" formControlName="fleetId"
                            [formControl]="fleetFilterControl" [matAutocomplete]="fleetAutoComplete">
                        <!-- <mat-autocomplete autoActiveFirstOption #fleetAutoComplete="matAutocomplete" [displayWith]="displayFn"> -->
                        <mat-autocomplete autoActiveFirstOption #fleetAutoComplete="matAutocomplete">
                            <mat-option *ngFor="let option of fleetFilteredOptions | async" [value]="option.fleetCode"
                                [disabled]="!unit.unitId">
                                {{option.fleetCode}}
                            </mat-option>

                        </mat-autocomplete>
                    </mat-form-field>
                </div>

                <div fxFlex="20%" fxFlex.md="40%" fxFlex.lg="30%">
                    <!-- <mat-form-field class="fom-input-field">
                        <mat-select [formControlName]="'activityCategoryId'" placeholder="Activity Category" required
                            tabindex="17" #activityField (selectionChange)="selectActivity(activityField.value)"
                            [disabled]="!unit.unitId">
                            <mat-option *ngFor="let option of activityCategoryOptions" [disabled]="option.isDisabled"
                                [value]="option.activityId">{{option.description}}</mat-option>
                        </mat-select>
                    </mat-form-field> -->

                    <mat-form-field class="fom-input-field" >
                        <input type="text"
                                placeholder="Activity Category"
                                required
                                tabindex="20"
                                matInput
                                formControlName="activityCategoryId"
                                [matAutocomplete]="activityAutoComplete"
                                (ngModelChange)="handleUWDateChange($event)">
                        <mat-autocomplete autoActiveFirstOption #activityAutoComplete="matAutocomplete">
                            <mat-option *ngFor="let option of activityFilteredOptions | async"
                                [value]="option.description" [disabled]="!unit.unitId" #matOption
                                (click)="selectActivity(option.activityId)">
                                {{option.description}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>

            <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px"
                fxLayoutGap.xs="0">

                <div fxFlex="20%" fxFlex.md="40%" fxFlex.lg="30%">
                    <mat-form-field class="fom-input-field" [floatLabel]="editForm?.controls?.dtgImaged?.value?.length">
                        <input matInput [owlDateTime]="dtgImaged" tabindex="3" formControlName="dtgImaged"
                            autocomplete="off" [readonly]="!unit.unitId"
                            (dateTimeChange)="dateTimeChange($event, 'dtgImaged')"
                            placeholder="Last Imaged DTG (d M Y hm)"
                            [max]="this.allowFutureDates ? undefined : maxDate">
                        <owl-date-time [disabled]="!unit.unitId" #dtgImaged></owl-date-time>
                        <span class="hyper" matSuffix [owlDateTimeTrigger]="dtgImaged">
                            <mat-icon class="no-inline">calendar_today</mat-icon>
                        </span>
                    </mat-form-field>
                </div>

                <div fxFlex="20%" fxFlex.md="40%" fxFlex.lg="30%">
                    <mat-form-field class="fom-input-field">
                        <input matInput tabindex="21" formControlName="activity" placeholder="Activity"
                            [readonly]="activityReadOnly"
                            (ngModelChange)="handleUWDateChange($event)">
                    </mat-form-field>
                </div>
            </div>

            <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px"
                fxLayoutGap.xs="0">

                <div fxFlex="20%" fxFlex.md="40%" fxFlex.lg="30%">
                    <mat-form-field class="fom-input-field" [floatLabel]="editForm?.controls?.mfiTs?.value?.length">
                        <input matInput [owlDateTime]="mfiTs" tabindex="4" formControlName="mfiTs" autocomplete="off"
                            [readonly]="!unit.unitId" (dateTimeChange)="dateTimeChange($event, 'mfiTs')"
                            placeholder="MFI DTG (d M Y hm)" [max]="this.allowFutureDates ? undefined : maxDate">
                        <owl-date-time [disabled]="!unit.unitId" #mfiTs></owl-date-time>
                        <span class="hyper" matSuffix [owlDateTimeTrigger]="mfiTs">
                            <mat-icon class="no-inline">calendar_today</mat-icon>
                        </span>
                    </mat-form-field>
                </div>

                <!--new days_out_ts for calc of days-->
                <div fxFlex="20%" fxFlex.md="40%" fxFlex.lg="30%">
                    <mat-form-field class="fom-input-field" [floatLabel]="editForm?.controls?.daysOutTs?.value?.length">
                        <input matInput [owlDateTime]="daysOutTs" tabindex="22" required formControlName="daysOutTs"
                            autocomplete="off" [readonly]="!unit.unitId"
                            (dateTimeChange)="dateTimeChange($event, 'daysOutTs')" placeholder="Underway DTG (d M Y hm)"
                            [max]="this.allowFutureDates ? undefined : maxDate"
                            (ngModelChange)="handleUWDateChange($event)">
                        <owl-date-time [disabled]="!unit.unitId" #daysOutTs></owl-date-time>
                        <span class="hyper" matSuffix [owlDateTimeTrigger]="daysOutTs">
                            <mat-icon class="no-inline">calendar_today</mat-icon>
                        </span>
                    </mat-form-field>
                    <mat-icon [matBadge]="daysOutCount" matBadgeColor="warn"
                        [ngStyle]="{'color':'blue'}" *ngIf="showPriorTrackUWFlag">directions_boat</mat-icon>
                </div>

                <!--end new days_out_Ts-->

            </div>
            <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px"
                fxLayoutGap.xs="0">

                <div fxFlex="20%" fxFlex.md="40%" fxFlex.lg="30%">
                    <!-- <mat-form-field class="fom-input-field">
                        <mat-select formControlName="portFacility" placeholder="Port Facility" required tabindex="5"
                            [disabled]="!unit.unitId || !this.inPortActivityFlag || !this.mfiInd" #portField
                            (selectionChange)="selectPort(portField.value)">
                            <mat-option *ngFor="let option of portOptions"
                                [value]="option.portId">{{option.name}}</mat-option>
                        </mat-select>
                        <mat-hint>DPT</mat-hint>
                    </mat-form-field> -->

                    <mat-form-field class="fom-input-field">
                        <input type="text" placeholder="Port Facility" matInput required tabindex="5"
                            formControlName="portFacility" [matAutocomplete]="portAutoComplete">
                        <mat-autocomplete autoActiveFirstOption #portAutoComplete="matAutocomplete"
                            (optionSelected)="selectPort($event)">

                            <mat-option *ngFor="let option of portFilteredOptions | async" [value]="option.name"
                                [disabled]="!unit.unitId">
                                {{option.name}}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="unit && unit.country && opAreaOptions?.length == 0">No U/W Areas associated
                            with Unit's Country: {{unit?.country?.name}}</mat-error>
                    </mat-form-field>
                </div>

                <div fxFlex="20%" fxFlex.md="40%" fxFlex.lg="30%">
                    <!-- <mat-form-field class="fom-input-field">
                        <mat-select formControlName="opAreaId" placeholder="U/W Area" required tabindex="19"
                            [disabled]="!unit.unitId">
                            <mat-option *ngFor="let option of opAreaOptions" [disabled]="option.isDisabled"
                                [value]="option.opAreaId">{{option.areaRemarks()}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="unit && unit.country && opAreaOptions?.length == 0">No U/W Areas associated
                            with Unit's Country: {{unit?.country?.name}}</mat-error>
                    </mat-form-field> -->

                    <mat-form-field class="fom-input-field">
                        <input type="text"
                                placeholder="U/W Area"
                                matInput
                                required 
                                tabindex="23"
                                formControlName="opAreaId"
                                [matAutocomplete]="areaAutoComplete">
                        <mat-autocomplete autoActiveFirstOption #areaAutoComplete="matAutocomplete">
                            <mat-option *ngFor="let option of areaFilteredOptions | async"
                                [value]="option.areaRemarks()" [disabled]="!unit.unitId">
                                {{option.areaRemarks()}}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="unit && unit.country && opAreaOptions?.length == 0">No U/W Areas associated
                            with Unit's Country: {{unit?.country?.name}}</mat-error>
                    </mat-form-field>
                </div>

            </div>
            <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px"
                fxLayoutGap.xs="0">

                <div fxFlex="20%" fxFlex.md="40%" fxFlex.lg="30%">
                    <mat-form-field class="fom-input-field">
                        <input matInput required formControlName="latitude" placeholder="Latitude" tabindex="6"
                            [readonly]="!unit.unitId" (change)="handleCoordinatesChange()"
                            oninput="this.value = this.value.trim().toUpperCase()">
                        <mat-hint>DMS or Decimal</mat-hint>
                        <mat-error *ngIf="editForm?.controls?.latitude?.hasError('format')">bad format</mat-error>
                        <mat-error *ngIf="editForm?.controls?.latitude?.hasError('length')">improper length</mat-error>
                        <mat-error *ngIf="editForm?.controls?.latitude?.hasError('range')">out of range</mat-error>
                        <mat-error *ngIf="editForm?.controls?.latitude?.hasError('required')">required</mat-error>
                        <!----mat-hint>1234N or 123456N</mat-hint-->
                    </mat-form-field>
                </div>

                <div fxFlex="20%" fxFlex.md="40%" fxFlex.lg="30%">
                    <span> <mat-checkbox formControlName="mfiInd" #mfiInd placeholder="MFI?" [disabled]="!unit.unitId"
                        (change)="toggleMfi($event)" style="vertical-align: bottom; margin-top: 20px;" tabindex="23">
                        MFI?
                    </mat-checkbox>
                    &nbsp;
                    <mat-checkbox formControlName="patCandInd" placeholder="Patrol Cand." [disabled]="!unit.unitId"
                    style="vertical-align: bottom; margin-top: 20px;" tabindex="24">
                    Patrol Cand.
                </mat-checkbox>
                </span>
                </div>

                <!---- <div fxFlex="20%" fxFlex.md="40%" fxFlex.lg="30%">
                    <mat-checkbox formControlName="patCandInd" placeholder="Patrol Cand." [disabled]="!unit.unitId"
                        style="vertical-align: bottom; margin-top: 20px;" tabindex="21">
                        Patrol Cand.
                    </mat-checkbox>
                </div>-->
            </div>

            <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px"
                fxLayoutGap.xs="0">

                <div fxFlex="20%" fxFlex.md="40%" fxFlex.lg="30%">
                    <mat-form-field class="fom-input-field">
                        <input matInput formControlName="longitude" placeholder="Longitude" required tabindex="7"
                            [readonly]="!unit.unitId" (change)="handleCoordinatesChange()"
                            oninput="this.value = this.value.trim().toUpperCase()">
                        <mat-hint>DMS or Decimal</mat-hint>
                        <mat-error *ngIf="editForm?.controls?.longitude?.hasError('format')">bad format</mat-error>
                        <mat-error *ngIf="editForm?.controls?.longitude?.hasError('length')">improper length</mat-error>
                        <mat-error *ngIf="editForm?.controls?.longitude?.hasError('range')">out of range</mat-error>
                        <mat-error *ngIf="editForm?.controls?.longitude?.hasError('required')">required</mat-error>
                        <!----mat-hint12345E or 1234567E</mat-hint-->
                    </mat-form-field>
                </div>

                <div fxFlex="20%" fxFlex.md="40%" fxFlex.lg="30%">
                    <mat-form-field class="fom-input-field">
                        <input matInput formControlName="papaCase" placeholder="Case #" tabindex="23"
                            [readonly]="!unit.unitId">
                    </mat-form-field>
                </div>
            </div>

            <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px"
                fxLayoutGap.xs="0">

                <div fxFlex="20%" fxFlex.md="40%" fxFlex.lg="30%">
                    <mat-form-field class="fom-input-field">
                        <input matInput formControlName="lastLoc" placeholder="Last Location" tabindex="8" required
                            [readonly]="!unit.unitId">
                    </mat-form-field>
                </div>

                <div fxFlex="20%" fxFlex.md="40%" fxFlex.lg="30%">
                    <mat-form-field class="fom-input-field">
                        <input matInput formControlName="newUnit" placeholder="New Assessment" [readonly]="!unit.unitId"
                            [matAutocomplete]="reassessAutocomplete"
                            [required]='editForm?.controls?.reassessedInd?.value' tabindex="24"
                            matTooltip="{{editForm.controls?.newUnit?.value | unitNamePipe}}">
                    </mat-form-field>
                    <mat-autocomplete #reassessAutocomplete="matAutocomplete" [displayWith]="displayUnit"
                        (optionSelected)="handleAssessmentChange($event)" (optionsScroll)="onAssessmentOptionsScroll()">
                        <ng-container>
                            <mat-option *ngIf="editForm.controls.newUnit.value" value="">None</mat-option>
                            <mat-option *ngFor="let option of filteredAssessments$ | async"
                                [disabled]="unit.unitId == option.unitId" [value]="option">
                                <span matTooltip="{{option.country?.name}}">
                                    <img style="vertical-align:middle;" [src]="utilities.getFlag(option)"
                                        height="20" />&nbsp;
                                </span>
                                <span matTooltip="{{option.fullName()}}">{{option.fullName()}}</span>
                            </mat-option>
                        </ng-container>
                        <mat-option *ngIf="isLoading" class="is-loading">
                            <mat-spinner diameter="30"></mat-spinner>
                        </mat-option>
                    </mat-autocomplete>

                </div>
            </div>

            <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px"
                fxLayoutGap.xs="0">

                <div fxFlex="20%" fxFlex.md="40%" fxFlex.lg="30%">
                    <!-- <mat-form-field class="fom-input-field">
                        <mat-select [formControlName]="'lastLocSrc'" placeholder="Source" required tabindex="9"
                            [disabled]="!unit.unitId">
                            <mat-option *ngFor="let option of sourceOptions" [disabled]="option.isDisabled"
                                [value]="option.sourceId">{{option.sourceName}}</mat-option>
                        </mat-select>
                    </mat-form-field> -->

                    <mat-form-field class="fom-input-field">
                        <input type="text" placeholder="Source" matInput required tabindex="9"
                            formControlName="lastLocSrc" [matAutocomplete]="sourceAutoComplete">
                        <mat-autocomplete autoActiveFirstOption #sourceAutoComplete="matAutocomplete">
                            <mat-option *ngFor="let option of sourceFilteredOptions | async" [value]="option.sourceName"
                                [disabled]="!unit.unitId">
                                {{option.sourceName}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

                <div fxFlex="20%" fxFlex.md="40%" fxFlex.lg="30%">
                    <mat-checkbox formControlName="reassessedInd" (change)='clearNewUnitOnUncheck($event)'
                        [disabled]="!unit.unitId" placeholder="Reassessed" tabindex="25">
                        Reassessed
                    </mat-checkbox>
                </div>
            </div>

            <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px"
                fxLayoutGap.xs="0">

                <div fxFlex="20%" fxFlex.md="40%" fxFlex.lg="30%">
                    <mat-form-field class="fom-input-field">
                        <input matInput formControlName="srcOrig" placeholder="Originator" tabindex="10" required
                            [readonly]="!unit.unitId">
                    </mat-form-field>
                </div>
                <div fxFlex="20%" fxFlex.md="40%" fxFlex.lg="30%">
                    <mat-checkbox formControlName="tunnelInd" placeholder="In Tunnel" tabindex="26"
                        [disabled]="!unit.unitId">
                        In Tunnel
                    </mat-checkbox>
                </div>
            </div>

            <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px"
                fxLayoutGap.xs="0">
                  <!--New Confidence Field-->
                  <div fxFlex="20%" fxFlex.md="40%" fxFlex.lg="30%">
                    <mat-form-field class="fom-input-field">
                        <input type="text"
                                placeholder="Confidence"
                                matInput 
                                tabindex="11"
                                formControlName="confidence"
                                [matAutocomplete]="confidenceAutoComplete">
                        <mat-autocomplete autoActiveFirstOption #confidenceAutoComplete="matAutocomplete">
                            <mat-option *ngFor="let option of confidenceCodeFilteredOptions | async" [value]="option.confidenceCode" [disabled]="!unit.unitId">
                            {{option.confidenceCode}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <!---->
               
                <div fxFlex="20%" fxFlex.md="40%" fxFlex.lg="30%">
                    <mat-checkbox formControlName="nobRemoveInd" placeholder="Remove from NOB View" tabindex="27"
                        [disabled]="!unit.unitId">
                        Remove from NOB View
                    </mat-checkbox>
                </div>
            </div>

            <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px"
                fxLayoutGap.xs="0">

                <div fxFlex="20%" fxFlex.md="40%" fxFlex.lg="30%">
                    <!-- <mat-form-field class="fom-input-field">
                        <mat-select formControlName="el_not_id" #elnotField
                            (selectionChange)="selectElnot(elnotField.value)" placeholder="ELNOT" tabindex="11"
                            [disabled]="!unit.unitId">
                            <mat-option *ngFor="let option of elnotOptions" [disabled]="option.isDisabled"
                                [value]="option.el_not_id">{{option.el_not_code}}</mat-option>
                        </mat-select>
                    </mat-form-field> -->

                    <mat-form-field class="fom-input-field">
                        <input type="text"
                                placeholder="ELNOT"
                                matInput 
                                tabindex="12"
                                formControlName="el_not_id"
                                [matAutocomplete]="elnotAutoComplete">
                        <mat-autocomplete autoActiveFirstOption #elnotAutoComplete="matAutocomplete" (optionSelected)="selectElnot($event)">
                            <mat-option *ngFor="let option of elnotFilteredOptions | async" [value]="option.el_not_code" [disabled]="!unit.unitId">
                            {{option.el_not_code}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
              
                <div fxFlex="20%" fxFlex.md="40%" fxFlex.lg="30%">
                    <mat-form-field class="fom-input-field">
                        <mat-select formControlName="freq_type_id" #freqTypeField
                            (selectionChange)="selectFreqType(freqTypeField.value)" placeholder="Frequency Type"
                            tabindex="28" [disabled]="elnotReadOnly">
                            <mat-option *ngFor="let option of freqTypeOptions" [disabled]="option.isDisabled"
                                [value]="option.freq_type_id">{{option.freq_type_description}}</mat-option>
                        </mat-select>
                        <mat-hint>Hz, KHz, MHz, or GHz</mat-hint>
                    </mat-form-field>
                </div>
            </div>

            <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px"
                fxLayoutGap.xs="0">
                <div fxFlex="20%" fxFlex.md="40%" fxFlex.lg="30%">
                    <mat-form-field class="fom-input-field">
                        <input matInput formControlName="band" placeholder="Bandwidth" tabindex="13"
                            [readonly]="elnotReadOnly">
                        <mat-hint>Number or Decimal</mat-hint>
                        <mat-error *ngIf="editForm.get('band').hasError('pattern')">Please enter a number or decimal
                            value</mat-error>
                    </mat-form-field>
                </div>
               
                <div fxFlex="20%" fxFlex.md="40%" fxFlex.lg="30%">
                    <mat-form-field class="fom-input-field">
                        <mat-select formControlName="elint_function_id" #funcField
                            (selectionChange)="selectFunc(funcField.value)" placeholder="Function" tabindex="29"
                            [disabled]="elnotReadOnly">
                            <mat-option *ngFor="let option of funcTypeOptions" [disabled]="option.isDisabled"
                                [value]="option.elint_function_id">{{option.elint_function_code}}
                                ({{option.elint_function_name}})</mat-option>
                        </mat-select>
                        <mat-hint>Select the function type.</mat-hint>
                    </mat-form-field>
                </div>
            </div>

            <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px"
                fxLayoutGap.xs="0">

                <div fxFlex="20%" fxFlex.md="40%" fxFlex.lg="30%">
                    <mat-form-field class="fom-input-field">
                        <input matInput formControlName="provider" placeholder="Provider" tabindex="14"
                            [readonly]="elnotReadOnly">
                    </mat-form-field>
                </div>
              
                <div fxFlex="20%" fxFlex.md="40%" fxFlex.lg="30%">
                    <mat-form-field class="fom-input-field">
                        <input matInput formControlName="pri" placeholder="PRI" tabindex="30"
                            [readonly]="elnotReadOnly">
                        <mat-hint>Number or Decimal in Microseconds</mat-hint>
                        <mat-error *ngIf="editForm.get('pri').hasError('pattern')">Please enter a number or decimal
                            value</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px"
                fxLayoutGap.xs="0">
                <div fxFlex="20%" fxFlex.md="40%" fxFlex.lg="30%">
                    <mat-form-field class="fom-input-field">
                        <input matInput formControlName="pul_dur" placeholder="Pulse Width" tabindex="15"
                            [readonly]="elnotReadOnly">
                        <mat-hint>Number or Decimal</mat-hint>
                        <mat-error *ngIf="editForm.get('pul_dur').hasError('pattern')">Please enter a number or decimal
                            value</mat-error>
                    </mat-form-field>
                </div>
                
                <div fxFlex="20%" fxFlex.md="40%" fxFlex.lg="30%">
                    <mat-form-field class="fom-input-field">
                        <input matInput formControlName="prf" placeholder="PRF" tabindex="31"
                            [readonly]="elnotReadOnly">
                        <mat-hint>Number or Decimal</mat-hint>
                        <mat-error *ngIf="editForm.get('prf').hasError('pattern')">Please enter a number or decimal
                            value</mat-error>
                    </mat-form-field>
                </div>
             
            </div>

            <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px"
                fxLayoutGap.xs="0">

                <div fxFlex="20%" fxFlex.md="40%" fxFlex.lg="30%">
                    <mat-form-field class="fom-input-field">
                        <input matInput formControlName="scan_rate" placeholder="Scan Rate" tabindex="16"
                            [readonly]="elnotReadOnly">
                        <mat-hint>Number or Decimal</mat-hint>
                        <mat-error *ngIf="editForm.get('scan_rate').hasError('pattern')">Please enter a number or
                            decimal value</mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex="20%" fxFlex.md="40%" fxFlex.lg="30%">
                    <mat-form-field class="fom-input-field">
                        <input matInput formControlName="scan_measure" placeholder="Scan Measure" tabindex="32"
                            [readonly]="elnotReadOnly">
                    </mat-form-field>
                </div>
              
            </div>

            <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px"
            fxLayoutGap.xs="0">

            <div fxFlex="20%" fxFlex.md="40%" fxFlex.lg="30%">
                <!-- <mat-form-field class="fom-input-field">
                    <mat-select formControlName="scan_type_id" #scanTypeField
                        (selectionChange)="selectScanType(scanTypeField.value)" placeholder="Scan Type"
                        tabindex="16" [disabled]="elnotReadOnly">
                        <mat-option *ngFor="let option of scanTypeOptions" [disabled]="option.isDisabled"
                            [value]="option.scan_type_id">{{option.scan_type_code}}
                            ({{option.scan_type_description}})</mat-option>
                    </mat-select>
                </mat-form-field> -->

                <mat-form-field class="fom-input-field">
                    <input type="text"
                            placeholder="Scan Type"
                            matInput 
                            tabindex="18"
                            formControlName="scan_type_id"
                            [matAutocomplete]="scanAutoComplete">
                    <mat-autocomplete autoActiveFirstOption #scanAutoComplete="matAutocomplete" (optionSelected)="selectScanType($event)">
                        <mat-option *ngFor="let option of scanFilteredOptions | async" [value]="option.scan_type_id" [disabled]="elnotReadOnly">
                            {{option.scan_type_code}}
                            ({{option.scan_type_description}})
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div fxFlex="20%" fxFlex.md="40%" fxFlex.lg="30%">
                <mat-form-field class="fom-input-field" [floatLabel]="editForm?.controls?.toi_ts?.value?.length">
                    <input matInput [owlDateTime]="toi_ts" tabindex="33" formControlName="toi_ts" autocomplete="off"
                        [readonly]="elnotReadOnly" (dateTimeChange)="dateTimeChange($event, 'toi_ts')"
                        placeholder="TOI (d M Y hm)" [max]="this.allowFutureDates ? undefined : maxDate">
                    <owl-date-time [disabled]="elnotReadOnly" #toi_ts></owl-date-time>
                    <span class="hyper" matSuffix [owlDateTimeTrigger]="toi_ts">
                        <mat-icon class="no-inline">calendar_today</mat-icon>
                    </span>
                </mat-form-field>
            </div>
        </div>

            <!-- Hidden inpputs -->
            <input type="hidden" formControlName="isRemovedFromNob">
            <input type="hidden" formControlName="ltn">
            <input type="hidden" formControlName="uid">
            <input type="hidden" formControlName="uwCode">
        </mat-card-content>

        <div class="container" fxLayout="row" fxLayoutAlign="center center" *ngIf="fromTrackMaint">
            <mat-form-field class="fom-input-field">
                <mat-label>Change Reason</mat-label>
                <textarea formControlName="rationale" matInput required tabindex="17"></textarea>
            </mat-form-field>
        </div>

        <div *ngIf="isDelete" fxLayout="row" fxLayoutAlign="center center">
            <div class="mat-color-warn warning">
                NOTE: This action will only delete this location's information from the Unit's history, the Unit itself
                will not be deleted.
            </div>
        </div>
        <!-- <div *ngIf="isDelete && fromParser" fxLayout="row" fxLayoutAlign="center center">
            <div class="mat-color-warn warning">
                NOTE: You cannot delete this track because this track was accepted from the parser queue.
            </div>
        </div> -->

        <mat-card-actions>
            <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px"
                fxLayoutGap.xs="0">

                <div fxFlex="40%" fxFlex.md="40%" fxFlex.lg="30%">
                    <button mat-raised-button color="primary" (click)="handleSubmit()"
                        [disabled]="!editForm.valid || !editForm.controls?.unit?.value?.unitId" *ngIf="!isDelete">
                        Submit
                    </button>&nbsp;
                    <button mat-raised-button color="warn" (click)="handleDeletefromTrackMaint()"
                        [disabled]="editForm.controls?.rationale?.invalid || !editForm.controls?.unit?.value?.unitId"
                        *ngIf="isDelete">
                        Delete
                    </button>&nbsp;
                    <button mat-raised-button (click)="handleCancel()" type="button" *ngIf="!fromTrackMaint">
                        Cancel
                    </button>
                    <button mat-raised-button (click)="cancel()" type="button" *ngIf="fromTrackMaint">
                        Cancel
                    </button>
                </div>
                <div fxFlex="20%" fxFlex.md="40%" fxFlex.lg="30%"></div>
            </div>
        </mat-card-actions>
    </form>
</mat-card>