import { Component, OnInit } from '@angular/core';
import { ServicesService } from '@services/data-services/services.service';
import { MatTableDataSource } from '@angular/material/table';
import { GridBaseComponent } from '@fom-module/base-classes/grid-base.component';
import { take } from 'rxjs/operators';
import { AppInitService } from '../../../config/init.service';

@Component({
  selector: 'app-service-dashboard',
  templateUrl: './service-dashboard.component.html',
  styleUrls: ['./service-dashboard.component.css']
})
export class ServiceDashboardComponent extends GridBaseComponent implements OnInit {

  dataSource: any;
  displayedColumns = ['name', 'status', 'logFiles', 'onOff', 'start', 'requests', 'errors', 'events'];

  active: any = { status: {} };

  // objects to describe the services, feed the UI, handle the buttons
  services: any[] = [
    // the api log files are held locally on the API server
    // kindofa hack, but just tell the api not to go out to a microservice. get them locally
    {name: 'api', display: 'Rest API', type: 'rest', status: {currentStatus: 'up'}, hideSuspend: true, isMicroservice: false},
    {name: 'auth', display: 'AUTH', type: 'rest', isMicroservice: true, hideSuspend: true},
    {name: 'kml', display: 'KML', type: 'rest', isMicroservice: true},
    {name: 'midb', display: 'MIDB', type: 'rest', isMicroservice: true},
    {name: 'email', display: 'EMAIL', type: 'rest', isMicroservice: true},
    {name: 'excel', display: 'NOB Excel', type: 'rest', isMicroservice: true},
    {name: 'pptx', display: 'Powerpoint', type: 'rest', isMicroservice: true},
    {name: 'galeSend', display: 'OTH-G Send', type: 'rest', isMicroservice: true},
    {name: 'rmsend', display: 'RM Send', type: 'rest', isMicroservice: true},
    {name: 'galeListen', display: 'OTH-G Listen', type: 'tcp', isMicroservice: true, status: {}}
  ];
  constructor(private servicesService: ServicesService,
    public initService: AppInitService) {
    super(null, null, null);
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource<object>(this.services);

    this.active = this.services[0]
    this.services.forEach(element => {
      this.servicesService.getStatus(element.name, element.type).pipe(take(1)).subscribe(response => {
        console.log(response)
        element.status = response
        // ok we got a hit, now try to get the log file list
        this.servicesService.getLogFiles(element.name, element.type).pipe(take(1)).subscribe(response => {
          response.sort(function(a,b) {
            return Date.parse(b.lastModify) - Date.parse(a.lastModify)
          })
          element.logFiles = response
        }, error => { element.logFiles = [] })
      }, error => {
        // on error load unavailable and do not get log files ('cause you can't)
        element.status = { currentStatus: 'unreachable'}
        element.logFiles = []
      })
    })
  }
  changeStatus(service) {
    if (service.status.currentStatus == 'up') {
      this.servicesService.suspend(service.name, service.type).pipe(take(1)).subscribe(response => {
        // on success the status is returned
        service.status = response
      })
    }
    else {
      this.servicesService.resume(service.name, service.type).pipe(take(1)).subscribe(response => {
        // on success the status is returned
        service.status = response
    })
      }
  }
  download(service, event) {
    this.servicesService.getLogFile(service.name, service.type, event.value.name).pipe(take(1)).subscribe(response => {
      // ok, we have a file, what to do?
      const fileName = event.value.name;
      const file = new Blob([response]);
      if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(file, fileName);
      } else {
              const a = document.createElement('a');
              a.href = window.URL.createObjectURL(file); // xhr.response is a blob
              a.style.display = 'none';
              a.download = fileName;
              document.body.appendChild(a);
              a.click();
      }
    })
  }
}
