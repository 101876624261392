<mat-toolbar class="navbar">
    <mat-toolbar-row class="first-row">
        <div class="left-buttons">
            <img id="nav-bar-logo" (click)="handleHomeClick()" class="hyper" title="FDT-M version {{version}}"
                width="35" height="35" src="assets/images/FDT-M.png">
            <div>
                <a *ngIf="showMenu('units')" mat-button [routerLink]="'/units/unit-update'" [ngClass]="{'mat-current mat-raised-button' : mainMenu == 'units'}"
                    matTooltip="Unit Update, Parser Queue, Create COI">Units</a>
                <a *ngIf="showMenu('products')" mat-button [routerLink]="'/products/current-nob'" [ngClass]="{'mat-current mat-raised-button' : mainMenu == 'products'}"
                    matTooltip="Current NOB, Locator, various reports">Products</a>
                <a *ngIf="showMenu('analysis')" mat-button [routerLink]="'/analysis/unit-search'" [ngClass]="{'mat-current mat-raised-button' : mainMenu == 'analysis'}"
                    matTooltip="Unit Search, KML, various Analysis tools">Analysis</a>
                <a *ngIf="showMenu('admin')" mat-button [routerLink]="'/admin/tasks'" [ngClass]="{'mat-current mat-raised-button' : mainMenu == 'admin'}"
                    matTooltip="FDT-M Administration screens">Admin</a>
            </div>
        </div>
        <div class="right-buttons">
            <div class="navbar-text" *ngIf="globalStore?.storeData?.currentUser">
              <button mat-button [matMenuTriggerFor]="belowMenu" matTooltip="User settings and preferences">
                <mat-icon aria-hidden="false" aria-label="User Settings">settings</mat-icon>
                     {{globalStore?.storeData?.currentUser?.username}}
                     ({{globalStore?.storeData?.currentUser?.command?.name}})
              </button>
                <mat-menu #belowMenu="matMenu" yPosition="below">
                  <button matTooltip="Toggle Light / Dark theme" (click)="onSetTheme()" mat-menu-item>Light / Dark</button>
                  <button (click)="userSettings()" matTooltip="View / Change User settings" mat-menu-item>My Settings</button>
                  <a *ngIf="authServiceURL && authServiceURL.length > 0 && globalStore?.storeData?.isLoggedIn && globalStore?.storeData?.currentUser?.pki_ind !== false" matTooltip="Sync Certificate"
                    mat-menu-item id="sync-button" href="{{authServiceURL}}">Sync Certificate</a>
                  <button matTooltip="Request changes to your account and permissions" (click)="requestAccount()" mat-menu-item>Request Account Changes</button>
                </mat-menu>
            </div>
            <mat-select class="mat-color-warn jfmcc-select" [(ngModel)]="currentJFMCC" (selectionChange)="jfmccChange($event)" *ngIf="JFMCCs?.length > 1">
                <mat-select-trigger *ngIf="currentJFMCC">
                    <span>{{currentJFMCC.name}}</span>
                </mat-select-trigger>
                <mat-option [value]="jfmcc" *ngFor="let jfmcc of JFMCCs"><span>{{jfmcc.name}}</span></mat-option>
            </mat-select>
            <button matTooltip="Request FDT-M account" *ngIf="!globalStore?.storeData?.isLoggedIn" mat-button (click)="requestAccount()">Request Account</button>
            <a mat-button [routerLink]="'/about'" [ngClass]="{'mat-current mat-raised-button' : mainMenu == 'about'}" matTooltip="Open Help / About screen">Help</a>
            <button *ngIf="globalStore?.storeData?.isLoggedIn" mat-button id="login-button" (click)="handleLogoutClick()">Logout</button>
            <button matTooltip="Login with Username and Password" *ngIf="!globalStore?.storeData?.isLoggedIn" mat-button id="login-button" (click)="handleLoginClick()">Login</button>
        </div>
    </mat-toolbar-row>
    <mat-toolbar-row id="dividing-line">
        <hr/>
    </mat-toolbar-row>
    <mat-toolbar-row *ngIf="mainMenu == 'units'" class="second-row">
        <div>
            <a mat-button [ngClass]="{'mat-current mat-raised-button' : secondMenu == 'unit-update'}"
                matTooltip="Add Unit Location information" [routerLink]="'/units/unit-update'">Unit Update</a>
            <a mat-button [ngClass]="{'mat-current mat-raised-button' : secondMenu == 'parser-queue'}"
                matTooltip="Review Unit Location information for possible import" [routerLink]="'/units/parser-queue'">Parser Queue</a>
            <a *ngIf="user?.isSystemAdmin()" mat-button [ngClass]="{'mat-current mat-raised-button' : secondMenu == 'parser-queue-b'}"
                matTooltip="Review Unit Location information for possible import" [routerLink]="'/units/parser-queue-b'">Parser Queue B</a>
            <a mat-button [ngClass]="{'mat-current mat-raised-button' : secondMenu == 'coi'}"
                matTooltip="Create a new Contact of Interest" [routerLink]="'/units/coi'">COI</a>
        </div>
    </mat-toolbar-row>
    <mat-toolbar-row *ngIf="mainMenu == 'products'" class="second-row">
        <div>
            <a mat-button [ngClass]="{'mat-current mat-flat-button' : secondMenu == 'current-nob'}"
                matTooltip="Display the Current NOB and download KML" [routerLink]="'/products/current-nob'">Current NOB</a>
            <a mat-button [ngClass]="{'mat-current mat-raised-button' : secondMenu == 'locator'}"
                matTooltip="Display Locator table" [routerLink]="'/products/locator'">Locator</a>
            <a mat-button [ngClass]="{'mat-current mat-raised-button' : secondMenu == 'current-nob-excel'}"
                matTooltip="Download the Current NOB in Excel format" [routerLink]="'/products/current-nob-excel'">Excel</a>
            <a mat-button [ngClass]="{'mat-current mat-flat-button' : secondMenu == 'powerpoint'}"
                matTooltip="Create one or more PowerPoint slides for geographic areas" [routerLink]="'/products/powerpoint'">PowerPoint</a>
            <a mat-button [ngClass]="{'mat-current mat-flat-button' : secondMenu == 'record-message'}"
                matTooltip="Download the Current NOB in RMG format" [routerLink]="'/products/record-message'">RMG</a>
            <a mat-button [ngClass]="{'mat-current mat-raised-button' : secondMenu == 'rss'}"
                matTooltip="Open an RSS feed for recent Unit Updates" [routerLink]="'/products/rss'">RSS</a>
            <a mat-button *ngIf="pacmisTabToggle" [ngClass]="{'mat-current mat-raised-button' : secondMenu == 'pacmis'}"
                matTooltip="Display PACMIS Report" [routerLink]="'/products/pacmis'">PACMIS</a>
            <a *ngIf="dashboard" mat-button matTooltip="Open Analytic Dashboard in a new Window/Tab" (click)="openDashboard()">Analytic Dashboard</a>
            <a mat-button [ngClass]="{'mat-current mat-raised-button' : secondMenu == 'ocp'}"
                matTooltip="Access the Open Collaboration Portal" [routerLink]="'/products/ocp'">OCP</a>
        </div>
    </mat-toolbar-row>
    <mat-toolbar-row *ngIf="mainMenu == 'analysis'" class="second-row">
        <div>
            <a mat-button [ngClass]="{'mat-current mat-raised-button' : secondMenu == 'unit-search'}"
                matTooltip="Search for Unit(s) by times, Activity, Country. Export to Excel or KML" [routerLink]="'/analysis/unit-search'">Unit Search</a>
            <a mat-button [ngClass]="{'mat-current mat-raised-button' : secondMenu == 'kml'}"
                matTooltip="Download KML for Units within a timeframe" [routerLink]="'/analysis/kml'">Google Earth History</a>
            <a mat-button [ngClass]="{'mat-current mat-raised-button' : secondMenu == 'data-cards'}"
                matTooltip="Display Data Card for Unit" [routerLink]="'/analysis/data-cards'">Underway History</a>
            <a mat-button [ngClass]="{'mat-current mat-raised-button' : secondMenu == 'optempo'}"
                matTooltip="Display OPTEMPO Report" [routerLink]="'/analysis/optempo'">OPTEMPO</a>
            <a mat-button *ngIf="currentUserService.getCurrentUser()?.isUpdater()" [ngClass]="{'mat-current mat-raised-button' : secondMenu == 'blue-search'}"
                matTooltip="Search for Blue Unit Location(s)" [routerLink]="'/analysis/blue-search'">Blue</a>
                <a mat-button *ngIf="grafanaTabToggle && currentUserService.getCurrentUser()?.isUpdater()" [ngClass]="{'mat-current mat-raised-button' : secondMenu == 'grafana'}"
                matTooltip="Access the Grafana Dashboard" [routerLink]="'/analysis/grafana'">Grafana</a>
        </div>
    </mat-toolbar-row>
    <mat-toolbar-row *ngIf="mainMenu == 'admin'" class="second-row">
        <div>
            <a mat-button *ngIf="currentUserService.getCurrentUser()?.isSupervisor()" [ngClass]="{'mat-current mat-raised-button' : secondMenu == 'tasks'}"
                matTooltip="FDT-M Administration screens" [routerLink]="'/admin/tasks'">Admin Tasks</a>
            <a mat-button *ngIf="currentUserService.getCurrentUser()?.isAdmin()" [ngClass]="{'mat-current mat-raised-button' : secondMenu == 'classifymenu'}"
                matTooltip="FDT-M Administration screens" [routerLink]="'/admin/classifymenu'">Classifications</a>
            <a mat-button *ngIf="currentUserService.getCurrentUser()?.isSupervisor()" [ngClass]="{'mat-current mat-raised-button' : secondMenu == 'servicemenu'}"
                matTooltip="FDT-M Administration screens" [routerLink]="'/admin/servicemenu'">Services</a>
            <a mat-button *ngIf="currentUserService.getCurrentUser()?.isSupervisor()" [ngClass]="{'mat-current mat-raised-button' : secondMenu == 'appsystemadminmenu'}"
                matTooltip="FDT-M Administration screens" [routerLink]="'/admin/appsystemadminmenu'">App System Admin</a>
        </div>
    </mat-toolbar-row>
</mat-toolbar>
