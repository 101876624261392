import { JsonObject, JsonProperty, Any } from 'json2typescript';
import { DateConverter } from '../services/global/date-converter';
import { Fleet } from '@models/fleet.model';
import { Port } from '@models/port.model';
import { Country } from '@models/country.model';
import { Category } from '@models/category.model';
import { ShipType } from '@models/ship-type.model';
import { Threat } from '@models/threat.model';
import { Command } from '@models/command.model';
import { User } from '@models/user.model';
import { UnitOtherName } from './unit-other-name.model';
// import { Location } from '@models/location.model';

@JsonObject('Unit')
export class Unit {
  @JsonProperty('unit_id', String, true)
  unitId: string = undefined;
  @JsonProperty('fleet_id', String, true)
  fleetId: string = undefined;
  @JsonProperty('fleet', Fleet, true)
  fleet: Fleet = undefined;
  // @JsonProperty('location', Location, true)
  // location: Location = undefined;
  @JsonProperty('ship_type_id', String, true)
  shipTypeId: string = undefined;
  @JsonProperty('ship-type', ShipType, true)
  ship: ShipType = undefined;
  @JsonProperty('threat_id', String, true)
  threatId: string = undefined;
  @JsonProperty('threat', Threat, true)
  threat: Threat = undefined;
  @JsonProperty('class_description', String, true)
  class: string = undefined;
  @JsonProperty('type_description', String, true)
  type: string = undefined;
  @JsonProperty('country_id', String, true)
  countryId: string = undefined;
  @JsonProperty('country', Country, true)
  country: Country = undefined;
  @JsonProperty('pennant', String, true)
  pennant: string = undefined;
  @JsonProperty('port_id', String, true)
  portId: string = undefined;
  @JsonProperty('port', Port, true)
  port: Port = undefined;
  @JsonProperty('category_id', String, true)
  categoryId: string = undefined;
  @JsonProperty('category', Category, true)
  category: Category = undefined;
  @JsonProperty('sconum', String, true)
  sconum: string = undefined;
  @JsonProperty('midb_id', String, true)
  midb: string = undefined;
  @JsonProperty('active_ind', Boolean, true)
  isActive: boolean = undefined;
  @JsonProperty('underway_ind', Boolean, true)
  isUnderway: boolean = undefined;
  @JsonProperty('command_id', String, true)
  commandId: string = undefined;
  @JsonProperty('borrower_command_id', String, true)
  borrowerCommandId: string = undefined;
  @JsonProperty('command', Command, true)
  command: Command = undefined;
  @JsonProperty('created_by', String, true)
  createdBy: string = undefined;
  @JsonProperty('create_ts', DateConverter, true)
  dateCreated: Date = undefined;
  @JsonProperty('nob_report', Object, true)
  nobReport: Object = undefined;
  @JsonProperty('update_ts', DateConverter, true)
  updateTs: Date = undefined;
  @JsonProperty('creator', User, true)
  creator: User = undefined;
  @JsonProperty('updater', User, true)
  updater: User = undefined;
  @JsonProperty('borrower-command', Command, true)
  borrowerCommand: Command = undefined;
  @JsonProperty('otherNames', Object, true)
  otherNames: Object[] = undefined;

  static lookupName(): string {
    return 'units';
  }

  fullName(): string {
    return `${this.class.trim()} ${this.type.trim()} ${this.pennant.trim()}`;
  }
}
