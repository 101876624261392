import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from './api.config';

@Injectable()
export class PicklistTypeApiService {
    SERVER_URL;

    constructor(
        private http: HttpClient, apiConfig: APIConfig) {
        this.SERVER_URL = apiConfig.TARGET_URL;
    }

    public getAllPicklistTypes(): Observable<any> {
        return this.http.get(
            `${this.SERVER_URL}/picklist-types`);
    }

    public getPicklistTypeByPicklistTypeId(picklistTypeId: string): Observable<any> {
        return this.http.get(
            `${this.SERVER_URL}/picklist-types/${picklistTypeId}`);
    }

    public updatePicklistType(picklistType: any): Observable<any> {
        return this.http.put(
            `${this.SERVER_URL}/picklist-types/${picklistType.picklist_type_id}`, picklistType);
    }

    public createPicklistType(picklistType: any): Observable<any> {
        return this.http.post(
            `${this.SERVER_URL}/picklist-types`, picklistType);
    }

    public deletePicklistType(picklistType: any): Observable<any> {
        return this.http.delete(
            `${this.SERVER_URL}/picklist-types/${picklistType.picklist_type_id}`);
    }
}
