import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter } from '../services/global/date-converter';
import { Command } from '@models/command.model';

@JsonObject('Country')
export class Country {
  @JsonProperty('country_id', String, true)
  countryId: string = undefined;
  @JsonProperty('country_name', String, true)
  name: string = undefined;
  @JsonProperty('tri_graph', String, true)
  triGraph: string = undefined;
  @JsonProperty('legacy_id', Number, true)
  legacyId: number = undefined;
  @JsonProperty('command_id', String, true)
  commandId: string = undefined;
  @JsonProperty('command', Command, true)
  command: Command = undefined;
  @JsonProperty('created_by', String, true)
  createdBy: string = undefined;
  @JsonProperty('create_ts', DateConverter, true)
  dateCreated: Date = undefined;
  @JsonProperty('lookup_filter_id', String, true)
  lookupFilterId: string = undefined;
  @JsonProperty('sort_sequence', String, true)
  sortSequence: number = undefined;
  @JsonProperty('flag_image_path', String, true)
  flagImagePath: string = null;

  static lookupName(): string {
    return 'flags';
  }
}
