import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from './api.config';

@Injectable()
export class CommandHierarchyApiService {
  SERVER_URL;
  RESOURCE_URI = 'command-hierarchy';

  constructor(private http: HttpClient, apiConfig: APIConfig) {
      this.SERVER_URL = apiConfig.TARGET_URL;
  }

  public getAll(params): Observable<any> {
    let httpParams = new HttpParams();

    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        httpParams = httpParams.set(key, params[key]);
      }
    }
    return this.http.get(
      `${this.SERVER_URL}/${this.RESOURCE_URI}`, {params:params});
  }

  public createCommandHierarchy(commandHierarchy: any): Observable<any> {
    return this.http.post(
      `${this.SERVER_URL}/${this.RESOURCE_URI}`, commandHierarchy);
  }

}
