import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter } from '../services/global/date-converter';
import { Command } from '@models/command.model';
import { User } from '@models/user.model';
import { Source } from '@models/source.model';

@JsonObject('FDOMap')
export class FDOMap {
@JsonProperty('class_fdo_map_id', String, true)
classFDOMapId: string = undefined;
@JsonProperty('src_classification_code', String, true)
srcClassificationCode: string = undefined;
@JsonProperty('src_classification_string', String, true)
srcClassificationString: string = undefined;
@JsonProperty('source_id', String, true )
sourceId: string = undefined;
@JsonProperty('source', Source, true )
source: Source = undefined;
@JsonProperty('command_id', String, true )
commandId: string  = undefined;
@JsonProperty('command', Command, true )
command: Command = undefined;
@JsonProperty('tgt_classification_code', String, true )
tgtClassificationCode: string = undefined;
@JsonProperty('tgt_classification_string', String, true )
tgtClassificationString: string = undefined;
@JsonProperty('create_ts', DateConverter, true)
dateCreated: Date = undefined;
@JsonProperty('created_by', String, true)
createdBy: string = undefined;
@JsonProperty('update_ts', DateConverter, true)
dateUpdated: Date = undefined;
@JsonProperty('updated_by', String, true)
updatedBy: string = undefined;
@JsonProperty('delete_ts', DateConverter, true)
dateDeleted: Date = undefined;
@JsonProperty('deleted_by', String, true)
deletedBy: string = undefined;
@JsonProperty('processing_tags', String, true)
processingTags: string = undefined;
}
