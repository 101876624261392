import { FomPageBaseComponent } from '@fom-module/base-classes/fom-page-base.component';
import { LocationService } from '@services/data-services/location.service';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective } from '@angular/forms';
import { OnDestroy, OnInit } from '@angular/core';
import { Component, ViewChild, Inject, Optional } from '@angular/core';
import { Location } from '@models/location.model';
import { Fgi } from '@models/fgi.model';
import { User } from '@models/user.model';
import { Unit } from '@models/unit.model';
import { UnitService } from '@services/data-services/unit.service';
import { LookUpService } from '@services/data-services/lookup.service';
import { Classification } from '@models/classification.model';
import { Control } from '@models/control.model';
import { Releasability } from '@models/releasability.model';
import { OtherControl } from '@models/other-control.model';
import { timer, Subscription, Observable, Subject } from 'rxjs';
import { debounceTime, exhaustMap, filter, finalize, map, scan, startWith, switchMap, takeWhile, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Zulu1Pipe } from '../../core/pipes/zulu1.pipe';
import { UtilityService } from '@services/utility.service';
import { SortingService } from '@services/sorting.service';
import { take } from 'rxjs/operators';
import { PicklistPrefService } from '@services/data-services/picklist-pref.service';
import { Country } from '@models/country.model';
import { LookupSelectComponent } from '@fom-module/reusable/lookup-select/lookup-select.component';
import { CurrentUserService } from '@services/current-user-service';
import { CountryService } from '@services/data-services/country.service';
import { AppInitService } from '../../../config/init.service';
import { GeoRefService } from '@services/data-services/geo-ref.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ClassificationService } from "@services/data-services/classification.service";
import { ReleasabilityService } from "@services/data-services/releasability.service";
import { OtherControlService } from "@services/data-services/other-control.service";
import { ControlService } from "@services/data-services/control.service";
import { FgiService } from "@services/data-services/fgi.service";
import { FleetService } from "@services/data-services/fleet.service";
import { PortService } from "@services/data-services/port.service";
import { ActivityService } from "@services/data-services/activity.service";
import { AreaService } from "@services/data-services/area.service";
import { SourceService } from "@services/data-services/source.service";
import { ElnotService } from "@services/data-services/elnot.service";
import { FreqTypeService } from "@services/data-services/freq-type.service";
import { ScanTypeService } from "@services/data-services/scan-type.service";
import { ElintFunctionTypeService } from "@services/data-services/elint-function-type.service";
import { ConfidenceCodeService } from "@services/data-services/confidence-code.service";

import * as moment from 'moment';
import { Fleet } from '@models/fleet.model';
import { Area } from '@models/area.model';
import { Port } from '@models/port.model';
import { Source } from '@models/source.model';
import { Elnot } from '@models/elnot.model';
import { Activity } from '@models/activity.model';
import { FriendlyErrorService } from '@services/friendly-error.service';
import { FreqType } from '@models/freq-type.model';
import { ScanType } from '@models/scan-type.model';
import { ElintFunctionType } from '@models/elint-function-type.model';
import { ConfidenceCode } from '@models/confidence-code.model';
// import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
// import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';

// interface frequencyType{
//   value: string;
// }

@Component({
  selector: 'app-unit-update',
  templateUrl: './unit-update.component.html',
  styleUrls: ['./unit-update.component.css']
})
export class UnitUpdateComponent extends FomPageBaseComponent implements OnInit, OnDestroy {

  @ViewChild(MatAutocompleteTrigger, { static: true }) unitAutocomplete: MatAutocompleteTrigger;

  allowFutureDates: Boolean;
  maxDate: any;
  user: User;
  entity: any;
  daysOutCount: number = 0;
  unit: any = {};  // using this to drive the filters for dropdowns, would prefer it all be databound to ngModel...
  latitudePattern: any;
  longitudePattern: any;
  dtgImagedPattern: any;
  mfiDtgPattern: any;
  locTimePattern: any;
  editForm: FormGroup;
  // RELOAD_MESSAGE: GlobalMessageTriggers;
  // RadioButton Options
  classificationOptions: Classification[];

  // Checkbox Options
  sciControlsOptions: Object[];
  fgiControlOptions: Object[];
  otherControlsOptions: Object[];
  releasabilityOptions: Object[];

  // Dropdown options
  filteredOptions: Observable<Object[]>;
  fleetOptions: Fleet[];
  fleetFilterControl = new FormControl('');
  fleetFilteredOptions: Observable<Object[]>;
  activityCategoryOptions: Activity[];
  activityFilteredOptions: Observable<Object[]>;
  sourceOptions: Source[];
  sourceFilteredOptions: Observable<Object[]>;
  confidenceCodeOptions: ConfidenceCode[];
  confidenceCodeFilteredOptions: Observable<Object[]>;
  elnotOptions: Elnot[];
  elnotFilteredOptions: Observable<Object[]>;
  freqTypeOptions: FreqType[];
  scanTypeOptions: ScanType[];
  scanFilteredOptions: Observable<Object[]>;
  funcTypeOptions: ElintFunctionType[];
  // frequencyTypes: frequencyType[] = [
  //   {value: 'Hz'},
  //   {value: 'KHz'},
  //   {value: 'MHz'},
  //   {value: 'GHz'},
  // ];

  // OpAreas are going to be filtered down when a Unit is selected.
  // all will be gathered from the database, then User's preferences applied to sort and disable some,
  // finally they are filtered down when the Unit gets selected
  allOpAreaOptions: Area[] = [];
  opAreaOptions: Area[] = [];
  areaFilteredOptions: Observable<Object[]>;
  portOptions: Port[] = [];
  portFilteredOptions: Observable<Object[]>;
  // special filter for Country on the search/type-ahead
  countries: Country[] = [];
  filterByCountry: any = {};
  noCountry = {};
  // type ahead options
  // unit select from fomAssist View
  // unitOptions: Unit[] = [];

  // Limits the number of units returned per page when searching through the type ahead
  unitsPerPage: number;

  // Observables and subjects leveraged to paginate autocomplete options as a user scrolls.
  filteredUnits$: Observable<Unit[]> | undefined;
  private nextUnitPage$ = new Subject();
  filteredAssessments$: Observable<Unit[]> | undefined;
  private nextAssessmentPage$ = new Subject();
  isLoading = false;

  //disable fields when ELNOT is not selected


  BOTTOM_NAV_BAR: any;

  // get the max for the date pickers, reset every 20 seconds
  private maxDateSubscription: Subscription;

  // variables for track maint updates
  isNew: boolean;
  isDelete: boolean;
  fromTrackMaint: boolean = false;
  trackMaintEntity: any;
  fromParser: boolean = false;

  @ViewChild('editFormDirective') editFormDirective: FormGroupDirective;
  //@ViewChild('opAreaSelect') opAreaSelect: LookupSelectComponent;
  //@ViewChild('fleetSelect') fleetSelect: LookupSelectComponent;

  commandId: [];
  currentUserABACPrefs: any;
  elnotReadOnly: boolean;
  activityReadOnly: boolean;
  inPortActivityFlag: boolean;
  showPriorTrackUWFlag: boolean;
  activityCatDesc: string;

  constructor(public locationService: LocationService,
    public lookUpService: LookUpService,
    private snackBar: MatSnackBar,
    private unitService: UnitService,
    public formBuilder: FormBuilder,
    public messageBusService: MessageBusService,
    public route: Router,
    private zulu1Pipe: Zulu1Pipe,
    public utilities: UtilityService,
    private lookupService: LookUpService,
    private picklistPrefService: PicklistPrefService,
    private currentUserService: CurrentUserService,
    private countryService: CountryService,
    public initService: AppInitService,
    private geoRefService: GeoRefService,
    private friendlyErrorService: FriendlyErrorService,
    private sorter: SortingService,
    private classificationService: ClassificationService,
    private releasabilityService: ReleasabilityService,
    private otherControlService: OtherControlService,
    private fgiService: FgiService,
    private fleetService: FleetService,
    private portService: PortService,
    private activityService: ActivityService,
    private opAreaService: AreaService,
    private sourceService: SourceService,
    private elnotService: ElnotService,
    private freqTypeService: FreqTypeService,
    private scanTypeService: ScanTypeService,
    private funcTypeService: ElintFunctionTypeService,
    private controlService: ControlService,
    private confidenceCodeService: ConfidenceCodeService,
    @Optional() public dialogRef: MatDialogRef<UnitUpdateComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(messageBusService, route);
  }

  async ngOnInit() {
    //moment.tz.setDefault('Europe/London')
    this.filterByCountry = this.noCountry
    this.user = this.currentUserService.getCurrentUser();

    this.unitsPerPage = this.initService.getConfig().unitPageSize || 20;

    const filters = JSON.parse(sessionStorage.getItem('unitFilters'))
    // get the max for the date pickers, reset every 20 seconds
    this.allowFutureDates = this.initService.getConfig().allowFutureDates;
    this.maxDate = moment(); // Date()
    let maxTimer = timer(0, 10000)
    this.maxDateSubscription = maxTimer.subscribe(x => {
      this.maxDate = moment(); // Date()
    })
    this.latitudePattern = '^[0-9NS]+$';
    this.longitudePattern = '^[0-9EW]+$';
    this.dtgImagedPattern = /\d{4}-\d{2}-\d{2} \d{2}:\d{2}/;
    this.mfiDtgPattern = /\d{4}-\d{2}-\d{2} \d{2}:\d{2}/;
    this.locTimePattern = /\d{4}-\d{2}-\d{2} \d{2}:\d{2}/;
    //this.listenToMessageBus();
    this.entity = new Location();

    //check if the track is from parser when updating from track maint
    if (this.fromTrackMaint && this.trackMaintEntity.parser_msg_id) {
      this.fromParser = true;
    }

    this.initializeForm(filters);
    await Promise.resolve().then(() => this.loadLookups());

    //load options arrays into the filtered options arrays for pipe'ing
    this.autoCompleteArrays();

    //elnot read only fields
    this.elnotReadOnly = true;
    this.activityReadOnly = true;
    this.editForm.controls.portFacility.disable();
    this.setInPortActivityFlag(null);

    //why is this here??
    if (this.fromTrackMaint) {
      this.entity = this.trackMaintEntity;
      this.loadLocationFromTrackMaint(this.entity.unit, this.entity);
    }

    super.ngOnInit();
  }

  ngOnDestroy() {
    if (this.maxDateSubscription) {
      this.maxDateSubscription.unsubscribe()
    }
  }

  // We are only doing inserts to the ship location so there is no need to prepopulate the form.
  initializeForm(filters) {
    this.unit = {}
    filters = filters || {}
    filters.sort = 'name'; // add name sort
    let numPattern = /^\d*(\.\d+)?([.,]\d+)?$/

    this.showPriorTrackUWFlag = false;
    this.editForm = this.formBuilder.group({
      classificationId: [{ value: '', disabled: false }, Validators.required], // primary TS or Secret or Confi or Un
      sciControl: [[]], // Default selection lists to an empty array
      fgiControl: [[]],
      otherControl: [[]],
      dissemination: [[]],
      unit: [{ value: '', disabled: this.fromParser || this.fromTrackMaint }],
      totalDays: [],
      dtgImaged: [],
      locTime: [],
      lastLoc: [],
      latitude: ['', this.utilities.latValidator],
      longitude: ['', this.utilities.lonValidator],
      fleetId: [],
      portId: [],
      lastLocSrc: [],
      activityCategoryId: [],
      opAreaId: [],
      srcOrig: [],
      activity: [],
      tunnelInd: false,
      patCandInd: false,
      papaCase: [],
      nobRemoveInd: false,
      reassessedInd: false,
      uwCode: [],
      newUnit: [''],
      ltn: [],
      uid: [],
      mfiTs: [],
      daysOutTs: [],
      isRemovedFromNob: [],
      el_not_id: [],
      el_not: [''],
      provider: [''],
      freq: [''/*, this.utilities.freqValidator*/],
      freq_type_id: [''],
      band: [, Validators.pattern(numPattern)],
      func: [''],
      elint_function_id: [],
      pul_dur: [, Validators.pattern(numPattern)],
      pri: [, Validators.pattern(numPattern)],
      prf: [, Validators.pattern(numPattern)],
      scan_rate: [, Validators.pattern(numPattern)],
      scan_measure: [''],
      scan_type: [''],
      scan_type_id: [''],
      toi_ts: [],
      mfiInd: false,
      portFacility: [],
      confidence:[],
      rationale: []
    });



    // clear 'em out
    this.opAreaOptions = [];
    this.portOptions = [];
    // light the Unit message
    //this.editForm.markAllAsTouched()
    this.editForm.controls.unit.markAsTouched()

    const userFilters = Object.assign(filters, this.user.unitUpdateFilter())
    const unitFilter$ = this.editForm.get('unit').valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      filter(q => typeof q === "string"));

    this.filteredUnits$ = unitFilter$.pipe(
      switchMap(filter => {
        // Reset current page whenever name filter changes.
        let currentPage = 1;
        return this.nextUnitPage$.pipe(
          tap(() => this.isLoading = true),
          startWith(currentPage),
          // Note: Until the backend responds, ignore NextPage requests.
          exhaustMap(_ => this.searchUnits(filter, userFilters, currentPage)),
          tap(() => currentPage++),

          takeWhile(p => p.length > 0, true),
          scan((allUnits: any, newUnits: any) => allUnits.concat(newUnits), []),
          tap(async units => {
            const unit = this.editForm.get('unit')?.value || '';

            if (unit && unit.length) {
              await this.clearForm();

              if (units.length === 1 && unit.trim().toLocaleLowerCase() === units[0].fullName().toLocaleLowerCase()) {
                this.unitAutocomplete.closePanel();
                this.handleUnitChange({ option: { value: units[0] } })
              }
            }
            this.isLoading = false;
          })
        );
      }));

    const assessmentFilter$ = this.editForm.get('newUnit').valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      filter(q => typeof q === 'string'));

    this.filteredAssessments$ = assessmentFilter$.pipe(
      switchMap(filter => {
        let currentPage = 1;
        return this.nextAssessmentPage$.pipe(
          tap(() => this.isLoading = true),
          startWith(currentPage),
          exhaustMap(_ => this.searchUnits(filter, filters, currentPage)),
          tap(() => currentPage++),
          takeWhile(p => p.length > 0, true),
          scan((allUnits: any, newUnits: any) => allUnits.concat(newUnits), []),
          tap(() => this.isLoading = false)
        )
      })
    );
  }

  autoCompleteArrays() {
    this.fleetFilteredOptions = this.editForm.controls['fleetId'].valueChanges.pipe(
      startWith(''),
      map(value => this._filterArray(value || '', "fleet"))
    );
    this.activityFilteredOptions = this.editForm.controls['activityCategoryId'].valueChanges.pipe(
      startWith(''),
      map(value => this._filterArray(value || '', "activity"))
    );
    this.sourceFilteredOptions = this.editForm.controls['lastLocSrc'].valueChanges.pipe(
      startWith(''),
      map(value => this._filterArray(value || '', "source"))
    );
    this.elnotFilteredOptions = this.editForm.controls['el_not_id'].valueChanges.pipe(
      startWith(''),
      map(value => this._filterArray(value || '', "elnot"))
    );
    this.areaFilteredOptions = this.editForm.controls['opAreaId'].valueChanges.pipe(
      startWith(''),
      map(value => this._filterArray(value || '', "area"))
    );
    this.portFilteredOptions = this.editForm.controls['portFacility'].valueChanges.pipe(
      startWith(''),
      map(value => this._filterArray(value || '', "port"))
    );
    this.scanFilteredOptions = this.editForm.controls['scan_type_id'].valueChanges.pipe(
      startWith(''),
      map(value => this._filterArray(value || '', "scantype"))
    );
    this.confidenceCodeFilteredOptions = this.editForm.controls['confidence'].valueChanges.pipe(
      startWith(''),
      map(value => this._filterArray(value || '', "confidence"))
    );
  }

  private _filterArray(value: string, type: string): Object[] {
    const filterValue = value ? value.toLowerCase() : '';
    switch (type) {
      case "fleet":
        return this.fleetOptions.filter(option => option.fleetCode.toLowerCase().includes(filterValue));
      case "activity":
        return this.activityCategoryOptions.filter(option => option.description.toLowerCase().includes(filterValue));
      case "source":
        return this.sourceOptions.filter(option => option.sourceName.toLowerCase().includes(filterValue));
      case "elnot":
        return this.elnotOptions.filter(option => option.el_not_code.toLowerCase().includes(filterValue));
      case "area":
        return this.opAreaOptions.filter(option => option.areaRemarks().toLowerCase().includes(filterValue));
      case "port":
        return this.portOptions.filter(option => option.name.toLowerCase().includes(filterValue));
      case "scantype":
        return this.scanTypeOptions.filter(option => option.scan_type_code.toLowerCase().includes(filterValue));
      case "confidence":
        return this.confidenceCodeOptions.filter(option => option.confidenceCode.toLowerCase().includes(filterValue));
      default:
        return [];
    }
  }

  setInPortActivityFlag(val) {
    let inPortValList = ['INPT', 'RTP']
    if (val) {
      //is this inPort INPT or RTP
      const found = this.activityCategoryOptions.find(x => x.activityId == val)
      if (found) {
        if (inPortValList.includes(found.code)) {
          if (!(this.inPortActivityFlag)) {
            this.editForm.controls.mfiInd.setValue(false);
            this.editForm.controls.mfiInd.disable();
            this.editForm.controls.latitude.setValue('');
            this.editForm.controls.longitude.setValue('');
            this.editForm.controls.longitude.disable();
            this.editForm.controls.latitude.disable();
            this.editForm.controls.portFacility.setValue(null);
            this.editForm.controls.portFacility.enable();

          }
          this.inPortActivityFlag = true;
        } else {
          if ((this.inPortActivityFlag)) {
            this.editForm.controls.mfiInd.enable();
            this.editForm.controls.latitude.setValue('');
            this.editForm.controls.longitude.setValue('');
            this.editForm.controls.longitude.enable();
            this.editForm.controls.latitude.enable();
            this.editForm.controls.portFacility.setValue(null);
            this.editForm.controls.portFacility.disable();

          }
          this.inPortActivityFlag = false;
        }

      }
    }
  }

  countryFilterChange(event) {
    const filter = event.value.countryId ? { country_id: event.value.countryId } : {}
    this.initializeForm(filter)
    // if the unit update set a country filter, move the items to the top
    if (event.value) {
      const topAreas = this.opAreaOptions.filter(x => x.remarks == event.value.name)
      const bottomAreas = this.opAreaOptions.filter(x => x.remarks != event.value.name)
      this.opAreaOptions = topAreas.concat(bottomAreas)
      const topFleets = this.fleetOptions.filter(x => x.remarks == event.value.name)
      const bottomFleets = this.fleetOptions.filter(x => x.remarks != event.value.name)
      this.fleetOptions = topFleets.concat(bottomFleets)
    }
    //this.opAreaSelect.secondFilter = {name: 'OpArea', filter: event.value}
    //this.fleetSelect.secondFilter = {name: 'Fleet', filter: event.value}
  }

  // the user has changed the filters. this affects the searching for units in the autocomplete
  unitFiltersChanged(filters) {
    this.initializeForm(filters)
  }

  async loadLookups() {
    // this.classificationOptions = await this.lookUpService.getLookupByType(Classification).pipe(take(1)).toPromise()
    // descending order fomng-840

    const ownerId = this.user.unitOwnerId(null)
    /*this.sciControlsOptions = await this.lookUpService.getLookupByType(Control).pipe(take(1)).toPromise()
    this.otherControlsOptions = await this.lookUpService.getLookupByType(OtherControl).pipe(take(1)).toPromise()
    this.releasabilityOptions = await this.lookUpService.getLookupByType(Releasability).pipe(take(1)).toPromise()
    this.fgiControlOptions = await this.lookUpService.getLookupByType(Fgi).pipe(take(1)).toPromise()*/

    //the class tables are not the same as the others
    this.sciControlsOptions = await this.controlService.getAll().pipe(take(1)).toPromise();
    this.fgiControlOptions = await this.fgiService.getAll().pipe(take(1)).toPromise();
    this.otherControlsOptions = await this.otherControlService.getAll().pipe(take(1)).toPromise();
    this.releasabilityOptions = await this.releasabilityService.getAll().pipe(take(1)).toPromise();
    this.classificationOptions = await this.classificationService.getAll().pipe(take(1)).toPromise();

    this.classificationOptions.sort((a, b) => {
      return a.sortBy < b.sortBy ? 1 : -1
    })


    //this.fleetOptions = await this.lookupService.getLookupByType(Fleet).pipe(take(1)).toPromise();
    this.fleetOptions = await this.fleetService.getAll().pipe(take(1)).toPromise();
    //this.portOptions = await this.lookUpService.getLookupByType(Port).pipe(take(1)).toPromise()
    this.portOptions = await this.portService.getAll().pipe(take(1)).toPromise()
    //this.activityCategoryOptions = await this.lookUpService.getLookupByType(Activity).pipe(take(1)).toPromise()
    this.activityCategoryOptions = await this.activityService.getAll().pipe(take(1)).toPromise()
    //this.allOpAreaOptions = await this.lookUpService.getLookupByType(Area).pipe(take(1)).toPromise()
    this.allOpAreaOptions = await this.opAreaService.getAll().pipe(take(1)).toPromise()
    //this.sourceOptions = await this.lookUpService.getLookupByType(Source).pipe(take(1)).toPromise()
    this.sourceOptions = await this.sourceService.getAll().pipe(take(1)).toPromise()
    //this.countries = await this.lookUpService.getLookupByType(Country, true).pipe(take(1)).toPromise()
    this.countries = await this.countryService.getAll().pipe(take(1)).toPromise()
    //this.elnotOptions = await this.lookUpService.getLookupByType(Elnot).pipe(take(1)).toPromise()
    this.elnotOptions = await this.elnotService.getAll().pipe(take(1)).toPromise()
    //this.freqTypeOptions = await this.lookUpService.getLookupByType(FreqType).pipe(take(1)).toPromise()
    this.freqTypeOptions = await this.freqTypeService.getAll().pipe(take(1)).toPromise()
    // this.scanTypeOptions = await this.lookUpService.getLookupByType(ScanType).pipe(take(1)).toPromise()
    this.scanTypeOptions = await this.scanTypeService.getAll().pipe(take(1)).toPromise()
    // this.funcTypeOptions = await this.lookUpService.getLookupByType(ElintFunctionType).pipe(take(1)).toPromise()
    this.funcTypeOptions = await this.funcTypeService.getAll().pipe(take(1)).toPromise()
    this.confidenceCodeOptions = await this.confidenceCodeService.getAll().pipe(take(1)).toPromise()


    const filter = { owner_id: this.user.unitOwnerId(null) }
    this.picklistPrefService.getAll(filter).pipe(take(1)).subscribe(async preferences => {
      this.countries = this.sorter.sortList(this.countries, preferences, 'name', 'countryId')
      this.fleetOptions = this.sorter.sortList(this.fleetOptions, preferences, 'fleetCode', 'fleetId')
      this.portOptions = this.sorter.sortList(this.portOptions, preferences, 'portCode', 'portId')
      this.activityCategoryOptions = this.sorter.sortList(this.activityCategoryOptions, preferences, 'code', 'activityId')
      this.allOpAreaOptions = this.sorter.sortList(this.allOpAreaOptions, preferences, 'opArea', 'opAreaId')
      this.sourceOptions = this.sorter.sortList(this.sourceOptions, preferences, 'shortCode', 'sourceId')
      this.elnotOptions = this.sorter.sortList(this.elnotOptions, preferences, 'el_not_code', 'el_not_id')
      this.confidenceCodeOptions = this.sorter.sortList(this.confidenceCodeOptions, preferences, 'confidenceCode', 'confidenceCodeId')
    })

    // Handle if a unit_id is passed to the component after lookups load so that associations don't break...
    if (history.state?.data?.unit_id) {
      this.unitService.getUnitByUnitId(history.state.data.unit_id).pipe(take(1)).subscribe(unit => {
        this.handleUnitChange({ option: { value: unit } });
      });
    }

  }

  displayUnit(unit?: Unit): string | undefined {
    return unit ? unit.fullName() : undefined;
  }

  loadLocationFromTrackMaint(unit, location) {
    this.activityReadOnly = true;
    this.showPriorTrackUWFlag = false;
    // pare down the list
    if (unit.country) {
      this.opAreaOptions = this.allOpAreaOptions.filter(x => x.remarks == (unit as Unit).country.name)
    } else {
      // what else to do if no Country? the UI error will have blank...
      this.opAreaOptions = [];
    }
    this.unit = unit;
    // Force the editForm's control value to the unit, in case user pasted the name and option is selected
    // automatically
    this.editForm.controls.unit.setValue(this.unit);

    // can't have any of the reassessed stuff laying around..
    this.editForm.controls['reassessedInd'].setValue(false);
    this.editForm.controls['newUnit'].setValue(null);

    if (location) {
      // set the inport activity flag based on the selected track's location data
      if (location.activityCategoryId) {
        this.setInPortActivityFlag(location.activityCategoryId);
      }

      this.editForm.patchValue({
        totalDays: location.totalDays,
        dtgImaged: location.dtgImaged,
        locTime: location.locTime,
        lastLoc: location.lastLoc,
        latitude: location.latitude,
        longitude: location.longitude,
        fleetId: this.fleetOptions.find(x => x.fleetId === location.fleetId)?.fleetCode,
        // fleetId: location.fleetId,
        portId: this.portOptions.find(x => x.portId === location.port_id)?.name,
        // portId: location.port_id,
        lastLocSrc: this.sourceOptions.find(x => x.sourceId === location.lastLocSrc)?.sourceName,
        // lastLocSrc: location.lastLocSrc,
        activityCategoryId: this.activityCategoryOptions.find(x => x.activityId === location.activityCategoryId)?.description,
        // activityCategoryId: location.activityCategoryId,
        // opAreaId: location.opAreaId,
        srcOrig: location.srcOrig,
        activity: location.activity,
        tunnelInd: location.tunnelInd,
        patCandInd: location.patCandInd,
        papaCase: location.papaCase,
        nobRemoveInd: location.nobRemoveInd,
        reassessedInd: location.reassessedInd,
        uwCode: location.uwCode,
        newUnit: location.newUnitId,
        ltn: location.ltn,
        uid: location.uid,
        mfiTs: location.mfiTs,
        daysOutTs: location.daysOutTs,
        isRemovedFromNob: location.isRemovedFromNob,
        mfiInd: location.mfiInd,
        portFacility: this.portOptions.find(x => x.portId === location.port_id)?.name,
        confidence: this.confidenceCodeOptions.find(x => x.confidenceCodeId === location.confidenceCodeId)?.confidenceCode,
        // portFacility: location.port_id,
      });

       //set UW flag for icon
       this.showPriorTrackUWFlag = this.unitUwCheck(this.editForm.controls.activityCategoryId.value, this.editForm.controls.activity);

      // assign classification values if the track is from parser so that the editform can be valid and user can still hit submit
      if (this.fromParser) {
        this.editForm.patchValue({
          classificationId: this.getClassification(location.classificationDescription),
          sciControl: this.getSciControls(location.classificationDescription),
          fgiControl: this.getFgiControls(location.classificationDescription),
          otherControl: this.getOtherControls(location.classificationDescription),
          dissemination: this.getDisseminations(location.classificationDescription),
        })
      }

      // assign elint values from the location data if it exists
      if (location.elint) {
        this.elnotReadOnly = false;

        this.editForm.patchValue({
          el_not_id: this.elnotOptions.find(x => x.el_not_id === location.elint.elnotId)?.el_not_code,
          // el_not_id: location.elint.el_not_id,
          el_not: location.elint.el_not,
          provider: location.elint.provider,
          freq: location.elint.freq,
          freq_type_id: location.elint.freqTypeId,
          band: location.elint.band,
          func: location.elint.func,
          elint_function_id: location.elint.elint_function_id,
          pul_dur: location.elint.pulDur,
          pri: location.elint.pri,
          scan_rate: location.elint.scanRate,
          scan_measure: location.elint.scanMeasure,
          scan_type: location.elint.scanType,
          scan_type_id: location.elint.scanTypeId ?
            (this.scanTypeOptions.find(x => x.scan_type_id === location.elint.scanTypeId)?.scan_type_code +
              " (" +
              this.scanTypeOptions.find(x => x.scan_type_id === location.elint.scanTypeId)?.scan_type_description
              + ")")
            : null,
          // scan_type_id: location.elint.scanTypeId,
          toi_ts: location.elint.toiTs,
        });
      }
      // make sure the old Op Area is still valid for the Unit's country....
      // if not it should be cleared to help the screen validation
      const found = this.opAreaOptions.find(x => x.opAreaId == location.opAreaId)
      if (found) {
        this.editForm.controls['opAreaId'].setValue(this.opAreaOptions.find(x => x.opAreaId === location.opAreaId)?.areaRemarks());
        // this.editForm.controls['opAreaId'].setValue(location.opAreaId);
      } else {
        this.editForm.controls['opAreaId'].setValue(null);
      }
      //if the resulting unit has activity, enable editing the activity field
      if (location.activityCategoryId) {
        this.activityReadOnly = false;
      } else {
        this.activityReadOnly = true;
      }

      // If unit was removed from NOB don't carry over reassessment suffixes.
      this.entity.prevAssess = location.nobRemoveInd ? null : location.prevAssess;
    } else {
      // refactored for use above
      this.clearForm()
    }
  }

  ooaCheck(activity) {
    let retVal = false;
    let ooaValList = ['OOA', 'NETF']
    if (ooaValList.includes(activity)) {
      retVal = true
    }
    return retVal;
  }

  unitUwCheck(activityCatDesc, activity) {
    let retVal = false;
    let tmpDaysOut = 0;
    if ((activityCatDesc == 'INPT' && this.ooaCheck(activity)) || (activityCatDesc != 'INPT')) {
      tmpDaysOut = this.calcDaysOut();
      retVal = true
    }
    this.daysOutCount = tmpDaysOut;
    return retVal;
  }

  calcDaysOut() {
    let retVal = 0;
    let tmpDayOutTs = this.editForm.controls.daysOutTs.value
    if (tmpDayOutTs) {
      var current_date = moment().format("YYYY-MM-DD");
      var dayOut_date = moment(tmpDayOutTs).format("YYYY-MM-DD");
      var days = moment(current_date).diff(dayOut_date, 'days');
      retVal = days + 1;//add 1 per request that cannot be zero
    }
    return retVal;
  }


  handleUWDateChange(event) {
    this.showPriorTrackUWFlag =this.unitUwCheck(this.editForm.controls.activityCategoryId.value, this.editForm.controls.activity.value);
  }

  handleUnitChange(event) {
    //Dont overwrite anything if this is track maint!
    if (this.fromTrackMaint) {
      return;
    }

    //
    this.activityReadOnly = true;
    this.elnotReadOnly = true;
    const unit = event.option.value;

    // pare down the list
    if (unit.country) {
      this.opAreaOptions = this.allOpAreaOptions.filter(x => x.remarks == (unit as Unit).country.name)
    } else {
      // what else to do if no Country? the UI error will have blank...
      this.opAreaOptions = [];
    }
    this.unit = event.option.value
    // Force the editForm's control value to the unit, in case user pasted the name and option is selected
    // automatically
    this.editForm.controls.unit.setValue(this.unit);
    // can't have any of the reassessed stuff laying around..
    this.editForm.controls['reassessedInd'].setValue(false);
    this.editForm.controls['newUnit'].setValue(null);
    this.locationService.getAll({ unit_id: unit.unitId, sort: 'loc_time_ts, create_ts', limit: 1 }).pipe(take(1)).subscribe(results => {
      if (results.length > 0) {
        // set the inport activity flag based on the unit's last location
        this.activityCatDesc = null;
        if (results[0].activityCategoryId) {
          this.setInPortActivityFlag(results[0].activityCategoryId);
          this.activityCatDesc = this.activityCategoryOptions.find(x => x.activityId === results[0].activityCategoryId)?.description;
        }
        //Some of these fields are given the plaintext remarks/names instead of the ID. The reason for this is for matAutoComplete considerations.
        this.editForm.controls['dtgImaged'].setValue(results[0].dtgImaged);
        //address days out counter
        this.editForm.controls['daysOutTs'].setValue(this.unitUwCheck(this.activityCatDesc, results[0].activity) ? results[0].daysOutTs : null);
        //set UW flag for icon
        this.showPriorTrackUWFlag = this.unitUwCheck(this.activityCatDesc, results[0].activity);
        this.editForm.controls['mfiTs'].setValue(results[0].mfiTs);
        this.editForm.controls['locTime'].setValue(results[0].locTime);
        this.editForm.controls['activity'].setValue(results[0].activity);
        this.editForm.controls['activityCategoryId'].setValue(this.activityCatDesc);
        // this.editForm.controls['activityCategoryId'].setValue(results[0].activityCategoryId);
        this.editForm.controls['lastLoc'].setValue(results[0].lastLoc);
        // Add values to hidden attributes
        this.editForm.controls['isRemovedFromNob'].setValue(results[0].nobRemoveInd);
        this.editForm.controls['ltn'].setValue(results[0].ltn);
        this.editForm.controls['uid'].setValue(results[0].uid);
        this.editForm.controls['uwCode'].setValue(results[0].uwCode);
        this.editForm.controls['fleetId'].setValue(this.fleetOptions.find(x => x.fleetId === results[0].fleetId)?.fleetCode);
        this.editForm.controls['confidence'].setValue(this.confidenceCodeOptions.find(x => x.confidenceCodeId === results[0].confidenceCodeId)?.confidenceCode);
        // this.editForm.controls['fleetId'].setValue(results[0].fleetId);
        // this.editForm.controls['portFacility'].setValue(this.portOptions.find(x => x.portId === results[0].portId).name);
        this.editForm.controls['portFacility'].setValue(results[0].portId);
        // make sure the old Op Area is still valid for the Unit's country....
        // if not it should be cleared to help the screen validation
        const found = this.opAreaOptions.find(x => x.opAreaId == results[0].opAreaId)
        if (found) {
          this.editForm.controls['opAreaId'].setValue(this.opAreaOptions.find(x => x.opAreaId === results[0].opAreaId).areaRemarks());
          // this.editForm.controls['opAreaId'].setValue(results[0].opAreaId); 
        } else {
          this.editForm.controls['opAreaId'].setValue(null);
        }
        //if the resulting unit has activity, enable editing the activity field
        if (results[0].activityCategoryId) {
          this.activityReadOnly = false;
        } else {
          this.activityReadOnly = true;
        }
        //if the resulting unit doesn't have an activity id, don't enable editing the field
        if (results[0].el_not_code) {
          this.elnotReadOnly = false;
        } else {
          this.elnotReadOnly = true;
        }

        // If unit was removed from NOB don't carry over reassessment suffixes.
        this.entity.prevAssess = results[0].nobRemoveInd ? null : results[0].prevAssess;

      } else {
        // refactored for use above
        this.clearForm()
      }
    });
  }

  handleFleetChange(optionSelected: string) {
    console.log('optionSelected : ', optionSelected);
  }

  // refactored from handleUnitChange
  async clearForm() {
    // Reset values so data isn't pulled in from another unit's last update
    this.editForm.controls['fleetId'].setValue(null);
    this.editForm.controls['activityCategoryId'].setValue(null);
    this.editForm.controls['opAreaId'].setValue(null);
    this.editForm.controls['lastLocSrc'].setValue(null);
    this.editForm.controls['dtgImaged'].setValue(null);
    this.editForm.controls['daysOutTs'].setValue(null);
    this.editForm.controls['mfiTs'].setValue(null);
    this.editForm.controls['locTime'].setValue(null);
    this.editForm.controls['activity'].setValue(null);
    this.editForm.controls['lastLoc'].setValue(null);
    this.editForm.controls['confidence'].setValue(null);

    // Reset hidden attributes
    this.editForm.controls['isRemovedFromNob'].setValue(null);
    this.editForm.controls['ltn'].setValue(null);
    this.editForm.controls['uid'].setValue(null);
    this.editForm.controls['uwCode'].setValue(null);
    this.editForm.controls['reassessedInd'].setValue(false);
    this.editForm.controls['newUnit'].setValue(null);
    this.entity.prevAssess = ''
  }

  handleAssessmentChange(event) {
    if (event.option.value) {
      this.editForm.controls.reassessedInd.setValue(true);
    } else {
      this.editForm.controls.reassessedInd.setValue(false);
    }
  }

  clearNewUnitOnUncheck(event) {
    if (!event.checked) {
      this.editForm.controls.newUnit.setValue('');
    }
  }

  handleSubmit() {
    // Validating last location down here so we don't ding user for an auto-filled DTG until they have a chance to change it
    if (this.editForm.valid && this.utilities.validateLastLoc(this.editForm.controls.dtgImaged.value, this.editForm.controls.locTime.value)) {
      this.updateEntity();
      // Handle condition of null underway code
      if (!this.entity.uwCode && this.entity.reassessedInd) {
        this.snackBar.open(`Unit ${this.unit.fullName()} is not underway and cannot be reassessed`, 'OK', { duration: 5000 });
      } else {
        this.setFieldValuesToId();
        if (this.fromTrackMaint) {
          this.locationService.update(this.entity).pipe(take(1)).subscribe(response => {
            this.dialogRef.close(response);
          });
        } else {
          this.locationService.create(this.entity).pipe(take(1)).subscribe(res => {
            this.snackBar.open('Unit Update sent successfully', 'OK', { duration: 5000 });

            // wasn't loving the way the form refreshed, leaving the required fields all orange...
            // let's see how this goes -- it does flash the screen
            let currentUrl = this.route.url
            this.route.navigateByUrl('/', { skipLocationChange: true }).then(() =>
              this.route.navigate([currentUrl]));
          }, err => {
            this.friendlyErrorService.processError(err)
          });
        }
      }
    } else {
      this.snackBar.open('Last Location must be equal to or later than Imaged DTG', 'OK', { duration: 5000, panelClass: 'red-snackbar' });
    }
  }

  setFieldValuesToId() {
    //mutate the fields back into values that the database needs. This was a workaround for matAutoComplete.
    this.entity.fleetId = this.fleetOptions.find(x => x.fleetCode === this.editForm.controls['fleetId'].value)?.fleetId;
    this.entity.activityCategoryId = this.activityCategoryOptions.find(x => x.description === this.editForm.controls['activityCategoryId'].value)?.activityId;
    this.entity.opAreaId = this.opAreaOptions.find(x => x.areaRemarks() === this.editForm.controls['opAreaId'].value)?.opAreaId;
    this.entity.port_id = this.portOptions.find(x => x.name === this.editForm.controls['portFacility'].value)?.portId;
    this.entity.lastLocSrc = this.sourceOptions.find(x => x.sourceName === this.editForm.controls['lastLocSrc'].value)?.sourceId;
    this.entity.confidenceCodeId = this.confidenceCodeOptions.find(x => x.confidenceCode === this.editForm.controls['confidence'].value)?.confidenceCodeId;
    this.entity.el_not_id = this.elnotOptions.find(x => x.el_not_code === this.editForm.controls['el_not_id'].value)?.el_not_id;
    this.entity.scan_type_id = this.scanTypeOptions.find(x => x.scan_type_code === this.editForm.controls['scan_type_id'].value?.substring(0, this.editForm.controls['scan_type_id'].value.indexOf(' ')))?.scan_type_id; //the html field "scan_type_id" is a misnomer. It's a combo of scan_type_code and scan_type_description. Here we cut the first part from the last to find a match in the scanTypeOptions array.
  }

  handleDeletefromTrackMaint() {
    this.updateEntity();

    if (this.isDelete) {
      if (confirm('Are you sure you want to delete this location?')) {
        this.locationService.delete(this.entity).pipe(take(1)).subscribe(response => {
          this.dialogRef.close(response);
        });
      }
    }
  }

  handleCancel() {
    this.initializeForm({})
  }

  updateEntity() {
    for (const key in this.editForm.controls) {
      if (this.entity.hasOwnProperty(key.toString())) {
        this.entity[key.toString()] = this.editForm.controls[key]['value'];
      }
    }
    // Join the classification fields into 5-part value
    this.entity.classificationDescription = this._classificationDescription();
    //forcer update of portId given name changes
    this.entity.port_id = this.editForm.controls['portFacility']['value'];
    // Add decimal values for latitude and longitude
    const latitude = this.entity.latitude;
    if (latitude.indexOf('.') === -1) {
      this.entity.decLat = this.utilities.decimalLatitude(latitude);
    } else {
      this.entity.decLat = latitude;
      this.entity.latitude = this.utilities.getDMS(latitude, 'lat');
    }

    const longitude = this.entity.longitude;
    if (longitude.indexOf('.') === -1) {
      this.entity.decLon = this.utilities.decimalLongitude(longitude);
    } else {
      this.entity.decLon = longitude;
      this.entity.longitude = this.utilities.getDMS(longitude, 'lon');
    }

    // Add data from unit selected in the autocomplete
    const unit = this.editForm.controls['unit']['value'];
    if (unit) {
      this.entity.unitId = unit.unitId;
    }

    // Add new Unit ID for reassessments
    const newUnit = this.editForm.controls.newUnit.value;
    if (newUnit) {
      this.entity.newUnitId = newUnit.unitId;
    } else {
      // Set to null if validation fails and user removes the reassessment.
      this.entity.newUnitId = null;
    }
  }

  // Creates the joined string of all selected values for the 5-part classification
  private _classificationDescription(): string {
    const classification = this.editForm.controls['classificationId']['value'];

    const selectedScis = this.editForm.controls['sciControl'].value || [];
    const sci = selectedScis.join(',');

    const selectedFgis = this.editForm.controls['fgiControl'].value || [];
    const fgi = selectedFgis.join(',');

    const selectedControls = this.editForm.controls['otherControl'].value || [];
    const control = selectedControls.join(',');

    const selectedDissems = this.editForm.controls['dissemination'].value || [];
    const dissemination = selectedDissems.join(',');

    return `${classification}/${sci}/${fgi}/${control}/${dissemination}`;
  }


  // coerce it back into Zulu if not pasted correctlty
  // need the Z for one thing, non-moment format will drop the HH MM
  dateTimeChange(event, name) {
    // parsing error will set the value to null
    if (event.value) {
      this.editForm.controls[name].setValue(event.value);
    }
    // let it fail with either parse error or if required set
    else if (event.source.value) {
      this.editForm.controls[name].setErrors({ owlDateTimeParse: true })
    }
  }

  getFlag(country) {
    return this.countryService.getFlagUrl(country)
  }

  // clear the MFI value, not handled in owl-date component
  clearMFI() {
    this.editForm.controls['mfiTs'].setValue(null);
  }

  handleCoordinatesChange() {
    const latitudeControl = this.editForm.controls.latitude;
    const longitudeControl = this.editForm.controls.longitude;

    if (latitudeControl.valid && longitudeControl.valid) {
      this.geoRefService.getNearestGeoRef(latitudeControl.value, longitudeControl.value).pipe(take(1)).subscribe(results => {
        if (results) {
          this.editForm.controls.lastLoc.setValue(results['nearest_geo_ref']);
        }
      });
    }
  }

  searchUnits(nameFilter: any, filters: any, page: number): Observable<Unit[]> {
    const offset = page > 0 ? (page - 1) * this.unitsPerPage : 0;

    return this.unitService.findUnits(nameFilter, filters, this.unitsPerPage, offset);
  }

  onUnitOptionsScroll() {
    this.nextUnitPage$.next();
  }

  onAssessmentOptionsScroll() {
    this.nextAssessmentPage$.next();
  }

  selectActivity(val) {
    if (val) {
      this.activityReadOnly = false;
    } else {
      this.activityReadOnly = true;
    }
    this.setInPortActivityFlag(val);
  }

  selectPort(event: any) {

    // Find the selected option object in the elnotOptions array
    const selectedPort = this.portOptions.find(option => option.name === event.option.value);

    if ((selectedPort))
    // && (!(this.editForm.get('mfiInd').value))

    {
      this.editForm.controls.latitude.setValue(selectedPort?.decLat);
      this.editForm.controls.longitude.setValue(selectedPort?.decLon);
      this.editForm.controls.longitude.disable();
      this.editForm.controls.latitude.disable();
    }
  }

  toggleMfi(event) {
    if (event.checked) {
      //  this.editForm.controls.latitude.setValue('0.00000');
      //  this.editForm.controls.longitude.setValue('0.00000');
      this.editForm.controls.longitude.disable();
      this.editForm.controls.latitude.disable();
      this.editForm.controls.portFacility.enable();
    }
    else {
      this.editForm.controls.portFacility.setValue('');
      this.editForm.controls.latitude.setValue('');
      this.editForm.controls.longitude.setValue('');
      this.editForm.controls.portFacility.disable();
      this.editForm.controls.longitude.enable();
      this.editForm.controls.latitude.enable();
    }
  }


  selectElnot(event: any) {
    if (event) {
      this.elnotReadOnly = false;
    } else {
      this.elnotReadOnly = true;
    }
    // Find the selected option object in the elnotOptions array
    const selectedElnot = this.elnotOptions.find(option => option.el_not_code === event.option.value);

    const el_not = this.editForm.get('el_not');
    if (el_not) {
      el_not.patchValue(selectedElnot.el_not_code);
    }
  }

  selectFreqType(freqType) {
    // Find the selected option object in the elnotOptions array
    const selectedFreqType = this.freqTypeOptions.find(option => option.freq_type_id === freqType);

    const freq_type = this.editForm.get('freq');
    if (freq_type) {
      freq_type.patchValue(selectedFreqType.freq_type_description);
    }
    const freq_type_id = this.editForm.get('freq_type_id');
    if (freq_type_id) {
      freq_type_id.patchValue(freqType);
    }
  }


  selectFunc(funcType) {
    // Find the selected option object in the elnotOptions array
    const selectedFunc = this.funcTypeOptions.find(option => option.elint_function_id === funcType);

    const func_type = this.editForm.get('func');
    if (func_type) {
      func_type.patchValue(selectedFunc.elint_function_code);
    }
    const elint_function_id = this.editForm.get('elint_function_id');
    if (elint_function_id) {
      elint_function_id.patchValue(funcType);
    }
  }

  selectScanType(event: any) {
    // Find the selected option object in the elnotOptions array
    const selectedScanType = this.scanTypeOptions.find(option => option.scan_type_id === event.option.value);

    const scan_type = this.editForm.get('scan_type');
    if (scan_type) {
      scan_type.patchValue(selectedScanType.scan_type_code);
    }

    const scan_type_id = this.editForm.get('scan_type_id');
    if (scan_type_id) {
      scan_type_id.patchValue(selectedScanType.scan_type_code + " (" + selectedScanType.scan_type_description + ")");
    }
  }

  cancel() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  getClassification(classDescription): string {
    return classDescription.split('/')[0];
  }

  getSciControls(classDescription): string[] {
    return classDescription.split('/')[1].split(',').filter(Boolean);
  }

  getFgiControls(classDescription): string[] {
    return classDescription.split('/')[2].split(',').filter(Boolean);
  }

  getOtherControls(classDescription): string[] {
    return classDescription.split('/')[3].split(',').filter(Boolean);
  }

  getDisseminations(classDescription): string[] {
    return classDescription.split('/')[4].split(',').filter(Boolean);
  }
}
