<app-classification-bar class="top-modal-classification-bar"></app-classification-bar>
<app-fdtm-modal-header [headerString]="headerString"></app-fdtm-modal-header>
<form #form="ngForm">
  <div *ngIf="status == 'in progress'">
    <mat-progress-bar
        class="nob-margin"
        [color]="'primary'"
        [mode]="'indeterminate'"
    >
    </mat-progress-bar>
  </div>
  <div class="textarea-container">
    <textarea readonly>{{ reportPreviewText }}</textarea>
  </div>
  <div *ngIf="featureTypeDefinition">
    <div *ngFor="let section of featureTypeDefinition.Sections">
      <ng-container *ngIf="section.name === 'footerFields'">
        <mat-grid-list cols="2" rowHeight="80px">
          <ng-container *ngFor="let field of section.Fields">
            <mat-grid-tile *ngIf="!(field.uiWidget === null || field.uiWidget === 'null')">
              <mat-form-field class="input-90-width">
                <mat-label>{{field.uiLabel}}</mat-label>
                <textarea matInput [(ngModel)]="field.value" [name]="field.name"></textarea>
              </mat-form-field>
            </mat-grid-tile>
          </ng-container>
        </mat-grid-list>
      </ng-container>
    </div>
  </div>

  <div mat-dialog-actions>
    <button color="primary" mat-raised-button [disabled]="!form.valid" (click)="submit()">Download</button>
    <button color="accent" mat-raised-button [disabled]="!form.valid" (click)="regenPreview()">Regenerate Preview</button>
    <button mat-raised-button (click)="cancel()">Cancel</button>
  </div>

</form>
<app-classification-bar class="bottom-modal-classification-bar"></app-classification-bar>
