import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'commandFilter'
})
export class CommandFilterPipe implements PipeTransform {

  transform(value: any, commands: any[]): any {
    if (!value) {
      return
    }
    return value.filter(function(element) {
      return element.noSelection || commands.includes(element.commandId)
    })
    //return null;
  }

}
