import { Inject } from '@angular/core';
import {AppInitService} from '../../../../config/init.service'
import { Injectable } from "@angular/core";


@Injectable()
export class APIConfig {
    constructor(@Inject(AppInitService) public configService : AppInitService) {

    }
    public TARGET_URL = this.configService.getConfig().API_BASE_URL;
    NAMESPACE: '$(TARGET_URL}/roles';
}
