import { Command } from '@models/command.model';
import { CommandApiService } from '@services/api-services/command-api.service';
import { Injectable } from '@angular/core';
import { JsonConverter } from '@services/global/json-converter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CommandService {
  private jsonConvert: JsonConverter;

  constructor(private commandApiService: CommandApiService) {
    this.jsonConvert = new JsonConverter();
  }

  public getAll(params = {}): Observable<Command[]> {
    return this.commandApiService.getAllCommands(params)
      .pipe(
        map((data) => {
          return this.jsonConvert.deserializeArray(data, Command);
        })
      );
  }

  public getCommandByCommandId(commandId: string): Observable<Command> {
    return this.commandApiService.getCommandByCommandId(commandId)
      .pipe(
        map((data) => {
          return this.jsonConvert.deserializeObject(data, Command);
        })
      );
  }

  public update(command: Command): Observable<Command> {
    return this.commandApiService.updateCommand(this.jsonConvert.serializeObject(command))
      .pipe(
        map((data) => {
          return this.jsonConvert.deserializeObject(data, Command);
        })
      );
  }

  public create(command: Command): Observable<Command> {
    return this.commandApiService.createCommand(this.jsonConvert.serializeObject(command))
      .pipe(
        map((data) => {
          return this.jsonConvert.deserializeObject(data, Command);
        })
      );
  }

  public delete(command: Command): Observable<Command> {
    return this.commandApiService.deleteCommand(this.jsonConvert.serializeObject(command))
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  /** LOGO Servies **/
  public getLogo(id: string): Observable<any> {
    return this.commandApiService.getLogo(id)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  public getLogoUrl(command: Command){
    if(command.logoPath === "" || command.logoPath === null){
      return "assets/images/Blank.png"
    }else{
      return this.commandApiService.getLogoURL(command.commandId)
    }
  }
  public createLogo(imageFile: any, id: string): Observable<any> {
    return this.commandApiService.createLogo(imageFile, id)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  public updateLogo(imageFile: any, id: string): Observable<any> {
    return this.commandApiService.updateLogo(imageFile, id)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  public deleteLogo(id: string): Observable<any> {
    return this.commandApiService.deleteLogo(id)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
}
