import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from '@services/api-services/api.config';

@Injectable()
export class LocationApiService {
  SERVER_URL;

  constructor(
    private http: HttpClient, apiConfig: APIConfig) {
    this.SERVER_URL = apiConfig.TARGET_URL;
  }

  public getAllLocations(queryParams: Object): Observable<any> {
    let httpParams = new HttpParams();

    for (const key in queryParams) {
      if (queryParams.hasOwnProperty(key)) {
        httpParams = httpParams.set(key, queryParams[key]);
      }
    }

    return this.http.get(`${this.SERVER_URL}/locations`, { params: httpParams });
  }

  public getLocationByLocationId(shipLocationId: string): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/locations/${shipLocationId}`);
  }

  public getLocationWithVersions(shipLocationId: string): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/locations/${shipLocationId}/version_histories`);
  }

  public updateLocation(location: any): Observable<any> {
    return this.http.put(`${this.SERVER_URL}/locations/${location.ship_location_id}`, location);
  }

  public bulkUpdateLocations(shipLocationIds: string[], changeset: Object): Observable<any> {
    return this.http.put(`${this.SERVER_URL}/locations`,
      { ship_location_ids: shipLocationIds, changes: changeset });
  }

  public createLocation(location: any): Observable<any> {
    return this.http.post(`${this.SERVER_URL}/locations`, location);
  }

  public deleteLocation(location: any): Observable<any> {
    // Pass the Location in the body so that information can be captured (rationale)
    return this.http.delete(
      `${this.SERVER_URL}/locations/${location.ship_location_id}`, { body: location });
  }

  public bulkDeleteLocations(shipLocationIds: string[], rationale: string): Observable<any> {
    return this.http.delete(`${this.SERVER_URL}/locations`,
      { body: { ship_location_ids: shipLocationIds, rationale: rationale }});
  }
}
