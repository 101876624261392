import {JsonObject, JsonProperty, Any} from 'json2typescript';
import { DateConverter } from '../services/global/date-converter';
import { BlueUnit } from '@models/blue.model';

@JsonObject('BlueLocation')
export class BlueLocation {
  @JsonProperty('blue_location_id', String, true)
  blueLocationId: string = undefined;
  @JsonProperty('blue_unit_id', String, true)
  blueUnitId: string = undefined;
  @JsonProperty('blue_name', String, true)
  blueUnitName: string = undefined;
  @JsonProperty('blue_unit', BlueUnit, true)
  blueUnit: BlueUnit = undefined;
  @JsonProperty('latitude', String, true)
  latitude: string = undefined;
  @JsonProperty('longitude', String, true)
  longitude: string = undefined;
  @JsonProperty('dec_lat', String, true)
  decLat: string = undefined;
  @JsonProperty('dec_lon', String, true)
  dec_lon: string = undefined;
  @JsonProperty('date_time_ts', DateConverter, true)
  dateTime: Date = undefined;
  @JsonProperty('course', String, true)
  course: string = undefined;
  @JsonProperty('speed', String, true)
  speed: string = undefined;
  @JsonProperty('track_num', String, true)
  trackNum: string = undefined;
  @JsonProperty('uid', String, true)
  uid: string = undefined;
  @JsonProperty('legacy_id', String, true)
  legacyId: Number = undefined;
  @JsonProperty('precedence', String, true)
  precedence: BigInteger = undefined;
  @JsonProperty('created_by', String, true)
  createdBy: string = undefined;
  @JsonProperty('create_ts', DateConverter, true)
  dateCreated: Date = undefined;

}
