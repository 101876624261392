import { FgiService } from '@services/data-services/fgi.service';
import { FormBuilder } from '@angular/forms';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { EditModalBaseComponent } from '@fom-module/base-classes/edit-modal-base.component';
import { LookUpService } from '@services/data-services/lookup.service';
import { MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { User } from '@models/user.model';
import { CurrentUserService } from '@services/current-user-service';
import { CommandHierarchy } from '@models/command-hierarchy.model';
import { FriendlyErrorService } from '@services/friendly-error.service';

@Component({
  selector: 'app-edit-fgi-modal',
  templateUrl: './edit-fgi-modal.component.html',
  styleUrls: ['./edit-fgi-modal.component.css']
})
export class EditFgiModalComponent extends EditModalBaseComponent implements OnInit {
  
  constructor(public fgiService: FgiService,
    public lookUpService: LookUpService,
    public formBuilder: FormBuilder,
    private friendlyErrorService: FriendlyErrorService,
    public dialogRef: MatDialogRef<EditFgiModalComponent>,
    private currentUserService: CurrentUserService) {
    super(fgiService, dialogRef);
  }

  ngOnInit() {
    this.headerString = this.isNew ? 'Add FGI' : this.isDelete ? 'Delete FGI' : 'Edit FGI'
    this.editForm = this.formBuilder.group({
      commandId: [{ value: this.entity.commandId, disabled: this.isDelete }],
      name: [{ value: this.entity.name, disabled: this.isDelete }]
    });
  }
  localDelete() {
    this.fgiService.delete(this.entity).pipe(take(1)).subscribe(res => {
      this.dialogRef.close(res)
    }, err => {
      this.friendlyErrorService.processError(err);
    });
  }
}
