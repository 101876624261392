<app-classification-bar class="top-modal-classification-bar"></app-classification-bar>
<form [formGroup]="loginForm">
  <app-fdtm-modal-header [headerString]="'FDT-M User Login'"></app-fdtm-modal-header>
  <div *ngIf="authServiceURL && authServiceURL.length > 0">
    <div class="info-text">Login with DoD Certificate</div>
    <br>
    <div>
      <button mat-raised-button (click)="loginWithCert()" color="primary" class="full-width-button" type="button">Login with Certificate</button>
    </div>
    <br>
    <hr>
  </div>
  <div class="info-text">Login with Username and Password</div>
  <div>
    <mat-form-field class="input-full-width">
      <input value="{{loginForm.controls?.username.value}}" matInput autocomplete="off"
        placeholder="Username" required formControlName="username">
    </mat-form-field>
    <mat-form-field class="input-full-width">
      <input value="{{loginForm.controls?.password.value}}" matInput type="password" placeholder="Password" required formControlName="password">
    </mat-form-field>
    <br>
    <button [disabled]="!loginForm?.controls?.username?.value || !loginForm?.controls?.password?.value"
      mat-raised-button color="primary" class="full-width-button" (click)="handleLoginClick()">Login</button>
    <br>
  </div>
</form>
<app-classification-bar class="bottom-modal-classification-bar"></app-classification-bar>
