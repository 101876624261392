import { Location } from '@models/location.model';
import { LocationApiService } from './../api-services/location-api.service';
import { Injectable } from '@angular/core';
import { JsonConverter } from '../global/json-converter';
import { Observable, empty } from 'rxjs';
import { map, catchError } from 'rxjs/operators';


@Injectable()
export class LocationService {
    private jsonConvert: JsonConverter;

    constructor(private locationApiService: LocationApiService) {
        this.jsonConvert = new JsonConverter();
    }

    // Add withCount param to handle paged requests without breaking old requests (such as in unit update)
    public getAll(queryParams: Object, withCount: boolean = false): Observable<any> {
        return this.locationApiService.getAllLocations(queryParams)
            .pipe(
                map((data) => {
                    // If withCount include the count with the results, else just return the array.
                    if (withCount) {
                        return {
                            count: data.count,
                            locations: this.jsonConvert.deserializeArray(data.rows, Location)
                        };
                    } else {
                        return this.jsonConvert.deserializeArray(data.rows, Location);
                    }
                })
            );
    }

    public getLocationByLocationId(locationId): Observable<Location> {
        return this.locationApiService.getLocationByLocationId(locationId)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Location);
                })
            );
    }

    public getLocationWithVersions(locationId): Observable<Location> {
        return this.locationApiService.getLocationWithVersions(locationId)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Location);
                })
            );
    }

    public update(location: Location): Observable<Location> {
        return this.locationApiService.updateLocation(this.jsonConvert.serializeObject(location))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Location);
                })
            );
    }

    public bulkUpdate(shipLocationIds: string[], changeset: Object): Observable<any> {
        return this.locationApiService.bulkUpdateLocations(shipLocationIds, changeset)
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    public create(location: Location): Observable<Location> {
        return this.locationApiService.createLocation(this.jsonConvert.serializeObject(location))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Location);
                })
            );
    }

    public delete(location: Location): Observable<Location> {
        return this.locationApiService.deleteLocation(this.jsonConvert.serializeObject(location))
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    public bulkDelete(shipLocationIds: string[], rationale: string): Observable<any> {
        return this.locationApiService.bulkDeleteLocations(shipLocationIds, rationale)
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }
}
