<div class="admin-work-area">
  <div fxLayout fxLayoutAlign="center center" class="simple-grid-title">
    <span class="grid-title">{{initService.getConfig().titlePrefix}} FDT-M Geo-References</span>
    <!-- Add Unit Floating Action Button -->
    <button mat-mini-fab class="add-button" (click)="add()">
      <img class="light-icon" src="./assets/icons/ic_add_white_24px.svg" matTooltip="Add Geo-Reference" />
    </button>
  </div>
  <div fxLayout fxLayoutAlign="center center">
    <mat-form-field fxFlex="30%">
      <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Filter by GeoRef Name or Fleet Area">
    </mat-form-field>
  </div>
  <div class="mat-elevation-z8">
    <div fxLayout class="table-actions">
      <div class="export-actions">
        <button mat-raised-button [disabled]="!readyForExport" (click)="exportToExcel()">
          <mat-icon>file_download</mat-icon> Export to Excel
        </button>
      </div>

      <!-- Paginator -->
      <mat-paginator fxFlexOffset="auto" [pageSizeOptions]="[10, 20, 50, 100, 1000]" [pageSize]="20" [length]="dataSource?.data?.length" showFirstLastButtons></mat-paginator>
    </div>
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="command.name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Command </th>
          <td mat-cell *matCellDef="let element"> {{getCommand(element)}} </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Reference Name</th>
        <td mat-cell *matCellDef="let element">{{element.name}}</td>
      </ng-container>

      <ng-container matColumnDef="area">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Fleet Area</th>
        <td mat-cell *matCellDef="let element">{{element.area}}</td>
      </ng-container>

      <ng-container matColumnDef="latitude">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Latitude</th>
        <td mat-cell *matCellDef="let element">{{element.latitude}}</td>
      </ng-container>

      <ng-container matColumnDef="longitude">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Longitude</th>
        <td mat-cell *matCellDef="let element">{{element.longitude}}</td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef>
          <div>
            Edit
          </div>
        </th>
        <td mat-cell *matCellDef="let row">
          <button title="Edit Geo-Reference" mat-button *ngIf="row.canEdit" class="delete-button" [disabled]="!row.canEdit"
          (click)="$event.stopPropagation()" (click)="edit(row)">
          <mat-icon>edit</mat-icon>
        </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef>
          <div>
            Delete
          </div>
        </th>
        <td mat-cell *matCellDef="let row">
          <button title="Delete Geo-Reference" mat-button *ngIf="row.canEdit" class="delete-button" (click)="$event.stopPropagation()" (click)="handleDelete(row)">
            <img src="./assets/icons/ic_delete_gray_24px.svg" />
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
