import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from './api.config';

@Injectable()
export class ControlApiService {
  SERVER_URL;
  RESOURCE_URI = 'scis';


  constructor(
    private http: HttpClient, apiConfig: APIConfig) {
    this.SERVER_URL = apiConfig.TARGET_URL;
  }

  public getAllControls(): Observable<any> {
    return this.http.get(
      `${this.SERVER_URL}/${this.RESOURCE_URI}`);
  }

  public getControlByControlId(id: string): Observable<any> {
    return this.http.get(
      `${this.SERVER_URL}/${this.RESOURCE_URI}/${id}`);
  }

  public updateControl(item: any): Observable<any> {
    return this.http.put(
      `${this.SERVER_URL}/${this.RESOURCE_URI}/${item.sci_control_id}`, item);
  }

  public createControl(item: any): Observable<any> {
    return this.http.post(
      `${this.SERVER_URL}/${this.RESOURCE_URI}`, item);
  }

  public deleteControl(item: any): Observable<any> {
    return this.http.delete(
      `${this.SERVER_URL}/${this.RESOURCE_URI}/${item.sci_control_id}`);
  }
}
