import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from '@services/data-services/user.service';
import { AuthenticationService } from '@services/data-services/authentication.service';
import { User } from '@models/user.model';
import { Command } from '@models/command.model';
import { JsonConverter } from '@services/global/json-converter';
import { take } from 'rxjs/operators';
import { LookUpService } from '@services/data-services/lookup.service';
import { CommandHierarchyService } from '@services/data-services/command-hierarchy.service';
import { GlobalStore } from '@services/global/message-bus/global-store.model';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { UtilityService } from '@services/utility.service';

@Component({
  selector: 'app-edit-user-settings',
  templateUrl: './edit-user-settings.component.html',
  styleUrls: ['./edit-user-settings.component.css']
})
export class EditUserSettingsComponent implements OnInit {
  private jsonConvert: JsonConverter;
  user: User;
  changePassword = false;
  commands: [Command];
  commandGraph: [];
  headerString: string;

  constructor(public dialogRef: MatDialogRef<EditUserSettingsComponent>,
    private lookupService: LookUpService,
    private userService: UserService,
    private messageBusService: MessageBusService,
    private commandHierarchyService: CommandHierarchyService,
    private utilityService: UtilityService,
    private authService: AuthenticationService) {
    this.jsonConvert = new JsonConverter();
  }
  // from authentication.service.ts
  // user.JFMCCs = graph
  // user.currentJFMCC = graph.length ? graph[0] : null
  // sessionStorage.setItem('user', JSON.stringify(user));

  ngOnInit() {
    this.user = this.jsonConvert.deserializeObject(JSON.parse(sessionStorage.getItem('user')), User);
    this.headerString = `User Settings for ${this.user.firstName} ${this.user.lastName}`
    Promise.resolve().then(() => {
      this.lookupService.getLookupByType(Command, true).subscribe(result => {
        this.commands = result;
      });

      // get the command graph in case the JFMCCs become invalid
      this.commandGraph = [];
      this.commandHierarchyService.getAll({ sort: 'version_num', limit: 1 }).pipe(take(1)).subscribe(json => {
        if (json && Array.isArray(json) && json.length) {
          this.commandGraph = JSON.parse(json[0].treeObject);
        }
      });
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  update() {
    this.userService.update(this.user).pipe(take(1)).subscribe(result => {
      // ok, so the selected Command ngModel is bound to the user.commandId, but there is also the
      // important user.command object. Need to set it accordingly
      const found = this.commands.find(x => x.commandId === result.commandId);
      if (found) {
        result.command = found;
      }
      const JFMCCs = this.jfmccsForCommand(result.commandId);
      result.JFMCCs = JFMCCs;
      result.currentJFMCC = JFMCCs.length ? JFMCCs[0] : JFMCCs.find(x => x.commandId === result.commandId)
      // shove the user back into session storage
      sessionStorage.setItem('user', JSON.stringify(this.jsonConvert.serializeObject(result)));
      const storeData = {
        isLoggedIn: true,
        currentUserId: this.user.userId,
        currentUser: result
      };
      this.messageBusService.updateStore(new GlobalStore(storeData));

      if (this.changePassword) {
        this.dialogRef.close('User Setting and Password Updated.');
      } else {
        this.dialogRef.close('User Settings Updated.');
      }
    });
  }

  jfmccsForCommand(commandId) {
    let JFMCCs = []
    if (this.commandGraph && Array.isArray(this.commandGraph) && this.commandGraph.length) {
      this.commandGraph.forEach(unified => {
        (unified as any).children.forEach(jfmcc => {
          if (commandId == jfmcc.commandId) {
            JFMCCs.push(jfmcc)
          }
          jfmcc.children.forEach(command => {
            if (command.commandId == commandId) {
              JFMCCs.push(jfmcc)
            }
          });
        });
      });
    }
    return JFMCCs;
  }

  // stub in function to verify rules on the client side
  passwordRules() {
    if (this.user.currentPassword === '') {
      return 'Enter existing password';
    }

    if (this.user.password && !this.utilityService.isComplexEnough(this.user.password)) {
      return 'New password does not meet complexity requirements';
    }

    if (this.user.password !== this.user.passwordConfirmation) {
      return 'New passwords must match';
    }

    return '';
  }

  isDisabled() {
    return !this.user.isSystemAdmin()
  }
}
