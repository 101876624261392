import { CdkDrag, CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Command } from '@models/command.model';
import { Owner } from '@models/owner.model';
import { LookUpService } from '@services/data-services/lookup.service';
import { PicklistPrefService } from '@services/data-services/picklist-pref.service';
import { OwnerTypeService } from '@services/data-services/owner-type.service';
import { OwnerService } from '@services/data-services/owner.service';
import { ignoreElements, take } from 'rxjs/operators';
import * as cloneDeep from 'lodash/cloneDeep';
import { Country } from '@models/country.model';
import { Port } from '@models/port.model';
import { LookupFilter } from '@models/lookup-filter';
import { UtilityService } from '@services/utility.service';
import { SortingService } from '@services/sorting.service';
import { User } from '@models/user.model';
import { PicklistTypeService } from '@services/data-services/picklist-type.service';
import { PicklistPreferences } from '@models/picklist-preferences.model';
import * as picklistModels from '@models/picklistExport';
import { CommandHierarchyService } from '@services/data-services/command-hierarchy.service';
import { JsonConvert } from 'json2typescript';
import { CurrentUserService } from '@services/current-user-service';
import { CountryService } from '@services/data-services/country.service';
import { FleetService } from '@services/data-services/fleet.service';
import { AppInitService } from '../../../config/init.service';
import { UserNotificationService } from '@services/notification.service';
import { ReportingService } from '@services/data-services/reporting.service';
import { MatSort, Sort } from '@angular/material/sort';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { LookoupValuesMapService } from '@services/lookup-values-map.service';

type NewType = OwnerService;

@Component({
  selector: 'app-lookup-maintenance',
  templateUrl: './lookup-maintenance.component.html',
  styleUrls: ['./lookup-maintenance.component.css']
})
export class LookupMaintenanceComponent implements OnInit {
  @ViewChild('dropTable') dropTable: MatTable<any>;
  private jsonConvert: JsonConvert = new JsonConvert();
  @ViewChildren(CdkDrag) draggables!: QueryList<CdkDrag>;
  dragDisabled = false;

  owner: Owner;
  lookup: any;
  owners: Owner[];
  ownerTypes: any[];
  picklists: any[];
  picklist: any;
  user: User;
  JFMCCs: any[];
  fleets: any[];

  @ViewChild(MatSort) sort: MatSort;
  dataSource = new MatTableDataSource<object>();
  entities: Array<any>;

  isDirty = false;
  allColumns: string[] = [];

  previewItem: any;

  //excel export
  status: string = 'ready';
  subscription: any;

  // track the filterValue to prevent saving
  filterValue = '';

  // if the lookup needs country flag but only has name, need to get country_id from list
  countryMap = {};

  // picklist preferences brought back by API call
  picklistPreferences: PicklistPreferences[] = [];

  // toggle to show disabled lookups
  showDisabled = false;

  lookups = this.lookupValuesMapService.getLookupMap();

  constructor(private lookupService: LookUpService,
    private picklistTypeService: PicklistTypeService,
    private picklistPrefService: PicklistPrefService,
    private ownerTypeService: OwnerTypeService,
    private ownerService: OwnerService,
    private commandHierarchyService: CommandHierarchyService,
    private dialog: MatDialog,
    public utilities: UtilityService,
    private currentUserService: CurrentUserService,
    private countryService: CountryService,
    private fleetService: FleetService,
    private sorter: SortingService,
    public initService: AppInitService,
    private notifier: UserNotificationService,
    private reportingService: ReportingService,
    private lookupValuesMapService: LookoupValuesMapService) { }

  ngOnInit() {
    this.user = this.currentUserService.getCurrentUser();
    // to get the flags...
    this.loadCountryMap()
    // hack to get fleets for mapping
    this.loadFleets()

    this.dataSource.filterPredicate = this.createFilterPredicate.bind(this);
    this.dataSource.sortingDataAccessor = this.sortDataAccessor;

    // gotta get the instance methods
    //get the owners (commands, aors) that have a say in the lookup values being displayed
    this.ownerTypeService.getAll().pipe(take(1)).subscribe(ownerTypes => {
      this.ownerTypes = ownerTypes
      //get the owners (commands, aors) that have a say in the lookup values being displayed

      // this is nested so that i can get set an owner_type_id for the dummy owner (command)
      this.ownerService.getAll().pipe(take(1)).subscribe(owners => {
        // one more nested call to filter for just the owners that are JFMCCs
        this.commandHierarchyService.getAll({ sort: 'version_num', limit: 1 }).pipe(take(1)).subscribe(json => {
          if (json && Array.isArray(json) && json.length) {
            const commandGraph = JSON.parse(json[0].treeObject)
            this.JFMCCs = []
            commandGraph.forEach(unified => {
              (unified as any).children.forEach(jfmcc => {
                this.JFMCCs.push(jfmcc)
              })
            })
            owners = owners.filter(item => {
              return this.JFMCCs.find(x => item.ownerId == x.commandId)
            })
          }
          let owner = new Owner()
          // we have a replacement of owner_id on the API
          owner.ownerId = null
          owner.ownerName = 'Global (default)'
          owner.ownerTypeId = ownerTypes[0].ownerTypeId
          owners.unshift(owner)
          this.owners = owners
        })
      })
    })

    // merge the lookups objects in the above code with the picklist types
    this.picklistTypeService.getAll().pipe(take(1)).subscribe(results => {
      this.picklists = results
      this.lookups.map(item => {
        const found = this.picklists.find(x => {
          return x.tableName == item.tableName
        })
        if (found) {
          (item as any).picklistType = found
        }
      })
    })
  }

  loadCountryMap() {
    // get all the countries and create a map
    this.countryService.getAll().pipe(take(1)).subscribe(countries => {
      countries.forEach(c => {
        this.countryMap[c.name] = c;
      });
    })
  }

  loadFleets() {
    // get all the fleets for lookup pairing
    this.fleetService.getAll().pipe(take(1)).subscribe(fleets => {
     this.fleets = fleets;
      });
  }

  // add, edit, and delete will use common edit modals with different options
  // (isNew) to determine if adding or editing
  // (isDelete) if deleting the item
  delete(item) {
    this.edit(item, true)
  }

  add() {
    // gotta make sure the lookups are loaded, add is enabled early, just when the type is selected
    if (this.lookup) {
      this.lookupService.getLookupByType(this.lookup.type, true).pipe(take(1)).subscribe(entities => {
        this.entities = entities
        this.edit(null)
      })
    }
  }

  edit(item, isDelete = false) {
    let isNew = false
    if (!item) {
      item = new this.lookup.type()
      isNew = true
    }
    // Countries can have three buttons, make it wider
    const dialogRef = this.dialog.open(this.lookup.editor, {
      width: this.lookup.display === 'Countries' ? '420px' : '320px',
    });
    (dialogRef.componentInstance as any).isNew = isNew;
    (dialogRef.componentInstance as any).entity = item;
    (dialogRef.componentInstance as any).isDelete = isDelete;
    (dialogRef.componentInstance as any).items = this.entities;

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      // does this need to be synchronous? angular digest, change detection should track?
      if (item instanceof Country) {
        this.loadCountryMap()
      }
      this.selectionChanged(null) // force the reload
    });
  }

  // allow the user to filter the list, but each lookup type needs a specific
  // filter predicate to ensure the proper columns are filtered
  public doFilter = (value: string) => {
    // When filtering disable drag and drop
    if (value.length > 0){
      this.toggleDraggables(false);
    } else {
      this.toggleDraggables(true);
    }

    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  clearFilter() {
    // Re-enable drag when filter is cleared
    this.toggleDraggables(false);

    this.filterValue = '';
    this.dataSource.filter = '';
  }

  lookupChanged(_event) {
    this.clearFilter();

    this.dataSource.data = [];

    if (this.lookup) {
      // load the values for the 'add/edit' button so that duplicates can be detected by the modal
      // this list is not used in the lookup maintenance preferences
      this.lookupService.getLookupByType(this.lookup.type, true).pipe(take(1)).subscribe(results => {
        this.entities = results
        // and... if the user has selected a owner(like command) then load the grid with preferences for it
        if (this.owner) {
          this.selectionChanged()
        }
      })
    }
  }

  selectionChanged(_event = null) {
    this.clearFilter();

    if (this.lookup && this.owner) {
      this.isDirty = false
      // get em all in order
      this.allColumns = ['edit', 'isDisabled'].concat(this.lookup.attributes.map(e => e.property)).concat(['delete'])

      this.lookupService.getLookupByType(this.lookup.type, true).pipe(take(1)).subscribe(async entities => {
        // find the picklist_type_id that we are querying for
        // only get picklist preferences that belong to the selected owner and the lookup type we are working with
        this.picklistPreferences = await this.picklistPrefService.getAll({ owner_id: this.owner.ownerId, picklist_type_id: this.lookup?.picklistType?.picklistTypeId}).pipe(take(1)).toPromise();

        if (this.lookup.type == Port) {
          entities.map(item => {
            const found = this.JFMCCs.find(x => x.commandId == item.commandId)
            if (found) {
              item.commandName = found.name
            }
          })
          //same for fleet
          entities.map(item => {
            const found = this.fleets.find(x => x.fleetId == item.fleetId)
            if (found) {
              item.fleetName = found.fleetCode
            }
          })
        }

        this.entities = entities;
        this.clearSort();
        this.sortData(this.sort);
      })
    }
  }

  sortDataAccessor(item: any, property: any): any {
    const value = item[property];

    return typeof value === 'string' ? value.toUpperCase() : value;
  }

  createFilterPredicate(data: any, filter: string) {
    let fullString = ''
    this.lookup.attributes.forEach(attribute => {
      fullString += data[attribute.property] ? data[attribute.property].toString().trim().toLowerCase() : ''
    });

    return fullString.indexOf(filter) != -1;
  }

  // a common function that can be called from this, the base class, or from classes implementing this
  // some (most) edit lists will sort the entities and then call this to setup the dataSource
  setDataSource(entities) {
    this.entities = entities;
    this.dataSource.data = entities;
  }

  save() {
    let prefs: PicklistPreferences[] = []
    let i = 0

    this.dataSource.data.forEach(element => {
      prefs.push({
        // if null then will be inserted on the upsert
        picklistPreferenceId: (element as any).picklistPreference ? (element as any).picklistPreference.picklistPreferenceId : null,
        // crash if null....
        picklistTypeId: this.lookup.picklistType ? this.lookup.picklistType.picklistTypeId : null,
        // owner type.... ? tbd
        ownerTypeId: this.owner.ownerTypeId, // maybe better
        ownerId: this.owner.ownerId,
        sourcePicklistId: element[this.lookup.lookupId],
        sortSequence: i++,
        createdBy: this.user.userId,
        disabled: (element as any).isDisabled
      })
    })

    this.picklistPrefService.savePreferences(prefs).pipe(take(1)).subscribe(entities => {
      this.selectionChanged(null)
    })
  }

  cancel() {
    this.selectionChanged(null)
  }

  // move the item and reset the dataSource
  drop(event: CdkDragDrop<any[]>) {
    this.isDirty = true;
    moveItemInArray(this.dataSource.data, event.previousIndex, event.currentIndex);
    this.dataSource.data = cloneDeep(this.dataSource.data)
  }

  getFlag(country) {
    const countryObject = this.countryMap[country]
    return countryObject ? this.countryService.getFlagUrl(countryObject) : null
  }

  public async getLookupExcel(lookupType) {
    this.status = 'in progress'
    this.subscription = this.reportingService.getLookupExcel(lookupType).pipe(take(1)).subscribe(excel => {
      this.subscription.unsubscribe()
      this.status = 'complete'
      this.notifier.showSuccess('Download Complete', 3000)
      this.downloadLookup(excel);
    }, err => {
      // we caught the error only to stop the progress bar
      this.subscription.unsubscribe()
      this.status = 'complete'
      // we still want to throw it to the global ErrorHandler
      throw (err)
    });
  }

  downloadLookup(excel: any) {
    const fileName = 'Lookup Report.xlsx';
    const file = new Blob([excel.body]);

    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(file); // xhr.response is a blob
    a.style.display = 'none';
    a.download = fileName
    document.body.appendChild(a);
    a.click()
  }
  
  clearSort(): void {
    this.sort.active = null;
    this.sort.direction = '';
    this.sort._stateChanges.next();
  }

  sortData(sort: Sort): void {
    // Disable saving preferences when sorting...
    this.isDirty = false;

    this.dataSource.sort = this.sort;

    if (!sort.active || sort.direction === '') {
      // Re-enable drag and drop to allow for users to change preferences.
      this.toggleDraggables(true);

      // when sort is cleared, default to picklist pref/default sorting
      this.entities = this.sorter.sortList(this.entities, this.picklistPreferences, this.lookup.defaultSort, this.lookup.lookupId, this.showDisabled);
      this.dataSource.data = this.entities;
    } else {
      // Disable drag and drop while sorting by column header, otherwise drag and drop create some confusion with
      // dragged elements being sorted immediately after drop.
      this.toggleDraggables(false);
    }
  }

  changeDraggables(enable: boolean) {
    this.dragDisabled = !enable;
    this.draggables.forEach(e => e.disabled = this.dragDisabled);
  }

  toggleDraggables(enable: boolean) {
    // Only enable draggables if no filter and no sorting.
    if (enable) {
      if (this.filterValue.length < 1 && (!this.sort.active || this.sort.direction === '')) {
        this.changeDraggables(enable);
      }
    } else {
      this.changeDraggables(enable)
    }
  }

  // Returns the tooltip message for the save button based on the current state.
  saveTooltip(): string {
    if (!this.lookup || !this.owner) {
      return 'No lookups are loaded, please select a Lookup and Owner to edit preferences.';
    }

    if (this.dragDisabled) {
      return 'Unable to save preferences while sorting or filtering data, please clear your sort and filter to edit preferences.';
    }

    if (this.isDirty) {
      return 'Save these preferences for owner';
    } else {
      return 'Preferences are unchanged, please make changes to the preferences usng drag and drop in order to save.';
    }
  }

  toggleDisabled(event: MatSlideToggleChange) {
    this.showDisabled = event.checked;
    this.selectionChanged(null);
  }
}
