import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'zulu1Pipe'
})

// pipe to display dates in DD MMM YYYY HH MMZ
export class Zulu1Pipe implements PipeTransform {

  months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

  transform(value: any, ...args: any[]): any {
    if (value) {
      let date = new Date(value) 
      return `${this.pad(date.getUTCDate())} ${this.months[date.getUTCMonth()]} ${date.getUTCFullYear()} ${this.pad(date.getUTCHours())}${this.pad(date.getUTCMinutes())}Z`
    }
    return ''
  }

  pad(num) {
    num = '0' + num
    if (num.length == 2) {
      return num;
    }
    return  num.slice(1);
  }

}
