  <mat-card class="form-card">
    <div fxLayout fxLayoutAlign="center center" class="simple-grid-title">
      <span class="grid-title">{{initService.getConfig().titlePrefix}} FDT-M PowerPoint Slides</span>
    </div>
    <div class="mat-elevation-z8">
  <mat-card-content fxLayout="row">
    <table mat-table [dataSource]="dataSource" matSort matSortStart="desc" fxFlex="80%" fxFlexOffset="10%">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? toggleAll() : null"
                        [checked]="hasAllSelected()"
                        [indeterminate]="selection.hasValue() && !hasAllSelected()">
            Select All
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(element) : null"
                        [checked]="selection.isSelected(element)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="slideName">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Slide Name </th>
        <td mat-cell *matCellDef="let element"> {{element.slideName}} </td>
      </ng-container>

      <ng-container matColumnDef="countryName">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Country Code </th>
        <td mat-cell *matCellDef="let element">
          <img [src]="getFlag(element.countryName)" height="16">&nbsp;
          {{element.countryName}}
        </td>
      </ng-container>

      <ng-container matColumnDef="countUnits">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> # of Units </th>
        <td mat-cell *matCellDef="let element"> {{element.countUnits}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        [ngClass]="{'invalid-file-type' : checkFileType(row)}" title="{{checkFileType(row)}}"
        (click)="this.selection.toggle(row)"></tr>
    </table>
  </mat-card-content>
  <mat-card-actions fxLayout="row" fxLayoutAlign="center">
    <button mat-raised-button color="primary" [disabled]="!hasSelection()" (click)="handleSubmit()">
      Generate Slides
    </button>
  </mat-card-actions>
  </div>
  </mat-card>
