<app-classification-bar class="top-modal-classification-bar"></app-classification-bar>
<app-fdtm-modal-header [headerString]="headerString"></app-fdtm-modal-header>

<div mat-dialog-content>
  <table class="unit-info-header">
    <tr>
      <td rowspan="3"><img height="74px" width="74px" src="assets/images/FDT-M.png" /></td>
      <td>{{entry.name}}</td>
    </tr>
    <tr>
      <td><span [ngClass]='entry.classification'>{{entry.classification}}</span></td>
    </tr>
    <tr>
      <td>Location Time: {{entry.locTimeTs | zulu1Pipe}}</td>
    </tr>
  </table>

  <div class="unit-latest-info" fxLayout="row" fxLayoutGap="5px">
    <div fxFlex="50%">
      <table class="unit-info">
        <tr>
          <td>Latitude</td>
          <td>{{entry.latitude}} ({{entry.dec_lat | number: '1.1-3'}})</td>
        </tr>
        <tr class="odd">
          <td>Longitude</td>
          <td>{{entry.longitude}} ({{entry.dec_lon | number: '1.1-3'}})</td>
        </tr>
        <tr>
          <td>Geo</td>
          <td>{{entry.geo}}</td>
        </tr>
        <tr class="odd">
          <td>SCONUM</td>
          <td>{{entry.sconum}}
          </td>
        </tr>
        <tr>
          <td>Source</td>
          <td>
              {{entry.source}}
              <mat-icon *ngIf="canEdit(entry)" [inline]="true" class="override-icon hyper" matTooltip="Override Source" (click)="$event.stopPropagation()" (click)="toggleOverrideSource()">
                edit
              </mat-icon>
          </td>
        </tr>
        <tr class="odd">
          <td>Originator</td>
          <td>
            {{entry.originator}}
              <mat-icon *ngIf="canEdit(entry)" [inline]="true" class="override-icon hyper" matTooltip="Override Originator" (click)="$event.stopPropagation()" (click)="toggleOverrideOriginator()">
                edit
              </mat-icon>
          </td>
        </tr>
        <tr class="odd">
          <td>Confidence</td>
          <td>
            {{entry.confidenceCode}}
              <mat-icon *ngIf="canEdit(entry)" [inline]="true" class="override-icon hyper" matTooltip="Override Confidence" (click)="$event.stopPropagation()" (click)="toggleOverrideConfidence()">
                edit
              </mat-icon>
          </td>
        </tr>
        <tr>
          <td>Category</td>
          <td>{{entry.unit?.category?.category}}</td>
        </tr>
        <tr class="odd">
          <td>Country</td>
          <td>{{entry.unit?.country?.name}}</td>
        </tr>
        <tr>
          <td>Fleet</td>
          <td>{{entry.unit?.fleet?.fleetCode}} ({{entry.unit?.fleet?.remarks}})</td>
        </tr>
        <tr class="odd">
          <td>Port</td>
          <td>{{entry.unit?.port?.name}}</td>
        </tr>
        <tr>
          <td>Ship Type</td>
          <td>{{entry.unit?.ship?.shipTypeName}}</td>
        </tr>
        <tr class="odd">
          <td>Threat</td>
          <td>{{entry.unit?.threat?.threatName}}
          </td>
        </tr>
        <tr>
          <td>Source Code</td>
          <td>{{entry.sourceCode}}</td>
        </tr>
        <tr class="odd">
          <td>GCCS ID</td>
          <td>{{entry.uid}}</td>
        </tr>
        <tr>
          <td>Force Code</td>
          <td>{{entry.forceCode}}</td>
        </tr>
        <tr class="odd">
          <td>KML Tracks</td>
          <td>
            <a *ngIf="!isUnMatched" class="link" href="javascript: void(0)" matTooltip="Download 1-day KML Track from NOB" (click)="tracks(entry, '1')">1-day</a>&nbsp;
            <a *ngIf="!isUnMatched" class="link" href="javascript: void(0)" matTooltip="Download 7-day KML Track from NOB" (click)="tracks(entry, '7')">7-day</a>&nbsp;
            <a *ngIf="!isUnMatched" class="link" href="javascript: void(0)" matTooltip="Download 14-day KML Track from NOB" (click)="tracks(entry, '14')">14-day</a>&nbsp;
            <a *ngIf="!isUnMatched" class="link" href="javascript: void(0)" matTooltip="Download 30-day KML Track from NOB" (click)="tracks(entry, '30')">30-day</a>&nbsp;
            <a class="link" href="javascript: void(0)" matTooltip="Download KML Track for this Message" (click)="getKML(entry)">From Message</a>&nbsp;
          </td>
        </tr>
        <tr class="odd">
          <td>Points in Message</td>
          <td>{{entry.points}}</td>
        </tr>
      </table>
    </div>

    <div fxFlex="50%">
      <table class="unit-info">
        <!-- <style>table {color:#ffffff;} .green {background-color: #7FFF00; color: black;} .yellow {background-color: #FFFF00; color: black;} .red {background-color: #FF0000}</style> -->  
        <tr>
          <td>Major Axis Bearing</td>
          <td>{{entry.majorAxisBearing}}</td>
        </tr>
        <tr class="odd">
          <td>Semi Major Axis Length</td>
          <td>{{entry.semiMajorAxisLength}}</td>
        </tr>
        <tr>
        <tr>
          <td>Semi Minor Axis Length</td>
          <td>{{entry.semiMinorAxisLength}}</td>
        </tr>
        <tr class="odd">
          <td>ELNOT</td>
          <td>{{entry.elint?.el_not}}</td>
        </tr>
        <tr>
          <td>Provider</td>
          <td>{{entry.elint?.provider}}</td>
        </tr>
        <tr class="odd">
          <td>Radio Frequency</td>
          <td>{{entry.elint?.freq}}</td>
        </tr>
        <tr>
          <td>Bandwidth</td>
          <td>{{entry.elint?.band}}</td>
        </tr>
        <tr class="odd">
          <td>Function</td>
          <td>{{entry.elint?.func}}</td>
        </tr>
        <tr>
          <td>Pulse Width</td>
          <td>{{entry.elint?.pulDur}}</td>
        </tr>
        <tr class="odd">
          <td>PRI</td>
          <td>{{entry.elint?.pri}}</td>
        </tr>
        <tr>
          <td>PRF</td>
          <td>{{entry.elint?.prf}}</td>
        </tr>
        <tr class="odd">
          <td>Scan Rate</td>
          <td>{{entry.elint?.scanRate}}</td>
        </tr>
        <tr>
          <td>Scan Measure</td>
          <td>{{entry.elint?.scanMeasure}}</td>
        </tr>
        <tr class="odd">
          <td>Scan Type</td>
          <td>{{entry.elint?.scanType}}</td>
        </tr>
        <tr>
          <td>TOI</td>
          <td>{{entry.elint?.toiTs}}</td>
        </tr>
        
        
      </table>
    </div>
  </div>
  
  <div style="height: 20px;"></div>
  <fieldset *ngIf="user.isSupervisor() && isUnMatched">
    <legend> Unmatched Unit Actions</legend>
    <div fxLayout="row" fxLayoutGap="10px">
      <form fxFlex="50%" [formGroup]="editForm">
        <div>
          <!-- !!!!!! LEAVE THIS: It's how to do autocomplete for just an otherName !!!!!! -->
          <!-- <mat-form-field class="unit-input-field">
                <input matInput type="text" formControlName="otherNameSearch" placeholder="Other Name" [matAutocomplete]="otherNameAutocomplete"
                  tabindex="1" matTooltip="{{ editForm.controls?.unitOtherName?.value | unitOtherNamePipe }}">
              </mat-form-field>
              <mat-autocomplete #otherNameAutocomplete="matAutocomplete" [displayWith]="displayUnitOtherName" (optionsScroll)="onUnitOtherNameOptionsScroll()">
                <mat-option *ngFor="let option of filteredOtherNames$ | async" [value]="option">
                  <span matTooltip="{{ option.otherName }}">
                    <img style="vertical-align: middle" height="20">&nbsp;
                  </span>
                  <span matTooltip="{{ option.otherName }}">{{ option.otherName }}</span>
                </mat-option>
                <mat-option *ngIf="isLoading" class="is-loading">
                  <mat-spinner diameter="30"></mat-spinner>
                </mat-option>
              </mat-autocomplete> -->
          <mat-form-field class="unit-input-field">
            <input matInput type="text" formControlName="unit" placeholder="Assign to Existing Unit"
              [matAutocomplete]="unitAutocomplete" matTooltip="{{editForm.controls?.unit?.value | unitNamePipe}}">
            <mat-error *ngIf="!editForm.controls?.unit?.value?.unitId">Find and select Unit to enable remaining fields</mat-error>
          </mat-form-field>
          <mat-autocomplete #unitAutocomplete="matAutocomplete" [displayWith]="displayUnit"
            (optionSelected)="handleUnitChange($event)" showPanel="true" (optionsScroll)="onUnitOptionsScroll()">
            <ng-container>
              <mat-option *ngFor="let option of filteredUnits$ | async" [value]="option" matTooltip="{{option.fullName()}}">
                <span matTooltip="{{option.country?.name}}">
                  <img style="vertical-align:middle;" [src]="utilities.getFlag(option)" height="20" />&nbsp;
                </span>
                <span>{{option.fullName()}}</span>
              </mat-option>
            </ng-container>
            <mat-option *ngIf="isLoading" class="is-loading">
              <mat-spinner diameter="30"></mat-spinner>
            </mat-option>
          </mat-autocomplete>
        </div>
      </form>

      <div fxFlex="50%" fxLayoutAlign="center center">
        <button mat-raised-button color="warn" (click)="add(entry)" class="add-unit">
          Add as New Unit
        </button>
        <!-- <button mat-mini-fab class="add-other-name" (click)="add(entry)">
          <img class="light-icon" src="./assets/icons/ic_add_white_24px.svg" matTooltip="Add Unit" />
        </button> -->
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="10px">
      <mat-checkbox class="example-margin" [(ngModel)]="saveOtherName">Save as Other Name</mat-checkbox>
      <div style="height: 40px;"></div>
    </div>
  </fieldset>

  <div fxLayout="row" fxLayoutGap="10px" *ngIf="entry.unit && !entry.unit.nobReport">
    <mat-checkbox [(ngModel)]="setUnderway">Set Unit Underway</mat-checkbox>
    <div style="height: 40px;"></div>
  </div>

  <div fxLayout="row" fxLayoutGap="10px" *ngIf="(entry.unit && inPortActivityFlag) || setInPort">
    <mat-checkbox [(ngModel)]="setInPort">New In-Port Track</mat-checkbox>
    <div style="height: 40px;"></div>
  </div>

  <div fxLayout="row" fxLayoutGap="10px" *ngIf="canEdit(entry)">
    <!-- <div fxFlex="33%">
      <app-lookup-select (selectedValueChanged)="entry.activityCategoryId = $event.activityId" required [trigger]="trigger" 
        [itemPK]="'activityId'" [itemId]="entry.activityCategoryId" [typeName]="'Activity Category'"
        [keyProperty]="'description'"></app-lookup-select>
    </div> -->
    <div fxFlex="33%">
      <app-lookup-select (selectedValueChanged)="selectActivity($event.activityId, $event.description)" required [trigger]="trigger" 
        [itemPK]="'activityId'" [itemId]="entry.activityCategoryId" [typeName]="'Activity Category'" 
        [keyProperty]="'description'"></app-lookup-select>
    </div>
    <div fxFlex="33%">
      <mat-form-field>
        <input matInput placeholder="Activity" [(ngModel)]="entry.activity">
      </mat-form-field>
    </div>
    <div fxFlex="33%">
      <app-lookup-select (selectedValueChanged)="entry.opAreaId = $event.opAreaId" [trigger]="trigger"
        [itemPK]="'opAreaId'" [itemId]="entry.opAreaId" [typeName]="'Op Area'"></app-lookup-select>
    </div>
  </div>

  <div *ngIf="showOverrides" fxLayout="row" fxLayoutGap="10px">
    <div *ngIf="overrideSource" fxFlex="33%">
      <app-lookup-select (selectedValueChanged)="source = $event" [trigger]="trigger"
        [itemId]="source" [typeName]="'Source'" [keyProperty]="'sourceName'"></app-lookup-select>
    </div>

    <div *ngIf="overrideOriginator" fxFlex="33%">
      <mat-form-field>
        <input matInput placeholder="Override Originator" [(ngModel)]="originator">
      </mat-form-field>
    </div>

    <div *ngIf="overrideConfidence" fxFlex="33%">
      <app-lookup-select (selectedValueChanged)="confidenceCode = $event" [trigger]="trigger"
      [itemId]="confidenceCode" [typeName]="'Confidence Code'" [keyProperty]="'confidenceCode'"></app-lookup-select>
    </div>
  </div>

  <div fxLayout="row" fxLayoutGap="20px">
    <div fxFlex="100%">
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Raw Message ({{entry.points}} {{entry.points == 1 ? 'point' : 'points'}})
          </mat-panel-title>
          <mat-panel-description>
            (click to expand / collapse)
          </mat-panel-description>
        </mat-expansion-panel-header>

        <ng-template matExpansionPanelContent>
          <p>{{entry.rawMessage}}</p>
        </ng-template>
      </mat-expansion-panel>
    </div>
  </div>

  <br>
  <div *ngIf="!validElnot">
    <span class="yellow-text">Warning: This unit contains invalid elnot<br><br></span>
  </div>
  <div *ngIf="!validFreqType">
    <span class="yellow-text">Warning: This unit contains invalid frequency type<br><br></span>
  </div>
  <div *ngIf="!validScanType">
    <span class="yellow-text">Warning: This unit contains invalid scan type<br><br></span>
  </div>
  <div *ngIf="!setUnderway &&  (entry.unit && !entry.unit?.nobReport)">
    <span class="yellow-text">This unit is not underway. Select the 'Set Unit Underway' checkbox to commence the unit<br><br></span>
  </div>
  <div *ngIf="setUnderway">
    <span class="yellow-text">Setting unit underway. The last DTG will be set to the location time/date of this parser queue record.<br>Select an Activity Category and an Op Area for this record<br><br></span>
  </div>
  <div *ngIf="entry.unit &&  inPortActivityFlag">
    <span class="yellow-text">To accept this parser track with an Activity Category of INPT or RTP:<br>1. Select 'New In-Port Track' checkbox to enter Unit Update<br>2. Select a different Activity Category<br>3. Click the 'Accept' button<br><br></span>
  </div>
  <div *ngIf="entry.unit && !canEdit(entry)">
    <span class="mat-color-warn">Please contact {{entry.unit.port.command.name}} to update or accept this entry<br><br></span>
  </div>

  <div class="actions" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
    <button mat-raised-button color="primary" *ngIf="canEdit(entry)" [disabled]="(!entry.opAreaId || !entry.activityCategoryId || inPortActivityFlag || !setUnderway && (!entry.unit || !entry.unit.nobReport)) || (setUnderway && (!entry.activityCategoryId || !entry.opAreaId))"
      (click)="accept(entry)">Accept</button>
    <button mat-raised-button color="warn" *ngIf="canEdit(entry)" (click)="delete(entry)">Discard</button>
    <button mat-raised-button (click)="cancel()">Cancel</button>
  </div>
</div>

<app-classification-bar class="bottom-modal-classification-bar"></app-classification-bar>
