import { JsonObject, JsonProperty, Any } from 'json2typescript';

@JsonObject('PicklistPreferences')
export class PicklistPreferences {
  @JsonProperty('picklist_pref_id', String, true)
  picklistPreferenceId: string = undefined;
  @JsonProperty('picklist_type_id', String, true)
  picklistTypeId: string = undefined;
  @JsonProperty('owner_type_id', String, true)
  ownerTypeId: string = undefined;
  @JsonProperty('source_picklist_id', String, true)
  sourcePicklistId: string = undefined;
  @JsonProperty('owner_id', String, true)
  ownerId: string = undefined;
  @JsonProperty('sort_order', Number, true)
  sortSequence: number = undefined;
  @JsonProperty('created_by', String, true)
  createdBy: string = undefined;
  @JsonProperty('disabled_ind', Boolean, true )
  disabled = false;

  static lookupName(): string {
    return 'picklistPreferences';
  }
}
