import { Source } from '@models/source.model';
import { SourceApiService } from '@services/api-services/source-api.service';
import { Injectable } from '@angular/core';
import { JsonConverter } from '@services/global/json-converter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class SourceService {
    private jsonConvert: JsonConverter;

    constructor(private sourceApiService: SourceApiService) {
        this.jsonConvert = new JsonConverter();
    }

    public getAll(filters = {}): Observable<Source[]> {
        return this.sourceApiService.getAllSources(filters)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, Source);
                })
            );
    }

    public getSourceBySourceId(sourceId: string): Observable<Source> {
        return this.sourceApiService.getSourceBySourceId(sourceId)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Source);
                })
            );
    }

    public update(source: Source): Observable<Source> {
        return this.sourceApiService.updateSource(this.jsonConvert.serializeObject(source))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Source);
                })
            );
    }

    public create(source: Source): Observable<Source> {
        return this.sourceApiService.createSource(this.jsonConvert.serializeObject(source))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Source);
                })
            );
    }

    public delete(source: Source): Observable<Source> {
        return this.sourceApiService.deleteSource(this.jsonConvert.serializeObject(source))
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }
}
