import { JsonObject, JsonProperty, Any } from 'json2typescript';

@JsonObject('Classification')
export class Classification {
  @JsonProperty('classification_id', String, true)
  classificationId: string = undefined;
  @JsonProperty('classification_name', String, true)
  classificationName: string = undefined;
  @JsonProperty('short_name', String, true)
  shortName: string = undefined;
  @JsonProperty('sacc', String, true)
  sacc: string = undefined;
  @JsonProperty('source_code', String, true)
  sourceCode: string = undefined;
  @JsonProperty('sort_by', Number, true)
  sortBy: number = undefined;
  @JsonProperty('created_by', String, true)
  createdBy: string = undefined;

  static lookupName(): string {
    return 'classifications';
  }
}
