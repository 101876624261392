import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from './api.config';

@Injectable()
export class ActivityApiService {
    SERVER_URL;

    constructor(
        private http: HttpClient, apiConfig: APIConfig) {
        this.SERVER_URL = apiConfig.TARGET_URL;
    }

    public getAllActivities(filters = {}): Observable<any> {
        let httpParams = new HttpParams();

        for (const key in filters) {
            if (filters.hasOwnProperty(key)) {
                httpParams = httpParams.set(key, filters[key]);
            }
        }

        return this.http.get(
            `${this.SERVER_URL}/activities`, { params: httpParams });
    }

    public getActivityByActivityId(activityId: string): Observable<any> {
        return this.http.get(
            `${this.SERVER_URL}/activities/${activityId}`);
    }

    public updateActivity(activity: any): Observable<any> {
        return this.http.put(
            `${this.SERVER_URL}/activities/${activity.activity_category_id}`, activity);
    }

    public createActivity(activity: any): Observable<any> {
        return this.http.post(
            `${this.SERVER_URL}/activities`, activity);
    }

    public deleteActivity(activity: any): Observable<any> {
        return this.http.delete(
            `${this.SERVER_URL}/activities/${activity.activity_category_id}`);
    }
}
