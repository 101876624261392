import { JsonObject, JsonProperty, Any } from 'json2typescript';

@JsonObject('PicklistType')
export class PicklistType {
  @JsonProperty('picklist_type_id', String, true)
  picklistTypeId: string = undefined;
  @JsonProperty('picklist_tablename', String, true)
  tableName: string = undefined;
  @JsonProperty('picklist_key_column', String, true)
  primaryKeyName: string = undefined;
  @JsonProperty('picklist_description', String, true)
  description: string = undefined;
  @JsonProperty('created_by', String, true)
  createdBy: string = undefined;

  static lookupName(): string {
    return 'ownerType';
  }
}
