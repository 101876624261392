export enum GlobalMessageTriggers {
    LOAD_NAV_BAR = 'LOAD_NAV_BAR',
    LOAD_MODAL = 'LOAD_MODAL',
    CLOSE_MODAL = 'CLOSE_MODAL',
    RELOAD_ACTIVITY_DATA = 'RELOAD_ACTIVITY_DATA',
    RELOAD_COMMAND_DATA = 'RELOAD_COMMAND_DATA',
    RELOAD_COUNTRY_DATA = 'RELOAD_COUNTRY_DATA',
    RELOAD_UNIT_DATA = 'RELOAD_UNIT_DATA',
    RELOAD_USER_DATA = 'RELOAD_USER_DATA',
    RELOAD_CATEGORY_DATA = 'RELOAD_CATEGORY_DATA',
    RELOAD_FGI_DATA = 'RELOAD_FGI_DATA',
    RELOAD_FLEET_DATA = 'RELOAD_FLEET_DATA',
    RELOAD_GEO_REF_DATA = 'RELOAD_GEO_REF_DATA',
    RELOAD_LOCATION_DATA = 'RELOAD_LOCATION_DATA',
    RELOAD_PORT_DATA = 'RELOAD_PORT_DATA',
    RELOAD_AREA_DATA = 'RELOAD_AREA_DATA',
    RELOAD_SOURCE_DATA = 'RELOAD_SOURCE_DATA',
    RELOAD_SHIP_TYPE_DATA = 'RELOAD_SHIP_TYPE_DATA',
    RELOAD_NOTIFIER_DATA = 'RELOAD_NOTIFIER_DATA',
    RELOAD_SLIDE_MAINTENANCE_DATA = 'RELOAD_SLIDE_MAINTENANCE_DATA',
    RELOAD_DATA_TAG_DATA = 'RELOAD_DATA_TAG_DATA',
    SET_THEME = 'SET_THEME'
}
