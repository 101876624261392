import { UserService } from '@services/data-services/user.service';
import { JsonConverter } from '@services/global/json-converter';
import { JsonConvert } from 'json2typescript';
import { OverlayContainer } from '@angular/cdk/overlay';
import { GlobalMessageTriggers } from '@services/global/message-bus/global-message-triggers.enum';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { ModalComponent } from '@core-module/app-components/modal/modal.component';
import { Component, ViewEncapsulation, HostBinding } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { GlobalStore } from '@services/global/message-bus/global-store.model';
import { HttpClient } from '@angular/common/http';
import { AppInitService } from '../config/init.service';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  private jsonConvert: JsonConvert = new JsonConverter();

  constructor(private dialog: MatDialog,
    private messageBusService: MessageBusService,
    private userService: UserService,
    private overlayContainer: OverlayContainer,
    private http: HttpClient,
    private init: AppInitService,
    private router: Router,
    private titleService: Title) {

  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {
    this.listenToMessageBus();
    this.instantiateStore();
    this.cacheLocalUrlIfUnauthenticated();
    this.getPKI();
    // use local storage to hold the user's theme. it will persist over sessions
    if (localStorage['theme']) {
      this.setTheme(localStorage['theme']);
    }

    this.setTitleOverride();
  }

  /**
   * Global Theme Changing Function
   */
  // tslint:disable-next-line:member-ordering
  @HostBinding('class') componentCssClass;
  setTheme(theme: string = null) {
    // accept a theme string and if set, use it -- this will come from ngOnInit
    if (theme) {
      this.overlayContainer.getContainerElement().classList.remove('dark-theme');
      this.overlayContainer.getContainerElement().classList.remove('default-theme');
      this.overlayContainer.getContainerElement().classList.add(theme);
    } else {
      // when it comes from the preferences menu, it just means to toggle the theme
      if (this.overlayContainer.getContainerElement().classList.contains('dark-theme')) {
        this.overlayContainer.getContainerElement().classList.remove('dark-theme');
        theme = 'default-theme';
        this.overlayContainer.getContainerElement().classList.add(theme);
      } else {
        this.overlayContainer.getContainerElement().classList.remove('default-theme');
        theme = 'dark-theme';
        this.overlayContainer.getContainerElement().classList.add(theme);
      }
    }
    this.componentCssClass = theme;
    localStorage['theme'] = theme;
  }

  /**
   * Subscribes to the Global Message Bus
   */
  listenToMessageBus() {
    this.messageBusService.globalMessage$.subscribe(message => {
      if (message.trigger === GlobalMessageTriggers.LOAD_MODAL) {
        this.openDialog(message.data);
      }
      if (message.trigger === GlobalMessageTriggers.SET_THEME) {
        this.setTheme();
      }
    });
  }

  /**
   * This initantites the initial global store. If a valid token is
   * in the local store, it flags isLoggedIn to true only if the exp
   * date is valid.
   */
  instantiateStore() {
    const initialStoreData = {};
    if (sessionStorage['token'] && sessionStorage['currentUserId']) {
      const tokenExp = JSON.parse(window.atob(sessionStorage['token'].split('.')[1])).exp;
      const expDate = new Date(0);
      expDate.setUTCSeconds(tokenExp);
      if (new Date() < expDate) {
        initialStoreData['isLoggedIn'] = true;
        initialStoreData['currentUserId'] = sessionStorage['currentUserId'];
        /// uggghhhh, the one stored in session storage is not completely parsed
        const sessionUserObject = JSON.parse(sessionStorage['user']);
        this.userService.getUserByUserId(sessionStorage['currentUserId']).pipe(take(1)).subscribe(user => {
          user.currentJFMCC = sessionUserObject.currentJFMCC;
          user.JFMCCs = sessionUserObject.JFMCCs;
          initialStoreData['currentUser'] = user;
          this.messageBusService.updateStore(new GlobalStore(initialStoreData));
        });
      } else {
        this.messageBusService.updateStore(new GlobalStore(initialStoreData));
      }
    } else {
      this.messageBusService.updateStore(new GlobalStore(initialStoreData));
    }
  }

  /**
   * This will open the application wide, reusable modal component.
   * Closing the dialog is handled in the modal component in case
   * mulitiple open modals might be used in the future.
   * @param data
   */
  openDialog(data: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = data;
    this.dialog.open(ModalComponent, dialogConfig);
  }

  getPKI() {
    const redirectURL = this.init.getConfig().PKI.authServiceRedirectURL;

    if (this.shouldRedirectForPKI(redirectURL)) {
      // need a better way to do this
      sessionStorage.setItem('PKIattempts', '1'); // prevents redirect loops, can be used for future retry behavoir
      window.location.href = redirectURL;
    }
  }

  // If user is unauthenticated, cache the route they accessed at to reroute them to the path
  // after authentication.
  cacheLocalUrlIfUnauthenticated() {
    if (!sessionStorage.getItem('currentUserId') && !sessionStorage.getItem('originRoute')) {
      sessionStorage.setItem('originRoute', window.location.href.split('#').pop());
    }
  }

  /**
   * Checks if PKI authentication redirect URL is configured and whether a user is already logged in
   * or has already been redirected for PKI authentication. Returns true if browser should redirect
   * the user for PKI authentication, false if not.
   *
   * @param redirectURL URL to redirect for PKI authentication
   * @returns boolean true if browser should redirect to auth service for authentication, false if not.
   */
  shouldRedirectForPKI(redirectURL: string): boolean {
    return redirectURL && redirectURL.length > 0 && !sessionStorage.getItem('currentUserId') && !sessionStorage.getItem('PKIattempts') && !this.pkiOptional();
  }

  pkiOptional(): boolean {
    return this.init.getConfig().PKI.pkiOptional && (this.init.getConfig().PKI.pkiOptionalExpiration === false || Date.now() < Date.parse(this.init.getConfig().PKI.pkiOptionalExpiration))
  }

  /**
   * Checks if a title prefix is provided, and prefixes the html title with it.
   */
  setTitleOverride() {
    const prefix = this.init.getConfig().titlePrefix;
    if (prefix != null && prefix.length > 0) {
      this.titleService.setTitle(`${prefix} ${this.titleService.getTitle()}`);
    }
  }
}
