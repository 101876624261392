<app-classification-bar class="top-modal-classification-bar"></app-classification-bar>
<app-fdtm-modal-header [headerString]="headerString"></app-fdtm-modal-header>
<div mat-dialog-content style="overflow:visible">
  <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="center center">
    <mat-form-field flex="33">
      <input matInput placeholder="Username" [(ngModel)]="user.username" required [disabled]="true">
    </mat-form-field>
    <mat-form-field flex="33">
      <input matInput placeholder="First Name" [(ngModel)]="user.firstName" required [disabled]="true">
    </mat-form-field>
    <mat-form-field flex="33">
      <input matInput placeholder="Last Name" [(ngModel)]="user.lastName" required [disabled]="true">
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="center center">
    <mat-form-field flex="33">
      <input matInput placeholder="Account Level" [(ngModel)]="user.permission.permissionDescription" required
        [disabled]="true">
    </mat-form-field>
    <mat-form-field flex="33">
      <input #email="ngModel" type="email" name="email" id="email" email matInput placeholder="Email Address" [(ngModel)]="user.email" required>
      <mat-error *ngIf="email.invalid">Invalid email address</mat-error>
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="center center">
    <mat-form-field flex="33">
      <mat-select placeholder="Command" required [(ngModel)]="user.commandId" [disabled]="isDisabled()">
        <mat-option *ngFor="let command of commands" [value]="command.commandId">{{command.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="center center">
    <div flex="33">
      <input type="checkbox" placeholder="Change Password" [(ngModel)]="changePassword"><span>Change Password?</span>
    </div>
  </div>
  <div *ngIf="changePassword" fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="center center">
    <mat-form-field>
      <input matInput placeholder="Password" cdkFocusInitial type="password" [(ngModel)]="user.currentPassword">
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="New Password" type="password" [(ngModel)]="user.password">
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Repeat New Password" type="password" [(ngModel)]="user.passwordConfirmation">
    </mat-form-field>
  </div>
  <div fxLayoutAlign="center center">
    <span *ngIf="passwordRules() != '' && changePassword" class="mat-color-warn">{{passwordRules()}}</span>
    <span *ngIf="passwordRules() == ''">&nbsp;</span>
  </div>

  <div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="update()" [disabled]="(changePassword && (!user.currentPassword?.length ||
                !user.password?.length ||
                !user.passwordConfirmation?.length ||
                passwordRules() != '')) || email.invalid || !(user.commandId && user.commandId.length)">Update</button>
    <button mat-raised-button (click)="cancel()">Cancel</button>
  </div>
</div>
<app-classification-bar class="bottom-modal-classification-bar"></app-classification-bar>