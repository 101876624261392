import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppInitService } from '../../../../config/init.service';
import { PocService } from '@services/data-services/point-of-contact-data.service';
import { pointOfContact as Poc } from '@models/point-of-contact.model';
import { FormControl, Validators } from '@angular/forms';
import { CommandService } from '@services/data-services/command.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  docs: any;
  pocs: any;
info: any;
  defaultPoc = null;

  commandControl = new FormControl('', Validators.required);
  commandSelect: any;
  // selectFormControl = new FormControl('', Validators.required);
  commands: any;
  commandMap = {};

  constructor(private route: Router,
    private configInfo: AppInitService,
    private pocService: PocService,
    private commandService: CommandService) {
  }

  ngOnInit() {
    this.getDefaultData();
    this.getPocs();
    this.userCommandSelect();
  }

  getDefaultData() {
    const data = this.configInfo.getConfig();
    this.info = data;
    if (this.info.jdissPoc) {
      this.defaultPoc = this.info.jdissPoc;
    }
    this.defaultPoc.logoLocation = 'assets/images/Jdiss_symbol.png';
    if (this.defaultPoc.contact_name !== undefined && this.defaultPoc.contact_name.includes(';')) {
      this.defaultPoc.contact_name = this.formatListStrings(this.defaultPoc.contact_name);
    }
    if (this.defaultPoc.email !== undefined && this.defaultPoc.email.includes(';')) {
      this.defaultPoc.email = this.formatListStrings(this.defaultPoc.email);
    }
    if (this.defaultPoc.phone !== undefined && this.defaultPoc.phone.includes(';')) {
      this.defaultPoc.phone = this.formatListStrings(this.defaultPoc.phone);
    }
    // this.docs = data.documention
  }

  getPocs() {
    this.pocService.getAll().subscribe( res => {
      if (res.length > 0) {
        const dbPocs = res.map( p => {
          // Listing Multiple
          if (p.contactName !== undefined && p.contactName.includes(';')) {
            p.contactName = this.formatListStrings(p.contactName);
          }
          if (p.email !== undefined && p.email.includes(';')) {
            p.email = this.formatListStrings(p.email);
          }
          if (p.phone !== undefined && p.phone.includes(';')) {
            p.phone = this.formatListStrings(p.phone);
          }
          // Make Dropdown only list Commands w/ contacts
          this.commandMap[`${p.command['name']}`] = p.command;
          return p;
        });
        this.pocs = dbPocs;
        this.commands = Object.keys(this.commandMap);
      }
    });
  }

  userCommandSelect() {
    if (sessionStorage.getItem('currentUserId') && sessionStorage.getItem('user')) {
      this.commandSelect = JSON.parse(sessionStorage.user).command.command_name;
    } else {
      this.commandSelect = 'JDISS';
    }
  }

  fetchImage(command) {
    command.command_id = command.commandId;
    return this.commandService.getLogoUrl(command);
  }

  formatListStrings(stringToSplit) {
    return stringToSplit.split(';');
  }

  isArray(val): boolean {
    return typeof val !== 'string';
  }

  filterByCommand(command) {
    if (command === undefined || command === null) {
      return [];
    }
    try {
      return this.pocs.filter( poc => poc.command.name === command);
    } catch {
      // Will sometimes render an error stating 'no property filter of undefined"
      // but page will render without issue
    }
  }
}
