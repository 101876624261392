import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { LookUpService } from '@services/data-services/lookup.service';
import { Command } from '@models/command.model';
import { Port } from '@models/port.model';
import { Area } from '@models/area.model';
import { take } from 'rxjs/operators'
// import { CommandFilterPipe } from '../../pipes/command-filter.pipe'
// tslint:disable-next-line:no-empty-interface
export interface AbacPreferencesdData {
}
@Component({
  selector: 'app-abac-preferences',
  templateUrl: './abac-preferences.component.html',
  styleUrls: ['./abac-preferences.component.css']
})
export class AbacPreferencesComponent implements OnInit {

  // the user preferences passed in and out
  currentUserABACPrefs: any;
  defaultUserABACPrefs: any;
  user: any;

  // load the UI controls for display and selection
  commands: any[];
  opAreas: any[];
  ports: any[];

  constructor(
    private lookups: LookUpService,
    public dialogRef: MatDialogRef<AbacPreferencesComponent>) {}


  ngOnInit() {
    // getting expression changed errors in development
    this.currentUserABACPrefs = JSON.parse(sessionStorage.getItem('currentUserABACPrefs'));
    this.defaultUserABACPrefs = JSON.parse(sessionStorage.getItem('defaultUserABACPrefs'));
    //this.preferences.command_id = ['fea7cebf-f682-4ec2-912b-bd8ccb10e2f6']
    this.user = JSON.parse(sessionStorage.getItem('user'));

    // call with true to refresh the cache
    Promise.resolve().then(() => {
      this.lookups.getLookupByType(Command, true).pipe(take(1)).subscribe(result => {
        this.commands = result;
        // now that we have the commands we can get others and associate command name -- yeah nested subscribes...
        this.lookups.getLookupByType(Area, true).pipe(take(1)).subscribe(result => {
          this.opAreas = result;
          this.opAreas.forEach(element => {
            let found = this.commands.find(command => {
              return command.commandId == element.commandId
            })
            if (found) {
              // the command name is displayed next to the op area in the drop down
              element.commandName = found.name
            }
          })
        });
        this.lookups.getLookupByType(Port, true).pipe(take(1)).subscribe(result => {
          this.ports = result;
          this.ports.forEach(element => {
            let found = this.commands.find(command => {
              return command.commandId == element.commandId
            })
            if (found) {
              // command name next to the port
              element.commandName = found.name
            }
          })
        });
      });
    })

  }
  cancel() {
    this.dialogRef.close();
  }
  reset() {
    this.currentUserABACPrefs = this.defaultUserABACPrefs;
  }
  update() {
    sessionStorage.setItem('currentUserABACPrefs', JSON.stringify(this.currentUserABACPrefs));
    this.dialogRef.close(this.currentUserABACPrefs);
  }

  allCommands(event) {
    // we are going to light the checkboxes behind the scenes, do not allow the dropdown to open
    event.stopPropagation();

    this.currentUserABACPrefs.command_id = []
    this.commands.forEach(element => {
      this.currentUserABACPrefs.command_id.push(element.commandId)
    })
  }

  // event.value holds the selected command ids
  commandsChanged(event) {
    // need to see if any checked op_areas are made invalid by changing the command list
    if (this.currentUserABACPrefs.op_area_id) {
      this.currentUserABACPrefs.op_area_id = this.currentUserABACPrefs.op_area_id.filter(item => {
        // since the commands changed, maybe some of the op aeras are no longer valid
        let found = this.opAreas.find(area => {
          return area.opAreaId == item
        })
        if (found) {
          return this.currentUserABACPrefs.command_id.indexOf(found.commandId) > -1
        }
        else {
          return false
        }
      })
    }
    // need to see if any checked op_areas are made invalid by changing the command list
    if (this.currentUserABACPrefs.port_id) {
      this.currentUserABACPrefs.port_id = this.currentUserABACPrefs.port_id.filter(item => {
        // since the commands changed, maybe some of the port_ids are no longer valid
        let found = this.ports.find(port => {
          return port.portId == item
        })
        if (found) {
          return this.currentUserABACPrefs.command_id.indexOf(found.commandId) > -1
        }
        else {
          return false
        }
      })
    }
  }
  allOpAreas(event) {
    // we are going to light the checkboxes behind the scenes, do not allow the dropdown to open
    event.stopPropagation();

    this.currentUserABACPrefs.op_area_id = []
    // gotta filter by the selected Commands, and watch if the Commands list changes
    this.opAreas.forEach(element => {
      if (this.currentUserABACPrefs.command_id.indexOf(element.commandId) > -1) {
        this.currentUserABACPrefs.op_area_id.push(element.opAreaId)
      }
    })
  }

  allPorts(event) {
    // we are going to light the checkboxes behind the scenes, do not allow the dropdown to open
    event.stopPropagation();

    this.currentUserABACPrefs.port_id = []
    // gotta filter by the selected Commands, and watch if the Commands list changes
    this.ports.forEach(element => {
      if (this.currentUserABACPrefs.command_id.indexOf(element.commandId) > -1) {
        this.currentUserABACPrefs.port_id.push(element.portId)
      }
    })
  }
  notAvailable(commandId) {
    return this.currentUserABACPrefs.command_id.indexOf(commandId) == -1
  }
}
