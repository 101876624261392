<div class="admin-work-area">
  <div fxLayout fxLayoutAlign="center center" class="header">
    <span class="grid-title">{{ initService.getConfig().titlePrefix }} FDT-M Feature Preference</span>
    <!-- Add Unit Floating Action Button -->
    <button *ngIf="user.isAdmin()" mat-mini-fab class="add-button" (click)="add()">
      <img class="light-icon" src="./assets/icons/ic_add_white_24px.svg" matTooltip="Add New Feature Preference" />
    </button>
  </div>

  <div class="space"></div>

  <mat-card>
    <mat-card-content class="fom-card-content">
      <fieldset>
        <legend>Feature Category</legend>
        <mat-form-field appearance="fill" class="half-width mr-10">
          <mat-label>Category</mat-label>
          <mat-select [(ngModel)]="featureCategory" (selectionChange)="loadFeatureTypes()">
            <mat-option value="REPORT">Report</mat-option>
            <mat-option value="CONFIG">Config</mat-option>
            <mat-option value="SCREEN">Screen</mat-option>
          </mat-select>
        </mat-form-field>
      </fieldset>
    </mat-card-content>
  </mat-card>

  <div class="space"></div>

  <div *ngIf="categoryLoaded">
    <mat-card>
      <mat-card-content class="fom-card-content">
        <fieldset>
          <legend>Preference Name</legend>
          <div class="same-line-left">
            <mat-form-field appearance="fill" class="half-width mr-10">
              <mat-label>Preference</mat-label>
              <mat-select [(ngModel)]="selectedPreference" (selectionChange)="loadVersionandSchemaDefinition()">
                <mat-option *ngFor="let preference of featurePreferenceTypes" [value]="preference.featureTypeId">
                  {{ preference.featureTypeName }} (Version: {{ preference.versionNum }})
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" *ngIf="selectedPreference" class="ml-10">
              <mat-label>Iteration</mat-label>
              <mat-select [(ngModel)]="versionNum" (selectionChange)="loadValues()">
                <mat-option *ngFor="let preference of featurePreferences" [value]="preference.versionNum">
                  {{ preference.featurePrefName }} (Version: {{preference.versionNum}})
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" *ngIf="selectedPreference" class="ml-10">
              <mat-label>Command</mat-label>
              <mat-select [(ngModel)]="selectedOwner">
                <mat-option *ngFor="let owner of owners" [value]="owner">
                  {{ owner.ownerName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          </fieldset>
      </mat-card-content>
    </mat-card>
  </div>

  <div *ngIf="schemaLoaded">
    <h2 class="centered-heading">{{ schemaDefinition.uiLabel }}</h2>
    <mat-card>
      <mat-card-content class="fom-card-content">
        <fieldset>
          <legend> Feature Preference Value Name </legend>
            <ng-container>
              <mat-form-field appearance="fill" class="full-width" matTooltip="Enter the name you wish to save these feature preference values as">
                <mat-label>Name</mat-label>
                <input matInput [(ngModel)]="versionName">
              </mat-form-field>
            </ng-container>
        </fieldset>
        <div *ngFor="let section of schemaDefinition.Sections">
          <fieldset>
            <legend>{{ section.uiLabel }}</legend>
            <ng-container *ngIf="section.name === 'classificationFilter'">
              <mat-grid-list cols="5" rowHeight="175px">
                <ng-container *ngFor="let field of section.Fields">
                  <mat-grid-tile *ngIf="!(field.uiWidget === null || field.uiWidget === 'null')">
                    <ng-container [ngSwitch]="field.uiWidget">
                      <div *ngSwitchCase="'selectionList'">
                        <h4>{{ field.uiLabel }}:</h4>
                        <mat-selection-list class="bordered" fxLayout="column" [(ngModel)]="field.value">
                            <mat-list-option class="smallerList" checkboxPosition="before"
                                *ngFor="let option of this[field.name]" [value]="option[field.rptLabel]">
                                {{option[field.uiFormatMask]}}
                            </mat-list-option>
                        </mat-selection-list>
                    </div>
                    <div *ngSwitchCase="'radioGroup'">
                      <h4>{{ field.uiLabel }}:</h4>
                      <mat-radio-group class="bordered" fxLayout="column" [(ngModel)]="field.value">
                          <mat-radio-button class="classification-option smallRadio"
                              *ngFor="let option of this[field.name]" [value]="option[field.rptLabel]">
                              {{option[field.uiFormatMask]}}
                          </mat-radio-button>
                      </mat-radio-group>
                    </div>
                    </ng-container>
                  </mat-grid-tile>
                </ng-container>
              </mat-grid-list>
            </ng-container>
            <ng-container *ngIf="section.name !== 'classificationFilter'">
              <mat-grid-list cols="3" rowHeight="70px">
                <ng-container *ngFor="let field of section.Fields">
                  <mat-grid-tile *ngIf="!(field.uiWidget === null || field.uiWidget === 'null')">
                    <ng-container [ngSwitch]="field.uiWidget">
                      <mat-form-field appearance="fill" class="input-90-width" matTooltip="{{field.uiToolTip}}" matTooltipClass="mat-tooltip" *ngSwitchCase="'text'">
                        <mat-label>{{ field.uiLabel }}</mat-label>
                        <input matInput [(ngModel)]="field.value" [type]="field.type" />
                      </mat-form-field>
                      <mat-form-field appearance="fill" class="input-90-width" matTooltip="{{field.uiToolTip}}" matTooltipClass="mat-tooltip" *ngSwitchCase="'textArea'">
                        <mat-label>{{ field.uiLabel }}</mat-label>
                        <textarea matInput [(ngModel)]="field.value" [type]="field.type"></textarea>
                      </mat-form-field>
                    </ng-container>
                  </mat-grid-tile>
                </ng-container>
              </mat-grid-list>
            </ng-container>
          </fieldset>
        </div>
        <div class="actions" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
          <button mat-raised-button color="primary" (click)="submitForm()">Submit</button>
          <button mat-raised-button color="warn" [disabled]="doNotDelete" (click)="delete()">Discard</button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
