import { FormBuilder, FormGroup } from '@angular/forms';
import { EditModalBaseComponent } from '@fom-module/base-classes/edit-modal-base.component';
import { AreaService } from '@services/data-services/area.service';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { Component, OnInit } from '@angular/core';
import { Area } from '@models/area.model';
import { LookUpService } from '@services/data-services/lookup.service';
import { Country } from '@models/country.model';
import { Command } from '@models/command.model';
import { MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { UtilityService } from '@services/utility.service';
import { CommandHierarchyService } from '@services/data-services/command-hierarchy.service';
import { User } from '@models/user.model';
import { CurrentUserService } from '@services/current-user-service';
import { CommandHierarchy } from '@models/command-hierarchy.model';

@Component({
  selector: 'app-edit-area-modal',
  templateUrl: './edit-area-modal.component.html',
  styleUrls: ['./edit-area-modal.component.css']
})
export class EditAreaModalComponent extends EditModalBaseComponent implements OnInit {
  // Dropdown Options
  countryOptions: Country[];
  commandOptions: Command[];
  user: User;
  editForm: FormGroup;

  commandHierarchy: CommandHierarchy;
  flag: string = '';

  constructor(public areaService: AreaService,
    public lookUpService: LookUpService,
    public utilities: UtilityService,
    public formBuilder: FormBuilder,
    private commandHierarchyService: CommandHierarchyService,
    public dialogRef: MatDialogRef<EditAreaModalComponent>,
    public messageBusService: MessageBusService,
    private currentUserService: CurrentUserService) {
    super(areaService, dialogRef);
  }

  ngOnInit() {
    this.headerString = this.isNew ? 'Add OpArea' :
    this.isDelete ? 'Delete OpArea' :
    'Edit OpArea'
    this.user = this.currentUserService.getCurrentUser();
    if (this.isNew) {
      this.entity.commandId = this.user.currentJFMCC ? this.user.currentJFMCC.commandId : this.user.commandId;
    }
    this.editForm = this.formBuilder.group({
      // opAreaId: [this.entity.opAreaId],
      commandId: [{ value: this.entity.commandId, disabled: this.isDelete }],
      reportGroup: [{ value: this.entity.reportGroup, disabled: this.isDelete }],
      opArea: [{ value: this.entity.opArea, disabled: this.isDelete }],
      //remarks: [this.entity.remarks]
    });
    this.flag = this.entity.remarks;
    Promise.resolve().then(() => {
      this.loadLookups();
    });
  }

  resolveDynamicComponentClass() {
    return EditAreaModalComponent;
  }

  loadLookups() {
    this.lookUpService.getLookupByType(Command, true).pipe(take(1)).subscribe(result => {
      this.commandHierarchyService.getAll({ sort: 'version_num', limit: 1 }).pipe(take(1)).subscribe(json => {
        if (json && Array.isArray(json) && json.length) {
          this.commandHierarchy = json[0];

          let jfmccs;
          if (this.user.isSystemAdmin()) {
            jfmccs = this.commandHierarchy.getJFMCCs();
          } else {
            jfmccs = this.commandHierarchy.getJFMCCIds(this.user.command);
          }

          this.commandOptions = result.filter(item => {
            return jfmccs.includes(item.commandId);
          });
        } else {
          this.commandOptions = result;
        }
      });
    });
  }

  onCountryChange(country) {
    this.entity.remarks = this.flag = country.name
  }

  // override the shared handler to check for any new or edit entry. will set an error on the form
  checkDups() {
    if (!this.editForm.controls['opArea']['value']) {
      return
    }
    const area = this.editForm.controls['opArea']['value'].toUpperCase().trim();
    const country = this.entity.remarks ? this.entity.remarks.toUpperCase().trim() : ''

    // ok, do not check the item being edited, that's not right!
    const index = this.items.indexOf(this.entity)

    const found = this.items.find((x, i) => { 
      return i != index && (x['opArea'].toUpperCase().trim() == area) && x['remarks'].toUpperCase().trim() == country 
    })
    if (found) {
      this.editForm.controls['opArea'].setErrors({ 'duplicate entry': true });
    }
    else {
      const error = this.editForm.controls['opArea'].hasError('duplicate entry')
      if (error) {
        this.editForm.controls['opArea'].setErrors(null);
      }
    }
  }
}
