import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

// simplified grouping. only concerned with one level in FDT-M
export class Group {
  //level: number = 0;
  //parent: Group;
  expanded: boolean = true;
  visible: boolean = true;
  itemCount = 0;
  name: string;
  isGroup: boolean = true;
  items = [];
}

@Injectable({
  providedIn: 'root'
})

export class GridGroupingService {

  constructor() { }

  // build an array of items with group objects leading each set of groupings
  // the items themselves have a reference to their 'parent' to make processing easier
  buildDataSourceData = (data: any, groupBy: string) => {
    const map = {};
    data.forEach(element => {
      const key = this.dataAccessor(element, groupBy) || 'All';

      if (map[key]) {
        element.group = map[key].group;
        map[key].group.items.push(element);
        map[key].items.push(element);
      } else {
        const group = new Group();
        group.name = key;
        group.items = [element];
        element.group = group;
        map[key] = { group: group, items: [element] };
      }
    });
    const dataArray = Object.values(map);
    const flattened = [];
    dataArray.forEach((element: any) => {
      element.group.itemCount = element.items.length;
      flattened.push(element.group);
      element.items.forEach(item => {
        flattened.push(item);
      });
    });

    return flattened;
  }

  dataAccessor = (item, property) => {
    let value;
    if (property.includes('.')) {
      value = property.split('.').reduce((o, p) => o && o[p], item);
    } else {
      value = item ? item[property] : null;
    }

    return typeof value === 'string' ? value.toUpperCase() : value;
  }
}
