<div class="admin-work-area">
  <div fxLayout fxLayoutAlign="center center">
    <span class="grid-title">{{initService.getConfig().titlePrefix}} FDT-M Points of Contact</span>
    <!-- Add Floating Action Button -->
    <button mat-mini-fab class="add-button" (click)="add()">
      <img class="light-icon" src="./assets/icons/ic_add_white_24px.svg" matTooltip="Add Point of Contact" />
    </button>
  </div>

  <div class="mat-elevation-z8">
    <!-- Paginator -->
    <mat-paginator [pageSizeOptions]="[10, 20, 50, 100, 1000]" [pageSize]="20" [length]="dataSource?.data?.length" showFirstLastButtons></mat-paginator>
    <table mat-table [dataSource]="dataSource" matSort matSortStart="desc">
      <ng-container matColumnDef="commandName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Command</th>
        <td mat-cell *matCellDef="let element"> {{element.commandName}} </td>
      </ng-container>

      <ng-container matColumnDef="supportingCommands">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Supporting Commands</th>
        <td mat-cell *matCellDef="let element"> {{element.supportingCommands}} </td>
      </ng-container>

      <ng-container matColumnDef="contactName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name</th>
        <td mat-cell *matCellDef="let element"> {{element.contactName}} </td>
      </ng-container>

      <ng-container matColumnDef="contactPosition">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Position</th>
        <td mat-cell *matCellDef="let element"> {{element.contactPosition}} </td>
      </ng-container>

      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Phone</th>
        <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Email</th>
        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
      </ng-container>

      <ng-container matColumnDef="link">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Link</th>
        <td mat-cell *matCellDef="let element"> {{element.link}} </td>
      </ng-container>
      <ng-container matColumnDef="supportHours">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Support Hours</th>
        <td mat-cell *matCellDef="let element"> {{element.supportHours}} </td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef>
          <div>
            Delete
          </div>
        </th>
        <td mat-cell *matCellDef="let row" title="Delete POC">
          <button title="Delete Contact" mat-button class="delete-button" (click)="$event.stopPropagation()" (click)="handleDelete(row)">
            <img src="./assets/icons/ic_delete_gray_24px.svg" />
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr class="hyper" matTooltip="Edit POC" matTooltipPosition="before" mat-row *matRowDef="let row; columns: displayedColumns;" (click)="edit(row)"></tr>
    </table>
  </div>
</div>
