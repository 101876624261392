import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RecordMessagePopupModalComponent } from '@fom-module/edit-modals/recordmessage-popup-modal/recordmessage-popup-modal.component';
import { GlobalMessageTriggers } from '@services/global/message-bus/global-message-triggers.enum';
import { ReportingService } from '@services/data-services/reporting.service';
import { FomPageBaseComponent } from '@fom-module/base-classes/fom-page-base.component';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { Router } from '@angular/router';
import { UserNotificationService } from '@services/notification.service';
import { take } from 'rxjs/operators';
import { ModalResponse } from "@core-module/enums/modal-response.enum";
import { AppInitService } from '../../../../config/init.service';
import { CurrentUserService } from '@services/current-user-service';
import { FeaturePreferenceService } from '@services/data-services/feature-preference.service';
import { FeaturePreference } from '@models/feature-preference.model';
import { FeatureType } from '@models/feature-type.model';
import { FeatureTypeService } from '@services/data-services/feature-type.service';
import { } from 'wicg-file-system-access';

@Component({
  selector: 'app-record',
  templateUrl: './recordMessage.component.html',
  styleUrls: ['./recordMessage.component.css']
})
export class RecordMessageComponent extends FomPageBaseComponent implements OnInit, OnDestroy {

  status: string = 'ready';

  // track the subscription in case of navigating away
  subscription: any;
  featurePrefs: FeaturePreference[] = [];
  selectedPrefType: string = undefined;
  inPortHours: number = 0;

  pacfltRmgToggle = this.initService.getConfig().togglePacfltRmgTab || false;

  constructor(
    private dialog: MatDialog,
    private reportingService: ReportingService,
    private notifier: UserNotificationService,
    public messageBusService: MessageBusService,
    public route: Router,
    public initService: AppInitService,
    public featurePreferenceService: FeaturePreferenceService,
    public featureTypeService: FeatureTypeService,
    public currentUserService: CurrentUserService) {
      super(messageBusService, route);
      this.RELOAD_MESSAGE = GlobalMessageTriggers.RELOAD_PORT_DATA;
      this.EDIT_MODAL_COMPONENT = RecordMessagePopupModalComponent;
  }
  ngOnInit() {
    super.ngOnInit();
    this.getFeaturePrefs();
  }

  // if we navigate away but not done, kill the subscription
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  // Only Integer Numbers
  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  public getRecordMessage(type, selectedPref, inPortHours) {
    this.status = 'in progress'
    const user = this.currentUserService.getCurrentUser()
    const currentJFMCC = user.currentJFMCC ? user.currentJFMCC.commandId : user.commandId
    const saveReport = false;
    const overrideFields = {};
    this.subscription = this.reportingService.getRecordMessage(currentJFMCC, type, saveReport, overrideFields, selectedPref, inPortHours).pipe(take(1)).subscribe(nob => {
      this.subscription.unsubscribe()
      this.status = 'complete'
      this.add(nob, currentJFMCC, type, saveReport, selectedPref, inPortHours);
    }, async err => {
      const responseText = await err.error.text();
      const errorResponse = JSON.parse(responseText);
      const errorMessage = errorResponse.message; // Extract the error message

      // we caught the error only to stop the progress bar
      this.subscription.unsubscribe()
      this.status = 'complete'

      if (errorMessage.startsWith("No NOB Data for user's command:")) {
        throw(errorMessage)
      }
      // we still want to throw it to the global ErrorHandler
      throw (err)
    });
  }

  cancel() {
    if (this.subscription) {
      this.subscription.unsubscribe()
      this.status = 'ready'
      this.notifier.showError('Request Cancelled', 4000)
    }
  }

  add(blob, currentJFMCC, type, saveReport, selectedPref, inPortHours) {
    const dialogRef = this.dialog.open(RecordMessagePopupModalComponent, {
      width: '90%', // Set the width to 90% of the screen width
      maxWidth: '100vw', // Limit the maximum width to the viewport width
      maxHeight: '100vh', // Limit the maximum height to the viewport height
      data: { blob, currentJFMCC, type, saveReport, selectedPref, inPortHours}
    });
    // Subscribe to the modal's afterClosed event to get the result
    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      switch (result.response) {
        case ModalResponse.CANCEL: 
          break;
        case ModalResponse.SAVE:
          this.notifier.showSuccess('Download Complete', 3000)
          break;
        case undefined:
          break;
        default:
          throw 'Unhandled response type from RMG Popup Modal'
      }
    })
  }

  async getFeaturePrefs() {
    this.featurePrefs = await this.featurePreferenceService.getAll().pipe(take(1)).toPromise();
  }

  async selectFeaturePref(pref) {
    if (pref) {
      const featureType = await this.featureTypeService.getById(pref.featureTypeId).pipe(take(1)).toPromise();
      this.selectedPrefType = featureType.featureTypeName;
    }
  }

  resetFeaturePrefSelection(selectValue) {
    this.selectedPrefType = undefined;
    selectValue.value = undefined;
    this.inPortHours = 0;
  }
}
