import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';


@Pipe({
  name: 'locatorPipe'
})

export class LocatorPipe implements PipeTransform {
  datepipe: DatePipe = new DatePipe('en-US')
  transform(value: any, removedFromNob: boolean, formatDate: boolean): any {
    if (value && !removedFromNob) {
      return formatDate ? this.datepipe.transform(value, 'dd MMM YYYY HHmm\'Z\'', 'UTC') : value
    } else {
      return "No last location"
    }
  }

}