<div class="admin-work-area">
  <div fxLayout fxLayoutAlign="center center" class="simple-grid-title">
    <span class="grid-title">{{configInfo.getConfig().titlePrefix}} FDT-M Commands</span>
    <!-- Add Unit Floating Action Button -->
    <button mat-mini-fab class="add-button" (click)="add()">
      <img class="light-icon" src="./assets/icons/ic_add_white_24px.svg" matTooltip="Add Command" />
    </button>
  </div>
  <div fxLayout fxLayoutAlign="center center">
    <mat-form-field fxFlex="30%">
      <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Filter by Command Name">
    </mat-form-field>
  </div>
  <div class="mat-elevation-z8">
    <!-- Paginator -->
    <mat-paginator [pageSizeOptions]="[10, 20, 50, 100, 1000]" [pageSize]="20" [length]="dataSource?.data?.length"
      showFirstLastButtons></mat-paginator>
    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Command Name</th>
        <td mat-cell *matCellDef="let element">{{element.name}}</td>
      </ng-container>

      <ng-container matColumnDef="logo">
        <th mat-header-cell *matHeaderCellDef>Command Logo</th>
        <td mat-cell *matCellDef="let element">
          <img *ngIf="element.logoPath" [src]="fetchCommandLogo(element)" [alt]="element.name+' logo'" width="45px"
            height="45px" />
        </td>
      </ng-container>

      <ng-container matColumnDef="users">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Number of Users</th>
        <td mat-cell *matCellDef="let element">{{element.usersCount}}</td>
      </ng-container>

      <ng-container matColumnDef="units">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Number of Units</th>
        <td mat-cell *matCellDef="let element">{{formatUnitCount(element.commandId)}}</td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef>Edit</th>

        <td mat-cell *matCellDef="let row">
          <button mat-button mat-icon-button title="Edit Command" matTooltip="Edit Command" matTooltipPosition="below"
            class="action-button" (click)="$event.stopPropagation(); edit(row)">
            <mat-icon>edit</mat-icon>
          </button>
        </td>

      </ng-container>

      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef>Delete</th>

        <td mat-cell *matCellDef="let row">
          <button mat-button mat-icon-button title="Delete Command" matTooltip="Delete Command"
            matTooltipPosition="below" class="action-button" (click)="$event.stopPropagation(); handleDelete(row)">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
