import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Classification } from '@models/classification.model';
import { CPage } from '@models/cpage.model';
import { Unit } from '@models/unit.model';
import { CurrentUserService } from '@services/current-user-service';
import { CPageService } from '@services/data-services/cpage.service';
import { LookUpService } from '@services/data-services/lookup.service';
import { UnitService } from '@services/data-services/unit.service';
import { UtilityService } from '@services/utility.service';
import { debounceTime, exhaustMap, filter, finalize, scan, startWith, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { Country } from '@models/country.model';
import { CountryService } from '@services/data-services/country.service';
import { PicklistPrefService } from '@services/data-services/picklist-pref.service';
import { UserNotificationService } from '@services/notification.service';
import { Observable, Subject } from 'rxjs';
import { AppInitService } from '../../../../config/init.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
  selector: 'app-edit-collaboration-modal',
  templateUrl: './edit-collaboration-modal.component.html',
  styleUrls: ['./edit-collaboration-modal.component.css']
})
export class EditCollaborationModalComponent implements OnInit {

  isLoading = false
  user: any
  editForm: FormGroup;
  classifications: Classification[] = []
  cpage: CPage;
  selectedFile: File
  fileSelectMsg: string = ''
  // let's use this for deleting an entry also
  isDelete = false

  // special filter for Country on the search/type-ahead
  countries: Country[] = [];
  filterByCountry: any = {};
  noCountry = {};
  headerString: string;

  @ViewChild(MatAutocompleteTrigger, { static: true }) unitAutocomplete: MatAutocompleteTrigger;
  unitsPerPage: number;
  filteredUnits$: Observable<Unit[]> | undefined;
  private nextUnitPage$ = new Subject();
  filteredUnits: Unit[] = []

  constructor(private unitService: UnitService,
    private currentUserService: CurrentUserService,
    private cpageService: CPageService,
    public utilities: UtilityService,
    private lookupService: LookUpService,
    private picklistPrefService: PicklistPrefService,
    private countryService: CountryService,
    private notificationService: UserNotificationService,
    public dialogRef: MatDialogRef<EditCollaborationModalComponent>,
    private initService: AppInitService,
    private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.headerString = this.isDelete ? 'Delete Collaboration Portal Entry' :
      'Add Collaboration Portal Entry'
    this.filterByCountry = this.noCountry
    this.user = this.currentUserService.getCurrentUser();
    this.unitsPerPage = this.initService.getConfig().unitPageSize || 20;
    this.initializeForm({})
    Promise.resolve().then(() => {
      this.lookupService.getLookupByType(Classification, true).pipe(take(1)).subscribe(results => {
        this.classifications = results
        // descending order fomng-840
        this.classifications.sort((a,b) => {
          return a.sortBy < b.sortBy ? 1 : -1
        })
        if (this.cpage && this.cpage.unit) {
          this.editForm.controls['unit'].setValue(this.cpage.unit)
          this.editForm.controls['remarks'].setValue(this.cpage.comments)
          this.editForm.controls['classification'].setValue(this.classifications.find(x => x.classificationName == this.cpage.classification))
        }
        // just disable the entire form, that'll take care of dropdowns, autocompletes, etc.
        if (this.isDelete) {
          this.editForm.disable()
        }
      })
      // use the user instance function, will be changed to JFMCC
      const filter = {owner_id: this.user.unitOwnerId(null)}
      this.picklistPrefService.getAll(filter).pipe(take(1)).subscribe( preferences => {
        this.lookupService.getLookupByType(Country, true).pipe(take(1)).subscribe(entities => {
          entities.map(item => {
            // ok, is there a preference for this guy?
            //const id = item[this.itemPK]  // this.itemId ? this.itemId : item[this.itemPK]
            const found = preferences.find(x => {
              return x.sourcePicklistId == item.countryId
            })
            if (found) {
              if (found.sortSequence && found.sortSequence == this.utilities.HIGH_VALUES) {
                item.isDisabled = true
              }
              else {
                item.isDisabled = false
              }
              item.sortSequence = found.sortSequence
            }
          })
          entities.sort((a,b) => {
            return a.sortSequence - b.sortSequence
          })
          this.countries = entities
        })
      })
    })
  }
  countryFilterChange(event) {
    const filter = event.value.countryId ? {country_id: event.value.countryId} : {}
    this.initializeForm(filter)
  }

  getFlag(country) {
    return this.countryService.getFlagUrl(country)
  }

  initializeForm(filters, clear = true) {
    if (clear) {
      this.editForm = this.formBuilder.group({
        unit: [''],
        remarks: [],
        classification: []
      });
    }

    filters["sort"] = "name";

    const unitFilters$ = this.editForm.get('unit').valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      filter(q => typeof q === "string"));

    this.filteredUnits$ = unitFilters$.pipe(
      switchMap(filter => {
        // Reset current page whenever name filter changes.
        let currentPage = 1;
        return this.nextUnitPage$.pipe(
          tap(() => this.isLoading = true),
          startWith(currentPage),
          // Note: Until the backend responds, ignore NextPage requests.
          exhaustMap(_ => this.searchUnits(filter, filters, currentPage)),
          tap(() => currentPage++),

          takeWhile(p => p.length > 0, true),
          scan((allUnits: any, newUnits: any) => allUnits.concat(newUnits), []),
          tap(async units => {
            const unit = this.editForm.get('unit')?.value || '';

            if (unit?.length) {
              if (units.length === 1 && unit.trim().toLocaleLowerCase() === units[0].fullName().toLocaleLowerCase()) {
                this.unitAutocomplete.closePanel();
                this.editForm.controls.unit.setValue(units[0]);
              }
            }
            this.isLoading = false;
          })
        );
      }));
  }
  // autocomplete or type ahead on the unit search form.
  displayUnit(unit?: Unit): string | undefined {
    return unit ? unit.fullName() : undefined;
  }

  add() {
    let cpage = new CPage()
    const unitId = this.editForm.controls['unit']['value'].unitId
    cpage.unitId = unitId
    cpage.firstName = this.user.firstName
    cpage.lastName = this.user.lastName
    cpage.email = this.user.email
    cpage.comments = this.editForm.controls['remarks']['value']
    cpage.classification = this.editForm.controls['classification']['value'].classificationName
    cpage.verCode = 'non null ver code'
    cpage.docName = this.selectedFile ? this.selectedFile.name : ''
    cpage.docSize = this.selectedFile ? this.selectedFile.size : 0
    cpage.docType = this.selectedFile ? this.selectedFile.type : ''
    cpage.image = this.selectedFile ? { contents: this.selectedFile, name: this.selectedFile.name } : null
    this.cpageService.create(cpage).pipe(take(1)).subscribe(result => {
      //console.log(result)
      this.dialogRef.close('add')
      this.notificationService.showSuccess('Submission accepted.', 3000)
    }, err => {
      throw err
    })
  }

  delete(cpage) {
    this.cpageService.delete(cpage).pipe(take(1)).subscribe(result => {
      this.dialogRef.close('delete')
      this.notificationService.showSuccess('Submission deleted.', 3000)
    }, err => {
      throw err
    })
  }

  cancel() {
    this.dialogRef.close()
  }

  // file handling
  onFileChanged(event) {
    this.selectedFile = <File>event.target.files[0];
  }
  cancelEvent(): void {
    this.fileSelectMsg = 'No file selected yet.';
    this.selectedFile = null;
  }

  searchUnits(nameFilter: any, filters: any, page: number): Observable<Unit[]> {
    const offset = page > 0 ? (page - 1) * this.unitsPerPage : 0;

    return this.unitService.findUnits(nameFilter, filters, this.unitsPerPage, offset);
  }

  onUnitOptionsScroll() {
    this.nextUnitPage$.next();
  }
}
