<mat-card class="form-card" >
<mat-card-content>
<mat-sidenav-container class="admin-container">
  <mat-sidenav  #drawer mode="side" opened class="admin-sidenav">
      <mat-nav-list>
          <a *ngIf="user.isAdmin()" [ngClass]="{'current' : selection == 'service-dashboard'}"
            mat-list-item routerLink="/admin/servicemenu/service-dashboard"> Services</a>
          <a *ngIf="user.isSystemAdmin()" [ngClass]="{'current' : selection == 'connections'}"
            mat-list-item routerLink="/admin/servicemenu/system-connections"> System Connections</a>
          <a *ngIf="user.isAdmin()" [ngClass]="{'current' : selection == 'notifier'}"
            mat-list-item routerLink="/admin/servicemenu/notifier"> Notifier</a>
      </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <!-- <router-outlet></router-outlet> -->
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
</mat-card-content>
</mat-card>
