import { DataTag } from '@models/data-tags.model';
import { DataTagApiService } from '@services/api-services/data-tags-api.service';
import { Injectable } from '@angular/core';
import { JsonConverter } from '@services/global/json-converter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class DataTagService {
    private jsonConvert: JsonConverter;

    constructor(private dataTagApiService: DataTagApiService) {
        this.jsonConvert = new JsonConverter();
    }

    public getAll(): Observable<DataTag[]> {
        return this.dataTagApiService.getAllDataTags()
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, DataTag);
                })
            );
    }

    public getDataTagByDataTagId(dataTagId: string): Observable<DataTag> {
        return this.dataTagApiService.getDataTagByDataTagId(dataTagId)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, DataTag);
                })
            );
    }

    public update(dataTag: DataTag): Observable<DataTag> {
        return this.dataTagApiService.updateDataTag(this.jsonConvert.serializeObject(dataTag))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, DataTag);
                })
            );
    }

    public create(dataTag: DataTag): Observable<DataTag> {
        return this.dataTagApiService.createDataTag(this.jsonConvert.serializeObject(dataTag))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, DataTag);
                })
            );
    }

    public delete(dataTag: DataTag): Observable<DataTag> {
        return this.dataTagApiService.deleteDataTag(this.jsonConvert.serializeObject(dataTag))
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }
}
