import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from './api.config';
import { ReportTemplate } from '@models/report-template.model';

@Injectable({ providedIn: 'root' })
export class ReportTemplateApiService {
    SERVER_URL;

    constructor(
        private http: HttpClient, apiConfig: APIConfig) {
        this.SERVER_URL = apiConfig.TARGET_URL;
    }

    public getAll(): Observable<any> {
        return this.http.get(
            `${this.SERVER_URL}/reportTemplates`);
    }

    public getAllByName(reportTemplateName: string): Observable<any> {
        return this.http.get(
            `${this.SERVER_URL}/reportTemplates/name/${reportTemplateName}`);
    }

    public getById(parameterId: string): Observable<any> {
        return this.http.get(
            `${this.SERVER_URL}/reportTemplates/${parameterId}`);
    }

    public update(parameterId: any, formData: FormData): Observable<any> {
        return this.http.put(
            `${this.SERVER_URL}/reportTemplates/${parameterId}`, formData);
    }

    public create(reportTempate: any): Observable<any> {
        return this.http.post(
            `${this.SERVER_URL}/reportTemplates`, reportTempate);
    }

    public delete(parameter: any): Observable<any> {
        return this.http.delete(
            `${this.SERVER_URL}/reportTemplates/${parameter.parameter_id}`);
    }
}
