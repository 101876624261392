import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { JsonConverter } from '@services/global/json-converter';
import { map } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { Permission } from "@models/permission.model";
import { PermissionApiService } from '@services/api-services/permission-api.service'

@Injectable()
export class PermissionService {
  private jsonConvert: JsonConverter;

  constructor(private permissionApiService: PermissionApiService) {
    this.jsonConvert = new JsonConverter();
  }

  public getAll(): Observable<Permission[]> {
    return this.permissionApiService.getAllPermissions().pipe(
      map( data => { return this.jsonConvert.deserializeArray(data, Permission)})
    )
  }
}