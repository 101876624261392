import { ClassMarkingRelationship } from '@models/class-marking-relationship.model';
import { ClassMarkingRelationshipApiService } from '@services/api-services/class-marking-relns-api.service';
import { Injectable } from '@angular/core';
import { JsonConverter } from '@services/global/json-converter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ClassMarkingRelationshipService {
    private jsonConvert: JsonConverter;

    constructor(private classMarkingRelnApiService: ClassMarkingRelationshipApiService) {
        this.jsonConvert = new JsonConverter();
    }

    public getAll(): Observable<ClassMarkingRelationship[]> {
        return this.classMarkingRelnApiService.getAllClassMarkingRelns()
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, ClassMarkingRelationship);
                })
            );
    }

    public getClassMarkingRelnById(classMarkingRelnId: string): Observable<ClassMarkingRelationship> {
        return this.classMarkingRelnApiService.getClassMarkingRelnById(classMarkingRelnId)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, ClassMarkingRelationship);
                })
            );
    }

    public update(classMarkingRelnId: ClassMarkingRelationship): Observable<ClassMarkingRelationship> {
        return this.classMarkingRelnApiService.updateClassMarkingReln(this.jsonConvert.serializeObject(classMarkingRelnId))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, ClassMarkingRelationship);
                })
            );
    }

    public create(classMarkingRelnId: ClassMarkingRelationship): Observable<ClassMarkingRelationship> {
        return this.classMarkingRelnApiService.createClassMarkingReln(this.jsonConvert.serializeObject(classMarkingRelnId))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, ClassMarkingRelationship);
                })
            );
    }

    //changing to use altered hierarchy model vs singular record and via primaryDetail
    public delete(classMarkingRelnId: any): Observable<ClassMarkingRelationship> {
        const rationale = 'bulkDelete'
        return this.classMarkingRelnApiService.deleteClassMarkingRelnByPrimaryId(
            this.jsonConvert.serializeObject(classMarkingRelnId?.primaryDetail[0], ClassMarkingRelationship),
            rationale)
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    public bulkUpdate(classMarkingRelnIds: ClassMarkingRelationship[], deletePrimaryId: string): Observable<any> {
        let relns = []
        relns = classMarkingRelnIds.map((data) => {
            return this.jsonConvert.serializeObject(data, ClassMarkingRelationship);
        });
        return this.classMarkingRelnApiService.bulkUpdateClassMarkingReln(relns, deletePrimaryId)
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    public getAllClassMarkingRelnByCategory(classMarkingCategoryId: string): Observable<ClassMarkingRelationship[]> {
        return this.classMarkingRelnApiService.getAllClassMarkingRelnByCategory(classMarkingCategoryId)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, ClassMarkingRelationship);
                })
            );
    }
}
