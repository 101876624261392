<mat-card class="form-card" >
<mat-card-content>
<mat-sidenav-container class="admin-container">
  <mat-sidenav  #drawer mode="side" opened class="admin-sidenav">
      <mat-nav-list> 
          <a *ngIf="user.isSystemAdmin()" [ngClass]="{'current' : selection == 'owner-type'}"
            mat-list-item routerLink="/admin/appsystemadminmenu/owner-type"> Owner Type List</a>
          <a *ngIf="user.isSystemAdmin()" [ngClass]="{'current' : selection == 'picklist-type'}"
            mat-list-item routerLink="/admin/appsystemadminmenu/picklist-type"> Picklist Type List</a>
          <a *ngIf="user.isSystemAdmin()" [ngClass]="{'current' : selection == 'parameters'}"
            mat-list-item routerLink="/admin/appsystemadminmenu/parameters"> Parameters</a>
          <a *ngIf="user.isSupervisor()" [ngClass]="{'current' : selection == 'preferences'}"
            mat-list-item routerLink="/admin/appsystemadminmenu/preferences"> Preferences</a>
      </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <!-- <router-outlet></router-outlet> -->
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
</mat-card-content>
</mat-card>
