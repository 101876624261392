import { CommandHierarchy } from '@models/command-hierarchy.model';
import { CommandHierarchyApiService } from '@services/api-services/command-hierarchy-api.service';
import { Injectable } from '@angular/core';
import { JsonConverter } from '@services/global/json-converter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CommandHierarchyService {
  private jsonConvert: JsonConverter;

  constructor(private commandHierarchyApiService: CommandHierarchyApiService) {
    this.jsonConvert = new JsonConverter();
  }

  public getAll(params = {}): Observable<CommandHierarchy[]> {
    return this.commandHierarchyApiService.getAll(params)
      .pipe(
        map((data) => {
          return this.jsonConvert.deserializeArray(data, CommandHierarchy);
        })
      );
  }

  public create(commandHierarchy: CommandHierarchy): Observable<CommandHierarchy> {
    return this.commandHierarchyApiService.createCommandHierarchy(this.jsonConvert.serializeObject(commandHierarchy))
      .pipe(
        map((data) => {
          return this.jsonConvert.deserializeObject(data, CommandHierarchy);
        })
      );
  }

}
