import { Component, Input, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ModalResponse } from "@core-module/enums/modal-response.enum";
import { FeaturePreference } from "@models/feature-preference.model";
import { FeatureType } from "@models/feature-type.model";
import { ReportTemplate } from "@models/report-template.model";
import { FeaturePreferenceService } from "@services/data-services/feature-preference.service";
import { FeatureTypeService } from '@services/data-services/feature-type.service';
import { ReportTemplateService } from '@services/data-services/report-template.service'
import { FriendlyErrorService } from '@services/friendly-error.service';
import { take } from "rxjs/operators";

@Component({
  selector: "app-edit-feature-preference-modal",
  templateUrl: "./edit-feature-preference-modal.component.html",
  styleUrls: ["./edit-feature-preference-modal.component.css"],
})
export class EditFeaturePreferenceModalComponent implements OnInit {
  featurePreferenceTypes: FeatureType[] = [];
  headerString = "Add New Feature Preference (DEMO MODE)";
  featureTypeName: string = '';
  featureCategory: string = undefined;
  schema: any;
  schemaDefinition: any;
  reportTemplates: ReportTemplate[] = [];
  reportTemplate: any;
  reportTemplateName: string = undefined;
  // name

  constructor(
    private featurePreferenceService: FeaturePreferenceService,
    private featureTypeService: FeatureTypeService,
    private reportTemplateService: ReportTemplateService,
    private friendlyErrorService: FriendlyErrorService,
    public dialogRef: MatDialogRef<EditFeaturePreferenceModalComponent>
  ) {}

  async ngOnInit() {

  }

  async submit() {
    // functionality to add new feature types to db
    this.featurePreferenceTypes = await this.featureTypeService.getAllByName(this.featureTypeName).pipe(take(1)).toPromise();
    let newFeatureType: FeatureType = new FeatureType();
    if (this.featurePreferenceTypes.length > 0) {
      newFeatureType.versionNum = this.featurePreferenceTypes[0].versionNum + 1;
    } else {
      newFeatureType.versionNum = 1;
    }
    newFeatureType.featureTypeName = this.featureTypeName;
    newFeatureType.featureTypeSchema = JSON.parse(this.schema);
    newFeatureType.featureTypeDefinition = JSON.parse(this.schemaDefinition);
    newFeatureType.featureCategory = this.featureCategory;
    
    this.featureTypeService.create(newFeatureType).pipe(take(1)).toPromise()
    .then(async result => {
      // functionality to add new report templates to db
      if (this.featureCategory === 'REPORT') {
        this.reportTemplates = await this.reportTemplateService.getAllByName(this.reportTemplateName).pipe(take(1)).toPromise();
        let newReportTemplate: ReportTemplate = new ReportTemplate();
        if (this.reportTemplates.length > 0) {
          newReportTemplate.versionNum = this.reportTemplates[0].versionNum + 1;
        } else {
          newReportTemplate.versionNum = 1;
        }
        newReportTemplate.reportTemplateName = this.reportTemplateName;
        newReportTemplate.reportTemplateFile = this.reportTemplate;
        newReportTemplate.featureTypeId = result.featureTypeId;
  
        this.reportTemplateService.create(newReportTemplate).pipe(take(1)).toPromise();
      }
    })
    .catch(error => {
      console.error("Error creating a new report template: ", error);
      this.friendlyErrorService.processError(error)
    });

    this.dialogRef.close(ModalResponse.ADD);
  }

  cancel() {
    this.dialogRef.close(ModalResponse.CANCEL);
  }
}
