import { EditUnitModalComponent } from '@edit-modals/edit-unit-modal/edit-unit-modal.component';
import { UnitService } from '@services/data-services/unit.service';
import { Router } from '@angular/router';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { GlobalMessageTriggers } from '@services/global/message-bus/global-message-triggers.enum';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { GridBaseComponent } from '@fom-module/base-classes/grid-base.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { LookUpService } from '@services/data-services/lookup.service';
import { Command } from '@models/command.model';
import { MatDialog } from '@angular/material/dialog';
import { Unit } from '@models/unit.model';
import { User } from '@models/user.model';
import { Sort } from '@angular/material/sort';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { UtilityService } from '@services/utility.service';
import { CurrentUserService } from '@services/current-user-service';
import { CountryService } from '@services/data-services/country.service';
import { AppInitService } from '../../../config/init.service';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { Subject, Subscription } from 'rxjs';
import { GridGroupingService, Group } from '@services/grid-grouping.service';
import { ConfirmationModalComponent } from '@core-module/app-components/confirmation-modal/confirmation-modal.component';
import { LocationService } from '@services/data-services/location.service';
import { UnitDetailsComponent } from '@fom-module/unit-details/unit-details.component';
import { ReportingService } from '@services/data-services/reporting.service';
import { OtherNameTableModal } from '@fom-module/other-name-table-modal/other-name-table-modal.component';

const DEFAULT_SORT: Sort = {
  active: 'isActive',
  direction: 'asc'
}

@Component({
  selector: 'app-units',
  templateUrl: './units.component.html',
  styleUrls: ['./units.component.css', '../base-classes/grid-base.component.css']
})
export class UnitsComponent extends GridBaseComponent implements OnInit {
  allColumns = [
    { name: 'currentCommand.name', supervisorVisible: true },
    { name: 'fleet.fleetCode', supervisorVisible: true },
    { name: 'class', supervisorVisible: true },
    { name: 'type', supervisorVisible: true },
    { name: 'pennant', supervisorVisible: true },
    { name: 'unitOtherNames', supervisorVisible: true },
    { name: 'port.name', supervisorVisible: true },
    { name: 'category.category', supervisorVisible: true },
    { name: 'threat.threatName', supervisorVisible: true },
    { name: 'ship.shipTypeName', supervisorVisible: true },
    { name: 'country.name', supervisorVisible: true },
    { name: 'sconum', supervisorVisible: true },
    { name: 'midb', supervisorVisible: true },
    { name: 'isActive', supervisorVisible: true },
    { name: 'info', supervisorVisible: true },
    { name: 'edit', supervisorVisible: true },
    // Track Maintenance is hidden from Supervisors
    { name: 'track-maintenance', supervisorVisible: true },
    { name: 'delete', supervisorVisible: true },
  ];
  displayedColumns: any;

  // need to retain the filtering value for the refresh hack
  filterValue = '';
  lastFilter = null;
  // let's filter the data on these columns
  filterColumns = [
    'class', 'type', 'pennant'
  ];
  // to debounce the filter input
  filterTextChanged: Subject<string> = new Subject<string>();

  // these objects represent the columns that can be grouped
  groupItems = [
    { display: 'No Grouping', value: 'All', column_name: null },
    { display: 'Class', value: 'class', column_name: 'class' },
    { display: 'Type', value: 'type', column_name: 'type' },
    { display: 'Ship Type', value: 'ship.shipTypeName', column_name: 'ship.shipTypeName' },
    { display: 'Flag', value: 'country.name', column_name: 'country.name' },
    { display: 'Home Port', value: 'port.name', column_name: 'port.name' },
    { display: 'Command', value: 'currentCommand.name', column_name: 'currentCommand.name' },
    { display: 'Delegated', value: 'borrowerName', column_name: 'borrowerName' }
  ];
  // the current (default) group will be the first one in the list
  public grouping = this.groupItems[0];
  groupByColumns: string[] = [this.grouping.value];
  groupCount = 0;

  public dataSource = new TableVirtualScrollDataSource<any | Group>();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  units: Unit[];
  user: User;
  commands: [Command];

  readyForExport: boolean = true;
  exportSubscripton: Subscription;

  constructor(public messageBusService: MessageBusService,
    public unitService: UnitService, public utilities: UtilityService,
    private lookupService: LookUpService, private dialog: MatDialog,
    private locationService: LocationService,
    public route: Router, private currentUserService: CurrentUserService,
    private countryService: CountryService,
    public initService: AppInitService,
    private gridGroupingService: GridGroupingService,
    private reportingService: ReportingService) {
    super(messageBusService, unitService, route);
    this.RELOAD_MESSAGE = GlobalMessageTriggers.RELOAD_UNIT_DATA;
    this.EDIT_MODAL_COMPONENT = EditUnitModalComponent;
    this.filterTextChanged
      .pipe(debounceTime(1000), // wait 1 sec after the last event before emitting last event
        distinctUntilChanged()) // only emit if value is different from previous value
      .subscribe(model => {
        // Expand all groups so that after filtering matching groups are expanded regardless of starting state.
        this.changeAllGroups(true);
        // pull in the ngModel value to the dataSourceFilter
        this.dataSource.filter = model.trim().toLocaleLowerCase();
      });
  }

  ngOnInit() {
    // this.listenToMessageBus();
    this.user = this.currentUserService.getCurrentUser();

    // don't like the grouped column being hidden
    // this.displayedColumns = this.allColumns.filter(value => value !== this.grouping.column_name);

    // Filter the columns based on whether user is an Admin or Supervisor.
    let columns;
    if (this.user.isAdmin()) {
      columns = this.allColumns;
    } else {
      columns = this.allColumns.filter(e => e.supervisorVisible);
    }
    this.displayedColumns = columns.map(e => e.name);

    // Set paginator before rendering data, so that a rerender isn't required to add the pagination.
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = this.dataAccessor;
    this.dataSource.filterPredicate = this.customFilterPredicate.bind(this);

    this.lookupService.getLookupByType(Command).pipe(take(1)).subscribe(response => {
      this.commands = response;
      // console.log(this.commands)
      this.loadUnits();
    });
    // super.ngOnInit();
  }

  dataAccessor = (item, property) => {
    let propertyValue;
    if (property.includes('.')) {
      propertyValue = property.split('.').reduce((o, p) => o && o[p], item);
    } else {
      propertyValue = item ? item[property] : null;
    }

    // Force strings to a common casing to prevent issues with mixed cases when sorting.
    return typeof propertyValue === 'string' ? propertyValue.toUpperCase() : propertyValue;
  }

  setDataSource(entities) {
    entities.forEach(element => {
      let borrowerName = '';
      if (element.borrowerCommandId) {
        const found = this.commands.find(command => command.commandId === element.borrowerCommandId);
        if (found) {
          borrowerName = found.name;
        }
      }
      // if the unit is loaned out we will show this in the UI
      (element as any).borrowerName = borrowerName;
      // current command will be the command, switching the borrow display rules so that
      // the real owner had all of its units
      (element as any).currentCommand = element.port && element.port.command ? element.port.command : { name: 'Removed' };
      (element as any).canEdit = this.user.canEdit(element);
      (element as any).flagSource = this.getFlag(element.country);
    });
    this.entities = entities;
    this.units = entities;  // /stash them
    this.sortData(DEFAULT_SORT);
  }

  loadUnits() {
    const filters = JSON.parse(sessionStorage.getItem('unitFilters'));

    this.unitService.getAll(filters).pipe(take(1)).subscribe(entities => {
      this.setDataSource(entities);
    });
  }

  // when the user is typing the filter action will wait a sec before applying
  onFilterChange(event) {
    this.filterTextChanged.next(event);
  }

  edit(unit: any) {
    // removing this pending decision on whether borrower can edit
    if (!this.user.canEdit(unit)) {
      return;
    }
    const dialogRef = this.dialog.open(EditUnitModalComponent, {
      width: '680px'
    });
    dialogRef.componentInstance.unit = unit;
    dialogRef.componentInstance.isNew = false;
    dialogRef.componentInstance.units = this.units;

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      if (result) {
        this.loadUnits();
      }
    });
  }

  add() {
    const dialogRef = this.dialog.open(EditUnitModalComponent, {
      width: '680px'
    });
    dialogRef.componentInstance.unit = new Unit();
    dialogRef.componentInstance.isNew = true;
    dialogRef.componentInstance.units = this.units;

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      // in order to get complete data for the new Unit, go back to the DB
      if (result) {
        this.loadUnits();
      }
    });
  }

  // unit filters applied
  unitFiltersChanged(event) {
    this.loadUnits();
  }

  // grouping
  customFilterPredicate(data: any | Group, filter: string): boolean {
    const triggered = filter === this.lastFilter ? true : false;

    if (data instanceof Group) {
      if (triggered && !data.expanded) {
        // If group row was clicked and the group is collapsed, return true to show the group row.
        return true;
      } else {
        // Otherwise show the group if it contains any items that matches the filterValue.
        return data.items.find(x => this.matchesFilter(x, this.filterValue));
      }
    } else {
      if (triggered && !data.group.expanded) {
        // If a group row was clicked and the group is collapsed, hide any data rows within that group
        return false;
      } else {
        // Otherwise only show a data row if it matches the filterValue.
        return this.matchesFilter(data, this.filterValue);
      }
    }
  }

  matchesFilter(data: any, filter: string): boolean {
    return data.fullName().toLowerCase().indexOf(filter.toLowerCase()) > -1;
  }

  groupingChanged(event, grouping) {
    this.groupByColumns = [grouping.value];

    // when grouping changes, sort by grouping and then isActive
    this.sortData(DEFAULT_SORT);
  }

  groupHeaderClick(row) {
    row.expanded = !row.expanded
    this.lastFilter = performance.now().toString()
    this.dataSource.filter = this.lastFilter;
  }

  isGroup(index, item): boolean {
    return item.isGroup;
  }

  sortData(sort: Sort) {
    if (!sort.active || sort.direction === '') {
      sort = DEFAULT_SORT;
    }

    const isAsc = sort.direction === 'asc';
    const data = this.units.sort((a, b) => {
      return this.compare(a, b, isAsc, sort.active);
    });

    this.dataSource.data = this.gridGroupingService.buildDataSourceData(data, this.groupByColumns[0])
  }

  compare(a, b, isAsc, active) {
    if (this.grouping.value) {
      const aGroupValue = this.dataAccessor(a, this.grouping.value);
      const bGroupValue = this.dataAccessor(b, this.grouping.value);
      // sort by grouped column first, then the active sort column
      if (aGroupValue > bGroupValue) {
        return 1;
      }
      if (aGroupValue < bGroupValue) {
        return -1;
      }
    }
    return (this.dataAccessor(a, active) < this.dataAccessor(b, active) ? -1 : 1) * (isAsc ? 1 : -1);
  }

  getFlag(country) {
    return this.countryService.getFlagUrl(country);
  }

  async deleteUnit(unit: Unit) {
    const locations: Location[] = await this.locationService.getAll({ unit_id: unit.unitId }).toPromise();

    const message = `${unit.fullName()} currently has ${locations.length} track(s) associated with it. Are you sure you want to completely delete ${unit.fullName()} and all of its track locations?`
    this.handleDelete(unit, 'Delete Unit', message, 'Delete');
  }

  navigateTrackMaintenance(unit: Unit): void {
    this.route.navigate(['/admin/tasks/track-maintenance'], { state: { data: { unit_id: unit.unitId } } });
  }

  hasMultipleGroups(): boolean {
    return this.dataSource.filteredData.filter((e, index) => this.isGroup(index, e)).length > 1;
  }

  hasAnyExpandedGroups(): boolean {
    return this.dataSource.filteredData.filter((e: any, index) => this.isGroup(index, e) && e.expanded).length > 0;
  }

  changeAllGroups(expanded: boolean): void {
    this.dataSource.filteredData.filter((e, index) => this.isGroup(index, e)).forEach((e: any) => e.expanded = expanded);

    // force filter to run again
    this.lastFilter = performance.now().toString();
    this.dataSource.filter = this.lastFilter;
  }

  showUnitDetails(unit: Unit): void {
    const dialogRef = this.dialog.open(UnitDetailsComponent, {
      width: '700px',
      data: {
        unitId: unit.unitId
      }
    });
    

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      switch(result) {
        case 'edit':
          this.edit(unit);
          break;
        case 'delete':
          this.deleteUnit(unit);
          break;
      }
    });
  }

  showUnitOtherNames(unit: Unit): void {
    // const unitOtherNames = this.unitService.getUnitOtherNames(unit);
    // console.log("OtherNames: " + unitOtherNames)
    const dialogRef = this.dialog.open(OtherNameTableModal, {
      width: '1000px',
      data: {
        unitId: unit.unitId
      }
    });
    
    // this.unitService.getAll(filters).pipe(take(1)).subscribe(entities => {
    //   console.log("getAll: " + entities),
    //   this.setDataSource(entities);
    // });

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      switch(result) {
        case 'edit':
          this.edit(unit);
          break;
        case 'delete':
          this.deleteUnit(unit);
          break;
      }
    });
    dialogRef.componentInstance.unitId = unit.unitId;
  }

  exportToExcel() {
    this.readyForExport = false;

    this.exportSubscripton = this.reportingService.getUnitExcel().pipe(take(1)).subscribe(excel => {
      this.exportSubscripton.unsubscribe();
      this.readyForExport = true;
      this.download(excel.body, 'FDT-M Units Export.xlsx');
    }, err => {
      this.exportSubscripton.unsubscribe();
      this.readyForExport = true;
      // pass error up to global ErrorHandler
      throw err;
    });
  }

  download(report: any, filename: string): void {
    const file = new Blob([report]);

    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(file); // xhr.response is a blob
    a.style.display = 'none';
    a.download = filename;
    document.body.appendChild(a);
    a.click();
  }
}
