import { Area } from '@models/area.model';
import { AreaApiService } from '@services/api-services/area-api.service';
import { Injectable } from '@angular/core';
import { JsonConverter } from '@services/global/json-converter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AreaService {
    private jsonConvert: JsonConverter;

    constructor(private areaApiService: AreaApiService) {
        this.jsonConvert = new JsonConverter();
    }

    public getAll(filters = {}): Observable<Area[]> {
        return this.areaApiService.getAllAreas(filters)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, Area);
                })
            );
    }

    public getAreaByAreaId(opAreaId: string): Observable<Area> {
        return this.areaApiService.getAreaByAreaId(opAreaId)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Area);
                })
            );
    }

    public update(area: Area): Observable<Area> {
        return this.areaApiService.updateArea(this.jsonConvert.serializeObject(area))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Area);
                })
            );
    }

    public create(area: Area): Observable<Area> {
        return this.areaApiService.createArea(this.jsonConvert.serializeObject(area))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Area);
                })
            );
    }

    public delete(area: Area): Observable<Area> {
        return this.areaApiService.deleteArea(this.jsonConvert.serializeObject(area))
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }
}
