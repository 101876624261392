import { JsonObject, JsonProperty, Any } from 'json2typescript';
import { Command } from '@models/command.model';
import { User } from './user.model';
import { Permission } from './permission.model';

@JsonObject('AccountRequest')
export class AccountRequest {
  @JsonProperty('id', String, true)
  accountRequestId: string = undefined;
  @JsonProperty('user_id', String, true)
  userId: string = undefined;
  @JsonProperty('user_name', String, true)
  userName: string = undefined;
  @JsonProperty('permission_id', String, true)
  permissionId: string = undefined;
  @JsonProperty('justification', String, true)
  justification: string = undefined;
  @JsonProperty('command_id', String, true)
  commandId: string = undefined;
  @JsonProperty('email', String, true)
  email: string = undefined;
  @JsonProperty('first_name', String, true)
  firstName: string = undefined;
  @JsonProperty('last_name', String, true)
  lastName: string = undefined;
  @JsonProperty('command', Command, true)
  command: Command = undefined;
  @JsonProperty('user', User, true)
  user: User = undefined;
  @JsonProperty('permission', Permission, true)
  permission: Permission = undefined;
  @JsonProperty('created_by', String, true)
  createdBy: string = undefined;
}
