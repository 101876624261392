<app-classification-bar class="top-modal-classification-bar"></app-classification-bar>

<app-fdtm-modal-header [headerString]="headerString"></app-fdtm-modal-header>

<div mat-dialog-content>
    <form [formGroup]="editForm">
        <div class="container" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxLayoutGap.lt-md="0">
            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-form-field class="fdtm-input-field" [ngClass]="{'fdtm-edited-field' : editForm.controls.classificationId.valid && editForm.controls.classificationId.value != entity.classificationId }">
                    <mat-select formControlName="classificationId" placeholder="Classification" required tabindex="1" autofocus >
                        <mat-option *ngFor="let classification of classifications" [value]="classification.classificationId">
                            {{classification.classificationName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-form-field class="fdtm-input-field"
                    [ngClass]="{ 'fdtm-edited-field' : (this.entitySciIds | json)  != (editForm.controls.sciControls.value | json) }">
                    <mat-select formControlName="sciControls" placeholder="SCI Controls" tabindex="2" multiple>
                        <mat-option *ngFor="let sci of sciControls" [value]="sci.sciControlId">
                            {{sci.controlMark}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="container" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxLayoutGap.lt-md="0">
            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-form-field class="fdtm-input-field"
                    [ngClass]="{ 'fdtm-edited-field' : (this.entityFgiIds | json) != (editForm.controls.fgiControls.value | json) }">
                    <mat-select formControlName="fgiControls" placeholder="FGI Controls" tabindex="3" multiple>
                        <mat-option *ngFor="let fgi of fgiControls" [value]="fgi.fgiId">
                            {{fgi.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-form-field class="fdtm-input-field"
                    [ngClass]="{ 'fdtm-edited-field': (this.entityOtherIds | json) != (editForm.controls.otherControls.value | json) }">
                    <mat-select formControlName="otherControls" placeholder="Other Controls" tabindex="4" multiple>
                        <mat-option *ngFor="let control of otherControls" [value]="control.controlId">
                            {{control.description}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="container" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxLayoutGap.lt-md="0">
            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-form-field class="fdtm-input-field"
                    [ngClass]="{ 'fdtm-edited-field' : (this.entityDissemIds | json) != (editForm.controls.disseminations.value | json) }">
                    <mat-select formControlName="disseminations" placeholder="Disseminations" tabindex="3" multiple>
                        <mat-option *ngFor="let dissemination of disseminations" [value]="dissemination.releasibilityId">
                            {{dissemination.releasabilityName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="container" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxLayoutGap.lt-md="0">
            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-form-field class="fdtm-input-field" [ngClass]="{'fdtm-edited-field' : editForm.controls.fleetId.value != entity.fleetId}">
                    <mat-select formControlName="fleetId" placeholder="Fleet" required tabindex="1" autofocus>
                        <mat-option *ngFor="let fleet of fleets" [disabled]="fleet.isDisabled" [value]="fleet.fleetId">
                            {{fleet.fleetCode}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-form-field class="fdtm-input-field" [floatLabel]="editForm?.controls?.locTime?.value?.length"
                    [ngClass]="{ 'fdtm-edited-field' : editForm.controls.locTime.value != entity.locTime }">
                    <input matInput [owlDateTime]="locTime" required formControlName="locTime" autocomplete="off"
                        placeholder="Location Time (d M Y hm)" [max]="this.allowFutureDates ? undefined : maxDate" tabindex="9" [readonly]="fromParser">
                    <owl-date-time #locTime></owl-date-time>
                    <span class="hyper" matSuffix [owlDateTimeTrigger]="locTime">
                        <mat-icon class="no-line">calendar_today</mat-icon>
                    </span>

                </mat-form-field>
            </div>
        </div>

        <div class="container" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxLayoutGap.lt-md="0">
            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-form-field class="fdtm-input-field" [floatLabel]="editForm?.controls?.dtgImaged?.value?.length"
                    [ngClass]="{ 'fdtm-edited-field' : editForm.controls.dtgImaged.value != entity.dtgImaged }">
                    <input matInput [owlDateTime]="dtgImaged" required formControlName="dtgImaged" autocomplete="off"
                        placeholder="Last Imaged DTG (d M Y hm)" [max]="this.allowFutureDates ? undefined : maxDate" tabindex="2" [readonly]="fromParser">
                    <owl-date-time #dtgImaged></owl-date-time>
                    <span class="hyper" matSuffix [owlDateTimeTrigger]="dtgImaged">
                        <mat-icon class="no-line">calendar_today</mat-icon>
                    </span>
                </mat-form-field>
            </div>

            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-form-field class="fdtm-input-field" [ngClass]="{ 'fdtm-edited-field' : editForm.controls.activityCategoryId.value != entity.activityCategoryId }">
                    <mat-select formControlName="activityCategoryId" placeholder="Activity Category" required tabindex="10"
                        #activityField (selectionChange)="selectActivity(activityField.value)">
                        <mat-option *ngFor="let activityCategory of activityCategories"
                            [disabled]="activityCategory.isDisabled" [value]="activityCategory.activityId">
                            {{activityCategory.description}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="container" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxLayoutGap.lt-md="0">
            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-form-field class="fdtm-input-field" [floatLabel]="editForm?.controls?.mfiTs?.value?.length"
                    [ngClass]="{ 'fdtm-edited-field' : editForm.controls.mfiTs.value != entity.mfiTs }">
                    <input matInput [owlDateTime]="mfiTs" formControlName="mfiTs" autocomplete="off"
                        placeholder="MFI DTG (d M Y hm)" [max]="this.allowFutureDates ? undefined : maxDate" tabindex="3" [readonly]="fromParser">
                    <owl-date-time #mfiTs></owl-date-time>
                    <span class="hyper" matSuffix [owlDateTimeTrigger]="mfiTs">
                        <mat-icon class="no-line">calendar_today</mat-icon>
                    </span>
                </mat-form-field>
            </div>

            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-form-field class="fdtm-input-field" [ngClass]="{ 'fdtm-edited-field' : editForm.controls.activity.valid && editForm.controls.activity.value != entity.activity }">
                    <input matInput formControlName="activity" placeholder="Activity" tabindex="11" [readonly]="activityReadOnly">
                </mat-form-field>
            </div>
        </div>

        <div class="container" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxLayoutGap.lt-md="0">
            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-form-field class="fdtm-input-field" [ngClass]="{ 'fdtm-edited-field' : editForm.controls.latitude.valid && editForm.controls.latitude.value != entity.latitude }">
                    <input matInput formControlName="latitude" placeholder="Latitude" required
                        oninput="this.value = this.value.toUpperCase()" tabindex="4">
                    <mat-hint>DMS or Decimal</mat-hint>
                    <mat-error *ngIf="editForm?.controls?.latitude?.hasError('format')">Invalid format</mat-error>
                    <mat-error *ngIf="editForm?.controls?.latitude?.hasError('length')">Length too long or too short
                    </mat-error>
                    <mat-error *ngIf="editForm?.controls?.latitude?.hasError('range')">Out of range</mat-error>
                    <mat-error *ngIf="editForm?.controls?.latitude?.hasError('required')">Required</mat-error>
                </mat-form-field>
            </div>

            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-form-field class="fdtm-input-field" [ngClass]="{ 'fdtm-edited-field' : editForm.controls.opAreaId.value != entity.opAreaId }">
                    <mat-select formControlName="opAreaId" placeholder="U/W Area" required tabindex="12">
                        <mat-option *ngFor="let opArea of opAreas" [value]="opArea.opAreaId">{{opArea.areaRemarks()}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="container" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxLayoutGap.lt-md="0">
            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-form-field class="fdtm-input-field" [ngClass]="{ 'fdtm-edited-field' : editForm.controls.longitude.valid && editForm.controls.longitude.value != entity.longitude }">
                    <input matInput formControlName="longitude" placeholder="Longitude" required
                        oninput="this.value = this.value.toUpperCase()" tabindex="5">
                    <mat-hint>DMS or Decimal</mat-hint>
                    <mat-error *ngIf="editForm?.controls?.longitude?.hasError('format')">Invalid format</mat-error>
                    <mat-error *ngIf="editForm?.controls?.longitude?.hasError('length')">Length too long or too short
                    </mat-error>
                    <mat-error *ngIf="editForm?.controls?.longitude?.hasError('range')">Out of range</mat-error>
                    <mat-error *ngIf="editForm?.controls?.longitude?.hasError('required')">Required</mat-error>
                </mat-form-field>
            </div>

            <div fxFlex="50%" fxFlex.lt-md="100%">
                <div class="fdtm-input-field fdtm-checkbox" [ngClass]="{ 'fdtm-edited-field' : editForm.controls.patCandInd.value != entity.patCandInd }">
                    <mat-checkbox formControlName="patCandInd" placeholder="Patrol Cand." tabindex="13">
                        Patrol Cand.
                    </mat-checkbox>
                </div>
            </div>
        </div>

        <div class="container" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxLayoutGap.lt-md="0">
            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-form-field class="fdtm-input-field" [ngClass]="{ 'fdtm-edited-field' : editForm.controls.lastLoc.valid && editForm.controls.lastLoc.value != entity.lastLoc }">
                    <input matInput formControlName="lastLoc" placeholder="Last Location" required tabindex="6">
                    <span matSuffix class="hyper" aria-label="Recalculate Last Location" *ngIf="canCalculateLastLoc"
                        matTooltip="Recalculate Last Location" (click)="calculateLastLoc()">
                        <mat-icon>sync</mat-icon>
                    </span>
                </mat-form-field>
            </div>

            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-form-field class="fdtm-input-field" [ngClass]="{ 'fdtm-edited-field' : editForm.controls.papaCase.value != entity.papaCase }">
                    <input matInput formControlName="papaCase" placeholder="Case No." tabindex="14">
                </mat-form-field>
            </div>
        </div>

        <div class="container" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxLayoutGap.lt-md="0">
            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-form-field class="fdtm-input-field" [ngClass]="{ 'fdtm-edited-field' : editForm.controls.lastLocSrc.value != entity.lastLocSrc }">
                    <mat-select formControlName="lastLocSrc" placeholder="Source" required tabindex="7">
                        <mat-option *ngFor="let source of sources" [disabled]="source.isDisabled"
                            [value]="source.sourceId">
                            {{source.sourceName}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div fxFlex="50%" fxFlex.lt-md="100%">
                <div class="fdtm-input-field fdtm-checkbox" [ngClass]="{ 'fdtm-edited-field' : editForm.controls.tunnelInd.value != entity.tunnelInd }">
                    <mat-checkbox formControlName="tunnelInd" placeholder="In Tunnel" tabindex="15">
                        In Tunnel
                    </mat-checkbox>
                </div>
            </div>
        </div>

        <div class="container" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxLayoutGap.lt-md="0">
            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-form-field class="fdtm-input-field" [ngClass]="{ 'fdtm-edited-field' : editForm.controls.srcOrig.valid && editForm.controls.srcOrig.value != entity.srcOrig }">
                    <input matInput formControlName="srcOrig" placeholder="Originator" required tabindex="8">
                </mat-form-field>
            </div>

            <div fxFlex="50%" fxFlex.lt-md="100%">
                <div class="fdtm-input-field fdtm-checkbox" [ngClass]="{'fdtm-edited-field' : editForm.controls.nobRemoveInd.value != entity.nobRemoveInd }">
                    <mat-checkbox formControlName="nobRemoveInd" placeholder="Remove from NOB View" tabindex="16">
                        Remove from NOB View
                    </mat-checkbox>
                </div>
            </div>
        </div>

        <!-- BEGIN elint FIELDS -->
        <div class="container" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxLayoutGap.lt-md="0">
            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-form-field class="fdtm-input-field">
                    <input matInput formControlName="el_not" placeholder="ELNOT" tabindex="8" [readonly]="true">
                </mat-form-field>
            </div>

            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-form-field class="fdtm-input-field">
                    <input matInput formControlName="provider" placeholder="Provider" tabindex="8" [readonly]="true">
                </mat-form-field>
            </div>
        </div>

        <div class="container" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxLayoutGap.lt-md="0">
            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-form-field class="fdtm-input-field">
                    <input matInput formControlName="freq" placeholder="Frequency" tabindex="8" [readonly]="true">
                </mat-form-field>
            </div>

            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-form-field class="fdtm-input-field">
                    <input matInput formControlName="band" placeholder="Bandwidth" tabindex="8" [readonly]="true">
                </mat-form-field>
            </div>
        </div>

        <div class="container" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxLayoutGap.lt-md="0">
            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-form-field class="fdtm-input-field">
                    <input matInput formControlName="func" placeholder="Function" tabindex="8" [readonly]="true">
                </mat-form-field>
            </div>

            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-form-field class="fdtm-input-field">
                    <input matInput formControlName="pulDur" placeholder="Pulse Duration" tabindex="8" [readonly]="true">
                </mat-form-field>
            </div>
        </div>

        <div class="container" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxLayoutGap.lt-md="0">
            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-form-field class="fdtm-input-field">
                    <input matInput formControlName="pri" placeholder="PRI" tabindex="8" [readonly]="true">
                </mat-form-field>
            </div>

            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-form-field class="fdtm-input-field">
                    <input matInput formControlName="scanRate" placeholder="Scan Rate" tabindex="8" [readonly]="true">
                </mat-form-field>
            </div>
        </div>

        <div class="container" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxLayoutGap.lt-md="0">
            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-form-field class="fdtm-input-field">
                    <input matInput formControlName="scanMeasure" placeholder="Scan Measure" tabindex="8" [readonly]="true">
                </mat-form-field>
            </div>

            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-form-field class="fdtm-input-field">
                    <input matInput formControlName="scanType" placeholder="Scan Type" tabindex="8" [readonly]="true">
                </mat-form-field>
            </div>
        </div>

        <div class="container" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxLayoutGap.lt-md="0">
            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-form-field class="fdtm-input-field">
                    <input matInput formControlName="toiTs" placeholder="TOI Timestamp" tabindex="8" [readonly]="true">
                </mat-form-field>
            </div>
        </div>
        <!-- END elint FIELDS -->

        <div class="container" fxLayout="row" fxLayout.lt-md="column">
            <div fxFlex="100%">
                <mat-form-field class="fdtm-input-field">
                    <mat-label>Change Reason</mat-label>
                    <textarea formControlName="rationale" matInput required tabindex="17"></textarea>
                </mat-form-field>
            </div>
        </div>

        <div *ngIf="isDelete" class="container" fxLayout="row" fxLayout.lt-md="column">
            <div fxFlex="100%" class="mat-color-warn warning">
                NOTE: This action will only delete this location's information from the Unit's history, the Unit itself will not be deleted.
            </div>
        </div>

        <mat-error *ngIf="editForm?.hasError('locationTimeBeforeLastImaged')">
            Location Time cannot occur before Last Imaged DTG
        </mat-error>

        <mat-error *ngIf="mfiReadOnly">
            <span class="mat-color-warn">MFI Track; can only delete</span>
        </mat-error>

        <div mat-dialog-actions>
            <button mat-raised-button color="primary" *ngIf="!isDelete" [disabled]="!editForm.valid || !hasChanges || mfiReadOnly" (click)="submit()">Submit</button>
            <button mat-raised-button color="warn" *ngIf="isDelete" [disabled]="!editForm.valid || fromParser" (click)="submit()">Delete</button>
            <button mat-raised-button (click)="cancel()">Cancel</button>
        </div>
    </form>
</div>

<app-classification-bar class="bottom-modal-classification-bar"></app-classification-bar>
