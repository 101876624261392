import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Activity } from '@models/activity.model';
import { Area } from '@models/area.model';
import { Classification } from '@models/classification.model';
import { Control } from '@models/control.model';
import { Fgi } from '@models/fgi.model';
import { Fleet } from '@models/fleet.model';
import { Location } from '@models/location.model';
import { OtherControl } from '@models/other-control.model';
import { Releasability } from '@models/releasability.model';
import { Source } from '@models/source.model';
import { User } from '@models/user.model';
import { ClassificationDescriptionService } from '@services/classification-description.service';
import { CurrentUserService } from '@services/current-user-service';
import { LocationService } from '@services/data-services/location.service';
import { LookUpService } from '@services/data-services/lookup.service';
import { PicklistPrefService } from '@services/data-services/picklist-pref.service';
import { SortingService } from '@services/sorting.service';
import { UtilityService } from '@services/utility.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppInitService } from '../../../../config/init.service';

const IGNORED_CHANGED_ATTRIBUTES = [ 'rationale' ];

// Map of form control name to changeset attribute name.
const CONTROL_ATTRIBUTE_MAP: Object = {
  activity: "activity",
  activityCategoryId: 'activity_category_id',
  classificationId: 'classification_id',
  dtgImaged: 'dtg_imaged_ts',
  fleetId: 'fleet_id',
  lastLoc: 'last_loc',
  lastLocSrc: 'last_loc_src',
  mfiTs: 'mfi_ts',
  opAreaId: 'op_area_id',
  papaCase: 'papa_case',
  rationale: 'rationale',
  scOrig: 'src_orig'
}

@Component({
  selector: 'app-bulk-edit-location-modal',
  templateUrl: './bulk-edit-location-modal.component.html',
  styleUrls: ['./bulk-edit-location-modal.component.css']
})
export class BulkEditLocationModalComponent implements OnInit, OnDestroy {
  entities: Location[];
  isDelete: boolean;

  editForm: FormGroup;

  headerString: string;

  allowFutureDates: boolean;
  maxDate: Date;

  // Tracks if form has changed values to enable submission on bulk edit.
  hasChanges = false;
  changeSubscription: Subscription;

  user: User;

  // Lookups
  activityCategories: Activity[];
  classifications: Classification[];
  disseminations: Releasability[];
  fgiControls: Fgi[];
  fleets: Fleet[];
  opAreas: Area[];
  otherControls: OtherControl[];
  sciControls: Control[];
  sources: Source[];

  constructor(
    private currentUserService: CurrentUserService,
    private classDescriptionService: ClassificationDescriptionService,
    public dialogRef: MatDialogRef<BulkEditLocationModalComponent>,
    private formBuilder: FormBuilder,
    private initService: AppInitService,
    private locationService: LocationService,
    private lookupService: LookUpService,
    private picklistPrefService: PicklistPrefService,
    private sortingService: SortingService,
    private utilities: UtilityService) {

  }

  ngOnInit(): void {
    const prefix = this.isDelete ? 'Delete' : 'Edit'
    this.headerString = `Bulk ${prefix} ${this.entities.length} Location(s)`;

    this.user = this.currentUserService.getCurrentUser();

    this.allowFutureDates = this.initService.getConfig().allowFutureDates;
    this.maxDate = new Date();

    Promise.resolve().then(() => {
      this.loadLookups();
    });

    this.editForm = this.formBuilder.group({
      activity: [{ value: null, disabled: this.isDelete }],
      activityCategoryId: [{ value: null, disabled: this.isDelete }],
      classificationId: [{ value: null, disabled: this.isDelete }],
      disseminations: [{ value: null, disabled: true }],
      dtgImaged: [{ value: null, disabled: this.isDelete }],
      fgiControls: [{ value: null, disabled: true }],
      fleetId: [{ value: null, disabled: this.isDelete }],
      lastLoc: [{ value: null, disabled: this.isDelete }],
      lastLocSrc: [{ value: null, disabled: this.isDelete }],
      mfiTs: [{ value: null, disabled: this.isDelete }],
      opAreaId: [{ value: null, disabled: this.isDelete }],
      otherControls: [{ value: null, disabled: true }],
      papaCase: [{ value: null, disabled: this.isDelete }],
      rationale: [],
      sciControls: [{ value: null, disabled: true }],
      srcOrig: [{ value: null, disabled: this.isDelete }],
    });

    // Required at least one field to be set, otherwise bulk edit doesn't change anything.
    this.changeSubscription = this.editForm.valueChanges.subscribe(values => {
      this.hasChanges = Object.keys(values).some(key => !IGNORED_CHANGED_ATTRIBUTES.includes(key) && values[key] != null);
    });
  }

  ngOnDestroy(): void {
    this.changeSubscription.unsubscribe();
  }

  async loadLookups(): Promise<any> {
    this.classifications = await this.lookupService.getLookupByType(Classification).pipe(take(1)).toPromise();
    this.classifications.sort((a, b) => a.sortBy < b.sortBy ? 1 : -1);
    this.disseminations = await this.lookupService.getLookupByType(Releasability).pipe(take(1)).toPromise();
    this.fgiControls = await this.lookupService.getLookupByType(Fgi).pipe(take(1)).toPromise();
    this.otherControls = await this.lookupService.getLookupByType(OtherControl).pipe(take(1)).toPromise();
    this.sciControls = await this.lookupService.getLookupByType(Control).pipe(take(1)).toPromise();

    this.activityCategories = await this.lookupService.getLookupByType(Activity).pipe(take(1)).toPromise();
    this.fleets = await this.lookupService.getLookupByType(Fleet).pipe(take(1)).toPromise();
    this.opAreas = await this.lookupService.getLookupByType(Area).pipe(take(1)).toPromise();
    this.sources = await this.lookupService.getLookupByType(Source).pipe(take(1)).toPromise();

    const filter = { owner_id: this.user.unitOwnerId() };
    this.picklistPrefService.getAll(filter).pipe(take(1)).subscribe(preferences => {
      this.sortingService.sortList(this.activityCategories, preferences, 'description');
      this.sortingService.sortList(this.fleets, preferences, 'fleetCode');
      this.sortingService.sortList(this.opAreas, preferences, 'opArea')
      this.sortingService.sortList(this.sources, preferences, 'sourceName');
    });
  }

  submit(): void {
    const ids = this.entities.map(e => e.shipLocationId);

    if (this.isDelete) {
      if(confirm(`Are you sure you want to delete ${this.entities.length} locations?`)) {
        const rationale = this.editForm.controls.rationale.value;

        this.locationService.bulkDelete(ids, rationale).pipe(take(1)).subscribe(response => {
          this.dialogRef.close(response);
        });
      }

    } else {
      // handle bulk update.
      const changeSet = this.getChangeSet();

      this.locationService.bulkUpdate(ids, changeSet).pipe(take(1)).subscribe(response => {
        this.dialogRef.close(response);
      });
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  toggleClassificationFields(): void {
    // If a classification is selected, enable the the other controls unless they already are
    if (this.editForm.controls.classificationId.value) {
      if (this.editForm.controls.sciControls.disabled) this.editForm.controls.sciControls.enable();
      if (this.editForm.controls.fgiControls.disabled) this.editForm.controls.fgiControls.enable();
      if (this.editForm.controls.otherControls.disabled) this.editForm.controls.otherControls.enable();
      if (this.editForm.controls.disseminations.disabled) this.editForm.controls.disseminations.enable();
    } else {
      // disable the other controls unless already disabled and clear any values.
      if (this.editForm.controls.sciControls.enabled) this.editForm.controls.sciControls.disable();
      if (this.editForm.controls.fgiControls.enabled) this.editForm.controls.fgiControls.disable();
      if (this.editForm.controls.otherControls.enabled) this.editForm.controls.otherControls.disable();
      if (this.editForm.controls.disseminations.enabled) this.editForm.controls.disseminations.disable();

      if (this.editForm.controls.sciControls.value) this.editForm.controls.sciControls.setValue(null);
      if (this.editForm.controls.fgiControls.value) this.editForm.controls.fgiControls.setValue(null);
      if (this.editForm.controls.otherControls.value) this.editForm.controls.otherControls.setValue(null);
      if (this.editForm.controls.disseminations.value) this.editForm.controls.disseminations.setValue(null);
    }
  }

  getChangeSet(): Object {
    const results: any = {};

    Object.keys(this.editForm.controls).forEach(control => {
      if (this.editForm.controls[control].value && CONTROL_ATTRIBUTE_MAP[control]) {
        results[CONTROL_ATTRIBUTE_MAP[control]] = this.editForm.controls[control].value;
      }
    });

    // get class description...
    if (this.editForm.controls.classificationId.value) {
      results.classification_description = this.classDescriptionService.getClassificationDescription(
        this.editForm.controls.classificationId.value, this.editForm.controls.sciControls.value || [],
        this.editForm.controls.fgiControls.value || [], this.editForm.controls.otherControls.value || [],
        this.editForm.controls.disseminations.value || []);
    }

    return results;
  }

  getClassification(): string {
    let result = '';
    const classification = this.classifications.find(c => c.classificationId === this.editForm.controls.classificationId.value);
    result = classification.classificationName;

    const sciIds: string[] = this.editForm.controls.sciControls.value;
    if (sciIds && sciIds.length) {
      const controls = this.sciControls.filter(control => sciIds.includes(control.sciControlId));

      result = `${result}//${controls.map(e => e.controlMark.trim()).join('/')}`;
    }

    const fgiIds: string[] = this.editForm.controls.fgiControls.value;
    if (fgiIds && fgiIds.length) {
      const controls = this.fgiControls.filter(control => fgiIds.includes(control.fgiId));

      result = `${result}//FGI ${controls.map(e => e.name).join(',').trim()}`;
    }

    const controlIds: string[] = this.editForm.controls.otherControls.value;
    if (controlIds && controlIds.length) {
      const controls = this.otherControls.filter(control => controlIds.includes(control.controlId));

      result = `${result}//${controls.map(e => e.description).join(',').trim()}`;
    }

    const dissemIds: string[] = this.editForm.controls.disseminations.value;
    if (dissemIds && dissemIds.length) {
      const controls = this.disseminations.filter(control => dissemIds.includes(control.releasibilityId));

      if (controls.some(e => e.releasabilityName === 'NOFORN')) {
        result = `${result}//NOFORN`;
      } else {
        result = `${result}//REL TO ${controls.map(e => e.releasabilityName).join(',').trim()}`;
      }

    }

    return result;
  }
}
