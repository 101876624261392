<div class="admin-work-area">
    <div fxLayout fxLayoutAlign="center center" class="simple-grid-title">
      <span class="grid-title">{{initService.getConfig().titlePrefix}} FDT-M Powerpoint Slides</span>
      <!-- Add Unit Floating Action Button -->
      <button mat-mini-fab class="add-button" (click)="add()">
        <img class="light-icon" src="./assets/icons/ic_add_white_24px.svg" matTooltip="Add Slide" />
      </button>
    </div>
    <div fxLayout fxLayoutAlign="center center">
      <mat-form-field fxFlex="30%">
        <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Filter by Slide Name">
      </mat-form-field>
    </div>
    <div class="mat-elevation-z8">
          <!-- Paginator -->
          <mat-paginator [pageSizeOptions]="[10, 20, 50, 100, 1000]" [pageSize]="20" [length]="dataSource?.data?.length" showFirstLastButtons></mat-paginator>

          <table mat-table [dataSource]="dataSource" matSort matSortStart="desc">

                <!-- Data Columns -->
                <!----ng-container matColumnDef="fleet">
                  <th mat-header-cell *matHeaderCellDef> Fleet </th>
                  <td mat-cell *matCellDef="let element"> {{element.fleet.fleetCode}} </td>
                </ng-container-->

                <ng-container matColumnDef="slideName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                  <td mat-cell *matCellDef="let element"> {{element.slideName}} </td>
                </ng-container>

                <ng-container matColumnDef="flag">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Flag </th>
                    <td mat-cell *matCellDef="let element">
                      <img [src]="utilities.getCountryFlag(element?.country)" height="16">&nbsp;
                      {{element.country.name}} </td>
                </ng-container>

                <ng-container matColumnDef="lat1">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Bottom Left Lat </th>
                  <td mat-cell *matCellDef="let element"> {{element.lat1}} </td>
                </ng-container>

                <ng-container matColumnDef="lon1">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Bottom Left Lon </th>
                  <td mat-cell *matCellDef="let element"> {{element.lon1}} </td>
                </ng-container>

                <ng-container matColumnDef="lat2">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Top Right Lat </th>
                  <td mat-cell *matCellDef="let element"> {{element.lat2}} </td>
                </ng-container>

                <ng-container matColumnDef="lon2">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Top Right Lon </th>
                  <td mat-cell *matCellDef="let element"> {{element.lon2}} </td>
                </ng-container>

                <ng-container matColumnDef="image">
                  <th mat-header-cell *matHeaderCellDef>
                      Image
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <button title="{{checkFileType(row)}} Click to download {{row.originalFileName}}" mat-button (click)="$event.stopPropagation()" (click)="getImage(row)">
                      <img src="./assets/icons/download.jpg" height="32" width="32" />
                    </button>
                  </td>
                </ng-container>

                <!-- Delete Action Column -->
                <ng-container matColumnDef="delete">
                  <th mat-header-cell *matHeaderCellDef>
                      Delete
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <button title="Delete Slide" mat-button class="delete-button" (click)="$event.stopPropagation()" (click)="handleDelete(row)">
                      <img src="./assets/icons/ic_delete_gray_24px.svg" />
                    </button>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr class="hyper" matTooltip="Edit Slide" matTooltipPosition="before"
                  [ngClass]="{'invalid-file-type' : checkFileType(row)}"
                  mat-row *matRowDef="let row; columns: displayedColumns;" (click)="edit(row)"></tr>
              </table>
    </div>
</div>

