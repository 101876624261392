import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter } from '../services/global/date-converter';
import { Command } from '@models/command.model';

@JsonObject('Parameter')
export class Parameter {
  @JsonProperty('parameter_id', String, true)
  parameterId: string = undefined;
  @JsonProperty('param_level', String, true)
  level: string = undefined;
  @JsonProperty('component_name', String, true)
  componentName: string = undefined;
  @JsonProperty('command_id', String, true)
  commandId: string = undefined;
  @JsonProperty('command', Command, true)
  command: Command = undefined;
  @JsonProperty('param_name', String, true)
  name: string = undefined;
  @JsonProperty('string_value', String, true)
  stringValue: string = undefined;
  @JsonProperty('number_value', Number, true)
  numberValue: number = undefined;
  @JsonProperty('datetime_value', DateConverter, true)
  datetimeValue: Date = undefined;
  // @JsonProperty('blob_value', Blob, true)
  // blobValue: Blob = undefined;
  @JsonProperty('blob_name', String, true)
  blobName: string = undefined;
  @JsonProperty('created_by', String, true)
  createdBy: string = undefined;
  @JsonProperty('create_ts', DateConverter, true)
  dateCreated: Date = undefined;

  static lookupName(): string {
    return 'parameters';
  }
}
