<mat-card class="form-card">
  <div class="grid-container">
    <div fxLayout fxLayoutAlign="center center" class="simple-grid-title">
      <span class="grid-title">{{initService.getConfig().titlePrefix}} FDT-M Unit Search</span>
      <app-search-filter-control [filterType]="'Unit'" (filtersChanged)="unitFiltersChanged($event)">
      </app-search-filter-control>
    </div>

    <div class="mat-elevation-z8">
      <mat-card-content>
        <div fxFlex="row">
          <div fxFlex="20%" class="panel">
            <h4>Search Parameters</h4>
            <form [formGroup]="editForm">
              <span matPrefix>
                <mat-select class="country-filter" [(value)]="filterByCountry" (selectionChange)="filterChange($event)">
                  <mat-select-trigger *ngIf="filterByCountry.name" matTooltip="Find Units for {{filterByCountry.name}}">
                    <img [src]="getFlag(filterByCountry)" height="16">&nbsp;
                  </mat-select-trigger>
                  <mat-select-trigger *ngIf="!filterByCountry.name" matTooltip="Select a Country to Filter Search">
                    <mat-icon style="vertical-align:middle;">flag</mat-icon>
                  </mat-select-trigger>
                  <mat-option [value]="noCountry">
                    <mat-icon style="vertical-align:middle;">flag</mat-icon> (clear)
                  </mat-option>
                  <mat-option *ngFor="let country of countries" [value]="country">
                    <img [src]="getFlag(country)" height="16">&nbsp;{{country.name}}
                  </mat-option>
                </mat-select>
              </span>
              <mat-form-field class="unit-input-field">
                <span matPrefix *ngIf="utilities.getFlag(editForm.controls?.unit?.value)"><img
                    style="vertical-align:middle;" [src]="utilities.getFlag(editForm.controls?.unit?.value)"
                    height="20" />&nbsp;</span>
                <input matInput type="text" formControlName="unit" placeholder="Unit"
                  [matAutocomplete]="unitAutocomplete" tabindex="1"
                  matTooltip="{{editForm.controls?.unit?.value | unitNamePipe}}">
              </mat-form-field>
              <mat-autocomplete #unitAutocomplete="matAutocomplete" [displayWith]="displayUnit"
                (optionsScroll)="onUnitOptionsScroll()">
                <mat-option *ngFor="let option of filteredUnits$ | async" [value]="option">
                  <span matTooltip="{{option.country?.name}}">
                    <img style="vertical-align:middle;" [src]="utilities.getFlag(option)" height="20" />&nbsp;
                  </span>
                  <span matTooltip="{{option.fullName()}}">{{option.fullName()}}</span>
                </mat-option>
                <mat-option *ngIf="isLoading" class="is-loading">
                  <mat-spinner diameter="30"></mat-spinner>
                </mat-option>
              </mat-autocomplete>
              <br>
              <mat-form-field class="full-width">
                <input matInput tabindex=2 (dateChange)="change($event)" [matDatepicker]="startDate"
                  placeholder="Start Date" required formControlName="archive">
                <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                <mat-datepicker #startDate (date1)="catchDate1($event)"></mat-datepicker>
              </mat-form-field>
              <br>
              <mat-form-field class="full-width">
                <input matInput tabindex=3 [matDatepicker]="endDate" placeholder="End Date" required
                  formControlName="toDate">
                <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                <mat-datepicker #endDate></mat-datepicker>
              </mat-form-field>
              <br>
              <mat-form-field class="full-width">
                <mat-select [formControlName]="'activity'" placeholder="Activity Category">
                  <mat-option *ngFor="let activity of activityCategoryOptions" [disabled]="false"
                    [value]="activity.activityId">{{activity.code}}</mat-option>
                </mat-select>
              </mat-form-field>
              <br>
              <mat-form-field class="full-width">
                <mat-select [formControlName]="'country'" placeholder="Country">
                  <mat-option *ngFor="let country of countries" [disabled]="false" [value]="country.countryId">
                    {{country.name}}</mat-option>
                </mat-select>
              </mat-form-field>
              <br>
              <mat-form-field class="full-width">
                <mat-select multiple name="classification" formControlName="classificationNames"
                  placeholder="Classification(s)">
                  <mat-option *ngFor="let classification of classifications" [disabled]="false"
                    [value]="classification.classificationName"> {{classification.classificationName}}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="full-width">
                <mat-select [formControlName]="'opArea'" placeholder="Op Area" (selectionChange)="filterChange($event)">
                  <mat-option [value]=""></mat-option>
                  <mat-option *ngFor="let opArea of opAreas" [disabled]="false" [value]="opArea.areaWithRemarks()">
                    {{opArea.areaWithRemarks()}}</mat-option>
                </mat-select>
              </mat-form-field>
              <br>
              <mat-card-actions>
                <button [disabled]="!editForm.valid || badUnit()" mat-raised-button color="primary"
                  (click)="search()">Search</button>
              </mat-card-actions>
            </form>
          </div>

          <div fxFlex="80%" class="panel">
            <div>
              <b>Search Results</b>
              <mat-form-field style="margin-left: 2em;" *ngIf="dataSource?.data?.length" class="groupSelect">
                <mat-select [(ngModel)]="grouping" placeholder="Group By"
                  (selectionChange)="groupingChanged($event, grouping)">
                  <mat-option *ngFor="let groupItem of groupItems" [value]="groupItem">
                    {{groupItem.display}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <span style="float: right;" *ngIf="dataSource && dataSource.data && dataSource.data.length">
                Export to:
                <button [disabled]="!selection.selected?.length"
                  title="{{selection.selected.length}} item(s) selected for Export" mat-raised-button
                  class="fdt-m-primary" (click)="exportKML()">KML</button>&nbsp;
                <button [disabled]="!selection.selected?.length"
                  title="{{selection.selected.length}} item(s) selected for Export" mat-raised-button
                  class="fdt-m-primary" (click)="exportExcel()">Excel</button>
              </span>
            </div>

            <div>
              <cdk-virtual-scroll-viewport headerEnabled="true" tvsItemSize="30" headerHeight="56">
                <table mat-table [dataSource]="dataSource" matSort (matSortChange)="groupData($event)">
                  <!-- Checkbox Column -->
                  <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                      Export<br>
                      <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                      </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)">
                      </mat-checkbox>
                    </td>
                  </ng-container>
                  <!--Data Columns -->
                  <ng-container matColumnDef="classification">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Classification
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{element.classificationString}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="fleet">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Fleet </th>
                    <td mat-cell *matCellDef="let element">
                      {{element.fleet}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="finalName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Unit Name
                    </th>
                    <td mat-cell class="left" *matCellDef="let element">
                      <img [src]="getFlagFromRow(element)" height="16">
                      {{ element.confidenceCode ? element.confidenceCode + ' ' : '' }}{{element.finalName}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="daysOut">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Days Out
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{element.newDays}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="dtgImaged">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Start Date </th>
                    <td mat-cell *matCellDef="let element">
                      {{element.dtgImaged | zulu1Pipe}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="lastLoc">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Location </th>
                    <td mat-cell class="left" *matCellDef="let element">
                      {{element.lastLoc}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="portName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Home Port </th>
                    <td mat-cell class="left" *matCellDef="let element">
                      {{element.portName}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="latitude">
                    <th mat-header-cell *matHeaderCellDef> Latitude </th>
                    <td mat-cell *matCellDef="let element">
                      {{element.latitude}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="longitude">
                    <th mat-header-cell *matHeaderCellDef> Longitude </th>
                    <td mat-cell *matCellDef="let element">
                      {{element.longitude}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="locTime">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> DTG </th>
                    <td mat-cell *matCellDef="let element">
                      {{element.locTime | zulu1Pipe}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="countryName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Country </th>
                    <td mat-cell *matCellDef="let element">
                      {{element.countryName}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="opArea">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Op Area </th>
                    <td mat-cell *matCellDef="let element">
                      {{element.opArea}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="sourceName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Source </th>
                    <td mat-cell class="left" *matCellDef="let element">
                      {{element.sourceName}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="srcOrig">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Originator </th>
                    <td mat-cell *matCellDef="let element">
                      {{element.srcOrig}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="activityCategoryCode">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Activity </th>
                    <td mat-cell *matCellDef="let element">
                      {{element.activityCategoryName}}
                    </td>
                  </ng-container>

                  <!-- Group header -->
                  <ng-container matColumnDef="groupHeader">
                    <td colspan="999" mat-cell *matCellDef="let group; grouping"
                      [ngClass]="{'subgroupHeader': group.level > 1, 'groupHeader': true, 'hyper': true}"
                      title="Click to show/hide group items">
                      <div class="groupLabel">
                        {{group.name}}
                      </div>
                      <div class="groupIcon">
                        <mat-icon *ngIf="group.expanded"> expand_more </mat-icon>
                        <mat-icon *ngIf="!group.expanded"> chevron_left </mat-icon>
                      </div>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                  <tr mat-row class="grouping-row" *matRowDef="let row; columns: ['groupHeader']; when: isGroup"
                    (click)="groupHeaderClick(row)"> </tr>
                </table>
              </cdk-virtual-scroll-viewport>
            </div>
          </div>
        </div>
      </mat-card-content>
    </div>
  </div>
</mat-card>
