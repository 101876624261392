import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { GlobalMessageTriggers } from '@services/global/message-bus/global-message-triggers.enum';
import { GridBaseComponent } from '@fom-module/base-classes/grid-base.component';
import { AccountRequestService } from '@services/data-services/account-request.service';
import { EditAccountRequestModalComponent } from '@fom-module/edit-modals/edit-account-request-modal/edit-account-request.component';
import { DenyAccountRequestModalComponent } from '@fom-module/edit-modals/deny-account-request-modal/deny-account-request.component';
import * as moment from 'moment';
import { AppInitService } from '../../../config/init.service';

@Component({
  selector: 'app-account-request',
  templateUrl: './account-request.component.html',
  styleUrls: ['./account-request.component.css', '../base-classes/grid-base.component.css']
})
export class AccountRequestComponent extends GridBaseComponent implements OnInit {
  @Output() numberOfRequests: EventEmitter<any> = new EventEmitter();
  showOptions = 'all';
  allRequests: any;

  // Actual order of table items
  displayedColumns = [
    'date',
    'first_name',
    'last_name',
    'user_name',
    'permission',
    'email',
    // 'justification',
    'delete'];

  paginator: MatPaginator;
  sort: MatSort;

  constructor(public messageBusService: MessageBusService,
    private dialog: MatDialog,
    public accountRequestService: AccountRequestService,
    public route: Router,
    public initService: AppInitService) {
    super(messageBusService, accountRequestService, route);
    this.RELOAD_MESSAGE = GlobalMessageTriggers.RELOAD_PORT_DATA;
    this.EDIT_MODAL_COMPONENT = EditAccountRequestModalComponent;
  }

  ngOnInit() {
    this.loadEntityData();
  }

  loadEntityData() {
    this.accountRequestService.getAllAccountRequests().subscribe(entities => {
      this.setDataSource(entities);
      this.allRequests = entities;
      // emit data to parent component
      this.numberOfRequests.emit(this.allRequests.length);
    });
  }

  setDataSource(entities) {
    this.entities = entities;
    this.dataSource = new MatTableDataSource<object>(this.entities);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  edit(row) {
    const dialogRef = this.dialog.open(EditAccountRequestModalComponent, {
      width: '580px'
    });
    dialogRef.componentInstance.accreq = row;

    dialogRef.afterClosed().subscribe(result => {
      this.loadEntityData();
    });
  }

  dateFormat(date) {
    return moment(date).format('MMM Do YYYY');
  }

  handleDeny(row) {
    const dialogRef = this.dialog.open(DenyAccountRequestModalComponent, {
      width: '580px'
    });
    dialogRef.componentInstance.accreq = row;

    dialogRef.afterClosed().subscribe(result => {
      this.loadEntityData();
    });
  }
}
