import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { GlobalMessageTriggers } from '@services/global/message-bus/global-message-triggers.enum';
import { DynamicComponentInterface } from '@core-module/app-components/dynanmic-component/dynamic-component.interface';
import { EditModalBaseComponent } from '@fom-module/base-classes/edit-modal-base.component';
import { GeoRefService } from '@services/data-services/geo-ref.service';
import { GeoRef } from '@models/geo-ref.model';
import { LookUpService } from '@services/data-services/lookup.service';
import { Command } from '@models/command.model';
import { MatDialogRef } from '@angular/material/dialog';
import { UtilityService } from '@services/utility.service'
import { take } from 'rxjs/operators'
import { CurrentUserService } from '@services/current-user-service';
import { UserJfmccService } from '@services/user-jfmcc.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-edit-geo-ref-modal',
  templateUrl: './edit-geo-ref-modal.component.html',
  styleUrls: ['./edit-geo-ref-modal.component.css']
})
export class EditGeoRefModalComponent extends EditModalBaseComponent implements OnInit {
  // Dropdown options
  commandOptions: Command[];
  user: any;

  latitudePattern: any;
  longitudePattern: any;
  constructor(public geoRefService: GeoRefService, public utilities: UtilityService,
    public lookUpService: LookUpService,
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<EditGeoRefModalComponent>,
    public messageBusService: MessageBusService,
    private currentUserService: CurrentUserService,
    private userJfmccService: UserJfmccService,
    private snackBar: MatSnackBar) {
    super(geoRefService, dialogRef);
  }

  ngOnInit() {
    this.headerString = this.isNew ? 'Add Geo-Reference' : this.isDelete ? 'Delete Geo-Reference' : 'Edit Geo-Reference'
    this.user = this.currentUserService.getCurrentUser();
    this.latitudePattern = '^[0-9NS]+$';
    this.longitudePattern = '^[0-9EW]+$';
    if (this.isNew) {
      this.entity.commandId = this.user.command_id
    }
    this.editForm = this.formBuilder.group({
      commandId: [this.entity.commandId],
      name: [this.entity.name],
      area: [this.entity.area],
      latitude: [this.entity.latitude, this.utilities.latValidator],
      longitude: [this.entity.longitude, this.utilities.lonValidator]
    });
    Promise.resolve().then(() => {
      this.loadLookups();
    })
  }


  async loadLookups() {
    try {
      const jfmccs = await this.userJfmccService.getJfmccs(this.user)
      const commands = await this.lookUpService.getLookupByType(Command, true).pipe(take(1)).toPromise()

      if (jfmccs.length) {
        this.commandOptions = commands.filter(item => {
          return jfmccs.includes(item.commandId);
        });
      } else {
        // If no Command Hierarchies, Sys Admin gets all commands, other users can edit their own command
        this.commandOptions = this.user.isSystemAdmin() ? commands : [this.user.command];
      }
    } catch (err) {
      this.snackBar.open('An error occurred when trying to add or edit a Geo-Reference. Please contact your administrator.', 'Close', { horizontalPosition: 'center', verticalPosition: 'top', panelClass: ['red-snackbar'], })
    }

  }

  submit() {
    if (this.editForm.valid) {
      this.updateEntity();

      if (this.isNew) {
        (this.entityService as any).create(this.entity).pipe(take(1)).subscribe(res => {
          this.dialogRef.close(res)
        });
      } else {
        (this.entityService as any).update(this.entity).pipe(take(1)).subscribe(res => {
          this.dialogRef.close(res)
        });
      }
    }
  }
  handleCancel() {
    this.dialogRef.close()
  }

  updateEntity() {
    for (let key in this.editForm.controls) {
      if (this.entity.hasOwnProperty(key.toString())) {
        this.entity[key.toString()] = this.editForm.controls[key]['value'];
      }
    }
    // the DB stores lat/lon as DMS. may need to convert them
    const latitude = this.entity.latitude
    if (latitude.indexOf('.') > -1) {
      this.entity.latitude = this.utilities.getDMS(latitude, 'lat')
    }

    const longitude = this.entity.longitude
    if (longitude.indexOf('.') > -1) {
      this.entity.longitude = this.utilities.getDMS(longitude, 'lon')
    }

  }

}
