import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppInitService } from '../../../../config/init.service';

@Component({
  selector: 'app-access-agreement',
  templateUrl: './access-agreement.component.html',
  styleUrls: ['./access-agreement.component.css']
})
export class AccessAgreementComponent implements OnInit, OnDestroy {
  message: string;

  constructor(private init: AppInitService, private router: Router) { }

  ngOnInit() {
    this.message = this.init.getConfig().classification.acknowledgment;
  }

  ngOnDestroy() {
    // After accepting, route to the originRoute then remove from sessionStorage
    if (sessionStorage.getItem('originRoute') != null) {
      this.router.navigateByUrl(sessionStorage.getItem('originRoute'));
      sessionStorage.removeItem('originRoute');
    }
  }
}
