import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Activity } from '@models/activity.model';
import { Area } from '@models/area.model';
import { Classification } from '@models/classification.model';
import { Command } from '@models/command.model';
import { Fleet } from '@models/fleet.model';
import { Source } from '@models/source.model';
import { LookUpService } from '@services/data-services/lookup.service';
import { count, take } from 'rxjs/operators'

@Component({
  selector: 'app-location-filters',
  templateUrl: './location-filters.component.html',
  styleUrls: ['./location-filters.component.css']
})
export class LocationFiltersComponent implements OnInit {

  emptyFilters = {op_area_id: [], fleet_id: [], source_id: [], activity_category_id: [], classification_id: []}
  filters: any
  user
  opAreas: [Area]
  fleets: [Fleet]
  sources: [Source]
  activities: [Activity]
  classifications: [Classification]

  opAreaSelections = new SelectionModel<Area>(true, []);

  constructor(private lookupService: LookUpService,
    public dialogRef: MatDialogRef<LocationFiltersComponent>) { }

  ngOnInit() {
    this.filters = JSON.parse(sessionStorage.getItem('locationFilters')) || this.emptyFilters;
    this.user = JSON.parse(sessionStorage.getItem('user'))
    Promise.resolve().then(() => {
      this.loadLookups(); 
    })
  }

  loadLookups() {
    this.lookupService.getLookupByType(Command, true).pipe(take(1)).subscribe(result => {
      const commands = result;
      this.lookupService.getLookupByType(Area, true).pipe(take(1)).subscribe(results => {
        results.forEach(element => {
          let found = commands.find(command => {
            return command.commandId == element.commandId
          })
          if (found) {
            // command name for the display
            element.commandName = found.name
          }
        });
        this.opAreas = results
      });
      this.lookupService.getLookupByType(Fleet, true).pipe(take(1)).subscribe(results => {
        this.fleets = results
      });
      this.lookupService.getLookupByType(Source, true).pipe(take(1)).subscribe(results => {
        this.sources = results
      });
      this.lookupService.getLookupByType(Activity, true).pipe(take(1)).subscribe(results => {
        this.activities = results
      });
      this.lookupService.getLookupByType(Classification, true).pipe(take(1)).subscribe(results => {
        this.classifications = results
        // descending order fomng-840
        this.classifications.sort((a,b) => {
          return a.sortBy < b.sortBy ? 1 : -1
        })
      });
    })
  }
  cancel() {
    this.dialogRef.close()
  }
  clear() {
    this.filters = this.emptyFilters
  }
  apply() {
    sessionStorage.setItem('locationFilters', JSON.stringify(this.filters))
    this.dialogRef.close(this.filters)
  }

  // select or deselect all items in the list
  // the selections list comes from the UI, defined above below the model array
  all(name, list, key) {
    if (Array.isArray(this.filters[name])) {
      if (this.filters[name].length) {
        this.filters[name] = []
      }
      else {
        // so... when I pass in the array directly it loses the reference? use the this.filters reference
        let items = []
        list.forEach(item => {
          items.push(item[key])
        });
        // gotta wait a tick for the UI control to update
        setTimeout(() => {
          this.filters[name] = items
        })
      }
    }
  }
}
