<app-classification-bar class="top-modal-classification-bar"></app-classification-bar>
<app-fdtm-modal-header [headerString]="headerString"></app-fdtm-modal-header>
<div mat-dialog-content>
<form [formGroup]="editForm">
    <mat-form-field class="input-full-width">
        <mat-select placeholder="Command" required formControlName="commandId">
            <mat-option *ngFor="let option of commandOptions" [value]="option.commandId">{{option.name}}</mat-option>
        </mat-select>
      </mat-form-field>
  <br>
  <mat-form-field class="input-full-width">
    <input matInput (keyup)="checkDups($event, 'name')" placeholder="Name" required formControlName="name">
    <mat-error *ngIf="editForm?.controls?.name?.hasError('duplicate entry')">duplicate entry</mat-error>
  </mat-form-field>
  <br>
  <mat-form-field class="input-full-width">
    <input matInput (keyup)="checkDups($event, 'code')" placeholder="Code" required formControlName="code">
    <mat-error *ngIf="editForm?.controls?.code?.hasError('duplicate entry')">duplicate entry</mat-error>
  </mat-form-field>
  <br>
  <mat-form-field class="input-full-width">
    <mat-select placeholder="Fleet" required formControlName="fleetId">
        <mat-option *ngFor="let option of fleetOptions" [value]="option.fleetId">{{option.fleetCode}}</mat-option>
    </mat-select>
  </mat-form-field>
<br>
  <mat-form-field class="input-full-width">
    <input matInput
      placeholder="Latitude (DMS or decimal)"
      formControlName="decLat"
      required
     >
     <mat-error *ngIf="editForm?.controls?.decLat?.hasError('format')">bad format</mat-error>
     <mat-error *ngIf="editForm?.controls?.decLat?.hasError('length')">improper length</mat-error>
     <mat-error *ngIf="editForm?.controls?.decLat?.hasError('range')">out of range</mat-error>
     <mat-error *ngIf="editForm?.controls?.decLat?.hasError('required')">required</mat-error>
  </mat-form-field>
  <br>
  <mat-form-field class="input-full-width">
    <input matInput
      placeholder="Longitude (DMS or decimal)"
      formControlName="decLon"
      required
    >
    <mat-error *ngIf="editForm?.controls?.decLon?.hasError('format')">bad format</mat-error>
    <mat-error *ngIf="editForm?.controls?.decLon?.hasError('length')">improper length</mat-error>
    <mat-error *ngIf="editForm?.controls?.decLon?.hasError('range')">out of range</mat-error>
    <mat-error *ngIf="editForm?.controls?.decLon?.hasError('required')">required</mat-error>
  </mat-form-field>
  <!-- <div *ngIf="isDelete">{{ deleteWarning }}</div> -->
  <div mat-dialog-actions>
    <button *ngIf="!isDelete" [disabled]="!editForm.valid" mat-raised-button color="primary" (click)="submit()">Submit</button>
    <button *ngIf="isDelete" mat-raised-button color="warn" (click)="deletePort()">Delete</button>
    <button mat-raised-button (click)="handleCancel()">Cancel</button>
  </div>
</form>
</div>
<app-classification-bar class="bottom-modal-classification-bar"></app-classification-bar>