import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from '@services/api-services/api.config';

@Injectable()
export class FDOMapApiService {
  SERVER_URL;

  constructor(
    private http: HttpClient, apiConfig: APIConfig) {
    this.SERVER_URL = apiConfig.TARGET_URL;
  }

  public getAllFDOMaps(): Observable<any> {
    return this.http.get(
      `${this.SERVER_URL}/class-fdo-maps`);
  }

  public getFDOMapByFDOMapId(FDOMapId: string): Observable<any> {
    return this.http.get(
      `${this.SERVER_URL}/class-fdo-maps/${FDOMapId}`);
  }


  public updateFDOMap(FDOMap: any): Observable<any> {
    return this.http.put(
      `${this.SERVER_URL}/class-fdo-maps/${FDOMap.class_fdo_map_id}`, FDOMap);
  }

  public createFDOMap(FDOMap: any): Observable<any> {
    return this.http.post(
      `${this.SERVER_URL}/class-fdo-maps`, FDOMap);
  }

  public deleteFDOMap(FDOMap: any): Observable<any> {
    return this.http.delete(
      `${this.SERVER_URL}/class-fdo-maps/${FDOMap.class_fdo_map_id}`);
  }

  public getFDOMapByCommandId(commandId: string): Observable<any> {
    return this.http.get(
      `${this.SERVER_URL}/class-fdo-maps/command/${commandId}`);
  }
}
