import { Component, OnInit, OnDestroy } from '@angular/core';
import { ReportingService } from '@services/data-services/reporting.service';
import { FomPageBaseComponent } from '@fom-module/base-classes/fom-page-base.component';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { Router } from '@angular/router';
import { UserNotificationService } from '@services/notification.service';
import { take } from 'rxjs/operators';
import { AppInitService } from '../../../../config/init.service';
import { CurrentUserService } from '@services/current-user-service';
import { PicklistPrefService } from '@services/data-services/picklist-pref.service';
import { Area } from '@models/area.model'
import { LookUpService } from '@services/data-services/lookup.service';
import { UtilityService } from '@services/utility.service';
@Component({
  selector: 'app-nob',
  templateUrl: './currentNobExcel.component.html',
  styleUrls: ['./currentNobExcel.component.css']
})
export class CurrentNobExcelComponent extends FomPageBaseComponent implements OnInit, OnDestroy {

  status: string = 'ready';

  // track the subscription in case of navigating away
  subscription: any;
  user: any
  constructor(
    private reportingService: ReportingService,
    private notifier: UserNotificationService,
    public messageBusService: MessageBusService,
    private currentUserService: CurrentUserService,
    private picklistPrefService: PicklistPrefService,
    private lookupService: LookUpService,
    public route: Router,
    public initService: AppInitService,
    private utilityService: UtilityService
    ) {
      super(messageBusService, route);
  }
  ngOnInit() {
    this.user = this.currentUserService.getCurrentUser();
    super.ngOnInit();
    //this.getNobReport();
  }

  // if we navigate away but not done, kill the subscription
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  public async getNobReport() {
    this.status = 'in progress'
    // TODO confingure this
    const groupBy = 'op_area_title'
    const groupOrder = await this.getGroupOrder(groupBy)
    // a little weird to have the column headers come from the UI component
    // but it will keep the NOB screen in sync with Excel
    let columnHeaders = this.utilityService.getNOBColumnHeaders()
    this.subscription = this.reportingService.getCurrentNOBExcel(groupBy, groupOrder, columnHeaders).pipe(take(1)).subscribe(nob => {
      this.subscription.unsubscribe()
      this.status = 'complete'
      this.notifier.showSuccess('Download Complete', 3000)
      this.downloadNOB(nob);
    }, err => {
      // we caught the error only to stop the progress bar
      this.subscription.unsubscribe()
      this.status = 'complete'
      // we still want to throw it to the global ErrorHandler
      throw (err)
    });
  }
  async getGroupOrder(groupBy: string) {
    const picklistPreferences = await this.picklistPrefService.getAll({
      owner_id: this.user.currentJFMCC ? this.user.currentJFMCC.commandId : null
    }).toPromise() 
    const lookups = await this.lookupService.getLookupByType(Area, true).toPromise()
    let groupOrder = []
    lookups.forEach(element => {
      const found = picklistPreferences.find(x => x.sourcePicklistId == (element as Area).opAreaId)
      groupOrder.push({value: (element as Area).areaRemarks(), sortSequence: found ? found.sortSequence : 0})
    });
    groupOrder.sort((a,b) => {
      return a.sortSequence < b.sortSequence ? -1 : 1
    })
    return groupOrder.map(x => x.value)
  }
  downloadNOB(nob: any) {
    const fileName = 'NOB Report.xlsx';
    const file = new Blob([nob]);
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(file, fileName);
    } else {
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(file); // xhr.response is a blob
      a.style.display = 'none';
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
    }
  }
  cancel() {
    if (this.subscription) {
      this.subscription.unsubscribe()
      this.status = 'ready'
      this.notifier.showError('Request Cancelled', 4000)
    }
  }
}
