import { ArrayType } from "@angular/compiler";
import { Pipe, PipeTransform } from "@angular/core";

/****************
 html use:

  For a list of strings/numbers ​
  *ngFor="let releaseType of releaseTypes | sort"

  For a list of objects; enter the field you want to sort on
  *ngFor="let queue of queues | sort:'name'"


​ typesript use:

  this.allUsers = this.arraySort.transform(this.allUsers, 'FirstName')
 
 ------------------------------------------------------------------------ 
 Note: add a '!' to the field to sort on (i.e : "!name") it reverses the order of the sort
--------------------------------------------------------------------------
  */


@Pipe({
  name: "sort",
  pure: false
})
export class ArraySortPipe  implements PipeTransform {
  transform(array: any, field?: string): any[] {
    if (!Array.isArray(array)) {
      return array;
    }
    if (field) {
      let compareField = field
      let reverse = false
      if (field.startsWith('!')){
        compareField = field.substring(1, field.length);
        reverse = true
      }
      // if there's a field provided otherwise we're just reversing the order
      if (compareField) {
        array.sort((a: any, b: any) => {
          let first 
          let second
          const propertyA = this.dataAccessor(a, compareField || '') == null ? undefined : this.dataAccessor(a, compareField as string).toString()
          const propertyB= this.dataAccessor(b, compareField || '') == null ? undefined : this.dataAccessor(b, compareField as string).toString()
          if (propertyA != null && propertyA != undefined) {
            //@ts-ignore
            if (propertyA instanceof Date && !isNaN(propertyA)) {
              first = propertyA.getTime()
            }
            else if (!isNaN(propertyA)) {
              first = propertyA
            }
            else {
              first = propertyA.toLowerCase()
            }
          }
          if (propertyB != null && propertyB != undefined) {
            //@ts-ignore
            if (propertyB instanceof Date && !isNaN(propertyB)) {
              second = propertyB.getTime()
            }
            if (!isNaN(propertyB)) {
              second = propertyB
            }
            else {
              second = propertyB.toLowerCase()
            }
          }
          if (first < second) {
            return -1;
          } else if (first > second) {
            return 1;
          } else {
            return 0;
          }
        });
      } else {
        // sort the aray if there's no field provided, this calls itself
        array = this.transform(array)
      }
      if (reverse){
        array = array.reverse()
      }
    }
    else {
      return array.sort((a: any, b: any) => {
        let first = ''
        let second = ''
        if (a != null && a != undefined) {
          first = a.toLowerCase()
        }
        if (b != null && b != undefined) {
          second = b.toLowerCase()
        }
        if (first < second) {
          return -1;
        } else if (first > second) {
          return 1;
        } else {
          return 0;
        }
      });
    }
    return array;
  }

  dataAccessor = (item: any, property: string) => {
    if (property.includes('.')) {
      return property.split('.').reduce((o, p) => o && o[p], item)
    }
    return item ? item[property] : null;
  }

}