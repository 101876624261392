import { LoadingInterceptor } from '@core-module/interceptors/http-request.interceptor';
import { ClientErrorHandler } from '@core-module/ClientErrorHandler';
import { EditUserModalComponent } from '@edit-modals/edit-user-modal/edit-user-modal.component';
import { EditParserQueueModalComponent } from '@edit-modals/edit-parser-queue-modal/edit-parser-queue-modal.component';
import { EditUnitModalComponent } from '@edit-modals/edit-unit-modal/edit-unit-modal.component';
import { EditCategoryModalComponent } from '@edit-modals/edit-category-modal/edit-category-modal.component';
import { EditCommandModalComponent } from '@edit-modals/edit-command-modal/edit-command-modal.component';
import { EditActivityModalComponent } from '@edit-modals/edit-activity-modal/edit-activity-modal.component';
import { EditClassificationModalComponent } from '@edit-modals/edit-classification-modal/edit-classification-modal.component';
import { EditSourceModalComponent } from '@edit-modals/edit-source-modal/edit-source-modal.component';
import { EditSlideModalComponent } from '@edit-modals/edit-slide-modal/edit-slide-modal.component';
import { EditFgiModalComponent } from '@edit-modals/edit-fgi-modal/edit-fgi-modal.component';
import { EditPortModalComponent } from '@edit-modals/edit-port-modal/edit-port-modal.component';
import { EditAreaModalComponent } from '@edit-modals/edit-area-modal/edit-area-modal.component';
import { EditCountryModalComponent } from '@edit-modals/edit-country-modal/edit-country-modal.component';
import { EditFleetModalComponent } from '@edit-modals/edit-fleet-modal/edit-fleet-modal.component';
import { EditShipTypeModalComponent } from '@edit-modals/edit-ship-type-modal/edit-ship-type-modal.component';
import { EditShipClassModalComponent } from '@fom-module/edit-modals/edit-ship-class-modal/edit-ship-class-modal.component';
import { EditVesselTypeModalComponent } from '@fom-module/edit-modals/edit-vessel-type-modal/edit-vessel-type-modal.component';
import { EditFreqTypeModalComponent } from "@fom-module/edit-modals/edit-freq-type-modal/edit-freq-type-modal.component";
import { EditScanTypeModalComponent } from "@fom-module/edit-modals/edit-scan-type-modal/edit-scan-type-modal.component";
import { EditElintFunctionTypeModalComponent } from "@fom-module/edit-modals/edit-elint-function-type-modal/edit-elint-function-type-modal.component";
import { EditConfidenceCodeModalComponent } from '@fom-module/edit-modals/edit-confidence-code-modal/edit-confidence-code-modal.component';
import { EditGeoRefModalComponent } from '@edit-modals/edit-geo-ref-modal/edit-geo-ref-modal.component';
import { EditDataTagModalComponent } from '@edit-modals/edit-data-tag-modal/edit-data-tag-modal.component';
import { EditSystemConnectionModalComponent } from '@fom-module/edit-modals/edit-system-connection-modal/edit-system-connection-modal.component';
import { EditMessageConfigModalComponent } from '@fom-module/edit-modals/edit-message-config-modal/edit-message-config-modal.component';
import { AuthorizationInterceptor } from '@core-module/interceptors/authorization.interceptor';
import { SetFiltersInterceptor } from '@core-module/interceptors/setFilters.interceptor';
import { DynamicComponentComponent } from '@core-module/app-components/dynanmic-component/dynanmic-component.component';
import { ConfirmationModalComponent } from '@core-module/app-components/confirmation-modal/confirmation-modal.component';
import { EditCollaborationModalComponent } from '@edit-modals/edit-collaboration-modal/edit-collaboration-modal.component';
import { ModalComponent } from '@core-module/app-components/modal/modal.component';
import { NavbarComponent } from '@core-module/app-components/navbar/navbar.component';
import { HttpLoaderComponent } from '@core-module/app-components/http-loader/http-loader.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app.routing.module';
import { FomModule } from '@fom-module/fom.module';
import { CoreModule } from '@core-module/core.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { Injector } from '@angular/core';
import { RouterModule, Routes} from '@angular/router';
//import { FileSelectDirective } from 'ng2-file-upload';
import { CovalentFileModule } from '@covalent/core/file';
import { CovalentLayoutModule } from '@covalent/core/layout';
import { CovalentStepsModule } from '@covalent/core/steps';
//import { Idle } from 'idlejs';
// a service to read configuration data
import { AppInitService } from '../config/init.service';
export function initializeApp(appInitService: AppInitService) {
  return (): Promise<any> => {
    return appInitService.Init();
  };
}
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { MatFormFieldModule } from '@angular/material/form-field';
import {CdkTableModule} from '@angular/cdk/table';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule, FormBuilder } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { ChartsModule } from 'ng2-charts';
import { ChangePasswordDialog } from '@fom-module/user/user.component';
import { AdminModule } from '@fom-module/admin/admin.module';
import { NotifierComponent } from '@fom-module/notifier/notifier.component';
import { SlideMaintenanceComponent } from '@fom-module/slide-maintenance/slide-maintenance.component';
import { LookupMaintenanceComponent } from '@fom-module/lookup-maintenance/lookup-maintenance.component';
import { ClassificationMaintenanceComponent } from '@fom-module/classification-maintenance/classification-maintenance.component';
import { DataTagsComponent } from '@fom-module/data-tags/data-tags.component';
import { GenerateSlidesComponent } from './fom/products/generate-slides/generate-slides.component';
import { ServiceDashboardComponent } from '@fom-module/service-dashboard/service-dashboard.component';
//import { ParametersComponent } from '@fom-module/parameters/parameters.component';
//import { PreferencesComponent } from '@fom-module/preferences/preferences.component';
import { AccessAgreementComponent } from '@core-module/app-components/access-agreement/access-agreement.component';
import { AbacPreferencesComponent } from '@core-module/app-components/abac-preferences/abac-preferences.component';
import { TimeoutWarningComponent} from '@core-module/app-components/timeout-warning/timeout-warning.component';
import { EditUserSettingsComponent } from '@fom-module/edit-modals/edit-user-settings-modal/edit-user-settings.component';
import { EditParameterModalComponent } from '@fom-module/edit-modals/edit-parameter-modal/edit-parameter-modal.component';
import { EditFeaturePreferenceModalComponent } from '@fom-module/edit-modals/edit-feature-preference-modal/edit-feature-preference-modal.component';
import { RecordMessagePopupModalComponent } from '@fom-module/edit-modals/recordmessage-popup-modal/recordmessage-popup-modal.component';
import { CertLoginComponent } from './core/app-components/login/cert-login.component';
import { UnitInfoComponent } from '@fom-module/unit-info/unit-info.component';
import { LocationFiltersComponent } from './core/app-components/location-filters/location-filters.component';
import { UnitFiltersComponent } from './core/app-components/unit-filters/unit-filters.component';
import { AboutComponent } from '@core-module/app-components/about-component/about.component';
import { EditPocModalComponent } from '@fom-module/edit-modals/edit-poc-modal/edit-poc-modal.component';
import { EditOwnerTypeModalComponent} from '@fom-module/edit-modals/edit-owner-type-modal/edit-owner-type-modal.component';
import { EditPicklistTypeModalComponent} from '@fom-module/edit-modals/edit-picklist-type-modal/edit-picklist-type-modal.component';
import { EditAccountRequestModalComponent } from '@fom-module/edit-modals/edit-account-request-modal/edit-account-request.component';
import { DenyAccountRequestModalComponent } from '@fom-module/edit-modals/deny-account-request-modal/deny-account-request.component';
import { EditControlModalComponent } from '@fom-module/edit-modals/edit-control-modal/edit-control-modal.component';
import { EditOtherControlModalComponent } from '@fom-module/edit-modals/edit-other-control-modal/edit-other-control-modal.component';
import { EditReleasabilityModalComponent } from '@fom-module/edit-modals/edit-releasability-modal/edit-releasability-modal.component';
import { LoadingService } from '@services/global/loading/loading.service';
import { ConnectionsComponent } from '@fom-module/connections/connections.component';
import { FDOMapComponent } from '@fom-module/fdo-map/fdo-map.component';
import { EditFDOMapModalComponent } from '@fom-module/edit-modals/edit-fdo-map-modal/edit-fdo-map-modal.component';
import { GrafanaComponent } from '@fom-module/analysis/grafana/grafana.component';
import { ClassificationMarkingRelationship } from '@fom-module/classification-marking-relationship/classification-marking-relationship.component';
import { EditClassMarkRelationshipModalComponent } from '@fom-module/edit-modals/edit-classification-marking-relationship-modal/edit-class-mark-relationship-modal.component';
// date time picker...
// import { OwlDateTimeModule, OwlNativeDateTimeModule,
//   OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE, MomentDateTimeAdapter, OwlMomentDateTimeModule,
//   OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS } from '@danielmoncada/angular-datetime-picker';
// //import { OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime'
// //import { MomentDateTimeAdapter, OwlMomentDateTimeModule, OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS } from 'ng-pick-datetime-moment';
// export const ZULU_CUSTOM_FORMATS = {
//   parseInput: 'DD MMM GGGG HHmm[Z]',
//   fullPickerInput: 'DD MMM GGGG HHmm[Z]',
//   // datePickerInput: 'LL',
//   // timePickerInput: 'LT',
//   monthYearLabel: 'MMM YYYY',
//   dateA11yLabel: 'LL',
//   monthYearA11yLabel: 'MMMM YYYY',
// };
// const appRoutes: Routes = [{ path: 'units', component: UnitsComponent, data: {title: 'units'}},
//                             { path: 'user', component: UserComponent, data: { title: 'user'}},
//                             { path: 'notifier', component: NotifierComponent, data: {title: 'notifier'}},
//                             { path: 'slide-maintenance', component: SlideMaintenanceComponent, data: {title: 'slide-maintenance'}},
//                             { path: 'data-tags', component: DataTagsComponent, data: {title: 'data-tags'}},
//                             { path: 'service-dashboard', component: ServiceDashboardComponent, data: {title: 'service-dashboard'}},
//                             { path: 'parameters', component: ParametersComponent, data: {title: 'parameters'}},
//                           ];
@NgModule({
  entryComponents: [
    ModalComponent,
    AboutComponent,
    ConfirmationModalComponent,
    DynamicComponentComponent,
    EditUnitModalComponent,
    EditParameterModalComponent,
    EditUserModalComponent,
    EditParserQueueModalComponent,
    EditCategoryModalComponent,
    EditActivityModalComponent,
    EditClassificationModalComponent,
    EditControlModalComponent,
    EditOtherControlModalComponent,
    EditReleasabilityModalComponent,
    EditDataTagModalComponent,
    EditFDOMapModalComponent,
    EditClassMarkRelationshipModalComponent,
    EditFgiModalComponent,
    EditCommandModalComponent,
    EditPortModalComponent,
    EditAreaModalComponent,
    EditCountryModalComponent,
    EditSourceModalComponent,
    EditSlideModalComponent,
    EditFleetModalComponent,
    EditShipTypeModalComponent,
    EditShipClassModalComponent,
    EditVesselTypeModalComponent,
    EditFreqTypeModalComponent,
    EditScanTypeModalComponent,
    EditElintFunctionTypeModalComponent,
    EditConfidenceCodeModalComponent,
    EditGeoRefModalComponent,
    EditSystemConnectionModalComponent,
    EditMessageConfigModalComponent,
    AccessAgreementComponent,
    ChangePasswordDialog,
    AbacPreferencesComponent,
    EditUserSettingsComponent,
    UnitInfoComponent,
    LocationFiltersComponent,
    UnitFiltersComponent,
    TimeoutWarningComponent,
    EditPocModalComponent,
    LookupMaintenanceComponent,
    ClassificationMaintenanceComponent,
    EditOwnerTypeModalComponent,
    EditPicklistTypeModalComponent,
    EditAccountRequestModalComponent,
    DenyAccountRequestModalComponent,
    EditCollaborationModalComponent,
    EditFeaturePreferenceModalComponent,
    RecordMessagePopupModalComponent
  ],
  declarations: [
    AppComponent,
    HttpLoaderComponent,
    DynamicComponentComponent,
    ModalComponent,
    NavbarComponent,
    ConfirmationModalComponent,
    ChangePasswordDialog,
    NotifierComponent,
    SlideMaintenanceComponent,
    //FileSelectDirective,
    GenerateSlidesComponent,
    DataTagsComponent,
    FDOMapComponent,
    CertLoginComponent,
    LookupMaintenanceComponent,
    ClassificationMaintenanceComponent,
    EditUserSettingsComponent,
    ConnectionsComponent,
    GrafanaComponent,
    ClassificationMarkingRelationship
    // SearchFilterControlComponent
  ],
  imports: [
    BrowserModule,
    AdminModule,
    AppRoutingModule,
    CoreModule,
    FomModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    OverlayModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatMomentDateModule,
    ChartsModule,
    CovalentFileModule,
    CovalentLayoutModule,
    CovalentStepsModule,
    MatFormFieldModule,
    CdkTableModule,
    DragDropModule,
    // OwlDateTimeModule,
    // OwlMomentDateTimeModule,
    // RouterModule.forRoot(appRoutes, {useHash: true})
  ],
  providers: [
    AppInitService,
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppInitService], multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true},
    {provide: ErrorHandler, useClass: ClientErrorHandler},
    {provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, deps: [LoadingService], multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: SetFiltersInterceptor, multi: true},
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {horizontalPosition: 'right'}},
    FormBuilder,
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {
        hasBackdrop: true,
        //backdropClass: 'dialog-backdrop-no-click',
        closeOnNavigation: true,
        disableClose: true
      }
    }
    // { provide: OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    // {provide: MomentDateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE, OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS]},

    // {provide: OWL_DATE_TIME_FORMATS, useValue: ZULU_CUSTOM_FORMATS},
    //Idle
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  static injector: Injector;
  constructor(injector: Injector) {
    AppModule.injector = injector;
  }
}
