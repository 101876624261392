import { Fgi } from '@models/fgi.model';
import { FgiApiService } from './../api-services/fgi-api.service';
import { Injectable } from '@angular/core';
import { JsonConverter } from '../global/json-converter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable()
export class FgiService {
    private jsonConvert: JsonConverter;

    constructor(private fgiApiService: FgiApiService) {
        this.jsonConvert = new JsonConverter();
    }

    public getAll(): Observable<Fgi[]> {
        return this.fgiApiService.getAllFgis()
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, Fgi);
                })
            );
    }

    public getFgiByFgiId(fgiId): Observable<Fgi> {
        return this.fgiApiService.getFgiByFgiId(fgiId)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Fgi);
                })
            );
    }

    public update(fgi: Fgi): Observable<Fgi> {
        return this.fgiApiService.updateFgi(this.jsonConvert.serializeObject(fgi))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Fgi);
                })
            );
    }

    public create(fgi: Fgi): Observable<Fgi> {
        return this.fgiApiService.createFgi(this.jsonConvert.serializeObject(fgi))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Fgi);
                })
            );
    }

    public delete(fgi: Fgi): Observable<Fgi> {
        return this.fgiApiService.deleteFgi(this.jsonConvert.serializeObject(fgi))
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

}
