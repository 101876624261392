import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from './api.config';

@Injectable()
export class NotifierApiService {
    SERVER_URL;

    constructor(
        private http: HttpClient, apiConfig: APIConfig) {
        this.SERVER_URL = apiConfig.TARGET_URL;
    }

    public sendNotification(notifier: any): Observable<any> {
        return this.http.post(
            `${this.SERVER_URL}/notifications`, notifier);
    }
}
