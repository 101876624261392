import { Injectable } from '@angular/core';
import { Classification } from '@models/classification.model';
import { Control } from '@models/control.model';
import { OtherControl } from '@models/other-control.model';
import { FGI } from '@models/picklistExport';
import { Releasability } from '@models/releasability.model';

@Injectable({
  providedIn: 'root'
})
export class ClassificationDescriptionService {

  constructor() { }

  getClassificationDescription(classification: string, sciControls: string[],
                               fgiControls: string[], otherControls: string[],
                               releasabilities: string[]): string {
    sciControls = sciControls || [];
    const scis = sciControls.join(',');

    fgiControls = fgiControls || [];
    const fgis = fgiControls.join(',');

    otherControls = otherControls || [];
    const controls = otherControls.join(',');

    releasabilities = releasabilities || [];
    const disseminations = releasabilities.join(',');

    return `${classification}/${scis}/${fgis}/${controls}/${disseminations}`;
  }
}
