import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APIConfig } from './api.config';

@Injectable({
  providedIn: 'root'
})
export class CountryApiService {
  SERVER_URL;
  RESOURCE_URI = 'countries';

  constructor(private http: HttpClient, apiConfig: APIConfig) {
    this.SERVER_URL = apiConfig.TARGET_URL;
  }

  public getAllCountries(): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/${this.RESOURCE_URI}`);
  }

  public getCountryByCountryId(countryId: string): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/${this.RESOURCE_URI}/${countryId}`);
  }
/* Flag Services */
  public getFlag(id: string): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/${this.RESOURCE_URI}/${id}/flag`)
  }
  public getFlagURL(id: string): string{
    return `${this.SERVER_URL}/${this.RESOURCE_URI}/${id}/flag`
  }
/** End Flag Service */
  public createCountry(country: any): Observable<any> {
    return this.http.post(`${this.SERVER_URL}/${this.RESOURCE_URI}`, country);
  }

  public updateCountry(country: any): Observable<any> {
    return this.http.put(
      `${this.SERVER_URL}/${this.RESOURCE_URI}/${country.country_id}`, country);
  }

  public deleteCountry(country: any): Observable<any> {
    return this.http.delete(
      `${this.SERVER_URL}/${this.RESOURCE_URI}/${country.country_id}`);
  }
/** Flag Service Routes, require a country to exsist 1st **/
  public createFlag(imageFile, country_id): Observable<any> {
    return this.http.post(`${this.SERVER_URL}/${this.RESOURCE_URI}/${country_id}/flag`, imageFile)
  }

  public updateFlag(imageFile, country_id): Observable<any> {
    return this.http.put(`${this.SERVER_URL}/${this.RESOURCE_URI}/${country_id}/flag`, imageFile)
  }

  public deleteFlag(country_id): Observable<any> {
    return this.http.delete(`${this.SERVER_URL}/${this.RESOURCE_URI}/${country_id}/flag`)
  }
}
