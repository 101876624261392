import {JsonObject, JsonProperty, Any} from 'json2typescript';
import { DateConverter } from '../services/global/date-converter';
import { Permission } from '@models/permission.model';
import { Command } from '@models/command.model';
import { UserPki } from '@models/user-pki.model';
import * as moment from 'moment';

@JsonObject('User')
export class User {
  static readonly LOGIN_ACTIVITY_MESSAGES = {
    success: 'Success',
    failure: 'Failed',
    never: 'Never'
  };

  @JsonProperty('user_id', String, true)
  userId: string = undefined;
  @JsonProperty('user_name', String, true)
  username: string = undefined;
  @JsonProperty('email', String, true)
  email: string = undefined;
  @JsonProperty('first_name', String, true)
  firstName: string = undefined;
  @JsonProperty('last_name', String, true)
  lastName: string = undefined;
  @JsonProperty('password', String, true)
  password: string = undefined;
  @JsonProperty('create_ts', DateConverter, true)
  dateCreated: Date = undefined;
  @JsonProperty('command_id', String, true)
  commandId: string = undefined;
  @JsonProperty('permission_id', String, true)
  permissions: string = undefined;
  @JsonProperty('disabled_ind', Boolean, true )
  disabled = false;
  @JsonProperty('whitelisted_ind', Boolean, true )
  whitelisted = false;
  @JsonProperty('skip_inactivity_check_ind', Boolean, true )
  skipInactivityCheck = false;
  @JsonProperty('justification', String, true )
  justification: string = undefined;
  @JsonProperty('permission', Permission, true)
  permission: Permission = undefined;
  @JsonProperty('command', Command, true)
  command: Command = undefined;
  @JsonProperty('created_by', String, true)
  createdBy: string = undefined;
  @JsonProperty('JFMCCs', Object, true)
  JFMCCs: any = undefined;
  @JsonProperty('currentJFMCC', Object, true)
  currentJFMCC: any = undefined;
  @JsonProperty('current_password', String, true)
  currentPassword: string = undefined;
  @JsonProperty('password_confirmation', String, true)
  passwordConfirmation: string = undefined;
  @JsonProperty('last_login_ts', DateConverter, true)
  lastLogin: Date = undefined;
  @JsonProperty('last_attempted_login', DateConverter, true)
  lastFailedLogin: Date = undefined;
  @JsonProperty('login_attempts', Number, true)
  loginAttempts: number = undefined;
  @JsonProperty('user_pkis', Object, true)
  userPki: UserPki = undefined;
  // @JsonProperty('requestedChanges', Object, true)
  // requestedChanges: object = {};
  JFMCCId: string = undefined; // Property not included in the JSON conversion, but used.

  role(): string {
    if (!this.permission) {
      return 'reader';
    }

    switch (this.permission.permissionLevel) {
      case 1:
        return 'reader';
      case 2:
        return 'updater';
      case 3:
        return 'supervisor';
      case 4:
        return 'administrator';
      case 5:
        return 'system-admin';
      default:
        return 'reader';
    }
  }

  isSystemAdmin(): boolean {
    return this.permission && this.permission.permissionLevel >= 5;
  }

  isAdmin(): boolean {
    return this.permission && this.permission.permissionLevel >= 4;
  }

  isSupervisor(): boolean {
    return this.permission && this.permission.permissionLevel >= 3;
  }

  isUpdater(): boolean {
    return this.permission && this.permission.permissionLevel >= 2;
  }

  isReader(): boolean {
    return !this.permission || this.permission.permissionLevel >= 1;
  }

  isInactive(daysUntilInactive: Number): boolean {
    if (this.lastLogin == null || this.skipInactivityCheck) { return false }
    const lastActivity = this.lastLoginActivity();
    const now = moment()
    return now.diff(lastActivity.date, "days") > daysUntilInactive ? true : false
  }

  // Returns the last login activity for a user. Object contains two keys, date and message. Date
  // contains datetime of last attempt, message contains success, failure, or never.
  lastLoginActivity(): any {
    const result = {
      date: undefined,
      message: undefined
    };

    if (this.lastLogin) {
      if (this.lastFailedLogin) {
        if (this.lastLogin.valueOf() === this.lastFailedLogin.valueOf()) {
          result.date = this.lastLogin;
          result.message = User.LOGIN_ACTIVITY_MESSAGES.success;
        } else {
          result.date = this.lastFailedLogin;
          result.message = `${User.LOGIN_ACTIVITY_MESSAGES.failure} ${this.loginAttempts} time(s)`;
        }
      } else {
        result.date = this.lastLogin;
        result.message = User.LOGIN_ACTIVITY_MESSAGES.success;
      }
    } else {
      if (this.lastFailedLogin) {
        result.date = this.lastFailedLogin;
        result.message = `${User.LOGIN_ACTIVITY_MESSAGES.failure} ${this.loginAttempts} time(s)`;
      } else {
        result.message = User.LOGIN_ACTIVITY_MESSAGES.never;
      }
    }

    return result;
  }

  // instance functions to determine user's capabilities when it comes to various screens
  // these rules will supplement the general permissions given for admin, supervisor, read-write, etc.

  // Unit Update -- determine the ability to 'find' units in the typeahead for Unit Update
  // return a filter object that will be added to the find query
  public unitUpdateFilter(): any {
    // create the filter that passes the user's JFMCC.
    // a query will be built (in API) against the unit's command id or borrower command id

    if (!this.isSystemAdmin() && this.currentJFMCC) {
      return {JMFCCId: this.currentJFMCC.commandId};
    } else {
      return {};
    }
  }

  // Unit Update -- return a status message that depicts the user to unit relationship based on the
  // organization values of each -- e.g. Unit Update authority is primary, delegated, XX-tier, whatever
  public unitUpdateStatus(unit: any): string {
    // old ui code -- unit && unit.commandId && (user.command_id != unit.commandId)"

    // if operating as the same jifmic as the owner, no message
    let id = (this as any).commandId;
    if ((this as any).currentJFMCC) {
      id = (this as any).currentJFMCC.commandId;
    }
    if (this.isSystemAdmin() || (!unit || !unit.commandId) || (unit && unit.commandId && (id === unit.commandId))) {
      return '';
    }
    return 'Updating Unit as a Delegated Authority';
  }

  // Unit Maintenance -- determine the ability to edit values and the ability to delegate Unit Update authority
  // to another organization. return a boolean based on the unit's values and the user's values
  public canEdit(unit: any): boolean {
    // TODO, unansered question regarding 'can a delagatee edit a unit?'
    // if so, something like this but with high level organization id
    // return (unit.commandId == (this as any).command_id) || (unit.borrwerCommandId == (this as any).command_id)
    let canEdit = unit?.commandId === (this as any).commandId;
    if ((this as any).currentJFMCC) {
      canEdit = canEdit || unit?.commandId === (this as any).currentJFMCC.commandId;
      //canEdit = canEdit || unit.borrowerCommandId == (this as any).currentJFMCC.commandId
    }
    return this.isSystemAdmin() || canEdit;
  }

  // Lookup Maintenance -- determine the ability to edit or delete items
  // return a boolean based on the entity's values and the user's values
  public canEditLookup(commandId: any): boolean {
    let canEdit = commandId === (this as any).commandId;
    if ((this as any).currentJFMCC) {
      canEdit = canEdit || commandId === (this as any).currentJFMCC.commandId;
    }
    return this.isSystemAdmin() || canEdit;
  }

  // Lookup filters -- determine the filter applied to various picklists on the Unit Update screen
  // and other screens as needed
  // return a filter object that will be passed to the common component
  public unitOwnerId(unit: any = null): any {
    if (this.currentJFMCC) {
      return (this as any).currentJFMCC.commandId;
    } else {
      return (this as any).commandId;
    }
  }
}
