import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter } from '../services/global/date-converter';
import { Command } from '@models/command.model';

@JsonObject('FeaturePreference')
export class FeaturePreference {
  @JsonProperty('feature_pref_id', String, true)
  featurePrefId: string = undefined;
  @JsonProperty('feature_type_id', String, true)
  featureTypeId: string = undefined;
  @JsonProperty('enabled_ind', Boolean)
  enabled: boolean = true;
  @JsonProperty('owner_id', String, true)
  ownerId: string = undefined;
  @JsonProperty('owner_type_id', String, true)
  ownerTypeId: string = undefined;
  @JsonProperty('version_num', Number)
  versionNum: number | undefined = undefined;
  @JsonProperty('version_ts', DateConverter, true)
  versionDate: Date = undefined;
  @JsonProperty('feature_pref_name', String, true)
  featurePrefName: string = undefined;
  @JsonProperty('feature_pref_value', Object, true)
  featurePrefValue: any = undefined;
  @JsonProperty('created_by', String, true)
  createdBy: string = undefined;
  @JsonProperty('create_ts', DateConverter, true)
  dateCreated: Date = undefined;
  @JsonProperty('update_ts', DateConverter, true)
  dateUpdated: Date = undefined;
  @JsonProperty('updated_by', String, true)
  updatedBy: string = undefined;
  @JsonProperty('delete_ts', DateConverter, true)
  deleteTs: Date = undefined;
  @JsonProperty('deleted_by', String, true)
  deletedBy: string = undefined;

  fields = []
  values = []
}
