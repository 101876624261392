import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from './api.config';

@Injectable()
export class PicklistPrefApiService {
    SERVER_URL;

    constructor(
        private http: HttpClient, apiConfig: APIConfig) {
        this.SERVER_URL = apiConfig.TARGET_URL;
    }

    public getAllPicklistPrefs(params): Observable<any> {
        let httpParams = new HttpParams();

        for (const key in params) {
          if (params.hasOwnProperty(key)) {
            httpParams = httpParams.set(key, params[key]);
          }
        }
        return this.http.get(
            `${this.SERVER_URL}/picklist-prefs`, { params: httpParams });
    }

    public savePreferences(data: any): Observable<any> {
        return this.http.post(
          `${this.SERVER_URL}/picklist-prefs/save`, data);
      }
    public getPicklistPrefsByPicklistPrefsId(picklistPrefsId: string): Observable<any> {
        return this.http.get(
            `${this.SERVER_URL}/picklist-prefs/${picklistPrefsId}`);
    }

    public updatePicklistPrefs(picklistPrefs: any): Observable<any> {
        return this.http.put(
            `${this.SERVER_URL}/picklist-prefs/${picklistPrefs.picklist_prefs_id}`, picklistPrefs);
    }

    public createPicklistType(picklistPrefs: any): Observable<any> {
        return this.http.post(
            `${this.SERVER_URL}/picklist-prefs`, picklistPrefs);
    }

    public deletePicklistType(picklistPrefs: any): Observable<any> {
        return this.http.delete(
            `${this.SERVER_URL}/picklist-prefs/${picklistPrefs.picklist_prefs_id}`);
    }
}
