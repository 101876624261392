import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { LocationService } from "@services/data-services/location.service";
import { AppInitService } from "../../../../config/init.service";
import { Location } from "@models/location.model";
import { take } from "rxjs/operators";
import { VersionHistory } from "@models/version.model";
import { MatAccordion } from "@angular/material/expansion";
import { Unit } from "@models/unit.model";
import { Area } from "@models/area.model";
import { Activity } from "@models/activity.model";
import { Source } from "@models/source.model";
import { Fleet } from "@models/fleet.model";
import { ActivityService } from "@services/data-services/activity.service";
import { AreaService } from "@services/data-services/area.service";
import { UnitService } from "@services/data-services/unit.service";
import { SourceService } from "@services/data-services/source.service";
import { FleetService } from "@services/data-services/fleet.service";

@Component({
    selector: 'app-version-histories-modal',
    templateUrl: './version-histories-modal.component.html',
    styleUrls: ['./version-histories-modal.component.css']
})
export class VersionHistoriesModalComponent implements OnInit {
    headerString: String;
    locationId: String;
    location: Location;
    versions: VersionHistory[] = [];

    viewableAttributes = [
        'activity',
        'activity_category_id',
        'classification_description',
        'dec_lat',
        'dec_lon',
        'dtg_imaged_ts',
        'final_name',
        'fleet_id',
        'last_loc',
        'last_loc_src',
        'latitude',
        'loc_time_ts',
        'longitude',
        'mfi_ts',
        'nob_remove_ind',
        'op_area_id',
        'papa_case',
        'pat_cand_ind',
        'prev_assess',
        'tunnel_ind',
        'unit_id',
    ]

    // Object used to determine how to load various lookups that may be contained in each version's diff
    // Maps the foreign key attribute to information to determine how to load the
    lookupInfo = {
        activity_category_id: {
            // list of IDs for this attribute found in the diffs of all versions
            ids: [],
            // the attribute name for the ID on the UI model
            idAttr: 'activityId',
            // list of lookups loaded with the service
            lookups: [],
            // function that returns the human readable name for the lookup
            getName: (activity: Activity): string => {
                return activity.description;
            },
            // service used to retrieve the lookup (must be included in constructor)
            service: 'activityService'
        },
        fleet_id: {
            ids: [],
            idAttr: 'fleetId',
            lookups: [],
            getName: (fleet: Fleet): string => {
                return fleet.fleetCode;
            },
            service: 'fleetService'
        },
        op_area_id: {
            ids:[],
            idAttr: 'opAreaId',
            lookups: [],
            getName: (opArea: Area): string => {
                return opArea.opArea;
            },
            service: 'opAreaService',
        },
        last_loc_src: {
            ids: [],
            idAttr: 'sourceId',
            lookups: [],
            getName: (source: Source): string => {
                return source.sourceName;
            },
            service: 'sourceService',

        },
        unit_id: {
            ids: [],
            idAttr: 'unitId',
            lookups: [],
            getName: (unit: Unit): string => {
                return unit.fullName();
            },
            service: 'unitService',
        }
    };

    @ViewChild(MatAccordion) accordion: MatAccordion;

    constructor(
        private activityService: ActivityService,
        public dialogRef: MatDialogRef<VersionHistoriesModalComponent>,
        private fleetService: FleetService,
        private initService: AppInitService,
        private locationService: LocationService,
        private opAreaService: AreaService,
        private sourceService: SourceService,
        private unitService: UnitService,
    ) {
    }

    ngOnInit(): void {
        this.headerString = "Version History";

        Promise.resolve().then(() => {
            this.loadLocation();
        });
    }

    async loadLocation(): Promise<any> {
        this.location = await this.locationService.getLocationWithVersions(this.locationId).pipe(take(1)).toPromise();
        this.versions = this.location.versions;
        await this.loadAssociations();
    }

    async loadAssociations() {
        // Get all foreign keys from the diffs for each version.
        for (const version of this.location.versions) {
            for (const [foreignKey, info] of Object.entries(this.lookupInfo)) {
                const previousVal = (version.diff as any).previousValues[foreignKey];
                if (previousVal) {
                    info.ids.push(previousVal);
                }
                const newVal = (version.diff as any).newValues[foreignKey];
                if (newVal) {
                    info.ids.push(newVal);
                }
            }
        }

        for (const [foreignKey, info] of Object.entries(this.lookupInfo)) {
            if (info.ids.length > 0) {
                const uniqueIds = info.ids.filter((value, index, self) => self.indexOf(value) === index);
                const params = {};
                params[foreignKey] = uniqueIds;

                info.lookups = await this[info.service].getAll(params).pipe(take(1)).toPromise();
            }
        }
    }

    close(): void {
        this.dialogRef.close();
    }

    humanReadableAttributeName(attribute: string): string {
        return Location.READABLE_ATTRIBUTE_MAP[attribute];
    }

    humanReadableAttribute(attribute: string, value: any): string {
        const info = this.lookupInfo[attribute];
        if (info) {
            const lookup = info.lookups.find(e => e[info.idAttr] === value);
            if (lookup) {
                return info.getName(lookup);
            }
        }

        return value;
    }
}
