import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { GlobalMessageTriggers } from '@services/global/message-bus/global-message-triggers.enum';
import { GridBaseComponent } from '@fom-module/base-classes/grid-base.component';
import { GeoRefService } from '@services/data-services/geo-ref.service';
import { EditGeoRefModalComponent } from '@edit-modals/edit-geo-ref-modal/edit-geo-ref-modal.component';
import { Command } from '@models/command.model';
import { LookUpService} from '@services/data-services/lookup.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { GeoRef } from '@models/geo-ref.model';
import { take } from 'rxjs/operators';
import { AppInitService } from '../../../config/init.service';
import { User } from '@models/user.model';
import { CurrentUserService } from '@services/current-user-service';
import { ReportingService } from '@services/data-services/reporting.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-geo-refs',
  templateUrl: './geo-refs.component.html',
  styleUrls: ['./geo-refs.component.css', '../base-classes/grid-base.component.css']
})
export class GeoRefsComponent extends GridBaseComponent implements OnInit {

  displayedColumns = ['command.name', 'name', 'area', 'latitude', 'longitude', 'edit', 'delete'];
  commands: [] = [];
  user: User;

  readyForExport: boolean = true;
  exportSubscription: Subscription;

  constructor(public messageBusService: MessageBusService,
    private lookupService: LookUpService,
    public geoRefService: GeoRefService,
    private dialog: MatDialog,
    public route: Router,
    public initService: AppInitService,
    private currentUserService: CurrentUserService,
    private reportingService: ReportingService) {
      super(messageBusService, geoRefService, route);
      this.RELOAD_MESSAGE = GlobalMessageTriggers.RELOAD_GEO_REF_DATA;
      this.EDIT_MODAL_COMPONENT = EditGeoRefModalComponent;
    }

    ngOnInit() {
      // super.ngOnInit();
      this.user = this.currentUserService.getCurrentUser();
      this.loadEntityData();
      this.loadCommands();
    }

    loadEntityData() {
      this.geoRefService.getAll().pipe(take(1)).subscribe(entities => {
        entities.sort((a,b) => {
          return a.name < b.name ? -1 : 1
        })
        this.setDataSource(entities)
        let filterFunction = function (data, filter): boolean {
          return data.name.trim().toLowerCase().indexOf(filter) !== -1
            || data.area.trim().toLowerCase().indexOf(filter) !== -1;
        }
        this.dataSource.filterPredicate = filterFunction

        entities.forEach(element => {
          (element as any).canEdit = this.user.canEdit(element);
        });
      });

    }

  loadCommands() {
    this.lookupService.getLookupByType(Command, true).pipe(take(1)).subscribe(commands => {
      this.commands = commands
    })
  }
  getCommand(item) {
    let found = this.commands.find(element => {
      return (element as Command).commandId == item.commandId
    })
    if (found) {
      return (found as Command).name
    }
    else {
      return ''
    }
  }
  edit(geo: any) {
    const dialogRef = this.dialog.open(EditGeoRefModalComponent, {
      width: '320px'
    });
    dialogRef.componentInstance.isNew = false;
    dialogRef.componentInstance.entity = geo

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      if (result) {
        this.loadEntityData()
      }
    });

  }
  add() {
    const dialogRef = this.dialog.open(EditGeoRefModalComponent, {
      width: '320px'
    });
    dialogRef.componentInstance.isNew = true;
    dialogRef.componentInstance.entity = new GeoRef()

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      // in order to get complete data for the new Unit, go back to the DB
        this.loadEntityData()
    })
  }

  exportToExcel() {
    this.readyForExport = false;

    this.exportSubscription = this.reportingService.getLookupExcel('geo-ref').pipe(take(1)).subscribe(excel => {
      this.exportSubscription.unsubscribe();
      this.readyForExport = true;
      this.download(excel.body, 'FDT-M Geo-Ref Export.xlsx');
    }, err => {
      this.exportSubscription.unsubscribe();
      this.readyForExport = true;
      // pass error up to global ErrorHandler
      throw err;
    });
  }

  download(report: any, filename: string): void {
    const file = new Blob([report]);

    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(file); // xhr.response is a blob
    a.style.display = 'none';
    a.download = filename;
    document.body.appendChild(a);
    a.click();
  }
}
