
import { FomPageBaseComponent } from '@fom-module/base-classes/fom-page-base.component';
import { GlobalMessageTriggers } from '@services/global/message-bus/global-message-triggers.enum';
import { NotifierEmailService } from '@services/data-services/notifier.service';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective } from '@angular/forms';
import { Component, ViewChild } from '@angular/core';
import { LookUpService } from '@services/data-services/lookup.service';
import { Notifier } from '@models/notifier.model';
// import { Classification } from '@models/classification.model';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';
import { AppInitService } from '../../../config/init.service';

export interface Classification {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-notifier',
  templateUrl: './notifier.component.html',
  styleUrls: ['./notifier.component.css']
})

export class NotifierComponent extends FomPageBaseComponent {
  entity: any;
  editForm: FormGroup;
  RELOAD_MESSAGE: GlobalMessageTriggers;

  // RadioButton Options
  notifierTypes: any[] = [{type: 'fom', display: 'FDT-M users'}, {type: 'ocp', display: 'Collaboration Portal users'}];
  // Dropdown options
  // classificationOptions: Object[];
  classifications: Classification[] = [
    {value: '0', viewValue: 'UNCLASSIFIED'},
    {value: '1', viewValue: 'SECRET'},
    {value: '2', viewValue: 'TOP SECRET'},
    {value: '3', viewValue: 'SECRET//SI'},
    {value: '4', viewValue: 'TOP SECRET//SI'}
  ];


  BOTTOM_NAV_BAR: any;

  @ViewChild('editFormDirective') editFormDirective: FormGroupDirective;
  constructor(public notifierService: NotifierEmailService,
    public lookUpService: LookUpService,
    public formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    public messageBusService: MessageBusService,
    public route: Router,
    public initService: AppInitService) {
      super(messageBusService, route);
  }


  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {

    this.listenToMessageBus();
    this.entity = new Notifier();
    this.initializeForm();
    this.RELOAD_MESSAGE = GlobalMessageTriggers.RELOAD_NOTIFIER_DATA;
    super.ngOnInit();
  }

  initializeForm() {
    this.editForm = this.formBuilder.group({
      list: ['', Validators.required],
      subject: [],
      message: [],
      classification: []
    });
  }

  listenToMessageBus() {
    this.messageBusService.globalMessage$.subscribe(message => {
      if (message.trigger === this.RELOAD_MESSAGE) {
        this.initializeForm();
      }
    });
  }

  handleSubmit() {
    if (this.editForm.valid) {
      // console.log('Form submitted!');
      // console.log(this.editForm.value);
     this.updateEntity();
     this.notifierService.sendNotification(this.entity).pipe(take(1)).subscribe(res => {
       this.initializeForm;
       this.snackBar.open('Notifier message has been forwarded', 'OK', { duration: 5000 });
     }, err => {
       console.log(err)
       this.snackBar.open('Error forwarding Notifier message', 'OK', { duration: 5000 });
     });

      }
    }

  updateEntity() {
    for (const key in this.editForm.controls) {
      if (this.entity.hasOwnProperty(key.toString())) {
        this.entity[key.toString()] = this.editForm.controls[key]['value'];
      }
    }
  }

  clear() {
    this.editForm.reset();
  }
}
