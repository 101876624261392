import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APIConfig } from './api.config';

@Injectable({
  providedIn: 'root'
})
export class FleetApiService {
  SERVER_URL;
  RESOURCE_URL = 'fleets';

  constructor(private http: HttpClient, apiConfig: APIConfig) {
    this.SERVER_URL = apiConfig.TARGET_URL;
  }

  public getAllFleets(filters = {}): Observable<any> {
    let httpParams = new HttpParams();

    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        httpParams = httpParams.set(key, filters[key]);
      }
    }

    return this.http.get(`${this.SERVER_URL}/${this.RESOURCE_URL}`, { params: httpParams });
  }

  public getFleetByFleetId(fleetId: string): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/${this.RESOURCE_URL}/${fleetId}`);
  }

  public createFleet(fleet: any): Observable<any> {
    return this.http.post(
      `${this.SERVER_URL}/${this.RESOURCE_URL}`, fleet);

  }

  public updateFleet(fleet: any): Observable<any> {
    return this.http.put(
      `${this.SERVER_URL}/${this.RESOURCE_URL}/${fleet.fleet_id}`, fleet);
  }

  public deleteFleet(fleet: any): Observable<any> {
    return this.http.delete(
      `${this.SERVER_URL}/${this.RESOURCE_URL}/${fleet.fleet_id}`);
  }
}
