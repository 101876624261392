<app-classification-bar class="top-modal-classification-bar"></app-classification-bar>
<app-fdtm-modal-header [headerString]="headerString"></app-fdtm-modal-header>
<div mat-dialog-content >
  <div fxLayout="column" fxLayoutAlign="center stretch" class="row">
    <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
      <mat-form-field>
        <mat-select [disabled]=disableCommandControl() placeholder="Temporary Delegation"
          [(ngModel)]="unit.borrowerCommandId">
          <mat-option [disabled]="$any(unit).currentCommand?.commandId == option.commandId"
            *ngFor="let option of commandOptions" [value]="option.commandId">{{option.name}}</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- AUTOCOMPLETE -->
      <!-- <mat-form-field>
        <mat-select placeholder="Flag" required [(ngModel)]="unit.countryId"
          (selectionChange)="countrySelected($event)">
          <mat-option *ngFor="let option of globalCountries" [value]="option.countryId">
            <img [src]="option.flagImage" height="16">&nbsp;
            {{option.name}}
          </mat-option>
        </mat-select>
      </mat-form-field> -->

      <form>
        <mat-form-field class="input-full-width" appearance="fill">
          <mat-label>Flag</mat-label>
          <input type="text"
                 required
                 placeholder="Flag"
                 aria-label="Flag"
                 matInput
                 [formControl]="flagControl"
                 [matAutocomplete]="flagAutoComplete"
                 [(ngModel)]="unit.country.triGraph"
                 > 
          <mat-autocomplete #flagAutoComplete="matAutocomplete">
            <mat-option *ngFor="let option of flagFilteredOptions | async" [value]="option.triGraph" (click)="countrySelected(option)">
              {{option.triGraph}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </form>

      <mat-form-field>
        <mat-select placeholder="Threat" required [(ngModel)]="unit.threatId">
          <mat-option *ngFor="let option of threatOptions" [value]="option.threatId">{{option.threatName}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">

    <!-- AUTOCOMPLETE -->
    <!-- <mat-form-field>
      <mat-select placeholder="Home Port" required [(ngModel)]="unit.portId">
        <mat-option *ngFor="let option of portOptions" [value]="option.portId">{{option.name}}</mat-option>
      </mat-select>
    </mat-form-field> -->

    <form class="example-form">
      <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Home Port</mat-label>
        <input type="text"
               required
               placeholder="Home Port"
               aria-label="Home Port"
               matInput
               [formControl]="portControl"
               [matAutocomplete]="portAutoComplete"
               [(ngModel)]="unit.port.name">
        <mat-autocomplete #portAutoComplete="matAutocomplete">
          <mat-option *ngFor="let option of portFilteredOptions | async" [value]="option.name">
            {{option.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </form>

    <!-- AUTOCOMPLETE -->
    <!-- <mat-form-field class="fom-input-field">
      <mat-select [(ngModel)]="unit.fleetId" placeholder="Fleet" required [formControl]="fleetControl"
        [disabled]="!unit.country" matTooltip="Please select a country to view fleet options"
        [matTooltipDisabled]="unit.country">
        <mat-option *ngFor="let option of fleetOptions" [value]="option.fleetId" (click)="fleetSelected(option)">{{option.fleetCode}}</mat-option>
      </mat-select>
      <mat-error *ngIf="unit?.countryId && !fleetOptions.length">No Fleets for {{unit?.country?.name}}</mat-error>
    </mat-form-field> -->

    <form class="example-form">
      <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Fleet</mat-label>
        <input type="text"
               required
               placeholder="Fleet"
               aria-label="Fleet"
               matInput
               [formControl]="fleetControl"
               [matAutocomplete]="fleetAutoComplete"
               [(ngModel)]="unit.fleet.fleetCode"> 
        <mat-autocomplete #fleetAutoComplete="matAutocomplete">
          <mat-option *ngFor="let option of fleetFilteredOptions | async" [value]="option.fleetCode" [disabled]="!unit.country">
            {{option.fleetCode}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </form>

    <!-- AUTOCOMPLETE -->
    <!-- <mat-form-field>
      <mat-select placeholder="Category" required [(ngModel)]="unit.categoryId">
        <mat-option *ngFor="let option of categoryOptions" [value]="option.categoryId">{{option.category}}</mat-option>
      </mat-select>
    </mat-form-field> -->

    <form class="example-form">
      <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Category</mat-label>
        <input type="text"
               required
               placeholder="Category"
               aria-label="Category"
               matInput
               [formControl]="categoryControl"
               [matAutocomplete]="categoryAutoComplete"
               [(ngModel)]="unit.category.category"> 
        <mat-autocomplete #categoryAutoComplete="matAutocomplete">
          <mat-option *ngFor="let option of categoryFilteredOptions | async" [value]="option.category">
            {{option.category}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </form>

  </div>
  <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">

    <!-- AUTOCOMPLETE -->
    <!-- <mat-form-field>
      <mat-select placeholder="Ship Class" required [(ngModel)]="unit.ship.shipClassId" [formControl]="shipClassControl"
        [disabled]="!unit.country" matTooltip="Please select a country to view ship class options"
        [matTooltipDisabled]="unit.country">
        <mat-option *ngFor="let option of shipClassOptions" [value]="option.shipClassId" (click)="shipClassSelected(option)">{{option.shipClass}}
        </mat-option>
      </mat-select>
    </mat-form-field> -->

    <form class="example-form">
      <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Ship Class</mat-label>
        <input type="text"
               required
               placeholder="Ship Class"
               aria-label="Ship Class"
               matInput
               [formControl]="shipClassControl"
               [matAutocomplete]="shipClassAutoComplete"
               [(ngModel)]="unit.ship.shipClass.shipClass"> 
        <mat-autocomplete #shipClassAutoComplete="matAutocomplete">
          <mat-option *ngFor="let option of shipClassFilteredOptions | async" [value]="option.shipClass" [disabled]="!unit.country" (click)="shipClassSelected(option)">
            {{option.shipClass}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </form>

    <!-- AUTOCOMPLETE -->
    <!-- <mat-form-field>
      <mat-select placeholder="Vessel Type" required [(ngModel)]="unit.ship.vesselTypeId" [formControl]="vesselTypeControl"
        [disabled]="!unit.country" matTooltip="Please select a country to view vessel type options"
        [matTooltipDisabled]="unit.country">
        <mat-option *ngFor="let option of vesselTypeOptions" [value]="option.vesselTypeId" (click)="vesselTypeSelected(option)">{{option.vesselType}}
        </mat-option>
      </mat-select>
    </mat-form-field> -->

    <form class="example-form">
      <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Vessel Type</mat-label>
        <input type="text"
               required
               placeholder="Vessel Type"
               aria-label="Vessel Type"
               matInput
               [formControl]="vesselTypeControl"
               [matAutocomplete]="vesselTypeAutoComplete"
               [(ngModel)]="unit.ship.vesselType.vesselType"> 
        <mat-autocomplete #vesselTypeAutoComplete="matAutocomplete">
          <mat-option *ngFor="let option of vesselTypeFilteredOptions | async" [value]="option.vesselType" [disabled]="!unit.country" (click)="vesselTypeSelected(option)">
            {{option.vesselType}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </form>

    <mat-form-field>
      <input matInput placeholder="Pennant" required [(ngModel)]="unit.pennant">
    </mat-form-field>
  </div>

<div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
 
  <mat-form-field>
    <mat-select placeholder="Ship Type" required [(ngModel)]="unit.shipTypeId" [formControl]="shipTypeControl"
      [disabled]="true" matTooltip="Please select a country to view ship type options"
      [matTooltipDisabled]="unit.country">
      <mat-option *ngFor="let option of shipTypeOptions" [value]="option.shipTypeId">{{option.shipClassName}} {{option.vesselTypeName}} ({{option.remarks}})
      </mat-option>
    </mat-select>
    <mat-error *ngIf="unit?.countryId && !shipTypeOptions.length">No Ship Types for {{unit?.country?.name}}
    </mat-error>
  </mat-form-field>
  <mat-radio-group name="active" [(ngModel)]="unit.isActive">
    Active: <mat-radio-button class="smallRadio" color="primary" [value]=true>Yes</mat-radio-button>&nbsp;
    <mat-radio-button class="smallRadio" color="primary" [value]=false>No</mat-radio-button>
  </mat-radio-group>
  <mat-radio-group name="underway" [(ngModel)]="unit.isUnderway">
    Underway: <mat-radio-button class="smallRadio" color="primary" [value]=true>Yes</mat-radio-button>&nbsp;
    <mat-radio-button class="smallRadio" color="primary" [value]=false>No</mat-radio-button>
  </mat-radio-group>
</div>
  
  <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
    <mat-form-field>
      <input matInput placeholder="SCONUM" maxlength="10" [(ngModel)]="unit.sconum" (change)="checkUniqueSconum()">
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="MIDB ID" maxlength="11" [(ngModel)]="unit.midb">
      <button mat-icon-button (click)="getId()" matSuffix title="Get ID from MIDB"
        [disabled]="!unit.class || !unit.type || !unit.pennant || !unit.countryId">
        <mat-icon>search</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <br />
  <mat-hint class="mat-error" *ngIf="this.sconumError">Please enter a unique SCONUM before submitting.</mat-hint>
  <div *ngIf="mismatchedShipType">
    <span class="mat-error">Selected Flag, Ship Class and Vessel Type combination is not valid. Please review the ShipType Lookups.</span>
  </div>
 <div *ngIf="mismatchedFleet">
    <span class="yellow-text">Warning: The selected fleet's country does not match this unit's country.</span>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button color="primary" [disabled]="needFields() || this.sconumError" (click)="submit()">Submit
    </button>
    <button mat-raised-button (click)="cancel()">Cancel</button>
  </div>
  <!-- <div *ngIf="commandAlert()" fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
    <button [disabled]="needFields()"
      color="warn" mat-button (click)="submit()">Acknowledge
    </button>
    <span style="color: red;">This unit is borrowed from Command {{unit.port.command.name}}. Please coordinate your change with them. Please press 'Acknowledge' to process the change.</span>
   </div> -->
</div>
<app-classification-bar class="bottom-modal-classification-bar"></app-classification-bar>