import { ReportingApiService } from '@services/api-services/reporting-api.service';
import { Injectable } from '@angular/core';
// import { JsonConverter } from '@services/global/json-converter';
import { Observable, empty } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { JsonConverter } from '@services/global/json-converter';
import { Historical } from '@models/historical.model';
import { LocatorData } from '@models/locator-data.model';
declare var require: any;
const xml2js = require('xml2js');

@Injectable()
export class ReportingService {
    private jsonConvert: JsonConverter;

    constructor(private reportingApiService: ReportingApiService) {
        this.jsonConvert = new JsonConverter();
    }

    public getLocator(params: any): Observable<LocatorData[]> {
        return this.reportingApiService.getLocator(params)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, LocatorData);
                })
            );
    }

    public getGoogleEarthHistory(from: string, to: string) {
        return this.reportingApiService.getGoogleEarthHistory(from, to)
            .pipe(
                map((data) => {
                    return data;
                })
            );
        // .pipe(
        //     catchError((err, caught) => {
        //         console.error(err);
        //         return empty();
        //       })
        // );
    }
    public getCurrentNOBExcel(groupBy, groupOrder, columnHeaders) {
        return this.reportingApiService.getCurrentNOBExcel(groupBy, groupOrder, columnHeaders)
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    public getRecordMessage(currentJFMCC, type, saveReport, overrideFields, selectedPref, inPortHours) {
        return this.reportingApiService.getRecordMessage(currentJFMCC, type, saveReport, overrideFields, selectedPref, inPortHours)
            .pipe(
                map((data) => {
                    return data;
                })
            );
        // .pipe(
        //     catchError((err, caught) => {
        //         console.error(err);
        //         return empty();
        //     })
        // );
    }

    public getPACMIS() {
        return this.reportingApiService.getPACMIS()
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    public getCurrentNOB(filters) {

        return this.reportingApiService.getCurrentNOB(filters)
            .pipe(
                map((data) => {
                    // console.log(data);
                    return data;

                })
            );
        // .pipe(
        //     catchError((err, caught) => {
        //         console.error(err);
        //         return empty();
        //       })
        // );
    }

    public getNOBTicker() {
        return this.reportingApiService.getNOBTicker()
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }
    // get the NOB KML and download to user browser
    public getNOBKML() {
        return this.reportingApiService.getNOBKML()
            .pipe(
                map((data) => {
                    return data;
                })
            );
        // .pipe(
        //     catchError((err, caught) => {
        //         console.error(err);
        //         return empty();
        //       })
        // );
    }
    // get the NetworkLink KML and download to user browser
    public getNetworkLinkKML() {
        return this.reportingApiService.getNetworkLinkKML()
            .pipe(
                map((data) => {
                    return data;
                })
            );
        // .pipe(
        //     catchError((err, caught) => {
        //         console.error(err);
        //         return empty();
        //       })
        // );
    }
    // get the Historic KML and download to user browser
    public getHistoricKML(unit_id, time) {
        return this.reportingApiService.getHistoricKML(unit_id, time)
            .pipe(
                map((data) => {
                    return data;
                })
            );
        // .pipe(
        //     catchError((err, caught) => {
        //         console.error(err);
        //         return empty();
        //       })
        // );
    }
    // get the RSS feed for formatting and display in Products
    public getRSS() {
        return this.reportingApiService.getRSS()
            .pipe(
                map((data) => {
                    return data;
                })
            );
        // .pipe(
        //     catchError((err, caught) => {
        //         console.error(err);
        //         return empty();
        //       })
        // );
    }
    // get KML from search results
    public getSearchResultsKML(units) {
        units = this.jsonConvert.serialize(units);
        return this.reportingApiService.getSearchResultsKML(units)
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    // get Excel from search results
    public getSearchResultsExcel(units, groupBy = null, groupOrder = null, columnHeaders = null, sort = null) {
        const properties = new Historical();

        // quite the brittle hack, but need to get back to the original property names before deserialized.
        let sortParameter = null;
        if (sort) {
            sortParameter = { active: sort.active, direction: sort.direction };
            if (sortParameter.active) {
                sortParameter.active = Object.getPrototypeOf(properties)
                    .__jsonconvert__mapping__[`Historical.${sortParameter.active}`].jsonPropertyName;
            }
        }
        let internalName = null;
        if (groupBy) {
            internalName = Object.getPrototypeOf(properties).__jsonconvert__mapping__[`Historical.${groupBy}`].jsonPropertyName;
        }

        units = this.jsonConvert.serialize(units);
        return this.reportingApiService.getSearchResultsExcel(units, internalName, groupOrder, columnHeaders, sortParameter)
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    public getLookupExcel(lookupType) {
        const data = lookupType;

        return this.reportingApiService.getLookupExcel(data)
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    public getUnitExcel() {
        return this.reportingApiService.getUnitExcel()
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    public getUserExcel(): Observable<any> {
        return this.reportingApiService.getUserExcel()
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }
}
