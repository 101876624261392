<div class="admin-work-area">
  <div fxLayout fxLayoutAlign="center center">
    <span class="grid-title">{{initService.getConfig().titlePrefix}} FDT-M Unit Maintenance</span>
    <!-- Add Unit Floating Action Button -->
    <button mat-mini-fab class="add-button" (click)="add()">
      <img class="light-icon" src="./assets/icons/ic_add_white_24px.svg" matTooltip="Add Unit" />
    </button>
  </div>
  <div fxLayout fxLayoutAlign="center center">
    <mat-form-field class="groupSelect">
      <mat-select [(ngModel)]="grouping" placeholder="Group By" (selectionChange)="groupingChanged($event, grouping)">
        <mat-option *ngFor="let groupItem of groupItems" [value]="groupItem">
          {{groupItem.display}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field fxFlex="30%">
      <input matInput type="text" [(ngModel)]="filterValue" (ngModelChange)="onFilterChange($event)" autocomplete="off"
        placeholder="Filter by Unit Name (class type pennant)">
      <span class="hyper" (click)="filterValue = ''; doFilter('')" matSuffix>X</span>
    </mat-form-field>
    <app-search-filter-control [filterType]="'Unit'" (filtersChanged)="unitFiltersChanged($event)">
    </app-search-filter-control>
  </div>
  <div class="mat-elevation-z8">
    <div fxLayout class="table-actions">
      <div class="collapse-actions">
        <button mat-raised-button *ngIf="hasAnyExpandedGroups()" (click)="changeAllGroups(false)"
          matTooltip="Collapses all groupings within the table">
          <mat-icon>unfold_less</mat-icon> Collapse All
        </button>
        <button mat-raised-button *ngIf="!hasAnyExpandedGroups()" (click)="changeAllGroups(true)"
          matTooltip="Expands all groupings within the table">
          <mat-icon>unfold_more</mat-icon> Expand All
        </button>
      </div>

      <div class="export-actions">
        <button mat-raised-button [disabled]="!readyForExport" (click)="exportToExcel()">
          <mat-icon>file_download</mat-icon> Export to Excel
        </button>
      </div>

      <!-- Paginator -->
      <mat-paginator fxFlexOffset="auto" [pageSizeOptions]="[100, 200, 500, 1000, 5000]" [pageSize]="200"
        [length]="dataSource?.filteredData?.length - groupCount" showFirstLastButtons></mat-paginator>
    </div>

    <cdk-virtual-scroll-viewport tvsItemSize="48" headerHeight="56">
      <table mat-table [dataSource]="dataSource" matSort matSortStart="desc" (matSortChange)="sortData($event)">
        <!-- Data Columns -->
        <ng-container matColumnDef="currentCommand.name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Command </th>
          <td [ngClass]="{'isBorrowed': element.borrowerName != ''}" mat-cell *matCellDef="let element">
            <span *ngIf="element.borrowerName != ''" title="Temporary Delegation to {{element.borrowerName}}">
              {{element.currentCommand ? element.currentCommand.name: null}}
              ({{element.borrowerName}})
            </span>
            <span *ngIf="element.borrowerName == ''">{{element.currentCommand ? element.currentCommand.name:
              null}}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="fleet.fleetCode">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Fleet </th>
          <td mat-cell *matCellDef="let element"> {{element.fleet ? element.fleet.fleetCode: null}} </td>
        </ng-container>

        <ng-container matColumnDef="class">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Class </th>
          <td mat-cell *matCellDef="let element"> {{element.class}} </td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
          <td mat-cell *matCellDef="let element"> {{element.type}} </td>
        </ng-container>

        <ng-container matColumnDef="pennant">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Pennant </th>
          <td mat-cell *matCellDef="let element"> {{element.pennant}} </td>
        </ng-container>

        <ng-container matColumnDef="unitOtherNames">
          <th mat-header-cell *matHeaderCellDef> Other Names </th>
          <td mat-cell *matCellDef="let element">
            <button mat-button title="Edit Other Names" class="delete-button" (click)="$event.stopPropagation()"
              (click)="showUnitOtherNames(element)">
              <mat-icon>group_add</mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="port.name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Home Port </th>
          <td mat-cell *matCellDef="let element"> {{element.port ? element.port.name : 'Removed'}} </td>
        </ng-container>

        <ng-container matColumnDef="category.category">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Category </th>
          <td mat-cell *matCellDef="let element"> {{element.category ? element.category.category : null}} </td>
        </ng-container>

        <ng-container matColumnDef="threat.threatName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Threat </th>
          <td mat-cell *matCellDef="let element"> {{element.threat ? element.threat.threatName : null}}</td>
        </ng-container>

        <ng-container matColumnDef="ship.shipTypeName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Ship Type</th>
          <td mat-cell *matCellDef="let element"> {{element.ship ? element.ship.shipClass?.shipClass : null}} {{element.ship ? element.ship.vesselType?.vesselType : null}} ({{element.ship?.remarks}})</td>
        </ng-container>

        <ng-container matColumnDef="country.name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Flag </th>
          <td mat-cell *matCellDef="let element">
            <img [src]="element.flagSource" height="16">&nbsp;
            {{element.country ? element.country.name : null}}
          </td>
        </ng-container>

        <ng-container matColumnDef="sconum">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> SCONUM </th>
          <td mat-cell *matCellDef="let element"> {{element.sconum}} </td>
        </ng-container>

        <ng-container matColumnDef="midb">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> MIDB ID </th>
          <td mat-cell *matCellDef="let element"> {{element.midb}} </td>
        </ng-container>

        <!----ng-container matColumnDef="papaCase">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Case No </th>
        <td mat-cell *matCellDef="let element"> {{element.location ? element.location.papaCase : null}} </td>
      </ng-container-->

        <ng-container matColumnDef="isActive">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Active </th>
          <td mat-cell *matCellDef="let element"> {{element.isActive}} </td>
        </ng-container>

        <!-- Unit Details -->
        <ng-container matColumnDef="info">
          <th mat-header-cell *matHeaderCellDef>Info</th>
          <td mat-cell *matCellDef="let element">
            <button mat-button title="Show Unit Details" class="delete-button" (click)="$event.stopPropagation()"
              (click)="showUnitDetails(element)">
              <mat-icon>info</mat-icon>
            </button>
          </td>
        </ng-container>


        <!-- Edit Action Column -->
        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef>
            <div>
              Edit
            </div>
          </th>
          <td mat-cell *matCellDef="let row">
            <button title="Edit Unit" mat-button *ngIf="row.canEdit" class="delete-button" [disabled]="!row.canEdit"
              (click)="$event.stopPropagation()" (click)="edit(row)">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="track-maintenance">
          <th mat-header-cell *matHeaderCellDef>
            <div>
              Track<br>Maint.
            </div>
          </th>
          <td mat-cell *matCellDef="let row">
            <button title="View Track Maintenance for Unit" mat-button class="delete-button" *ngIf="user.canEdit(row)"
              [disabled]="!user.canEdit(row)" (click)="$event.stopPropagation()"
              (click)="navigateTrackMaintenance(row)">
              <mat-icon>history</mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- Delete Action Column -->
        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef>
            <div>
              Delete
            </div>
          </th>
          <td mat-cell *matCellDef="let row">
            <button title="Delete Unit" mat-button class="delete-button" *ngIf="user.canEdit(row)"
              [disabled]="!user.canEdit(row)" (click)="$event.stopPropagation()" (click)="deleteUnit(row)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <!-- the ngClass will cover the cursor, but can't disable since it's not a button -->
        <tr class="table-row" mat-row *matRowDef="let row; let i = index; columns: displayedColumns;"></tr>
        <!-- Group header -->
        <ng-container matColumnDef="groupHeader">
          <td colspan="999" mat-cell *matCellDef="let group; grouping" class="hyper">
            <!-- <mat-icon title="hide group items" class="hyper" *ngIf="group.expanded">chevron_left</mat-icon>
          <mat-icon title="show group items" class="hyper" *ngIf="!group.expanded">chevron_right</mat-icon> -->
            <span title="click to show/hide group items">
              {{group.name}}: {{group.itemCount}} total Unit(s)
            </span>
          </td>
        </ng-container>

        <tr mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup" (click)="groupHeaderClick(row)"
          class="groupHeader"> </tr>
      </table>
    </cdk-virtual-scroll-viewport>
  </div>
</div>