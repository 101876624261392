<mat-card class="form-card">
    <div fxLayout fxLayoutAlign="center" class="title">
      <div class="card">
        <img class="fomImage" height="74px" width="74px" src="assets/images/FDT-M.png" />
      </div>
      <div class="nob-header-middle" fxFlex="60%">
        <div class="grid-title nob-title">
          <span>{{initService.getConfig().titlePrefix}} FDT-M Open Collaboration Portal</span>
        </div>
        <div class="nob-title">
          <mat-form-field style="width: 400px;">
            <input matInput type="text" [(ngModel)]="filterValue" (keyup)="unitFilter($event.target.value)" placeholder="Unit Finder (class type pennant)">
          </mat-form-field>
          <button mat-mini-fab class="action-button padder" (click)="add('')">
            <img class="light-icon" src="./assets/icons/ic_add_white_24px.svg" matTooltip="Submit Info" />
          </button>
          <button mat-mini-fab class="action-button padder" (click)="refresh()">
            <mat-icon class="light-icon">refresh</mat-icon>
          </button>
        </div>
      </div>
      <div class="card" fxLayoutAlign="right">
        <img class="fomImage" height="74px" width="74px" src="assets/images/FDT-M.png" />
     </div>
    </div>
  <div fxLayoutGap="10px" fxLayoutGap.xs="0">
    <table class="ocp-table" mat-table matSort [dataSource]="dataSource" (matSortChange)="sortData($event)">

        <!-- Data Columns -->
        <ng-container matColumnDef="name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Unit Name </th>
          <td mat-cell *matCellDef="let element">
            <img [src]="utilities.getFlag(element.unit)" height="12">
              {{element.name}}
          </td>
        </ng-container>

        <ng-container matColumnDef="classification">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Classification </th>
          <td mat-cell *matCellDef="let element">
            {{element.classification}}
          </td>
        </ng-container>

        <ng-container matColumnDef="comments">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Comments </th>
          <td mat-cell *matCellDef="let element">
              <div matTooltip="{{element.comments}}" class="wrap-text">{{element.comments}}</div>
          </td>
        </ng-container>
        <ng-container matColumnDef="submitter">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Submitter </th>
          <td mat-cell *matCellDef="let element">
              {{element.user ? element.user.firstName : element.firstName}}
              {{element.user ? element.user.lastName : element.lastName}}
              <a href="mailto:{{element.user ? element.user.email : element.email}}?subject=FDT-M Open Collaboration Portal">
                {{element.user ? element.user.email : element.email}}
              </a>
          </td>
        </ng-container>
        <ng-container matColumnDef="dateCreated">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Submission Date </th>
          <td mat-cell *matCellDef="let element">
              {{element.dateCreated | zulu1Pipe}}
          </td>
        </ng-container>
        <ng-container matColumnDef="docName">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> File </th>
          <td mat-cell *matCellDef="let element">
            <a class="hyper" (click)="$event.preventDefault();downloadFile(element)" href="">
              {{element.docName}} <span *ngIf="element.docName">{{element.docSize | fileSize}}</span>
            </a>
          </td>
        </ng-container>
        <!-- Delete Action Column -->
        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef>
              Delete
          </th>
          <td mat-cell *matCellDef="let row">
            <button title="Delete Submittal" mat-button class="delete-button" 
              (click)="delete(row)">
              <img src="./assets/icons/ic_delete_gray_24px.svg" />
            </button>
          </td>
        </ng-container>
        <tr class="nob-header-row" mat-header-row *matHeaderRowDef="gridColumns"></tr>
        <tr class="nob-row" mat-row *matRowDef="let row; columns: gridColumns;"></tr>

      </table>
      </div>
    </mat-card>
