
<div class="ticker-control">
  <div class="ticker-wrapper">
    <button [disabled]="!showRefresh" class="refresh hyper" (click)="refreshClicked()" matTooltip="Refresh Ticker and Unit Grid">
      <mat-icon class="adjust-button">refresh</mat-icon>
    </button>
    <span class="ticker">
      <span class="ticker-label">Last 5 Updates</span>
      <span class="hyper ticker-content" (click)="unitClicked(currentElement)">{{currentElement.final_name}} was updated</span>
    </span>
  </div>
</div>