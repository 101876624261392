import { Component, OnInit, OnDestroy } from '@angular/core';
import { ReportingService } from '@services/data-services/reporting.service';
import { CommonModule } from '@angular/common';
import { FomPageBaseComponent } from '@fom-module/base-classes/fom-page-base.component';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
// import {TimerObservable} from "rxjs/observable/TimerObservable";
import {Subscription, timer} from 'rxjs';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { AppInitService } from '../../../../config/init.service';

declare var require: any;
var xml2js = require('xml2js');

@Component({
  selector: 'app-rss',
  templateUrl: './rss.component.html',
  styleUrls: ['./rss.component.css']
})
export class RSSComponent extends FomPageBaseComponent implements OnInit, OnDestroy {

  feed = {title: '', description: '', ttl: '', item: []};
  refresh = 30;
  private dataSubscription: Subscription;

  constructor(private reportingService: ReportingService,
    public messageBusService: MessageBusService,
    public route: Router,
    public initService: AppInitService) {
      super(messageBusService, route);
  }

  ngOnInit() {
    this.startTimer();
    super.ngOnInit();
  }
  ngOnDestroy() {
    // gotta make the subscriptions go away
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe()
    }
  }

  public startTimer() {
    // get new data every xx minutes
    let millis = this.refresh * 60 * 1000
    let dataTimer = timer(0, millis)
    this.dataSubscription = dataTimer.subscribe(x => {
      this.processRSS()
    })
  }
  public processRSS() {
    this.reportingService.getRSS().pipe(take(1)).subscribe(rss => {
      xml2js.parseString(rss, {'explicitArray': false}, this.loadFeed)
    });
  }

  loadFeed = (err, result) => {
    if (!result.rss.channel.item) {
      result.rss.channel.item = []
    }
    else if (!Array.isArray(result.rss.channel.item)) {
      result.rss.channel.item = [result.rss.channel.item]
    }
    this.feed = result.rss.channel
    this.refresh == result.rss.channel.ttl || 30  
  }

  public userRefresh() {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe()
    }
    this.startTimer()
  }
}
