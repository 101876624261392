import { Observable } from 'rxjs';
import { GlobalStore } from '@services/global/message-bus/global-store.model';
import { Router, NavigationEnd } from '@angular/router';
import { GlobalBusMessage } from '@services/global/message-bus/global-bus-message.model';
import { LoginModalComponent } from '@fom-module/edit-modals/login-modal/login-modal.component';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { GlobalMessageTriggers } from '@services/global/message-bus/global-message-triggers.enum';
import { AuthorizationService } from '@services/data-services/authorization.service';
import { AccessAgreementComponent } from '../access-agreement/access-agreement.component';
import { EditUserSettingsComponent } from '@fom-module/edit-modals/edit-user-settings-modal/edit-user-settings.component';
import { TimeoutWarningComponent } from '../timeout-warning/timeout-warning.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
import { AppInitService } from '../../../../config/init.service';
import { take } from 'rxjs/operators';
import { Idle } from 'idlejs';
import { User } from '../../models/user.model';
import { RequestAccountModalComponent } from '@fom-module/edit-modals/request-account-modal/request-account-modal.component';
import { AuthenticationService } from '@services/data-services/authentication.service';
import { JsonConverter } from '@services/global/json-converter';
import { CurrentUserService } from '@services/current-user-service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  private jsonConvert: JsonConverter;
  // the user preferences for command, op_area, port are gathered at login (defaults)
  // and may be changed via the preferences menu option
  currentUserABACPrefs: any;

  // watchers for the menu classes, set on route changes
  mainMenu = '';
  secondMenu = '';

  globalStore$: Observable<GlobalStore>;
  globalStore: GlobalStore;
  globalPermission: Array<any> = []; // array

  // JFMCC changing
  JFMCCs = [];
  currentJFMCC: any;

  version = '';
  dashboard: string;
  authServiceURL = '';

  user!: User;

  grafanaTabToggle = this.initService.getConfig().toggleGrafanaTab || false;
  pacmisTabToggle = this.initService.getConfig().togglePacmisTab || false;

  constructor(private messageBusService: MessageBusService, private route: Router, private location: Location,
    private dialog: MatDialog, public snackBar: MatSnackBar, public initService: AppInitService,
    private authenticationService: AuthenticationService,
    private authorizationService: AuthorizationService,
    public currentUserService: CurrentUserService) {
      this.jsonConvert = new JsonConverter();
      this.globalStore$ = this.messageBusService.globalStore$;
      this.subscribeToStore();
      this.getPermissions();
  }

  ngOnInit(): void {
    this.user = Object.assign(new User(), JSON.parse(sessionStorage.getItem('user')));
    this.version = this.initService.getConfig().VERSION || '3.0.0';
    this.dashboard = this.initService.getConfig().dashboardURL;
    this.route.events.subscribe(event => {
      if (event instanceof NavigationEnd) {   // grab the first part
        const parts = event.url.split('/');
        this.mainMenu = parts.length >= 1 ? parts[1] : '';
        this.secondMenu = parts.length >= 2 ? parts[2] : '';
      }
    });
    this.authServiceURL = this.initService.getConfig().PKI.authServiceRedirectURL;

    // since the navbar component is always present, let it host the idle watcher
    const timeout = this.initService.getConfig().sessionTimeoutMinutes || 30;
    const idle = new Idle()
      .whenNotInteractive()
      .within(timeout)
      .do(() => this.checkIdle())
      .start();
  }

  @HostListener('window:beforeunload', ['$event'])
  async beforeunloadHandler($event) {
    await this.authenticationService.logout('User Close Browser', true).toPromise();
    setTimeout(() => { }, 500);
  }

  checkIdle() {
    if (this.globalStore && this.globalStore.storeData && (this.globalStore.storeData as any).isLoggedIn) {
      const config = { autoFocus: false, disableClose: true };
      const dialogRef = this.dialog.open(TimeoutWarningComponent, config);
      dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
        if (result) {
          this.handleLogoutClick();
        } else {
          // testing to make sure the idle counter fires again
        }
      });
    }
  }

  subscribeToStore() {
    this.globalStore$.subscribe(store => {
      this.globalStore = store;
      this.JFMCCs = [];
      this.currentJFMCC = null;
      if (this.globalStore && this.globalStore.storeData && (this.globalStore.storeData as any).currentUser) {
        this.JFMCCs = (this.globalStore.storeData as any).currentUser.JFMCCs || [];
        // try to find the JFMCC to set, if can't find, set to first or null
        const found = (this.globalStore.storeData as any).currentUser.currentJFMCC ? (this.globalStore.storeData as any).currentUser.JFMCCs.find(x => {
          return x.commandId === (this.globalStore.storeData as any).currentUser.currentJFMCC.commandId;
        }) : null;

        if (found) {
          this.currentJFMCC = found;
        } else {
          this.currentJFMCC = this.JFMCCs.length ? this.JFMCCs[0] : null;
        }
      }
    });
  }

  // if the user has more than one JFMCC, they can choose
  jfmccChange(event) {
    // shove the change back into global store and the sessionStorage
    const user = this.jsonConvert.deserializeObject(JSON.parse(sessionStorage.getItem('user')), User);
    (user as any).currentJFMCC = event.value;
    sessionStorage.setItem('user', JSON.stringify(this.jsonConvert.serializeObject(user)));
    const storeData = {
      isLoggedIn: true,
      currentUserId: user.userId,
      currentUser: user
    };
    this.messageBusService.updateStore(new GlobalStore(storeData));
    const currentUrl = this.route.url;
    this.route.navigateByUrl('/', {skipLocationChange: true}).then(() =>
      this.route.navigate([currentUrl])
    );
  }

  // open an external URL for the Analysis Dashboard
  openDashboard() {
    if (this.dashboard) {
      try {
        const handle = window.open(this.dashboard, '_blank');
        if (!handle) {
          throw 'Analytics Dashboard is not configured properly';
        }
      } catch (e) {
        throw 'Analytics Dashboard is not configured properly';
      }
    } else {
      throw 'Analytics Dashboard is not configured properly';
    }
  }

  handleHomeClick() {
    this.route.navigate(['home']);
  }

  handleLoginClick() {
    const dialogRef = this.dialog.open(LoginModalComponent, {
      width: '360px'
    });
    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      if (result == true) {
        // nothing else to do? could navigate to another page for startup, like NOB
      }
    });
  }

  handleLogoutClick() {
    this.authenticationService.logout('User Logout').pipe(take(1)).subscribe(result => {
      this.clearAndGoHome();
    }, error => {
      this.clearAndGoHome();
    });
  }

  clearAndGoHome() {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('currentUserId');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('ackAgreement');
    this.globalPermission = [];
    this.messageBusService.updateStore(new GlobalStore({ isLoggedIn: false }));
    this.route.navigate(['home']);
  }

  onSetTheme() {
    this.messageBusService.publishMessage(new GlobalBusMessage(GlobalMessageTriggers.SET_THEME));
  }

  // decide whether to show or hide each menu item, based on permissions that are issued by the API server
  // NOTE: admin selections are handled within those components
  showMenu(menu) {
    return this.globalPermission.find(permission => permission.menu === menu);
  }

  public getPermissions() {
    this.globalPermission = [];
    // if the user is logged in
    this.globalStore$.subscribe(store => {
      if (store.storeData['isLoggedIn']) {
        this.authorizationService.getAllPermissions().pipe(take(1)).subscribe(result => {
          this.globalPermission = result;
          this.getAgreement();
        });
      } else { this.globalPermission = []; }
    });
  }

  public async getAgreement() {
    // it's a string, check it to see if already acknowledged for this session.
    // logout should clear it
    if (sessionStorage.getItem('ackAgreement') === 'true') {
      return;
    }
    const config = { autoFocus: true, disableClose: true };
    const dialogRef = this.dialog.open(AccessAgreementComponent, config);
    const agree = await dialogRef.afterClosed().toPromise();
    if (!agree) {
      this.globalPermission = [];
      this.handleLogoutClick();
    } else {
      sessionStorage.setItem('ackAgreement', 'true');
    }
  }

  userSettings(): void {
    const dialogRef = this.dialog.open(EditUserSettingsComponent, {
      width: '660px'
    });

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      if (result && result.length) {
        this.snackBar.open(`${result}`, 'OK', { duration: 3000 });
        const currentUrl = this.route.url;
        this.route.navigateByUrl('/', {skipLocationChange: true}).then(() =>
          this.route.navigate([currentUrl]));
      }
    });
  }

  requestAccount() {
    const dialogRef = this.dialog.open(RequestAccountModalComponent, { width: '600px' });
  }
}
