<div class="admin-work-area">
  <div fxLayout fxLayoutAlign="center center">
    <span class="grid-title">{{ initService.getConfig().titlePrefix }} FDT-M Track Maintenance</span>
  </div>

  <div fxLayout="row" fxLayoutAlign="center center">
    <form [formGroup]="searchForm" class="search-form">
      <span matPrefix>
        <mat-select class="country-filter" [(value)]="filterByCountry" (selectionChange)="countryFilterChange($event)">
          <mat-select-trigger *ngIf="filterByCountry.name" matTooltip="Find Units for {{ filterByCountry.name }}">
            <img [src]="getFlag(filterByCountry)" height="16">&nbsp;
          </mat-select-trigger>
          <mat-select-trigger *ngIf="!filterByCountry.name" matTooltip="Select a Country to Filter Search">
            <mat-icon style="vertical-align: middle">flag</mat-icon>
          </mat-select-trigger>
          <mat-option [value]="noCountry">
            <mat-icon style="vertical-align: middle">flag</mat-icon> (clear)
          </mat-option>
          <mat-option *ngFor="let country of countries" [value]="country">
            <img [src]="getFlag(country)" height="16">
            &nbsp;
            {{ country.name }}
          </mat-option>
        </mat-select>
      </span>
      <mat-form-field class="unit-input-field">
        <span matPrefix *ngIf="utilities.getFlag(searchForm.controls?.unit?.value)">
          <img style="vertical-align: middle" [src]="utilities.getFlag(searchForm.controls?.unit?.value)" height="20">
          &nbsp;
        </span>
        <input matInput type="text" formControlName="unit" placeholder="Unit" [matAutocomplete]="unitAutocomplete"
          tabindex="1" matTooltip="{{ searchForm.controls?.unit?.value | unitNamePipe }}">
      </mat-form-field>
      <mat-autocomplete #unitAutocomplete="matAutocomplete" [displayWith]="displayUnit" (optionsScroll)="onUnitOptionsScroll()">
        <mat-option *ngFor="let option of filteredUnits$ | async" [value]="option">
          <span matTooltip="{{ option.country?.name }}">
            <img style="vertical-align: middle" [src]="utilities.getFlag(option)" height="20">&nbsp;
          </span>
          <span matTooltip="{{ option.fullName() }}">{{ option.fullName() }}</span>
        </mat-option>
        <mat-option *ngIf="isLoading" class="is-loading">
          <mat-spinner diameter="30"></mat-spinner>
        </mat-option>
      </mat-autocomplete>
      <span class="search-element">
        <button mat-raised-button color="primary" (click)="search()">Find Tracks</button>
      </span>
    </form>
  </div>

  <div class="mat-elevation-z8">
    <div class="bulk-actions" *ngIf="dataSource && dataSource.data && dataSource.data.length && selection.selected.length > 0">
      <button mat-raised-button (click)="bulkEdit()">Bulk Edit</button>&nbsp;
      <button mat-raised-button (click)="bulkDelete()">Bulk Delete</button>
    </div>
    <mat-paginator [pageSizeOptions]="pageSizeOptions"
      [pageSize]="pageSize"
      [pageIndex]="pageIndex"
      [length]="dataLength"
      (page)="pageChange($event)"
      showFirstLastButtons></mat-paginator>
    <cdk-virtual-scroll-viewport headerEnabled="true" tvsItemSize="30" headerHeight="56">
      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortChange($event)">
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            Select<br>
            <mat-checkbox (change)="$event ? toggleSelectAll() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </th>

          <td mat-cell *matCellDef="let element">
            <mat-checkbox [disabled]="!canEdit(element)" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(element) : null"
              [checked]="selection.isSelected(element)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="classification_description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Classification</th>
          <td mat-cell *matCellDef="let element">{{element.classification}}</td>
        </ng-container>

        <ng-container matColumnDef="final_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let element">{{element.finalName}}</td>
        </ng-container>

        <ng-container matColumnDef="dtg_imaged_ts">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>U/W DTG</th>
          <td mat-cell *matCellDef="let element">{{element.dtgImaged | date: 'ddHHmm\'Z\' MMM YYYY':'UTC'}}</td>
        </ng-container>

        <ng-container matColumnDef="loc_time_ts">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Location Time</th>
          <td mat-cell *matCellDef="let element">{{element.locTime | date: 'ddHHmm\'Z\' MMM YYYY':'UTC'}}</td>
        </ng-container>

        <ng-container matColumnDef="last_loc">
          <th mat-header-cell *matHeaderCellDef>Last Location</th>
          <td mat-cell *matCellDef="let element">{{element.lastLoc}}</td>
        </ng-container>

        <ng-container matColumnDef="latitude">
          <th mat-header-cell *matHeaderCellDef>Latitude</th>
          <td mat-cell *matCellDef="let element">{{element.latitude}}</td>
        </ng-container>

        <ng-container matColumnDef="longitude">
          <th mat-header-cell *matHeaderCellDef>Longitude</th>
          <td mat-cell *matCellDef="let element">{{element.longitude}}</td>
        </ng-container>

        <ng-container matColumnDef="el_not">
          <th mat-header-cell *matHeaderCellDef>ELNOT</th>
          <td mat-cell *matCellDef="let element">{{element.elint?.el_not}}</td>
        </ng-container>

        <ng-container matColumnDef="last_loc_src">
          <th mat-header-cell *matHeaderCellDef>Source</th>
          <td mat-cell *matCellDef="let element">{{element.source?.sourceName}}</td>
        </ng-container>

        <ng-container matColumnDef="src_orig">
          <th mat-header-cell *matHeaderCellDef>Originator</th>
          <td mat-cell *matCellDef="let element">{{element.srcOrig}}</td>
        </ng-container>

        <ng-container matColumnDef="activity">
          <th mat-header-cell *matHeaderCellDef>Activity</th>
          <td mat-cell *matCellDef="let element">{{element.activity}}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element">
            <button mat-button matTooltip="View Version History" matTooltipPosition="above" class="action-button"
              (click)="viewHistory(element)">
              <mat-icon>history</mat-icon>
            </button>
            <button mat-button matTooltip="Edit Location" matTooltipPosition="above" class="action-button"
              (click)="edit(element)" *ngIf="canEdit(element)">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-button matTooltip="Delete Location" matTooltipPosition="above" class="action-button"
              (click)="delete(element)" *ngIf="canEdit(element)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr class="table-row" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </cdk-virtual-scroll-viewport>
  </div>
</div>
