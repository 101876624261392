import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from './api.config';
import { ScanType } from '@models/scan-type.model';

@Injectable()
export class ScanTypeApiService {
  SERVER_URL;
  RESOURCE_URI = 'ScanTypes';


  constructor(
    private http: HttpClient, apiConfig: APIConfig) {
    this.SERVER_URL = apiConfig.TARGET_URL;
  }

  public getAllScanTypes(): Observable<any> {
    return this.http.get(
      `${this.SERVER_URL}/${this.RESOURCE_URI}`);
  }

  public getScanTypeByScanTypeId(id: string): Observable<any> {
    return this.http.get(
      `${this.SERVER_URL}/${this.RESOURCE_URI}/${id}`);
  }

  public updateScanType(ScanType: ScanType): Observable<any> {
    return this.http.put(
      `${this.SERVER_URL}/${this.RESOURCE_URI}/${ScanType.scan_type_id}`, ScanType);
  }

  public createScanType(ScanType: any): Observable<any> {
    return this.http.post(
      `${this.SERVER_URL}/${this.RESOURCE_URI}`, ScanType);
  }

  public deleteScanType(ScanType: ScanType): Observable<any> {
    return this.http.delete(
      `${this.SERVER_URL}/${this.RESOURCE_URI}/${ScanType.scan_type_id}`);
  }
}
