import { DataCardsApiService } from '@services/api-services/datacards-api.service';
import { Injectable } from '@angular/core';
import { Observable, empty } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class DataCardsService {

    constructor(private dataCardsApiService: DataCardsApiService) {
    }


    public getTotalDays(queryParams: Object): Observable<Location[]> {
      /** Information for Underway History */
        return this.dataCardsApiService.getTotalDays(queryParams)
        .pipe(
          map((data) => {
              return data;
          })
        )
        // .pipe(
        //     catchError((err, caught) => {
        //         console.error(err);
        //         return empty();
        //       })
        // );
    }
}
