<app-classification-bar class="top-modal-classification-bar"></app-classification-bar>
<app-fdtm-modal-header [headerString]="headerString"></app-fdtm-modal-header>
<div mat-dialog-content>
<form [formGroup]="editForm">
  <mat-form-field class="input-full-width">
    <input matInput (keyup)="checkDups($event, 'name')" autocomplete="off" placeholder="FGI Name" required formControlName="name">
    <mat-error *ngIf="editForm?.controls?.name?.hasError('duplicate entry')">duplicate entry</mat-error>
  </mat-form-field>
  <br>
  <p *ngIf="isDelete" class="mat-color-warn">
    Deleting a Classification Marking may cause serious issues with existing records!
    If you choose to delete this record the system will search for possible errors and may reject the request. 
    This is no guarantee that data corruption may still be present.  
  </p>
  <p *ngIf="!isNew && !isDelete" class="mat-color-warn">
    Editing a Classification Marking may cause serious issues with existing records!
    If you choose to edit this marking the changes will be applied to all existing records in the database.  
  </p>
  <div mat-dialog-actions>
  <button *ngIf="!isDelete" [disabled]="!editForm.valid" mat-raised-button color="primary"
    (click)="handleSubmit()">Submit</button>
  <button id="delete" *ngIf="isDelete" mat-raised-button color="warn" (click)="localDelete()">Delete</button>
  <button mat-raised-button (click)="handleCancel()">Cancel</button>
  </div>
</form>
</div>
<app-classification-bar class="bottom-modal-classification-bar"></app-classification-bar>