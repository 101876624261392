<app-classification-bar class="top-modal-classification-bar"></app-classification-bar>
<app-fdtm-modal-header [headerString]="'Unit Information'"></app-fdtm-modal-header>
<div mat-dialog-content style="overflow-x: hidden;">
<table class="unit-info-header">
    <tr>
        <td rowspan="3"><img height="74px" width="74px" src="assets/images/FDT-M.png" /></td>
        <td>{{ unit.confidence_code ? unit.confidence_code + ' ' : '' }}{{ unit.final_name }}</td>
        <td rowspan="3"><img height="74px" width="74px" src="assets/images/FDT-M.png" /></td>
    </tr>
</table>
<br>
<table class="unit-info">
    <!-- <style>table {color:#ffffff;} .green {background-color: #7FFF00; color: black;} .yellow {background-color: #FFFF00; color: black;} .red {background-color: #FF0000}</style> -->
    <tr class="odd">
        <td>Classification</td> <td>{{unit.classification_string}}</td>
    </tr>
    <tr>
        <td>Last Located</td> <td>{{unit.loc_time_ts | zulu1Pipe}}</td>
    </tr>
    <tr class="odd">
        <td>Latitude</td> <td>{{unit.latitude}} ({{unit.dec_lat | number:'1.1-3'}})</td>
    </tr>
    <tr><td>Longitude</td><td>{{unit.longitude}} ({{unit.dec_lon | number:'1.1-3'}})</td>
    </tr>
    <tr class="odd"> <td>Category Description</td> <td [ngClass]="unit.col_class">{{unit.col_class == 'unitRed' ? '(SUB)' : unit.col_class == 'unitGreen' ? '(RTP or INPT)' : unit.category_description}}</td> </tr>
    <tr> <td>Activity</td> <td>{{unit.activity}} ({{unit.activity_category_code}})</td> </tr>
    <tr class="odd"><td>TimeLate (Hrs)</td>
        <td class="{{unit.latency_class}}">
            <span>{{unit.latency}}</span>&nbsp;
        </td>
    </tr>
    <tr><td>Home Port</td><td>{{unit.port_name}}<img [src]="utilities.getFlag(unit)" height="16"></td></tr>
    <tr class="odd"><td>Int Source</td><td>{{unit.source}}</td></tr>
    <tr><td>Source Code</td><td>{{unit.source_code}}</td></tr>
    <tr class="odd"><td>GCCS ID</td><td>{{unit.uid}}</td></tr>
    <tr><td>KML Tracks</td>
        <td>
            <a class="link" href="javascript: void(0)" (click)="tracks(1)">1-day</a>&nbsp;
            <a class="link" href="javascript: void(0)" (click)="tracks(7)">7-day</a>&nbsp;
            <a class="link" href="javascript: void(0)" (click)="tracks(14)">14-day</a>&nbsp;
            <a class="link" href="javascript: void(0)" (click)="tracks(30)">30-day</a>
        </td>
    </tr>
    <tr class="odd">
        <td>Major Axis Bearing</td>
        <td>{{unit.major_axis_bearing}}</td>
    </tr>
    <tr>
        <td>Semi Major Axis Length</td>
        <td>{{unit.semi_major_axis_length}}</td>
    </tr>
    <tr class="odd">
        <td>Semi Minor Axis Length</td>
        <td>{{unit.semi_minor_axis_length}}</td>
    </tr>
    <tr>
        <td>ELNOT</td>
        <td>{{unit.el_not_code}}</td>
    </tr>
    <tr class="odd">
        <td>Provider</td>
        <td>{{unit.provider}}</td>
    </tr>
    <tr>
        <td>Radio Frequency</td>
        <td>{{unit.freq}} {{unit.freq_type_description}}</td>
    </tr>
    <tr class="odd">
        <td>Bandwidth
        </td>
        <td>{{unit.band}}</td>
    </tr>
    <tr>
        <td>Function</td>
        <td>{{unit.func}}</td>
    </tr>
    <tr class="odd">
        <td>Pulse Width</td>
        <td>{{unit.pul_dur}}</td>
    </tr>
    <tr>
        <td>PRI</td>
        <td>{{unit.pri}}</td>
    </tr>
    <tr class="odd">
        <td>PRF</td>
        <td>{{unit.prf}}</td>
    </tr>
    <tr>
        <td>Scan Rate</td>
        <td>{{unit.scan_rate}}</td>
    </tr>
    <tr class="odd">
        <td>Scan Measure</td>
        <td>{{unit.scan_measure}}</td>
    </tr>
    <tr>
        <td>Scan Type</td>
        <td>{{unit.scan_type}}</td>
    </tr>
    <tr class="odd">
        <td>TOI</td>
        <td>{{unit.toi_ts}}</td>
    </tr>
</table>
</div>
<div mat-dialog-actions>
    <button mat-raised-button *ngIf="user.isSupervisor()" (click)="navigateTrackMaintenance()">View Track Maintenance</button>
    <button mat-raised-button (click)="close()">Close</button>
</div>
<app-classification-bar class="bottom-modal-classification-bar"></app-classification-bar>
