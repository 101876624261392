import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from './api.config';

@Injectable()
export class CommandApiService {
  SERVER_URL;
  RESOURCE_URI = 'commands';

  constructor(private http: HttpClient, apiConfig: APIConfig) {
      this.SERVER_URL = apiConfig.TARGET_URL;
  }

  public getAllCommands(params): Observable<any> {
    let httpParams = new HttpParams();

    for(const key in params) {
      if (params.hasOwnProperty(key)) {
        httpParams = httpParams.set(key, params[key]);
      }
    }

    return this.http.get(`${this.SERVER_URL}/${this.RESOURCE_URI}`, { params: params });
  }

  public getCommandByCommandId(commandId: string): Observable<any> {
    return this.http.get(
      `${this.SERVER_URL}/${this.RESOURCE_URI}/${commandId}`);
  }

  public updateCommand(command: any): Observable<any> {
    return this.http.put(
      `${this.SERVER_URL}/${this.RESOURCE_URI}/${command.command_id}`, command);
  }

  public createCommand(command: any): Observable<any> {
    return this.http.post(
      `${this.SERVER_URL}/${this.RESOURCE_URI}`, command);
  }

  public deleteCommand(command: any): Observable<any> {
    return this.http.delete(
      `${this.SERVER_URL}/${this.RESOURCE_URI}/${command.command_id}`);
  }

  /* Logo Services */
  public getLogo(id: string): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/${this.RESOURCE_URI}/${id}/logo`)
  }
  public getLogoURL(id: string): string{
    return `${this.SERVER_URL}/${this.RESOURCE_URI}/${id}/logo`
  }
  public createLogo(imageFile: any, id: string): Observable<any> {
    return this.http.post(
      `${this.SERVER_URL}/${this.RESOURCE_URI}/${id}/logo`,
      imageFile
    )
  }
  public updateLogo(imageFile: any, id: string): Observable<any> {
    return this.http.put(
      `${this.SERVER_URL}/${this.RESOURCE_URI}/${id}/logo`,
      imageFile
    )
  }
  public deleteLogo(id: string): Observable<any> {
    return this.http.delete(
      `${this.SERVER_URL}/${this.RESOURCE_URI}/${id}/logo`
    )
  }
}
