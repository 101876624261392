import { JsonObject, JsonProperty, Any } from 'json2typescript';
import { Command } from '@models/command.model';

@JsonObject('Activity')
export class Activity {
  @JsonProperty('activity_category_id', String, true)
  activityId: string = undefined;
  @JsonProperty('activity_category_name', String, true)
  description: string = undefined;
  @JsonProperty('activity_category_code', String, true)
  code: string = undefined;
  @JsonProperty('slide_description', String, true)
  slideDescription: string = undefined;
  @JsonProperty('command_id', String, true)
  commandId: string = undefined;
  @JsonProperty('command', Command, true)
  command: Command = undefined;
  @JsonProperty('created_by', String, true)
  createdBy: string = undefined;
  @JsonProperty('lookup_filter_id', String, true)
  lookupFilterId: string = undefined;
  @JsonProperty('sort_sequence', String, true)
  sortSequence: number = undefined;

  static lookupName(): string {
    return 'activities';
  }

  static primaryKey(): string {
    return 'activityId';
  }
  groupProperty(): string {
    return this.description;
  }
}
