import { Injectable } from '@angular/core';
import { GeoRef } from '@models/geo-ref.model';
import { GeoRefApiService } from '@services/api-services/geo-ref-api.service';
import { JsonConverter } from '@services/global/json-converter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable(
//   {
//   providedIn: 'root'
// }
)
export class GeoRefService {
  private jsonConvert: JsonConverter;

  constructor(private geoRefApiService: GeoRefApiService) {
    this.jsonConvert = new JsonConverter();
  }

  public getAll(): Observable<GeoRef[]> {
    return this.geoRefApiService.getAllGeoRefs()
      .pipe(
        map((data) => {
          return this.jsonConvert.deserializeArray(data, GeoRef);
        })
      );
  }

  public getGeoRefByGeoRefId(geoRefId: string): Observable<GeoRef> {
    return this.geoRefApiService.getGeoRefByGeoRefId(geoRefId)
      .pipe(
        map((data) => {
          return this.jsonConvert.deserializeObject(data, GeoRef);
        })
      );
  }

  public create(geoRef: GeoRef): Observable<GeoRef> {
    return this.geoRefApiService.createGeoRef(this.jsonConvert.serializeObject(geoRef))
      .pipe(
        map((data) => {
          return this.jsonConvert.deserializeObject(data, GeoRef);
        })
      );
  }

  public update(geoRef: GeoRef): Observable<GeoRef> {
    return this.geoRefApiService.updateGeoRef(this.jsonConvert.serializeObject(geoRef))
      .pipe(
        map((data) => {
          return this.jsonConvert.deserializeObject(data, GeoRef);
        })
      );
  }

  public delete(geoRef: GeoRef): Observable<any> {
    return this.geoRefApiService.deleteGeoRef(this.jsonConvert.serializeObject(geoRef))
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  public getNearestGeoRef(latitude: string, longitude: string): Observable<any> {
    return this.geoRefApiService.getNearestGeoRef(latitude, longitude)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
}
