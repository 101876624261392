<app-classification-bar class="top-modal-classification-bar"></app-classification-bar>
<app-fdtm-modal-header [headerString]="headerString"></app-fdtm-modal-header>
<div mat-dialog-content>
    <form [formGroup]="editForm" #dtForm>

        <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px"
            fxLayoutGap.xs="0">
            <div fxFlex="30%" fxFlex.md="40%" fxFlex.lg="30%">
                <mat-form-field class="groupSelect">
                    <mat-select [(ngModel)]="selectedCommandId" [ngModelOptions]="{standalone: true}" required
                        placeholder="Command" (selectionChange)="entity.commandId = $event.value">
                        <mat-option *ngFor="let command of commandOptions" [value]="command.commandId">
                            {{command.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="30%" fxFlex.md="40%" fxFlex.lg="30%">
                <mat-form-field class="groupSelect">
                    <mat-select [(ngModel)]="selectedSourceId" [ngModelOptions]="{standalone: true}" required
                        placeholder="Source" (selectionChange)="entity.sourceId = $event.value">
                        <mat-option *ngFor="let source of sourceOptions" [value]="source.sourceId">
                            {{source.sourceName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div fxLayoutAlign="center">
            <h3>From Classification:</h3>
        </div>
        <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px"
            fxLayoutGap.xs="0">
            <div fxFlex="20%">
                <h5>CLASSIFICATION:</h5>
            </div>
            <div fxFlex="20%">
                <h5>CONTROL: </h5>
            </div>

            <div fxFlex="20%">
                <h5>FGI:</h5>
            </div>

            <div fxFlex="20%">
                <h5>OTHER CONTROLS:</h5>
            </div>

            <div fxFlex="20%">
                <h5>DISSEMINATION:</h5>
            </div>
        </div>
        <div class="container shrink" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px"
            fxLayoutGap.xs="0">
            <div fxFlex="20%">
                <mat-radio-group class="noscroll-bordered" fxLayout="column" formControlName="srcClassificationId"
                    placeholder="Classification">
                    <mat-radio-button validation="required" class="classification-option smallRadio"
                        *ngFor="let option of classificationOptions" [value]="option.classificationId">
                        {{option.classificationName}}
                    </mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="dtForm.submitted && editForm.controls.classificationId.invalid">Classification is
                    required.</mat-error>
            </div>
            <div fxFlex="20%">
                <mat-selection-list class="bordered" fxLayout="column" formControlName="srcSciControl">
                    <mat-list-option class="shrink" checkboxPosition="before" *ngFor="let option of sciControlsOptions"
                        [value]="option.sciControlId">
                        {{option.controlMark}}
                    </mat-list-option>
                </mat-selection-list>
            </div>

            <div fxFlex="20%">
                <mat-selection-list class="bordered" fxLayout="column" formControlName="srcFgiControl">
                    <mat-list-option class="shrink" checkboxPosition="before" [disabled]="option.isDisabled"
                        *ngFor="let option of fgiControlOptions" [value]="option.fgiId">
                        {{option.name}}
                    </mat-list-option>
                </mat-selection-list>
            </div>

            <div fxFlex="20%">
                <mat-selection-list class="bordered" fxLayout="column" formControlName="srcOtherControl">
                    <mat-list-option class="shrink" checkboxPosition="before"
                        *ngFor="let option of otherControlsOptions" [value]="option.controlId">
                        {{option.description}}
                    </mat-list-option>
                </mat-selection-list>
            </div>

            <div fxFlex="20%">
                <mat-selection-list class="bordered" fxLayout="column" formControlName="srcDissemination">
                    <mat-list-option class="shrink" checkboxPosition="before"
                        *ngFor="let option of releasabilityOptions" [value]="option.releasibilityId">
                        {{option.releasabilityName}}
                    </mat-list-option>
                </mat-selection-list>
            </div>
        </div>
        <div fxLayoutAlign="center">
            <h3>To Classification:</h3>
        </div>
        <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px"
            fxLayoutGap.xs="0">
            <div fxFlex="20%">
                <h5>CLASSIFICATION:</h5>
            </div>
            <div fxFlex="20%">
                <h5>CONTROL: </h5>
            </div>

            <div fxFlex="20%">
                <h5>FGI:</h5>
            </div>

            <div fxFlex="20%">
                <h5>OTHER CONTROLS:</h5>
            </div>

            <div fxFlex="20%">
                <h5>DISSEMINATION:</h5>
            </div>
        </div>
        <div class="container shrink" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px"
            fxLayoutGap.xs="0">
            <div fxFlex="20%">
                <mat-radio-group class="noscroll-bordered" fxLayout="column" formControlName="tgtClassificationId"
                    placeholder="Classification">
                    <mat-radio-button validation="required" class="classification-option smallRadio"
                        *ngFor="let option of classificationOptions" [value]="option.classificationId">
                        {{option.classificationName}}
                    </mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="dtForm.submitted && editForm.controls.classificationId.invalid">Classification is
                    required.</mat-error>
            </div>
            <div fxFlex="20%">
                <mat-selection-list class="bordered" fxLayout="column" formControlName="tgtSciControl">
                    <mat-list-option class="shrink" checkboxPosition="before" *ngFor="let option of sciControlsOptions"
                        [value]="option.sciControlId">
                        {{option.controlMark}}
                    </mat-list-option>
                </mat-selection-list>
            </div>

            <div fxFlex="20%">
                <mat-selection-list class="bordered" fxLayout="column" formControlName="tgtFgiControl">
                    <mat-list-option class="shrink" checkboxPosition="before" [disabled]="option.isDisabled"
                        *ngFor="let option of fgiControlOptions" [value]="option.fgiId">
                        {{option.name}}
                    </mat-list-option>
                </mat-selection-list>
            </div>

            <div fxFlex="20%">
                <mat-selection-list class="bordered" fxLayout="column" formControlName="tgtOtherControl">
                    <mat-list-option class="shrink" checkboxPosition="before"
                        *ngFor="let option of otherControlsOptions" [value]="option.controlId">
                        {{option.description}}
                    </mat-list-option>
                </mat-selection-list>
            </div>

            <div fxFlex="20%">
                <mat-selection-list class="bordered" fxLayout="column" formControlName="tgtDissemination">
                    <mat-list-option class="shrink" checkboxPosition="before"
                        *ngFor="let option of releasabilityOptions" [value]="option.releasibilityId">
                        {{option.releasabilityName}}
                    </mat-list-option>
                </mat-selection-list>
            </div>
        </div>
        <br>
        <div *ngIf="!user.isSystemAdmin()">
            <span class="red">You are not a system admin. You will not be able to create or update FDO mappings.</span>
        </div>
        <div mat-dialog-actions>
            <button [disabled]="!editForm.valid || !entity.sourceId || !entity.commandId || !user.isSystemAdmin()" mat-raised-button
                color="primary" (click)="submit()">
                Submit
            </button>
            <button mat-raised-button type="reset" (click)="cancel()">
                Cancel
            </button>
        </div>
    </form>
</div>
<app-classification-bar class="bottom-modal-classification-bar"></app-classification-bar>