import { FDOMap } from '@models/fdo-map.model';
import { FDOMapApiService } from '@services/api-services/fdo-maps-api.service';
import { Injectable } from '@angular/core';
import { JsonConverter } from '@services/global/json-converter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class FDOMapService {
    private jsonConvert: JsonConverter;

    constructor(private FDOMapApiService: FDOMapApiService) {
        this.jsonConvert = new JsonConverter();
    }

    public getAll(): Observable<FDOMap[]> {
        return this.FDOMapApiService.getAllFDOMaps()
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, FDOMap);
                })
            );
    }

    public getFdoMapByFdoMapId(fdoMapId: string): Observable<FDOMap> {
        return this.FDOMapApiService.getFDOMapByFDOMapId(fdoMapId)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, FDOMap);
                })
            );
    }

    public update(fdoMap: FDOMap): Observable<FDOMap> {
        return this.FDOMapApiService.updateFDOMap(this.jsonConvert.serializeObject(fdoMap))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, FDOMap);
                })
            );
    }

    public create(fdoMap: FDOMap): Observable<FDOMap> {
        return this.FDOMapApiService.createFDOMap(this.jsonConvert.serializeObject(fdoMap))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, FDOMap);
                })
            );
    }

    public delete(fdoMap: FDOMap): Observable<FDOMap> {
        return this.FDOMapApiService.deleteFDOMap(this.jsonConvert.serializeObject(fdoMap))
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    public getAllByCommandId(commandId: string): Observable<FDOMap[]> {
        return this.FDOMapApiService.getFDOMapByCommandId(commandId)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, FDOMap);
                })
            );
    }
}
