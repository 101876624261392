import { JsonObject, JsonProperty, Any } from 'json2typescript';
import { Command } from '@models/command.model';

@JsonObject('ConfidenceCode')
export class ConfidenceCode {
  @JsonProperty('confidence_code_id', String, true)
  confidenceCodeId: string = undefined;
  @JsonProperty('confidence_code', String, true)
  confidenceCode: string = undefined;
  @JsonProperty('confidence_code_description', String, true)
  confidenceCodeDescription: string = undefined;
  @JsonProperty('command_id', String, true)
  commandId: string = undefined;
  @JsonProperty('command', Command, true)
  command: Command = undefined;
  @JsonProperty('created_by', String, true)
  createdBy: string = undefined;
  @JsonProperty('lookup_filter_id', String, true)
  lookupFilterId: string = undefined;
  @JsonProperty('sort_sequence', String, true)
  sortSequence: number = undefined;

  static lookupName(): string {
    return 'confidencecodes';
  }

  static primaryKey(): string {
    return 'confidenceCodeId'
  }
  groupProperty(): string {
    return this.confidenceCode
  }
}
