import { Notifier } from '@models/notifier.model';
import { NotifierApiService } from '@services/api-services/notifier-api.service';
import { Injectable } from '@angular/core';
import { JsonConverter } from '@services/global/json-converter';
import { Observable, empty } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class NotifierEmailService {
    private jsonConvert: JsonConverter;

    constructor(private notifierApiService: NotifierApiService) {
        this.jsonConvert = new JsonConverter();
    }

    public sendNotification(notifier: Notifier): Observable<Notifier> {
        return this.notifierApiService.sendNotification(this.jsonConvert.serializeObject(notifier))
        .pipe(
          map((data) => {
              return data;
          })
        );

    }
}
