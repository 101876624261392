import { Component, Input, OnInit } from '@angular/core';
import { AppInitService } from '../../../../config/init.service';

@Component({
  selector: 'app-fdtm-modal-header',
  templateUrl: './fdtm-modal-header.component.html',
  styleUrls: ['./fdtm-modal-header.component.css']
})
export class FdtmModalHeaderComponent implements OnInit {

  @Input() headerString: string = ''

  constructor(public init: AppInitService) { }

  ngOnInit(): void {
  }

}
