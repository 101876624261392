import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter } from '../services/global/date-converter';
import { Command } from '@models/command.model';
import { Country } from './country.model';

@JsonObject('ShipClass')
export class ShipClass {
  @JsonProperty('ship_class_id', String, true)
  shipClassId: string = undefined;
  @JsonProperty('ship_class', String, true)
  shipClass: string = undefined;
  @JsonProperty('ship_class_description', String, true)
  shipClassDescription: string = undefined;
  @JsonProperty('command_id', String, true)
  commandId: string = undefined;
  @JsonProperty('command', Command, true)
  command: Command = undefined;
  @JsonProperty('created_by', String, true)
  createdBy: string = undefined;
 
  static lookupName(): string {
    return 'shipclasses';
  }
}
