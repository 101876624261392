<app-classification-bar class="top-modal-classification-bar"></app-classification-bar>

<app-fdtm-modal-header [headerString]="headerString"></app-fdtm-modal-header>

<div mat-dialog-content>
    <form [formGroup]="editForm">
        <div *ngIf="!isDelete" class="container" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxLayoutGap.lt-md="0">
            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-form-field class="fdtm-input-field">
                    <mat-select formControlName="classificationId" placeholder="Classification" (selectionChange)="toggleClassificationFields()">
                        <mat-option [value]="null">Clear</mat-option>
                        <mat-option *ngFor="let classification of classifications" [value]="classification.classificationId">
                            {{classification.classificationName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-form-field class="fdtm-input-field">
                    <mat-select formControlName="sciControls" placeholder="SCI Controls" multiple
                        matTooltip="To Edit SCI Controls, a Classification must also be selected"
                        [matTooltipDisabled]="editForm.controls.sciControls.enabled">
                        <mat-option *ngFor="let sci of sciControls" [value]="sci.sciControlId">
                            {{sci.controlMark}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div *ngIf="!isDelete" class="container" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxLayoutGap.lt-md="0">
            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-form-field class="fdtm-input-field">
                    <mat-select formControlName="fgiControls" placeholder="FGI Controls" multiple
                        matTooltip="To Edit FGI Controls, a Classification must also be selected"
                        [matTooltipDisabled]="editForm.controls.fgiControls.enabled">
                        <mat-option *ngFor="let fgi of fgiControls" [value]="fgi.fgiId">
                            {{fgi.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-form-field class="fdtm-input-field">
                    <mat-select formControlName="otherControls" placeholder="Other Controls" multiple
                        matTooltip="To Edit Other Controls, a Classification must also be selected"
                        [matTooltipDisabled]="editForm.controls.otherControls.enabled">
                        <mat-option *ngFor="let control of otherControls" [value]="control.controlId">
                            {{control.description}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div *ngIf="!isDelete" class="container" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxLayoutGap.lt-md="0">
            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-form-field class="fdtm-input-field">
                    <mat-select formControlName="disseminations" placeholder="Disseminations" tabindex="3" multiple
                        matTooltip="To Edit Disseminations, a Classification must also be selected"
                        [matTooltipDisabled]="editForm.controls.disseminations.enabled">
                        <mat-option *ngFor="let dissemination of disseminations" [value]="dissemination.releasibilityId">
                            {{dissemination.releasabilityName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div fxFlex="50%" fxFlex.lt-md="100%">
                <div class ="mat-color-warn warning" *ngIf="editForm.controls.classificationId.value">
                    Warning: This will update the classification for all selected Locations to:<br>{{getClassification()}}
                </div>
            </div>
        </div>

        <div *ngIf="!isDelete" class="container" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxLayoutGap.lt-md="0">
            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-form-field class="fdtm-input-field">
                    <mat-select formControlName="fleetId" placeholder="Fleet" tabindex="1" autofocus>
                        <mat-option *ngFor="let fleet of fleets" [disabled]="fleet.isDisabled" [value]="fleet.fleetId">
                            {{fleet.fleetCode}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-form-field class="fdtm-input-field">
                    <input matInput formControlName="activity" placeholder="Activity" tabindex="10">
                </mat-form-field>
            </div>
        </div>

        <div *ngIf="!isDelete" class="container" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxLayoutGap.lt-md="0">
            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-form-field class="fdtm-input-field" [floatLabel]="editForm?.controls?.dtgImaged?.value?.length">
                    <input matInput [owlDateTime]="dtgImaged" formControlName="dtgImaged" autocomplete="off"
                        placeholder="Last Imaged DTG (d M Y hm)" [max]="this.allowFutureDates ? undefined : maxDate" tabindex="2">
                    <owl-date-time #dtgImaged></owl-date-time>
                    <span class="hyper" matSuffix [owlDateTimeTrigger]="dtgImaged">
                        <mat-icon class="no-line">calendar_today</mat-icon>
                    </span>
                </mat-form-field>
            </div>

            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-form-field class="fdtm-input-field">
                    <mat-select formControlName="activityCategoryId" placeholder="Activity Category" tabindex="11">
                        <mat-option *ngFor="let activityCategory of activityCategories"
                            [disabled]="activityCategory.isDisabled" [value]="activityCategory.activityId">
                            {{activityCategory.description}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div *ngIf="!isDelete" class="container" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxLayoutGap.lt-md="0">
            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-form-field class="fdtm-input-field" [floatLabel]="editForm?.controls?.mfiTs?.value?.length">
                    <input matInput [owlDateTime]="mfiTs" formControlName="mfiTs" autocomplete="off"
                        placeholder="MFI DTG (d M Y hm)" [max]="this.allowFutureDates ? undefined : maxDate" tabindex="3">
                    <owl-date-time #mfiTs></owl-date-time>
                    <span class="hyper" matSuffix [owlDateTimeTrigger]="mfiTs">
                        <mat-icon class="no-line">calendar_today</mat-icon>
                    </span>
                </mat-form-field>
            </div>

            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-form-field class="fdtm-input-field">
                    <mat-select formControlName="opAreaId" placeholder="U/W Area" tabindex="12">
                        <mat-option *ngFor="let opArea of opAreas" [value]="opArea.opAreaId">{{opArea.areaRemarks()}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div *ngIf="!isDelete" class="container" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxLayoutGap.lt-md="0">
            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-form-field class="fdtm-input-field">
                    <input matInput formControlName="lastLoc" placeholder="Last Location" tabindex="6">
                </mat-form-field>
            </div>

            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-form-field class="fdtm-input-field">
                    <input matInput formControlName="papaCase" placeholder="Case No." tabindex="14">
                </mat-form-field>
            </div>
        </div>

        <div *ngIf="!isDelete" class="container" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxLayoutGap.lt-md="0">
            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-form-field class="fdtm-input-field">
                    <mat-select formControlName="lastLocSrc" placeholder="Source" tabindex="7">
                        <mat-option *ngFor="let source of sources" [disabled]="source.isDisabled"
                            [value]="source.sourceId">
                            {{source.sourceName}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div fxFlex="50%" fxFlex.lt-md="100%">
                <mat-form-field class="fdtm-input-field">
                    <input matInput formControlName="srcOrig" placeholder="Originator" tabindex="8">
                </mat-form-field>
            </div>
        </div>

        <div class="container" fxLayout="row">
            <div fxFlex="100%">
                <mat-form-field class="fdtm-input-field">
                    <mat-label>Reason</mat-label>
                    <textarea formControlName="rationale" matInput required></textarea>
                </mat-form-field>
            </div>
        </div>

        <div *ngIf="isDelete" class="container" fxLayout="row">
            <div fxFlex="100%" class="mat-color-warn warning">
                Note: This action will only delete the selected location information, the Unit(s) themselves will not be deleted.
            </div>
        </div>

        <div mat-dialog-actions>
            <button mat-raised-button color="primary" *ngIf="!isDelete" [disabled]="!editForm.valid || !hasChanges" (click)="submit()">Submit</button>
            <button mat-raised-button color="warn" *ngIf="isDelete" [disabled]="!editForm.valid" (click)="submit()">Delete</button>
            <button mat-raised-button (click)="cancel()">Cancel</button>
        </div>
    </form>
</div>

<app-classification-bar class="bottom-modal-classification-bar"></app-classification-bar>
