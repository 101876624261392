import { Component, OnInit } from '@angular/core';
import { GlobalMessageTriggers } from '@services/global/message-bus/global-message-triggers.enum';
import { CommandService } from '@services/data-services/command.service';
import { Command } from '@models/command.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { DynamicComponentInterface } from '@core-module/app-components/dynanmic-component/dynamic-component.interface';
import { EditModalBaseComponent } from '@fom-module/base-classes/edit-modal-base.component';
import { LookUpService } from '@services/data-services/lookup.service';
import { MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators'


@Component({
  selector: 'app-edit-command-modal',
  templateUrl: './edit-command-modal.component.html',
  styleUrls: ['./edit-command-modal.component.css']
})
export class EditCommandModalComponent extends EditModalBaseComponent implements  OnInit {

  editForm: FormGroup;
  logos: any;
  tmpImg: any;
  currentImage: any;
  message: string;

  constructor(public commandService: CommandService,
    public lookUpService: LookUpService,
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<EditCommandModalComponent>,
    public messageBusService: MessageBusService) {
      super(commandService, dialogRef);
    }

  ngOnInit() {
    this.headerString = this.isNew ? 'Add Command' :
    this.isDelete ? 'Delete Command' :
    'Edit Command'
    this.editForm = this.formBuilder.group({
      name: [this.entity.name]
    });
    this.logoPreview()
  }

  noLogo(){
    return this.entity.logoPath === "" || this.entity.logoPath === null ? "post":"put"
  }

  async uploadForm  (event) {
    this.handleCommandSubmit(event)
  }


  async handleCommandSubmit(event) {
    if (this.editForm.valid) {
      this.updateEntity();

      if (this.isNew) {
        (this.entityService as any).create(this.entity).pipe(take(1)).subscribe(res => {
          this.entity.commandId = res.commandId;
          let logo = this.uploadFile(event)
          this.dialogRef.close(res)
          this.notificationService.showSuccess('New item successfuly created', 3000)
          //this.messageBusService.publishMessage(new GlobalBusMessage(this.RELOAD_MESSAGE));
          //this.messageBusService.publishMessage(new GlobalBusMessage(GlobalMessageTriggers.CLOSE_MODAL));
        });
      } else {
        (this.entityService as any).update(this.entity).pipe(take(1)).subscribe(res => {
          let logo = this.uploadFile(event)
          this.dialogRef.close(res)
          this.notificationService.showSuccess('Item successfully edited', 3000)
          //this.messageBusService.publishMessage(new GlobalBusMessage(GlobalMessageTriggers.CLOSE_MODAL));
        });
      }
    }
  }

  uploadFile(event){
    const formData = new FormData();
    
    formData.append('file', this.logos);

    let file = formData.get("file")

    if(file === "undefined"){
      // no logo selected
      return false
    } else if(this.entity.logoPath === null || this.entity.logoPath === ""){
      // Logo selected, Command no logo
      
      return this.commandService.createLogo(formData, this.entity.commandId).pipe(take(1)).subscribe( res => {
        return res
      })
    } else {
      // Update current Logo
      return this.commandService.updateLogo(formData, this.entity.commandId).pipe(take(1)).subscribe( res => {
        return res
      })
    }
  }

  imageChange(e){
    this.message = ""
    this.logos = e.target.files[0]
    let reader = new FileReader()
    reader.readAsDataURL(this.logos)
    reader.onload = (_event) => {
      this.tmpImg = reader.result
      this.currentImage = false
    }
  }

  getALogo(){
    this.commandService.getLogo(this.entity.commandId).subscribe( () => {})
  }

  logoPreview(){
    if(this.entity.logoPath === null || this.entity.logoPath === ""){
      this.tmpImg = false
      this.currentImage = false
    }else{
      this.currentImage = this.commandService.getLogoUrl(this.entity)
      this.tmpImg = false
    }
  }

  removeLogo(event){
    if(confirm(`Are you sure you want to delete the ${this.entity.name} logo?`)){
      this.commandService.deleteLogo(this.entity.commandId).subscribe( res => {
        if(!res["error"]){
          this.entity.logoPath = res.logo_path
          this.tmpImg = false
          this.currentImage = false
          this.message = "Logo Deleted!"
        }
      })
    }else{
      event.preventDefault()
    }
  }
  // Not working come back later
  // dropHandler(event) {
  //   // Prevent default behavior (Prevent file from being opened)
  //   event.preventDefault();

  //   if (ev.dataTransfer.items) {
  //     this.imageChange(event)
  //   }
  // }
  // dragOverHandler(event) {
  //   // Prevent default behavior (Prevent file from being opened)
  //   event.preventDefault();
  // }
}
