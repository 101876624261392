import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter } from '../services/global/date-converter';
import { Command } from '@models/command.model';

@JsonObject('Elnot')
export class Elnot {
  @JsonProperty('el_not_id', String, true)
  el_not_id: string = undefined;
  @JsonProperty('el_not_code', String, true)
  el_not_code: string = undefined;
  @JsonProperty('create_ts', DateConverter, true)
  dateCreated: Date = undefined;
  @JsonProperty('created_by', String, true)
  createdBy: string = undefined;
  @JsonProperty('update_ts', String, true)
  dateUpdated: Date = undefined;
  @JsonProperty('updated_by', Command, true)
  updatedBy: string = undefined;
  @JsonProperty('delete_ts', DateConverter, true)
  dateDeleted: Date = undefined;
  @JsonProperty('deleted_by', String, true)
  deletedBy: string = undefined;
  @JsonProperty('legacy_id', String, true)
  legacyId: string = undefined;
  @JsonProperty('command_id', String, true)
  commandId: string = undefined;
  @JsonProperty('processing_tags', String, true)
  processingTags: string = undefined;
  @JsonProperty('classification_description', String, true)
  classificationDescription: string = undefined;

  static lookupName(): string {
    return 'elnots';
  }
}
