import { JsonObject, JsonProperty, Any } from 'json2typescript';

@JsonObject('OwnerType')
export class OwnerType {
  @JsonProperty('owner_type_id', String, true)
  ownerTypeId: string = undefined;
  @JsonProperty('owner_tablename', String, true)
  tableName: string = undefined;
  @JsonProperty('owner_description', String, true)
  description: string = undefined;
  @JsonProperty('owner_key_column', String, true)
  keyColumn: string = undefined;
  @JsonProperty('owner_name_column', String, true)
  nameColumn: string = undefined;
  @JsonProperty('created_by', String, true)
  createdBy: string = undefined;

  static lookupName(): string {
    return 'ownerType';
  }
}
