import { Any, JsonObject, JsonProperty } from 'json2typescript';
import { User } from '@models/user.model'
import { Port } from '@models/port.model'

@JsonObject('Command')
export class Command {
  @JsonProperty('command_id', String, true)
  commandId: string = undefined;
  @JsonProperty('command_name', String, true)
  name: string = undefined;
  @JsonProperty('logo_path', String, true)
  logoPath: string = null;
  @JsonProperty('created_by', String, true)
  createdBy: string = undefined;
  // Using 'any' to avoid circular imports
  @JsonProperty('users', [Any], true)
  users: User[] = [];
  @JsonProperty('ports', [Any], true)
  ports: Port[] = [];
  @JsonProperty('users_count', Number, true)
  usersCount: Number = undefined;

  static lookupName(): string {
    return 'commands';
  }
}
