import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { GlobalMessageTriggers } from '@services/global/message-bus/global-message-triggers.enum';
import { ConnectionsService } from '@services/data-services/connections.service';
import { Command } from '@models/command.model';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { EditModalBaseComponent } from '@fom-module/base-classes/edit-modal-base.component';
import { LookUpService } from '@services/data-services/lookup.service';
import { MatDialogRef } from '@angular/material/dialog';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { BreakpointObserver } from '@angular/cdk/layout';
import { StepperOrientation } from '@angular/material/stepper';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { MessageConfig } from '@models/message-config.model';
import { MessageConfigService } from '@services/data-services/message-config.service';
import { AppInitService } from '../../../../config/init.service';
import { SystemConnection } from '@models/system-connection.model';
import { UtilityService } from '@services/utility.service';

@Component({
  selector: 'app-edit-system-connection-modal',
  templateUrl: './edit-system-connection-modal.component.html',
  styleUrls: ['./edit-system-connection-modal.component.css'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
  ],
})

export class EditSystemConnectionModalComponent extends EditModalBaseComponent implements OnInit {
  entity: SystemConnection;

  editForm: FormGroup;
  message: string;
  owner: any;
  directionOptions: string[];
  protocolOptions: string[];
  environmentOptions: string[];
  ownerId: any;
  ownerOptions = [];
  stepperOrientation: Observable<StepperOrientation>;
  /** Returns a FormArray with the name 'formArray'. */
  get formArray(): AbstractControl | null { return this.editForm.get('formArray'); }
  get messageConfigsArray(): FormArray | null { return this.editForm.get('formArray').get([2]).get('messageConfigs') as FormArray; }

  messageTypeOptions: string[];
  invalidMessageTypes: string[];

  parametersTemplates: any;

  constructor(public connectionsService: ConnectionsService,
    public lookUpService: LookUpService,
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<EditSystemConnectionModalComponent>,
    public messageBusService: MessageBusService,
    private breakpointObserver: BreakpointObserver,
    private messageConfigService: MessageConfigService,
    private changeDetectorRef: ChangeDetectorRef,
    private utilities: UtilityService,
    private initService: AppInitService) {
    super(connectionsService, dialogRef);
    this.stepperOrientation = breakpointObserver
      .observe('(min-width: 800px)')
      .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));
  }

  ngOnInit() {
    this.headerString = this.isNew ? 'Add System Connection' :
      this.isDelete ? 'Delete System Connection' :
        'Edit System Connection'

    // In future move to database over the config file.
    this.messageTypeOptions = this.initService.getConfig().connections.messageTypes?.sort() || ['OTH-G'];
    this.directionOptions = this.initService.getConfig().connections.directions?.sort() || ['Inbound', 'Outbound'];
    this.protocolOptions = this.initService.getConfig().connections.protocols?.sort() || ['TCP', 'UDP', 'TCP/UDP'];
    this.environmentOptions = this.initService.getConfig().connections.environments?.sort() || ['Test', 'Production'];

    this.editForm = this.formBuilder.group({
      formArray: this.formBuilder.array([
        this.formBuilder.group({
          ownerId: [{ value: this.entity.ownerId, disabled: this.isDelete }],
          systemName: [{ value: this.entity.systemName, disabled: this.isDelete }],
          port: [{ value: this.entity.port, disabled: this.isDelete }, Validators.max(65353)],
          host: [{ value: this.entity.host, disabled: this.isDelete }],
          direction: [{ value: this.entity.direction, disabled: this.isDelete }],
          environment: [{ value: this.entity.environment, disabled: this.isDelete }],
          protocol: [{ value: this.entity.protocol, disabled: this.isDelete }],
          enabled: [{ value: this.entity.enabled, disabled: this.isDelete }]
        }),
        this.formBuilder.group({
          connectionParams: [{ value: this.entity.connectionParams, disabled: this.isDelete }, this.utilities.jsonValidator]
        }),
        this.formBuilder.group({
          messageConfigs: this.formBuilder.array([])
        }),
      ])
    });

    if (this.isNew) {
      this.addMessageConfig();
    } else {
      for (const config of this.entity.messageConfigs) {
        this.addMessageConfig(config);
      }
    }
  }

  toHumanReadable(key) {
    const words = key.match(/[A-Za-z][a-z]*/g) || [];

    return words.map(this.capitalize).join(" ");
  }

  capitalize(word) {
    return word.charAt(0).toUpperCase() + word.substring(1);
  }

  handleMessageTypeUpdate(messageType, formGroup) {
    this.validateMessageType(messageType, formGroup)
    this.setParameterTemplate(messageType, formGroup)
  }

  setParameterTemplate(messageType, formGroup) {
    const template = this.parametersTemplates.filter((template) => template.parametersType === messageType)
    if (template.length >= 1 && formGroup.controls.messageType.valid) {
      const params = {};

      const templateOptions = template[0]?.template.options;
      templateOptions.forEach(e => params[e.option] = "");

      formGroup.controls.messageParams.setValue(JSON.stringify(params, null, 2));
    } else {
      formGroup.controls.messageParams.setValue('{\n}')
    }

    this.changeDetectorRef.detectChanges();
  }

  validateMessageType(messageType, formGroup) {
    const messageConfigs = <FormArray>this.editForm.get('formArray').get([2]).get('messageConfigs');
    let usedMessageTypes = []
    messageConfigs.controls.forEach((controlGroup) => {
      if (controlGroup !== formGroup) {
        usedMessageTypes.push(controlGroup.value.messageType)
      }
    }
    )
    if (usedMessageTypes.includes(messageType)) {
      formGroup.controls['messageType'].setErrors({ 'duplicate': true })
    }
  }

  async uploadForm(event) {
    this.handleSubmit()
  }

  addMessageConfig(messageConfig: MessageConfig = new MessageConfig()): void {
    const control = <FormArray>this.editForm.get('formArray').get([2]).get('messageConfigs');

    control.push(
      this.formBuilder.group({
        messageConfigId: [{ value: messageConfig.messageConfigId, disabled: this.isDelete }], // included to
        messageType: [{ value: messageConfig.messageType, disabled: this.isDelete }],
        enabled: [{ value: messageConfig.enabled, disabled: this.isDelete }],
        messageParams: [{ value: messageConfig.messageParams, disabled: this.isDelete }, this.utilities.jsonValidator]
      })
    );

    // Manually detect changes, this seems to avoid throwing ExpressionChangeAfterItHasBeenCheckedError
    // when adding additional MessageConfigs when editing a SystemConnection.
    this.changeDetectorRef.detectChanges();
  }

  removeMessageConfig(index): void {
    this.messageConfigsArray.removeAt(index);
  }

  updateFormArrayEntity() {
    this.editForm.controls.formArray.value.forEach((formStep, index) => {
      for (const key in formStep) {
        // Handle message configs separately since they need some further logic.
        if (key.toString() === 'messageConfigs') {
          for (const formValues of formStep[key]) {
            let messageConfig;

            // If editing, find the config from the array
            if (formValues.messageConfigId) {
              messageConfig = this.entity.messageConfigs.find(e => e.messageConfigId === formValues.messageConfigId);
            }

            // if config isn't found, or doesn't have an ID, then create new and add to the array.
            if (!messageConfig) {
              messageConfig = new MessageConfig();
              this.entity.messageConfigs.push(messageConfig);
            }

            for (const key in formValues) {
              if (formValues[key] != null && messageConfig.hasOwnProperty(key.toString())) {
                messageConfig[key.toString()] = formValues[key];
              }
            }
          }
        } else {
          if (this.entity.hasOwnProperty(key.toString())) {
            this.entity[key.toString()] = formStep[key];
          }
        }
      }
    });

    // Handle owner type a little special for now.
    this.entity.ownerType = this.ownerOptions.find(e => e.ownerId === this.entity.ownerId).ownerType;
  }

  handleSubmit() {
    if (this.editForm.valid) {
      this.updateFormArrayEntity();

      if (this.isNew) {
        (this.entityService as any).create(this.entity).pipe(take(1)).subscribe(res => {
          this.dialogRef.close(res)
          this.notificationService.showSuccess('New item successfuly created', 3000)
        });
      } else {
        (this.entityService as any).update(this.entity).pipe(take(1)).subscribe(res => {
          this.dialogRef.close(res)
          this.notificationService.showSuccess('Item successfully edited', 3000)
        });
      }
    }
  }

}
