import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from './api.config';

@Injectable()
export class OwnerApiService {
  SERVER_URL;

  constructor(
    private http: HttpClient, apiConfig: APIConfig) {
    this.SERVER_URL = apiConfig.TARGET_URL;
  }

  public getAllOwners(): Observable<any> {
    return this.http.get(
      `${this.SERVER_URL}/owners`);
  }
  public getOwnerByTypeId(ownerTypeId: string): Observable<any> {
    if (!ownerTypeId || !ownerTypeId.length) {
      ownerTypeId = ''
    }
    return this.http.get(
      `${this.SERVER_URL}/owners?ownerTypeId=${ownerTypeId}`);
  }
}

