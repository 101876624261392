<app-classification-bar class="top-modal-classification-bar"></app-classification-bar>
<app-fdtm-modal-header [headerString]="headerString"></app-fdtm-modal-header>
<div mat-dialog-content>

<form [formGroup]="editForm">
    <mat-form-field class="input-full-width">
        <mat-select placeholder="Command" required formControlName="commandId">
            <mat-option *ngFor="let option of commandOptions" [value]="option.commandId">{{option.name}}</mat-option>
        </mat-select>
      </mat-form-field>
  <br>
  <mat-form-field class="input-full-width">
    <input matInput placeholder="Reference Name" required formControlName="name">
  </mat-form-field>
  <br>
  <mat-form-field class="input-full-width">
    <input matInput placeholder="Fleet Area" required formControlName="area">
  </mat-form-field>
  <br>
  <mat-form-field class="input-full-width">
    <input matInput
      placeholder="Latitude (DMS or decimal)"
      required
      formControlName="latitude"
      oninput="this.value = this.value.trim().toUpperCase()">
      <mat-error *ngIf="editForm?.controls?.latitude?.hasError('format')">bad format</mat-error>
      <mat-error *ngIf="editForm?.controls?.latitude?.hasError('length')">improper length</mat-error>
      <mat-error *ngIf="editForm?.controls?.latitude?.hasError('range')">out of range</mat-error>
      <mat-error *ngIf="editForm?.controls?.latitude?.hasError('required')">required</mat-error>
  </mat-form-field>
  <br>
  <mat-form-field class="input-full-width">
    <input matInput class="input-full-width"
      placeholder="Longitude (DMS or decimal)"
      required
      formControlName="longitude"
      oninput="this.value = this.value.trim().toUpperCase()">
      <mat-error *ngIf="editForm?.controls?.longitude?.hasError('format')">bad format</mat-error>
      <mat-error *ngIf="editForm?.controls?.longitude?.hasError('length')">improper length</mat-error>
      <mat-error *ngIf="editForm?.controls?.longitude?.hasError('range')">out of range</mat-error>
      <mat-error *ngIf="editForm?.controls?.longitude?.hasError('required')">required</mat-error>
  </mat-form-field>
  <div mat-dialog-actions>
    <button [disabled]="!editForm.valid" mat-raised-button color="primary" (click)="submit()">Submit</button>
    <button mat-raised-button (click)="handleCancel()">Cancel</button>
  </div>
</form>
</div>
<app-classification-bar class="bottom-modal-classification-bar"></app-classification-bar>
