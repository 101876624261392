import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Unit } from '@models/unit.model';
import { User } from '@models/user.model';
import { CurrentUserService } from '@services/current-user-service';
import { CountryService } from '@services/data-services/country.service';
import { LocationService } from '@services/data-services/location.service';
import { UnitService } from '@services/data-services/unit.service';
import { take } from 'rxjs/operators';
import { Location } from '@models/location.model';

const UNIT_ASSOCIATIONS = ['creator', 'updater'];

const LOCATION_ASSOCIATIONS = ['op_area', 'creator'];


@Component({
  selector: 'app-unit-details',
  templateUrl: './unit-details.component.html',
  styleUrls: ['./unit-details.component.css']
})
export class UnitDetailsComponent implements OnInit, AfterViewInit {
  unit: Unit;
  lastLocation: Location;
  currentUser: User;

  constructor(private countryService: CountryService,
    private currentUserService: CurrentUserService,
    @Inject(MAT_DIALOG_DATA) public data: { unitId: string },
    private dialogRef: MatDialogRef<UnitDetailsComponent>,
    private locationService: LocationService,
    private unitService: UnitService,
    private route: Router) { }

  ngOnInit(): void {
    this.currentUser = this.currentUserService.getCurrentUser();
  }

  ngAfterViewInit(): void {
    // Use a promise to avoid change detection issues...
    Promise.resolve().then(() => {
      this.unitService.getUnitByUnitId(this.data.unitId, { include: UNIT_ASSOCIATIONS}).pipe(take(1)).subscribe(response => {
        this.unit = response;
      });

      this.locationService.getAll({ unit_id: this.data.unitId, sort: 'create_ts', limit: 1, include: LOCATION_ASSOCIATIONS }).pipe(take(1)).subscribe(response => {
        this.lastLocation = response[0];
      });
    });
  }

  close(result = null): void {
    this.dialogRef.close(result);
  }

  canEdit(): boolean {
    if (this.unit) {
      return this.currentUser.canEdit(this.unit);
    } else {
      return false;
    }
  }

  edit(): void {
    this.close('edit');
  }

  navigateUnitUpdate(): void {
    this.close();

    this.route.navigate(['/units/unit-update'], { state: { data: { unit_id: this.data.unitId } } });
  }

  delete(): void {
    this.close('delete');
  }

  getFlag(country) {
    return this.countryService.getFlagUrl(country);
  }
}
