<div id="help-page">

  <!-- Start ABOUT Info -->
  <mat-card class="form-card" fxLayoutAlign="center">
    <div>
      <mat-card-header>
        <mat-card-title>
          <h3>{{info.titlePrefix}} About:</h3>
        </mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <div>FDT-M Version: {{info.VERSION}}</div>
      </mat-card-content>
    </div>
  </mat-card>
  <!-- End ABOUT Info -->

  <!-- Start CONTACT SUPPORT -->
  <mat-card class="form-card" fxLayoutAlign="center">
    <div style="text-align:center">
      <mat-card-header style="text-align:center;display:block">
        <mat-card-title>
          <h3>Contact Support:</h3>
        </mat-card-title>
      </mat-card-header>

      <!-- Start select drop down -->
      <mat-form-field appearance="fill">
        <mat-label>Points of Contact</mat-label>
        <mat-select [formControl]="commandControl" required [(ngModel)]="commandSelect">
          <mat-option>--</mat-option>
          <mat-option *ngFor="let cmd of commands" [value]="cmd">
            {{cmd}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="commandControl.hasError('required')">Please choose a Command</mat-error>
        <!-- <mat-hint>{{commandSelect}}</mat-hint> -->
      </mat-form-field>
      <!-- End select drop down -->
      <div style="display:flex">
        <mat-card-content>
          <div id="contact-card">
            <mat-card *ngFor="let poc of filterByCommand(commandSelect)" class="contact-card">
              <div class="card-title-box">
                <div *ngIf="poc.command" class="command-box card-title-items">
                  <span>{{poc.command.name}}</span>
                </div>
                <div class="logo-box card-title-items">
                  <img [src]="fetchImage(poc.command)" [alt]="poc.command.name" width="100" height="100">
                </div>
              </div>

              <hr>
              <div *ngIf="poc.supportingCommands">
                <div class="icon-text">
                  <!-- <mat-icon></mat-icon> -->
                  <span class="contact-text">{{poc.supportingCommands}}</span>
                </div>
              </div>

              <div *ngIf="poc.contactPosition">
                <div class="icon-text">
                  <mat-icon>account_balance</mat-icon>
                  <span class="contact-text">{{poc.contactPosition}}</span>
                </div>
              </div>

              <div *ngIf="poc.contactName && isArray(poc.contactName)">
                <div class="icon-text" *ngFor="let name of poc.contactName">
                  <mat-icon>person</mat-icon>
                  <span class="contact-text">{{name}}</span>
                </div>
              </div>
              <div *ngIf="!isArray(poc.contactName)">
                <div class="icon-text">
                  <mat-icon>person</mat-icon>
                  <span class="contact-text">{{poc.contactName}}</span>
                </div>
              </div>

              <div *ngIf="poc.phone && isArray(poc.phone)">
                <div class="icon-text" *ngFor="let phone of poc.phone">
                  <mat-icon>phone</mat-icon>
                  <span class="contact-text">{{phone}}</span>
                </div>
              </div>
              <div *ngIf="!isArray(poc.phone)">
                <div class="icon-text">
                  <mat-icon>phone</mat-icon>
                  <span class="contact-text">{{poc.phone}}</span>
                </div>
              </div>

              <div *ngIf="poc.email && isArray(poc.email)">
                <div class="icon-text" *ngFor="let email of poc.email">
                  <mat-icon>email</mat-icon>
                  <span class="contact-text">
                    <a [href]="'mailto:'+email">{{email}}</a>
                  </span>
                </div>
              </div>
              <div *ngIf="!isArray(poc.email)">
                <div class="icon-text">
                  <mat-icon>email</mat-icon>
                  <span class="contact-text">
                    <a [href]="'mailto:'+poc.email">{{poc.email}}</a>
                  </span>
                </div>
              </div>

              <div *ngIf="poc.link">
                <div class="icon-text">
                  <mat-icon>link</mat-icon>
                  <span class="contact-text web-link"><a [href]="poc.link" target="_blank">{{poc.link}}</a></span>
                </div>
              </div>

              <div *ngIf="poc.supportHours">
                <div class="icon-text">
                  <mat-icon>access_time</mat-icon>
                  <span class="contact-text">{{poc.supportHours}}</span>
                </div>
              </div>
            </mat-card>
          </div>
        </mat-card-content>
      </div>
    </div>
  </mat-card>
  <!-- End Contact Support Info -->

  <!-- Start Additional POC-->
  <!--<mat-card class="form-card" fxLayoutAlign="center">
    <div style="text-align:center">
      <mat-card-header *ngIf="defaultPoc">
        <mat-card-title>
          <h3>For Technical or Additional Support Contact:</h3>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div id="contact-card">
          <mat-card *ngIf="defaultPoc.command" class="contact-card">
            <div class="card-title-box">
              <div class="command-box card-title-items">
                <span>{{defaultPoc.command.command_name}}</span>
              </div>

              <div class="logo-box card-title-items" *ngIf="defaultPoc.logoLocation">
                <img [src]="defaultPoc.logoLocation" [alt]="defaultPoc.command.command_name" width="100" height="100">
              </div>
            </div>
            <hr>

            <div *ngIf="defaultPoc.supporting_commands">
              <div class="icon-text">
                <span class="contact-text">{{defaultPoc.supporting_commands}}</span>
              </div>
            </div>

            <div id="mat-card-content">
              <div *ngIf="defaultPoc.contact_position" class="icon-text">
                <mat-icon class="contact-icon">account_balance</mat-icon>
                <span class="contact-text">{{defaultPoc.contact_position}}</span>
              </div>

              <div *ngIf="defaultPoc.contact_name && isArray(defaultPoc.contact_name)">
                <div *ngFor="let name of defaultPoc.contact_name" class="icon-text">
                  <mat-icon class="contact-icon">person</mat-icon>
                  <span class="contact-text">{{name}}</span>
                </div>
              </div>
              <div *ngIf="!isArray(defaultPoc.contact_name)">
                <div class="icon-text">
                  <mat-icon class="contact-icon">person</mat-icon>
                  <span class="contact-text">{{defaultPoc.contact_name}}</span>
                </div>
              </div>

              <div *ngIf="defaultPoc.phone && isArray(defaultPoc.phone)">
                <div *ngFor="let phone of defaultPoc.phone" class="icon-text">
                  <mat-icon class="contact-icon">phone</mat-icon>
                  <span class="contact-text">{{phone}}</span>
                </div>
              </div>
              <div *ngIf="!isArray(defaultPoc.phone)" class="icon-text">
                <mat-icon class="contact-icon">phone</mat-icon>
                <span class="contact-text">{{defaultPoc.phone}}</span>
              </div>

              <div *ngIf="defaultPoc.email && isArray(defaultPoc.email)">
                <div *ngFor="let email of defaultPoc.email" class="icon-text">
                  <mat-icon class="contact-icon">email</mat-icon>
                  <span class="contact-text">
                    <a [href]="'mailto:'+email">{{email}}</a>
                  </span>
                </div>
              </div>
              <div *ngIf="!isArray(defaultPoc.email)">
                <div class="icon-text">
                  <mat-icon class="contact-icon">email</mat-icon>
                  <span class="contact-text">
                    <a [href]="'mailto:'+defaultPoc.email">{{defaultPoc.email}}</a>
                  </span>
                </div>
              </div>

              <div *ngIf="defaultPoc.link" class="icon-text">
                <mat-icon class="contact-icon">link</mat-icon>
                <span class="contact-text web-link"><a [href]="defaultPoc.link"
                    target="_blank">{{defaultPoc.link}}</a></span>
              </div>

              <div *ngIf="defaultPoc.support_hours" class="icon-text">
                <mat-icon class="contact-icon">access_time</mat-icon>
                <span class="contact-text">{{defaultPoc.support_hours}}</span>
              </div>
            </div>
          </mat-card>
        </div>
      </mat-card-content>
    </div>
  </mat-card>-->
</div>