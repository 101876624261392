import { ShipType } from '@models/ship-type.model';
import { ShipTypeApiService } from '@services/api-services/ship-type-api.service';
import { Injectable } from '@angular/core';
import { JsonConverter } from '@services/global/json-converter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ShipTypeService {
    private jsonConvert: JsonConverter;

    constructor(private shipTypeApiService: ShipTypeApiService) {
        this.jsonConvert = new JsonConverter();
    }

    public getShipType(params: any): Observable<ShipType[]> {
        return this.shipTypeApiService.getShipType(params)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, ShipType);
                })
            );
    }

    public getAll(): Observable<ShipType[]> {
        return this.shipTypeApiService.getAllShipTypes()
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, ShipType);
                })
            );
    }

    public getShipTypeByShipTypeId(shipTypeId: string): Observable<ShipType> {
        return this.shipTypeApiService.getShipTypeByShipTypeId(shipTypeId)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, ShipType);
                })
            );
    }

    public update(shipType: ShipType): Observable<ShipType> {
        return this.shipTypeApiService.updateShipType(this.jsonConvert.serializeObject(shipType))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, ShipType);
                })
            );
    }

    public create(shipType: ShipType): Observable<ShipType> {
        return this.shipTypeApiService.createShipType(this.jsonConvert.serializeObject(shipType))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, ShipType);
                })
            );
    }

    public delete(shipType: ShipType): Observable<ShipType> {
        return this.shipTypeApiService.deleteShipType(this.jsonConvert.serializeObject(shipType))
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }
}
