import { Component, OnInit } from '@angular/core';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { GlobalMessageTriggers } from '@services/global/message-bus/global-message-triggers.enum';
import { GlobalBusMessage } from '@services/global/message-bus/global-bus-message.model';
import { Router } from '@angular/router';
import { ReportingService } from '@services/data-services/reporting.service';

@Component({
  template: '<div></div>'
})
export class FomPageBaseComponent  {
  dataSource: any;
  entities: Array<any>;
  EDIT_MODAL_COMPONENT: any;
  RELOAD_MESSAGE: GlobalMessageTriggers;

  constructor(public messageBusService: MessageBusService,
    public route: Router, ) {}

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {
    //this.loadNavBar();
  }

  loadNavBar() {
    //const navBarData = {
    //  topNavBar: new this.TOP_NAV_BAR(this.route),
    //  bottomNavBar: (this.BOTTOM_NAV_BAR) ? new this.BOTTOM_NAV_BAR(this.route) : null
    //};
    //const navBarGlobalMessage = new GlobalBusMessage(GlobalMessageTriggers.LOAD_NAV_BAR, navBarData);
    //this.messageBusService.publishMessage(navBarGlobalMessage);
  }

}
