import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from './api.config';

@Injectable()
export class AccountRequestApiService {
  SERVER_URL;

  constructor(
    private http: HttpClient,
    apiConfig: APIConfig) {
      this.SERVER_URL = apiConfig.TARGET_URL;
  }

  public requestAcc(accDetails: any): Observable<any> {
    return this.http.post(`${this.SERVER_URL}/account-requests`, accDetails);
  }

  public exsistingAccountRequest(accDetails: any): Observable<any> {
    return this.http.put(`${this.SERVER_URL}/account-requests`, accDetails);
  }

  public getAccountRequests(): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/account-requests`);
  }

  public getAccountRequestCount(): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/account-requests?count=true`);
  }

  public getAccountRequstById(id: string): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/account-requests/${id}`);
  }

  public findAccountRequstByUserId(id: string): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/account-requests/user/${id}`);
  }

  public applyChanges(data: object): Observable<any> {
    return this.http.put(`${this.SERVER_URL}/account-requests/${data["id"]}`, data)
  }

  public denyChanges(id: string, reason: string): Observable<any> {
    const options:any = {}
    options.headers = {"Content-Type": "application/json"}
    options.body = { "reason": reason }
    return this.http.request('delete', `${this.SERVER_URL}/account-requests/${id}`, options)
  }
}
