import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from './api.config';

@Injectable()
export class MessageConfigApiService {
  SERVER_URL;
  RESOURCE_URI = 'message-configs';

  constructor(private http: HttpClient, apiConfig: APIConfig) {
    this.SERVER_URL = apiConfig.TARGET_URL;
  }

  public createMessageConfig(messageConfig: any): Observable<any> {
    return this.http.post(
      `${this.SERVER_URL}/${this.RESOURCE_URI}`, messageConfig);
  }

  public updateMessageConfig(messageConfig: any): Observable<any> {
    return this.http.put(
      `${this.SERVER_URL}/${this.RESOURCE_URI}/${messageConfig.message_config_id}`, messageConfig);
  }

  public deleteMessageConfig(messageConfig: any): Observable<any> {
    return this.http.delete(
      `${this.SERVER_URL}/${this.RESOURCE_URI}/${messageConfig.message_config_id}`);
  }

}