<app-classification-bar class="top-modal-classification-bar"></app-classification-bar>
<app-fdtm-modal-header [headerString]="headerString"></app-fdtm-modal-header>
<div mat-dialog-content>
    <div fxLayout="column" fxLayoutAlign="center stretch" class="row">
        <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
            <mat-form-field>
                <mat-select [disabled]="!isNew" placeholder="System or Command" [(ngModel)]="parameter.level">
                    <mat-option [value]="'System'">System</mat-option>
                    <mat-option [value]="'Command'">Command</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
               <mat-select [disabled]="parameter.level == 'System'" placeholder="Command" [(ngModel)]="parameter.commandId">
                  <mat-option *ngFor="let command of commandOptions" [value]="command.commandId">{{command.name}}</mat-option>
               </mat-select>
            </mat-form-field>
        </div>
       </div>
       <div fxLayout="column" fxLayoutAlign="center stretch" class="row">
        <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
            <mat-form-field>
                <input matInput placeholder="Parameter Name" required [(ngModel)]="parameter.name">
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="Component Name" [(ngModel)]="parameter.componentName">
            </mat-form-field>
        </div>
       </div>
  <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
    <mat-form-field>
        <input matInput placeholder="Value (string)" [(ngModel)]="parameter.stringValue">
    </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
        <mat-form-field>
            <input type="number" matInput placeholder="Value (number)" [(ngModel)]="parameter.numberValue">
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
        <mat-form-field>
            <input matInput [matDatepicker]="myDatepicker" [(ngModel)]="parameter.datetimeValue" placeholder="Value (date)">
            <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #myDatepicker></mat-datepicker>
        </mat-form-field>
    </div>
  <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
        <button mat-raised-button class="fdt-m-primary" (click)="fileInput.click()">
            <span>Select</span>
            <input #fileInput type="file" (change)="onFileInput($event)" style="display:none;" />
        </button>
    <mat-form-field>
      <input matInput placeholder="Filename" [(ngModel)]="parameter.blobName">
    </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button color="primary" [disabled]="watchFields()" mat-raised-button (click)="submit()">Submit</button>
      <button mat-raised-button (click)="cancel()">Cancel</button>
      <span *ngIf="watchFields()" class="mat-color-warn">{{requiredMessage}}</span>
   </div>
</div>
<app-classification-bar class="bottom-modal-classification-bar"></app-classification-bar>
