import { JsonObject, JsonProperty, Any} from 'json2typescript';

@JsonObject('Notifier')
export class Notifier {
 @JsonProperty('list', String, true)
 list: string = undefined;
 @JsonProperty('subject', String, true)
 subject: string = undefined;
 @JsonProperty('message', String, true)
 message: string = undefined;
 @JsonProperty('classification', String, true)
 classification: string = undefined;
}
