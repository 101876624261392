import { ArrayType } from "@angular/compiler";
import { Pipe, PipeTransform } from "@angular/core";

/****************
 html use:

  For a list of strings​/numbers
  *ngFor="let releaseType of releaseTypes | filter:'abc'"

  For a list of objects include the field you want to filter on
  *ngFor="let releaseType of releaseTypes | filter: 'abc': 'description'"

------------------------------------------------------------------------ 
 Note: add a '!' to the searchTerm to filter on (i.e : "!abc") it will exclude everything from the list that matches searchTerm
--------------------------------------------------------------------------
------------------------------------------------------------------------ 
 Note: If you are not looking for an exact match you can add true/false for notExactMatch
--------------------------------------------------------------------------
  */


@Pipe({
  name: "filter",
  pure: false
})
export class ArrayFilterPipe implements PipeTransform {
  transform(array: any[], searchTerm: string | number, fieldName?: string, notExactMatch: boolean = false): any[] {
    let exclude = false
    if (searchTerm && searchTerm.toString().startsWith('!')) {
      searchTerm = searchTerm.toString().substring(1, searchTerm.toString().length);
      exclude = true
    }
    if (searchTerm && fieldName == null || fieldName == undefined) {
      if (!exclude) {
        return array.filter(item => item.toString().toLowerCase().trim() == searchTerm.toString().toLowerCase().trim());
      } else {
        return array.filter(item => item.toString().toLowerCase().trim() != searchTerm.toString().toLowerCase().trim());
      }
    } else if (searchTerm && fieldName) {
      return array.filter(item => {
        const propertyValue = this.dataAccessor(item, fieldName || '') == null ? undefined : this.dataAccessor(item, fieldName as string).toString()
        if (item && propertyValue && propertyValue!= undefined) {
          if (!exclude && !notExactMatch) {
            return propertyValue.toString().toLowerCase().trim() == searchTerm.toString().toLowerCase().trim();
          } 
          else if (!exclude && notExactMatch) {
            return propertyValue.toString().toLowerCase().trim().includes(searchTerm.toString().toLowerCase().trim());
          } 
          else if (exclude && notExactMatch) {
            return !propertyValue.toString().toLowerCase().trim().includes(searchTerm.toString().toLowerCase().trim()); 
          } else {
            return propertyValue.toString().toLowerCase().trim() != searchTerm.toString().toLowerCase().trim();
          }
        }
        return false;
      });
    }
    else {
      return array
    }
  }

  dataAccessor = (item: any, property: string) => {
    if (property.includes('.')) {
      return property.split('.').reduce((o, p) => o && o[p], item)
    }
    return item ? item[property] : null;
  }

}