import { Port } from '@models/port.model';
import { PortApiService } from '@services/api-services/port-api.service';
import { Injectable } from '@angular/core';
import { JsonConverter } from '@services/global/json-converter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class PortService {
    private jsonConvert: JsonConverter;

    constructor(private portApiService: PortApiService) {
        this.jsonConvert = new JsonConverter();
    }

    public getAll(): Observable<Port[]> {
        return this.portApiService.getAllPorts()
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, Port);
                })
            );
    }

    public getPortByPortId(portId: string): Observable<Port> {
        return this.portApiService.getPortByPortId(portId)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Port);
                })
            );
    }

    public update(port: Port): Observable<Port> {
        return this.portApiService.updatePort(this.jsonConvert.serializeObject(port))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Port);
                })
            );
    }

    public create(port: Port): Observable<Port> {
        return this.portApiService.createPort(this.jsonConvert.serializeObject(port))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Port);
                })
            );
    }

    public delete(port: Port): Observable<Port> {
        return this.portApiService.deletePort(this.jsonConvert.serializeObject(port))
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }
}
