<mat-form-field *ngIf="lookups" [ngClass]="selectClass">
    <mat-select placeholder="{{typeName}}" [(ngModel)]="selectedItem" [disabled]="disabled" (selectionChange)="selectionChanged($event)" >
        <mat-select-trigger *ngIf="selectedItem && flagProperty && selectedItem[flagProperty]">
            <img  [src]="getFlag(selectedItem)" height="16">&nbsp;
            {{selectedItem[keyProperty] || '-- Select --'}}
        </mat-select-trigger>
        <mat-option *ngFor="let item of lookups" [disabled]="item.isDisabled" [value]="item">
            <img *ngIf="flagProperty == keyProperty && item[keyProperty]" [src]="getFlag(item)" height="16">&nbsp;
            {{item[keyProperty] || getDisplayValue(item) || '-- Select --'}}
        </mat-option>
    </mat-select>
</mat-form-field>