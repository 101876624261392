<app-classification-bar class="top-modal-classification-bar"></app-classification-bar>
<app-fdtm-modal-header [headerString]="headerString"></app-fdtm-modal-header>
<div mat-dialog-content>
<form [formGroup]="editForm">
  <mat-form-field class="input-full-width">
    <mat-select placeholder="Command" required formControlName="commandId">
      <mat-option *ngFor="let option of commandOptions" [value]="option.commandId">{{option.name}}</mat-option>
    </mat-select>
  </mat-form-field>
  <br>
  <mat-form-field class="input-full-width">
    <input matInput (keyup)="checkDups($event, 'legacyRank', true);checkIDkey($event)" autocomplete="off"
      placeholder="Rank (numeric)" required formControlName="legacyRank">
      <mat-error *ngIf="editForm?.controls?.legacyRank?.hasError('duplicate entry')">duplicate entry</mat-error>
      <mat-error *ngIf="editForm?.controls?.legacyRank?.hasError('numeric only')">numeric only</mat-error>
  </mat-form-field>
  <br>
  <mat-form-field class="input-full-width">
    <mat-label>Select a Ship Class:</mat-label>
    <mat-select formControlName="shipClassId" required>
      <mat-option *ngFor="let option of shipClassOptions" [value]="option.shipClassId">{{ option.shipClass }}</mat-option>
    </mat-select>
    <mat-error *ngIf="editForm?.controls?.selectedOption?.hasError('required')">This field is required</mat-error>
  </mat-form-field>
  <br>
  <mat-form-field class="input-full-width">
    <mat-label>Select a Vessel Type:</mat-label>
    <mat-select formControlName="vesselTypeId" required>
      <mat-option *ngFor="let option of vesselTypeOptions" [value]="option.vesselTypeId">{{ option.vesselType }}</mat-option>
    </mat-select>
    <mat-error *ngIf="editForm?.controls?.selectedOption?.hasError('required')">This field is required</mat-error>
  </mat-form-field>
  <app-lookup-select (selectedValueChanged)="onCountryChange($event)" [itemPK]="'name'" [itemId]="entity.remarks" [selectClass]="'input-full-width'"
  [typeName]="'Country Code'" [keyProperty]="'name'" [flagProperty]="'name'" [disabled]="isDelete">
  </app-lookup-select>
  <div mat-dialog-actions>
  <button *ngIf="!isDelete" [disabled]="!editForm.valid || !entity.remarks" mat-raised-button color="primary"
    (click)="handleSubmit()">Submit</button>
  <button *ngIf="isDelete" mat-raised-button color="warn" (click)="handleDelete()">Delete</button>
  <button mat-raised-button (click)="handleCancel()">Cancel</button>
  </div>
</form>
</div>
<app-classification-bar class="bottom-modal-classification-bar"></app-classification-bar>