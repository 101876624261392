import { Component, Input, OnInit } from '@angular/core';
import { AppInitService } from '../../../../config/init.service';

@Component({
  selector: 'app-classification-bar',
  templateUrl: './classification-bar.component.html',
  styleUrls: ['./classification-bar.component.css']
})
export class ClassificationBarComponent implements OnInit {
  classification = 'UNCLASSIFIED // TEST';

  @Input() main: boolean = false;
  constructor(private init: AppInitService) { }

  ngOnInit() {
    const banner = this.init.getConfig().classification.banner
    const modalBanner = this.init.getConfig().classification.modalBanner
    const allowModalBanner = this.init.getConfig().classification.allowModalBanner
    this.classification = this.main ? banner
      : !this.main && allowModalBanner && modalBanner ? modalBanner :
      banner;
  }
}
