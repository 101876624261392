<h1 mat-dialog-title>Unit Filters</h1>
<div mat-dialog-content>
  <mat-form-field>
    <mat-select [(ngModel)]="filters.port_id" multiple name="port" placeholder="Ports">
        <mat-option *ngFor="let port of ports" [disabled]="false" [value]="port.portId"> {{port.name}}</mat-option>
    </mat-select>
    <button mat-icon-button (click)="$event.stopPropagation();all('port_id', ports, 'portId')" matSuffix title="Select/Deselect all available Ports">
        <mat-icon>select_all</mat-icon>
    </button>
    <span class="small-text" matSuffix>({{filters?.port_id? filters?.port_id?.length : 0}} of {{ports?.length}} selected)</span>
 </mat-form-field>
 <br>
 <mat-form-field>
    <mat-select [(ngModel)]="filters.threat_id" multiple name="threat" placeholder="Threats">
        <mat-option *ngFor="let threat of threats" [disabled]="false" [value]="threat.threatId"> {{threat.threatName}}</mat-option>
    </mat-select>
    <button mat-icon-button (click)="$event.stopPropagation();all('threat_id', threats, 'threatId')" matSuffix title="Select/Deselect all available Threats">
        <mat-icon>select_all</mat-icon>
    </button>
    <span class="small-text" matSuffix>({{filters?.threat_id? filters?.threat_id?.length : 0}} of {{threats?.length}} selected)</span>
 </mat-form-field>
 <br>
 <mat-form-field>
    <mat-select [(ngModel)]="filters.category_id" multiple name="category" placeholder="Categories">
        <mat-option *ngFor="let category of categories" [disabled]="false" [value]="category.categoryId"> {{category.category}}</mat-option>
    </mat-select>
    <button mat-icon-button (click)="$event.stopPropagation();all('category_id', categories, 'categoryId')" matSuffix title="Select/Deselect all available Categories">
        <mat-icon>select_all</mat-icon>
    </button>
    <span class="small-text" matSuffix>({{filters?.category_id? filters?.category_id?.length : 0}} of {{categories?.length}} selected)</span>
 </mat-form-field>
 <br>
 <mat-form-field>
  <mat-select [(ngModel)]="filters.ship_type_id" multiple name="shipTypes" placeholder="Ship Types">
      <mat-option *ngFor="let shipType of shipTypes" [disabled]="false" [value]="shipType.shipTypeId"> {{shipType.shipTypeName}}</mat-option>
  </mat-select>
  <button mat-icon-button (click)="$event.stopPropagation();all('ship_type_id', shipTypes, 'shipTypeId')" matSuffix title="Select/Deselect all available Ship Types">
      <mat-icon>select_all</mat-icon>
  </button>
  <span class="small-text" matSuffix>({{filters?.ship_type_id? filters?.ship_type_id?.length : 0}} of {{shipTypes?.length}} selected)</span>
  </mat-form-field>
  <br>
  <mat-form-field>
    <mat-select [(ngModel)]="filters.country_id" multiple name="countries" placeholder="Countries">
        <mat-option *ngFor="let country of countries" [disabled]="false" [value]="country.countryId"> {{country.name}}</mat-option>
    </mat-select>
    <button mat-icon-button (click)="$event.stopPropagation();all('country_id', countries, 'countryId')" 
        matSuffix title="Select/Deselect all available Countries">
        <mat-icon>select_all</mat-icon>
    </button>
    <span class="small-text" matSuffix>({{filters?.country_id? filters?.country_id?.length : 0}} of {{countries?.length}} selected)</span>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="warn" (click)="cancel()">Cancel</button>
  <button mat-raised-button color="accent" (click)="clear()">Clear</button>
  <button mat-raised-button color="primary" (click)="apply()" cdkFocusInitial>Apply</button>
</div>
