<mat-card class="form-card">
    <div fxLayoutAlign="center" class="nob-header">
    <div class="card">
      <img class="fomImage" height="74px" width="74px" src="assets/images/FDT-M.png" />
    </div>
    <div class="nob-header-middle">
      <div class="grid-title nob-title">
        <span>{{initService.getConfig().titlePrefix}}FDT-M Blue Locations</span>
      </div>
    </div>
    <div class="card">
        <img class="fomImage" height="74px" width="74px" src="assets/images/FDT-M.png" />
      </div>
    </div>
  <div class="container action-area" fxLayout.xs="column" fxLayoutAlign="center" >
   <!-- <mat-form-field class="groupSelect">
    <mat-select [(ngModel)]="grouping"  placeholder="Group By" (selectionChange)="groupingChanged($event, grouping)">
      <mat-option *ngFor="let groupItem of groupItems" [value]="groupItem">
              {{groupItem.display}}
      </mat-option>
      </mat-select>
    </mat-form-field> -->
    <mat-form-field class="groupSelect" fxFlex="30%">
      <input matInput type="text" [(ngModel)]="filterValue" autocomplete="off" (ngModelChange)="onFilterChange($event)"
         placeholder="Find Unit (partial), SCONUM or Pennant (exact)">
      <span class="hyper" (click)="clearFilter('')" matSuffix>X</span>
    </mat-form-field>
  </div>
  <br>
  <div fxLayoutGap="10px" fxLayoutGap.xs="0" >
    <mat-paginator 
      (page)="pageChange($event,'')"
      [length]="dataLength"
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions"
      showFirstLastButtons>
    </mat-paginator>
    <!-- <cdk-virtual-scroll-viewport tvsItemSize="48" headerHeight="56" class="scroll-viewport"> -->
    <table class="nob-table" mat-table matSort [dataSource]="dataSource" (matSortChange)="sortData($event)">
        <ng-container matColumnDef="dateCreated">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Receive Date </th>
          <td mat-cell
            *matCellDef="let element">
              {{element.dateCreated | zulu1Pipe}}
          </td>
        </ng-container>  
        <ng-container matColumnDef="blueUnit.blueUnitName">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Unit Name </th>
            <td mat-cell *matCellDef="let element">
              {{element.blueUnit.blueUnitName}}
            </td>
          </ng-container>
          <ng-container matColumnDef="blueUnit.pennant">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Pennant </th>
            <td mat-cell *matCellDef="let element">
              {{element.blueUnit.pennant}}
            </td>
          </ng-container>
          <ng-container matColumnDef="blueUnit.sconum">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> SCONUM </th>
            <td mat-cell *matCellDef="let element">
              {{element.blueUnit.sconum}}
            </td>
          </ng-container>
          <ng-container matColumnDef="blueUnit.cCode">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> CCode </th>
            <td mat-cell *matCellDef="let element">
              {{element.blueUnit.cCode}}
            </td>
          </ng-container>
          <ng-container matColumnDef="blueUnit.blueUnitCode">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Unit Code </th>
            <td mat-cell *matCellDef="let element">
              {{element.blueUnit.blueUnitCode}}
            </td>
          </ng-container>
          <ng-container matColumnDef="latitude">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Latitude </th>
            <td mat-cell *matCellDef="let element">
              {{element.latitude}} ({{element.decLat}})
            </td>
          </ng-container>
          <ng-container matColumnDef="longitude">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Longitude </th>
            <td mat-cell *matCellDef="let element">
              {{element.longitude}} ({{element.dec_lon}})
            </td>
          </ng-container>
          <ng-container matColumnDef="dateTime">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Location Time </th>
            <td mat-cell
              *matCellDef="let element">
                {{element.dateTime | zulu1Pipe}}
            </td>
          </ng-container>
          <ng-container matColumnDef="course">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Course </th>
            <td mat-cell
              *matCellDef="let element">
                {{element.course}}
            </td>
          </ng-container>
          <ng-container matColumnDef="speed">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Speed </th>
            <td mat-cell
              *matCellDef="let element">
                {{element.speed}}
            </td>
          </ng-container>
          <ng-container matColumnDef="trackNum">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Track </th>
            <td mat-cell
              *matCellDef="let element">
                {{element.trackNum}}
            </td>
          </ng-container>
          <ng-container matColumnDef="uid">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> GCCS ID </th>
            <td mat-cell
              *matCellDef="let element">
                {{element.uid}}
            </td>
          </ng-container>
      
        <tr class="nob-header-row" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr class="nob-row" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    <!-- </cdk-virtual-scroll-viewport>   -->
    </div>
    
    </mat-card>
  
