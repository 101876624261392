import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { EditModalBaseComponent } from '@fom-module/base-classes/edit-modal-base.component';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { ClassificationService } from '@services/data-services/classification.service';
import { FriendlyErrorService } from '@services/friendly-error.service';

@Component({
  selector: 'app-edit-classification-modal',
  templateUrl: './edit-classification-modal.component.html',
  styleUrls: ['./edit-classification-modal.component.css']
})
export class EditClassificationModalComponent extends EditModalBaseComponent implements OnInit {

  editForm: FormGroup;

  constructor(public classificationService: ClassificationService,
    public formBuilder: FormBuilder, private friendlyErrorService: FriendlyErrorService,
    public dialogRef: MatDialogRef<EditClassificationModalComponent>) {
    super(classificationService, dialogRef);
  }

  ngOnInit() {
    this.headerString = this.isNew ? 'Add Classification' : this.isDelete ? 'Delete Classification' : 'Edit Classification'
    this.editForm = this.formBuilder.group({
      classificationName: [{ value: this.entity.classificationName, disabled: this.isDelete }],
      shortName: [{ value: this.entity.shortName, disabled: this.isDelete }],
      sacc: [{ value: this.entity.sacc, disabled: this.isDelete }],
      sourceCode: [{ value: this.entity.sourceCode, disabled: this.isDelete }],
      sortBy: [{ value: this.entity.sortBy, disabled: this.isDelete }]
    });
  }

  localDelete() {
    this.classificationService.delete(this.entity).pipe(take(1)).subscribe(res => {
      this.dialogRef.close(res)
      //this.messageBusService.publishMessage(new GlobalBusMessage(GlobalMessageTriggers.CLOSE_MODAL));
    }, err => {
      this.friendlyErrorService.processError(err);
    });
  }
}
