import {JsonObject, JsonProperty, Any} from 'json2typescript';
import { DateConverter } from '../services/global/date-converter';

@JsonObject('ClassMarkingCategory')
export class ClassMarkingCategory {
@JsonProperty('class_marking_category_id', String, true)
classMarkingCategoryId: string = undefined;
@JsonProperty('class_marking_name', String, true)
classMarkingName: string = undefined;
@JsonProperty('class_marking_table_name', String, true )
classMarkingTableName: string = undefined;
@JsonProperty('create_ts', DateConverter, true)
dateCreated: Date = undefined;
@JsonProperty('created_by', String, true)
createdBy: string = undefined;
@JsonProperty('update_ts', DateConverter, true)
dateUpdated: Date = undefined;
@JsonProperty('updated_by', String, true)
updatedBy: string = undefined;
@JsonProperty('delete_ts', DateConverter, true)
dateDeleted: Date = undefined;
@JsonProperty('deleted_by', String, true)
deletedBy: string = undefined;
@JsonProperty('processing_tags', String, true)
processingTags: string = undefined;
}
