import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter } from '../services/global/date-converter';
import { Command } from '@models/command.model';

@JsonObject('Fleet')
export class Fleet {
  @JsonProperty('fleet_id', String, true)
  fleetId: string = undefined;
  @JsonProperty('fleet_code', String, true)
  fleetCode: string = undefined;
  @JsonProperty('remarks', String, true)
  remarks: string = undefined;
  @JsonProperty('command_id', String, true)
  commandId: string = undefined;
  @JsonProperty('command', Command, true)
  command: Command = undefined;
  @JsonProperty('created_by', String, true)
  createdBy: string = undefined;
  @JsonProperty('create_ts', DateConverter, true)
  dateCreated: Date = undefined;
  @JsonProperty('lookup_filter_id', String, true)
  lookupFilterId: string = undefined;
  @JsonProperty('sort_sequence', String, true)
  sortSequence: number = undefined;

  static lookupName(): string {
    return 'fleets';
  }
}
