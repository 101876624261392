import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { EditModalBaseComponent } from '@fom-module/base-classes/edit-modal-base.component';
import { LookUpService } from '@services/data-services/lookup.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MessageConfigService } from '@services/data-services/message-config.service';
import { AppInitService } from '../../../../config/init.service';
import { UtilityService } from '@services/utility.service';

@Component({
  selector: 'app-message-config-modal',
  templateUrl: './edit-message-config-modal.component.html',
  styleUrls: ['./edit-message-config-modal.component.css']
})
export class EditMessageConfigModalComponent extends EditModalBaseComponent implements OnInit {

  editForm: FormGroup;
  message: string;
  systemConnectionId: any;
  messageTypeOptions: string[];
  invalidMessageTypes: string[];
  parametersTemplates: any;

  constructor(public messageConfigService: MessageConfigService,
    public lookUpService: LookUpService,
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<EditMessageConfigModalComponent>,
    private initService: AppInitService,
    public messageBusService: MessageBusService,
    private utilities: UtilityService,
    private changeDetectorRef: ChangeDetectorRef) {
    super(messageConfigService, dialogRef);
  }

  ngOnInit() {
    this.headerString = this.isNew ? 'Add Message Config' :
      this.isDelete ? 'Delete Message Config' : 'Edit Message Config';

    // In future move to database over the config file.
    this.messageTypeOptions = this.initService.getConfig().connections?.messageTypes?.sort() || ['OTH-G'];

    this.editForm = this.formBuilder.group({
      systemConnectionId: [{ value: this.entity.systemConnectionId, disabled: this.isDelete }],
      messageType: [{ value: this.entity.messageType, disabled: this.isDelete }],
      messageParams: [{ value: this.entity.messageParams, disabled: this.isDelete }, this.utilities.jsonValidator],
      enabled: [{ value: this.entity.enabled, disabled: this.isDelete }]
    });
  }

  setParameterTemplate(messageType, formGroup) {
    const template = this.parametersTemplates.filter((template) => template.parametersType === messageType)

    if(template.length >= 1){
      const params = {};

      const templateOptions = template[0]?.template.options;
      templateOptions.forEach(e => params[e.option] = "");

      formGroup.controls.messageParams.setValue(JSON.stringify(params, null, 2));
    } else {
      formGroup.controls.messageParams.setValue('{\n}')
    }

    this.changeDetectorRef.detectChanges();
  }

  async uploadForm(event) {
    this.handleSubmit();
  }

}
