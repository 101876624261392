import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { JsonConverter } from '@services/global/json-converter';
import { SlideChoice } from '@models/slide-choice.model';
import { SlideGeneratorApiService } from '@services/api-services/slide-generator-api.service';
import { map } from 'rxjs/operators';

@Injectable()
export class SlideGeneratorService {
    private jsonConvert: JsonConverter;

    constructor(private slideGeneratorApiService: SlideGeneratorApiService) {
        this.jsonConvert = new JsonConverter();
    }

    public getAll(): Observable<SlideChoice[]> {
        return this.slideGeneratorApiService.getAllSlideChoices()
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, SlideChoice);
                })
            );
    }

    public generateSlides(slideChoices: Array<any>): Observable<any> {
        return this.slideGeneratorApiService.generateSlides(slideChoices)
            // .pipe(
            //     map((data) => {
            //         return data;
            //     })
            // );
    }
}
