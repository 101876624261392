import { Injectable } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { BehaviorSubject } from "rxjs";
@Injectable({
    providedIn: 'root'
  })
export class TableWrapperService {

    paginator!: MatPaginator 

}