import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SystemConnection } from '@models/system-connection.model';
import { FormArray, FormGroup } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.css', '../base-classes/grid-base.component.css']
})
export class ReviewComponent implements OnInit, OnDestroy {

  @Input() form: FormGroup;
  @Input() connection: SystemConnection;

  subscription: Subscription;

  review: any;
  editForm: FormGroup;
  formArrays: any;
  formFields: any[];


  constructor() {
  }

  ngOnInit() {
    this.editForm = <FormGroup>this.form
    this.review = this.parseForm(this.form);

    // Watch for changes to the form.
    this.subscription = this.editForm.valueChanges.subscribe(() => this.review = this.parseForm(this.form));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  toHumanReadable(key) {
    const words = key.match(/[A-Za-z][a-z]*/g) || [];

    return words.map(this.capitalize).join(" ");
  }

  capitalize(word) {
    return word.charAt(0).toUpperCase() + word.substring(1);
  }

  parseForm(editForm) {
    const getValue = (v) => {
      return v.value
    }

    this.formFields = editForm.get('formArray').controls.map(e => {
      return Object.entries(e.controls).map(([k, v]) => { return { key: k, value: getValue(v) } })
    });

    return this.formFields.flat();
  }

  isArray(obj: any) : boolean {
    return Array.isArray(obj);
  }

  entries(obj: any) {
    return Object.entries(obj).map(([k, v]) => { return { key: k, value: v}});
  }
}
