import { FormBuilder, Validators } from '@angular/forms';
import { EditModalBaseComponent } from '@fom-module/base-classes/edit-modal-base.component';
import { ClassMarkingRelationshipService } from '@services/data-services/class-marking-relns.service';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { Component, OnInit } from '@angular/core';
import { LookUpService } from '@services/data-services/lookup.service';
import { MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators'
import { Classification } from '@models/classification.model';
import { Fgi } from '@models/fgi.model';
import { Control } from '@models/control.model';
import { Releasability } from '@models/releasability.model';
import { OtherControl } from '@models/other-control.model';
import { User } from '@models/user.model';
import { UtilityService } from '@services/utility.service';
import { ClassMarkingCategory } from '@models/class-marking-category.model';
import { CurrentUserService } from '@services/current-user-service';
import { ClassMarkingCategoryService } from '@services/data-services/class-marking-category.service';
import { JSONPath } from 'jsonpath-plus';


@Component({
  selector: 'app-edit-class-mark-relationship-modal',
  templateUrl: './edit-class-mark-relationship-modal.component.html',
  styleUrls: ['./edit-class-mark-relationship-modal.component.css']
})
export class EditClassMarkRelationshipModalComponent extends EditModalBaseComponent implements OnInit {
  constructor(
    public classMarkingCategoryService: ClassMarkingCategoryService,
    public classMarkingRelationshipService: ClassMarkingRelationshipService,
    public lookUpService: LookUpService,
    public currentUserService: CurrentUserService,
    public formBuilder: FormBuilder, public utilities: UtilityService,
    public dialogRef: MatDialogRef<EditClassMarkRelationshipModalComponent>,
    public messageBusService: MessageBusService) {
    super(classMarkingRelationshipService, dialogRef);
  }


  primaryMarkingOptions: object[];
  relatedMarkingOptions: object[];
  categoryOptions: ClassMarkingCategory[];

  selectedCategory: ClassMarkingCategory = null;
  cat: ClassMarkingCategory = null;

  user: User;

  ngOnInit() {
    let primaryMarking: '';
    let relatedMarkList: [];
    let cat: {};
    this.headerString = this.isNew ? 'Add Classification Marking Relationship' :
      this.isDelete ? 'Delete Classification Marking Relationship' :
        'Edit Classification Marking Relationship';
    primaryMarking = JSONPath({ path: "$.primaryDetail[0].primaryMarkingId", json: this.entity })
    relatedMarkList = JSONPath({ path: "$.primaryDetail[*].relatedMarkingId", json: this.entity });
    this.editForm = this.formBuilder.group({
      primaryMarkingId: [primaryMarking[0], Validators.required],
      relatedMarkings: [relatedMarkList, Validators.required],
      category: [this.selectedCategory, Validators.required]
    });

    this.user = this.currentUserService.getCurrentUser();

    Promise.resolve().then(() => {
      this.loadLookups()
    })
    this.editForm.controls['category'].setValue(this.selectedCategory.classMarkingCategoryId);
  }

  loadLookups() {
    this.classMarkingCategoryService.getAll().pipe(take(1)).subscribe(entities => {
      this.categoryOptions = entities;
    });
    //
    switch (this.selectedCategory?.classMarkingName) {
      case 'Classifications':
        this.lookUpService.getLookupByType(Classification).pipe(take(1)).subscribe(result => {
          this.primaryMarkingOptions = result;
          this.relatedMarkingOptions = result;
        });
        break;
      case 'SCI Controls':
        this.lookUpService.getLookupByType(Control).pipe(take(1)).subscribe(result => {
          this.primaryMarkingOptions = result;
          this.relatedMarkingOptions = result;
        });
        break;
      case 'FGIs':
        this.lookUpService.getLookupByType(Fgi, true).pipe(take(1)).subscribe(result => {
          this.primaryMarkingOptions = result;
          this.relatedMarkingOptions = result;
        });
        break;
      case 'Other Controls':
        this.lookUpService.getLookupByType(OtherControl).pipe(take(1)).subscribe(result => {
          this.primaryMarkingOptions = result;
          this.relatedMarkingOptions = result;
        });
        break;
      case 'Releasability':
        this.lookUpService.getLookupByType(Releasability).pipe(take(1)).subscribe(result => {
          this.primaryMarkingOptions = result;
          this.relatedMarkingOptions = result;
        });
        break;
    }

  }

  onCategoryChange(event) {
    let tmpClassMarkingCategoryId = event.value;
    //look up the full object to make the screen behave
    const firstMatch = this.categoryOptions.find((cat) => cat.classMarkingCategoryId === tmpClassMarkingCategoryId);
    if (firstMatch) {
      this.selectedCategory = firstMatch
    }
    this.ngOnInit();
  }


  createNewEntity() {
    //as we are doing things in batch with dependent children; easiest CRUD approach is delete and add
    let newEntities = []
    let newCreatedBy = this.currentUserService.getCurrentUser().userId;
    //get new PrimaryId
    let newPrimaryId = this.editForm.controls['primaryMarkingId']['value'];
    let oldPrimaryId = this.entity.isNew ? null : newPrimaryId
    //get new Children Related Ids
    let newChildren = this.editForm.controls['relatedMarkings']['value'];
    let newCategory = this.editForm.controls['category']['value'];
    //create new classMarkingRelationship for each child
    for (const newChild in newChildren) {
      const newClassRelation = {
        primaryMarkingId: newPrimaryId,
        classMarkingCategoryId: newCategory,
        relatedMarkingId: newChildren[newChild],
        createdBy: newCreatedBy
      }
      newEntities.push(newClassRelation)
    }
    this.classMarkingRelationshipService.bulkUpdate(newEntities,
      oldPrimaryId).pipe(take(1)).subscribe(response => {
        this.dialogRef.close(response)
      })

  }

  submit() {
    this.createNewEntity()
  }
  cancel() {
    this.dialogRef.close()
  }
}
