import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { JsonConverter } from '@services/global/json-converter';
import { map, catchError } from 'rxjs/operators';
import { PocApiService } from '@services/api-services/point-of-contact-api.service';
import { pointOfContact as Poc } from '@models/point-of-contact.model'


@Injectable()
export class PocService {
  private jsonConvert: JsonConverter;

  constructor(private pocApiService: PocApiService) {
    this.jsonConvert = new JsonConverter();
  }

  public getAll(): Observable<Poc[]> {
    return this.pocApiService.getAllPocs().pipe(
      map( (data) => {
        return this.jsonConvert.deserializeArray(data, Poc);
      })
    );
  }

  public getPocById(id: string): Observable<Poc> {
    return this.pocApiService.getPocById(id).pipe(
      map((data) => {
        return this.jsonConvert.deserializeObject(data, Poc);
      })
    );
  }

  public updatePoc(id, data: object): Observable<Poc> {
    return this.pocApiService.updatePoc(id, data).pipe(
      map((data) => {
        return this.jsonConvert.deserializeObject(data, Poc);
      })
    );
  }

  public create(data: object): Observable<Poc> {
    return this.pocApiService.createPoc(data).pipe(
      map((data) => {
        return this.jsonConvert.deserializeObject(data, Poc);
      })
    );
  }

  public delete(poc: Poc): Observable<Poc> {
    return this.pocApiService.deletePoc(poc).pipe(
      map((data) => {
        return data;
      })
    );
  }
}
