<div class="admin-work-area">
    <div fxLayout fxLayoutAlign="center center" class="simple-grid-title">
      <span class="grid-title">{{initService.getConfig().titlePrefix}} FDT-M Services</span>
    </div>
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource">

         <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Service </th>
            <td mat-cell *matCellDef="let element"> {{element.display}} {{element?.status?.version ? '(' + element.status.version + ')' : ''}} </td>
         </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let element"> {{element.status?.currentStatus}} </td>
          </ng-container>

          <ng-container matColumnDef="onOff">
            <th mat-header-cell *matHeaderCellDef> Sus/Res </th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="!element.hideSuspend">
                    <button mat-raised-button class="fdt-m-primary"
                        [disabled]="element.status?.currentStatus != 'up' && element.status?.currentStatus != 'down'"
                        (click)="changeStatus(element)">{{element.status?.currentStatus == 'up' ? 'Suspend' : 'Resume'}}
                    </button>
                </div>
            </td>
          </ng-container>

        <ng-container matColumnDef="start">
          <th mat-header-cell *matHeaderCellDef> Started </th>
          <td mat-cell *matCellDef="let element"> {{element.status?.start | zulu1Pipe}} </td>
        </ng-container>

        <ng-container matColumnDef="requests">
            <th mat-header-cell *matHeaderCellDef> Requests </th>
            <td mat-cell *matCellDef="let element"> {{element.status?.requestCount}} </td>
          </ng-container>

          <ng-container matColumnDef="errors">
            <th mat-header-cell *matHeaderCellDef> Errors </th>
            <td mat-cell *matCellDef="let element"> {{element.status?.errorCount}} </td>
          </ng-container>
          <ng-container matColumnDef="events">
            <th mat-header-cell *matHeaderCellDef> Events </th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="element.isMicroservice && element.status && element.status.activationEvents">
                    <mat-select placeholder="Events">
                        <mat-option *ngFor="let item of element.status.activationEvents | slice:0:20; let i=index"
                            title="{{item.event ? item.event : item.eventName ? item.eventName  : ''}} {{item.time | zulu1Pipe}}">
                            {{item.event ? item.event : item.eventName ? item.eventName  : ''}} {{item.time | zulu1Pipe}}
                        </mat-option>
                    </mat-select>
                </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="logFiles">
            <th mat-header-cell *matHeaderCellDef> Log Files </th>
            <td mat-cell *matCellDef="let row; let element">
                <div *ngIf="element.logFiles?.length">
                    <mat-select placeholder="Log Files ({{element.logFiles?.length}})" (selectionChange)="download(row, $event)">
                        <mat-option *ngFor="let item of element.logFiles" [value]="item">
                            {{item.name}}: {{item.size | fileSize}}
                        </mat-option>
                    </mat-select>
                </div>
            </td>
          </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

    </div>
  </div>
