<app-classification-bar class="top-classification-bar modal-top-bar"></app-classification-bar>
<app-fdtm-modal-header [headerString]="headerString"></app-fdtm-modal-header>
<form #editForm="ngForm">
<div mat-dialog-content id="account-request-form" class="request-account-form" fxLayoutAlign="space-between left" style="margin:auto">
  <mat-form-field>
    <mat-label>Username</mat-label>
    <input matInput name="username" placeholder="firstName.M.lastName" required [(ngModel)]="user.user_name" matTooltip="Format: firstName.lastName">
  </mat-form-field>

  <mat-form-field>
    <mat-label>First Name</mat-label>
    <input matInput name="firstName" placeholder="First Name" required [(ngModel)]="user.first_name">
  </mat-form-field>

  <mat-form-field>
    <mat-label>Last Name</mat-label>
    <input matInput name="lastName" placeholder="Last Name" required [(ngModel)]="user.last_name">
  </mat-form-field>

  <mat-form-field>
    <mat-label>Email</mat-label>
    <input matInput name="email" placeholder="Person.Name@Navy.mil" required [(ngModel)]="user.email">
  </mat-form-field>

  <mat-form-field>
    <mat-label>Justification</mat-label>
    <input matInput name="justification" placeholder="need permission for... reasons, New user for Fleet" required [(ngModel)]="user.justification">
  </mat-form-field>

  <mat-form-field>
    <mat-select placeholder="Permission level" [(ngModel)]="user.permission.permission_description" required name="permission">
        <mat-option *ngFor="let option of permissionOptions" [value]="option.permissionDescription">{{option.permissionDescription}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-select placeholder="Command" [(ngModel)]="user.command.command_name" required name="command">
        <mat-option *ngFor="let option of commandOptions" [value]="option.name" >{{option.name}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div mat-dialog-actions>
  <button mat-raised-button [disabled]="!editForm.valid" color="primary" (click)="submit()">Submit</button>
  <button mat-raised-button (click)="cancel()">Cancel</button>
</div>
</form>
<app-classification-bar class="bottom-classification-bar modal-bottom-bar"></app-classification-bar>
