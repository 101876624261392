import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { OwnerType} from '@models/owner-type.model';
import { EditOwnerTypeModalComponent } from '@edit-modals/edit-owner-type-modal/edit-owner-type-modal.component';
import { GridBaseComponent } from '@fom-module/base-classes/grid-base.component';
import { OwnerTypeService } from '@services/data-services/owner-type.service';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { take } from 'rxjs/operators';
import { AppInitService } from '../../../config/init.service';

@Component({
  selector: 'app-owner-type',
  templateUrl: './owner-type.component.html',
  styleUrls: ['./owner-type.component.css', '../base-classes/grid-base.component.css']
})
export class OwnerTypeComponent extends GridBaseComponent implements OnInit {
  displayedColumns = ['description', 'tableName', 'keyColumn', 'nameColumn', 'delete'];
  constructor(public messageBusService: MessageBusService,
    private ownerTypeService: OwnerTypeService,
    private dialog: MatDialog,
    public route: Router,
    public initService: AppInitService) {
      super(messageBusService, ownerTypeService, route);
    }

  ngOnInit() {
    this.loadEntityData();
  }
  edit(item: any) {
    const dialogRef = this.dialog.open(EditOwnerTypeModalComponent, {
      width: '320px'
    });
    dialogRef.componentInstance.isNew = false;
    dialogRef.componentInstance.entity = item;

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      if (result) {
        this.loadEntityData();
      }
    });

  }
  add() {
    const dialogRef = this.dialog.open(EditOwnerTypeModalComponent, {
      width: '320px'
    });
    dialogRef.componentInstance.isNew = true;
    dialogRef.componentInstance.entity = new OwnerType();

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      // in order to get complete data for the new Unit, go back to the DB
      this.loadEntityData();
    });
  }
}
