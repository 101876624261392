import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FomPageBaseComponent } from '@fom-module/base-classes/fom-page-base.component';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { Router } from '@angular/router';
import { LookUpService } from '@services/data-services/lookup.service';
import { Unit } from '@models/unit.model';
import { DataCardsService } from '@services/data-services/datacards.service';
import { debounceTime, exhaustMap, filter, finalize, map, scan, startWith, switchMap, takeWhile, tap } from 'rxjs/operators';
import { GlobalMessageTriggers } from '@services/global/message-bus/global-message-triggers.enum';
import { take } from 'rxjs/operators';
import { UnitService } from '@services/data-services/unit.service';
import { UtilityService } from '@services/utility.service';
import { AppInitService } from '../../../../config/init.service';
import { Country } from '@models/country.model';
import { CountryService } from '@services/data-services/country.service';
import { PicklistPrefService } from '@services/data-services/picklist-pref.service';
import { CurrentUserService } from '@services/current-user-service';
import { Observable, Subject } from 'rxjs';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
  selector: 'app-data-card',
  templateUrl: './data-cards.component.html',
  styleUrls: ['./data-cards.component.css'],
})

// Underway History
export class DataCardComponent extends FomPageBaseComponent implements OnInit {
  unit: Unit;
  editForm: FormGroup;
  entity: any;
  now = new Date();
  month = [];
  underway = [];
  chart = [];
  // type ahead options
  // unit select from fomAssist View
  unitOptions: Unit[] = [];

  // special filter for Country on the search/type-ahead
  countries: Country[] = [];
  filterByCountry: any = {};
  noCountry = {};

  @ViewChild(MatAutocompleteTrigger, { static: true }) unitAutocomplete: MatAutocompleteTrigger;
  unitsPerPage: number;
  filteredUnits$: Observable<Unit[]> | undefined;
  private nextUnitPage$ = new Subject();
  isLoading = false;

  // add title to the chart
  title = 'Units';
  // add chart options. this will make the chart responsive (visible in any device)
  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      xAxes: [{
        display: true,
        scaleLabel: {
          display: true,
          labelString: 'Month'
        }
      }],
      yAxes: [{
        display: true,
        scaleLabel: {
          display: true,
          labelString: 'Days Underway'
        }
      }],
    }
  };
  public barChartLabels: string[] = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  public barChartType = 'bar';
  public barChartLegend = true;
  dataCard: any;
  public barChartData: any[];

  public chartClicked(e: any): void {
    console.log(e);
  }

  public chartHovered(e: any): void {
    console.log(e);
  }

  constructor(public dataCardsService: DataCardsService,
    public messageBusService: MessageBusService,
    public lookUpService: LookUpService,
    public unitService: UnitService,
    public formBuilder: FormBuilder,
    public utilities: UtilityService,
    public route: Router,
    private lookupService: LookUpService,
    private picklistPrefService: PicklistPrefService,
    private currentUserService: CurrentUserService,
    private countryService: CountryService,
    public initService: AppInitService) {
    super(messageBusService, route);
  }

  ngOnInit() {
    this.unitsPerPage = this.initService.getConfig().unitPageSize || 20;
    this.filterByCountry = this.noCountry
    this.barChartData = [];
    const filters = Object.assign({}, JSON.parse(sessionStorage.getItem('unitFilters')));
    this.initializeForm(filters);
    const user = this.currentUserService.getCurrentUser()
    this.RELOAD_MESSAGE = GlobalMessageTriggers.RELOAD_LOCATION_DATA;
    // use the user instance function, will be changed to JFMCC
    const filter = { owner_id: user.unitOwnerId(null) }
    this.picklistPrefService.getAll(filter).pipe(take(1)).subscribe(preferences => {
      this.lookupService.getLookupByType(Country, true).pipe(take(1)).subscribe(entities => {
        entities.map(item => {
          // ok, is there a preference for this guy?
          //const id = item[this.itemPK]  // this.itemId ? this.itemId : item[this.itemPK]
          const found = preferences.find(x => {
            return x.sourcePicklistId == item.countryId
          })
          if (found) {
            if (found.sortSequence && found.sortSequence == this.utilities.HIGH_VALUES) {
              item.isDisabled = true
            }
            else {
              item.isDisabled = false
            }
            item.sortSequence = found.sortSequence
          }
        })
        entities.sort((a, b) => {
          return a.sortSequence - b.sortSequence
        })
        this.countries = entities
      })
    })
    super.ngOnInit();
  }

  countryFilterChange(event) {
    const filter = event.value.countryId ? { country_id: event.value.countryId } : {}
    this.initializeForm(filter)
  }

  getFlag(country) {
    return this.countryService.getFlagUrl(country)
  }
  unitFiltersChanged(filters) {
    // clear the results
    this.barChartData = [];
    // but don't clear the input fields
    this.initializeForm(filters);
  }
  initializeForm(filters) {
    this.editForm = this.formBuilder.group({
      unit: ['']
    });

    filters['sort'] = 'name';

    const unitFilters$ = this.editForm.get('unit').valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      filter(q => typeof q === 'string')
    );

    this.filteredUnits$ = unitFilters$.pipe(
      switchMap(filter => {
        // Reset current page whenever name filter changes.
        let currentPage = 1;
        return this.nextUnitPage$.pipe(
          tap(() => this.isLoading = true),
          startWith(currentPage),
          // Note: Until the backend responds, ignore NextPage requests.
          exhaustMap(_ => this.searchUnits(filter, filters, currentPage)),
          tap(() => currentPage++),
          takeWhile(p => p.length > 0, true),
          scan((allUnits: any, newUnits: any) => allUnits.concat(newUnits), []),
          tap(units => {
            const unit = this.editForm.get('unit')?.value || '';

            if(unit?.length) {
              if (units.length === 1 && unit.trim().toLocaleLowerCase() === units[0].fullName().toLocaleLowerCase()) {
                this.unitAutocomplete.closePanel();
                this.editForm.controls.unit.setValue(units[0])
              }
            }

            this.isLoading = false;
          })
        );
      })
    );
  }

  displayUnit(unit?: Unit): string | undefined {
    return unit ? unit.fullName() : undefined;
  }

  private _filterUnits(value: any): Unit[] {
    const filterValue = value.toString().toLowerCase();

    return this.unitOptions.filter(option => option.fullName().toLowerCase().includes(filterValue));
  }

  public getDataForUnit() {
    this.unit = this.editForm.controls.unit.value;
    this.dataCardsService.getTotalDays({ unit_id: this.unit.unitId }).pipe(take(1)).subscribe(barChartData => {
      this.barChartData = barChartData as any[]; // Fill the chart array with data
    }
    );
  }

  onChartClick(event) {
    console.log(event);
  }

  searchUnits(nameFilter: string, filters: any, page: number): Observable<Unit[]> {
    const offset = page > 0 ? (page - 1) * this.unitsPerPage : 0;

    return this.unitService.findUnits(nameFilter, filters, this.unitsPerPage, offset);
  }

  onUnitOptionsScroll() {
    this.nextUnitPage$.next();
  }
}
