import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from './api.config';

@Injectable()
export class CPageApiService {
  SERVER_URL;

  constructor(
    private http: HttpClient, apiConfig: APIConfig) {
    this.SERVER_URL = apiConfig.TARGET_URL;
  }

  public getAllCPages(params = {}): Observable<any> {
    let httpParams = new HttpParams();

    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        httpParams = httpParams.set(key, params[key]);
      }
    }
    return this.http.get(
      `${this.SERVER_URL}/cpages`, {params: httpParams});
  }

  public getCPageByCPageId(cpageId: string): Observable<any> {
    return this.http.get(
      `${this.SERVER_URL}/cpages/${cpageId}`);
  }

  public updateCPage(cpage: any): Observable<any> {
    return this.http.put(
      `${this.SERVER_URL}/cpages/${cpage.cpage_id}`, cpage);
  }

  public createCPage(cpage: any): Observable<any> {
    return this.http.post(
      `${this.SERVER_URL}/cpages`, cpage);
  }

  public deleteCPage(cpage: any): Observable<any> {
    return this.http.delete(
      `${this.SERVER_URL}/cpages/${cpage.cpage_id}`);
  }
  public getFile(cpageId: string): Observable<any> {
    return this.http.get(
      `${this.SERVER_URL}/cpages/file/${cpageId}`,
      {responseType: 'blob'});
  } 
}
