import { Component, OnInit, OnDestroy, Output, EventEmitter, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { ReportingService } from '@services/data-services/reporting.service';
import {Subscription, timer} from "rxjs";
//import {TimerObservable} from "rxjs/observable/TimerObservable";
import { take } from 'rxjs/operators'

@Component({
  selector: 'app-nob-ticker',
  templateUrl: './nob-ticker.component.html',
  styleUrls: ['./nob-ticker.component.css']
})
export class NobTickerComponent implements OnInit, OnDestroy, OnChanges {

  @Output() unitClickEvent = new EventEmitter<any>();
  @Output() refreshClickEvent = new EventEmitter<any>();
  @Input() startTicker = ''
  showRefresh = false;
  public currentElement = {final_name: ''};  // bind this to the UI
  // two subscriptions, one 45 seconds apart to gather new data
  // the other every few seconds apart to update the display
  private dataSubscription: Subscription;
  private tickerSubscription: Subscription;
  constructor(private reportingService: ReportingService) { }

  ngOnChanges(changes: SimpleChanges): void {
    // Extract changes to the input property by its name

    // implementing this so that the ticker does not start until NOB or Locator has grabbed its data
    let trigger: SimpleChange = changes['startTicker']; 
    if (trigger && trigger.currentValue && trigger.currentValue == 'true') {
      // get new data every 45 seconds
      let dataTimer = timer(0, 45000)
      if (this.dataSubscription) {
        this.dataSubscription.unsubscribe()
      }
      this.dataSubscription = dataTimer.subscribe(x => {
        this.getNOBTicker()
      })
      // turn on the ticker
      this.showRefresh = true
    }
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    // gotta make the subscriptions go away
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe()
    }
    if (this.tickerSubscription) {
      this.tickerSubscription.unsubscribe()
    }
  }

  // called every 45 seconds to get new data
  public getNOBTicker() {
    // they could back up when refreshing
    if (this.showRefresh == true) {
      this.reportingService.getNOBTicker().pipe(take(1)).subscribe(nob => {
        if (nob && nob.length) {
          this.processNobTicker(nob);
        }
      })
    }
  }

  // if/when new data gathered, update the UI every few seconds displaying one of the values
  public processNobTicker(nob) {
    if (this.tickerSubscription) {
      this.tickerSubscription.unsubscribe()
    }
    let current = -1;
    let tickerTimer = timer(0, 3000)
    this.tickerSubscription = tickerTimer.subscribe(x => {
      current++
      if (current == nob.length) {
        current = 0
      }
      this.currentElement = nob[current]
    })
  }

  // when the unit in the ticker is clicked send an event to the parent (nob or locator)
  // so that it can activate a filter, or anything else
  public unitClicked(unit) {
    this.unitClickEvent.emit(unit)
  }

  public refreshClicked() {
    if (this.showRefresh == true) {
      //this.getNOBTicker()
      this.refreshClickEvent.emit()
      // it will come back on later
      this.showRefresh = false;
    }
  }
}
