<div class="admin-work-area">
  <div fxLayout fxLayoutAlign="center center" class="simple-grid-title">
    <span class="grid-title">{{initService.getConfig().titlePrefix}} FDT-M AOR Command Graph Maintenance</span>
  </div>
  <div cdkDropListGroup class="wrapper">
    <div class="commands">
      <div class="column-title">Commands</div>
      <div class="list-section list-view">
        <div cdkDropList [cdkDropListData]="commands" #commandList [cdkDropListDisabled]="!canEdit()">
          <div *ngFor="let command of commands" class="command" cdkDrag [cdkDragData]="command">
            <img [src]="loadLogo(command)" height="32">
            <span style="margin-left: 8px;">{{command.name}}</span>
            <span class="moving" *cdkDragPreview>{{dragPreview(command)}}</span>
            <span *cdkDragPlaceholder></span>
          </div>
        </div>
      </div>
    </div>
    <div label="Command Grid">
      <div class="header-box" cdkDropList (cdkDropListDropped)="dropUnified($event)">
        <span class="new-command" (click)="add()">
          <mat-icon>add</mat-icon>
        </span>
        <span>FDT-M Unified Commands</span>
        <span class="hyper save-button" *ngIf="canEdit()" (click)=save()>
          <mat-icon>save</mat-icon>
        </span>
      </div>
      <div class="tile-wrapper">
        <div *ngFor="let command of unified" class="unified-tile">
          <div class="role-box">
            <img [src]="loadLogo(command)" height="32">
            <span>{{command.name}}</span>
            <span class="remove-command" *ngIf="canEdit()" (click)="remove(command, unified)">X</span>
            <span *ngIf="!canEdit()"></span>
          </div>
          <div class="header-box" cdkDropList (cdkDropListDropped)="dropSubordinate($event, command)">
            <span class="new-command" (click)="add()">
              <mat-icon>add</mat-icon>
            </span>
            <span>{{command.name}} JFMCCs</span>
          </div>
          <div class="subordinate-grid-list">
            <div class="subordinate-tile-wrapper">
              <div *ngFor="let subordinate of command.children" class="subordinate-tile">
                <div class="role-box">
                  <img [src]="loadLogo(subordinate)" height="32">
                  <span>{{subordinate.name}}</span>
                  <span class="remove-command" *ngIf="canEdit()" (click)="remove(subordinate, command.children)">X</span>
                  <span *ngIf="!canEdit()"></span>
                </div>
                <div class="header-box" cdkDropList (cdkDropListDropped)="dropSubordinate($event, subordinate)">
                  <span class="new-command" (click)="add()">
                    <mat-icon>add</mat-icon>
                  </span>
                  <span>{{subordinate.name}} Commands</span>
                </div>
                <div class="command-list">
                  <div class="sub-children">
                    <div *ngFor="let level3 of subordinate.children" class="role-box small-text">
                      <img [src]="loadLogo(level3)" height="32">
                      <span>{{level3.name}}</span>
                      <span class="remove-command" *ngIf="canEdit()" (click)="remove(level3, subordinate.children)">X</span>
                      <span *ngIf="!canEdit()"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
