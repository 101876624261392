import { GlobalStore } from './global-store.model';
import { GlobalBusMessage } from './global-bus-message.model';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class MessageBusService {

  private globalMessage = new Subject<GlobalBusMessage>();
  globalMessage$ = this.globalMessage.asObservable();

  private globalStore = new Subject<GlobalStore>();
  globalStore$ = this.globalStore.asObservable();

  publishMessage(message: GlobalBusMessage) {
    this.globalMessage.next(message);
  }

  updateStore(newStore: GlobalStore) {
    let nextStore: GlobalStore =  Object.assign(
      this.globalStore.subscribe(currentStore =>  {
        return currentStore; }),
      newStore
    );
    this.globalStore.next(nextStore);
  }

}
