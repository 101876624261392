import {JsonObject, JsonProperty, Any} from 'json2typescript';
import { DateConverter } from '../services/global/date-converter';
import { ClassMarkingCategory } from '@models/class-marking-category.model';

@JsonObject('ClassMarkingRelationship')
export class ClassMarkingRelationship {
@JsonProperty('class_marking_relationship_id', String, true)
classMarkingRelationshipId: string = undefined;
@JsonProperty('class_marking_category_id', String, true)
classMarkingCategoryId: string = undefined;
@JsonProperty('classMarkCat', ClassMarkingCategory, true)
classMarkingCategory: ClassMarkingCategory = undefined;
@JsonProperty('relationship_type', String, true )
relationshipType: string = undefined;
@JsonProperty('primary_marking_id', String, true )
primaryMarkingId: string  = undefined;
@JsonProperty('primaryMarkClassification', Object, true )
primaryMarkClassification: object  = undefined;
@JsonProperty('primaryMarkSci', Object, true )
primaryMarkSci: object  = undefined;
@JsonProperty('primaryMarkFgi', Object, true )
primaryMarkFgi: object  = undefined;
@JsonProperty('primaryMarkControl', Object, true )
primaryMarkControl: object  = undefined;
@JsonProperty('primaryMarkReleasability', Object, true )
primaryMarkReleasability: object  = undefined;
@JsonProperty('related_marking_id')
relatedMarkingId: string = undefined;
@JsonProperty('relatedMarkClassification', Object, true )
relatedMarkClassification: object  = undefined;
@JsonProperty('relatedMarkSci', Object, true )
relatedMarkSci: object  = undefined;
@JsonProperty('relatedMarkFgi', Object, true )
relatedMarkFgi: object  = undefined;
@JsonProperty('relatedMarkControl', Object, true )
relatedMarkControl: object  = undefined;
@JsonProperty('relatedMarkReleasability', Object, true )
relatedMarkReleasability: object  = undefined;
@JsonProperty('notes', String, true )
notes: string = undefined;
@JsonProperty('create_ts', DateConverter, true)
dateCreated: Date = undefined;
@JsonProperty('created_by', String, true)
createdBy: string = undefined;
@JsonProperty('update_ts', DateConverter, true)
dateUpdated: Date = undefined;
@JsonProperty('updated_by', String, true)
updatedBy: string = undefined;
@JsonProperty('delete_ts', DateConverter, true)
dateDeleted: Date = undefined;
@JsonProperty('deleted_by', String, true)
deletedBy: string = undefined;
@JsonProperty('processing_tags', String, true)
processingTags: string = undefined;
}
