import { Injectable, NgZone} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class UserNotificationService {

  constructor(public snackBar: MatSnackBar, private zone: NgZone) { }

  showSuccess(message: string, duration: number = 3000): void {
    try {
      this.zone.run(() => {
        this.snackBar.open(message, 'Close', {
          duration: duration,
          verticalPosition: 'top', 
          horizontalPosition: 'center',
          panelClass: ['green-snackbar']
        });
      })
    }
    catch (e) {
      alert (message)
    }
}

  showError(message: string, duration: number = 6000): void {
    try {
      this.zone.run(() => {
        this.snackBar.open(message, 'Close', {
          duration: duration,
          verticalPosition: 'top', 
          horizontalPosition: 'center',
          panelClass: ['red-snackbar']
        });
      })
    }
    catch (e) {
      alert (message)
    }
  }
}
