import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from '@services/api-services/api.config';

@Injectable()
export class AuditUnitBorrowedApiService {
    SERVER_URL;

    constructor(
        private http: HttpClient, apiConfig: APIConfig) {
        this.SERVER_URL = apiConfig.TARGET_URL;
    }

    public getAllAudits(): Observable<any> {
        return this.http.get(
            `${this.SERVER_URL}/audits`);
    }

    public getAuditByAuditId(id: string): Observable<any> {
        return this.http.get(
            `${this.SERVER_URL}/audits/${id}`);
    }

    public createAudit(audit: any): Observable<any> {
        return this.http.post(
            `${this.SERVER_URL}/audits`, audit);
    }

}
