import { JsonObject, JsonProperty } from "json2typescript";
import { DateConverter } from '../services/global/date-converter';
import { User } from "@models/user.model";

@JsonObject('VersionHistory')
export class VersionHistory {
    @JsonProperty('version_id', String, true)
    versionId: string = undefined;
    @JsonProperty('version_number', Number, true)
    versionNumber: number = undefined;
    @JsonProperty('diff', Object, true)
    diff: Object = undefined;
    @JsonProperty('version_action', String, true)
    versionAction: string = undefined;
    @JsonProperty('versionable_type', String, true)
    versionableType: string = undefined;
    @JsonProperty('versionable_id', String, true)
    versionableId: string = undefined;
    @JsonProperty('rationale', String, true)
    rationale: string = undefined;
    @JsonProperty('created_by', String, true)
    createdBy: string = undefined;
    @JsonProperty('create_ts', DateConverter, true)
    createTs: Date = undefined;
    @JsonProperty('creator', User, true)
    creator: User = undefined;
}
