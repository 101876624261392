import {JsonObject, JsonProperty, Any} from 'json2typescript';
import { Command } from '@models/command.model';

@JsonObject('Fgi')
export class Fgi {
  @JsonProperty('fgi_id', String, true)
  fgiId: string = undefined;
  @JsonProperty('fgi_name', String, true)
  name: string = undefined;
  @JsonProperty('command_id', String, true)
  commandId: string = undefined;
  @JsonProperty('command', Command, true)
  command: Command = undefined;
  @JsonProperty('created_by', String, true)
  createdBy: string = undefined;
  @JsonProperty('lookup_filter_id', String, true)
  lookupFilterId: string = undefined;
  @JsonProperty('sort_sequence', String, true)
  sortSequence: number = undefined;

  static lookupName(): string {
    return 'fgis';
  }
}
