import { FormBuilder } from '@angular/forms';
import { EditModalBaseComponent } from '@fom-module/base-classes/edit-modal-base.component';
import { ShipTypeService } from '@services/data-services/ship-type.service';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { Component, OnInit } from '@angular/core';
import { Country } from '@models/country.model';
import { LookUpService } from '@services/data-services/lookup.service';
import { Command } from '@models/command.model';
import { MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { UtilityService } from '@services/utility.service';
import { CommandHierarchyService } from '@services/data-services/command-hierarchy.service';
import { User } from '@models/user.model';
import { CurrentUserService } from '@services/current-user-service';
import { CommandHierarchy } from '@models/command-hierarchy.model';
import { ShipClass } from '@models/ship-class.model';
import { VesselType } from '@models/vessel-type.model';

@Component({
  selector: 'app-edit-ship-type-modal',
  templateUrl: './edit-ship-type-modal.component.html',
  styleUrls: ['./edit-ship-type-modal.component.css']
})
export class EditShipTypeModalComponent extends EditModalBaseComponent implements OnInit {
  commandOptions: Command[];
  countryOptions: Country[];
  shipClassOptions: ShipClass[];
  vesselTypeOptions: VesselType[];
  user: User;
  commandHierarchy: CommandHierarchy;

  flag: string = '';

  constructor(public shipTypeService: ShipTypeService,
    public lookUpService: LookUpService, public utilities: UtilityService,
    public formBuilder: FormBuilder,
    private commandHierarchyService: CommandHierarchyService,
    public dialogRef: MatDialogRef<EditShipTypeModalComponent>,
    public messageBusService: MessageBusService,
    private currentUserService: CurrentUserService) {
    super(shipTypeService, dialogRef);
  }

  ngOnInit() {
    this.headerString = this.isNew ? 'Add Ship Type' :
    this.isDelete ? 'Delete Ship Type' :
    'Edit Ship Type'
    this.user = this.currentUserService.getCurrentUser();
    if (this.isNew) {
      this.entity.commandId = this.user.currentJFMCC ? this.user.currentJFMCC.commandId : this.user.commandId;
    }
    this.editForm = this.formBuilder.group({
      commandId: [{ value: this.entity.commandId, disabled: this.isDelete }],
      legacyRank: [{ value: this.entity.legacyRank, disabled: this.isDelete }],
      shipClassId: [{ value: this.entity.shipClassId, disabled: this.isDelete }],
      vesselTypeId: [{ value: this.entity.vesselTypeId, disabled: this.isDelete }],
      //remarks: [this.entity.remarks]
    });
    this.flag = this.entity.remarks;
    Promise.resolve().then(() => {
      this.loadLookups();
    });
  }

  loadLookups() {
    this.lookUpService.getLookupByType(Command, true).pipe(take(1)).subscribe(result => {
      this.commandHierarchyService.getAll({ sort: 'version_num', limit: 1 }).pipe(take(1)).subscribe(json => {
        if (json && Array.isArray(json) && json.length) {
          this.commandHierarchy = json[0];

          let jfmccs;
          if (this.user.isSystemAdmin()) {
            jfmccs = this.commandHierarchy.getJFMCCs();
          } else {
            jfmccs = this.commandHierarchy.getJFMCCIds(this.user.command);
          }

          this.commandOptions = result.filter(item => {
            return jfmccs.includes(item.commandId);
          });
        } else {
          this.commandOptions = result;
        }
      });
    });
    this.lookUpService.getLookupByType(ShipClass, true).pipe(take(1)).subscribe(result => {
      this.shipClassOptions = result;
    })
    this.lookUpService.getLookupByType(VesselType, true).pipe(take(1)).subscribe(result => {
      this.vesselTypeOptions = result;
    })
  }

  onCountryChange(country) {
    this.entity.remarks = this.flag = country.name
  }


  checkIDkey(event) {
    const value = this.editForm.controls.legacyRank.value
    if (value && value.length && isNaN(value)) {
      this.editForm.controls.legacyRank.setErrors({'numeric only': true});
    }
    else {
      const error = this.editForm.controls.legacyRank.hasError('numeric only')
      if (error) {
        this.editForm.controls.legacyRank.setErrors({'numeric only': null});
      }
    }
  }
}
