import { OtherControl } from '@models/other-control.model';
import { OtherControlApiService } from '../api-services/other-control-api.service';
import { Injectable } from '@angular/core';
import { JsonConverter } from '../global/json-converter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable()
export class OtherControlService {
    private jsonConvert: JsonConverter;

    constructor(private apiService: OtherControlApiService) {
        this.jsonConvert = new JsonConverter();
    }

    public getAll(): Observable<OtherControl[]> {
        return this.apiService.getAllControls()
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, OtherControl);
                })
            );
    }

    public getControlByControlId(id): Observable<OtherControl> {
        return this.apiService.getControlByControlId(id)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, OtherControl);
                })
            );
    }

    public update(item: OtherControl): Observable<OtherControl> {
        return this.apiService.updateControl(this.jsonConvert.serializeObject(item))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, OtherControl);
                })
            );
    }

    public create(item: OtherControl): Observable<OtherControl> {
        return this.apiService.createControl(this.jsonConvert.serializeObject(item))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, OtherControl);
                })
            );
    }

    public delete(item: OtherControl): Observable<OtherControl> {
        return this.apiService.deleteControl(this.jsonConvert.serializeObject(item))
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

}
