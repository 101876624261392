<app-classification-bar class="top-modal-classification-bar"></app-classification-bar>
<app-fdtm-modal-header [headerString]="headerString"></app-fdtm-modal-header>
<div mat-dialog-content>
    <div [innerHtml]="message"></div>
</div>
<div mat-dialog-actions>
    <button mat-raised-button color="warn" (click)="handleConfirm()">{{buttonText}}</button>
    <button mat-raised-button (click)="handleCancel()">Cancel</button>
</div>
<app-classification-bar class="bottom-modal-classification-bar"></app-classification-bar>
