<app-classification-bar class="top-modal-classification-bar"></app-classification-bar>
<app-fdtm-modal-header [headerString]="headerString"></app-fdtm-modal-header>

<div mat-dialog-content>
    <div *ngIf="versions.length > 0" class="accordion-buttons">
        <button mat-raised-button (click)="accordion.openAll()">
            <mat-icon>unfold_more</mat-icon>
            Expand All
        </button>
        <button mat-raised-button (click)="accordion.closeAll()">
            <mat-icon>unfold_less</mat-icon>
            Collapse All
        </button>
    </div>

    <div *ngIf="versions.length === 0">
        There is no version history to view for this Location.
    </div>

    <mat-accordion class="headers-align" multi>
        <mat-expansion-panel *ngFor="let version of versions">
            <mat-expansion-panel-header>
                <mat-panel-title>Version {{version.versionNumber}}</mat-panel-title>
                <mat-panel-description>
                    {{version.versionAction | titlecase}}: {{ version.createTs | date: 'MM/dd/YYYY HH:mm\'Z\'':'UTC' | uppercase }}
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="container" fxLayout="row">
                Changed By: {{ version.creator.username }} ({{ version.creator?.command?.name }})
            </div>

            <div class="container" fxLayout="row">
                Change Action: {{ version.versionAction | titlecase }}
            </div>

            <div class="container" fxLayout="row">
                Change Reason: {{ version.rationale | titlecase }}
            </div>

            <ng-container *ngIf="(version.diff.previousValues | keyvalue).length > 0; else noChanges">
                <table class="changes-table">
                    <tr>
                        <th>Attribute</th>
                        <th>Previous Value</th>
                        <th>New Value</th>
                    </tr>
                    <ng-container *ngFor="let item of version.diff.previousValues | keyvalue">
                        <tr *ngIf="viewableAttributes.includes(item.key)">
                            <td>{{ humanReadableAttributeName(item.key) }}</td>
                            <td>{{ humanReadableAttribute(item.key, item.value) }}</td>
                            <td>{{ humanReadableAttribute(item.key, version.diff.newValues[item.key]) }}</td>
                        </tr>
                    </ng-container>
                </table>
            </ng-container>

            <ng-template #noChanges>
                <div class="no-changes">
                    This Location was updated as part of a bulk edit, but no data was changed for this particular location.
                </div>
            </ng-template>


        </mat-expansion-panel>
    </mat-accordion>

    <div mat-dialog-actions>
        <button mat-raised-button (click)="close()">Close</button>
    </div>
</div>

<app-classification-bar class="bottom-modal-classification-bar"></app-classification-bar>
