import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter } from '../services/global/date-converter';
import { Command } from '@models/command.model';

@JsonObject('ElintFunctionType')
export class ElintFunctionType {
  @JsonProperty('elint_function_id', String, true)
  elint_function_id: string = undefined;
  @JsonProperty('elint_function_code', String, true)
  elint_function_code: string = undefined;
  @JsonProperty('elint_function_name', String, true)
  elint_function_name: string = undefined;
  @JsonProperty('create_ts', DateConverter, true)
  dateCreated: Date = undefined;
  @JsonProperty('created_by', String, true)
  createdBy: string = undefined;
  @JsonProperty('update_ts', String, true)
  dateUpdated: Date = undefined;
  @JsonProperty('updated_by', Command, true)
  updatedBy: string = undefined;
  @JsonProperty('delete_ts', DateConverter, true)
  dateDeleted: Date = undefined;
  @JsonProperty('deleted_by', String, true)
  deletedBy: string = undefined;
  @JsonProperty('legacy_id', String, true)
  legacyId: Number = undefined;
  @JsonProperty('command_id', String, true)
  commandId: string = undefined;
  @JsonProperty('processing_tags', String, true)
  processingTags: string = undefined;

  static lookupName(): string {
    return 'elintfunctiontypes';
  }
}
