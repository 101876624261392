<div class="narrow-card">
  <mat-card class="form-card">
    <div class="grid-container">
      <div fxLayout fxLayoutAlign="center center" class="simple-grid-title">
        <span class="grid-title">{{initService.getConfig().titlePrefix}} FDT-M Contact of Interest (COI) Page</span>
      </div>
      <div class="mat-elevation-z8">
        <mat-card-content class="coi">
          <div fxLayout="row" fxLayoutGap="10">
            <div fxFlex="50%" class="panel">
              <div *ngIf="selectedCommand">
                <div *ngIf="cois.length">
                  <h4>Previous {{cois.length > 5 ? '5' : cois.length}} COIs for {{selectedCommand.name}}</h4>
                  <ul style="list-style: none;">
                    <li *ngFor="let coi of cois | slice:0:5;" mat-list-item>Contact # {{coi.pennant}}</li>
                  </ul>
                </div>
                <div *ngIf="!cois.length">
                  <h4>No previous COIs for {{selectedCommand.name}}</h4>
                </div>
              </div>
            </div>

            <div fxFlex="50%" class="panel">
              <h4>Create New COI</h4>
              <br>
              <mat-form-field>
                <mat-select placeholder="Current Command (click to change)" required [(ngModel)]="selectedCommand"
                  [disabled]="!commands" (selectionChange)="onCommandChanged($event)">
                  <mat-option *ngFor="let command of commands" [value]="command">
                    {{command.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field>
                <input matInput placeholder="Enter COI Number" required type="number" min="1"
                  [(ngModel)]="model.nextCOI">
              </mat-form-field>
              <br>
              <button mat-raised-button color="primary" [disabled]="!model.nextCOI || submitted"
                (click)="handleSubmit()">
                Submit
              </button>
              &nbsp;
              <button mat-raised-button (click)="handleCancel()">Cancel</button>
            </div>
          </div>
        </mat-card-content>
      </div>
    </div>
  </mat-card>
</div>
