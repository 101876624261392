import { Injectable } from '@angular/core';
import { AuthorizationApiService } from '../api-services/authorization-api.service';
import { JsonConverter } from '../global/json-converter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthorizationService {
    private jsonConvert = new JsonConverter;

    constructor(private authorizationApiService: AuthorizationApiService) {
        this.jsonConvert = new JsonConverter();
    }

    public getAllPermissions(): Observable<any> {
        return this.authorizationApiService.getAllPermissions()
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    public getPermissionByFeatureId(featureId): Observable<any> {
        return this.authorizationApiService.getPermissionByFeatureId(featureId)
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }
}
