import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('Optempo')
export class Optempo {
    @JsonProperty('group_name', String, true)
    groupName: string = undefined;
    @JsonProperty('count_total_units', Number, true)
    countTotal: number = undefined;
    @JsonProperty('count_underway_units', Number, true)
    countUnderway: number = undefined;

    percentUnderway(): number {
        if (this.countTotal === 0) {
            return 0;
        }
        return this.countUnderway * 100 / this.countTotal;
    }
}
