export class RoutingPaths {

  public static readonly DEFAULT_PATH: string = '';
  public static readonly HOME_PATH: string = 'home';
  public static readonly CERT_LOGIN_PATH: string = 'login/:encodedString';
  public static readonly CERT_SYNC_PATH: string = 'saml/sync/:encodedString';
  // public static readonly LOGIN_PATH: string = 'login';
  public static readonly ANALYSIS_PATH: string = 'analysis';
  public static readonly UNIT_UPDATE_PATH: string = 'units/unit-update';
  public static readonly PARSER_QUEUE_PATH: string = 'units/parser-queue';
  public static readonly PARSER_QUEUE_B_PATH: string = 'units/parser-queue-b';
  public static readonly COI_PATH: string = 'units/coi';
  public static readonly ADMIN_PATH: string = 'admin';
  public static readonly UNITS_PATH: string = 'admin/units';
  public static readonly NOTIFIER_PATH: string = 'admin/notifier';
  public static readonly SERVICE_DASHBOARD_PATH: string = 'admin/service-dashboard';
  public static readonly PARAMETERS_PATH: string = 'admin/parameters';
  public static readonly PREFERENCES_PATH: string = 'admin/preferences';
  public static readonly SLIDE_MAINTENANCE_PATH: string = 'admin/slide-maintenance';
  public static readonly LOOKUP_MAINTENANCE_PATH: string = 'admin/lookup-maintenance';
  public static readonly DATA_TAGS: string = 'admin/data-tags';
  public static readonly ACTIVITY_PATH: string = 'admin/activities';
  public static readonly SOURCE_PATH: string = 'admin/sources';
  public static readonly AREA_PATH: string = 'admin/areas';
  public static readonly COMMANDS_PATH: string = 'admin/commands';
  public static readonly USER_PATH: string = 'admin/users';
  public static readonly CATEGORY_PATH: string = 'admin/categories';
  public static readonly FGI_PATH: string = 'admin/fgis';
  public static readonly FLEET_PATH: string = 'admin/fleets';
  public static readonly GEO_REF_PATH: string = 'admin/geo-refs';
  public static readonly PORT_PATH: string = 'admin/ports';
  public static readonly COUNTRY_PATH: string = 'admin/countries';
  public static readonly SHIP_TYPE_PATH: string = 'admin/ship-type';
  public static readonly CONNECTIONS_PATH: string = 'admin/system-connections';
  public static readonly MESSAGE_CONFIGS_PATH: string = 'admin/message-configs';
  // some analysis paths
  public static readonly PRODUCTS_PATH: string = 'products';
  // public static readonly GOOGLE_EARTH_HISTORY = 'analysis/google-earth-history';
  public static readonly GOOGLE_EARTH_HISTORY = 'analysis/kml';
  public static readonly UNIT_SEARCH_PATH = 'analysis/unit-search';
  public static readonly DATA_CARDS_PATH = 'analysis/data-cards';
  public static readonly BLUE_SEARCH_PATH = 'analysis/blue-search';
  public static readonly GRAFANA = 'analysis/grafana';
  public static readonly CURRENT_NOB_EXCEL = 'products/current-nob-excel';
  public static readonly RECORD_MESSAGE = 'products/record-message';
  public static readonly CURRENT_NOB = 'products/current-nob';
  public static readonly OPTEMPO = 'analysis/optempo';
  public static readonly LOCATOR = 'products/locator';
  public static readonly POWERPOINT = 'products/powerpoint';
  public static readonly RSS = 'products/rss';
  public static readonly PACMIS = 'products/pacmis';
  public static readonly OCP = 'products/ocp';

  public static readonly WILDCARD_PATH: string = '**';

  constructor() {
  }

}
