import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { FomPageBaseComponent } from '@fom-module/base-classes/fom-page-base.component';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { SlideGeneratorService } from '@services/data-services/slide-generator.service';
import { SlideChoice } from '@models/slide-choice.model';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import * as moment from 'moment';
// if you want to interact with the loading popup, use this
// set the message property accordingly
import { LoadingService } from '@services/global/loading/loading.service';
import { take } from 'rxjs/operators';
import { UtilityService } from '@services/utility.service';
import { CountryService } from '@services/data-services/country.service';
import { AppInitService } from '../../../../config/init.service';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-generate-slides',
  templateUrl: './generate-slides.component.html',
  styleUrls: ['./generate-slides.component.css']
})
export class GenerateSlidesComponent extends FomPageBaseComponent implements OnInit {

  @ViewChild(MatSort, {static:  true}) sort: MatSort;
  
  displayedColumns = ['select', 'slideName', 'countryName', 'countUnits'];
  slides: SlideChoice[];
  dataSource = new MatTableDataSource<SlideChoice>();
  selection = new SelectionModel<SlideChoice>(true, []);
  statuses: Array<any>;

  countryMap = {}

  constructor(
    public slideGeneratorService: SlideGeneratorService,
    public snackBar: MatSnackBar,
    public utilities: UtilityService,
    public messageBusService: MessageBusService,
    private countryService: CountryService,
    public route: Router,
    public loadingService: LoadingService,
    public initService: AppInitService) {
    super(messageBusService, route);
  }

  ngOnInit() {
    this.loadSlides();
    this.loadCountries()
    super.ngOnInit();
  }

  loadSlides() {
    this.slideGeneratorService.getAll().pipe(take(1)).subscribe(result => {
      this.slides = result;
      this.dataSource = new MatTableDataSource<SlideChoice>(this.slides);
      this.dataSource.sort = this.sort;
    });
  }

  loadCountries() {
    // get all the countries and create a map
    this.countryService.getAll().pipe(take(1)).subscribe(countries => {
      countries.forEach(c => {
        this.countryMap[c.name] = c;
      });
    })
  }

  handleSubmit() {
    if (this.hasValidSelection()) {
      alert('The slides you are about to generate are not a finished intelligence product.\n\n' +
        'The end user is still required to manipulate each slide to meet end production needs.\n\n');
      const data = this.selection.selected.map(s => {
        return { slide_id: s.slideId };
      });
      const generate = this.slideGeneratorService.generateSlides(data);
      generate.subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            break;
          case HttpEventType.DownloadProgress:
            break;
          case HttpEventType.Response:
            try {
              this.download(event.body);
              this.clearSelection();
            } catch (e) {
              throw e;
            }
            break;
        }
      });
    } else {
      this.snackBar.open('One or more of the selected slides has no underway units. Please only select slides with underway units.',
        'Close');
    }
  }

  hasValidSelection(): boolean {
    return this.hasSelection() && this.selection.selected.filter(s => s.countUnits === 0).length === 0;
  }

  // Checks if all options are selected
  hasAllSelected(): boolean {
    return this.selection.selected.length === this.dataSource.data.length;
  }

  // Checks if user has selected any slides
  hasSelection(): boolean {
    return this.selection.hasValue();
  }

  // Clears selection list
  clearSelection() {
    this.selection.clear();
  }

  // Selects all options
  selectAll() {
    this.dataSource.data.forEach(row => this.selection.select(row));
  }

  // Toggles all selections
  toggleAll() {
    this.hasAllSelected() ? this.clearSelection() : this.selectAll();
  }

  // Downloads API response as a .pptx file.
  download(data: any) {
    const date = moment().utc().format('DDHHmm[Z]_MMM_YY');
    const fileName = `SA_Slides_${date}.pptx`;
    const file = new Blob([data]);
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(file);
    } else {
      const anchor = document.createElement('a');
      anchor.href = window.URL.createObjectURL(file);
      anchor.style.display = 'none';
      anchor.download = fileName;
      document.body.appendChild(anchor);
      anchor.click();
    }
  }
  // check for invalid file types and warn user
  checkFileType(row) {
    // hate to use try/catch but who knows what files are out there....
    try {
      let extension = row.originalFileName.toLowerCase().substr(row.originalFileName.lastIndexOf('.'))
      return ['.jpg', '.png', '.emf', '.wmf', '.jpeg', '.jfif', '.jpe', '.bmp', '.dib', '.rle', '.gif', '.emz', '.wmz', '.tif', '.tiff', '.svg', '.ico'].indexOf(extension) == -1 ? 'This slide has a file that may not be visible in Powerpoint slides! ' : null
    }
    catch (e) {
      return 'This slide has a file that may not be visible in Powerpoint slides! '
    }
  }

  // use the map since we only have country name
  getFlag(country){
    const countryObject = this.countryMap[country]
    return countryObject ? this.countryService.getFlagUrl(countryObject) : null
  }
}


