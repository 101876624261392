import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from './api.config';

@Injectable()
export class ShipTypeApiService {
    SERVER_URL;

    constructor(
        private http: HttpClient, apiConfig: APIConfig) {
        this.SERVER_URL = apiConfig.TARGET_URL;
    }

    public getShipType(params: any): Observable<any> {
        return this.http.post(
            `${this.SERVER_URL}/ship-types/ship-type`, params);
    }

    public getAllShipTypes(): Observable<any> {
        return this.http.get(
            `${this.SERVER_URL}/ship-types`);
    }

    public getShipTypeByShipTypeId(shipTypeId: string): Observable<any> {
        return this.http.get(
            `${this.SERVER_URL}/ship-types/${shipTypeId}`);
    }

    public updateShipType(shipType: any): Observable<any> {
        return this.http.put(
            `${this.SERVER_URL}/ship-types/${shipType.ship_type_id}`, shipType);
    }

    public createShipType(shipType: any): Observable<any> {
        return this.http.post(
            `${this.SERVER_URL}/ship-types`, shipType);
    }

    public deleteShipType(shipType: any): Observable<any> {
        return this.http.delete(
            `${this.SERVER_URL}/ship-types/${shipType.ship_type_id}`);
    }
}
