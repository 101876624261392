import { Component, OnInit, ViewChild } from '@angular/core';
import { FomPageBaseComponent } from '@fom-module/base-classes/fom-page-base.component';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { Router } from '@angular/router';
import { OptempoService } from '@services/data-services/optempo.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Optempo } from '@models/optempo.model';
import { take } from 'rxjs/operators';
import { AppInitService } from '../../../../config/init.service';

@Component({
  selector: 'app-optempo',
  templateUrl: './optempo.component.html',
  styleUrls: ['./optempo.component.css']
})
export class OptempoComponent extends FomPageBaseComponent implements OnInit {
  @ViewChild(MatSort, {static:  true}) sort: MatSort;
  public displayedColumns = ['groupName', 'underwayPercent', 'underwayCount'];

  constructor(public messageBusService: MessageBusService,
    private optempoService: OptempoService,
    public route: Router,
    public initService: AppInitService) {

    super(messageBusService, route);
  }

  ngOnInit() {
    this.optempoService.getOptempoData().pipe(take(1)).subscribe(data => {
      this.dataSource = new MatTableDataSource<Optempo>(data);
      this.dataSource.sort = this.sort;
    });

    super.ngOnInit();
  }
}
