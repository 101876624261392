import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter } from '../services/global/date-converter';
import { Unit } from '@models/unit.model';
import * as moment from 'moment';
import { Elint } from './elint.model';
import { ParserRawMessage } from './parser-raw-message.model';

@JsonObject('ParserQueueEntry')
export class ParserQueueEntry {
    @JsonProperty('parser_id', String, true)
    parserId: string = undefined;
    @JsonProperty('sconum', String, true)
    sconum: string = undefined;
    @JsonProperty('parser_name', String, true)
    name: string = undefined;
    @JsonProperty('geo', String, true)
    geo: string = undefined;
    @JsonProperty('loc_time_ts', DateConverter, true)
    locTimeTs: Date = undefined;
    @JsonProperty('latitude', String, true)
    latitude: string = undefined;
    @JsonProperty('longitude', String, true)
    longitude: string = undefined;
    @JsonProperty('elint', Elint, true)
    elint: Elint = undefined;
    @JsonProperty('el_not', String, true)
    elnot: string = undefined;
    @JsonProperty('originator', String, true)
    originator: string = undefined;
    @JsonProperty('source', String, true)
    source: string = undefined;
    @JsonProperty('classification_name', String, true)
    classification: string = undefined;
    @JsonProperty('current_uw_ind', Boolean, true)
    isUnderway: boolean = undefined;
    @JsonProperty('link', String, true)
    link: string = undefined;
    @JsonProperty('entries', String, true)
    points: string = undefined;
    @JsonProperty('msg_source', String, true)
    messageSource: string = undefined;
    @JsonProperty('create_ts', DateConverter, true)
    createTs: Date = undefined;
    @JsonProperty('created_by', String, true)
    createdBy: string = undefined;
    @JsonProperty('activity', String, true)
    activity: string = undefined;
    @JsonProperty('activity_category_id', String, true)
    activityCategoryId: string = undefined;
    @JsonProperty('op_area_id', String, true)
    opAreaId: string = undefined;
    @JsonProperty('country_name', String, true)
    countryName: string = undefined;
    @JsonProperty('unit', Unit, true)
    unit: Unit = undefined;
    @JsonProperty('unit_id', String, true)
    unitId: string = undefined;
    // Used to pass an overriden source value from parser queue component
    @JsonProperty('source_id', String, true)
    sourceId: string = undefined;
    @JsonProperty('parser_msg_id', String, true)
    parserMsgId: string = undefined;
    @JsonProperty('parser_msg_pos_jpath', String, true)
    parserMsgPosPath: string = undefined;
    @JsonProperty('source_code', String, true)
    sourceCode: string = undefined;
    @JsonProperty('uid', String, true)
    uid: string = undefined;
    @JsonProperty('owning_command_id', String, true)
    owningCommandId: string = undefined;
    @JsonProperty('major_axis_bearing', String, true)
    majorAxisBearing: string = undefined;
    @JsonProperty('semi_major_axis_length', String, true)
    semiMajorAxisLength: string = undefined;
    @JsonProperty('semi_minor_axis_length', String, true)
    semiMinorAxisLength: string = undefined;
    @JsonProperty('force_code', String, true)
    forceCode: string = undefined;
    @JsonProperty('confidence_code', String, true)
    confidenceCode: string = undefined;
    @JsonProperty('needs_review', Boolean, true)
    needsReview: boolean = undefined;
    @JsonProperty('borrower_command_id', String, true)
    borrowerCommandId: string = undefined;

    formattedDate() {
        return moment(this.locTimeTs).utc().format('DDHHmm[Z] MMM YYYY');
    }

    rawMessage: ParserRawMessage = null
}
