import { BlueLocation } from '@models/blue-location.model';
import { BlueLocationApiService } from './../api-services/blue-location-api.service';
import { Injectable } from '@angular/core';
import { JsonConverter } from '../global/json-converter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable()
export class BlueLocationService {
    private jsonConvert: JsonConverter;

    constructor(private blueLocationApiService:BlueLocationApiService) {
        this.jsonConvert = new JsonConverter();
    }
  
    // get all blue locations, not a good idea
    public getAll(filters = {}): Observable<BlueLocation[]> {
        return this.blueLocationApiService.getAll(filters)
            .pipe(
                map((data) => {
                    // console.log(data);
                    return this.jsonConvert.deserializeArray(data, BlueLocation);
                })
            );
    }
    // get the current location for blue units
    public getCurrentLocations(filters = {}): Observable<BlueLocation[]> {
        return this.blueLocationApiService.getCurrentLocations(filters)
            .pipe(
                map((data) => {
                    // console.log(data);
                    return this.jsonConvert.deserializeArray(data, BlueLocation);
                })
            );
    }
    // get the current location for blue units
    public getLocationsPage(filters = {}): Observable<any> {
        return this.blueLocationApiService.getLocationsPage(filters)
            .pipe(
                map((data) => {
                    return {
                        count: data.count,
                        locations: this.jsonConvert.deserializeArray(data.rows, BlueLocation)
                    }
                })
            );
    }
}
