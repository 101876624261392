import {JsonObject, JsonProperty, Any} from 'json2typescript';
import { DateConverter } from '../services/global/date-converter';
import { Unit } from '@models/unit.model';
import { Command } from '@models/command.model';
// import { Location } from '@models/location.model';

@JsonObject('AuditUnitBorrowed')
export class AuditUnitBorrowed {
  @JsonProperty('audit_id', String, true)
  auditId: string = undefined;
  @JsonProperty('unit_id', String, true)
  unitId: string = undefined;
  @JsonProperty('unit', Unit, true)
  unit: Unit = undefined;
  @JsonProperty('command_id', String, true)
  commandId: string = undefined;
  @JsonProperty('borrower_command_id', String, true)
  borrowerCommandId: string = undefined;
  @JsonProperty('command', Command, true)
  command: Command = undefined;
  @JsonProperty('created_by', String, true)
  createdBy: string = undefined;
  @JsonProperty('create_ts', DateConverter, true)
  dateCreated: Date = undefined;
}
