import { FormBuilder } from '@angular/forms';
import { EditModalBaseComponent } from '@fom-module/base-classes/edit-modal-base.component';
import { VesselTypeService } from '@services/data-services/vessel-type.service';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { Component, OnInit } from '@angular/core';
import { LookUpService } from '@services/data-services/lookup.service';
import { Command } from '@models/command.model';
import { MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { UtilityService } from '@services/utility.service';
import { CommandHierarchyService } from '@services/data-services/command-hierarchy.service';
import { User } from '@models/user.model';
import { CurrentUserService } from '@services/current-user-service';
import { CommandHierarchy } from '@models/command-hierarchy.model';

@Component({
  selector: 'app-edit-vessel-type-modal',
  templateUrl: './edit-vessel-type-modal.component.html',
  styleUrls: ['./edit-vessel-type-modal.component.css']
})
export class EditVesselTypeModalComponent extends EditModalBaseComponent implements OnInit {
  commandOptions: Command[];
  user: User;
  commandHierarchy: CommandHierarchy;

  flag: string = '';

  constructor(public vesselTypeService: VesselTypeService,
    public lookUpService: LookUpService, public utilities: UtilityService,
    public formBuilder: FormBuilder,
    private commandHierarchyService: CommandHierarchyService,
    public dialogRef: MatDialogRef<EditVesselTypeModalComponent>,
    public messageBusService: MessageBusService,
    private currentUserService: CurrentUserService) {
    super(vesselTypeService, dialogRef);
  }

  ngOnInit() {
    this.headerString = this.isNew ? 'Add Vessel Class' :
    this.isDelete ? 'Delete Vessel Class' :
    'Edit Vessel Class'
    this.user = this.currentUserService.getCurrentUser();
    if (this.isNew) {
      this.entity.commandId = this.user.currentJFMCC ? this.user.currentJFMCC.commandId : this.user.commandId;
    }
    this.editForm = this.formBuilder.group({
      vesselType: [{ value: this.entity.vesselType, disabled: this.isDelete }],
      vesselTypeDescription:  [{ value: this.entity.vesselTypeDescription, disabled: this.isDelete }],
      commandId: [{ value: this.entity.commandId, disabled: this.isDelete }],
      priority: [{ value: this.entity.priority, disabled: this.isDelete }],
    });
    Promise.resolve().then(() => {
      this.loadLookups();
    });
  }

  loadLookups() {
    this.lookUpService.getLookupByType(Command, true).pipe(take(1)).subscribe(result => {
      this.commandHierarchyService.getAll({ sort: 'version_num', limit: 1 }).pipe(take(1)).subscribe(json => {
        if (json && Array.isArray(json) && json.length) {
          this.commandHierarchy = json[0];

          let jfmccs;
          if (this.user.isSystemAdmin()) {
            jfmccs = this.commandHierarchy.getJFMCCs();
          } else {
            jfmccs = this.commandHierarchy.getJFMCCIds(this.user.command);
          }

          this.commandOptions = result.filter(item => {
            return jfmccs.includes(item.commandId);
          });
        } else {
          this.commandOptions = result;
        }
      });
    });
  }

  checkIDkey(event) {
    const value = this.editForm.controls.priority.value
    if (value && value.length && isNaN(value)) {
      this.editForm.controls.priority.setErrors({'numeric only': true});
    }
    else {
      const error = this.editForm.controls.priority.hasError('numeric only')
      if (error) {
        this.editForm.controls.priority.setErrors({'numeric only': null});
      }
    }
  }

}
