import { JsonObject, JsonProperty, Any } from 'json2typescript';
import { Command } from '@models/command.model';
import { VesselType } from './vessel-type.model';
import { ShipClass } from './ship-class.model';

@JsonObject('ShipType')
export class ShipType {
  @JsonProperty('ship_type_id', String, true)
  shipTypeId: string = undefined;
  @JsonProperty('ship_type_name', String, true)
  shipTypeName: string = undefined;
  @JsonProperty('legacy_rank', Number, true)
  legacyRank: number = undefined;
  @JsonProperty('remarks', String, true)
  remarks: string = undefined;
  @JsonProperty('command_id', String, true)
  commandId: string = undefined;
  @JsonProperty('ship_class_id', String, true)
  shipClassId: string = undefined;
  @JsonProperty('vessel_type_id', String, true)
  vesselTypeId: string = undefined;
  @JsonProperty('command', Command, true)
  command: Command = undefined;
  @JsonProperty('created_by', String, true)
  createdBy: string = undefined;
  @JsonProperty('lookup_filter_id', String, true)
  lookupFilterId: string = undefined;
  @JsonProperty('sort_sequence', String, true)
  sortSequence: number = undefined;
  @JsonProperty('ship_class_name', String, true)
  shipClassName: string = undefined;
  @JsonProperty('vessel_type_name', String, true)
  vesselTypeName: string = undefined;
  @JsonProperty('vessel_type', VesselType, true)
  vesselType: VesselType = undefined;
  @JsonProperty('ship_class', ShipClass, true)
  shipClass: ShipClass = undefined;

  static lookupName(): string {
    return 'types';
  }
}
