import { Any, JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter } from '../services/global/date-converter';
import { Command } from '@models/command.model';
import { Fleet } from '@models/fleet.model';
import { Unit } from '@models/unit.model'

@JsonObject('Port')
export class Port {
  @JsonProperty('port_id', String, true)
  portId: string = undefined;
  @JsonProperty('port_name', String, true)
  name: string = undefined;
  @JsonProperty('port_code', String, true)
  code: string = undefined;
  @JsonProperty('dec_lat', Number, true)
  decLat: number = undefined;
  @JsonProperty('dec_lon', Number, true)
  decLon: number = undefined;
  @JsonProperty('command_id', String, true)
  commandId: string = undefined;
  @JsonProperty('command', Command, true)
  command: Command = undefined;
  @JsonProperty('fleet_id', String, true)
  fleetId: string = undefined;
  @JsonProperty('fleet', Fleet, true)
  fleet: Fleet = undefined;
  @JsonProperty('created_by', DateConverter, true)
  createdBy: string = undefined;
  @JsonProperty('create_ts', String, true)
  dateCreated: Date = undefined;
  @JsonProperty('lookup_filter_id', String, true)
  lookupFilterId: string = undefined;
  @JsonProperty('sort_sequence', String, true)
  sortSequence: number = undefined;
  // Using 'any' to avoid circular imports
  @JsonProperty('units', [Any], true)
  units: Unit[] = [];

  static lookupName(): string {
    return 'ports';
  }

  static primaryKey(): string {
    return 'portId'
  }
  groupProperty(): string {
    return this.name
  }
}
