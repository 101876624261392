import { DummyApiService } from './../api-services/dummy-api.service';
import { Injectable } from '@angular/core';
import { JsonConverter } from '../global/json-converter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class DummyDataService {
    //private jsonConvert: JsonConverter;

    constructor() {
        //this.jsonConvert = new JsonConverter();
    }

}
