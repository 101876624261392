import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from '@services/api-services/api.config';

@Injectable()
export class SourceApiService {
    SERVER_URL;

    constructor(
        private http: HttpClient, apiConfig: APIConfig) {
        this.SERVER_URL = apiConfig.TARGET_URL;
    }

    public getAllSources(filters = {}): Observable<any> {
        let httpParams = new HttpParams();

        for (const key in filters) {
            if (filters.hasOwnProperty(key)) {
                httpParams = httpParams.set(key, filters[key]);
            }
        }

        return this.http.get(`${this.SERVER_URL}/sources`, { params: httpParams });
    }

    public getSourceBySourceId(sourceId: string): Observable<any> {
        return this.http.get(
            `${this.SERVER_URL}/sources/${sourceId}`);
    }

    public updateSource(source: any): Observable<any> {
        return this.http.put(
            `${this.SERVER_URL}/sources/${source.source_id}`, source);
    }

    public createSource(source: any): Observable<any> {
        return this.http.post(
            `${this.SERVER_URL}/sources`, source);
    }

    public deleteSource(source: any): Observable<any> {
        return this.http.delete(
            `${this.SERVER_URL}/sources/${source.source_id}`);
    }
}
