import { ClassMarkingCategory } from '@models/class-marking-category.model';
import { ClassMarkingCategoryApiService } from '@services/api-services/class-marking-category-api.service';
import { Injectable } from '@angular/core';
import { JsonConverter } from '@services/global/json-converter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ClassMarkingCategoryService {
    private jsonConvert: JsonConverter;

    constructor(private classMarkingCategoryApiService: ClassMarkingCategoryApiService) {
        this.jsonConvert = new JsonConverter();
    }

    public getAll(): Observable<ClassMarkingCategory[]> {
        return this.classMarkingCategoryApiService.getAllClassMarkingCategories()
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, ClassMarkingCategory);
                })
            );
    }

    public getClassMarkingCategoryById(classMarkingCategoryId: string): Observable<ClassMarkingCategory> {
        return this.classMarkingCategoryApiService.getClassMarkingCategoryById(classMarkingCategoryId)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, ClassMarkingCategory);
                })
            );
    }

    public update(classMarkingCategoryId: ClassMarkingCategory): Observable<ClassMarkingCategory> {
        return this.classMarkingCategoryApiService.updateClassMarkingCategory(this.jsonConvert.serializeObject(classMarkingCategoryId))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, ClassMarkingCategory);
                })
            );
    }

    public create(classMarkingCategoryId: ClassMarkingCategory): Observable<ClassMarkingCategory> {
        return this.classMarkingCategoryApiService.createClassMarkingCategory(this.jsonConvert.serializeObject(classMarkingCategoryId))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, ClassMarkingCategory);
                })
            );
    }

    public delete(classMarkingCategoryId: ClassMarkingCategory): Observable<ClassMarkingCategory> {
        return this.classMarkingCategoryApiService.deleteClassMarkingCategory(this.jsonConvert.serializeObject(classMarkingCategoryId))
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }
}
