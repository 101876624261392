import { Component, OnInit } from '@angular/core';
import { Command } from '@models/command.model';
import { LookUpService } from '@services/data-services/lookup.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { pointOfContact as Poc } from '@models/point-of-contact.model'
import { PocService } from '@services/data-services/point-of-contact-data.service';
import { JsonConverter } from '@services/global/json-converter';

@Component({
  selector: 'app-edit-poc-modal',
  templateUrl: './edit-poc-modal.component.html',
  styleUrls: ['./edit-poc-modal.component.css']
})
export class EditPocModalComponent implements OnInit {
  private jsonConvert: JsonConverter;
  isNew: boolean;
  poc: Poc;
  requiredMessage: string;
  commandOptions: Command[]
  currentCommand: string
  headerString: string;

  constructor(private pocService: PocService,
    public lookUpService: LookUpService,
    // private jsonConvert: JsonConverter,
    public dialogRef: MatDialogRef<EditPocModalComponent>) {
      this.jsonConvert = new JsonConverter();
    }

  ngOnInit() {
    this.headerString = this.isNew ? 'Add Point of Contact' :
      'Edit Point of Contact'
    Promise.resolve().then(() => {
      this.lookUpService.getLookupByType(Command, true).subscribe(result => {
        this.commandOptions = [{commandId: null, name: ' -- Select / Clear --', noSelection: true}, ...result];
      });
    })

    if(this.poc.commandId === null ||
      this.poc.commandId === "" ||
      this.poc.commandId === undefined ||
      this.isNew){
      this.poc.commandId = JSON.parse(sessionStorage.user).command_id
    }
  }

  baseRequirements(){
    if (!this.poc){return false}
    // name and org not set or blanked
    if( (this.poc.contactName === undefined && this.poc.contactPosition === undefined)||
        (this.poc.contactName === "" && this.poc.contactPosition === "")) {
      this.requiredMessage = 'Contact needs a Name or a Position'
      return false
    }
    // contact details
    if ((this.poc.phone === undefined &&
        this.poc.email === undefined &&
        this.poc.link === undefined) || (this.poc.phone === "" &&
        this.poc.email === "" &&
        this.poc.link === "")) {
      this.requiredMessage = "Need a way to contact this Person/Organization. Email, Phone, or Link"
      return false
    }
    return true
  }

  submit(){
    this.poc.createdBy = JSON.parse(sessionStorage.user).user_id
    let data = this.jsonConvert.serializeObject(this.poc)

    if(data.command_id === null ||
      data.command_id === "" ||
      data.command_id === undefined ||
      data.command.command_id === undefined
    ){
      data.command_id = JSON.parse(sessionStorage.user).command_id
    }else{
      data.command_id = data.command.command_id
    }

    if(this.baseRequirements()){
      if (this.isNew) {
        this.pocService.create(data).subscribe(response =>  {
          this.dialogRef.close(response)
        })
      }
      else {
        this.pocService.updatePoc(this.poc.pocId, data).subscribe(response =>  {
            this.dialogRef.close(response)
        })
      }
    }
  }

  cancel() {
    this.dialogRef.close()
  }
}