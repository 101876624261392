import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from '@services/api-services/api.config';

@Injectable()
export class ClassMarkingCategoryApiService {
  SERVER_URL;

  constructor(
    private http: HttpClient, apiConfig: APIConfig) {
    this.SERVER_URL = apiConfig.TARGET_URL;
  }

  public getAllClassMarkingCategories(): Observable<any> {
    return this.http.get(
      `${this.SERVER_URL}/class-mark-cats`);
  }

  public getClassMarkingCategoryById(classMarkingCategoryId: string): Observable<any> {
    return this.http.get(
      `${this.SERVER_URL}/class-mark-cats/${classMarkingCategoryId}`);
  }


  public updateClassMarkingCategory(classMarkingCategory: any): Observable<any> {
    return this.http.put(
      `${this.SERVER_URL}/class-mark-cats/${classMarkingCategory.class_marking_category_id}`, classMarkingCategory);
  }

  public createClassMarkingCategory(classMarkingCategory: any): Observable<any> {
    return this.http.post(
      `${this.SERVER_URL}/class-mark-cats`, classMarkingCategory);
  }

  public deleteClassMarkingCategory(classMarkingCategory: any): Observable<any> {
    return this.http.delete(
      `${this.SERVER_URL}/class-mark-cats/${classMarkingCategory.class_marking_category_id}`);
  }

}
