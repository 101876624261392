<app-classification-bar class="top-modal-classification-bar"></app-classification-bar>
<app-fdtm-modal-header [headerString]="headerString"></app-fdtm-modal-header>
<div mat-dialog-content>
<form [formGroup]="editForm">
  <!--mat-form-field>
    <input matInput placeholder="ID" required formControlName="opAreaId">
  </mat-form-field>
  <br-->
  <mat-form-field class="input-full-width">
    <mat-select placeholder="Command" formControlName="commandId">
      <mat-option *ngFor="let option of commandOptions" [value]="option.commandId">{{option.name}}</mat-option>
    </mat-select>
  </mat-form-field>
  <br>
  <mat-form-field class="input-full-width">
    <input matInput (keyup)="checkDups()" placeholder="OpArea Name" required formControlName="opArea">
    <mat-error *ngIf="editForm?.controls?.opArea?.hasError('duplicate entry')">duplicate entry w/country</mat-error>
  </mat-form-field>
  <br>
  <br>
  <mat-form-field class="input-full-width">
    <input matInput autocomplete="off"
      placeholder="Report Group" formControlName="reportGroup">
  </mat-form-field>
  <br>
  <app-lookup-select (selectedValueChanged)="onCountryChange($event);checkDups()" [itemPK]="'name'" [selectClass]="'input-full-width'"
    [itemId]="entity.remarks" [typeName]="'Country'" [keyProperty]="'name'" [flagProperty]="'name'"
    [disabled]="isDelete">
  </app-lookup-select>

  <div mat-dialog-actions>
    <button *ngIf="!isDelete" [disabled]="!editForm.valid || !entity.remarks" mat-raised-button color="primary"
      (click)="handleSubmit()">Submit</button>
    <button *ngIf="isDelete" mat-raised-button color="warn" (click)="handleDelete()">Delete</button>
    <button mat-raised-button (click)="handleCancel()">Cancel</button>
  </div>
</form>
</div>
<app-classification-bar class="bottom-modal-classification-bar"></app-classification-bar>
