import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { EditModalBaseComponent } from '@fom-module/base-classes/edit-modal-base.component';
import { OtherControlService } from '@services/data-services/other-control.service';
import { FriendlyErrorService } from '@services/friendly-error.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-edit-other-control-modal',
  templateUrl: './edit-other-control-modal.component.html',
  styleUrls: ['./edit-other-control-modal.component.css']
})
export class EditOtherControlModalComponent extends EditModalBaseComponent implements OnInit {

  editForm: FormGroup;

  constructor(public otherControlService: OtherControlService,
    public formBuilder: FormBuilder, private friendlyErrorService: FriendlyErrorService,
    public dialogRef: MatDialogRef<EditOtherControlModalComponent>) {
    super(otherControlService, dialogRef);
  }

  ngOnInit() {
    this.headerString = this.isNew ? 'Add Other Control' : this.isDelete ? 'Delete Other Control' : 'Edit Other Control'
    this.editForm = this.formBuilder.group({
      description: [{ value: this.entity.description, disabled: this.isDelete }]
    });
  }

  localDelete() {
    this.otherControlService.delete(this.entity).pipe(take(1)).subscribe(res => {
      this.dialogRef.close(res)
      //this.messageBusService.publishMessage(new GlobalBusMessage(GlobalMessageTriggers.CLOSE_MODAL));
    }, err => {
      this.friendlyErrorService.processError(err);
    });
  }


}
