import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Activity } from '@models/activity.model';
import { Area } from '@models/area.model';
import { Category } from '@models/category.model';
import { Country } from '@models/country.model';
import { Fleet } from '@models/fleet.model';
import { Port } from '@models/port.model';
import { ShipType } from '@models/ship-type.model';
import { Source } from '@models/source.model';
import { Unit } from '@models/unit.model';
import { Command } from '@models/command.model';
import { AppInitService } from '../../../config/init.service';
import { CountryService } from './data-services/country.service';
import * as moment from 'moment';
import { Elnot } from '@models/elnot.model';
import { ShipClass } from '@models/ship-class.model';
import { VesselType } from '@models/vessel-type.model';
import { FreqType } from "@models/freq-type.model";
import { ScanType } from "@models/scan-type.model";
import { ElintFunctionType } from "@models/elint-function-type.model";
import { ConfidenceCode } from '@models/confidence-code.model';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  HIGH_VALUES = 999999;

  LOWERCASE_REGEX = /[a-z]/g;
  UPPERCASE_REGEX = /[A-Z]/g;
  NUMBER_REGEX = /[0-9]/g;
  SYMBOL_REGEX = /[-#!$@%^&*()_+|~=`{}\[\]:";'<>?,.\/ ]/g;

  passwordRules: any;

  constructor(public initService: AppInitService,
    private countryService: CountryService) {
    this.passwordRules = initService.getConfig().passwordComplexity;
  }

  // Standardize date format
  momentToString = (value) => {
    if (moment(value).isValid()){
      return moment(value).format("YYYY-MM-DDTHH:mm")
    } else {
      return value.toString().slice(0, 16)
    }
  }

  validateLastLoc = (dtgImaged, locTime) => {
       return this.momentToString(dtgImaged) <= this.momentToString(locTime)
  }

  locTimeAfterDTGImagedValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const dtgImaged = control.get('dtgImaged').value;
    const locationTime = control.get('locTime').value;

    return dtgImaged && locationTime && !this.validateLastLoc(dtgImaged, locationTime) ?
      { locationTimeBeforeLastImaged: true } : null;
  }

  latValidator = (control: FormControl): ValidationErrors => {
    let latitude = control.value;
    if (!latitude || !latitude.length || !latitude.trim().length) {
      return { required: true };
    }

    // do some decimal checking
    const dot = latitude.indexOf('.');
    if (dot > -1) {
      try {  // let's not crash over some strang input....
        const dec_lat = Number(latitude);
        if (isNaN(dec_lat)) {
          return { format: true };
        }
        if (dec_lat > 90 || dec_lat < -90) {
          return { range: true };
        }
        return {};
      } catch (e) {
        return { format: true };
      }
    }

    if (latitude.length !== 5 && latitude.length !== 7) {
      return { length: true };
    }
    if (!(latitude.endsWith('N') || latitude.endsWith('S'))) {
      return { format: true };
    }
    if (!latitude.slice(0, -1).match(/^[0-9]+$/)) {
      return { format: true }
    }
    if (this.decimalLatitude(latitude) > 90 || this.decimalLatitude(latitude) < -90) {
      return { range: true };
    }
    return {};
  }

  lonValidator = (control: FormControl): ValidationErrors => {
    let longitude = control.value;
    if (!longitude || !longitude.length || !longitude.trim().length) {
      return { required: true };
    }
    // do some decimal checking
    const dot = longitude.indexOf('.');
    if (dot > -1) {
      try {  // let's not crash over some strang input....
        const dec = Number(longitude);
        if (isNaN(dec)) {
          return { format: true };
        }
        if (dec > 180 || dec < -180) {
          return { range: true };
        }
        return {};
      } catch (e) {
        return { format: true };
      }
    }

    if (longitude.length !== 6 && longitude.length !== 8) {
      return { length: true };
    }
    if (!(longitude.endsWith('E') || longitude.endsWith('W'))) {
      return { format: true };
    }
    if (!longitude.slice(0, -1).match(/^[0-9]+$/)) {
      return { format: true }
    }
    if (this.decimalLongitude(longitude) > 180 || this.decimalLongitude(longitude) < -180) {
      return { range: true };
    }
    return {};
  }

  freqValidator = (control: FormControl): ValidationErrors => {
    let freq = control.value;
    const freqRegex = /^\d*(\.\d+)?([.,]\d+)?(HZ|KHZ|MHZ|GHZ)$/i;
    if (!isNaN(freq)) {
      return {noUnit: true}
    }
    if(freqRegex.test(freq)) {
      // dont throw an error if the input matches
      return null
    } else {
      return {invalidFreq: true}
    }
  }

  passwordValidator = (control: FormControl): ValidationErrors => {
    const password = control.value;

    if (!password || password.length < this.passwordRules.minLength) {
      return { length: true };
    }

    if (!this.isComplexEnough(password)) {
      return { complexity: true };
    }

    return {};
  }

  jsonValidator = (control: FormControl): ValidationErrors => {
    try {
      JSON.parse(control.value);
    } catch (e) {
      return { jsonInvalid: true };
    }

    return {};
  }

  public getDMS(dd, longOrLat) {
    const absoluteValue = Math.abs(dd);
    const degrees = Math.floor(absoluteValue);
    let minutes = Math.floor((absoluteValue - degrees) * 60);
    // Round is more accurate for seconds than floor, numbers closer to 60 are unaffected, closer to
    // 0 is more accurate
    let seconds = Math.round((absoluteValue - degrees - minutes / 60) * 3_600);
    // Handle if seconds is rounded up to >= a minute.
    if (seconds >= 60) {
      seconds = seconds - 60;
      minutes += 1;
    }

    let hemisphere: string;
    let stringDegrees: string;
    switch (longOrLat) {
      case 'lat':
        hemisphere = dd > 0 ? 'N' : 'S';
        stringDegrees = `00${degrees}`.slice(-2);
        break;
      case 'lon':
        hemisphere = dd > 0 ? 'E' : 'W';
        stringDegrees = `000${degrees}`.slice(-3);
        break;
    }
    const stringMinutes = `00${minutes}`.slice(-2);

    // Hide seconds if there aren't any.
    let stringSeconds = '';
    if (seconds !== 0) {
      stringSeconds = `00${seconds}`.slice(-2);
    }

    return `${stringDegrees}${stringMinutes}${stringSeconds}${hemisphere}`;
  }

  decimalLatitude(latitude): number {
    let decLat;
    const degrees = parseInt(latitude.substr(0, 2), 10);
    const minutes = parseInt(latitude.substr(2, 2), 10) / 60;

    if (latitude.length === 7) {
      const seconds = parseInt(latitude.substr(4, 2), 10) / 3600;
      decLat = degrees + minutes + seconds;
    } else {
      decLat = degrees + minutes;
    }

    if (latitude.substr(-1).toUpperCase() === 'S') {
      decLat *= -1;
    }
    return decLat;
  }

  decimalLongitude(longitude): number {
    let decLon;
    const degrees = parseInt(longitude.substr(0, 3), 10);
    const minutes = parseInt(longitude.substr(3, 2), 10) / 60;

    if (longitude.length === 8) {
      const seconds = parseInt(longitude.substr(5, 2), 10) / 3600;
      decLon = degrees + seconds + minutes;
    } else {
      decLon = degrees + minutes;
    }

    if (longitude.substr(-1).toUpperCase() === 'W') {
      decLon *= -1;
    }

    return decLon;
  }

  // use the unit's country name to get a local asset
  public getFlag(unit: Unit) {
    if (!unit || !unit.country || !unit.country.name) {
      return null;
    }
    return this.countryService.getFlagUrl(unit.country)
  }

  // use the unit's country name to get a local asset
  public getCountryFlag(country) {
    return this.countryService.getFlagUrl(country)
  }

  public getTypeFromName(typeName: string) {
    typeName = (typeName || '').toLowerCase();
    switch (typeName) {
      case 'country':
      case 'associated country':
      case 'base flag':
      case 'country code':
        return Country;
      case 'activity category':
      case 'activity':
        return Activity;
      case 'fleet':
      case 'home fleet':
      case 'fleet area':
        return Fleet;
      case 'u/w area':
      case 'area':
      case 'op area':
        return Area;
      case 'source':
        return Source;
      case 'command':
        return Command;
      case 'port':
      case 'home port':
        return Port;
      case 'category':
        return Category;
      case 'ship type':
        return ShipType;
      case 'elnot':
        return Elnot;
      case 'ship class':
        return ShipClass;
      case 'vessel type':
        return VesselType;
      case "freq type":
        return FreqType;
      case "scan type":
        return ScanType;
      case "function type":
        return ElintFunctionType;
      case 'confidence code':
        return ConfidenceCode;
    }
  }

  // Public function to check complexity on a password.
  public isComplexEnough(string: string): boolean {
    const classCounts = this.countCharacterClasses(string);
    if (classCounts.lowercase < this.passwordRules.numLowercase ||
      classCounts.uppercase < this.passwordRules.numUppercase ||
      classCounts.number < this.passwordRules.numNumber ||
      classCounts.symbol < this.passwordRules.numSymbol) {
      return false;
    }

    return true;
  }

  private countCharacterClasses(string: string): any {
    return {
      lowercase: this.countCharacters(string, this.LOWERCASE_REGEX),
      uppercase: this.countCharacters(string, this.UPPERCASE_REGEX),
      number: this.countCharacters(string, this.NUMBER_REGEX),
      symbol: this.countCharacters(string, this.SYMBOL_REGEX)
    };
  }

  private countCharacters(string: string, regex: RegExp): number {
    return (string.match(regex) || []).length;
  }

  public getNOBColumnHeaders() {
    return {
      'classification_string': { columnName: 'Classification' },
      'fleet_code': { columnName: 'Fleet' },
      'final_name': { columnName: 'Name' },
      'new_days': { columnName: 'Days Out', type: 'number' },
      'dtg_imaged_ts': { columnName: 'U/W DTG', type: 'date' },
      'last_loc': { columnName: 'Last Location' },
      'latitude': { columnName: 'Lat' },
      'longitude': { columnName: 'Lon' },
      'source': { columnName: 'Source' },
      'el_not_code': { columnName: 'ELNOT' },
      'originator': { columnName: 'Originator' },
      'loc_time_ts': { columnName: 'Location Time', type: 'date' },
      'latency': { columnName: 'Time Late (hours)' },
      'port_name': { columnName: 'Home Port' },
      'category_description': { columnName: 'Category' },
      'activity': { columnName: 'Activity' },
      'papa_case': { columnName: 'Case No' },
      'op_area_title': { columnName: 'Op Area' },
    };

  }
}
