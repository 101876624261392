import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { GridBaseComponent } from '@fom-module/base-classes/grid-base.component';
import { EditCollaborationModalComponent } from '@fom-module/edit-modals/edit-collaboration-modal/edit-collaboration-modal.component';
import { Classification } from '@models/classification.model';
import { CPage } from '@models/cpage.model';
import { Unit } from '@models/unit.model';
import { CurrentUserService } from '@services/current-user-service';
import { CPageService } from '@services/data-services/cpage.service';
import { LookUpService } from '@services/data-services/lookup.service';
import { UnitService } from '@services/data-services/unit.service';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { UtilityService } from '@services/utility.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppInitService } from '../../../config/init.service';

@Component({
  selector: 'app-collaboration-portal',
  templateUrl: './collaboration-portal.component.html',
  styleUrls: ['./collaboration-portal.component.css', '../base-classes/grid-base.component.css']
})
export class CollaborationPortalComponent extends GridBaseComponent implements OnInit, OnDestroy {

  cpageEntries: CPage[] = [];
  subscription: Subscription;
  user: any;
  filterValue: string;
  filteredUnits: Unit[];
  isLoading = false;
  selectedUnit: Unit;

  dataSource = new MatTableDataSource<CPage>([]);
  gridColumns = ['classification', 'name', 'comments', 'submitter', 'dateCreated', 'docName'];

  editForm: FormGroup;

  //classifications: Classification[];

  constructor(public messageBusService: MessageBusService,
    public cpageService: CPageService,
    public route: Router,
    private lookupService: LookUpService,
    private unitService: UnitService,
    public formBuilder: FormBuilder,
    private dialog: MatDialog,
    public utilities: UtilityService,
    private snackBar: MatSnackBar,
    private currentUserService: CurrentUserService,
    private activatedRoute: ActivatedRoute,
    public initService: AppInitService) {
    super(messageBusService, cpageService, route);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  ngOnInit() {
    this.user = this.currentUserService.getCurrentUser();
    if (this.user.isSupervisor()) {
      this.gridColumns.push('delete')
    }
    this.dataSource.filterPredicate = this.customFilterPredicate.bind(this);
    // this.lookupService.getLookupByType(Classification, true).pipe(take(1)).subscribe(results => {
    //   this.classifications = results
    // })

    // lets redirect to add another OCP entry if the URL came in with a unit id
    // this would be the scenario from external sources such as KML links
    this.subscription = this.activatedRoute
      .queryParamMap
      .subscribe(params => {
        let unitId = params.get('u') || '';
        this.loadEntry(unitId)
        if (unitId.length) {
          this.add(unitId)
        }
        else {
          this.loadAll()
        }
    });
  }

  // we only want to filter on unit name, not all
  customFilterPredicate(data: any, filter: string): boolean {
    const lower = data.unit.fullName().toLocaleLowerCase()
    return lower.indexOf(filter) > -1
  }

  sortData(sort) {
    this.cpageEntries.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      return this.compare(a, b, isAsc, sort.active);
    });
    this.dataSource.data = this.cpageEntries
  }
  compare(a, b, isAsc, active) {
    return (a[active] < b[active] ? -1 : 1) * (isAsc ? 1 : -1);
  }
  // autocomplete or type ahead on the unit search form.
  displayUnit(unit?: Unit): string | undefined {
    return unit ? unit.fullName() : undefined;
  }

  unitSelected(event) {
    if (event && event.option && event.option.value) {
      this.loadEntry(event.option.value.unitId)
    }
  }
  // load up the entry in the CPage table
  loadEntry(unitId) {
    if (unitId && unitId.length) {
      this.cpageService.getAll({unit_id: unitId}).pipe(take(1)).subscribe(results => {
        results.forEach(element => {
          (element as any).name = element.unit.fullName()
        })
        this.cpageEntries = results
        this.dataSource.data = results
      }, err => {
        // bad parameter in the URL? what to do....
        this.cpageEntries = []
        this.dataSource.data = []
      })
    }
    else {
      this.cpageEntries = []
      this.dataSource.data = []
    }
  }

  // get all cpage entries to display in grid
  // we get the submitter's current user object just in case their info changes...
  loadAll() {
      this.cpageService.getAll().pipe(take(1)).subscribe(results => {
        results.forEach(element => {
          (element as any).name = element.unit.fullName()
        })
        this.cpageEntries = results
        this.dataSource.data = results
      }, err => {
        // bad parameter in the URL? what to do....
        this.cpageEntries = []
        this.dataSource.data = []
      })
    //})
  }

  unitFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  add(unitId) {
    let cpage = new CPage()
    if (unitId && unitId.length) {
      cpage.unitId = unitId
      this.unitService.getUnitByUnitId(unitId).pipe(take(1)).subscribe(unit => {
        cpage.unit = unit
        this.openDialog(cpage)
      }, err => {
        this.openDialog(cpage)
      })
    }
    else {
      this.openDialog(cpage)
    }
  }

  delete(cpage) {
    this.openDialog(cpage, true)
  }

  refresh() {
    this.loadAll()
  }

  // common, allow for different code paths, some non-async
  openDialog(cpage, isDelete = false) {
    const dialogRef = this.dialog.open(EditCollaborationModalComponent, {
      width: '680px'
    });
    dialogRef.componentInstance.isDelete = isDelete;
    dialogRef.componentInstance.cpage = cpage

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      this.loadAll()
    })
  }

  downloadFile(cpage) {
    //console.log(cpage)
    const fileName = cpage.docName || 'filedownload'
    this.cpageService.getFile(cpage.cpageId).pipe(take(1)).subscribe(data => {
      const file = new Blob([data]);
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(file, fileName);
      } else {
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(file); // xhr.response is a blob
        a.style.display = 'none';
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
      }
    })
  }
}
