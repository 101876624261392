<mat-card class="form-card">
  <div class="grid-container">
    <div fxLayout fxLayoutAlign="center center" class="simple-grid-title">
      <!-- a.k.a. Data-Cards -->
      <span class="grid-title">{{initService.getConfig().titlePrefix}} Underway History</span>
      <app-search-filter-control [filterType]="'Unit'" (filtersChanged)="unitFiltersChanged($event)"></app-search-filter-control>
    </div>
    <div class="mat-elevation-z8">
   <mat-card-content>
    <div fxLayout="row">
      <mat-card fxFlex="20%" fxFlexOffset="5%" class="form-card">
        <h4>Select Unit</h4>
        <form [formGroup]="editForm">
          <mat-card-content class="fom-card-content">
            <span matPrefix>
              <mat-select class="country-filter" [(value)]="filterByCountry"
                  (selectionChange)="countryFilterChange($event)">
                  <mat-select-trigger *ngIf="filterByCountry.name"
                      matTooltip="Find Units for {{filterByCountry.name}}">
                      <img [src]="getFlag(filterByCountry)" height="16">&nbsp;
                  </mat-select-trigger>
                  <mat-select-trigger *ngIf="!filterByCountry.name"
                      matTooltip="Select a Country to Filter Search">
                      <mat-icon style="vertical-align:middle;">flag</mat-icon>
                  </mat-select-trigger>
                  <mat-option [value]="noCountry">
                      <mat-icon style="vertical-align:middle;">flag</mat-icon> (clear)
                  </mat-option>
                  <mat-option *ngFor="let country of countries" [value]="country">
                      <img [src]="getFlag(country)" height="16">&nbsp;{{country.name}}
                  </mat-option>
              </mat-select>
          </span>
            <mat-form-field class="unit-input-field">
              <span matPrefix *ngIf="utilities.getFlag(editForm.controls?.unit?.value)"><img style="vertical-align:middle;" [src]="utilities.getFlag(editForm.controls?.unit?.value)" height="20" />&nbsp;</span>
              <input matInput type="text" formControlName="unit" placeholder="Unit" required
                [matAutocomplete]="unitAutocomplete" tabindex="1"
                matTooltip="{{editForm.controls?.unit?.value | unitNamePipe}}">
            </mat-form-field>
            <mat-autocomplete #unitAutocomplete="matAutocomplete" [displayWith]="displayUnit" (optionsScroll)="onUnitOptionsScroll()">
              <mat-option *ngFor="let option of filteredUnits$ | async" [value]="option">
                <span matTooltip="{{option.country?.name}}">
                  <img  style="vertical-align:middle;" [src]="utilities.getFlag(option)" height="20" />&nbsp;
                </span>
                <span matTooltip="{{option.fullName()}}">{{option.fullName()}}</span>
              </mat-option>
              <mat-option *ngIf="isLoading" class="is-loading"><mat-spinner diameter="30"></mat-spinner></mat-option>
            </mat-autocomplete>

          </mat-card-content>

          <button [disabled]="!editForm.valid || !editForm.controls.unit.value.unitId" mat-raised-button color="primary" (click)="getDataForUnit()">Submit</button>
        </form>
      </mat-card>
      <mat-card *ngIf="barChartData.length > 0" fxFlexOffset="5%" fxFlex="65%">
        <h4>
          <span *ngIf="unit"><img style="vertical-align:middle;" [src]="utilities.getFlag(unit)" height="20" />&nbsp;</span>
          {{unit?.fullName()}} Data Card
        </h4>
        <canvas baseChart
          [chartType]="barChartType"
          [datasets]="barChartData"
          [labels]="barChartLabels"
          [options]="barChartOptions"
          [legend]="barChartLegend"
          (chartHover)="chartHovered($event)"
          (chartClick)="chartClicked($event)">
        </canvas>
      </mat-card>
    </div>
   </mat-card-content>
  </div>
</div>
</mat-card>
