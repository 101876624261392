<app-classification-bar class="top-modal-classification-bar"></app-classification-bar>
<app-fdtm-modal-header [headerString]="headerString"></app-fdtm-modal-header>
<div mat-dialog-content class="tall-dialog-content">
  <div>
    <p *ngIf="message"><span class="green">{{message}}</span></p>
  </div>

  <form [formGroup]="editForm">
    <mat-stepper formArrayName="formArray" [orientation]="(stepperOrientation | async)!" linear>
      <mat-step formGroupName="0" [stepControl]="formArray?.get([0])" errorMessage="Connection Information is required">
        <ng-template matStepLabel>Connection Information:</ng-template>

        <div fxLayout="column" fxLayoutAlign="center center">
          <mat-form-field class="input-field">
            <mat-select formControlName="ownerId" placeholder="System owner" required>
              <mat-option *ngFor="let owner of this.ownerOptions" [disabled]="false" [value]="owner.ownerId">
                {{owner.ownerName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="input-field">
            <input matInput placeholder="System Name" required formControlName="systemName" autocomplete="off">
          </mat-form-field>
          <mat-form-field class="input-field">
            <input matInput placeholder="Host" required formControlName="host" autocomplete="off">
          </mat-form-field>
          <mat-form-field class="input-field">
            <input matInput type="number" placeholder="Port" required min="0" max="65535" formControlName="port"
              autocomplete="off">
            <mat-error *ngIf="editForm.controls?.formArray?.controls?.[0]?.controls?.port?.errors?.['min']">
              Must be greater than 0.
            </mat-error>
            <mat-error *ngIf="editForm.controls?.formArray?.controls?.[0]?.controls?.port?.errors?.['max']">
              Must be less than 65535.
            </mat-error>
          </mat-form-field>
          <mat-form-field class="input-field">
            <mat-select formControlName="direction" placeholder="Direction" required>
              <mat-option *ngFor="let direction of directionOptions" [disabled]="false" [value]="direction">
                {{direction}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="input-field">
            <mat-select formControlName="protocol" placeholder="Protocol" required>
              <mat-option *ngFor="let protocol of protocolOptions" [disabled]="false" [value]="protocol"> {{protocol}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="input-field">
            <mat-select formControlName="environment" placeholder="Environment" required>
              <mat-option *ngFor="let environment of environmentOptions" [disabled]="false" [value]="environment">
                {{environment}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="radio-form-group">
            <label>Enabled:</label>
            <mat-radio-group fxLayout="column" formControlName="enabled" placeholder="Enabled" required>
              <mat-radio-button value="true" [checked]="this.entity.enabled">True</mat-radio-button>
              <mat-radio-button value="false" [checked]="this.entity.enabled === false">False</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div fxLayoutAlign="end">
          <button mat-button matStepperNext type="button" mat-raised-button color="primary"
            class="next-button">Next</button>
        </div>
      </mat-step>

      <mat-step formGroupName="1" [stepControl]="formArray.get([1])">
        <ng-template matStepLabel>Connection Parameters:</ng-template>

        <div fxLayout="column" fxLayoutAlign="center center">
          <mat-form-field class="input-field">
            <mat-label>Connection Params</mat-label>
            <textarea matInput placeholder="{&#x0a;&#x0a;}" formControlName="connectionParams" autocomplete="off"
              class="text-area" required></textarea>
            <mat-error
              *ngIf="editForm.controls?.formArray?.controls?.[1]?.controls?.connectionParams?.errors?.['jsonInvalid']">
              Must be valid JSON
            </mat-error>
          </mat-form-field>
        </div>
        <div fxLayoutAlign="end" fxLayoutGap="20px">
          <button mat-button matStepperPrevious type="button" mat-raised-button color="secondary">Back</button>
          <button mat-button matStepperNext type="button" mat-raised-button color="primary"
            class="next-button">Next</button>
        </div>
      </mat-step>

      <mat-step formGroupName="2" [stepControl]="formArray.get([2])">
        <ng-template matStepLabel>Message Configurations</ng-template>
        <div class="centered">
          <h2>Message Configurations</h2>
          <button mat-raised-button color="primary" (click)="addMessageConfig()">Add</button>
        </div>
        <div formArrayName="messageConfigs">
          <div *ngFor="let messageConfig of messageConfigsArray.controls; let i=index">
            <div class="centered">
              <h3>Message Configuration #{{ i + 1 }}</h3>
              <button mat-raised-button color="warn" *ngIf="!messageConfig.value?.messageConfigId"
                (click)="removeMessageConfig(i)">
                Remove
              </button>
            </div>
            <div [formGroupName]="i" fxLayout="column" fxLayoutAlign="center center">
              <mat-form-field class="input-field">
                <mat-select formControlName="messageType" placeholder="Message Type" required
                  (selectionChange)="handleMessageTypeUpdate($event.value, messageConfig)">
                  <mat-option *ngFor="let option of this.messageTypeOptions" [value]="option">
                    {{ option }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="messageConfig.controls.messageType.hasError('duplicate')">Please select a message
                  type
                  that is not already in use.</mat-error>
              </mat-form-field>

              <div class="radio-form-group">
                <mat-label>Enabled: </mat-label>
                <mat-radio-group fxLayout="column" formControlName="enabled" placeholder="Enabled">
                  <mat-radio-button value="true" [checked]="messageConfig.value.enabled">True</mat-radio-button>
                  <mat-radio-button value="false" [checked]="!messageConfig.value.enabled">False</mat-radio-button>
                </mat-radio-group>
              </div>

              <mat-form-field class="input-field">
                <textarea matInput placeholder="Message Params" formControlName="messageParams" autocomplete="off"
                  required class="short-text-area"></textarea>
                <mat-error *ngIf="messageConfig.controls?.messageParams?.errors?.['jsonInvalid']">Must be valid JSON
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div fxLayoutAlign="end" fxLayoutGap="20px">
          <button mat-button matStepperPrevious type="button" mat-raised-button color="secondary">Back</button>
          <button mat-button matStepperNext type="button" mat-raised-button color="primary"
            class="next-button">Next</button>
        </div>
      </mat-step>

      <mat-step>
        <ng-template matStepLabel>Review</ng-template>
        <div *ngIf="editForm.valid; else invalidForm">
          <app-review [form]="editForm"></app-review>
        </div>

        <div mat-dialog-actions fxLayoutAlign="end" fxLayoutGap="20px">
          <button mat-raised-button (click)="handleCancel()">Cancel</button>
          <button [disabled]="!editForm.valid" mat-raised-button color="primary" value="Upload"
            (click)="uploadForm($event)" class="next-button">Submit</button>
        </div>
      </mat-step>
    </mat-stepper>

  </form>
</div>
<app-classification-bar class="bottom-modal-classification-bar"></app-classification-bar>

<ng-template #invalidForm>
  Please complete required fields to review.
</ng-template>
