import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APIConfig } from './api.config';

@Injectable({
  providedIn: 'root'
})
export class GeoRefApiService {
  SERVER_URL;
  RESOURCE_URI = 'geo-refs';

  constructor(private http: HttpClient, apiConfig: APIConfig) {
    this.SERVER_URL = apiConfig.TARGET_URL;
  }

  public getAllGeoRefs(): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/${this.RESOURCE_URI}`);
  }

  public getGeoRefByGeoRefId(geoRefId: string): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/${this.RESOURCE_URI}/${geoRefId}`);
  }

  public createGeoRef(geoRef: any): Observable<any> {
    return this.http.post(`${this.SERVER_URL}/${this.RESOURCE_URI}`, geoRef);
  }

  public updateGeoRef(geoRef: any): Observable<any> {
    return this.http.put(
      `${this.SERVER_URL}/${this.RESOURCE_URI}/${geoRef.geo_ref_id}`, geoRef);
  }

  public deleteGeoRef(geoRef: any): Observable<any> {
    return this.http.delete(
      `${this.SERVER_URL}/${this.RESOURCE_URI}/${geoRef.geo_ref_id}`);
  }

  public getNearestGeoRef(latitude: string, longitude: string): Observable<any> {
    const httpParams = new HttpParams({ fromObject: { latitude: latitude, longitude: longitude } });

    return this.http.get(`${this.SERVER_URL}/${this.RESOURCE_URI}/nearest`, { params: httpParams });
  }
}
