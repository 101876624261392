import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from './api.config';

@Injectable()
export class ConnectionApiService {
  SERVER_URL;
  RESOURCE_URI = 'system-connections';

  constructor(private http: HttpClient, apiConfig: APIConfig) {
    this.SERVER_URL = apiConfig.TARGET_URL;
  }

  public getAllSystemConnections(): Observable<any> {
    return this.http.get(
      `${this.SERVER_URL}/${this.RESOURCE_URI}`);
  }

  public createSystemConnection(conn: any): Observable<any> {
    return this.http.post(
      `${this.SERVER_URL}/${this.RESOURCE_URI}`, conn);
  }

  public updateSystemConnection(connection: any): Observable<any> {
    return this.http.put(
      `${this.SERVER_URL}/${this.RESOURCE_URI}/${connection.system_connection_id}`, connection);
  }

  public deleteSystemConnection(connection: any): Observable<any> {
    return this.http.delete(
      `${this.SERVER_URL}/${this.RESOURCE_URI}/${connection.system_connection_id}`);
  }

}
