import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpResponse, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoadingService } from '@services/global/loading/loading.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  //private totalRequests = 0;

  constructor(private loadingService: LoadingService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // log to the user console for debugging
    const date = new Date();
    console.log(`${date} request: ${request.url}`);
    // increment the number of outstanding rewuests
    this.loadingService.addRequest(request);
    this.loadingService.isLoading.next(true);

    // this SO gets it correct
    // https://stackoverflow.com/questions/47218216/angular-how-to-know-if-request-has-been-cancelled-in-httpclient/49536421#49536421
    return new Observable(observer => {
      let isCancelled = true;
      const sub = next.handle(request)
          .pipe(tap(event => {
            if (event instanceof HttpResponse) {
              isCancelled = false;
              this.decreaseRequests(request);
            }
          },
          (rspError: HttpErrorResponse) => {
            isCancelled = false;
            // your decrement logic here
            this.decreaseRequests(request);
            throwError(rspError); // re-throw same e
          },
        ))
          .subscribe(observer);

      return () => {
          if (isCancelled) {
              this.decreaseRequests(request);
              sub.unsubscribe();
          }
      };
    });
  }

  private decreaseRequests(request: HttpRequest<any>) {
    const count = this.loadingService.removeRequest(request);
    if (count === 0) {
      this.loadingService.isLoading.next(false);
    }
  }
}
