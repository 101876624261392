<div class="admin-work-area">
  <div fxLayout fxLayoutAlign="center center">
    <span class="grid-title">{{initService.getConfig().titlePrefix}} FDT-M Account Requests</span>
  </div>

  <div class="mat-elevation-z8">
    <!-- Paginator -->
    <mat-paginator [pageSizeOptions]="[10, 20, 50, 100, 1000]" [pageSize]="20" [length]="dataSource?.data?.length" showFirstLastButtons></mat-paginator>
    <table mat-table [dataSource]="dataSource" matSort matSortStart="desc">

      <!-- Command Column -->
      <!-- <ng-container matColumnDef="command">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Command </th>
        <td mat-cell *matCellDef="let element"> {{element.command.command_name}} </td>
      </ng-container> -->

      <!-- Date Column -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
        <td mat-cell *matCellDef="let element">
          <span class="mat-color-warn" *ngIf="!element.user">* </span>
          <span>{{dateFormat(element.create_ts)}}</span>
        </td>
      </ng-container>

      <!-- First Name Column -->
      <ng-container matColumnDef="first_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
        <td mat-cell *matCellDef="let element">
          <span class="mat-color-warn" *ngIf="!element.user">* </span>
          {{element.first_name}} </td>
      </ng-container>

      <!-- Last Name Column -->
      <ng-container matColumnDef="last_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>
        <td mat-cell *matCellDef="let element">
          <span class="mat-color-warn" *ngIf="!element.user">* </span>
          {{element.last_name}} </td>
      </ng-container>

      <!-- Username Column -->
      <ng-container matColumnDef="user_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Username </th>
      <td mat-cell *matCellDef="let element">
        <span class="mat-color-warn" *ngIf="!element.user">* </span>
        {{element.user_name}} </td>
      </ng-container>

      <!-- Permissions Column -->
      <ng-container matColumnDef="permission">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Permission </th>
        <td mat-cell *matCellDef="let element">
          <span class="mat-color-warn" *ngIf="!element.user">* </span>
          {{element.permission.permission_description}} </td>
      </ng-container>

      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
        <td mat-cell *matCellDef="let element">
          <span class="mat-color-warn" *ngIf="!element.user">* </span>
          {{element.email}} </td>
      </ng-container>

      <!--Justification Coloumn-->
      <!-- <ng-container matColumDef="justification">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Justification </th>
        <td mat-cell *matCellDef="let element"> {{element.justification}} </td>
      </ng-container> -->

      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef>
          <div>
            Deny
          </div>
        </th>
        <td mat-cell *matCellDef="let row" title="Deny Account Request">
          <mat-icon class=""
                    matTooltip="Deny request"
                    matTooltipPosition="above"
                    (click)="$event.stopPropagation()"
                    (click)="handleDeny(row)">
            do_not_disturb_alt
          </mat-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr class="hyper"  matTooltip="{{row.user ? 'Click to Approve' : 'This user account has been deleted'}}"
        matTooltipPosition="before" mat-row *matRowDef="let row; columns: displayedColumns;"
        (click)="row.user ? edit(row) : null">
      </tr>
    </table>
  </div>
</div>
