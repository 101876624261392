import { ProductsComponent } from './fom/products/products.component';
import { AnalysisComponent } from './fom/analysis/analysis.component';
import { UnitsComponent } from './fom/units/units.component';
import { NotifierComponent } from './fom/notifier/notifier.component';
import { ServiceDashboardComponent } from './fom/service-dashboard/service-dashboard.component';
import { ParametersComponent } from './fom/parameters/parameters.component';
import { SlideMaintenanceComponent } from './fom/slide-maintenance/slide-maintenance.component';
import { DataTagsComponent } from './fom/data-tags/data-tags.component';
import { HomeComponent } from './fom/home/home.component';
import { NgModule } from '@angular/core';
import { Routes,
         RouterModule } from '@angular/router';
import { UserComponent } from './fom/user/user.component';
import { RoutingPaths } from './app.routing.routing-paths';
import { CommandsComponent } from './fom/commands/commands.component';
import { UnitUpdateComponent } from './fom/unit-update/unit-update.component';
import { AuthorizationGuardService } from '@services/data-services/authorization-guard.service';
// report components
import { KMLComponent } from './fom/analysis/kml/kml.component';
import { UnitSearchComponent } from './fom/analysis/unit-search/unit-search.component';
import { DataCardComponent } from '@fom-module/analysis/data-cards/data-cards.component';
import { CurrentNobExcelComponent } from './fom/products/current-nob-excel/currentNobExcel.component';
import { RecordMessageComponent } from './fom/products/record-message/recordMessage.component';
import { RSSComponent } from './fom/products/rss/rss.component';
import { CurrentNobComponent } from './fom/products/current-nob/current-nob.component';
import { OptempoComponent } from '@fom-module/analysis/optempo/optempo.component';
import { LocatorComponent } from './fom/products/locator/locator.component';
import { PacmisComponent } from './fom/products/pacmis/pacmis.component';
import { GeoRefsComponent } from '@fom-module/geo-refs/geo-refs.component';
import { AuthenticationService } from '@services/data-services/authentication.service';
import { GenerateSlidesComponent } from '@fom-module/products/generate-slides/generate-slides.component';
import { ParserQueueComponent } from '@fom-module/parser-queue/parser-queue.component';
import { ParserQueueBComponent } from '@fom-module/parser-queue-b/parser-queue-b.component';
import { COIComponent } from '@fom-module/coi/coi.component';
import { CertLoginComponent } from "./core/app-components/login/cert-login.component";
import { AboutComponent } from '@core-module/app-components/about-component/about.component';
import { CollaborationPortalComponent } from '@fom-module/collaboration-portal/collaboration-portal.component'
import { BlueSearchComponent } from '@fom-module/analysis/blue-search/blue-search.component';
import { PreferencesComponent } from '@fom-module/admin/admin-screens/preferences/preferences.component';
import { GrafanaComponent } from '@fom-module/analysis/grafana/grafana.component';
const routes: Routes = [
    { path: RoutingPaths.HOME_PATH, component: HomeComponent },
    { path: RoutingPaths.CERT_LOGIN_PATH, component: CertLoginComponent },
    { path: "about", component: AboutComponent },
    { path: RoutingPaths.ANALYSIS_PATH, component: AnalysisComponent, canActivate: [AuthorizationGuardService] },
    { path: RoutingPaths.PRODUCTS_PATH, component: ProductsComponent, canActivate: [AuthorizationGuardService] },
    { path: RoutingPaths.UNITS_PATH, component: UnitsComponent, canActivate: [AuthorizationGuardService] },
    { path: RoutingPaths.USER_PATH, component: UserComponent, canActivate: [AuthorizationGuardService]},
    { path: RoutingPaths.NOTIFIER_PATH, component: NotifierComponent, canActivate: [AuthorizationGuardService]},
    { path: RoutingPaths.SLIDE_MAINTENANCE_PATH, component: SlideMaintenanceComponent, canActivate: [AuthorizationGuardService]},
    { path: RoutingPaths.DATA_TAGS, component: DataTagsComponent, canActivate: [AuthorizationGuardService]},
    { path: RoutingPaths.SERVICE_DASHBOARD_PATH, component: ServiceDashboardComponent, canActivate: [AuthorizationGuardService]},
    { path: RoutingPaths.PARAMETERS_PATH, component: ParametersComponent, canActivate: [AuthorizationGuardService]},
    { path: RoutingPaths.PREFERENCES_PATH, component: PreferencesComponent, canActivate: [AuthorizationGuardService]},
    { path: RoutingPaths.COMMANDS_PATH, component: CommandsComponent, canActivate: [AuthorizationGuardService] },
    { path: RoutingPaths.GEO_REF_PATH, component: GeoRefsComponent, canActivate: [AuthorizationGuardService] },
    { path: RoutingPaths.UNIT_UPDATE_PATH, component: UnitUpdateComponent, canActivate: [AuthorizationGuardService] },
    { path: RoutingPaths.PARSER_QUEUE_PATH, component: ParserQueueComponent, canActivate: [AuthorizationGuardService] },
    { path: RoutingPaths.PARSER_QUEUE_B_PATH, component: ParserQueueBComponent, canActivate: [] },
    { path: RoutingPaths.COI_PATH, component: COIComponent, canActivate: [AuthorizationGuardService] },
    // reporting paths
    { path: RoutingPaths.GOOGLE_EARTH_HISTORY, component: KMLComponent, canActivate: [AuthorizationGuardService] },
    { path: RoutingPaths.CURRENT_NOB_EXCEL, component: CurrentNobExcelComponent, canActivate: [AuthorizationGuardService] },
    { path: RoutingPaths.RECORD_MESSAGE, component: RecordMessageComponent, canActivate: [AuthorizationGuardService]},
    { path: RoutingPaths.CURRENT_NOB, component: CurrentNobComponent, canActivate: [AuthorizationGuardService] },
    { path: RoutingPaths.UNIT_SEARCH_PATH, component: UnitSearchComponent, canActivate: [AuthorizationGuardService]},
    { path: RoutingPaths.DATA_CARDS_PATH, component: DataCardComponent, canActivate: [AuthorizationGuardService]},
    { path: RoutingPaths.OPTEMPO, component: OptempoComponent, canActivate: [AuthorizationGuardService] },
    { path: RoutingPaths.LOCATOR, component: LocatorComponent, canActivate: [AuthorizationGuardService] },
    { path: RoutingPaths.POWERPOINT, component: GenerateSlidesComponent, canActivate: [AuthorizationGuardService] },
    { path: RoutingPaths.RSS, component: RSSComponent, canActivate: [AuthorizationGuardService] },
    { path: RoutingPaths.PACMIS, component: PacmisComponent, canActivate: [AuthorizationGuardService] },
    { path: RoutingPaths.OCP, component: CollaborationPortalComponent, canActivate: [AuthorizationGuardService] },
    { path: RoutingPaths.BLUE_SEARCH_PATH, component: BlueSearchComponent, canActivate: [AuthorizationGuardService] },
    { path: RoutingPaths.GRAFANA, component: GrafanaComponent, canActivate: [AuthorizationGuardService] },

    { path: RoutingPaths.WILDCARD_PATH, redirectTo: RoutingPaths.HOME_PATH },
  ];

@NgModule({
  imports: [
    RouterModule.forRoot(routes,
                         { onSameUrlNavigation: 'reload',
    useHash: true,
    enableTracing: false, relativeLinkResolution: 'legacy' })
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule {}
