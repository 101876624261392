import { Any, JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter } from '../services/global/date-converter';
import { Command } from '@models/command.model';

@JsonObject('ReportTemplate')
export class ReportTemplate {
  @JsonProperty('report_template_id', String, true)
  reportTemplateId: string = undefined;
  @JsonProperty('report_template_name', String, true)
  reportTemplateName: string = undefined;
  @JsonProperty('report_template_file', String, true)
  reportTemplateFile: string = undefined;
  @JsonProperty('version_num', Number)
  versionNum: number | undefined = undefined;
  @JsonProperty('version_ts', DateConverter, true)
  versionDate: Date = undefined;
  @JsonProperty('feature_type_id', String, true)
  featureTypeId: string = undefined;
  @JsonProperty('create_ts', DateConverter, true)
  dateCreated: Date = undefined;
  @JsonProperty('created_by', String, true)
  createdBy: string = undefined;
  @JsonProperty('update_ts', String, true)
  dateUpdated: Date = undefined;
  @JsonProperty('updated_by', Command, true)
  updatedBy: string = undefined;
  @JsonProperty('delete_ts', DateConverter, true)
  dateDeleted: Date = undefined;
  @JsonProperty('deleted_by', String, true)
  deletedBy: string = undefined;
  @JsonProperty('processing_tags', Any, true)
  processingTags: any = undefined;
}
