import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { Router } from '@angular/router';
import { GridBaseComponent } from '@fom-module/base-classes/grid-base.component';
import { AppInitService } from '../../../config/init.service';
import { User } from '@models/user.model';
import { ConnectionsService } from '@services/data-services/connections.service'
import { CommandService } from '@services/data-services/command.service'
import { take } from 'rxjs/operators';
import { SystemConnection } from '@models/system-connection.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EditSystemConnectionModalComponent } from '@edit-modals/edit-system-connection-modal/edit-system-connection-modal.component';
import { EditMessageConfigModalComponent } from '@fom-module/edit-modals/edit-message-config-modal/edit-message-config-modal.component';
import { MessageConfig } from '@models/message-config.model';
import { MatAccordion } from '@angular/material/expansion';
import { CommandHierarchyService } from '@services/data-services/command-hierarchy.service';
import { map, startWith } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips/chip-input';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MessageConfigService } from '@services/data-services/message-config.service';
import { ParametersTemplateService } from '@services/data-services/parameters-template.service';
import { GlobalBusMessage } from '@services/global/message-bus/global-bus-message.model';
import { GlobalMessageTriggers } from '@services/global/message-bus/global-message-triggers.enum';
import { ConfirmationModalComponent } from '@core-module/app-components/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-connections',
  templateUrl: './connections.component.html',
  styleUrls: ['./connections.component.css', '../base-classes/grid-base.component.css']
})
export class ConnectionsComponent extends GridBaseComponent implements OnInit {

  systemConnections: SystemConnection[];
  groupedConnections: any[];
  ownerOptions = [];
  connections: any[];

  @ViewChild(MatAccordion) accordion: MatAccordion;

  jfmccs = [];
  ownerCtrl = new FormControl()
  filteredOwners: Observable<string[]>
  owners: string[] = [];
  allOwners: string[] = [];
  @ViewChild('ownerInput') ownerInput: ElementRef<HTMLInputElement>;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  parametersTemplates: any;

  constructor(public messageBusService: MessageBusService,
    public configInfo: AppInitService,
    public route: Router,
    private connectionsService: ConnectionsService,
    private messageConfigService: MessageConfigService,
    private dialog: MatDialog,
    private commandHierarchyService: CommandHierarchyService,
    private commandService: CommandService,
    public snackBar: MatSnackBar,
    private parametersTemplateService: ParametersTemplateService) {
    super(messageBusService, connectionsService, route);
  }

  ngOnInit() {
    this.loadEntityData()
    this.getOwnerOptions()
    this.getParameterTemplates()
  }

  loadEntityData() {
    this.connectionsService.getAll().pipe(take(1)).subscribe(entities => {
      entities.sort((a, b): number => {
        if (a.ownerName === b.ownerName) {
          if (a.direction === b.direction) {
            return a.systemName < b.systemName ? -1 : 1; //alphabetical sort on name
          } else {
            return a.direction < b.direction ? -1 : 1; // alphabetical by direction (in case more than inbound/outbound)
          }
        } else {
          return a.ownerName < b.ownerName ? -1 : 1; //ascii uuid for now, switch to owner name alphabetical when retrieved
        }
      });
      let options = []
      for (const connection of entities) {
        if (!options.includes(connection.direction)) {
          options.push(connection.direction)
        }
      }

      this.systemConnections = entities;

      const groups = [];
      for (const connection of entities) {
        // Create a group for the owner if not already seen
        let group = groups.find((e) => e.ownerId === connection.ownerId);
        if (!group) {
          group = { ownerId: connection.ownerId, ownerName: connection.ownerName, directions: [] };
          options.forEach((option) => group.directions.push({ direction: option, connections: [] }))
          groups.push(group);
        }

        // Create list of connections for the direction if not already seen
        let direction = group.directions.find((e) => e.direction === connection.direction);
        if (!direction) {
          direction = { direction: connection.direction, connections: [] };
          group.directions.push(direction);
        }

        // Push connection into list of connections
        direction.connections.push(connection);
      }

      this.groupedConnections = groups;
      this.connections = groups;

      // Get all unique owner names
      this.allOwners = groups.map(e => e.ownerName).filter((e, index, self) => index === self.indexOf(e));

      this.filteredOwners = this.ownerCtrl.valueChanges.pipe(
        map((owner: string | null) => (owner ? this._filter(owner) : this.allOwners.slice().filter(owner => !this.owners.includes(owner)))),
      );
    });
  }

  refreshEntityData() {
    // Clear owner filtering then reload data.
    this.ownerCtrl.setValue(null);
    this.ownerInput.nativeElement.value = '';
    // empty owners without copying data.
    while(this.owners.length > 0) {
      this.owners.pop();
    }

    this.loadEntityData();
  }

  getParameterTemplates() {
    this.parametersTemplateService.getAll().pipe(take(1)).subscribe(entities => {
      this.parametersTemplates = entities
    })
  }

  add(ownerId = null) {
    const dialogRef = this.dialog.open(EditSystemConnectionModalComponent, {
      width: '75%',
      maxHeight: '800px'
    });
    dialogRef.componentInstance.isNew = true;
    dialogRef.componentInstance.entity = new SystemConnection();
    dialogRef.componentInstance.entity.ownerId = ownerId;
    dialogRef.componentInstance.ownerOptions = this.ownerOptions;
    dialogRef.componentInstance.parametersTemplates = this.parametersTemplates;

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      // only reload if a system connection is created.
      if (result !== true && result !== undefined) {
        this.loadEntityData();
      }
    })
  }

  edit(connection: any) {
    const dialogRef = this.dialog.open(EditSystemConnectionModalComponent, {
      width: '75%',
      maxHeight: '800px'
    });
    dialogRef.componentInstance.isNew = false;
    dialogRef.componentInstance.entity = connection;
    dialogRef.componentInstance.ownerId = connection.ownerId;
    dialogRef.componentInstance.ownerOptions = this.ownerOptions;
    dialogRef.componentInstance.parametersTemplates = this.parametersTemplates;

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      // only reload if system connection is edited.
      if (result !== true && result !== undefined) {
        this.loadEntityData();
      }
    });
  }

  getOwnerOptions() {
    this.commandHierarchyService.getAll({ sort: 'version_num', limit: 1 }).pipe(take(1)).subscribe(entities => {
      this.jfmccs = entities;
      if (entities && Array.isArray(entities) && entities.length) {
        entities.forEach((entity) => {
          const ids = entity.getJFMCCs();
          this.commandService.getAll({ command_id: ids.join(',') }).pipe(take(1)).subscribe(commands => {
            this.ownerOptions = commands.map(e => { return { ownerId: e.commandId, ownerName: e.name, ownerType: 'command' } });
          });
        });
      }
    });

    return this.ownerOptions;
  }

  handleDelete(entity: SystemConnection) {
    super.handleDelete(entity, 'Delete Command', `Select "Delete" to delete ${entity.systemName}`, 'Delete')
  }

  addMessageConfig(systemConnection: any) {
    const dialogRef = this.dialog.open(EditMessageConfigModalComponent, {
      width: '400px'
    });
    dialogRef.componentInstance.isNew = true;
    dialogRef.componentInstance.entity = new MessageConfig();
    dialogRef.componentInstance.invalidMessageTypes = systemConnection.messageConfigs.map(e => e.messageType)
    dialogRef.componentInstance.entity.systemConnectionId = systemConnection.systemConnectionId;
    dialogRef.componentInstance.parametersTemplates = this.parametersTemplates;

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      if (result && result.messageConfigId) {
        this.loadEntityData();
      }
    })
  }


  editMessageConfig(messageConfig: any, systemConnection: any) {
    const dialogRef = this.dialog.open(EditMessageConfigModalComponent, {
      width: '400px'
    });
    dialogRef.componentInstance.isNew = false;
    dialogRef.componentInstance.entity = messageConfig;
    dialogRef.componentInstance.invalidMessageTypes = systemConnection.messageConfigs.map(e => e.messageType);
    dialogRef.componentInstance.systemConnectionId = messageConfig.systemConnectionId
    dialogRef.componentInstance.parametersTemplates = this.parametersTemplates;

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      if (result && result.messageConfigId) {
        this.loadEntityData();
      }
    });
  }

  deleteMessageConfig(context: any, args: any) {
    context.messageConfigService.delete(args[0]).pipe(take(1)).subscribe(_response => {
      context.loadEntityData();
      context.messageBusService.publishMessage(new GlobalBusMessage(GlobalMessageTriggers.CLOSE_MODAL));
    });
  }

  handleMessageConfigDelete(entity: MessageConfig) {
    this.messageBusService.publishMessage(
      new GlobalBusMessage(GlobalMessageTriggers.LOAD_MODAL,
        {
          component: ConfirmationModalComponent,
          data: {
            message: `Select "Delete" to delete Message Configuration for ${entity.messageType}`,
            headerString: 'Delete Message Configuration',
            buttonText: 'Delete',
            action: this.deleteMessageConfig,
            context: this,
            args: [entity]
          }
        })
    );
  }

  groupHeaderClick(row) {
    row.expanded = !row.expanded;
  }

  // Chip selector methods
  addChip(event: MatChipInputEvent) {
    const value = (event.value || '').trim();
    if (value && this.allOwners.includes(value)) {
      if (!this.owners.includes(value)) {
        this.owners.push(value);
        this.connections = this.filterOwners()
      } else {
        this.snackBar.open('Owner already present in filter.', 'OK', { duration: 5_000, panelClass: 'red-snackbar' });
      }
    } else {
      this.snackBar.open("Please add a valid owner to filter by.", 'OK', { duration: 5_000, panelClass: 'red-snackbar' });
    }

    event.chipInput!.clear();

    this.ownerCtrl.setValue(null);
  }

  removeChip(owner: any): void {
    const index = this.owners.indexOf(owner);

    if (index >= 0) {
      this.owners.splice(index, 1);

      // clear inputs to force refilter options so that removed selection is available again.
      this.ownerCtrl.setValue(null);
      this.ownerInput.nativeElement.value = '';
    }
    this.connections = this.filterOwners()
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.owners.push(event.option.viewValue);
    this.ownerInput.nativeElement.value = '';
    this.ownerCtrl.setValue(null);
    this.connections = this.filterOwners()
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    const matchingOwners = this.allOwners.filter(owner => owner.toLowerCase().includes(filterValue));

    // Remove any already selected owners to prevent duplicate selected chips
    return matchingOwners.filter(owner => !this.owners.includes(owner));
  }

  filterOwners() {
    // Give all connections if no chips selected
    if (this.owners.length == 0) {
      return this.groupedConnections;
    }
    let filteredOwners = []
    for (let owner of this.groupedConnections) {
      if (this.owners.includes(owner.ownerName)) {
        filteredOwners.push(owner)
      }
    }
    return filteredOwners
  }

  toggleEnabled(event: MatSlideToggleChange) {
    const id = event.source._elementRef.nativeElement.id;
    let toggleable: SystemConnection | MessageConfig;

    // Ideally find a cleaner way to find the toggleable object.
    for (const connection of this.systemConnections) {
      if (connection.systemConnectionId === id) {
        toggleable = connection;
        break;
      } else {
        toggleable = connection.messageConfigs.find(c => c.messageConfigId === id);
        if (toggleable) {
          break;
        }
      }
    }

    // If for some reason toggled object can't be found, return silently until we have a cleaner solution
    if (!toggleable) {
      return
    }

    toggleable.enabled = event.checked;
    if (toggleable instanceof SystemConnection) {
      this.connectionsService.update(toggleable).pipe(take(1)).subscribe(res => {
        this.snackBar.open(`Successfully toggled System Connection`, 'Close', { duration: 10_000 });
      });
    }

    if (toggleable instanceof MessageConfig) {
      this.messageConfigService.update(toggleable).pipe(take(1)).subscribe(res => {
        this.snackBar.open(`Successfully toggled Message Configuration`, 'Close', { duration: 10_000 });
      });
    }
  }
}
