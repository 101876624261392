<div class="admin-work-area">
    <div fxLayout fxLayoutAlign="center center" class="simple-grid-title">
        <span class="grid-title">{{initService.getConfig().titlePrefix}} FDT-M Notifier</span>
    </div>
    <form [formGroup]="editForm" #notifyForm>
        <mat-card>
            <mat-card-content class="fom-card-content">
            <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px"
                fxLayoutGap.xs="0">
                <div>
                    <mat-radio-group fxLayout="row" formControlName="list" placeholder="Choose List">
                        Send email to:
                        <mat-radio-button validation="required" class="notifier-option" *ngFor="let option of notifierTypes"
                            [value]="option.type">
                            {{option.display}}
                        </mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="notifyForm.submitted && editForm.controls.list.invalid">Select a list of users</mat-error>
                </div>
            </div>
            <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px"
                fxLayoutGap.xs="0">
                <hr/>
            </div>

            <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px"
                fxLayoutGap.xs="0">


                <div fxFlex="20%" fxFlex.md="40%" fxFlex.lg="30%">
                    <mat-form-field>
                        <mat-select formControlName="classification" placeholder="Classification" tabindex=2 required>
                            <mat-option *ngFor="let classification of classifications" [value]="classification.viewValue">
                                {{classification.viewValue}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <!----div fxFlex="20%" fxFlex.md="40%" fxFlex.lg="30%">
                    <mat-form-field>
                        <input matInput tabindex="11" formControlName="activity" placeholder="Activity" required>
                    </mat-form-field>
                </div-->
            </div>

            <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px"
                fxLayoutGap.xs="0">

                <div fxFlex="20%" fxFlex.md="40%" fxFlex.lg="30%">
                    <mat-form-field class=fom-input-field>
                        <input matInput
                            tabindex="3"
                            formControlName="subject"
                            placeholder="Subject"
                            required
                        >
                        <!----mat-hint>YYYY-MM-DD HH:MM</mat-hint-->
                    </mat-form-field>
                </div>
            </div>

            <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px"
                fxLayoutGap.xs="0">

                <div fxFlex="80%" fxFlex.md="80%" fxFlex.lg="80%">
                    <mat-form-field class=fom-input-field>
                        <!----input matInput
                            formControlName="message"
                            placeholder="Message"
                            required
                            tabindex="4"-->
                        <!----mat-hint>YYYY-MM-DD HH:MM</mat-hint-->
                        <textarea class="message-area" matInput placeholder="Message" formControlName="message" required tabindex="4"></textarea>
                    </mat-form-field>
                </div>
            </div>
        </mat-card-content>

        <mat-card-actions>
            <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px"
                fxLayoutGap.xs="0">

                <div fxFlex="20%" fxFlex.md="40%" fxFlex.lg="30%">
                    <button mat-raised-button color="primary" [disabled]="!editForm.valid" (click)="handleSubmit()">
                        Send Mail
                    </button>&nbsp;
                    <button mat-raised-button type="reset" (click)="clear()">
                        Clear
                    </button>
                </div>
                <!----div fxFlex="20%" fxFlex.md="40%" fxFlex.lg="30%"></div-->
            </div>
        </mat-card-actions>
        </mat-card>
    </form>
</div>
