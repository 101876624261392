<mat-card class="form-card">
  <div fxLayoutAlign="center" class="nob-header">
    <div class="card">
      <img class="fomImage" height="74px" width="74px" src="assets/images/FDT-M.png" />
    </div>
    <div class="nob-header-middle">
      <div class="grid-title nob-title">
        <span>{{initService.getConfig().titlePrefix}}FDT-M Locator</span>
      </div>
      <div>
        <app-nob-ticker [startTicker]="startTicker" (unitClickEvent)="unitSelected($event)"
          (refreshClickEvent)="refreshPage()"></app-nob-ticker>
      </div>
    </div>
    <div class="card">
      <img class="fomImage" height="74px" width="74px" src="assets/images/FDT-M.png" />
    </div>
  </div>

  <div class="container action-area" fxLayout.xs="column" fxLayoutAlign="center">
    <mat-form-field class="groupSelect">
      <mat-select [(ngModel)]="grouping" placeholder="Group By" (selectionChange)="groupingChanged($event, grouping)">
        <mat-option *ngFor="let groupItem of groupItems" [value]="groupItem">
          {{groupItem.display}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="groupSelect">
      <input matInput type="text" [(ngModel)]="filterValue" (ngModelChange)="onFilterChange($event)" autocomplete="off"
        placeholder="Unit Name Filter">
      <span class="hyper" (click)="clearFilter('')" matSuffix>X</span>
    </mat-form-field>

    <div class="table-actions" fxFlexAlign="end" fxFlexOffset="5%">
      <div class="collapse-actions" *ngIf="hasMultipleGroups()">
        <button mat-raised-button class="fdt-m-primary" *ngIf="hasAnyExpandedGroups()" (click)="changeAllGroups(false)"
          matTooltip="Collapse all groupings in table">
          <mat-icon>unfold_less</mat-icon> Collapse All
        </button>

        <button mat-raised-button class="fdt-m-primary" *ngIf="!hasAnyExpandedGroups()" (click)="changeAllGroups(true)"
          matTooltip="Expand all groupings in table">
          <mat-icon>unfold_more</mat-icon> Expand All
        </button>
      </div>
    </div>
  </div>

  <div fxLayoutGap="10px" fxLayoutGap.xs="0">
    <cdk-virtual-scroll-viewport tvsItemSize="12" headerHeight="56">
      <table class="nob-table" mat-table matSort [dataSource]="dataSource" (matSortChange)="sortData($event)">


        <ng-container matColumnDef="classificationName">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Classification </th>
          <td mat-cell mat-cell *matCellDef="let element">
            {{element.classificationName}}
          </td>
        </ng-container>

        <ng-container matColumnDef="unitName">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Unit Name </th>
          <td mat-cell *matCellDef="let element">
            {{element.unitName}}
          </td>
        </ng-container>

        <ng-container matColumnDef="country.triGraph">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Flag </th>
          <td mat-cell mat-cell *matCellDef="let element">
            <img [src]="element.flagSource" height="16">&nbsp;
            {{element.country.triGraph}}
          </td>
        </ng-container>

        <ng-container matColumnDef="sconum">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> SCONUM </th>
          <td mat-cell mat-cell *matCellDef="let element">
            {{element.sconum}}
          </td>
        </ng-container>

        <ng-container matColumnDef="portName">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Home Port </th>
          <td mat-cell mat-cell *matCellDef="let element">
            {{element.portName}}
          </td>
        </ng-container>

        <ng-container matColumnDef="lastLoc">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Last Location </th>
          <td mat-cell mat-cell *matCellDef="let element">
            {{element.lastLoc | locatorPipe: element.isRemovedFromNob : false}}
          </td>
        </ng-container>

        <ng-container matColumnDef="locTimeTS">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Location Time </th>
          <td mat-cell mat-cell *matCellDef="let element">
            {{element.locTimeTS | locatorPipe: element.isRemovedFromNob : true}}
          </td>
        </ng-container>

        <ng-container matColumnDef="trackMaintenance">
          <th mat-header-cell *matHeaderCellDef>Track Maint.</th>
          <td mat-cell *matCellDef="let element">
            <span class="hyper" matTooltip="View Track Maintenance" title="View Track Maintenance"
              (click)="$event.stopPropagation(); navigateTrackMaintenance(element)">
              <mat-icon class="nob-icon">history</mat-icon>
            </span>
          </td>
        </ng-container>

        <tr class="nob-header-row" mat-header-row sticky *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr class="nob-row" [ngClass]="row.rowClass" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <!-- Group header -->
        <ng-container matColumnDef="name">
          <td colspan="999" mat-cell *matCellDef="let group; let grouping; let row" class="groupHeader">
            <span title="click to show/hide group items" class="hyper">
              {{group.name}}: {{group.itemCount}} total Unit(s)
            </span>
          </td>
        </ng-container>

        <tr mat-row *matRowDef="let row; columns: ['name']; when: isGroup" (click)="groupHeaderClick(row)"
          class="groupHeader"></tr>
      </table>
    </cdk-virtual-scroll-viewport>
  </div>

</mat-card>