import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GridBaseComponent } from '@fom-module/base-classes/grid-base.component';
import { EditFeaturePreferenceModalComponent } from '@fom-module/edit-modals/edit-feature-preference-modal/edit-feature-preference-modal.component';
import { FeaturePreferenceService } from '@services/data-services/feature-preference.service';
import { GlobalMessageTriggers } from '@services/global/message-bus/global-message-triggers.enum';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { AppInitService } from '../../../../../config/init.service';
import { take } from 'rxjs/operators';
import { FeaturePreference } from '@models/feature-preference.model';
import { FeatureType } from '@models/feature-type.model';
import { ModalResponse } from '@core-module/enums/modal-response.enum';
import { FeatureTypeService } from '@services/data-services/feature-type.service';
import { Owner } from '@models/owner.model';
import { OwnerTypeService } from '@services/data-services/owner-type.service';
import { OwnerService } from '@services/data-services/owner.service';
import { CommandHierarchyService } from '@services/data-services/command-hierarchy.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FriendlyErrorService } from '@services/friendly-error.service';
import { User } from '@models/user.model';
import { CurrentUserService } from '@services/current-user-service';
import { Classification } from '@models/classification.model';
import { Control } from '@models/control.model';
import { Fgi } from '@models/fgi.model';
import { OtherControl } from '@models/other-control.model';
import { Releasability } from '@models/releasability.model';
import { LookUpService } from '@services/data-services/lookup.service';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.css', '../../../base-classes/grid-base.component.css']
})
export class PreferencesComponent extends GridBaseComponent implements OnInit {

  selectedPreference: string;
  featurePreferenceTypes: FeatureType[] = []
  schemaDefinition: any;
  schemaLoaded: boolean = false;
  featurePreferences: FeaturePreference[] = []
  versionNum: number;
  versionName: string;
  owners: Owner[];
  ownerTypes: any[];
  JFMCCs: any[];
  selectedOwner: Owner;
  featureCategory: string = undefined;
  categoryLoaded: boolean = false;
  user: User;

  
  // Checkbox Options for classification filter and lookback
  classificationOptions: Classification[];
  sciControlsOptions: Object[];
  fgiControlOptions: Object[];
  otherControlsOptions: Object[];
  releasabilityOptions: Object[];

  doNotDelete: boolean = false;

  constructor(public messageBusService: MessageBusService,
    private dialog: MatDialog,
    public featurePreferenceService: FeaturePreferenceService,
    public featureTypeService: FeatureTypeService,
    public route: Router,
    private friendlyErrorService: FriendlyErrorService,
    private snackBar: MatSnackBar,
    private ownerTypeService: OwnerTypeService,
    private ownerService: OwnerService,
    private commandHierarchyService: CommandHierarchyService,
    private currentUserService: CurrentUserService,
    public initService: AppInitService,
    public lookUpService: LookUpService,) {
      super(messageBusService, featureTypeService, route);
      this.RELOAD_MESSAGE = GlobalMessageTriggers.RELOAD_PORT_DATA;
      this.EDIT_MODAL_COMPONENT = EditFeaturePreferenceModalComponent;
  }

  async ngOnInit(): Promise<void> {
    this.user = this.currentUserService.getCurrentUser();
  }

  async loadFeatureTypes() {
    await this.fetchPreferences(this.featureCategory)
  }

  // fetch feature preference types from database
  async fetchPreferences(category) {
    try {
      this.featurePreferenceTypes = await this.featureTypeService.getAllByCategory(category).pipe(take(1)).toPromise();
      if (this.featurePreferenceTypes.length === 0) {
        this.categoryLoaded = false;
        this.schemaLoaded = false;
        this.snackBar.open('No feature type schemas found for category: ' + category, 'OK', { duration: 7000 });
      } else {
        this.categoryLoaded = true;
        await this.fetchJfmccOwners();
      }
    } catch (error) {
      console.error("Error fetching preference types:", error)
      this.friendlyErrorService.processError(error)
    }
  }
  // load the values of the feature pref version that the user selects
  async loadValues() {
    this.selectedOwner = undefined
    try {
      this.featurePreferences?.forEach(preference => {
        if (preference?.versionNum === this.versionNum) {
          this.owners?.forEach(owner => {
            if (preference.ownerId === owner.ownerId) {
              this.selectedOwner = owner
            }
          });
          this.versionName = preference.featurePrefName;
          this.schemaDefinition.Sections?.forEach(section => {
            section.Fields?.forEach(field => {
              field.value = preference.featurePrefValue[field.name]
            });
          });

          if(preference?.featurePrefValue?.doNotDeleteInd === true || preference?.featurePrefValue?.doNotDeleteInd === "true") {
            this.doNotDelete = true;
          } else {
            this.doNotDelete = false;
          }
        }
      });
    } catch (error) {
      console.error('Error fetching feature preference values:', error)
      this.friendlyErrorService.processError(error)
    }
  }
  // perform this function after a user selects a feature preference type from the picklist. Loads the schema definition and version numbers based off of the user's selection
  async loadVersionandSchemaDefinition() {
    try {
      this.featurePreferences = await this.featurePreferenceService.getAllByType(this.selectedPreference).pipe(take(1)).toPromise();
      this.schemaDefinition = (await this.featureTypeService.getById(this.selectedPreference).pipe(take(1)).toPromise()).featureTypeDefinition;
      this.schemaLoaded = true;
      this.versionNum = undefined;
      this.versionName = undefined;
      this.classificationOptions = await this.lookUpService.getLookupByType(Classification).pipe(take(1)).toPromise()
      // descending order fomng-840
      this.classificationOptions.sort((a, b) => {
        return a.sortBy < b.sortBy ? 1 : -1
      })
      this.sciControlsOptions = await this.lookUpService.getLookupByType(Control).pipe(take(1)).toPromise()
      this.otherControlsOptions = await this.lookUpService.getLookupByType(OtherControl).pipe(take(1)).toPromise()
      this.releasabilityOptions = await this.lookUpService.getLookupByType(Releasability).pipe(take(1)).toPromise()
      this.fgiControlOptions = await this.lookUpService.getLookupByType(Fgi).pipe(take(1)).toPromise()
    } catch (error) {
      console.error('Error fetching schema definition:', error)
      this.friendlyErrorService.processError(error)
    }
  }
  // handles adding a feature preference type to the database in the edit modal
  add() {
    const dialogRef = this.dialog.open(EditFeaturePreferenceModalComponent, {
      width: '560px', // Set the width and other properties as needed
    });
    // Subscribe to the modal's afterClosed event to get the result
    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      switch (result) {
        case true:
        case ModalResponse.CANCEL: 
          break;
        case ModalResponse.ADD:
          this.ngOnInit();
          break;
        default:
          throw 'Unhandled response type from Preference Modal'
      }
    })
  }
  // handles the submission of user values for a feature pref type schema
  async submitForm() {
    try {
      let newFeaturePref: FeaturePreference = new FeaturePreference();
      newFeaturePref.featurePrefValue = {};
      // check if values alerady exist for the schema.
      // if it exists, then load other column values from db and increment version num
      if (this.featurePreferences.length > 0) {
        const latestVersion = this.featurePreferences.reduce((max, preference) => Math.max(max, preference.versionNum), this.featurePreferences[0].versionNum);
        this.featurePreferences.forEach(preference => {
          if(preference.versionNum == this.versionNum) {
            newFeaturePref = preference;
          }
        });
        if (this.selectedOwner) {
          newFeaturePref.ownerId = this.selectedOwner.ownerId;
          newFeaturePref.ownerTypeId = this.selectedOwner.ownerTypeId;
        }
        // reset featurePrefId and version_ts so that they can be automatically assigned in db
        newFeaturePref.featurePrefId = undefined;
        newFeaturePref.versionDate = undefined;
        newFeaturePref.versionNum = latestVersion + 1;
        newFeaturePref.featurePrefName = this.versionName;
      } else { //if it doesn't exist, then create a new feature preference row in db
        // assign the columns that can't be null in db
        newFeaturePref.featureTypeId = this.selectedPreference;
        newFeaturePref.enabled = true;
        newFeaturePref.versionNum = 1;
        newFeaturePref.featurePrefName = this.versionName; 
        newFeaturePref.ownerId = this.selectedOwner ? this.selectedOwner.ownerId : this.owners[0].ownerId;
        newFeaturePref.ownerTypeId = this.selectedOwner ? this.selectedOwner.ownerTypeId : this.owners[0].ownerTypeId;
      }

      // assign user inputted field values to feature preferences object
      this.schemaDefinition.Sections.forEach((section) => {
        section.Fields.forEach((field) => {
          newFeaturePref.featurePrefValue[field.name] = field.value;
        });
        if (section.name === 'classificationFilter') {
          // create the classification filter string for the lookback based on the user's classification and caveats
          newFeaturePref.featurePrefValue['classificationFilterLookback'] = section.Fields.map((field) => {
            if (Array.isArray(field.value) && field.value.length > 0) {
              return field.value.join(',');
            } else {
              return field.value;
            }
          }).join('/');
        }
      });
      newFeaturePref.featurePrefValue['doNotDeleteInd'] = "false";
      this.featurePreferenceService.create(newFeaturePref).pipe(take(1)).subscribe(res => {
        this.snackBar.open('Feature preference values sent successfully', 'OK', { duration: 5000 });
      }, err => {
        console.error(err)
        this.friendlyErrorService.processError(err)
      });
      this.loadVersionandSchemaDefinition();
    } catch(error) {
      console.error("Error submitting feature preference form:", error)
      this.friendlyErrorService.processError(error)
    }
  }
  async fetchJfmccOwners() {
    // Gotta get the instance methods
    // Get the ownerTypes
    this.ownerTypes = await this.ownerTypeService.getAll().pipe(take(1)).toPromise();
    // Get the owners
    let owners = await this.ownerService.getAll().pipe(take(1)).toPromise();
  
    // Get the commandGraph
    const json = await this.commandHierarchyService.getAll({ sort: 'version_num', limit: 1 }).pipe(take(1)).toPromise();
    if (json && Array.isArray(json) && json.length) {
      // Parse the commandGraph from the JSON response
      const commandGraph = JSON.parse(json[0].treeObject);
      this.JFMCCs = [];
  
      // Extract JFMCCs from the commandGraph
      commandGraph.forEach(unified => {
        (unified as any).children.forEach(jfmcc => {
          this.JFMCCs.push(jfmcc);
        });
      });
      // Filter owners based on JFMCCs
      owners = owners.filter(item => {
        return this.JFMCCs.find(x => item.ownerId == x.commandId);
      });
  
    }
    // Assign the filtered and updated owners array to this.owners
    this.owners = owners;
  }

  async delete() {
    const ok = confirm(`Are you sure you want to delete version ${this.versionNum} of ${this.versionName}?`);
    if (ok) {
      try {
        await this.featurePreferenceService.delete(this.featurePreferences.find(preference => preference.versionNum === this.versionNum)).pipe(take(1)).toPromise();
        this.snackBar.open('Feature preference deleted successfully', 'OK', { duration: 5000 });
        this.loadVersionandSchemaDefinition();
      } catch (error) {
        console.error('Error deleting feature preference:', error)
        this.friendlyErrorService.processError(error)
      }
    }
  }
}
