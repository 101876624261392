import {JsonObject, JsonProperty, Any} from 'json2typescript';
import { Source } from '@models/source.model';
import { User } from '@models/user.model';

@JsonObject('DataTag')
export class DataTag {
@JsonProperty('data_tag_id', String, true)
dataTagId: string = undefined;
@JsonProperty('classification_string', String, true)
classificationString: string = undefined;
@JsonProperty('classification_code', String, true )
classificationDescription: string = undefined;
@JsonProperty('source_name', String, true )
sourceName: string  = undefined;
@JsonProperty('source_id')
sourceId: string = undefined;
@JsonProperty('data_tag', String, true )
dataTag: string = undefined;
@JsonProperty('mod_data_tag', String, true )
modDataTag: string = undefined;
@JsonProperty('mod_remarks', String, true )
modRemarks: string = undefined;
@JsonProperty('classification_id', String, true)
classificationId: string = undefined;
@JsonProperty('created_by', String, true)
createdBy: string = undefined;
@JsonProperty('user', User, true)
user: User = undefined;
}
