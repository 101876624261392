import { Component, OnDestroy, OnInit, EventEmitter, Output } from '@angular/core';
import { CommandService } from '@services/data-services/command.service';
import { FormBuilder, FormControl, FormGroup, FormsModule } from '@angular/forms';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { LookUpService } from '@services/data-services/lookup.service';
import { MatDialogRef } from '@angular/material/dialog';
import { EditModalBaseComponent } from '@fom-module/base-classes/edit-modal-base.component';
import { MatSelectModule } from '@angular/material/select';
import { ParserQueueEntry } from '@models/parser-queue-entry.model';
import * as moment from 'moment';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { User } from '@models/user.model';
import { JsonConverter } from '@services/global/json-converter';
import { ParserQueueService } from '@services/data-services/parser-queue.service';
import { map, catchError, switchMap, debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { Classification } from '@models/classification.model';
import { ClassificationDescriptionService } from '@services/classification-description.service';

@Component({
  selector: 'app-bulk-delete-modal',
  templateUrl: './bulk-delete-modal.component.html',
  styleUrls: ['./bulk-delete-modal.component.css']
})
export class BulkDeleteModal extends EditModalBaseComponent implements OnInit, OnDestroy {

  logos: any;
  tmpImg: any;
  currentImage: any;
  message: string;
  currentUser: boolean = false;
  user: any;
  commandOptions: any;
  numberEntries: any;
  filterParams: any;
  context: any;

  now = new Date();
  date1: Date;
  // startDate: Date = new Date();
  endDate: Date = new Date();
  startDate: Date = new Date();
  bulkDeleteForm = new FormGroup({
    archive: new FormControl(moment([])),
    toDate: new FormControl(moment([])),
  });
  uniqueOrigins: string[];

  constructor(public commandService: CommandService,
    public parserQueueService: ParserQueueService,
    public lookUpService: LookUpService,
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<BulkDeleteModal>,
    public messageBusService: MessageBusService) {
      super(commandService, dialogRef);
    }
  ngOnDestroy(): void {
  }

  ngOnInit() {
    this.isLoggedIn()
    Promise.resolve().then(() => {
      this.loadLookups();
    })
  }

  // change(dateEvent) {
  //   this.startDate.emit(dateEvent.value);
  // }

  catchDate1(event) {
    this.date1 = event;
  }

  confirmDelete() {
    this.parserQueueService.bulkDelete(this.filterParams).pipe(take(1)).toPromise();
    this.context.loadParserQueueEntries(true);
    this.context.selection.clear();
    // this.context.messageBusService.publishMessage(new GlobalBusMessage(GlobalMessageTriggers.CLOSE_MODAL));
    this.context.snackBar.open('Successfully discarded entries.', 'Close', { duration: 5_000 });
    this.dialogRef.close();
  }

  cancel() {
    this.context.loadParserQueueEntries(true);
    this.context.selection.clear();
    this.dialogRef.close();
  }

  isLoggedIn(){
    if(sessionStorage.getItem('token') !== null){
      this.currentUser = true
      this.user = JSON.parse(sessionStorage.user)
    }else{
      this.currentUser = false
      this.user = new User
      this.user.command = {}
    }
  }

  loadLookups() {
    this.commandService.getAll().subscribe(result => {
      this.commandOptions = result;
    });
  }
}

