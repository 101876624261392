import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter } from '../services/global/date-converter';
import { Command } from '@models/command.model';

@JsonObject('FeatureType')
export class FeatureType {
  @JsonProperty('feature_type_id', String, true)
  featureTypeId: string = undefined;
  @JsonProperty('feature_type_name', String, true)
  featureTypeName: string = undefined;
  @JsonProperty('feature_type_schema_file', String, true)
  featureTypeSchemaFile: string = undefined;
  @JsonProperty('feature_type_schema', Object, true)
  featureTypeSchema: any[] = [];
  @JsonProperty('feature_type_definition', Object, true)
  featureTypeDefinition: any[] = [];
  @JsonProperty('feature_category', String, true)
  featureCategory: string = undefined;
  @JsonProperty('version_num', Number)
  versionNum: number | undefined = undefined;
  @JsonProperty('version_ts', DateConverter, true)
  versionDate: Date = undefined;
  @JsonProperty('created_by', String, true)
  createdBy: string = undefined;
  @JsonProperty('create_ts', DateConverter, true)
  dateCreated: Date = undefined;
  @JsonProperty('update_ts', DateConverter, true)
  dateUpdated: Date = undefined;
  @JsonProperty('updated_by', String, true)
  updatedBy: string = undefined;
  @JsonProperty('delete_ts', DateConverter, true)
  deleteTs: Date = undefined;
  @JsonProperty('deleted_by', String, true)
  deletedBy: string = undefined;
  @JsonProperty('command_id', String, true)
  commandId: string = undefined;
  @JsonProperty('command', Command, true)
  command: Command = undefined;

  commandName: string = ''
}
