import { FormBuilder } from "@angular/forms";
import { EditModalBaseComponent } from "@fom-module/base-classes/edit-modal-base.component";
import { MessageBusService } from "@services/global/message-bus/messaging-bus.service";
import { Component, OnInit } from "@angular/core";
import { LookUpService } from "@services/data-services/lookup.service";
import { Command } from "@models/command.model";
import { MatDialogRef } from "@angular/material/dialog";
import { UtilityService } from "@services/utility.service";
import { CommandHierarchyService } from "@services/data-services/command-hierarchy.service";
import { User } from "@models/user.model";
import { CurrentUserService } from "@services/current-user-service";
import { CommandHierarchy } from "@models/command-hierarchy.model";
import { ElintFunctionTypeService } from "@services/data-services/elint-function-type.service";

@Component({
  selector: "app-elint-function-type-modal",
  templateUrl: "./edit-elint-function-type-modal.component.html",
  styleUrls: ["./edit-elint-function-type-modal.component.css"],
})
export class EditElintFunctionTypeModalComponent
  extends EditModalBaseComponent
  implements OnInit
{
  commandOptions: Command[];
  user: User;
  commandHierarchy: CommandHierarchy;

  flag: string = "";

  constructor(
    public elintFunctionTypeService: ElintFunctionTypeService,
    public lookUpService: LookUpService,
    public utilities: UtilityService,
    public formBuilder: FormBuilder,
    private commandHierarchyService: CommandHierarchyService,
    public dialogRef: MatDialogRef<EditElintFunctionTypeModalComponent>,
    public messageBusService: MessageBusService,
    private currentUserService: CurrentUserService
  ) {
    super(elintFunctionTypeService, dialogRef);
  }

  ngOnInit() {
    this.headerString = this.isNew
      ? "Add Function Type"
      : this.isDelete
      ? "Delete Function Type"
      : "Edit Function Type";
    this.user = this.currentUserService.getCurrentUser();
    this.editForm = this.formBuilder.group({
      elint_function_code: [
        { value: this.entity.elint_function_code, disabled: this.isDelete },
      ],
      elint_function_name: [
        { value: this.entity.elint_function_name, disabled: this.isDelete },
      ],
    });
  }
}
