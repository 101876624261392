import { Component, OnInit, Input, EventEmitter, Output, ViewChild, AfterViewInit } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatDrawer } from "@angular/material/sidenav";
import { TableWrapperService } from "../facet.service";
import { Facet } from "../models/facet";
import { FacetGroup } from "../models/facet-Group";
import { Filter } from "../models/filter";
import { DateFilter } from "../models/date-filter";
import { filterTerm } from "../models/filter-term";
// Note: the user will need to create the FacetGroup populated with the facet list. This is custom to each table. 
// Then the list of FacetGroups will be passed here to be displayed
// When building the FacetGroup the FacetGroup.property needs to be the column name in the db
@Component({
    selector: 'app-table-wrapper',
    templateUrl: './table-wrapper.component.html',
    styleUrls: ['./table-wrapper.component.css',]
  })
  export class TableWrapperComponent implements OnInit, AfterViewInit {

    @Input() facets: FacetGroup[] = []
    @Input() filters: Filter[] = []
    @Input() dateFilters: DateFilter[] = []
    @Input() defaultPageSize = 100
    @Input() resultsCount: number = 0
    @Input() showPaginator: boolean = false // If you don't want this one and you want to use your own
    // Two ways to read the selected facets
    @Output() searchQuery = new EventEmitter<filterTerm[]>(); // This returns the selected facets as a db query
    /* OR */
    @Output() selectedFacets = new EventEmitter<FacetGroup[]>(); // This returns the facet groups with the list of selected facets in it
    @Output() selectedFilter = new EventEmitter<Filter[]>(); // This returns the used filters 
    @Output() selectedDateFilter = new EventEmitter<DateFilter[]>(); // This returns the used filters 
    

    @Output() paginatorEvent = new EventEmitter<any>();
    /* EXAMPLE USAGE in your component
        handlePageEvent() {
            // link the paginator to your table, this is not using server side pagination 
            this.dataSource.paginator = this.tableWrapperService.paginator

            OR (DON'T DO BOTH)
            
            // Execute the search
            this.performTheSearch() -> you can read the values for the search from 
            this.tableWrapperService.paginator?.pageIndex and this.tableWrapperService.paginator?.pageSize as needed
        }
  }  */

    @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
    @ViewChild(MatDrawer, { static: false }) filterDrawwe!: MatDrawer;
    
    searchFilters: Filter[] = []
    searchTerm: string[] = []  
    
    constructor(private tableWrapperService: TableWrapperService){

    }
    ngOnInit(): void {
      
    }

    ngAfterViewInit() {
      if (this.showPaginator) {
        this.tableWrapperService.paginator = this.paginator
        this.paginatorEvent.emit()
      }
    }


    buildSearch() {
      // reset values
      const filterTerms = []
      //this.paginator.pageIndex = 0

      // If you have filters
      for(let filter of this.searchFilters)  {
          //this.searchTerm.push(`${filter.property} LIKE '%${filter.value}%'`)
          let term = new filterTerm()
          term.field = filter.property
          term.operator = 'ILIKE'
          term.value = filter.value
          filterTerms.push(term)
      }

      for(let group of this.facets) {
        let foundValues = []
        for(let facet of group.selectedFacets) {
          foundValues.push(facet.propertyValue)
        }
      if (foundValues.length > 0) {
        this.searchTerm.push(`${group.property} in (${foundValues.join(',')})`)
        let term = new filterTerm()
        term.field = group.property
        term.operator = 'IN'
        term.values = foundValues
        filterTerms.push(term)
      }
      }
      this.dateFilters.forEach(filter=> {
        if (filter.end && filter.start) {
          let term = new filterTerm()
          term.field = filter.property
          term.operator = 'BETWEEN'
          term.values = [filter.start, filter.end]
          filterTerms.push(term)
        } else if (filter.start && !filter.end) {
          let term = new filterTerm()
          term.field = filter.property
          term.operator = '>='
          term.values = [filter.start]
          filterTerms.push(term)
        } else if (!filter.start && filter.end) {
          let term = new filterTerm()
          term.field = filter.property
          term.operator = '<='
          term.values = [filter.end]
          filterTerms.push(term)
        }
      })
      //let encodedSearch = encodeURIComponent(this.searchTerm.join(" AND ")).replace(/\_/g, '%5F')
      this.searchQuery.emit(filterTerms)
    }


    // Can't get this to work when there's more than one selected in the same group
    clearFacets() {
      this.facets.forEach(group =>  {
        group.selectedFacets = []
      })
      this.buildSearch()
    }

    handlePageEvent(event: any) {
      this.paginatorEvent.emit()
    }

     executeSearch() {
      // If you don't need a search query listen for this callback
      this.selectedFacets.emit(this.facets)
      this.selectedFilter.emit(this.searchFilters)
      // If you need a search query this will create the query
      this.buildSearch()
    } 

    removeFacet(facet: Facet, group: FacetGroup): void {
      const index = group.selectedFacets.indexOf(facet);
  
      if (index >= 0) {
        group.selectedFacets.splice(index, 1);
      }
      this.buildSearch()
    }

    removeFilter(filter: Filter) {
     filter.value = null
     this.updatedSelectedFilters()
    }

    removeDateFilter(filter: DateFilter) {
      filter.end = filter.start = null
      this.updatedSelectedDateFilters()
     }
 
    updatedSelectedFacets() {
      // If you don't need a search query listen for this callback
      this.selectedFacets.emit(this.facets)
      // If you need a search query this will create the query
      this.buildSearch()
    }
    
    updatedSelectedFilters() {
      this.searchFilters = this.filters.filter(f => f.value != null)
      // If you don't need a search query listen for this callback
      this.selectedFilter.emit(this.searchFilters)
      // If you need a search query this will create the query
      this.buildSearch()
    }
    updatedSelectedDateFilters() {
      //this.dateFilters = this.dateFilters.filter(f => f.end != null)
      // If you don't need a search query listen for this callback
      //this.selectedDateFilter.emit(this.dateFilters)
      // If you need a search query this will create the query
      this.buildSearch()
    }

     // @ts-ignore
     childFacetTrackBy(index, facet: any) {
    return facet.propertyValue
}
  }