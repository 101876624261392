import { Component, OnInit } from '@angular/core';
import { MatDialogRef} from '@angular/material/dialog';
import { take } from 'rxjs/operators'
import { AccountRequestService } from '@services/data-services/account-request.service';
import { UserNotificationService } from '@services/notification.service'

@Component({
  selector: 'deny-account-request-modal',
  templateUrl: './deny-account-request.component.html',
  styleUrls: ['./deny-account-request.component.css']
})
export class DenyAccountRequestModalComponent implements OnInit {
  accreq: any;
  reason: string = ''

  constructor(
    public dialogRef: MatDialogRef<DenyAccountRequestModalComponent>,
    private accountReqService: AccountRequestService,
    private notificationService: UserNotificationService) {
  }

  ngOnInit() {
  }

  // deny the request and close dialog, any API error will go to default handler
  deny(){
    this.accountReqService.denyAccountRequest(this.accreq, this.reason).pipe(take(1)).subscribe( (res)=> {
      this.notificationService.showSuccess('This Request has been denied and a message sent to the Requester')
      this.dialogRef.close(res)
    })
  }

  cancel(){
    this.dialogRef.close()
  }
}