import { SelectionModel } from '@angular/cdk/collections';
import { removeSummaryDuplicates } from '@angular/compiler';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { BulkEditLocationModalComponent } from '@fom-module/edit-modals/bulk-edit-location-modal/bulk-edit-location-modal.component';
import { EditLocationModalComponent } from '@fom-module/edit-modals/edit-location-modal/edit-location-modal.component';
import { UnitUpdateComponent } from '@fom-module/unit-update/unit-update.component';
import { VersionHistoriesModalComponent } from '@fom-module/edit-modals/version-histories-modal/version-histories-modal.component';
import { Country } from '@models/country.model';
import { Location } from '@models/location.model';
import { Unit } from '@models/unit.model';
import { User } from '@models/user.model';
import { CurrentUserService } from '@services/current-user-service';
import { CommandHierarchyService } from '@services/data-services/command-hierarchy.service';
import { CountryService } from '@services/data-services/country.service';
import { LocationService } from '@services/data-services/location.service';
import { LookUpService } from '@services/data-services/lookup.service';
import { PicklistPrefService } from '@services/data-services/picklist-pref.service';
import { UnitService } from '@services/data-services/unit.service';
import { SortingService } from '@services/sorting.service';
import { UtilityService } from '@services/utility.service';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { Observable, Subject } from 'rxjs';
import { debounceTime, exhaustMap, filter, finalize, scan, startWith, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { AppInitService } from '../../../config/init.service';

const DEFAULT_SORT: Sort = {
  active: 'create_ts',
  direction: 'desc'
}

@Component({
  selector: 'app-track-maintenance',
  templateUrl: './track-maintenance.component.html',
  styleUrls: ['./track-maintenance.component.css']
})
export class TrackMaintenanceComponent implements OnInit {
  searchForm: FormGroup;
  isLoading = false;
  user: User;
  countries: Country[] = [];
  noCountry = {};
  filterByCountry: any = {};

  @ViewChild(MatAutocompleteTrigger, { static: true }) unitAutocomplete: MatAutocompleteTrigger;
  filteredUnits$: Observable<Unit[]> | undefined;
  private nextUnitsPage$ = new Subject();
  unitsPerPage: number;

  dataLength: number = 0;
  pageIndex: number = 0;
  pageSizeOptions: number[] = [10, 50, 100, 500, 1_000];
  pageSize: number = 50;

  // Use JSON attribute names to pass back through API when querying
  displayedColumns: string[] = ['select', 'classification_description', 'final_name', 'dtg_imaged_ts',
    'loc_time_ts', 'last_loc', 'latitude', 'longitude', 'el_not', 'last_loc_src', 'src_orig', 'activity', 'actions'];

  dataSource: TableVirtualScrollDataSource<Location> = new TableVirtualScrollDataSource<Location>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  selection: SelectionModel<Location> = new SelectionModel<Location>(true, []);

  constructor(
    private commandHierarchyService: CommandHierarchyService,
    private countryService: CountryService,
    private currentUserService: CurrentUserService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    public initService: AppInitService,
    private locationService: LocationService,
    private lookupService: LookUpService,
    private picklistPrefService: PicklistPrefService,
    private snackBar: MatSnackBar,
    private sortingService: SortingService,
    public utilities: UtilityService,
    private unitService: UnitService,
  ) {

  }

  ngOnInit(): void {
    this.user = this.currentUserService.getCurrentUser();
    this.filterByCountry = this.noCountry;

    this.unitsPerPage = this.initService.getConfig().unitPageSize || 20;

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    const filters = Object.assign({}, JSON.parse(sessionStorage.getItem('unitFilters')));
    this.initializeForm(filters);

    this.loadLookups();

    // Handle if Unit ID is passed from another Component.
    if (history.state.data && history.state.data.unit_id) {
      const unit_id = history.state.data.unit_id;
      this.unitService.getUnitByUnitId(unit_id).pipe(take(1)).subscribe(unit => {
        this.searchForm.controls.unit.setValue(unit);
        this.search(); //! 3. 
      });
    }
  }

  async loadLookups() {
    this.countries = await this.lookupService.getLookupByType(Country, true).pipe(take(1)).toPromise();

    const picklistFilter = { owner_id: this.user.unitOwnerId() }; this.picklistPrefService.getAll(picklistFilter).pipe(take(1)).subscribe(preferences => {
      this.sortingService.sortList(this.countries, preferences, 'name');
    });
  }

  loadData(params: Object = {}) {
    this.locationService.getAll(params, true).pipe(take(1)).subscribe(result => {
      this.dataSource.data = result.locations;

      setTimeout(() => {
        this.dataLength = result.count;
        this.paginator.length = result.count;
        this.paginator.pageIndex = this.pageIndex;
      });
    });
  }

  initializeForm(filters, clear = true): void {
    this.searchForm = this.formBuilder.group({
      unit: ['']
    });
    filters['sort'] = 'name'; // sort by name.

    const unitFilters$ = this.searchForm.get('unit').valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      filter(q => typeof q === 'string')
    );

    this.filteredUnits$ = unitFilters$.pipe(
      switchMap(filter => {
        // Reset current page whenever name filter changes.
        let currentPage = 1;
        return this.nextUnitsPage$.pipe(
          tap(() => this.isLoading = true),
          startWith(currentPage),
          // Note: Until the backend responds, ignore NextPage requests.
          exhaustMap(_ => this.searchUnits(filter, filters, currentPage)),
          tap(() => currentPage++),
          takeWhile(p => p.length > 0, true),
          scan((allUnits: any, newUnits: any) => allUnits.concat(newUnits), []),
          tap(units => {
            const unit = this.searchForm.get('unit')?.value || '';

            if (unit?.length) {
              if (units.length === 1 && unit.trim().toLocaleLowerCase() === units[0].fullName().toLocaleLowerCase()) {
                this.unitAutocomplete.closePanel();
                this.searchForm.controls.unit.setValue(units[0]);
              }
            }

            this.isLoading = false;
          })
        );
      })
    );
  }

  countryFilterChange(event): void {
    const filter = event.value.countryId ? { country_id: event.value.countryId } : {}
    this.initializeForm(filter);
  }

  displayUnit(unit?: Unit): string | undefined {
    return unit ? unit.fullName() : undefined;
  }

  search() { //! 2.
    let sort: any = this.sort;
    if (!sort.active || sort.direction === '') {
      sort = DEFAULT_SORT;
    }

    const params: Object = {
      'sort': sort.active,
      'sort_direction': sort.direction,
      'limit': this.pageSize,
      'offset': this.pageSize * this.pageIndex,
    };

    this.selection.clear();

    const unit = this.searchForm.controls.unit.value;
    if (unit && unit.unitId) {
      params['unit_id'] = unit.unitId;
    }

    this.loadData(params); //! 1.
  }

  edit(location: Location): void {
    this.openEditDialog(location);
  }

  delete(location: Location): void {
    this.openEditDialog(location, true);
  }

  bulkDelete(): void {
    this.openBulkEditDialog(true);
  }

  bulkEdit(): void {
    this.openBulkEditDialog();
  }

  viewHistory(location: Location): void {
    const dialogRef = this.dialog.open(VersionHistoriesModalComponent, { width: "900px" });
    dialogRef.componentInstance.locationId = location.shipLocationId;
  }

  openEditDialog(location: Location, isDelete = false): void {
    let isNew = false;
    if (!location) {
      location = new Location;
      isNew = true;
    }
    try {
      const dialogRef = this.dialog.open(UnitUpdateComponent, {
        width: '70%',
        height: '90%',
        panelClass: 'dialog-scrollable'
      });
      dialogRef.componentInstance.trackMaintEntity = location;
      dialogRef.componentInstance.isNew = isNew;
      dialogRef.componentInstance.isDelete = isDelete;
      dialogRef.componentInstance.fromTrackMaint = true;

      dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
        // Only search again if closed with object.
        if (result) {
          this.search();
        }
      });

    } catch (e) {
      throw e
    }
  }

  openBulkEditDialog(isDelete = false): void {
    const dialogRef = this.dialog.open(BulkEditLocationModalComponent, { width: '900px' });
    dialogRef.componentInstance.entities = this.selection.selected;
    dialogRef.componentInstance.isDelete = isDelete;

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      // Only search again if closed with a message object.
      if (result && result.message) {
        this.snackBar.open(result.message, 'OK', { duration: 5000 });
        this.search();
      }
    });
  }

  getFlag(country) {
    return this.countryService.getFlagUrl(country);
  }

  // Helpers used in selecting Locations for bulk action
  // All selected are all locations that can be edited...
  isAllSelected(): boolean {
    return this.selection.selected.length === this.dataSource.data.filter(e => this.canEdit(e)).length;
  }

  toggleSelectAll(): void {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      // Only select locations that the user can edit.
      this.dataSource.data.forEach(row => {
        if (this.canEdit(row)) {
          this.selection.select(row);
        }
      });
    }
  }

  /*Old logic is insufficient and limits to sysadmin
   canEdit(location: Location): boolean {
     return this.user.canEdit(location.unit);
   }*/

  canEdit(location: Location): boolean {
    //otherwise, matched record, limit delete to any supervisor for the JFMCC and Supervisor
    let canEdit = location?.unit.commandId === this.user?.JFMCCId;
    if (this.user?.currentJFMCC) {
      canEdit = canEdit || location?.unit.commandId === this.user.currentJFMCC?.commandId;
      canEdit = canEdit || location?.unit?.borrowerCommandId == this.user.currentJFMCC?.commandId
    }
    return (this.user.isSupervisor() && canEdit) || this.user.isSystemAdmin();
  }

  sortChange(event) {
    // reset page index
    this.pageIndex = 0;

    this.search()
  }

  pageChange(event: PageEvent): void {
    if (event.pageSize !== this.pageSize) {
      this.pageSize = event.pageSize;
    }

    if (event.pageIndex !== this.pageIndex) {
      this.pageIndex = event.pageIndex;
    }

    this.search();
  }

  searchUnits(nameFilter: string, filters: any, page: number): Observable<Unit[]> {
    const offset = page > 0 ? (page - 1) * this.unitsPerPage : 0;

    return this.unitService.findUnits(nameFilter, filters, this.unitsPerPage, offset);
  }

  onUnitOptionsScroll() {
    this.nextUnitsPage$.next();
  }
}
