import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FomPageBaseComponent } from '@fom-module/base-classes/fom-page-base.component';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { LookUpService } from '@services/data-services/lookup.service';
import { Command } from '@models/command.model';
import { UnitService } from '@services/data-services/unit.service';
import { ShipTypeService } from '@services/data-services/ship-type.service';
import { take } from 'rxjs/operators';
import { Port } from '@models/port.model';
import { Category } from '@models/category.model';
import { Country } from '@models/country.model';
import { ShipType } from '@models/ship-type.model';
import { Fleet } from '@models/fleet.model';
import { CommandHierarchyService } from '@services/data-services/command-hierarchy.service';
import { AppInitService } from '../../../config/init.service';
import { FriendlyErrorService } from '@services/friendly-error.service';

@Component({
  selector: 'app-coi',
  templateUrl: './coi.component.html',
  styleUrls: ['./coi.component.css']
})
export class COIComponent extends FomPageBaseComponent implements OnInit {

  currentUserABACPrefs: any;
  commands: any[] = [];
  selectedCommand: any;
  cois = [];
  user: any;
  model = { nextCOI: '', ship_type_id: '', commandName: '', commandId: ''};
  commandName: any;
  submitted = false;
  ports: [Port];
  port: Port;
  categories: [Category];
  category: Category;
  countries: [Country];
  country: Country;
  shipTypes: [ShipType];
  shipType: ShipType;
  fleets: [Fleet];
  fleet: Fleet;


  constructor(public messageBusService: MessageBusService,
      public route: Router, private lookups: LookUpService,
      public shipTypeService: ShipTypeService,
      public unitService: UnitService,
      private commandHierarchyService: CommandHierarchyService,
      public snackBar: MatSnackBar,
      private friendlyErrorService: FriendlyErrorService,
      public initService: AppInitService) {
    super(messageBusService, route);
  }

  ngOnInit() {
    this.user = JSON.parse(sessionStorage.getItem('user'));
    this.lookups.getLookupByType(ShipType, true).pipe(take(1)).subscribe(result => {
      this.shipTypes = result
      // nest the commands and the onCommandChange call to avoid any race to the server. onCommandChange
      // expects to have the ShipTypes loaded
      this.lookups.getLookupByType(Command).pipe(take(1)).subscribe(result => {
        this.commandHierarchyService.getAll({ sort: 'version_num', limit: 1}).pipe(take(1)).subscribe(json => {
          if (json && Array.isArray(json) && json.length) {
            const commandGraph = JSON.parse(json[0].treeObject)
            let JFMCCS = []
            commandGraph.forEach(unified => {
              (unified as any).children.forEach(jfmcc => {
                JFMCCS.push(jfmcc)
              })
            })
            result = result.filter(item => {
              return JFMCCS.find(x => item.commandId == x.commandId)
            })
          }
          this.commands = result;
          // set the Command dropdown to the user's default JFMCC. others will be available
          const id = this.user.currentJFMCC ? this.user.currentJFMCC.commandId : this.user.command_id
          this.selectedCommand = this.commands.find(x => x.commandId == id);
          this.onCommandChanged({value: this.selectedCommand})
        })
      });
    })

  }

  onCommandChanged(event) {
    let command = this.selectedCommand
    if (!command || !command.commandId) {
      return
    }
    this.shipType = this.shipTypes.find(item => item.shipTypeName == command.name)
    this.getcois(this.shipType)
  }

  getcois(shipType: ShipType) {
    if (shipType) {
      // pull 100 for sorting and presenting the highest, but only display 5 on screen
      this.unitService.find({ 
        ship_type_id: shipType.shipTypeId, 
        type_description: 'COI',
        sort: 'create_ts', 
        limit: 100
      }).subscribe(result => {
        if (result && result.length) {
          result.sort((a,b) => {
            return a.pennant < b.pennant ? 1 : a.pennant > b.pennant ? -1 : 0
          })
          this.cois = result;
          this.model.nextCOI = (Number(this.cois[0].pennant) + 1).toString()
        }
        else {
          this.cois = []
          this.model.nextCOI = '001'
        }
      })
    }
    else {
      this.cois = []
      this.model.nextCOI = '001'
    }
   }

  handleSubmit() {
    this.submitted = true;
    // open the snackbar, get the reference, and set the action to be a Cancel
    const snackBarReference = this.snackBar.open('COI Request sent to server. please wait...', 'Cancel');
    // we are going to send the message, and possible cancel the subscription
    // add the selected command's command_id to the model
    this.model.commandId = this.selectedCommand.commandId
    this.model.commandName = this.selectedCommand.name
    this.model.ship_type_id = this.shipType ? this.shipType.shipTypeId : null;
    // the API does it own lookup for port, fleet, etc.

    // with ABAC / JFMCC changes, the user may have created a COI but not in their JFMCC
    // send the JFMCC id, and perhaps borrow it out
    const id = this.user.currentJFMCC ? this.user.currentJFMCC.commandId : this.user.command_id;
    (this.model as any).JFMCCId = id;
    const request = this.unitService.createCOI(this.model);
    const subscription = request.pipe(take(1)).subscribe(response => {
      this.submitted = false;
      snackBarReference.dismiss();
      let currentUrl = this.route.url
      this.route.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.route.navigate([currentUrl]));
    }, error => {
      this.submitted = false;
      subscription.unsubscribe();
      this.friendlyErrorService.processError(error);
    });

    // if the snackBar is cancelled, cancel the request
    snackBarReference.onAction().pipe(take(1)).subscribe(() => {
      this.submitted = false;
      subscription.unsubscribe();
    });

  }
  handleCancel() {
    let currentUrl = this.route.url
    this.route.navigateByUrl('/', {skipLocationChange: true}).then(()=>
      this.route.navigate([currentUrl]));
    }
}
