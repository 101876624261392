import { JsonConvert } from 'json2typescript';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { Injectable } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AuthenticationApiService } from '@services/api-services/authentication-api.service';
import { GlobalStore } from '@services/global/message-bus/global-store.model';
import { User } from '@models/user.model';
import { CommandHierarchyService } from './command-hierarchy.service';

@Injectable()
export class AuthenticationService {
  private jsonConvert: JsonConvert = new JsonConvert();
  isAdmin: boolean;
  constructor(private authenticationApiSerivice: AuthenticationApiService,
    private commandHierarchyService: CommandHierarchyService,
    private messageBusService: MessageBusService) {

  }

  private sessionSetUp(data: any) {
    sessionStorage.setItem('token', data.token);
    sessionStorage.setItem('currentUserId', data.user_id);
    let storeData = {
      isLoggedIn: true,
      currentUserId: data.user_id,
      currentUser: this.jsonConvert.deserializeObject(data.user, User)
    };
    // const preferences = {
    //   command_id: [data.user.command ? data.user.command.command_id : null],
    //   port_id: null,
    //   op_area_id: null
    // }
    // // FOMNG-284, allow for a default and a current. allow user to 'reset' to default
    // sessionStorage.setItem('defaultUserABACPrefs', JSON.stringify(preferences));
    // sessionStorage.setItem('currentUserABACPrefs', JSON.stringify(preferences));
    this.commandHierarchyService.getAll({ sort: 'version_num', limit: 1 }).pipe(take(1)).subscribe(results => {
      const graph = this.processCommandGraph(results, storeData.currentUser)
      let user = data.user
      storeData.currentUser.JFMCCs = user.JFMCCs = graph
      storeData.currentUser.currentJFMCC = user.currentJFMCC = graph.length ? graph[0] : null
      this.messageBusService.updateStore(new GlobalStore(storeData));
      sessionStorage.setItem('user', JSON.stringify(user));
    })
  }

  // return an array of JFMCCs that the user belongs to
  // it could be a JFMCC or a command beneath it
  processCommandGraph(json, user) {
    let graph = []
    if (json && Array.isArray(json) && json.length) {
      const graphArray = JSON.parse(json[0].treeObject)
      graphArray.forEach(unified => {
        unified.children.forEach(jfmcc => {
          if (user.commandId == jfmcc.commandId) {
            graph.push(jfmcc)
          }
          jfmcc.children.forEach(command => {
            if (command.commandId == user.commandId) {
              graph.push(jfmcc)
            }
          });
        });
      });
    }
    return graph
  }

  public login(username: string, password: string): Observable<any> {
    const loginInfo = {
      username: username,
      password: password
    };
    return this.authenticationApiSerivice.login(loginInfo)
      .pipe(
        map((data) => {
          this.sessionSetUp(data)
          return data;
        })
      )
  }

  public sessionTearDown() {
    // just remove the ones set in sessionSetup
    // sessionStorage.clear() would interfere with getPKI
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('currentUserId');
    const storeData = {
      isLoggedIn: false,
      currentUserId: null,
      currentUser: null
    };
    sessionStorage.removeItem('user');
    // FOMNG-284, allow for a default and a current. allow user to 'reset' to default
    sessionStorage.removeItem('defaultUserABACPrefs');
    sessionStorage.removeItem('currentUserABACPrefs');
    this.messageBusService.updateStore(new GlobalStore(storeData));
  }

  public logout(reason, skipTearDown = false): Observable<any> {
    // mixed bag of sessionStorage items, any of which could be a sign the credentials are hanging around
    if (sessionStorage.getItem('token') != null || sessionStorage.getItem('currentUserId') != null || sessionStorage.getItem('user') != null) {
      return this.authenticationApiSerivice.logout(reason)
        .pipe(
          map((data) => {
            // even though other component have have their tear down logic, do it here too
            // reverse the sessionSetup
            if (!skipTearDown) {
              this.sessionTearDown()
            }
            return data;
          })
        )
    }
    else {
      if (!skipTearDown) {
        this.sessionTearDown()
      }
      return of<Response>();
    }
  }
  public certLogin(samlResponse: string): Observable<any> {
    const num = sessionStorage.getItem('PKIattempts');
    sessionStorage.setItem('PKIattempts', `${parseInt(num, 10) ? num + 1 : 1}`);

    return this.authenticationApiSerivice.certLogin(samlResponse)
      .pipe(
        map((data) => {
          this.sessionSetUp(data);
          return data;
        })
      );
  }
}
