import { Owner } from '@models/owner.model';
import { OwnerApiService } from '@services/api-services/owner-api.service';
import { Injectable } from '@angular/core';
import { JsonConverter } from '@services/global/json-converter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class OwnerService {
    private jsonConvert: JsonConverter;

    constructor(private ownerApiService: OwnerApiService) {
        this.jsonConvert = new JsonConverter();
    }

    public getAll(): Observable<Owner[]> {
        return this.ownerApiService.getAllOwners()
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, Owner);
                })
            );
    }

    public getOwnerByTypeId(ownerTypeId: string): Observable<Owner> {
        return this.ownerApiService.getOwnerByTypeId(ownerTypeId)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Owner);
                })
            );
    }
}
