import { FormGroup } from '@angular/forms';
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DummyDataService } from '@services/data-services/dummy-data.service';
import { take } from 'rxjs/operators'
import { UserNotificationService } from '@services/notification.service';
import { AppModule } from '../../app.module';

@Component({
  template: '<div></div>'
})
export class EditModalBaseComponent {
  isNew: boolean;
  // now going to handle the delete in the modal, allow user to see what they are deleting
  isDelete: boolean;
  // TODO make interface or baseclass for entity
  entity: any;
  editForm: FormGroup;
  items: any[] = []; // the existing items, used for add/edit dedup check
  // the string to pass to the modal header
  headerString: string;

  notificationService: UserNotificationService;

  constructor(public entityService: DummyDataService, 
    public dialogRef: MatDialogRef<any> = null) {
      this.notificationService = AppModule.injector.get(UserNotificationService)
  }

  handleSubmit() {
    if (this.editForm.valid) {
      this.updateEntity();

      if (this.isNew) {
        (this.entityService as any).create(this.entity).pipe(take(1)).subscribe(res => {
          this.dialogRef.close(res)
          this.notificationService.showSuccess('New item successfuly created', 3000)
          //this.messageBusService.publishMessage(new GlobalBusMessage(this.RELOAD_MESSAGE));
          //this.messageBusService.publishMessage(new GlobalBusMessage(GlobalMessageTriggers.CLOSE_MODAL));
        });
      } else {
        (this.entityService as any).update(this.entity).pipe(take(1)).subscribe(res => {
          this.dialogRef.close(res)
          this.notificationService.showSuccess('Item successfully edited', 3000)
          //this.messageBusService.publishMessage(new GlobalBusMessage(GlobalMessageTriggers.CLOSE_MODAL));
        });
      }
    }
  }

  handleCancel() {
    this.dialogRef.close()
    //this.messageBusService.publishMessage(new GlobalBusMessage(GlobalMessageTriggers.CLOSE_MODAL));
  }

  updateEntity() {
    for (let key in this.editForm.controls) {
      if (this.entity.hasOwnProperty(key.toString())) {
        this.entity[key.toString()] = this.editForm.controls[key]['value'];
      }
    }
  }


  handleDelete() {
    (this.entityService as any).delete(this.entity).pipe(take(1)).subscribe(res => {
      this.dialogRef.close(res)
      this.notificationService.showSuccess('Item successfully deleted', 3000)
      //this.messageBusService.publishMessage(new GlobalBusMessage(GlobalMessageTriggers.CLOSE_MODAL));
    });
  }

  // a shared handler to check for any new or edit entry. will set an error on the form
  checkDups(event, fieldName, isNumber = false) {
    // non char keyboard items still trigger, control not initialize w/input
    if (!this.editForm.controls[fieldName]['value']) {
      return
    }

    const value = isNumber ? this.editForm.controls[fieldName]['value'].toString().toUpperCase().trim() : this.editForm.controls[fieldName]['value'].toUpperCase().trim();

    // ok, do not check the item being edited, that's not right!
    const index = this.items.indexOf(this.entity)
    const found = this.items.find((x, i) =>
      i != index && (x[fieldName] ? x[fieldName].toString().toUpperCase().trim() == value : false)
    )
    if (found) {
      //console.log('found, setting error')
      this.editForm.controls[fieldName].setErrors({'duplicate entry': true });
    }
    else {
      const error = this.editForm.controls[fieldName].hasError('duplicate entry')
      if (error) {
        //console.log('un found, removing error')
        this.editForm.controls[fieldName].setErrors(null);
      }
    }
  }
}