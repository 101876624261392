import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from '@services/api-services/api.config';

@Injectable()
export class AreaApiService {
    SERVER_URL;

    constructor(
        private http: HttpClient, apiConfig: APIConfig) {
        this.SERVER_URL = apiConfig.TARGET_URL;
    }

    public getAllAreas(filters = {}): Observable<any> {
        let httpParams = new HttpParams();

        for (const key in filters) {
            if (filters.hasOwnProperty(key)) {
                httpParams = httpParams.set(key, filters[key]);
            }
        }

        return this.http.get(
            `${this.SERVER_URL}/areas`, { params: httpParams });
    }

    public getAreaByAreaId(opAreaId: string): Observable<any> {
        return this.http.get(
            `${this.SERVER_URL}/areas/${opAreaId}`);
    }

    public updateArea(area: any): Observable<any> {
        return this.http.put(
            `${this.SERVER_URL}/areas/${area.op_area_id}`, area);
    }

    public createArea(area: any): Observable<any> {
        return this.http.post(
            `${this.SERVER_URL}/areas`, area);
    }

    public deleteArea(area: any): Observable<any> {
        return this.http.delete(
            `${this.SERVER_URL}/areas/${area.op_area_id}`);
    }
}
