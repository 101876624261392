import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JsonConverter } from '../global/json-converter';
import { OptempoApiService } from '@services/api-services/optempo-api.service';
import { Optempo } from '@models/optempo.model';

@Injectable()
export class OptempoService {
    private jsonConvert: JsonConverter;

    constructor(private optempoApiService: OptempoApiService) {
        this.jsonConvert = new JsonConverter();
    }

    public getOptempoData(): Observable<Optempo[]> {
        return this.optempoApiService.getOptempoData()
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, Optempo);
                })
            );
    }
}
