import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { GlobalMessageTriggers } from '@services/global/message-bus/global-message-triggers.enum';
import { EditPocModalComponent as EditPocModal } from '@edit-modals/edit-poc-modal/edit-poc-modal.component';
import { GridBaseComponent } from '@fom-module/base-classes/grid-base.component';
import { pointOfContact as Poc } from '@models/point-of-contact.model';
import { PocService } from '@services/data-services/point-of-contact-data.service';
import { AppInitService } from '../../../config/init.service';
import { Command } from '@models/command.model';

@Component({
  selector: 'app-poc',
  templateUrl: './point-of-contact.component.html',
  styleUrls: ['./point-of-contact.component.css', '../base-classes/grid-base.component.css']
})
export class PocComponent extends GridBaseComponent implements OnInit {
  showOptions = 'all';
  commandId: string = null;
  allContacts: any;

  // Actual order of table items
  displayedColumns = ['commandName',
    'supportingCommands',
    'contactName',
    'contactPosition',
    'phone',
    'email',
    'link',
    'supportHours',
    'delete'];

  paginator: MatPaginator;
  sort: MatSort;

  constructor(public messageBusService: MessageBusService,
    private dialog: MatDialog,
    public pocService: PocService,
    public route: Router,
    public initService: AppInitService) {
      super(messageBusService, pocService, route);
      this.RELOAD_MESSAGE = GlobalMessageTriggers.RELOAD_PORT_DATA;
      this.EDIT_MODAL_COMPONENT = EditPocModal;
  }


  ngOnInit() {
    this.loadEntityData();
  }

  loadEntityData() {
    this.pocService.getAll().subscribe(entities => {
      this.allContacts = entities
      this.setDataSource(entities)
      this.setCommandName(entities)
    });
  }

  setDataSource(entities) {
    this.entities = entities;
    this.dataSource = new MatTableDataSource<object>(this.entities);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  // Set a value for command name that is sortable by mat-sort-header
  setCommandName(entities) {
    entities.forEach(element => {
      element.commandName = element.command.name
    });
  }

  edit(poc: any) {
    const dialogRef = this.dialog.open(EditPocModal, {
      width: '560px'
    });
    dialogRef.componentInstance.poc = poc
    dialogRef.componentInstance.isNew = false;

    dialogRef.afterClosed().subscribe(result => {
        this.loadEntityData()
    });

  }
  add() {
    const dialogRef = this.dialog.open(EditPocModal, {
      width: '560px'
    });
    let poc = new Poc();
    poc.command = new Command()
    dialogRef.componentInstance.poc = poc;
    dialogRef.componentInstance.isNew = true;

    dialogRef.afterClosed().subscribe(result => {
      // in order to get complete data for the new Parameter
        this.loadEntityData()
    })
  }
}
