import { ConfidenceCode } from '@models/confidence-code.model';
import { ConfidenceCodeApiService } from '@services/api-services/confidence-code-api.service';
import { Injectable } from '@angular/core';
import { JsonConverter } from '@services/global/json-converter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ConfidenceCodeService {
    private jsonConvert: JsonConverter;

    constructor(private confidenceCodeApiService: ConfidenceCodeApiService) {
        this.jsonConvert = new JsonConverter();
    }

    public getConfidenceCode(params: any): Observable<ConfidenceCode[]> {
        return this.confidenceCodeApiService.getConfidenceCode(params)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, ConfidenceCode);
                })
            );
    }

    public getAll(): Observable<ConfidenceCode[]> {
        return this.confidenceCodeApiService.getAllConfidenceCodes()
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, ConfidenceCode);
                })
            );
    }

    public getConfidenceCodeByConfidenceCodeId(confidenceCodeId: string): Observable<ConfidenceCode> {
        return this.confidenceCodeApiService.getConfidenceCodeByConfidenceCodeId(confidenceCodeId)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, ConfidenceCode);
                })
            );
    }

    public update(confidenceCode: ConfidenceCode): Observable<ConfidenceCode> {
        return this.confidenceCodeApiService.updateConfidenceCode(this.jsonConvert.serializeObject(confidenceCode))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, ConfidenceCode);
                })
            );
    }

    public create(confidenceCode: ConfidenceCode): Observable<ConfidenceCode> {
        return this.confidenceCodeApiService.createConfidenceCode(this.jsonConvert.serializeObject(confidenceCode))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, ConfidenceCode);
                })
            );
    }

    public delete(confidenceCode: ConfidenceCode): Observable<ConfidenceCode> {
        return this.confidenceCodeApiService.deleteConfidenceCode(this.jsonConvert.serializeObject(confidenceCode))
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }
}
