import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ReportingService } from '@services/data-services/reporting.service';
import { FomPageBaseComponent } from '@fom-module/base-classes/fom-page-base.component';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { Router } from '@angular/router';
import { MatSort, Sort } from '@angular/material/sort';
import { take, subscribeOn, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { UtilityService } from '@services/utility.service';
import { CountryService } from '@services/data-services/country.service';
import { AppInitService } from '../../../../config/init.service';
import { GridGroupingService, Group } from '@services/grid-grouping.service';
import { User } from '@models/user.model';
import { Area } from '@models/area.model';
import { Port } from '@models/port.model';
import { PicklistTypeService } from '@services/data-services/picklist-type.service';
import { PicklistPrefService } from '@services/data-services/picklist-pref.service';
import { CurrentUserService } from '@services/current-user-service';
import { LookUpService } from '@services/data-services/lookup.service';
import { Subject } from 'rxjs';
import { Country } from '@models/country.model';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { LocatorData } from '@models/locator-data.model';
import { LocatorPipe } from '@core-module/pipes/locator.pipe';

const DEFAULT_SORT: Sort = {
  active: 'rank',
  direction: 'asc'
};

@Component({
  selector: 'app-locator',
  templateUrl: './locator.component.html',
  styleUrls: ['./locator.component.css']
})
export class LocatorComponent extends FomPageBaseComponent implements OnInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dataSource = new TableVirtualScrollDataSource<any | Group>();
  startTicker: string = '';
  now = new Date();
  user: User;
  units: any

  allColumns =
    [
      { name: 'classificationName', restrictToAdmin: false },
      { name: 'unitName', restrictToAdmin: false },
      { name: 'sconum', restrictToAdmin: false },
      { name: 'portName', restrictToAdmin: false },
      { name: 'lastLoc', restrictToAdmin: false },
      { name: 'locTimeTS', restrictToAdmin: false },
      { name: 'country.triGraph', restrictToAdmin: false },
      { name: 'trackMaintenance', restrictToAdmin: false },
    ];

  // need to retain the filtering value for the refresh hack
  filterValue = '';
  // these objects represent the columns that can be grouped
  groupItems = [
    {
      display: 'Country',
      value: 'countryName',
      column_name: 'countryName',
      lookupId: 'countryId',
      table_name: 'country',
      type: Country,
      getSortSequence: function (row) {
        const country = (this as any).lookups.find(lookup => {
          return lookup.name === row.countryName;
        });
        if (!country) {
          return null;
        }
        const found = (this as any).picklistPrefs.find(pref => {
          return pref.sourcePicklistId === country.countryId;
        });
        return found ? found.sortSequence : null;
      }
    },
    {
      display: 'Op Area',
      value: 'opAreaTitle',
      column_name: 'opAreaTitle',
      lookupId: 'opAreaId',
      table_name: 'op_area',
      type: Area,
      getSortSequence: function (row) {
        // data contains opAreaId, so can match to the picklist pref.
        const found = (this as any).picklistPrefs.find(pref => {
          return pref.sourcePicklistId === row.opAreaId;
        });
        return found ? found.sortSequence : null;
      }
    },
    {
      display: 'Home Port',
      value: 'portName',
      column_name: 'portName',
      table_name: 'port',
      type: Port,
      getSortSequence: function (row) {
        const port = (this as any).lookups.find(lookup => {
          return lookup.name === row.portName;
        });
        if (!port) {
          return null;
        }

        const found = (this as any).picklistPrefs.find(pref => {
          return pref.sourcePicklistId === port.portId;
        });
        return found ? found.sortSequence : null;
      }
    },
    { display: 'No Grouping', value: 'All', column_name: null }
  ];
  // the current (default) group will be the first one in the list
  public grouping = this.groupItems[0];

  displayedColumns: any[] = [];

  groupByColumns: string[] = [this.grouping.value];

  countryMap = {};
  // grouping order by picklist
  picklistTypes: any;
  picklistPreferences: any;
  // to debounce the filter input
  filterTextChanged: Subject<string> = new Subject<string>();

  lastFilter: string;

  constructor(private reportingService: ReportingService,
    public messageBusService: MessageBusService,
    public utilities: UtilityService,
    private countryService: CountryService,
    public route: Router,
    private cd: ChangeDetectorRef,
    private picklistTypeService: PicklistTypeService,
    private picklistPrefService: PicklistPrefService,
    private currentUserService: CurrentUserService,
    private lookupService: LookUpService,
    public initService: AppInitService,
    private gridGroupingService: GridGroupingService,
    private locatorPipe: LocatorPipe) {
    super(messageBusService, route);
    this.filterTextChanged
      .pipe(debounceTime(1000), // wait 1 sec after the last event before emitting last event
        distinctUntilChanged()) // only emit if value is different from previous value
      .subscribe(model => {
        // Expand all groups so that after filtering matching groups are expanded regardless of starting state.
        this.changeAllGroups(true);
        // pull in the ngModel value to the dataSourceFilter
        this.unitFilter(model);
      });
  }

  unitFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }
  async ngOnInit() {
    this.user = this.currentUserService.getCurrentUser();

    this.displayedColumns = this.getDisplayedColumns();

    this.dataSource.sortingDataAccessor = this.dataAccessor;
    // set the filter predicate
    this.dataSource.filterPredicate = this.customFilterPredicate.bind(this);

    // get picklist prefs where they exist; if none, return empty array
    this.picklistPreferences = await this.picklistPrefService.getAll({
      owner_id: this.user.currentJFMCC ? this.user.currentJFMCC.commandId : this.user.commandId
    }).toPromise();

    // merge the lookups objects in the above code with the picklist types
    this.picklistTypes = await this.picklistTypeService.getAll().toPromise();
    this.groupItems.forEach(async item => {
      // Add PicklistType to grouping
      const found = this.picklistTypes.find(x => {
        return x.tableName === item.table_name;
      });

      if (found) {
        (item as any).picklistType = found;

        // Add any PicklistPrefs to grouping
        (item as any).picklistPrefs = this.picklistPreferences.filter(pref => {
          return pref.picklistTypeId === found.picklistTypeId;
        });
        // Load lookups for that type
        (item as any).lookups = await this.lookupService.getLookupByType(item.type, true).toPromise();
      } else {
        (item as any).picklistPrefs = [];

      }
    });

    this.loadUnits();

    super.ngOnInit();
  }

  loadUnits() {
    const filters = JSON.parse(sessionStorage.getItem('unitFilters'));

    this.reportingService.getLocator(filters).pipe(take(1)).subscribe(entities => {
      this.setDataSource(entities);
    });
    this.startTicker = 'true';
  }

  setDataSource(entities) {
    entities.forEach(element => {
      (element as any).flagSource = this.getFlag(element.country);
      (element as any).opAreaTitle = element.opAreaTitle === " - " ? "No Op Area" : element.opAreaTitle
    });
    this.entities = entities;
    this.units = entities;  // /stash them
    const sort: Sort = {
      active: 'isActive',
      direction: 'asc'
    };
    this.sortData(sort);
  }

  getFlag(country) {
    return this.countryService.getFlagUrl(country);
  }

  dataAccessor = (item, property) => {
    if (property.includes('.')) {
      return property.split('.').reduce((o, p) => o && o[p], item);
    }
    return item ? item[property] : null;
  }

  // when the user is typing the filter action will wait a sec before applying
  onFilterChange(event) {
    this.filterTextChanged.next(event);
  }

  public refreshPage() {
    this.startTicker = 'refresh';
    this.cd.detectChanges();
    this.clearFilter('');
    this.loadUnits();
  }


  // unit selected from the nob-ticker. let's trigger the filter
  unitSelected(unit) {
    this.filterValue = unit.final_name;
    this.unitFilter(unit.final_name);
  }

  sortData(sort) {
    //If sort gets cycled, reset to rank sort.
    if (!sort.active || sort.direction === '') {
      sort = DEFAULT_SORT;
    }

    const isAsc = sort.direction === 'asc';
    const data = this.units.sort((a, b) => {
      return this.compare(a, b, isAsc, sort.active);
    });
    this.units.forEach(element => {
      element.rowClass = this.rowClassifier(element)
    });
    this.dataSource.data = this.gridGroupingService.buildDataSourceData(data, this.groupByColumns[0]);

    this.displayedColumns = this.getDisplayedColumns();
  }

  getDisplayedColumns(): any[] {
    let columns = this.allColumns.filter(value => value.name !== this.grouping.column_name);

    if (!this.user.isAdmin()) {
      columns = columns.filter(value => !value.restrictToAdmin);
    }

    return columns.map(e => e.name);
  }

  isGroup(index, item): boolean {
    return item.isGroup;
  }

  clearFilter = (value: string) => {
    this.filterValue = '';
    this.dataSource.filter = '';
  }

  // adding a class to the mat-row in a single row by row call at time of data load
  // so that the digest loop doesn't waste so many resources
  rowClassifier(record): string {
    // now, regardless the column, look for RTP, INPT
    if (record.activityCategoryCode && record.activityCategoryCode.trim() === 'RTP' ||
      record.activityCategoryCode && record.activityCategoryCode.trim() === 'INPT') {
      return 'unitGreen';
    } else if (record.categoryDescription === 'SUB') {
      return 'unitRed';
    }
    return 'unitWhite';
  }

  // grouping

  async groupingChanged(sort, grouping) {
    this.groupByColumns = [grouping.value];

    this.sortData(sort);
  }

  compare(a, b, isAsc, active) {
    // sort by grouped column (honoring picklist preferences if they exist) first, then the active sort column.
    if (this.picklistPreferences.length > 0 && this.grouping.value !== "All") {
      const aSequence = this.grouping.getSortSequence(a);
      const bSequence = this.grouping.getSortSequence(b);
      if (aSequence > bSequence) {
        return 1;
      }

      if (aSequence < bSequence) {
        return -1;
      }
    } else {
      if (a[this.grouping.value] > b[this.grouping.value]) {
        return 1;
      }

      if (a[this.grouping.value] < b[this.grouping.value]) {
        return -1;
      }
    }

    if (active == 'lastLoc' || active == 'locTimeTS') {
      // Many undefined values or values we aren't displaying,
      // sort to handle those.
      return this.handleLocationSort(a, b, active, isAsc)
    } else {
      return (a[active] < b[active] ? -1 : 1) * (isAsc ? 1 : -1);
    }
  }

  handleLocationSort(a, b, active, isAsc) {
    // If value is removed from NOB or is undefined ("No last location"
    // in view), send it to the bottom.
    let a_value = a.isRemovedFromNob || !a[active] ? "~" : a[active]
    let b_value = b.isRemovedFromNob || !b[active] ? "~" : b[active]
    return (a_value < b_value ? -1 : 1) * (isAsc ? 1 : -1);
  }

  groupHeaderClick(row) {
    row.expanded = !row.expanded;
    this.lastFilter = performance.now().toString();
    this.dataSource.filter = this.lastFilter;
  }

  // this routine runs once per row of data, whether it's a Group or a unit
  // the Group should be hidden if none of it's unit pass the filter
  // the unit should be hidden if the final name does not contain the string
  customFilterPredicate(data: any | Group, filter: string): boolean {
    const triggered = filter === this.lastFilter ? true : false;
    if (data instanceof Group) {
      if (triggered && !data.expanded) {
        // if a group row was clicked and the group is collapsed always show the group row.
        return true;
      } else {
        // Otherwise show the group if it contains any items matching the filterValue.
        return data.items.some(x => this.matchesFilter(x, this.filterValue));
      }
    } else {
      // if a group row was clicked and the group is collapsed, always hide contained data rows.
      if (triggered && !data.group.expanded) {
        return false;
      } else {
        // Otherwise show the data row only if it matches the filterValue.
        return this.matchesFilter(data, this.filterValue);
      }
    }
  }

  matchesFilter(item: any, filter: string): boolean {
    return item.unitName.toLowerCase().indexOf(filter.toLowerCase()) > -1;
  }

  navigateTrackMaintenance(data: LocatorData): void {
    this.route.navigate(['/admin/tasks/track-maintenance'], { state: { data: { unit_id: data.unitId } } });
  }

  hasMultipleGroups(): boolean {
    return this.dataSource.filteredData.filter((e, index) => this.isGroup(index, e)).length > 1;
  }

  hasAnyExpandedGroups(): boolean {
    return this.dataSource.filteredData.filter((e: any, index) => this.isGroup(index, e) && e.expanded).length > 0;
  }

  changeAllGroups(expanded: boolean): void {
    this.dataSource.filteredData.filter((e, index) => this.isGroup(index, e)).forEach((e: any) => e.expanded = expanded);

    // Force filter to run so view gets updated
    this.lastFilter = performance.now().toString();
    this.dataSource.filter = this.lastFilter;
  }
}
