<app-classification-bar class="top-modal-classification-bar"></app-classification-bar>
<app-fdtm-modal-header [headerString]="headerString"></app-fdtm-modal-header>

<div mat-dialog-content>
  <div>
    <p *ngIf="message"><span class="green">{{message}}</span></p>
  </div>

  <form [formGroup]="editForm">
    <mat-form-field class="input-full-width">
      <mat-select formControlName="messageType" placeholder="Message Type" required (selectionChange)="setParameterTemplate($event.value, editForm)">
        <mat-option *ngFor="let option of this.messageTypeOptions"
          [disabled]="this.invalidMessageTypes.includes(option) && this.entity.messageType !== option"
          [value]="option">
          {{ option }}
        </mat-option>
      </mat-select>
      <!-- <input matInput placeholder="Message Type" required formControlName="messageType" autocomplete="off"> -->
    </mat-form-field>
    <br>

    <mat-label>Enabled: </mat-label>
    <mat-radio-group fxLayout="column" formControlName="enabled" placeholder="Enabled">
      <mat-radio-button value="true" [checked]="this.entity.enabled">True</mat-radio-button>
      <mat-radio-button value="false" [checked]="!this.entity.enabled">False</mat-radio-button>
    </mat-radio-group>
    <br>

    <mat-form-field class="input-full-width">
      <textarea matInput placeholder="Message Params" required formControlName="messageParams" autocomplete="off"></textarea>
      <mat-error *ngIf="editForm.controls?.messageParams?.errors?.['jsonInvalid']">
        Must be valid JSON
      </mat-error>
    </mat-form-field>
    <br>

    <br>
    <div mat-dialog-actions>
      <button [disabled]="!editForm.valid" mat-raised-button color="primary" value="Upload"
        (click)="uploadForm($event)">Submit</button>
      <button mat-raised-button (click)="handleCancel()">Cancel</button>
    </div>
  </form>
</div>

<app-classification-bar class="bottom-modal-classification-bar"></app-classification-bar>
