import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { JsonConverter } from '@services/global/json-converter';
import { map, catchError } from 'rxjs/operators';
import { AccountRequestApiService } from '@services/api-services/account-request-api.service';
import { AccountRequest } from '@models/account-request.model';

@Injectable()
export class AccountRequestService {
  private jsonConvert: JsonConverter;

  constructor(private accountRequestApiService: AccountRequestApiService) {
    this.jsonConvert = new JsonConverter();
  }

  /**
   * data = {
   *   requestedChanges: {} user object w/ new values
   * }
   */
  public requestNewAccount(data: object): Observable<any>  {
    return this.accountRequestApiService.requestAcc(data).pipe(
      map( (data) => {
        return data
      })
    )
  }

  public requestUpdateAccount(data: object): Observable<any>  {
    return this.accountRequestApiService.exsistingAccountRequest(data).pipe(
      map( (data) => {
        return data
      })
    )
  }

  public getAllAccountRequests(): Observable<any> {
    return this.accountRequestApiService.getAccountRequests().pipe( map(res => {
      return res
    }))
  }

  public getAllAccountRequestsCount(): Observable<any> {
    return this.accountRequestApiService.getAccountRequestCount().pipe( map(res => {
      return res
    }))
  }

  public getAccountRequst(id: string): Observable<any> {
    return this.accountRequestApiService.getAccountRequstById(id).pipe( map(res => {
      return res
    }))
  }

  public denyAccountRequest(accountRequest: any, reason: string): Observable<any> {
    const id = accountRequest.id
    return this.accountRequestApiService.denyChanges(id, reason).pipe( map(res => {
      return res
    }))
  }

  public applyAccountRequest(data: object): Observable<any> {
    return this.accountRequestApiService.applyChanges(data).pipe( map(res => {
      return res
    }))
  }
  // find requests for user_id 
  public findAccountRequstByUserId(id: string): Observable<any> {
    return this.accountRequestApiService.findAccountRequstByUserId(id).pipe( 
      map(data => {
      return this.jsonConvert.deserializeObject(data, AccountRequest);
    }))
  }
}
