<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css">
<!--<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.5.0/css/all.css"> -->
<div class="admin-work-area">
  <div fxLayout fxLayoutAlign="center center" class="simple-grid-title">
    <span class="grid-title">{{initService.getConfig().titlePrefix}} FDT-M Classification Marking Map
      <mat-icon
        matTooltip="These entries designate specific abbreviation values for classification marking categories (e.g. REL TO) that identify a grouping of specific values. Example: NATO (Primary Designator) that maps to several Country Trigraphs (Related Designator). This information is used to properly filter and roll-up security classifications on FDT-M products.">help</mat-icon>
    </span>
    <!-- Add Unit Floating Action Button -->
    <button mat-mini-fab class="add-button" (click)="add()">
      <img class="light-icon" src="./assets/icons/ic_add_white_24px.svg"
        matTooltip="Add Classification Marking Relationship" />
    </button>
  </div>
  <div fxLayout fxLayoutAlign="center center">
    <mat-form-field class="groupSelect">
      <mat-select [(ngModel)]="selectedCategory" placeholder="Filter Marking Category"
        (selectionChange)="onCategoryChange($event)">
        <mat-option [value]="'All'">All</mat-option>
        <mat-option *ngFor="let category of categories" [value]="category">
          {{category.classMarkingName}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="mat-elevation-z8" *ngIf="entities.length > 0">
    <!-- Paginator -->
    <mat-paginator [pageSizeOptions]="[10, 20, 50, 100, 1000]" [pageSize]="20" [length]="dataSource?.data?.length"
      showFirstLastButtons></mat-paginator>
    <table mat-table [dataSource]="dataSource" matSort matSortStart="desc" (matSortChange)="sortData($event)">

      <!-- Data Columns -->
      <ng-container matColumnDef="markingCategory">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Marking Category</th>
        <td mat-cell *matCellDef="let element">
          {{element.classMarkingName}}
        </td>
      </ng-container>

      <ng-container matColumnDef="primaryMarking">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Primary Marking </th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.primaryMarkClassification; else next">
            {{element.relatedChildList.primaryMarkClassification.classification_name}}
          </ng-container>
          <ng-template #next>
            <ng-container *ngIf="element.primaryMarkSci; else next2">
              {{element.primaryMarkSci.control_mark}}
            </ng-container>
          </ng-template>
          <ng-template #next2>
            <ng-container *ngIf="element.primaryMarkFgi; else next3">
              {{element.primaryMarkFgi.fgi_name}}
            </ng-container>
          </ng-template>
          <ng-template #next3>
            <ng-container *ngIf="element.primaryMarkControl; else next4">
              {{element.primaryMarkControl.control_name}}
            </ng-container>
          </ng-template>
          <ng-template #next4>
            <ng-container *ngIf="element.classMarkingName; else blank">
            </ng-container>
          </ng-template>
          <ng-template #blank>
            <p></p>
          </ng-template>
          <!---->

          <ul class="list-group" *ngFor="let item of data.ParentChildchecklist">
            <!--Filter out so we don't get dupes-->
            <ng-container *ngIf="element.classMarkingName === item.classMarkingName; else next">

              <!-- Parent Item -->
              <li class="list-group-item" *ngFor="let item of item.categoryDetail">

                <!-- Parent Checkbox -->


                <span>
        <td>
          <button mat-button class="delete-button" (click)="$event.stopPropagation()" (click)="edit(item)">
            <mat-icon>edit</mat-icon>
          </button>
        </td>
        <td>
          <button mat-button class="delete-button" (click)="$event.stopPropagation()" (click)="handleDelete(item)">
            <img src="./assets/icons/ic_delete_gray_24px.svg" />
          </button>
        </td></span>

        {{item.primaryMarkingId}}
        <!-- Parent Arrow -->
        <span (click)="$event.stopPropagation()" (click)="expandCollapse(item)">

          <mat-icon *ngIf="item.isClosed;else isCollapsed">expand_less</mat-icon>
          <ng-template #isCollapsed>
            <mat-icon>expand_more</mat-icon>
          </ng-template>
        </span>
        <div class="child-list" [hidden]="item.isClosed">

          <ul class="list-group level-two">
            <!-- Child Item -->
            <li class="list-group-item level-two" *ngFor="let itemChild of
                          item.primaryDetail">
              <!-- Child Checkbox -->
              &#x2022;
              {{getRelatedItemChildVal(itemChild)}}
            </li>
          </ul>

        </div>
        </li>
      </ng-container>
      </ul>


      <!----->
      </td>
      </ng-container>

      <ng-container matColumnDef="relatedMarking">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Related Marking </th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.relatedMarkClassification; else next">
            {{element.relatedMarkClassification.classification_name}}
          </ng-container>
          <ng-template #next>
            <ng-container *ngIf="element.relatedMarkSci; else next2">
              {{element.relatedMarkSci.control_mark}}
            </ng-container>
          </ng-template>
          <ng-template #next2>
            <ng-container *ngIf="element.relatedMarkFgi; else next3">
              {{element.relatedMarkFgi.fgi_name}}
            </ng-container>
          </ng-template>
          <ng-template #next3>
            <ng-container *ngIf="element.relatedMarkControl; else next4">
              {{element.relatedMarkControl.control_name}}
            </ng-container>
          </ng-template>
          <ng-template #next4>
            <ng-container *ngIf="element.relatedMarkReleasability; else blank">
              {{element.relatedMarkReleasability.releasability_name}}
            </ng-container>
          </ng-template>
          <ng-template #blank>
            <p></p>
          </ng-template>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr class="hyper" matTooltip="Edit Classification Marking Relationship" matTooltipPosition="before" mat-row
        *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>