import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { BlueUnit} from '@models/blue.model';
import { BlueLocation} from '@models/blue-location.model'
import { BlueService } from '@services/data-services/blue.service';
import { BlueLocationService } from '@services/data-services/blue-location.service';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AppInitService } from '../../../../config/init.service';
import { MatPaginator } from '@angular/material/paginator';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-blue-search',
  templateUrl: './blue-search.component.html',
  styleUrls: ['./blue-search.component.css']
})
export class BlueSearchComponent implements OnInit {

  @ViewChild(MatSort, {static:  true}) sort: MatSort;
  //public dataSource = new TableVirtualScrollDataSource<any>();
  public dataSource = new MatTableDataSource()
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  pageSizeOptions= [100, 200, 500, 1000, 5000]
  pageSize = 200 
  dataLength = 0

  displayedColumns =
  [
  'dateCreated',
  'blueUnit.blueUnitName',
  'blueUnit.pennant',
  'blueUnit.sconum',
  'blueUnit.cCode',
  'blueUnit.blueUnitCode',
  'latitude',
  'longitude',
  'dateTime',
  'course',
  'speed',
  'trackNum',
  'uid'
  ];

  // need to retain the filtering value for the refresh hack
  filterValue = ''
  // to debounce the filter input
  filterTextChanged: Subject<string> = new Subject<string>();
  
  constructor(private blueService: BlueService, 
    private blueLocationService: BlueLocationService,
    public initService: AppInitService) {
      this.filterTextChanged
      .pipe(debounceTime(1000), // wait 1 sec after the last event before emitting last event
      distinctUntilChanged()) // only emit if value is different from previous value
      .subscribe(model => {
        this.pageChange({pageSize: this.pageSize, pageIndex: 0}, model)
        // pull in the ngModel value to the dataSourceFilter
        //this.dataSource.filter = model.trim().toLocaleLowerCase();
      });
     }

  ngOnInit() {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = this.dataAccessor;
    // this.dataSource.filterPredicate = (data, filter) => {
    //   return (data as any).blueUnit.blueUnitName.toLowerCase().indexOf(filter.toLowerCase()) != -1 ||
    //   ((data as any).blueUnit.sconum ? (data as any).blueUnit.sconum.toLowerCase().indexOf(filter.toLowerCase()) != -1 : false) ||
    //   (data as any).blueUnit.pennant.toString().toLowerCase().indexOf(filter.toLowerCase()) != -1
    // }
    this.pageChange({pageSize: this.pageSize, pageIndex: 0}, '')
  }

  // when the user is typing the filter action will wait a sec before applying
  onFilterChange(event) {
    this.filterTextChanged.next(event);
  }

  pageChange = (event, filter) => {
    //console.log(event)
    filter = filter || this.filterValue
    const limit = event.pageSize
    const index = event.pageIndex
    let paging = {
      where: {},
      limit: limit,
      offset: index * limit
    }
    if (filter && filter.length) {
      // currently the API does what it wants with the string
      paging.where = filter
    }
    this.blueLocationService.getLocationsPage(paging).pipe(take(1)).subscribe(result => {
      //console.log(result)
      this.dataSource.data = result.locations;
      this.dataLength = result.count;
    })
  }
    // a custom getter for properties. if a property is displayed using dot notation 
  // because it is a property of a linked object, the getter needs to traverse the object

  dataAccessor = (item, property) => {
    if (property.includes('.')) {
      return property.split('.').reduce((o, p) => o && o[p], item)
    }
    return item ? item[property] : null;
  }

  sortData(sort: Sort) {
      const data = this.dataSource.data
      const sorted = data.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        return this.compare(a, b, isAsc, sort.active);
      });
      this.dataSource.data = sorted;
  }
  compare(a, b, isAsc, active) {
    return (a[active] < b[active] ? -1 : 1) * (isAsc ? 1 : -1);
  }

  // when unit filter is called do not limit the search range to just a page of data
  unitFilter = (value: string) => {
      this.pageChange({pageSize: this.pageSize, pageIndex: 0}, value)
  }

  clearFilter = (value: string) => {
    this.filterValue = '';
    this.unitFilter('')
  }
}
