import { Component, Input, AfterContentInit, ComponentRef, ViewChild, ViewContainerRef, ComponentFactoryResolver, ChangeDetectorRef } from '@angular/core';
import { DynamicComponentInterface } from './dynamic-component.interface';

@Component({
  selector: 'dynamic-component',
  templateUrl: './dynanmic-component.component.html',
  styleUrls: ['./dynanmic-component.component.css']
})
export class DynamicComponentComponent implements AfterContentInit {

  componentRef: ComponentRef<any>;
  @Input() component: DynamicComponentInterface;
  @Input() dynamicData: any;
  @ViewChild('dynamicComponent', { read: ViewContainerRef, static: true }) dynamicComponent : ViewContainerRef;

  constructor(private resolver: ComponentFactoryResolver) {
  }

  ngAfterContentInit() {
    let formFactory = this.resolver.resolveComponentFactory(this.component.resolveDynamicComponentClass());
    this.componentRef = this.dynamicComponent.createComponent(formFactory);
    this.componentRef.instance.dynamicData = this.dynamicData;
  }

}
