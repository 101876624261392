import { Component, OnInit, OnDestroy } from '@angular/core';
import { ReportingService } from '@services/data-services/reporting.service';
import { FomPageBaseComponent } from '@fom-module/base-classes/fom-page-base.component';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { Router } from '@angular/router';
import { UserNotificationService } from '@services/notification.service';
import { take } from 'rxjs/operators';
import { AppInitService } from '../../../../config/init.service';

@Component({
  selector: 'app-pacmis',
  templateUrl: './pacmis.component.html',
  styleUrls: ['./pacmis.component.css']
})
export class PacmisComponent extends FomPageBaseComponent implements OnInit, OnDestroy {

  status: string = 'ready';

  // track the subscription in case of navigating away
  subscription: any;

  constructor(
    private reportingService: ReportingService,
    private notifier: UserNotificationService,
    public messageBusService: MessageBusService,
    public route: Router,
    public initService: AppInitService) {
      super(messageBusService, route);
  }
  ngOnInit() {
    super.ngOnInit();
  }

  // if we navigate away but not done, kill the subscription
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  public getPACMIS() {
    this.status = 'in progress'
    this.subscription = this.reportingService.getPACMIS().pipe(take(1)).subscribe(nob => {
      this.subscription.unsubscribe()
      this.status = 'complete'
      this.notifier.showSuccess('Download Complete', 3000)
      this.downloadMessage(nob);
    }, err => {
      // we caught the error only to stop the progress bar
      this.subscription.unsubscribe()
      this.status = 'complete'
      // we still want to throw it to the global ErrorHandler
      throw (err)
    });
  }
  downloadMessage(nob: any) {
    const fileName = 'PACMIS.txt';
    const file = new Blob([nob]);
    if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(file, fileName);
    } else {
            const a = document.createElement('a');
            a.href = window.URL.createObjectURL(file); // xhr.response is a blob
            a.style.display = 'none';
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
    }
  }

  cancel() {
    if (this.subscription) {
      this.subscription.unsubscribe()
      this.status = 'ready'
      this.notifier.showError('Request Cancelled', 4000)
    }
  }
}
