import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { FomPageBaseComponent } from '@fom-module/base-classes/fom-page-base.component';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { ParserQueueEntry } from '@models/parser-queue-entry.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ParserQueueService } from '@services/data-services/parser-queue.service';
import { Area } from '@models/area.model';
import { Activity } from '@models/activity.model';
import { LookUpService } from '@services/data-services/lookup.service';
import { GlobalMessageTriggers } from '@services/global/message-bus/global-message-triggers.enum';
import { GlobalBusMessage } from '@services/global/message-bus/global-bus-message.model';
import { ConfirmationModalComponent } from '@core-module/app-components/confirmation-modal/confirmation-modal.component';
import { empty, timer, Observable, Subscription, Subject } from 'rxjs';
import { map, catchError, switchMap, debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { EditParserQueueModalComponent } from '@edit-modals/edit-parser-queue-modal/edit-parser-queue-modal.component';
import { UtilityService } from '@services/utility.service';
import { AppInitService } from '../../../config/init.service';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { GridGroupingService, Group } from '@services/grid-grouping.service';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { User } from '@models/user.model';
import { CurrentUserService } from '@services/current-user-service';
import { CommandService } from '@services/data-services/command.service';
import { UserJfmccService } from '@services/user-jfmcc.service';
import { SelectionModel } from '@angular/cdk/collections';
import { JsonConverter } from '@services/global/json-converter';
import { isEmptyExpression } from '@angular/compiler';
import { MatTableDataSource } from '@angular/material/table';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ParserRawMessage, ParserTrack } from '@models/parser-raw-message.model';

const DEFAULT_SORT: Sort = {
  active: 'locTimeTs',
  direction: 'asc'
};
//TODO: may want to move some of these new fields into base class and or configurables--get this working first
const DB_DEFAULT_SORT = "loc_time_ts"
const DB_DEFAULT_GROUP = "parser_name"
const DB_DEFAULT_SEARCH = "command_id"


@Component({
  selector: 'app-parser-queue-b',
  templateUrl: './parser-queue-b.component.html',
  styleUrls: ['./parser-queue-b.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParserQueueBComponent implements OnInit, OnDestroy, AfterViewInit {
  //expandedElement: ParserQueueEntry = new ParserQueueEntry()
  //@ViewChild('expandDetail', { static: true }) expandDetail: ElementRef;
  REFRESH_INTERVAL = this.initService.getConfig().PARSER_QUEUE_REFRESH_RATE || 600_000; // check parser queue data every 60 seconds.
  DB_DEFAULT_SORT = "loc_time_ts"
  DB_QUERY_LIMIT = 10; //query from DB is set to use a range; this is max number of rows to query at once
  db_offset = 0; // query from DB is set to use a range; this is current lower bound
  db_rowCount = 0; // how many rows are in the DB so we can properly set paginator
  db_query_limit = 10; // how many rows to fetch -- going to match pagesize after init later on for simplicity

  timer$: Observable<any>;
  subscription: Subscription;
  filterTextSubscription: Subscription;
  jsonConvert: JsonConverter;

  parserQueueEntries: ParserQueueEntry[] = [];
  opAreaOptions: Area[];
  activityOptions: Activity[];
  statuses: Array<any>;

  trackColumns = ['pick','type', 'date1','ll','source','source3']
  allColumns = [
    { name: 'select', restrictToAdmin: true },
    { name: 'classification', restrictToAdmin: false, column_name: 'classification_name' },
    { name: 'name', restrictToAdmin: false, column_name: 'parser_name' },
    { name: 'geo', restrictToAdmin: false, column_name: 'geo' },
    { name: 'locTimeTs', restrictToAdmin: false, column_name: 'loc_time_ts' },
    { name: 'latitude', restrictToAdmin: false, column_name: 'latitude' },
    { name: 'longitude', restrictToAdmin: false, column_name: 'longitude' },
    { name: 'elnot', restrictToAdmin: false, column_name: 'el_not' },
    { name: 'points', restrictToAdmin: false, column_name: 'entries' },
    { name: 'originator', restrictToAdmin: false, column_name: 'originator' },
    { name: 'source', restrictToAdmin: false, column_name: 'source' },
    { name: 'actions', restrictToAdmin: false }
  ];
  displayedColumns: string[] = [];

  groupingList: Array<any> = [
    { name: 'Unit', value: 'name', column_name: 'parser_name'/*'unit.unitId'*/ },
    { name: 'Classification', value: 'classification', column_name: 'classification_name' },
    { name: 'Originator', value: 'originator', column_name: 'originator' },
    { name: 'Source', value: 'source', column_name: 'source' },
    { name: 'No Grouping', value: 'All', column_name: null }
  ];
  grouping: any = this.groupingList[0];
  groupByColumn: string = this.grouping.value;
  groupCount = 0;
  sortByColumn: string = DB_DEFAULT_SORT;
  sortByColumnOrder: string = 'ASC';
  db_groupByColumn: string = this.grouping.column_name;
  page_current = 1;
  page_previous = 0;
  page_next = 0;
  itemCount = 0;

  @ViewChild('filterInput', { static: true }) filterInput: ElementRef;
  filterValue = '';
  // holds a string that will force filters to run without using a potentially valid filter string.
  forcedFilter = '';
  filterColumn = 'name';
  filterTextChanged: Subject<string> = new Subject<string>();

  dataSource = new TableVirtualScrollDataSource<ParserQueueEntry>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  // Plural Mapping for I18nPluralPipe
  pluralMapping = { '=0': '0 Entries', '=1': '1 Entry', 'other': '# Entries' };

  user: User;

  jfmccs: any = [];

  selectedJfmcc: any;

  allParserQueueEntries: Array<any> = [];

  selection: SelectionModel<ParserQueueEntry> = new SelectionModel<ParserQueueEntry>(true, []);

  constructor(
    public parserQueueService: ParserQueueService,
    public lookupService: LookUpService,
    public snackBar: MatSnackBar,
    private dialog: MatDialog,
    public messageBusService: MessageBusService,
    public utilities: UtilityService,
    public route: Router,
    public initService: AppInitService,
    private groupingService: GridGroupingService,
    private currentUserService: CurrentUserService,
    private commandService: CommandService,
    private userJfmccService: UserJfmccService) {
    //super(messageBusService, route);

    // Wait 1 second before applying filter, and only apply if the filter string has changed.
    this.filterTextSubscription = this.filterTextChanged
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(model => {
        this.changeAllGroups(true);
        // pull in the ngModel value to the dataSourceFilter
        this.dataSource.filter = model.trim().toLocaleLowerCase();
      });
  }

  /** new ngOnInit 
   ** This is doing server side paging now so the paging logic is managed by DB queries.
   ** each mod to a configuration of the selectors at the top of the screen (group by, jfmcc, column sort, page movement
  ** we must go back to the DB to ensure we present the proper data in correct order)
   **/

  ngOnInit() {
    this.user = this.currentUserService.getCurrentUser();
    // Default view to user's JFMCC if present, otherwise show all
    this.selectedJfmcc = this.user.currentJFMCC ? this.user.currentJFMCC.commandId : 'All'
    this.displayedColumns = this.getDisplayColumns();

    this.loadLookups();
    this.dataSource.filterPredicate = this.customFilterPredicate.bind(this);

    // Override the _pageData function to exclude Group elements when determining page contents.
    // No longer using client-side sort/filter/group -- removed all
    //super.ngOnInit();
  }

  // get the raw message if not already loaded
  async getRawMessage(parser) {
    // did we click on an expanded row? shrink it

      if (!parser.rawMessage) {
        parser.rawMessage =  await this.parserQueueService.getRawMessageParsed(parser).pipe(take(1)).toPromise()
        parser.rawMessage.tracks.forEach((track:ParserTrack)=>{
          const latlon = this.parseLatLon(track.ll);
          track.lat = latlon.lat
          track.lon = latlon.lon
        })
        parser.dataSource = new MatTableDataSource<any>();

        parser.dataSource.data = parser.rawMessage.tracks
      }
      setTimeout(()=>{
        parser.isExpanded = !parser.isExpanded
        console.log(parser.isExpanded)
      })
      //this.expandedElement = parser
    // if (!parser.dataSource) {
    //   parser.dataSource = new MatTableDataSource<any>();
    // }
    // // if (!parser.rawMessage) {
    // //   parser.rawMessage =  await this.parserQueueService.getRawMessageParsed(parser).pipe(take(1)).toPromise()
    // //   //parser.dataSource = new MatTableDataSource<any>();
    // //   parser.dataSource.data = parser.rawMessage.tracks
    // // }
    // setTimeout(async ()=>{
    //   this.expandedElement = this.expandedElement === parser ? null : parser;
    // })
    
  }

  parseLatLon(value: string) {
    value = value.trim();
    if (value.startsWith('LL:')) {
      value = value.replace('LL:', '');
      const coords = value.split('-');
  
      return {
        lat: this.sanitizeCoordinate(coords[0]),
        lon: this.sanitizeCoordinate(coords[1])
      };
    }
  }
  sanitizeCoordinate = (coord) => {
    // Slice off the checksum
    coord = coord.slice(0, -1);
  
    const decimal = coord.indexOf('.');
    if (decimal >= 0) {
      coord = `${coord.substring(0, decimal)}${coord.slice(coord.length - 1)}`;
    }
    return coord;
  }
  

  getPageFromDB() {
    //handle the where clause of the query 
    //presently based at min on the JFMCC selection -- get command_id(s) and match to unit->port.command_id
    //TODO: Expand to use the Unit Name filter
    let whereInVals = []
    whereInVals.push(this.selectedJfmcc)

    if (this.selectedJfmcc === "All") {
      // Create map of JFMCC Id to Name
      whereInVals = []
      this.jfmccs.forEach(function (value) {
        whereInVals.push(value.id)
      });
    }

    //handle order by for query based on group by and sort selection
    let orderDirectionArray = []
    let orderByArray = []
    if (this.groupByColumn !== "All") {
      orderByArray.push(this.grouping.column_name ? this.grouping.column_name : this.db_groupByColumn)
      orderDirectionArray.push("ASC")
    }
    orderByArray.push(this.sortByColumn ? this.sortByColumn : DB_DEFAULT_SORT);
    orderDirectionArray.push(this.sortByColumnOrder ? this.sortByColumnOrder : "ASC")

    //handle what page we are on with paginator so we retrieve correct rows back
    let pageNo = this.paginator.pageIndex ? this.paginator.pageIndex : 0
    pageNo = pageNo + 1 // increment by 1 for DB query
    let paramsnew = {
      //q_in--where in, page, limit, order_by, order_direction 
      q_in: whereInVals,
      page: pageNo,
      limit: this.paginator.pageSize ? this.paginator.pageSize : this.db_query_limit,
      order_by: orderByArray,
      order_direction: orderDirectionArray
    }
    // Create timer observable that retrieves parserQueue data periodically
    this.timer$ = timer(0, this.REFRESH_INTERVAL).pipe(
      switchMap(_ => {
        return this.parserQueueService.getAll(paramsnew).pipe(
          map((data) => {
            this.db_rowCount = data.data.total;
            this.itemCount = this.db_rowCount;
            this.page_current = data.data.currentPage;
            this.page_previous = data.data.previousPage;
            this.page_next = data.data.nextPage;
            this.paginator.pageSize = data.data.limit;
            //convert the actual datarows returned to proper type
            let jsonConvert: JsonConverter = new JsonConverter();
            this.allParserQueueEntries = jsonConvert.deserializeArray(data.data.data, ParserQueueEntry);
            return data
            //filter is handled by DB query so no filter logic in widget on rows here
          }),
          catchError((err, caught) => {
            console.error(err);
            return empty();
          })
        );
      })
      
    );
  }

  async loadLookups() {
    try {
      //Get Ids for all JFMCCs current user supports
      const jfmccIds = await this.userJfmccService.getJfmccs(this.user)

      // Create map of JFMCC Id to Name
      jfmccIds.forEach((id) => {
        this.commandService.getAll({ command_id: id }).pipe(take(1)).subscribe(command => {
          const obj = {}
          obj['name'] = command[0].name
          obj['id'] = command[0].commandId
          this.jfmccs.push(obj)
        })
      })
    } catch (err) {
      this.snackBar.open('An error occurred when trying to load the Parser Queue. Please contact your administrator.', 'Close', { horizontalPosition: 'center', verticalPosition: 'top', panelClass: ['red-snackbar'], })
    }
  }

  ngAfterViewInit() {
    this.loadParserQueueEntries();
  }

  // Unsubscribe from the timer when navigating away from the component, prevents parser queue modals
  // from being opened in other components.
  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.filterTextSubscription.unsubscribe();
  }

  customFilterPredicate(data: any | Group, filter: string): boolean {
    const triggered = filter === this.forcedFilter ? true : false;

    if (data instanceof Group) {
      if (triggered && !data.expanded) {
        return true;
      } else {
        return data.items.find(x => this.matchesFilter(x, this.filterValue.toLocaleLowerCase()));
      }
    } else {
      if (triggered && !data.group.expanded) {
        return false;
      } else {
        return this.matchesFilter(data, this.filterValue.toLocaleLowerCase());
      }
    }
  }

  matchesFilter(data: ParserQueueEntry, filter: string): boolean {
    if (filter === this.forcedFilter) {
      return true;
    }
    return data[this.filterColumn].toLocaleLowerCase().indexOf(filter) > -1;
  }

  isGroup(_index, item): boolean {
    return item.isGroup;
  }

  // Creates or recreates the subscription to the timer to load parser queue entries
  loadParserQueueEntries(forceReload: boolean = false) {
    // Unsubscribe from the old subscription to restart timer with new subscription.
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    //force the db query to update
    this.getPageFromDB();

    // If forcing the reload empty the parserQueue entries.
    if (forceReload) {
      this.parserQueueEntries = [];
    }

    // Creates the subscription to timer, refreshing an empty table or prompting user to refresh when
    // additonal entries are inserted

    this.subscription = this.timer$.subscribe((data) => {
      // If entries list is empty refresh data without prompting user since no work is lost
      if (this.parserQueueEntries.length === 0) {
        this.updateEntries(this, [data]);
      } else {
        // otherwise prompt user if data has changed to refresh the page or continue working.
        const difference = data.length - this.parserQueueEntries.length;
        if (difference > 0) {
          this.messageBusService.publishMessage(
            new GlobalBusMessage(GlobalMessageTriggers.LOAD_MODAL,
              {
                component: ConfirmationModalComponent,
                data: {
                  buttonText: 'Confirm',
                  headerString: 'Parser Queue',
                  message: `An additional ${difference} report(s) are available in the update queue.<br>` +
                    `Click 'Confirm' to refresh the table with latest data.`,
                  action: this.updateEntries,
                  context: this,
                  args: [data]
                }
              })
          );
        }
      }
    });
  }

  // Applies the filter value to data source.
  applyFilter(event) {
    this.filterTextChanged.next(event);
  }

  // Clears the filter input and filter on the data source.
  clearFilter() {
    this.filterInput.nativeElement.value = '';
    this.applyFilter('');
  }

  // Function to update the entries, is passed local context so confirmation modal can also update entries
  updateEntries(context, args) {
    context.parserQueueEntries = args[0];
    if (context) {
      if (context.parserQueueEntries.data) {

        //TODO: context pass vs. current -- determine which is appropriate...
        let jsonConvert: JsonConverter = new JsonConverter();
        this.allParserQueueEntries = jsonConvert.deserializeArray(context.parserQueueEntries.data.data, ParserQueueEntry);
        //TODO: We may need to perform the global update here...leave as context for the moment.
        //this.dataSource.data = this.groupingService.buildDataSourceData(this.allParserQueueEntries, this.groupByColumn)
        this.allParserQueueEntries.forEach(entry=>entry.isExpanded = false)
        this.dataSource.data = this.allParserQueueEntries
      }
      // we must not have gotten any data
      else {

        if (this.itemCount === 0) {
          //clear the screen else data is lingering
          this.dataSource._pageData = null;
        }
      }
    }
    this.messageBusService.publishMessage(new GlobalBusMessage(GlobalMessageTriggers.CLOSE_MODAL));
  }

  // open the modal dialog to take action on the parser queue entry
  open(entry: any) {
    const dialogRef = this.dialog.open(EditParserQueueModalComponent, {
      height: 'auto',
      width: '840px'
    });
    dialogRef.componentInstance.entry = entry;

    // Reload data only if accepted/discarded.
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadParserQueueEntries(true);
      }
    });
  }

  onPaginateChange(event) {
    //Page Event: {"previousPageIndex":0,"pageIndex":0,"pageSize":10,"length":32}
    this.loadParserQueueEntries(true);
    //TODO: is this correct?
    this.itemCount = this.countParserEntries();
  }

  changeGrouping(event, grouping) {
    this.groupByColumn = grouping.value;
    this.db_groupByColumn = grouping.column_name;
    this.loadParserQueueEntries(true);
  }


  sortData(sort: Sort): void {
    if (!sort.active || sort.direction === '') {
      sort = DEFAULT_SORT;
    }
    const sortEntry = this.allColumns.find((i) => i.name === sort.active);
    this.sortByColumn = sortEntry.column_name;
    const isAsc = sort.direction === 'asc';
    this.sortByColumnOrder = sort.direction;
    //Given server side paging, we now need to always go back to DB when a sort happens as limitor may cause us to miss records
    this.loadParserQueueEntries(true);

  }

  toggleGroup(row) {
    row.expanded = !row.expanded;

    this.forcedFilter = performance.now().toString();
    // Force filter to run so that collapse/expand occurs
    this.dataSource.filter = this.forcedFilter;
  }

  showGroup(row: Group): boolean {
    if (row.expanded && this.dataSource.filteredData) {
      for (const item of row.items) {
        const found = this.dataSource.filteredData.find(x => (x instanceof ParserQueueEntry) ? x.parserId === item.parserId : false);
        if (found) {
          return true;
        }
      }
      return false;
    } else {
      return true;
    }
  }

  // Gets the count of parser entries in the filtered data set for use in paginator length messages.
  countParserEntries = () => {
    //return this.db_rowCount;
    return this.dataSource.filteredData.filter((e: any) => !e.isGroup).length;
  }

  compare(a: ParserQueueEntry, b: ParserQueueEntry, isAsc: boolean, active: string): number {
    if (this.groupByColumn) {
      if (a[this.groupByColumn] > b[this.groupByColumn]) {
        return 1;
      }

      if (a[this.groupByColumn] < b[this.groupByColumn]) {
        return -1;
      }
    }

    return (a[active] < b[active] ? -1 : 1) * (isAsc ? 1 : -1);
  }

  changeJfmccView(event) {
    this.parserQueueEntries = event.value !== "All" ? this.allParserQueueEntries.filter((entry) => { return entry.unit.commandId === event.value }) : this.allParserQueueEntries
    this.selectedJfmcc = event.value;
    this.loadParserQueueEntries(true)
    //this.sortData(this.sort);
    // clear selection to avoid issues if user changes JFMCC view
    this.selection.clear();
    // Manually force paginator length update. Otherwise when re-grouping the total length in paginator
    // includes the group elements.
    // this.dataSource._updatePaginator(this.countParserEntries());
  }

  toggleSelectAll() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.dataSource.filteredData.forEach(row => {
        if (row instanceof ParserQueueEntry && this.canEdit(row)) {
          this.selection.select(row);
        }
      });
    }
  }

  isAllSelected(): boolean {
    return this.selection.selected.length === this.dataSource.filteredData.filter(e => e instanceof ParserQueueEntry && this.canEdit(e)).length;
  }

  canEdit(entry: ParserQueueEntry): boolean {
    return this.user.canEdit(entry.unit);
  }

  bulkDiscard() {
    const message = `Select "Confirm" to discard the selected ${this.selection.selected.length} entries.`;

    this.messageBusService.publishMessage(
      new GlobalBusMessage(GlobalMessageTriggers.LOAD_MODAL,
        {
          component: ConfirmationModalComponent,
          data: {
            message: message,
            headerString: 'Discard Entries?',
            buttonText: 'Confirm',
            action: this.bulkDelete,
            context: this,
            args: []
          }
        })
    );
  }

  bulkDelete(context: any, args: any[]) {
    context.parserQueueService.bulkDelete(context.selection.selected.map(e => e.parserId)).pipe(take(1)).subscribe(res => {
      context.loadParserQueueEntries(true);
      context.selection.clear();
      context.messageBusService.publishMessage(new GlobalBusMessage(GlobalMessageTriggers.CLOSE_MODAL));
      context.snackBar.open('Successfully discarded entries.', 'Close', { duration: 5_000 });
    });
  }

  getDisplayColumns(): string[] {
    let columns = this.allColumns;

    if (!this.user.isAdmin()) {
      columns = columns.filter(value => !value.restrictToAdmin);
    }

    return columns.map(e => e.name);
  }

  hasMultipleGroups(): boolean {
    return this.dataSource.filteredData.filter((e, index) => this.isGroup(index, e)).length > 1;
  }

  hasAnyExpandedGroups(): boolean {
    return this.dataSource.filteredData.filter((e: any, index) => this.isGroup(index, e) && e.expanded).length > 0;
  }

  changeAllGroups(expanded: boolean) {
    this.dataSource.filteredData.filter((e, index) => this.isGroup(index, e)).forEach((e: any) => e.expanded = expanded);
    // Clear selection to avoid users accidentally removing hidden entries.
    this.selection.clear();

    this.forcedFilter = performance.now().toString();
    // Force filter to run so that collapse/expand occurs
    this.dataSource.filter = this.forcedFilter;
  }

  trackSelected(item: any,event:any) {
    //event.preventDefault()
  }
}
