<mat-card class="form-card" >
<mat-card-content>
<mat-sidenav-container class="admin-container">
  <mat-sidenav  #drawer mode="side" opened class="admin-sidenav">
      <mat-nav-list>
          <a *ngIf="user.isSupervisor()" [ngClass]="{'current' : selection == 'units'}"
            mat-list-item routerLink="/admin/tasks/units"> Unit Maintenance</a>
          <a *ngIf="user.isAdmin()" [ngClass]="{'current' : selection == 'users'}"
            mat-list-item routerLink="/admin/tasks/users"> User Maintenance</a>
          <a *ngIf="user.isSupervisor()" [ngClass]="{'current' : selection == 'lookup-maintenance'}"
            mat-list-item routerLink="/admin/tasks/lookup-maintenance"> Lookup Maintenance</a>  
          <a *ngIf="user.isSupervisor()" [ngClass]="{'current' : selection == 'slide-maintenance'}"
            mat-list-item routerLink="/admin/tasks/slide-maintenance"> Slide Maintenance</a>
          <a *ngIf="user.isSupervisor()" [ngClass]="{'current' : selection == 'geo-refs'}"
            mat-list-item routerLink="/admin/tasks/geo-refs"> Geo-Reference List</a>
          <a *ngIf="user.isSupervisor()" [ngClass]="{'current' : selection == 'track-maintenance'}"
            mat-list-item routerLink="/admin/tasks/track-maintenance"> Track Maintenance</a>
          <a *ngIf="user.isSupervisor()" [ngClass]="{'current' : selection == 'commands'}"
            mat-list-item routerLink="/admin/tasks/commands"> Command List</a>
          <a *ngIf="user.isAdmin()" [ngClass]="{'current' : selection == 'command-graph'}"
            mat-list-item routerLink="/admin/tasks/command-graph"> AOR Command Graph</a>
          <a *ngIf="user.isSupervisor()" [ngClass]="{'current' : selection == 'account-requests'}"
            mat-list-item routerLink="/admin/tasks/account-requests"> Account Requests
            <span *ngIf="badgeCount > 0" [matBadge]="badgeCount"
            matBadgeOverlap="false"
            matBadgeSize="medium"></span>
          </a>
          <a *ngIf="user.isAdmin()" [ngClass]="{'current' : selection == 'point-of-contacts'}"
          mat-list-item routerLink="/admin/tasks/point-of-contacts"> Points of Contact</a>         
      </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
</mat-card-content>
</mat-card>
