import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { APIConfig } from './api.config';

@Injectable()
export class ParserQueueApiService {
    SERVER_URL;
    RESOURCE_URI = 'parser-queue';

    constructor(private http: HttpClient, apiConfig: APIConfig) {
        this.SERVER_URL = apiConfig.TARGET_URL;
    }

    public getAllParserQueueEntries(params: any): Observable<any> {
        return this.http.post(`${this.SERVER_URL}/${this.RESOURCE_URI}`, params);
    }

    public getParserByParserId(parserId: string): Observable<any> {
        return this.http.get(`${this.SERVER_URL}/${this.RESOURCE_URI}/${parserId}`);
      }

    /*
       * Requests the KML file and returns an observable of the response so filename can be
       * retrieved from content-disposition header
       */
    public getKML(entry: any): Observable<HttpResponse<Object>> {
        return this.http.get(
            `${this.SERVER_URL}/${this.RESOURCE_URI}/${entry.parser_id}/kml`,
            { observe: 'response', responseType: 'blob' });
    }

    public getRawMessage(entry: any): Observable<any> {
        if (entry.parser_msg_id) {
            return this.http.get(
                `${this.SERVER_URL}/${this.RESOURCE_URI}/${entry.parser_msg_id}/raw-message-db`,
                { responseType: 'text' });
        }
        else {
            return this.http.get(
                `${this.SERVER_URL}/${this.RESOURCE_URI}/${entry.parser_id}/raw-message`,
                { responseType: 'text' });
        }
    }

    public ingestEntry(entry: any): Observable<any> {
        return this.http.post(
            `${this.SERVER_URL}/${this.RESOURCE_URI}/${entry.parser_id}/ingest`, entry);
    }

    public deleteEntry(entry: any): Observable<any> {
        return this.http.delete(`${this.SERVER_URL}/${this.RESOURCE_URI}/${entry.parser_id}`);
    }

    public bulkDeleteEntries(params: any): Observable<any> {
        return this.http.post(`${this.SERVER_URL}/${this.RESOURCE_URI}/bulk-delete`, params);
    }

    public selectedDeleteEntries(entryIds: string[]): Observable<any> {
        return this.http.delete(`${this.SERVER_URL}/${this.RESOURCE_URI}/selected-delete`, { body: { parser_ids: entryIds } });
    }
}
