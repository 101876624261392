import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { User } from '@models/user.model';
import { CurrentUserService } from '@services/current-user-service';
import { UtilityService } from '@services/utility.service';

@Component({
  selector: 'app-unit-info',
  templateUrl: './unit-info.component.html',
  styleUrls: ['./unit-info.component.css']
})
export class UnitInfoComponent implements OnInit {

    // passed in via call to modal
  unit: any;
  headerString: any;
  user: User;

  constructor(
    private currentUserService: CurrentUserService,
    public dialogRef: MatDialogRef<UnitInfoComponent>,
    public utilities: UtilityService,
    private route: Router) { }

  ngOnInit() {
    this.headerString = this.unit.final_name
    this.user = this.currentUserService.getCurrentUser();
  }

  tracks(days) {
    this.dialogRef.close({days: days})
  }

  close() {
    this.dialogRef.close()
  }

  navigateTrackMaintenance() {
    this.dialogRef.close();
    this.route.navigate(['/admin/tasks/track-maintenance'], { state: { data: { unit_id: this.unit.unit_id } } });
  }
}
