import { JsonObject, JsonProperty, Any } from 'json2typescript';
import { Country } from '@models/country.model';

@JsonObject('Slide')
export class Slide {
  @JsonProperty('slide_id', String, true)
  slideId: string = undefined;
  @JsonProperty('slide_name', String, true)
  slideName: string = undefined;
  @JsonProperty('lat1', Number, true)
  lat1: number = undefined;
  @JsonProperty('lon1', Number, true)
  lon1: number = undefined;
  @JsonProperty('lat2', Number, true)
  lat2: number = undefined;
  @JsonProperty('lon2', Number, true)
  lon2: number = undefined;
  @JsonProperty('image', Object, true)
  image: object = undefined;
  @JsonProperty('fleet_id', String, true)
  fleetId: string = undefined;
  @JsonProperty('assoc_fleet_id', String, true)
  assocFleetId: string = undefined;
  @JsonProperty('link', String, true)
  link: string = undefined;
  @JsonProperty('country_id', String, true)
  countryId: string = undefined;
  @JsonProperty('country', Country, true)
  country: Country = undefined;
  @JsonProperty('created_by', String, true)
  createdBy: string = undefined;
  @JsonProperty('original_file_name', String, true)
  originalFileName: string = undefined;

}
