<app-classification-bar class="top-modal-classification-bar"></app-classification-bar>
<app-fdtm-modal-header [headerString]="headerString"></app-fdtm-modal-header>
<div mat-dialog-content>
  <form [formGroup]="editForm">
    <mat-form-field class="input-full-width">
      <input matInput (keyup)="checkDups($event, 'elint_function_code')" placeholder="Function Code" required
        autocomplete="off" formControlName="elint_function_code">
      <mat-error *ngIf="editForm?.controls?.elint_function_code?.hasError('duplicate entry')">duplicate
        entry</mat-error>
    </mat-form-field>
    <br>
    <mat-form-field class="input-full-width">
      <input matInput (keyup)="checkDups($event, 'elint_function_name')" placeholder="Function Name" required
        autocomplete="off" formControlName="elint_function_name">
      <mat-error *ngIf="editForm?.controls?.elint_function_name?.hasError('duplicate entry')">duplicate
        entry</mat-error>
    </mat-form-field>
    <br>
    <div mat-dialog-actions>
      <button *ngIf="!isDelete" [disabled]="!editForm.valid" mat-raised-button color="primary"
        (click)="handleSubmit()">Submit</button>
      <button *ngIf="isDelete" mat-raised-button color="warn" (click)="handleDelete()">Delete</button>
      <button mat-raised-button (click)="handleCancel()">Cancel</button>
    </div>
  </form>
</div>
<app-classification-bar class="bottom-modal-classification-bar"></app-classification-bar>