<h1 mat-dialog-title>Session Timeout Warning</h1>
<div mat-dialog-content>
  <p>Your session has been idle for {{timeout}} minutes.</p>
  <br>
  <p>Select 'Stay' to continue your session or you will be automatically logged out.</p>
  <p>
    Automatic logout in {{timeLeft}} seconds....
  </p>
</div>
<div mat-dialog-actions>
  <button mat-raised-button class="fdt-m-primary" (click)="logout()">Logout</button>
  <button mat-raised-button class="fdt-m-primary" (click)="remain()">Stay</button>
</div>
