import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from './api.config';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class OptempoApiService {
    SERVER_URL;
    RESOURCE_URI = 'optempos';

    constructor(private http: HttpClient, private apiConfig: APIConfig) {
        this.SERVER_URL = apiConfig.TARGET_URL;
    }

    public getOptempoData(): Observable<any> {
        return this.http.get(`${this.SERVER_URL}/${this.RESOURCE_URI}`);
    }
}
