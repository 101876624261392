import { GlobalMessageTriggers } from '@services/global/message-bus/global-message-triggers.enum';
import { EditModalBaseComponent } from '@fom-module/base-classes/edit-modal-base.component';
import { UserService } from '@services/data-services/user.service';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DynamicComponentInterface } from '@core-module/app-components/dynanmic-component/dynamic-component.interface';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { User } from '@models/user.model';
import { Command } from '@models/command.model';
import { Permission } from '@models/permission.model';
import { AccountRequest } from '@models/account-request.model'
import { LookUpService } from '@services/data-services/lookup.service';
import { FriendlyErrorService } from '@services/friendly-error.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { UtilityService } from '@services/utility.service';
import { CommandHierarchyService } from '@services/data-services/command-hierarchy.service';
import { CurrentUserService } from '@services/current-user-service';
import { CommandHierarchy } from '@models/command-hierarchy.model';
import { AppInitService } from '../../../../config/init.service';
import { AccountRequestService } from '@services/data-services/account-request.service';

@Component({
  selector: 'app-edit-user-modal',
  templateUrl: './edit-user-modal.component.html',
  styleUrls: ['./edit-user-modal.component.css']
})
export class EditUserModalComponent implements OnInit {
  // Grab form field content for adding distinguished name
  @ViewChild("distinguishedName") distinguishedName: ElementRef;
  
  editForm: FormGroup;
  // Dropdown options
  commandOptions: Command[];
  // Holds response from Commands API, filtered based on CommandHierarchy.
  commands: Command[];
  permissionOptions: Permission[];

  entity: User;
  currentUser: User;
  commandHierarchy: CommandHierarchy;
  isNew: boolean;
  // check for account requests, notify user
  accountRequest: AccountRequest = null
  daysUntilInactive: Number;
  headerString: string;
  userPkis: Object = {};

  constructor(public userService: UserService,
    public lookUpService: LookUpService,
    private friendlyErrorService: FriendlyErrorService,
    public formBuilder: FormBuilder,
    public utilities: UtilityService,
    private accountRequestService: AccountRequestService,
    public dialogRef: MatDialogRef<EditUserModalComponent>,
    private commandHierarchyService: CommandHierarchyService,
    private currentUserService: CurrentUserService,
    private init: AppInitService,) {
  }

  ngOnInit() {
    this.headerString = this.isNew ? 'Add User' :
      'Edit User'
    this.currentUser = this.currentUserService.getCurrentUser();
    this.daysUntilInactive = this.init.getConfig().daysUntilInactive || 30;
    this.userPkis = this.entity.userPki
    this.editForm = this.formBuilder.group({
      commandId: [this.entity.commandId],
      firstName: [this.entity.firstName],
      lastName: [this.entity.lastName],
      username: [this.entity.username],
      password: [this.entity.password],
      permissions: [this.entity.permissions],
      disabled: [this.entity.disabled],
      whitelisted: [this.entity.whitelisted],
      skipInactivityCheck: [this.entity.skipInactivityCheck],
      email: [this.entity.email],
      justification: [this.entity.justification]
    });

    // Only add password validator when creating a new user. Edit doesn't allow password updates
    if (this.isNew) {
      this.editForm.get('password').setValidators(this.utilities.passwordValidator);
    }

    Promise.resolve().then(() => {
      this.loadLookups();
      this.loadAccountRequest()
    });
  }

  loadLookups() {
    this.commandHierarchyService.getAll({ sort: 'version_num', limit: 1 }).pipe(take(1)).subscribe(result => {
      if (result.length > 0) {
        this.commandHierarchy = result[0];
      } else {
        // set the hierarchy to a blank list if no command hierarchy is created.
        this.commandHierarchy = new CommandHierarchy();
        this.commandHierarchy.treeObject = '[]';
      }
        this.filterCommandOptions();
    });

    this.lookUpService.getLookupByType(Command, true).pipe(take(1)).subscribe(result => {
      this.commands = result;
      this.filterCommandOptions();
    });

    this.lookUpService.getLookupByType(Permission).pipe(take(1)).subscribe(result => {
      result.sort(function (a, b) {
        return a.permissionLevel - b.permissionLevel;
      });
      this.permissionOptions = result;
    });
  }

  loadAccountRequest() {
    console.log("Loading account request" + this.entity.userId)
    if (!this.isNew) {
      this.accountRequestService.findAccountRequstByUserId(this.entity.userId).pipe(take(1)).subscribe(accountRequest => {
        this.accountRequest = accountRequest
      })
    }
  }
  submit() {
    this.updateEntity();
    this.updateInactivityCheck();
    if (this.isNew) {
      this.userService.create(this.entity).pipe(take(1)).subscribe(response => {
        this.dialogRef.close(response);
      }, error => {
        this.friendlyErrorService.processError(error);
      });
    } else {
      this.userService.update(this.entity).pipe(take(1)).subscribe(response => {
        this.dialogRef.close(response);
      }, error => {
        this.friendlyErrorService.processError(error);
      });
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  updateEntity() {
    for (const key in this.editForm.controls) {
      if (this.entity.hasOwnProperty(key.toString())) {
        this.entity[key.toString()] = this.editForm.controls[key]['value'];
      }
    }
  }

  updateInactivityCheck() {
    if (this.entity.disabled) {
      this.entity.skipInactivityCheck = true;
    } else {
      this.entity.skipInactivityCheck = false;
    }
  }

  // Callback to set this.commandOptions after both the hierarchy and commands list are retrieved.
  // Is called by both the subscription for retrieving the commands and hierarchy, and only actually
  // sets commandOptions after both are retrieved. If user isn't a system admin, filters the command options
  // to the list of commands support their JFMCC(s) or their own command.
  filterCommandOptions() {
    if (this.commandHierarchy != null && this.commands != null) {
        if (this.currentUser.isSystemAdmin()) {
          this.commandOptions = this.commands;
        } else {
          this.commandOptions = this.commands.filter(c => {
            return this.commandHierarchy.getSupportingCommandIds(this.currentUser).includes(c.commandId) ||
              c.commandId === this.currentUser.commandId;
          });
        }
    }
  }

  addDistinguishedName() {
    let distinguishedName = this.distinguishedName.nativeElement.value
    this.userService.addPki(this.entity, distinguishedName).pipe(take(1)).subscribe(response => {
      this.userPkis = response.userPki;
      this.distinguishedName.nativeElement.value = '';
    }, error => {
      this.friendlyErrorService.processError(error);
    });
  }

  delete(pki) {
    this.userService.deletePki(pki).pipe(take(1)).subscribe(response => {
      let pkis = []
      for(var obj in this.userPkis) {
        if(this.userPkis[obj] !== pki){
          pkis.push(this.userPkis[obj])
        }
      }
      this.userPkis = pkis
    }, error => {
      this.friendlyErrorService.processError(error);
    });
  }
}
