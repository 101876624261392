import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from './api.config';

@Injectable()
export class PermissionApiService {
  SERVER_URL;

  constructor(
    private http: HttpClient,
    apiConfig: APIConfig) {
      this.SERVER_URL = apiConfig.TARGET_URL;
  }

  public getAllPermissions(): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/permissions`);
  }
}