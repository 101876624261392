import { Unit } from '@models/unit.model';
import { UnitApiService } from './../api-services/unit-api.service';
import { AuditUnitBorrowed } from '@models/audit-unit-borrowed.model';
import { Injectable } from '@angular/core';
import { JsonConverter } from '../global/json-converter';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable()
export class UnitService {
    private jsonConvert: JsonConverter;

    constructor(private unitApiService: UnitApiService) {
        this.jsonConvert = new JsonConverter();
    }
// the params object contains nextCOI, Command name, ShipType ID since we laready have these
    public createCOI(params: any): Observable<Unit> {
      return this.unitApiService.createCOI(params)
          .pipe(
              map((data) => {
                  return this.jsonConvert.deserializeObject(data, Unit);
              })
          );
    }
    // search for duplicate name, sconum, midb
    public uniqueFieldsSearch(unit: Unit): Observable<Unit[]> {
        return this.unitApiService.uniqueFieldsSearch(unit)
        .pipe(
            map((data) => {
                return this.jsonConvert.deserializeArray(data, Unit);
            })
        );
    }

    // a very specific search
    public findUnits(value, filters = {}, limit = 20, offset = 0): Observable<Unit[]> {
        if (!value) {
            return of([]);
        }

        // copy paste may have blanks?
        if (typeof value === 'string' || value instanceof String) {
            value = value.toString().trim();
        } else {
            return of([]);
        }

        return this.unitApiService.findUnits(value, filters, limit, offset)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, Unit);
                })
            );
    }

    public find(queryParams: Object): Observable<Unit[]> {
        return this.unitApiService.find(queryParams)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, Unit);
                })
            );
    }

    public getAll(filters = {}): Observable<Unit[]> {
        return this.unitApiService.getAllUnits(filters)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, Unit);
                })
            );
    }

    public getUnitOtherNames(unitId: string): Observable<Unit> {
        return this.unitApiService.getUnitOtherNames(unitId)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Unit);
                })
            );
    }

    public getCommandUnits(commandId): Observable<Unit[]> {
        return this.unitApiService.getCommandUnits(commandId)
            .pipe(
                map((data) => {
                    // console.log(data);
                    return this.jsonConvert.deserializeArray(data, Unit);
                })
            );
    }

    public getUnitByUnitId(unitId, params = {}): Observable<Unit> {
        return this.unitApiService.getUnitByUnitId(unitId, params)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Unit);
                })
            );
    }

    public update(unit: Unit): Observable<Unit> {
        let converted = this.jsonConvert.serializeObject(unit)
        // ensure a null get passed since jsonConvert will just remove it
        if (unit.borrowerCommandId == null) {
            converted.borrower_command_id = null
        }
        return this.unitApiService.updateUnit(converted)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Unit);
                })
            );
    }

    public create(unit: Unit): Observable<Unit> {
        return this.unitApiService.createUnit(this.jsonConvert.serializeObject(unit))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Unit);
                })
            );
    }

    public delete(unit: Unit): Observable<Unit> {
        return this.unitApiService.deleteUnit(this.jsonConvert.serializeObject(unit))
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    public getMIDBUnit(name: string, flag: string): Observable<any> {
        // can't serialize the unit, need the country/flag field, which gets destroyed
        return this.unitApiService.getMIDBUnit(name, flag)
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    public getByUnitSearchFilters(filters = {}): Observable<any> {
        return this.unitApiService.getByUnitSearchFilters(filters)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, Unit);
                })
            );
    }

}
