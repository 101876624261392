import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter } from '../services/global/date-converter';
import { Command } from '@models/command.model';
import { Country } from './country.model';

@JsonObject('VesselType')
export class VesselType {
  @JsonProperty('vessel_type_id', String, true)
  vesselTypeId: string = undefined;
  @JsonProperty('vessel_type', String, true)
  vesselType: string = undefined;
  @JsonProperty('vessel_type_description', String, true)
  vesselTypeDescription: string = undefined;
  @JsonProperty('command_id', String, true)
  commandId: string = undefined;
  @JsonProperty('command', Command, true)
  command: Command = undefined;
  @JsonProperty('created_by', String, true)
  createdBy: string = undefined;
  @JsonProperty('priority', Number, true)
  priority: number = undefined;

  static lookupName(): string {
    return 'vesseltypes';
  }
}
