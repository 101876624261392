
import { SlideService } from '@services/data-services/slide.service';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { Component, OnInit } from '@angular/core';
import { Slide } from '@models/slide.model';
import { LookUpService } from '@services/data-services/lookup.service';
import { Router } from '@angular/router';
import { GridBaseComponent } from '@fom-module/base-classes/grid-base.component';
import { TdFileService } from '@covalent/core/file';
// import { calcBindingFlags } from '@angular/core/src/view/util';
import { MatIconRegistry } from '@angular/material/icon';
import { EditSlideModalComponent } from '@edit-modals/edit-slide-modal/edit-slide-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { UtilityService } from '@services/utility.service';
import { AppInitService } from '../../../config/init.service';

@Component({
  selector: 'app-slide-maintenance',
  templateUrl: './slide-maintenance.component.html',
  styleUrls: ['./slide-maintenance.component.css', '../base-classes/grid-base.component.css'],
  providers: [TdFileService]
})
export class SlideMaintenanceComponent extends GridBaseComponent implements OnInit {

  BOTTOM_NAV_BAR: any;
  displayedColumns = ['slideName', 'flag', 'lat1', 'lon1', 'lat2', 'lon2', 'image', 'delete'];
  constructor(public iconRegistry: MatIconRegistry,
    public slideService: SlideService,
    public lookUpService: LookUpService,
    public utilities: UtilityService,
    public messageBusService: MessageBusService,
    private dialog: MatDialog,
    public route: Router,
    public initService: AppInitService) {
      super(messageBusService, slideService, route);
      iconRegistry.addSvgIcon('thumbs-up', '');
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {
    // super.ngOnInit();
    super.listenToMessageBus();
    this.loadEntityData();
  }

  // use our own rather than base class, set sort order and filter predicate
  loadEntityData() {
    this.slideService.getAll().pipe(take(1)).subscribe(entities => {
      entities.sort((a,b) => {
        return a.slideName < b.slideName ? -1: 1
      })
      this.setDataSource(entities)
      this.dataSource.filterPredicate =
        (data: Slide, filter: string) => data.slideName.trim().toLowerCase().indexOf(filter) != -1
    })

  }
  // edit not supported at the moment
  edit(slide: any) {
    const dialogRef = this.dialog.open(EditSlideModalComponent, {
      width: '560px'
    });
    dialogRef.componentInstance.isNew = false;
    dialogRef.componentInstance.entity = slide

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      // in order to get complete data
      this.loadEntityData()
    });

  }
  add() {
    const dialogRef = this.dialog.open(EditSlideModalComponent, {
      width: '560px'
    });
    dialogRef.componentInstance.isNew = true;
    dialogRef.componentInstance.entity = new Slide()

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      // in order to get complete data
        this.loadEntityData()
    })
  }
  getImage(row) {
    this.slideService.getImage(row.slideId).pipe(take(1)).subscribe(image => {
      this.download(image, row.originalFileName);
    }, err => {
      throw (err)
    });
  }
  download(image: any, fileName: string) {
    const file = new Blob([image]);
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(file, fileName);
    } else {
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(file); // xhr.response is a blob
      a.style.display = 'none';
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
    }
  }
  // check for invalid file types and warn user
  checkFileType(row) {
    // hate to use try/catch but who knows what files are out there....
    try {
      let extension = row.originalFileName.toLowerCase().substr(row.originalFileName.lastIndexOf('.'))
      return ['.jpg', '.png', '.emf', '.wmf', '.jpeg', '.jfif', '.jpe', '.bmp', '.dib', '.rle', '.gif', '.emz', '.wmz', '.tif', '.tiff', '.svg', '.ico'].indexOf(extension) == -1 ? 'This slide has a file that may not be visible in Powerpoint slides! ' : null
    }
    catch (e) {
      return 'This slide has a file that may not be visible in Powerpoint slides! '
    }
  }
}
