<app-classification-bar class="top-modal-classification-bar"></app-classification-bar>
<app-fdtm-modal-header [headerString]="data.headerString"></app-fdtm-modal-header>
<div mat-dialog-content>
<form [formGroup]="editForm">
  <div mat-dialog-content>
    <p>Enter new password</p>
    <mat-form-field class="input-full-width">
      <input matInput type="password" formControlName="password" autofocus required>
      <mat-error *ngIf="editForm?.controls?.password?.hasError('length')">invalid length</mat-error>
      <mat-error *ngIf="editForm?.controls?.password?.hasError('complexity')">is not complex enough</mat-error>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button color="primary" type="submit" [disabled]="!editForm.valid" [mat-dialog-close]="editForm.controls.password.value">Change</button>
    <button mat-raised-button type="button" (click)="onNoClick()">Cancel</button>
  </div>
</form>
<app-classification-bar class="bottom-modal-classification-bar"></app-classification-bar>