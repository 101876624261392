import { FormBuilder } from '@angular/forms';
import { EditModalBaseComponent } from '@fom-module/base-classes/edit-modal-base.component';
import { PortService } from '@services/data-services/port.service';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { Component, OnInit } from '@angular/core';
import { Port } from '@models/port.model';
import { LookUpService } from '@services/data-services/lookup.service';
import { Command } from '@models/command.model';
import { Fleet } from '@models/fleet.model';
import { MatDialogRef } from '@angular/material/dialog';
import { UtilityService } from '@services/utility.service';
import { take } from 'rxjs/operators';
import { CommandHierarchyService } from '@services/data-services/command-hierarchy.service';
import { CurrentUserService } from '@services/current-user-service';
import { User } from '@models/user.model';
import { CommandHierarchy } from '@models/command-hierarchy.model';
import { Unit } from '@models/unit.model';
import { UnitService } from '@services/data-services/unit.service';
import { GlobalBusMessage } from '@services/global/message-bus/global-bus-message.model';
import { GlobalMessageTriggers } from '@services/global/message-bus/global-message-triggers.enum';
import { ConfirmationModalComponent } from '@core-module/app-components/confirmation-modal/confirmation-modal.component';
import { UserNotificationService } from '@services/notification.service';
import { AppModule } from '../../../app.module';

@Component({
  selector: 'app-edit-port-modal',
  templateUrl: './edit-port-modal.component.html',
  styleUrls: ['./edit-port-modal.component.css']
})
export class EditPortModalComponent extends EditModalBaseComponent implements OnInit {
  commandOptions: Command[];
  fleetOptions: Fleet[];
  user: User;
  commandHierarchy: CommandHierarchy;
  deleteWarning;
  notificationService: UserNotificationService;
  nameOfUnits: String;


  constructor(public portService: PortService, public utilities: UtilityService,
    public lookUpService: LookUpService,
    public formBuilder: FormBuilder,
    private commandHierarchyService: CommandHierarchyService,
    public dialogRef: MatDialogRef<EditPortModalComponent>,
    public messageBusService: MessageBusService,
    public unitService: UnitService,
    private currentUserService: CurrentUserService) {
    super(portService, dialogRef);
    this.notificationService = AppModule.injector.get(UserNotificationService)
  }

  ngOnInit() {
    this.headerString = this.isNew ? 'Add Homeport' :
      this.isDelete ? 'Delete Homeport' :
        'Edit Homeport'
    this.user = this.currentUserService.getCurrentUser();
    if (this.isNew) {
      this.entity.commandId = this.user.currentJFMCC ? this.user.currentJFMCC.commandId : this.user.commandId;
    }
    this.editForm = this.formBuilder.group({
      commandId: [{ value: this.entity.commandId, disabled: this.isDelete }],
      name: [{ value: this.entity.name, disabled: this.isDelete }],
      code: [{ value: this.entity.code, disabled: this.isDelete }],
      fleetId: [{ value: this.entity.fleetId, disabled: this.isDelete }],
      decLat: [{ value: this.entity.decLat, disabled: this.isDelete }, { validators: this.utilities.latValidator }],
      decLon: [{ value: this.entity.decLon, disabled: this.isDelete }, { validators: this.utilities.lonValidator }]
    });
    Promise.resolve().then(() => {
      this.loadLookups();
      this.loadFleetLookups();
    });
  }

  async deletePort() {
    this.nameOfUnits = ``; //reset to nothing to prevent repeat addage
    const units: Unit[] = await this.unitService.getAll({ port_id: this.entity.portId }).toPromise();
    if (units.length > 0) {
      for (let i = 0; i < 5; i++) {
        const item = units[i];
        console.log(item)
        this.nameOfUnits = this.nameOfUnits + (i + 1 + `. ` + item.ship.shipTypeName + ` ` + item.pennant + `<br>`)
      }
    }
    if (units.length > 5) {
      const amount = units.length;
      this.nameOfUnits = this.nameOfUnits + (amount - 5).toString() + ` additional units.`
    }
    const message = units.length > 0 ?
      `${this.entity.name} has ${units.length} unit(s) assigned to it:<br><br> ${this.nameOfUnits} <br>Deleting this port may cause issues. Are you sure you want to delete this port?` :
      `Are you sure you want to delete ${this.entity.name}?`
    await this.handlePortDelete(this.entity, message)
    this.dialogRef.close(true);
  }

  handlePortDelete(entity: any, message) {
    this.messageBusService.publishMessage(
      new GlobalBusMessage(GlobalMessageTriggers.LOAD_MODAL,
        {
          component: ConfirmationModalComponent,
          data: {
            message: message,
            headerString: 'Delete Port?',
            buttonText: 'Delete',
            action: this.deleteEntity,
            context: this,
            args: [entity]
          }
        })
    );
  }

  deleteEntity(context, args) {
    context.entityService.delete(args[0]).pipe(take(1)).subscribe(res => {
      context.messageBusService.publishMessage(new GlobalBusMessage(GlobalMessageTriggers.CLOSE_MODAL));
    });
  }

  loadLookups() {
    this.lookUpService.getLookupByType(Command, true).pipe(take(1)).subscribe(result => {
      this.commandHierarchyService.getAll({ sort: 'version_num', limit: 1 }).pipe(take(1)).subscribe(json => {
        if (json && Array.isArray(json) && json.length) {
          this.commandHierarchy = json[0];

          let jfmccs;
          if (this.user.isSystemAdmin()) {
            jfmccs = this.commandHierarchy.getJFMCCs();
          } else {
            jfmccs = this.commandHierarchy.getJFMCCIds(this.user.command);
          }

          this.commandOptions = result.filter(item => {
            return jfmccs.includes(item.commandId);
          });
        } else {
          this.commandOptions = result;
        }
      });
    });
  }

  loadFleetLookups() {
    this.lookUpService.getLookupByType(Fleet, true).pipe(take(1)).subscribe(result => {
      this.commandHierarchyService.getAll({ sort: 'version_num', limit: 1 }).pipe(take(1)).subscribe(json => {
        if (json && Array.isArray(json) && json.length) {
          this.commandHierarchy = json[0];
          let jfmccs;
          if (this.user.isSystemAdmin()) {
            jfmccs = this.commandHierarchy.getJFMCCs();
          } else {
            jfmccs = this.commandHierarchy.getJFMCCIds(this.user.command);
          }
          this.fleetOptions = result.filter(item => {
            return jfmccs.includes(item.commandId);
          });
        } else {
          this.fleetOptions = result;
        }
      });
    });
  }

  submit() {
    if (this.editForm.valid) {
      this.updateEntity();

      if (this.isNew) {
        (this.entityService as any).create(this.entity).pipe(take(1)).subscribe(res => {
          this.dialogRef.close(res)
        });
      } else {
        (this.entityService as any).update(this.entity).pipe(take(1)).subscribe(res => {
          this.dialogRef.close(res)
        });
      }
    }
  }
  handleCancel() {
    this.dialogRef.close()
  }

  updateEntity() {
    for (let key in this.editForm.controls) {
      if (this.entity.hasOwnProperty(key.toString())) {
        this.entity[key.toString()] = this.editForm.controls[key]['value'];
      }
    }
    // the DB stores lat/lon as decimal. may need to convert them from DMS
    const latitude = this.entity.decLat
    if (latitude.indexOf('.') == -1) {
      this.entity.decLat = this.utilities.decimalLatitude(latitude)
    }

    const longitude = this.entity.decLon
    if (longitude.indexOf('.') == -1) {
      this.entity.decLon = this.utilities.decimalLongitude(longitude)
    }

  }

}
