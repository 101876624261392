import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '@models/user.model';
import { Observable } from 'rxjs';
import { APIConfig } from './api.config';

@Injectable()
export class ReportingApiService {
    SERVER_URL;

    constructor(
        private http: HttpClient, apiConfig: APIConfig) {
        this.SERVER_URL = apiConfig.TARGET_URL;
    }

    public getLocator(filters = {}): Observable<any> {
        let httpParams = new HttpParams();
        for (const key in filters) {
            if (filters.hasOwnProperty(key)) {
                httpParams = httpParams.set(key, filters[key]);
            }
        }
        return this.http.get(
            `${this.SERVER_URL}/reports/locator`, { params: httpParams });
    }

    public getGoogleEarthHistory(from: string, to: string) {
        return this.http.get(
            `${this.SERVER_URL}/reports/google-earth-history?from=` + from + `&to=` + to,
          {responseType: 'blob'}
        );
    }
    public getCurrentNOBExcel(groupBy, groupOrder, columnHeaders) {
        const data = {
            columnHeaders: columnHeaders,
            groupOrder: groupOrder,
            groupBy: groupBy
        };
        return this.http.post(
            `${this.SERVER_URL}/reports/current-nob-excel`,
            data,
            {responseType: 'blob'}
        );
    }
    public getCurrentNOB(filters): Observable<any> {
        let httpParams = new HttpParams();

        for (const key in filters) {
          if (filters.hasOwnProperty(key)) {
            httpParams = httpParams.set(key, filters[key]);
          }
        }
        return this.http.get(
            `${this.SERVER_URL}/reports/current-nob`, {params: httpParams}
        );
    }
    public getNOBTicker(): Observable<any> {
      const params = new HttpParams().set('option', 'nob-ticker');
        return this.http.get(
            `${this.SERVER_URL}/reports/current-nob`,
            {params: params}
        );
    }
    public getRecordMessage(currentJFMCC, type, saveReport, overrideFields, selectedPref, inPortHours) {
        let params;
     
        if (selectedPref) {
            params = {'current_jfmcc': currentJFMCC, 'type': type, 'saveReport': saveReport, 'selectedPref': selectedPref.featurePrefId, 'inPortHours': inPortHours}
        } else {
            params = {'current_jfmcc': currentJFMCC, 'type': type, 'saveReport': saveReport, 'selectedPref': undefined, 'inPortHours': inPortHours}
        }
        return this.http.post(
        `${this.SERVER_URL}/reports/record-message`,
            overrideFields,
            {params: params, responseType: 'blob'}
        );
    }

    public getPACMIS() {
        return this.http.get(
            `${this.SERVER_URL}/reports/pacmis`,
            {responseType: 'blob'}
        );
    }

    public getNOBKML() {
        return this.http.put(
            `${this.SERVER_URL}/reports/nob-kml`,
            {'OOB_CUSTOM': false},
            {responseType: 'blob',
             observe: 'response'}
        );
    }
    public getNetworkLinkKML() {
        return this.http.get(
            `${this.SERVER_URL}/reports/networkLinkKML`,
            {responseType: 'blob',
             observe: 'response'}
        );
    }
    public getHistoricKML(unit_id, t) {
        return this.http.get(
            `${this.SERVER_URL}/reports/historicKML?u=${unit_id}&t=${t}`,
            {responseType: 'blob',
             observe: 'response'}
        );
    }
    public getRSS() {
        return this.http.get(
            `${this.SERVER_URL}/rss`,
            {responseType: 'text'}
        );
    }

    public getSearchResultsKML(units) {
        return this.http.post(
            `${this.SERVER_URL}/reports/searchResultsKML`,
            units,
            {responseType: 'blob',
             observe: 'response'}
        );
    }

    // get KML from an uw_code for a unit
    public getUnderwayKML(unit_id, uw_code, color) {
        return this.http.get(
            `${this.SERVER_URL}/reports/underwayKML?unit_id=${unit_id}&uw_code=${uw_code}&color=${color}`,
            {responseType: 'blob',
             observe: 'response'}
        );
    }

    public getSearchResultsExcel(units, groupBy = null, groupOrder = null, columnHeaders = null, sort = null) {
        const data = {
            units: units,
            columnHeaders: columnHeaders,
            groupOrder: groupOrder,
            groupBy: groupBy,
            sort: sort
        };
        return this.http.post(
            `${this.SERVER_URL}/reports/searchResultsExcel`,
            data,
            {responseType: 'blob',
             observe: 'response'}
        );
    }

    public getLookupExcel(data) {
        return this.http.get(
            `${this.SERVER_URL}/reports/lookupExcel?lookupType=${data}`,
            {
                responseType: 'blob',
                observe: 'response'
            }
        );
    }

    public getUnitExcel() {
        return this.http.get(
            `${this.SERVER_URL}/reports/units-excel`,
            {
                responseType: 'blob',
                observe: 'response'
            }
        );
    }

    public getUserExcel(): Observable<any> {
        return this.http.get(
            `${this.SERVER_URL}/reports/users-excel`,
            {
                responseType: 'blob',
                observe: 'response'
            }
        );
    }
}
