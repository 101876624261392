import { Component, OnInit } from '@angular/core';
import {LoadingService} from '@services/global/loading/loading.service'
import { take } from 'rxjs/operators'

@Component({
  selector: 'app-http-loader',
  templateUrl: './http-loader.component.html',
  styleUrls: ['./http-loader.component.css']
})
export class HttpLoaderComponent implements OnInit {

  constructor(public loadingService: LoadingService) { }

  ngOnInit() {
    this.loadingService.isLoading.asObservable().pipe(take(1)).subscribe();
    this.loadingService.message.asObservable().pipe(take(1)).subscribe();
    this.loadingService.requests.asObservable().pipe(take(1)).subscribe();
  }

  cancelRequests() {
    this.loadingService.cancelRequests();
  }
}
