import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from '@services/api-services/api.config';

@Injectable()
export class ClassMarkingRelationshipApiService {
  SERVER_URL;

  constructor(
    private http: HttpClient, apiConfig: APIConfig) {
    this.SERVER_URL = apiConfig.TARGET_URL;
  }

  public getAllClassMarkingRelns(): Observable<any> {
    return this.http.get(
      `${this.SERVER_URL}/class-mark-relns`);
  }

  public getClassMarkingRelnById(classMarkingRelnId: string): Observable<any> {
    return this.http.get(
      `${this.SERVER_URL}/class-mark-relns/${classMarkingRelnId}`);
  }

  public updateClassMarkingReln(classMarkingReln: any): Observable<any> {
    return this.http.put(
      `${this.SERVER_URL}/class-mark-relns/${classMarkingReln.class_marking_relationship_id}`, classMarkingReln);
  }

  public createClassMarkingReln(classMarkingReln: any): Observable<any> {
    return this.http.post(
      `${this.SERVER_URL}/class-mark-relns`, classMarkingReln);
  }

  public deleteClassMarkingReln(classMarkingReln: any): Observable<any> {
    return this.http.delete(
      `${this.SERVER_URL}/class-mark-relns/${classMarkingReln.class_marking_relationship_id}`);
  }

  public deleteClassMarkingRelnByPrimaryId(classMarkingReln: any, rationale: string): Observable<any> {
    return this.http.delete(
      `${this.SERVER_URL}/class-mark-relns`,
      { body: { primary_marking_id: classMarkingReln.primary_marking_id, rationale: rationale } });
  }

  public bulkUpdateClassMarkingReln(classMarkingRelns: Object[], deletePrimaryId: string): Observable<any> {
    console.log("APIUPDATESVC")
    return this.http.put(
      `${this.SERVER_URL}/class-mark-relns`,
      { class_marking_reln_ids: classMarkingRelns, oldDeleteId: deletePrimaryId });
  }

  public getAllClassMarkingRelnByCategory(classMarkingCategoryId: string): Observable<any> {
    return this.http.get(
      `${this.SERVER_URL}/class-mark-relns/category/${classMarkingCategoryId}`);
  }

}
