import { Any, JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('MessageConfig')
export class MessageConfig {
  @JsonProperty('message_config_id', String, true)
  messageConfigId: string = undefined;
  @JsonProperty('system_connection_id', String, true)
  systemConnectionId: string = undefined;
  @JsonProperty('message_type', String, true)
  messageType: string = undefined;
  @JsonProperty('message_params', Any, true)
  messageParams: any = undefined;
  @JsonProperty('enabled', Boolean, true)
  enabled: boolean = false;
}
