import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from './api.config';
import { Form } from '@angular/forms';

@Injectable()
export class FeaturePreferenceApiService {
    SERVER_URL;

    constructor(
        private http: HttpClient, apiConfig: APIConfig) {
        this.SERVER_URL = apiConfig.TARGET_URL;
    }

    public getAll(): Observable<any> {
        let params = new HttpParams().set('sort', JSON.stringify([['feature_type_id', 'DESC'], ['version_num', 'DESC']]));
        
        return this.http.get(
            `${this.SERVER_URL}/featurePrefs`,
            { params: params }
            );
    }

    public getById(featurePrefsId: string): Observable<any> {
        return this.http.get(
            `${this.SERVER_URL}/featurePrefs/${featurePrefsId}`);
    }

    public getAllByType(featurePrefsTypeId: string): Observable<any> {
        return this.http.get(
            `${this.SERVER_URL}/featurePrefs/type/${featurePrefsTypeId}`);
    }

    public update(featurePrefs: any): Observable<any> {
        return this.http.put(
            `${this.SERVER_URL}/featurePrefs/${featurePrefs.feature_pref_id}`, featurePrefs);
    }

    public create(featurePrefs: any): Observable<any> {
        return this.http.post(
            `${this.SERVER_URL}/featurePrefs`, featurePrefs);
    }

    public delete(featurePrefs: any): Observable<any> {
        return this.http.delete(
            `${this.SERVER_URL}/featurePrefs/${featurePrefs.feature_pref_id}`);
    }
}
