<app-classification-bar class="top-modal-classification-bar"></app-classification-bar>
<app-fdtm-modal-header [headerString]="headerString"></app-fdtm-modal-header>
<div mat-dialog-content>
<form [formGroup]="editForm" #dtForm>
    <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px" fxLayoutGap.xs="0">
        <div fxFlex="20%">
            <h5>CLASSIFICATION:</h5>
        </div>
        <div fxFlex="20%">
            <h5>CONTROL: </h5>
        </div>
        
        <div fxFlex="20%">
            <h5>FGI:</h5>
        </div>
        
        <div fxFlex="20%">
            <h5>OTHER CONTROLS:</h5>
        </div>
        
        <div fxFlex="20%">
            <h5>DISSEMINATION:</h5>
        </div>
    </div>
    <div class="container shrink" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px" fxLayoutGap.xs="0">
    <div fxFlex="20%">
        <mat-radio-group class="noscroll-bordered" fxLayout="column" formControlName="classificationId" placeholder="Classification">
            <mat-radio-button validation="required" class="classification-option smallRadio" *ngFor="let option of classificationOptions"
                [value]="option.classificationId">
                {{option.classificationName}}
            </mat-radio-button>
        </mat-radio-group>
        <mat-error *ngIf="dtForm.submitted && editForm.controls.classificationId.invalid">Classification is required.</mat-error>
    </div>
    <div fxFlex="20%">
        <mat-selection-list class="bordered" fxLayout="column" formControlName="sciControl">
            <mat-list-option class="shrink" checkboxPosition="before" *ngFor="let option of sciControlsOptions" [value]="option.sciControlId">
                {{option.controlMark}}
            </mat-list-option>
        </mat-selection-list>
    </div>

    <div fxFlex="20%">
        <mat-selection-list class="bordered" fxLayout="column" formControlName="fgiControl">
            <mat-list-option class="shrink" checkboxPosition="before" [disabled]="option.isDisabled"
                *ngFor="let option of fgiControlOptions" [value]="option.fgiId">
                {{option.name}}
            </mat-list-option>
        </mat-selection-list>
    </div>

    <div fxFlex="20%">
        <mat-selection-list class="bordered" fxLayout="column" formControlName="otherControl">
            <mat-list-option class="shrink" checkboxPosition="before" *ngFor="let option of otherControlsOptions" [value]="option.controlId">
                {{option.description}}
            </mat-list-option>
        </mat-selection-list>
    </div>

    <div fxFlex="20%">
        <mat-selection-list class="bordered" fxLayout="column" formControlName="dissemination">
            <mat-list-option class="shrink" checkboxPosition="before" *ngFor="let option of releasabilityOptions" [value]="option.releasibilityId">
                {{option.releasabilityName}}
            </mat-list-option>
        </mat-selection-list>
    </div>
</div>
<br>
            <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px"
                fxLayoutGap.xs="0">

                <div fxFlex="30%" fxFlex.md="40%" fxFlex.lg="30%">
                    <app-lookup-select (selectedValueChanged)="entity.sourceId = $event.sourceId" [selectClass]="'default-width'"
                      [itemPK]="'sourceId'" [itemId]="entity.sourceId"
                      [typeName]="'Source'" [keyProperty]="'sourceName'" >
                    </app-lookup-select>
                </div>
            </div>

            <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px"
                fxLayoutGap.xs="0">

                <div fxFlex="30%" fxFlex.md="40%" fxFlex.lg="30%">
                    <mat-form-field class="default-width">
                        <input required matInput type="text" formControlName="dataTag" placeholder="Tag"
                        tabindex="2">
                    </mat-form-field>
                </div>
            </div>

            <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px"
                fxLayoutGap.xs="0">

                <div fxFlex="30%" fxFlex.md="40%" fxFlex.lg="30%">
                    <mat-form-field class="default-width">
                        <input required matInput type="text" formControlName="modRemarks" placeholder="Remarks"
                        tabindex="3">
                    </mat-form-field>
                </div>
            </div>
            <div mat-dialog-actions>
                    <button [disabled]="!editForm.valid || !entity.sourceId" mat-raised-button color="primary" (click)="submit()">
                        Submit
                    </button>
                    <button mat-raised-button type="reset" (click)="cancel()">
                        Cancel
                    </button>
            </div>
</form>
</div>
<app-classification-bar class="bottom-modal-classification-bar"></app-classification-bar>