import { Component, OnInit } from '@angular/core';
import { OwnerTypeService } from '@services/data-services/owner-type.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { EditModalBaseComponent } from '@fom-module/base-classes/edit-modal-base.component';
import { LookUpService } from '@services/data-services/lookup.service';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-edit-owner-type-modal',
  templateUrl: './edit-owner-type-modal.component.html',
  styleUrls: ['./edit-owner-type-modal.component.css']
})
export class EditOwnerTypeModalComponent extends EditModalBaseComponent implements  OnInit {

  editForm: FormGroup;

  constructor(public ownerTypeService: OwnerTypeService,
    public lookUpService: LookUpService,
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<EditOwnerTypeModalComponent>,
    public messageBusService: MessageBusService) {
      super(ownerTypeService, dialogRef);
    }

  ngOnInit() {
    this.headerString = this.isNew ? 'Add Owner Type' : 'Edit Owner Type'
    this.editForm = this.formBuilder.group({
      description: [this.entity.description],
      tableName: [this.entity.tableName],
      keyColumn: [this.entity.keyColumn],
      nameColumn: [this.entity.nameColumn]
    });
  }
}
