import { Injectable } from '@angular/core';
import { User } from '@models/user.model';
import { JsonConvert } from 'json2typescript';

@Injectable({
    providedIn: 'root'
})
export class CurrentUserService {
    private jsonConvert: JsonConvert;

    constructor() {
        this.jsonConvert = new JsonConvert();
    }

    getCurrentUser(): User {
        return this.jsonConvert.deserializeObject(JSON.parse(sessionStorage.getItem('user')), User);
    }
}
