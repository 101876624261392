<div class="admin-work-area">
  <div fxLayout fxLayoutAlign="center center" class="simple-grid-title">
    <span class="grid-title">{{init.getConfig().titlePrefix}} FDT-M User Maintenance</span>
    <!-- Add Unit Floating Action Button -->
    <button mat-mini-fab class="add-button" (click)="add()">
      <img class="light-icon" src="./assets/icons/ic_add_white_24px.svg" matTooltip="Add User" />
    </button>
  </div>
  <div fxLayout fxLayoutAlign="center center">
    <mat-form-field class="groupSelect">
      <mat-select [(ngModel)]="grouping" placeholder="Group By" (selectionChange)="groupingChanged($event, grouping)">
        <mat-option *ngFor="let groupItem of groupItems" [value]="groupItem">
          {{groupItem.display}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field fxFlex="40%">
      <input matInput type="text" [(ngModel)]="filterValue" (ngModelChange)="onFilterChange($event)" autocomplete="off"
        placeholder="Filter Users (username first last)">
      <span class="hyper" (click)="filterValue = ''; clearFilter('')" matSuffix>X</span>
    </mat-form-field>
  </div>
  <div class="mat-elevation-z8">
    <div fxLayout class="table-actions">
      <div class="collapse-actions">
        <button mat-raised-button *ngIf="hasAnyExpandedGroups()" (click)="changeAllGroups(false)"
          matTooltip="Collapses all groupings within the table">
          <mat-icon>unfold_less</mat-icon> Collapse All
        </button>
        <button mat-raised-button *ngIf="!hasAnyExpandedGroups()" (click)="changeAllGroups(true)"
          matTooltip="Expands all groupings within the table">
          <mat-icon>unfold_more</mat-icon> Expand All
        </button>
      </div>

      <div class="export-actions">
        <button mat-raised-button [disabled]="!readyForExport" (click)="exportToExcel()">
          <mat-icon>file_download</mat-icon> Export to Excel
        </button>
      </div>

      <!-- Paginator -->
      <mat-paginator fxFlexOffset="auto" [pageSizeOptions]="[100, 200, 500, 1000, 5000]" [pageSize]="200"
        [length]="dataSource?.filteredData?.length - groupCount" showFirstLastButtons></mat-paginator>
    </div>

    <cdk-virtual-scroll-viewport tvsItemSize="48" headerHeight="56">
      <table mat-table [dataSource]="dataSource" matSort matSortStart="desc" (matSortChange)="sortData($event)">
        <!-- Edit Action Column -->
        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef>
            <div>
              Edit
            </div>
          </th>
          <td mat-cell *matCellDef="let row">
            <button title="Edit User" mat-button *ngIf="canEdit(row)" class="delete-button"
              (click)="$event.stopPropagation()" (click)="edit(row)">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container>
        <!-- Command Column -->
        <ng-container matColumnDef="command.name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Command </th>
          <td mat-cell *matCellDef="let element"> {{element.command.name}} </td>
        </ng-container>

        <!-- First Name Column -->
        <ng-container matColumnDef="firstName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
          <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
        </ng-container>

        <!-- Last Name Column -->
        <ng-container matColumnDef="lastName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>
          <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>
        </ng-container>

        <!-- Username Column -->
        <ng-container matColumnDef="username">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Username </th>
          <td mat-cell *matCellDef="let element"> {{element.username}} </td>
        </ng-container>

        <!-- Permissions Column -->
        <!--ng-container matColumnDef="permissions">
            <th mat-header-cell *matHeaderCellDef> Permissions</th>
            <td mat-cell *matCellDef="let element"> {{element.permissions}} </td>
        </ng-container-->

        <!-- Permissions Column -->
        <ng-container matColumnDef="permission.permissionDescription">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Permission </th>
          <td mat-cell *matCellDef="let element"> {{element.permission.permissionDescription}} </td>
        </ng-container>

        <!--Enabled Column-->
        <ng-container matColumnDef="disabled">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Enabled </th>
          <td mat-cell *matCellDef="let element"> {{element.disabled || element.isInactive(daysUntilInactive) ? "No" :
            "Yes"}}</td>
        </ng-container>

        <!--Whitelisted Column-->
        <ng-container matColumnDef="whitelisted">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Whitelist </th>
          <td mat-cell *matCellDef="let element"> {{element.whitelisted ? "Yes" :
            "No"}}</td>
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
          <td mat-cell *matCellDef="let element"> {{element.email}} </td>
        </ng-container>

        <!--Justification Coloumn-->
        <!--ng-container matColumDef="justification">
          <th mat-header-cell *matHeaderCellDef> Justification </th>
          <td mat-cell *matCellDef="let element"> {{element.justification}} </td>
        </ng-container-->

        <!-- Last login activity Column -->
        <ng-container matColumnDef="lastActivity">
          <th mat-header-cell *matHeaderCellDef> Last Login Activity </th>
          <td mat-cell *matCellDef="let element">{{formatLastActivity(element)}}</td>
        </ng-container>

        <!-- Delete Action Column -->
        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef>
            <div>
              Delete
            </div>
          </th>
          <td mat-cell *matCellDef="let row" title="Delete User">
            <button title="Delete User" mat-button class="delete-button" (click)="$event.stopPropagation()"
              (click)="checkBeforeDelete(row)" *ngIf="canDelete(row)">
              <img src="./assets/icons/ic_delete_gray_24px.svg" />
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="reset">
          <th mat-header-cell *matHeaderCellDef>
            <div>
              Reset Password
            </div>
          </th>
          <td mat-cell *matCellDef="let row" title="Reset Password">
            <button mat-button (click)="$event.stopPropagation()" (click)="resetPassword(row)"
              *ngIf="canResetPassword(row)">
              ***************
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr class="table-row" mat-row *matRowDef="let row; let i = index; columns: displayedColumns;"></tr>
        <!-- Group header -->
        <ng-container matColumnDef="groupHeader">
          <td colspan="999" mat-cell *matCellDef="let group; grouping" class="hyper">
            <!-- <mat-icon title="hide group items" class="hyper" *ngIf="group.expanded">chevron_left</mat-icon>
          <mat-icon title="show group items" class="hyper" *ngIf="!group.expanded">chevron_right</mat-icon> -->
            <span title="click to show/hide group items">
              {{group.name}}: {{group.itemCount}} total User(s)

            </span>
          </td>
        </ng-container>

        <tr mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup" (click)="groupHeaderClick(row)" class="groupHeader"></tr>
      </table>
    </cdk-virtual-scroll-viewport>
  </div>
</div>
