import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from './api.config';
import { Form } from '@angular/forms';

@Injectable()
export class ParameterApiService {
    SERVER_URL;

    constructor(
        private http: HttpClient, apiConfig: APIConfig) {
        this.SERVER_URL = apiConfig.TARGET_URL;
    }

    public getAllParameters(): Observable<any> {
        return this.http.get(
            `${this.SERVER_URL}/parameters`);
    }

    public getParameterByParameterId(parameterId: string): Observable<any> {
        return this.http.get(
            `${this.SERVER_URL}/parameters/${parameterId}`);
    }

    public getBlob(parameterId: string): Observable<any> {
        return this.http.get(
            `${this.SERVER_URL}/parameters/blob/${parameterId}`,
            {responseType: 'blob'});
    }

    public updateParameter(parameterId: any, formData: FormData): Observable<any> {
        return this.http.put(
            `${this.SERVER_URL}/parameters/${parameterId}`, formData);
    }

    public createParameter(formData: FormData): Observable<any> {
        return this.http.post(
            `${this.SERVER_URL}/parameters`, formData);
    }

    public deleteParameter(parameter: any): Observable<any> {
        return this.http.delete(
            `${this.SERVER_URL}/parameters/${parameter.parameter_id}`);
    }
}
