import { Component, Input, OnInit, Inject} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ReportingService } from '@services/data-services/reporting.service';
import { FeatureType } from '@models/feature-type.model';
import { FeaturePreference } from '@models/feature-preference.model';
import { FeaturePreferenceService } from '@services/data-services/feature-preference.service';
import { FeatureTypeService } from '@services/data-services/feature-type.service';
import { ModalResponse } from "@core-module/enums/modal-response.enum";
import { filter, take } from "rxjs/operators";
import { UserNotificationService } from '@services/notification.service';

@Component({
  selector: "app-recordmessage-popup-modal",
  templateUrl: "./recordmessage-popup-modal.component.html",
  styleUrls: ["./recordmessage-popup-modal.component.css"],
})
export class RecordMessagePopupModalComponent implements OnInit {
  reportPreview: Blob;
  headerString = "Report Preview and Override Preference Values";
  reportPreviewText: string;
  currentJFMCC: any;
  type: string;
  saveReport: boolean;
  featureTypeDefinition: any;
  selectedPref: FeaturePreference;
  inPortHours: number;
  overrideFields = {};
  status: string = 'ready';

  constructor(
    public dialogRef: MatDialogRef<RecordMessagePopupModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private reportingService: ReportingService,
    public featureTypeService: FeatureTypeService,
    public featurePreferenceService: FeaturePreferenceService,
    private notifier: UserNotificationService
  ) {this.reportPreview = data.blob, this.currentJFMCC = data.currentJFMCC, this.type = data.type, this.saveReport = data.saveReport, this.selectedPref = data.selectedPref, this.inPortHours = data.inPortHours}

  async ngOnInit() {
    // convert report preview from blob to text
    try {
      this.reportPreviewText = await this.reportPreview.text();
    } catch(e) {
      throw("Could not fetch report preview: " + e)
    }

    // get the schema definition over the feature type schema definition and only display the footer fields in the ui to allow the user to override footer fields
    let featureTypes;
    try {
      let featureTypeName
      if (this.type === "MOS") {
        featureTypeName = "RMG Report:MOS"
      } else if (this.type === "MDOS") {
        featureTypeName = "RMG Report:MDOS"
      } else {
        featureTypeName = "RMG Report:PACFLT"
      }
      featureTypes = await this.featureTypeService.getAllByName(featureTypeName).pipe(take(1)).toPromise();
      if (featureTypes.length !== 0) {
        this.featureTypeDefinition = featureTypes[0].featureTypeDefinition;
      } else {
        this.featureTypeDefinition = undefined;
      }
    } catch(e) {
      throw("Error fetching schema definition footer fields: " + e);
    }

    try {
      // pull feature preference values that was used in report. If nothing was selected, pull latest feature pref values (which is what the report does)
      // else us selected feature preference values
      let values
      if (featureTypes.length !== 0) {
        if (!this.selectedPref) {
          const featurePrefByType = await this.featurePreferenceService.getAllByType(featureTypes[0].featureTypeId).pipe(take(1)).toPromise();
          values = featurePrefByType[0].featurePrefValue;
        } else {
          values = this.selectedPref.featurePrefValue;
        }
        // assign each feature preference value to its appropriate html field
        if (this.featureTypeDefinition) {
          this.featureTypeDefinition.Sections.forEach(section => {
            if (section.name === "footerFields") {
              section.Fields.forEach(field => {
                if (field.name in values) {
                  field.value = values[field.name]
                }
              })
            }
          })
        }
      }
    } catch (e) {
      throw("Error fetching feature preference values: " + e);
    }
  }

  // assign override values to the overrideFields object to get passed to the api
  assignUserOverrideValues() {
    try {
      if (this.featureTypeDefinition) {
        this.featureTypeDefinition.Sections.forEach(section => {
          if (section.name === "footerFields") {
            section.Fields.forEach(field => {
              if (field.value !== undefined) {
                this.overrideFields[field.name] = field.value;
              }
            })
          }
        })
      }
      const removeBlankFields = (obj) => Object.fromEntries(Object.entries(obj).filter(([_, v]) => v));
      this.overrideFields = removeBlankFields(this.overrideFields)
    } catch(e) {
      throw("Error assigning override values: " + e);
    }
  }

  // generate new report with override values and save report to database in report_activity table 
  // download 'save as' functionality with a HTML5 download fallback for older browsers
  async submit() {
    try {
      this.status= 'in progress'
      this.assignUserOverrideValues();
      let fileName = `${this.type} Nob Report`
      
      // check if the browser has showSaveFilePicker functionality
      // if it does, use showSaveFilePicker to download file. If not, use
      // regular HTML5 download feature
      
      // create the file handle here to avoid timeout security error when downloading file via showSaveFilePicker
      let fileHandlePromise
      if ('showSaveFilePicker' in window) {
        fileHandlePromise = this.exportNativeFileSystem(fileName);
      }
      
      const saveReport = true;
      const returnBlobPromise = this.reportingService.getRecordMessage(this.currentJFMCC, this.type, saveReport, this.overrideFields, this.selectedPref, this.inPortHours).pipe(take(1)).toPromise()

      // Use Promise.all to wait for both the API call and file handling tasks to complete
      // This allows the report to generate while the user is picking where to save the file
      const [fileHandle] = await Promise.all([fileHandlePromise]);
      
      if(fileHandle) {
        const [returnBlob] = await Promise.all([returnBlobPromise]);
        const file = new Blob([returnBlob]);
        await this.writeFile({fileHandle, file})
      } else {
        // original download functionality that works on all browsers
        fileName = (window as Window).prompt("Please enter a file name", fileName);
        if (fileName === null) {
          this.status = 'ready'
          return this.notifier.showError('Request Cancelled', 4000)
        }
        const [returnBlob] = await Promise.all([returnBlobPromise]);
        const file = new Blob([returnBlob]);
        this.download({file, fileName});
      }
      
      this.dialogRef.close({
        response: ModalResponse.SAVE,
      });

    } catch (e) {
      this.status = 'ready'
      // catch if the user hits cancel in the 'Save As' window
      if (e.name ==='AbortError') {
        this.notifier.showError('Save As Request Cancelled', 4000)
        return
      } else {
        throw("Error generating and downloading report")
      }
    }
  }

  cancel() {
    this.dialogRef.close({response: ModalResponse.CANCEL});
  }

  // generate a preview for the user without saving the report to the database
  async regenPreview() {
    this.status = 'in progress'
    this.reportPreviewText = '';
    this.assignUserOverrideValues();
    

    this.reportingService.getRecordMessage(this.currentJFMCC, this.type, this.saveReport, this.overrideFields, this.selectedPref, this.inPortHours).pipe(take(1)).subscribe(async nob => {
      this.status = 'complete'
      this.reportPreviewText = await nob.text();
    }, err => {
      // we caught the error only to stop the progress bar
      this.status = 'complete'
      // we still want to throw it to the global ErrorHandler
      throw ("Error generating report with new values for preview: " + err)
    });
  }

  // create a file handle 
  exportNativeFileSystem = async (fileName: string) => {
    const fileHandle: FileSystemFileHandle = await this.getNewFileHandle({fileName});
  
    if (!fileHandle) {
      throw new Error('Cannot access filesystem');
    }
    
    return fileHandle;
  }

  getNewFileHandle = ({fileName}: {fileName: string}): Promise<FileSystemFileHandle> => {
    const opts: SaveFilePickerOptions = {
      suggestedName: fileName,
      types: [
        {
          description: 'Text file',
          accept: {
            'text/plain': ['.txt']
          }
        }
      ]
    };
  
    return showSaveFilePicker(opts);
  }

  // write to the file of the file handle
  writeFile = async ({fileHandle, file}: {fileHandle: FileSystemFileHandle; file: Blob}) => {
    const writer: FileSystemWritableFileStream = await fileHandle.createWritable();
    await writer.write(file);
    await writer.close();
  }

  // legacy download functionality that works on all browsers
  download = ({fileName, file}: {fileName: string; file: Blob}) => {
    const a: HTMLAnchorElement = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);
  
    const url: string = window.URL.createObjectURL(file);
  
    a.href = url;
    a.download = `${fileName}.txt`;
  
    a.click();
  
    window.URL.revokeObjectURL(url);
    a.parentElement?.removeChild(a);
  }
}
