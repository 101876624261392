import { LookUpService } from '@services/data-services/lookup.service';
import { UnitService } from '@services/data-services/unit.service';
import { AuditUnitBorrowedService } from '@services/data-services/audit.service';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Area } from '@models/area.model';
import { Activity } from '@models/activity.model';
import { User } from '@models/user.model';
import { Command } from '@models/command.model';
import { FreqType } from '@models/freq-type.model';
import { ScanType } from '@models/scan-type.model';
import { Elnot } from '@models/elnot.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UtilityService } from '@services/utility.service';
import { ParserQueueService } from '@services/data-services/parser-queue.service';
import { HttpResponse } from '@angular/common/http';
import { exhaustMap, scan, filter, startWith, switchMap, take, takeWhile, tap, debounceTime } from 'rxjs/operators';
import { ReportingService } from '@services/data-services/reporting.service';
import { Source } from '@models/source.model';
import { CurrentUserService } from '@services/current-user-service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { EditUnitModalComponent } from '../edit-unit-modal/edit-unit-modal.component';
import { Unit } from '@models/unit.model';
import { FormBuilder, FormGroup, FormGroupDirective } from '@angular/forms';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';
import { AppInitService } from '../../../../config/init.service';
import { ClassificationBarComponent } from '@core-module/app-components/classification-bar/classification-bar.component';
import { UnitOtherNameService } from '@services/data-services/unit-other-name-service';
import { UnitOtherName } from '@models/unit-other-name.model';
import { LocationService } from '@services/data-services/location.service';
import { UnitUpdateComponent } from '@fom-module/unit-update/unit-update.component';
import { ConfidenceCode } from '@models/confidence-code.model';

@Component({
  selector: 'app-edit-parser-queue-modal',
  templateUrl: './edit-parser-queue-modal.component.html',
  styleUrls: ['./edit-parser-queue-modal.component.css']
})
export class EditParserQueueModalComponent implements OnInit, AfterViewInit {

  editForm: FormGroup;
  private nextOtherNamesPage$ = new Subject();
  private nextUnitPage$ = new Subject();
  // editForm: FormGroup;
  isLoading = false;
  filteredOtherNames$: Observable<Unit[]> | undefined;
  filteredUnits$: Observable<Unit[]> | undefined;
  unitsPerPage: number;

  //autocomplete trigger for autocompleting other name
  @ViewChild(MatAutocompleteTrigger, { static: true }) otherNameAutocomplete: MatAutocompleteTrigger;
  @ViewChild(MatAutocompleteTrigger, { static: true }) unitAutocomplete: MatAutocompleteTrigger;
  @ViewChild('editFormDirective') editFormDirective: FormGroupDirective;

  // passed in via call to modal
  entry: any;
  isNew: boolean;
  user: User;
  entity: any;
  unit: any = {};  // using this to drive the filters for dropdowns, would prefer it all be databound to ngModel...
  isUnMatched: boolean;

  // booleans to hold whether a user
  overrideOriginator = false;
  overrideSource = false;
  overrideConfidence = false;

  // Entered/Select override values, sent to API if accepted.
  originator: string;
  source: Source;
  confidenceCode: ConfidenceCode;

  trigger: any = {};

  allOpAreaOptions: Area[] = [];
  opAreaOptions: Area[] = [];

  // the user preferences passed in and out
  currentUserABACPrefs: any;
  selectedCommandId: any;
  headerString: string;

  // check if parser queue entry has valid elnot 
  validElnot: boolean = true;
  // check if parser queue entry has valid freq type 
  validFreqType: boolean = true;
  // check if parser queue entry has valid scan type
  validScanType: boolean = true;

  //check if activity is INPT or RTP for in-port logic
  inPortActivityFlag: boolean = false;

  //New box to let user trigger Unit Update
  setInPort: boolean = false;

  // activity category list that determines if the activity is in port
  inPortActivityCategoryList: string[];

  // Automatically set unit underway if this field is true
  setUnderway: boolean = false;

  // boolean for checking whether or not to save the aname to the unit
  saveOtherName: boolean = false;
  otherName: string = null;

  // boolean for whether or not the parser entry needs manual review through unit update for "bad data"
  needsReview: boolean = false;

  constructor(
    public locationService: LocationService,
    public unitService: UnitService,
    public unitOtherNameService: UnitOtherNameService,
    private parserQueueService: ParserQueueService,
    public lookUpService: LookUpService, private reportingService: ReportingService,
    public snackBar: MatSnackBar, private utilityService: UtilityService,
    public dialogRef: MatDialogRef<EditParserQueueModalComponent>,
    private formBuilder: FormBuilder,
    public initService: AppInitService,
    private dialog: MatDialog,
    public utilities: UtilityService,
    public currentUserService: CurrentUserService) {
  }
  ngAfterViewInit(): void {
    // if (this.entry.unit && this.entry.unit.nobReport) {
    //   // force the trigger, new references
    //   this.trigger = Object.assign({}, this.trigger);
    // }
  }

  ngOnInit() {
    this.headerString = `Parser Queue Entry for ${this.entry.name}`
    this.currentUserABACPrefs = JSON.parse(sessionStorage.getItem('currentUserABACPrefs'));
    this.unitsPerPage = this.initService.getConfig().unitPageSize || 20;

    this.inPortActivityCategoryList = this.initService.getConfig().inPortActivityCategoryList || ["INPT", "RTP"];

    this.user = this.currentUserService.getCurrentUser();
    this.entry.dec_lat = this.utilityService.decimalLatitude(this.entry.latitude);
    this.entry.dec_lon = this.utilityService.decimalLongitude(this.entry.longitude);
    this.originator = this.entry.originator;
    this.confidenceCode = this.entry.confidenceCode

    // ok, if the parser queue entry exists in NOB, grab the stuff
    if (this.entry.unit && this.entry.unit.nobReport) {
       //block this out for now to force them to make an entry
      //this.entry.activity = this.entry.unit.nobReport.activity;
      //this.trigger.activityId = this.entry.activityCategoryId = this.entry.unit.nobReport.activity_category_id;
      this.trigger.opAreaId = this.entry.opAreaId = this.entry.unit.nobReport.op_area_id;
     // force the trigger, new references
      // this.trigger = Object.assign({}, this.trigger);
    }
    this.trigger.ownerId = this.user.unitOwnerId(this.entry.unit);

    if (this.entry.unit) {
      this.isUnMatched = false;
    } else {
      this.isUnMatched = true;
    }
    Promise.resolve().then(() => {
      this.getRawMessage(this.entry);
    });

    // Call the invalidTypeCheck function using setTimeout to defer the execution
    setTimeout(() => {
      this.invalidElnotCheck();
      this.invalidFreqTypeCheck();
      this.invalidScanTypeCheck();
    });

    if (this.isUnMatched) {
      this.otherName = this.entry.name;
      const filters = Object.assign({}, JSON.parse(sessionStorage.getItem('unitOtherNameFilters')));
      this.initializeForm(filters);
    }

    // this.editForm = this.formBuilder.group({
    //   commandId: [{ value: this.entity.commandId, disabled: this.isDelete }],
    //   el_not_code: [{ value: this.entity.el_not_code, disabled: this.isDelete }]
    // });
  }

  //TODO generate new unit from parserqueue track
  generateUnit(entry) {

  }

  initializeForm(filters, clear = true): void {
    this.editForm = this.formBuilder.group({
      otherNameSearch: [''],
      unit: [''],
      actCatId: ['']
    });
    filters['sort'] = 'name'; // sort by name.

    //attempt to set picklist to dirty to invoke logic
    const userFilters = Object.assign(filters, this.user.unitUpdateFilter())
    const unitFilter$ = this.editForm.get('unit').valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      filter(q => typeof q === "string"));
    // const unitOtherNameFilters$ = this.editForm.get('otherNameSearch').valueChanges.pipe(
    //   startWith(''),
    //   debounceTime(300),
    //   filter(q => typeof q === 'string'));

    this.filteredUnits$ = unitFilter$.pipe(
      switchMap(filter => {
        // Reset current page whenever name filter changes.
        let currentPage = 1;
        return this.nextUnitPage$.pipe(
          tap(() => this.isLoading = true),
          startWith(currentPage),
          // Note: Until the backend responds, ignore NextPage requests.
          exhaustMap(_ => this.searchUnits(filter, userFilters, currentPage)),
          tap(() => currentPage++),

          takeWhile(p => p.length > 0, true),
          scan((allUnits: any, newUnits: any) => allUnits.concat(newUnits), []),
          tap(async units => {
            const unit = this.editForm.get('unit')?.value || '';

            if (unit && unit.length) {
              await this.clearForm();

              // TODO: not working so i'm commenting this out for now. figure out how to make this work
              // if (units.length === 1 && unit.trim().toLocaleLowerCase() === units[0].fullName().toLocaleLowerCase()) {
              //   this.unitAutocomplete.closePanel();
              //   this.handleUnitChange({ option: { value: units[0] } })
              // }
            }
            this.isLoading = false;
          })
        );
      }));

    // !!! This is for otherName autocomplete logic, very similar to filteredUnits above !!!
    // this.filteredOtherNames$ = unitOtherNameFilters$.pipe(
    //   switchMap(filter => {
    //     // Reset current page whenever name filter changes.
    //     let currentPage = 1;
    //     return this.nextOtherNamesPage$.pipe(
    //       tap(() => this.isLoading = true),
    //       startWith(currentPage),
    //       // Note: Until the backend responds, ignore NextPage requests.
    //       exhaustMap(_ => this.searchUnitOtherNames(filter, filters, currentPage)),
    //       tap(() => currentPage++),
    //       takeWhile(p => p.length > 0, true),
    //       scan((allOtherNames: any, newOtherNames: any) => allOtherNames.concat(newOtherNames), []),
    //       tap(othernames => {
    //         const othername = this.editForm.get('otherNameSearch')?.value || '';

    //         if(othername?.length) {
    //           if (othernames.length === 1 && othername.trim().toLocaleLowerCase() === othernames[0].fullName().toLocaleLowerCase()) {
    //             this.otherNameAutocomplete.closePanel();
    //             this.editForm.controls.unitOtherName.setValue(othernames[0]);
    //           }
    //         }

    //         this.isLoading = false;
    //       })
    //     );
    //   })
    // );
  }

  getRawMessage(entry) {
    this.parserQueueService.getRawMessage(entry).subscribe(response => {
      this.entry.rawMessage = response;
    }, error => {
      this.entry.rawMessage = 'Unable to retrieve raw message';
    });
  }

  getKML(entry) {
    this.parserQueueService.getKML(entry).pipe(take(1)).subscribe((response: HttpResponse<any>) => {
      this.downloadKML(response, 'Parser KML.kml');
    });
  }

  tracks(entry: any, item: string) {
    this.reportingService.getHistoricKML(entry.unit.unitId, item).pipe(take(1)).subscribe(response => {
      const filename = `${entry.name}_(${item}).kml`;
      this.downloadKML(response, filename);
    });
  }

  downloadKML(response, filename) {
    const disposition = response.headers.get('content-disposition');
    let match: any;
    if (disposition) {
      match = disposition.match(/filename="(.*)"/)[1];
    }
    const fileName = match || filename;

    const file = new Blob([response.body]);

    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(file);
    } else {
      const anchor = document.createElement('a');
      anchor.href = window.URL.createObjectURL(file);
      anchor.style.display = 'none';
      anchor.download = fileName;
      document.body.appendChild(anchor);
      anchor.click();
    }
  }

  // button actions
  accept(entry) {

    if (!this.validElnot || !this.validFreqType || !this.validScanType) {
      const ok = confirm(`WARNING:\nThis track contains invalid fields that is not in our database.\n` +
        `Invalid Fields:\n${!this.validElnot ? 'Elnot\n' : ''}${!this.validScanType ? 'Scan Type\n' : ''}${!this.validFreqType ? 'Frequency Type\n' : ''}\n` +
        `Any invalid elnot, scan type of frequency type will be dropped and will not transfer to track entry\n` +
        `Click 'OK' to proceed.`);

      if (!ok) {
        this.dialogRef.close(false)
        return;
      }
    }
    // Override the values when sending to the API.
    if (this.originator !== this.entry.originator) {
      entry.originator = this.originator;
    }

     // Override the values when sending to the API.
     // if value came straight from the GALE message, then it will be a string
     // if value came from override, then we have to pull string from the object
     if (this.confidenceCode !== this.entry.confidenceCode) {
      entry.confidenceCode = this.confidenceCode.confidenceCode || this.confidenceCode;
    }
  
   

    // Override the source value if selected.
    if (this.source) {
      entry.sourceId = this.source.sourceId;
    }
    this.parserQueueService.ingest(entry).pipe(take(1)).subscribe(
      (response) => {
        let message = 'Successful ingest and send to COP';

        if (response.status === "send2CopError") {
          message = 'Send to COP Error: This track is ingested into FDTM but was not sent to COP';
        }

        // if the user has selected a unit and wants to save this entry's name as an other name, then send the other name to the api to save the other name to the unit
        if (this.saveOtherName && entry.unit) {
          let otherName = new UnitOtherName();
          otherName.unitId = entry.unit.unitId;
          otherName.commandId = entry.unit.commandId;
          otherName.otherName = this.otherName;
          otherName.createTs = new Date();

          this.unitOtherNameService.create(otherName).pipe(take(1)).subscribe((response) => {
            this.unitOtherNameService.updateParserEntries().pipe(take(1)).toPromise();
          }, error => {
            throw 'There was an error saving this record\'s name as an other name for the selected unit';
          });
        }

        this.snackBar.open(response.message ? response.message : message, 'Close', { duration: response.status === "send2CopError" ? 10000 : 3000 });
        //check to see if we need to run the Unit Update screen instead due to in port
        
        if (this.setInPort || this.needsReview) {
          this.locationService.getAll({ unit_id: entry.unit.unitId, sort: 'create_ts', limit: 1 }).pipe(take(1)).subscribe(results => {
            this.doUnitUpdateAccept(results[0]);
          });
        }
        this.dialogRef.close(true);
      }, error => { //catch any errors that occur in galesend. Open a snackbar letting the user know galesend failed
        if (error.error && error.error.message && (
          error.error.message.includes('FDTMFailedSendError') ||
          error.error.message.includes('FDTMAuthenticationError') ||
          error.error.message.includes('FDTMConfigurationError') ||
          error.error.message.includes('FDTMLocationUpdateFailureError'))) {
          this.snackBar.open('Send to COP Error: This track is ingested into FDTM but was not sent to COP', 'Close', { duration: 10000 });
          this.dialogRef.close(true);
        } else {
          throw error;
        }
      }
    );
  }

  async invalidElnotCheck() {
    if (this.entry.elint && this.entry.elint.el_not) {
      let elnots = await this.lookUpService.getLookupByType(Elnot).pipe(take(1)).toPromise();

      const elnotExists = elnots.some((type) => type.el_not_code === this.entry.elint.el_not);

      this.validElnot = elnotExists;
    }
  }

  async invalidFreqTypeCheck() {
    if (this.entry.elint && this.entry.elint.freq) {
      let freqTypes = await this.lookUpService.getLookupByType(FreqType).pipe(take(1)).toPromise();

      const freqTypeExists = freqTypes.some((type) => type.freq_type_description === this.entry.elint.freq);

      this.validFreqType = freqTypeExists;
    }
  }

  async invalidScanTypeCheck() {
    if (this.entry.elint && this.entry.elint.scanType) {
      let scanTypes = await this.lookUpService.getLookupByType(ScanType).pipe(take(1)).toPromise();

      const scanTypeExists = scanTypes.some((type) => type.scan_type_code === this.entry.elint.scanType);

      this.validScanType = scanTypeExists;
    }
  }

  delete(entry) {
    const ok = confirm(`This report contains ${entry.points} history point(s).\n\nOld ` +
      `positions can still be added without overwriting \nthe latest update. ` +
      `This will help develop the unit history.\n\nClick 'OK' to discard the updates.`);
    if (ok) {
      this.parserQueueService.delete(entry).pipe(take(1)).subscribe(results => {
        this.dialogRef.close(true);
      });
    } else {
      this.dialogRef.close(false);
    }
  }

  cancel() {
    if (this.isUnMatched) {
      // clear out the entry's unit on cancel to clear out any unit linkage that may have been set from aname update
      this.entry.unit = null;
      this.entry.name = this.otherName;
      // // clear out the activity category, activity, and op area from the previous unit because we're cancelling the assignment to a unit
      this.entry.activityCategoryId = null;
      this.entry.activity = null;
      this.entry.opAreaId = null;
    }
    this.dialogRef.close(false);
  }

  toggleOverrideOriginator() {
    this.overrideOriginator = !this.overrideOriginator;
    this.originator = this.entry.originator;
  }

  toggleOverrideConfidence() {
    this.overrideConfidence = !this.overrideConfidence;
    this.confidenceCode = null;
  }

  toggleOverrideSource() {
    this.overrideSource = !this.overrideSource;
    this.source = null;
  }

  showOverrides(): boolean {
    return this.overrideOriginator || this.overrideSource || this.overrideConfidence;
  }

  // canEdit(unit): boolean {
  //   return this.user.canEdit(unit)
  // }

  /*New canEdit is localized to data from parser skinny view*/
  canEdit(entry): boolean {
    //if unmatched record, limit delete to supervisor
    if (!entry.unitId) {
      return (this.user.isSupervisor())
    }
  //otherwise, matched record, limit delete to any read/write for the JFMCC or a SystemAdmin
    let canEdit = entry?.owningCommandId === this.user?.JFMCCId || entry?.borrowerCommandId === this.user?.JFMCCId;
    // console.log(canEdit)
    if (this.user?.currentJFMCC) {
      
      canEdit = canEdit || entry?.owningCommandId === this.user.currentJFMCC?.commandId || entry?.borrowerCommandId === this.user.currentJFMCC?.commandId;
      //canEdit = canEdit || unit.borrowerCommandId == (this as any).currentJFMCC.commandId
    }
    return this.user.isSystemAdmin() || canEdit;
  }

  selectActivity(value, desc) {
    if (value) {
      this.entry.activityCategoryId = value;
      this.setInPortActivityFlag(desc);
    }
  }

  setInPortActivityFlag(desc) {
    let inPortValList = this.inPortActivityCategoryList;
    if (desc) {
      //is this inPort INPT or RTP
      if (inPortValList.includes(desc)) {
        this.inPortActivityFlag = true;
      } else {
        this.inPortActivityFlag = false;
      }

    }
  }

  add(entry) {
    const dialogRef = this.dialog.open(EditUnitModalComponent, {
      width: '680px'
    });
    dialogRef.componentInstance.unit = new Unit();
    dialogRef.componentInstance.isNew = true;
    // dialogRef.componentInstance.units = this.units;

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      // in order to get complete data for the new Unit, go back to the DB
      if (result) {
        // this.loadUnits();
      }
    });
  }

  doUnitUpdateAccept(location: Location): void {
    const updateSuccess = this.openEditDialog(location);
    if (updateSuccess) {
      this.dialogRef.close(false)
      return;
    }
  }

  openEditDialog(location: Location, isDelete = false): boolean {
    let isNew = false;
    if (!location) {
      location = new Location;
      isNew = true;
    }

    try {
      const dialogRef = this.dialog.open(UnitUpdateComponent, {
        width: '70%',
        height: '90%',
        panelClass: 'dialog-scrollable'
      });
      dialogRef.componentInstance.trackMaintEntity = location;
      dialogRef.componentInstance.isNew = isNew;
      dialogRef.componentInstance.isDelete = isDelete;
      dialogRef.componentInstance.fromTrackMaint = true;

      dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
        // Only search again if closed with object.
        if (result) {
          this.snackBar.open('Unit Update Complete', 'Close', { duration: 5000 });
        } else {
          this.snackBar.open('Unit Update Incomplete', 'Close', { duration: 5000 });
        }
      });

    } catch (e) {
      throw e
    }
    return false;
  }

  onUnitOtherNameOptionsScroll() {
    this.nextOtherNamesPage$.next();
  }

  onUnitOptionsScroll() {
    this.nextUnitPage$.next();
  }

  displayUnitOtherName(unitOtherName?: UnitOtherName): string | undefined {
    return unitOtherName ? unitOtherName.otherName : undefined;
  }

  displayUnit(unit?: Unit): string | undefined {
    return unit ? unit.fullName() : undefined;
  }

  searchUnitOtherNames(nameFilter: string, filters: any, page: number): Observable<UnitOtherName[]> {
    const offset = page > 0 ? (page - 1) * this.unitsPerPage : 0;

    return this.unitOtherNameService.findOtherNames(nameFilter, filters, this.unitsPerPage, offset);
  }

  searchUnits(nameFilter: any, filters: any, page: number): Observable<Unit[]> {
    const offset = page > 0 ? (page - 1) * this.unitsPerPage : 0;

    return this.unitService.findUnits(nameFilter, filters, this.unitsPerPage, offset);
  }

  async clearForm() {
    //!do something
  }

  handleUnitChange(event) {
    const unit = event.option.value;
    this.entry.unit = unit;
    this.entry.name = unit.fullName();
    // clear out the activity category, activity, and op area from the previous unit because we're assigning this record to a new unit
    this.entry.activityCategoryId = null;
    this.trigger.activityCategoryId = this.entry.activity = null;
    this.entry.confidenceCode = null;
    this.trigger.confidenceCode = this.entry.confidenceCode = null;
    this.trigger.opAreaId = this.entry.opAreaId = null;
    this.setInPort = false;
    this.trigger = Object.assign({}, this.trigger);

    this.setUnderway = unit.isUnderway === true ? true : false;

    // assign activity category, activity, and op area from previous nob record if it exists
    if (unit.nobReport) {
      // this.entry.activity = unit.nobReport.activity;
      // this.trigger.activityCategoryId = this.entry.activityCategoryId = unit.nobReport.activity_category_id;
      this.trigger.opAreaId = this.entry.opAreaId = unit.nobReport.op_area_id;
      
      // force the trigger, new references
      this.trigger = Object.assign({}, this.trigger);
    }

    // pare down the list
    if (unit.country) {
      this.opAreaOptions = this.allOpAreaOptions.filter(x => x.remarks == (unit as Unit).country.name)
    } else {
      // what else to do if no Country? the UI error will have blank...
      this.opAreaOptions = [];
    }
    this.unit = event.option.value
    // Force the editForm's control value to the unit, in case user pasted the name and option is selected
    // automatically
    this.editForm.controls.unit.setValue(this.unit);

  }
}
