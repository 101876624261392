<mat-card class="form-card">
  <div class="grid-container">
    <div fxLayout fxLayoutAlign="center center" class="simple-grid-title">
      <span class="grid-title">
        {{initService.getConfig().titlePrefix}} FDT-M Parser Queue ({{ itemCount | i18nPlural:
        pluralMapping }})
      </span>
    </div>

    <div fxLayout fxLayoutAlign="center center">
      <button mat-mini-fab color="primary" (click)="loadParserQueueEntries(true)"
        matTooltip="Refresh Parser Queue Entries">
        <mat-icon>refresh</mat-icon>
      </button>
      <mat-form-field *ngIf="jfmccs.length" class="groupSelect">
        <mat-select placeholder="JFMCC View" (selectionChange)='changeJfmccView($event)' [(value)]="selectedJfmcc">
          <mat-option [value]="'All'">All</mat-option>
          <mat-option *ngFor="let option of jfmccs" [value]="option.id">
            {{option.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="groupSelect">
        <mat-select [(ngModel)]="grouping" placeholder="Group By" (selectionChange)='changeGrouping($event, grouping)'>
          <mat-option *ngFor="let option of groupingList" [value]="option">
            {{option.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field fxFlex="30%" class="groupSelect">
        <input #filterInput matInput type="text" [(ngModel)]="filterValue" (ngModelChange)="applyFilter($event)"
          placeholder="Filter by Unit Name (class type pennant)">
        <span class="hyper" (click)="clearFilter()" matSuffix>X</span>
      </mat-form-field>
    </div>

    <div class="mat-elevation-z8">
      <div class="table-actions">
        <div class="collapsible-actions" *ngIf="hasMultipleGroups()">
          <button mat-raised-button *ngIf="hasAnyExpandedGroups()" (click)="changeAllGroups(false)">
            <mat-icon>unfold_less</mat-icon> Collapse All
          </button>
          <button mat-raised-button *ngIf="!hasAnyExpandedGroups()" (click)="changeAllGroups(true)">
            <mat-icon>unfold_more</mat-icon> Expand All
          </button>
        </div>

        <div class="bulk-actions" *ngIf="dataSource?.data?.length && selection.selected.length > 1">
          <button mat-raised-button (click)="bulkDiscard()">Bulk Discard</button>
        </div>
      </div>

      <mat-paginator [pageSizeOptions]="[10, 50, 100, 500, 1000]" [pageSize]="100" [length]="itemCount"
        (page)="onPaginateChange($event)" showFirstLastButtons></mat-paginator>

      <cdk-virtual-scroll-viewport tvsItemSize="pageSize" headerHeight="32">
        <mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" multiTemplateDataRows>
          <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef>
              Select
              <mat-checkbox (change)="$event ? toggleSelectAll() : null"
                [checked]="selection.hasValue() && isAllSelected()" [disabled]="!hasAnyExpandedGroups()"
                [indeterminate]="selection.hasValue() && !isAllSelected()">
              </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <mat-checkbox [disabled]="!canEdit(element)" (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(element) : null" [checked]="selection.isSelected(element)">
              </mat-checkbox>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="classification">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Classification</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.classification}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <img [src]="utilities.getFlag(element.unit)" height="16" class="mr-5">
              {{element.name}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="geo">
            <mat-header-cell *matHeaderCellDef>Geo</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.geo}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="locTimeTs">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Time</mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{element.locTimeTs | date: 'ddHHmm\'Z\' MMM YYYY':'UTC'}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="latitude">
            <mat-header-cell *matHeaderCellDef>Latitude</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.latitude}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="longitude">
            <mat-header-cell *matHeaderCellDef>Longitude</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.longitude}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="elnot">
            <mat-header-cell *matHeaderCellDef>ELNOT</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.elint? element.elint.el_not : null}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="points">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Points</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.points}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="originator">
            <mat-header-cell *matHeaderCellDef>Originator</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.originator}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="source">
            <mat-header-cell *matHeaderCellDef>Source</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.source}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button mat-button title="Open Parser Queue Entry" (click)="$event.stopPropagation()"
                (click)="open(element)">
                <mat-icon>launch</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="groupHeader">
            <mat-cell class="hyper" matTooltip="Click to show/hide group items" colspan="999"
              *matCellDef="let group; grouping">
              <div class="groupLabel">
                {{grouping.name}}: {{group.name}}
              </div>
              <div class="groupIcon">
                <mat-icon *ngIf="group.expanded">expand_more</mat-icon>
                <mat-icon *ngIf="!group.expanded">expand_less</mat-icon>
              </div>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" style="width:100%">
      
              <div class="example-element-detail" 
                [@detailExpand]="element.isExpanded ? 'expanded' : 'collapsed'">
                <!-- [@detailExpand]="parser.isExpanded ? 'expanded' : 'collapsed'"> -->
                <mat-table class="Xdetail-table" [dataSource]="element?.dataSource" matSort >
                  <ng-container matColumnDef="pick">
                    <mat-header-cell *matHeaderCellDef>
                     Select
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="checkbox">
                      <mat-checkbox class="Xwhite" (change)="trackSelected(element, $event)"></mat-checkbox>
                      <!-- <mat-radio-group name="newPrimarySelection" class="item-radio-group">
                        <mat-radio-button>Import</mat-radio-button>
                        <mat-radio-button>Discard</mat-radio-button>
                      </mat-radio-group> -->
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="type">
                    <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.type}}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="decimal">
                    <mat-header-cell *matHeaderCellDef>decimal</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.decimal}}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="date1">
                    <mat-header-cell *matHeaderCellDef>MM YY</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.date1}}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="ll">
                    <mat-header-cell *matHeaderCellDef>Location</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.lat}} {{element.lon}}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="source">
                    <mat-header-cell *matHeaderCellDef>Comm</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.source}}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="track">
                    <mat-header-cell *matHeaderCellDef>track</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.track}}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="track2">
                    <mat-header-cell *matHeaderCellDef>track2</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.track2}}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="track3">
                    <mat-header-cell *matHeaderCellDef>track3</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.track3}}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="source2">
                    <mat-header-cell *matHeaderCellDef>source2</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.source2}}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="source3">
                    <mat-header-cell *matHeaderCellDef>Source</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.source3}}</mat-cell>
                  </ng-container>
                  <mat-row class="Xnob-row" *matRowDef="let row; columns: trackColumns;"></mat-row>
                  <mat-header-row class="Xnob-header-row" *matHeaderRowDef="trackColumns; sticky: true"></mat-header-row>
                </mat-table>
  
              </div>
      
            </td>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row table-row hyper"
            [class.example-expanded-row]="element.isExpanded"
            (click)="getRawMessage(element)">
            
          </mat-row>
          <!-- <mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup" (click)="toggleGroup(row)" class="groupHeader"></mat-row> -->
          <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></mat-row>
        </mat-table>
      </cdk-virtual-scroll-viewport>
    </div>
  </div>
</mat-card>







<!-- <mat-card class="form-card">
    <div class="grid-container">
      <div fxLayout fxLayoutAlign="center center" class="simple-grid-title">
        <span class="grid-title">
          {{initService.getConfig().titlePrefix}} FDT-M Parser Queue B ({{ itemCount | i18nPlural:
          pluralMapping }})
        </span>
      </div>
      <div class="mat-elevation-z8">
        <div class="table-actions">
          <div class="collapsible-actions" *ngIf="hasMultipleGroups()">
            <button mat-raised-button *ngIf="hasAnyExpandedGroups()" (click)="changeAllGroups(false)">
              <mat-icon>unfold_less</mat-icon> Collapse All
            </button>
            <button mat-raised-button *ngIf="!hasAnyExpandedGroups()" (click)="changeAllGroups(true)">
              <mat-icon>unfold_more</mat-icon> Expand All
            </button>
          </div>
          <button mat-mini-fab color="primary" (click)="loadParserQueueEntries(true)"
          matTooltip="Refresh Parser Queue Entries">
          <mat-icon>refresh</mat-icon>
        </button>
        <mat-form-field *ngIf="jfmccs.length" class="groupSelect">
          <mat-select placeholder="JFMCC View" (selectionChange)='changeJfmccView($event)' [(value)]="selectedJfmcc">
            <mat-option [value]="'All'">All</mat-option>
            <mat-option *ngFor="let option of jfmccs" [value]="option.id">
              {{option.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="groupSelect">
          <mat-select [(ngModel)]="grouping" placeholder="Group By" (selectionChange)='changeGrouping($event, grouping)'>
            <mat-option *ngFor="let option of groupingList" [value]="option">
              {{option.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex="30%" class="groupSelect">
          <input #filterInput matInput type="text" [(ngModel)]="filterValue" (ngModelChange)="applyFilter($event)"
            placeholder="Filter by Unit Name (class type pennant)">
          <span class="hyper" (click)="clearFilter()" matSuffix>X</span>
        </mat-form-field>
  
          <div class="bulk-actions" *ngIf="dataSource?.data?.length && selection.selected.length > 1">
            <button mat-raised-button (click)="bulkDiscard()">Bulk Discard</button>
          </div>
        </div>
  
        <mat-paginator [pageSizeOptions]="[10, 50, 100, 500, 1000]" [pageSize]="100" [length]="itemCount"
          (page)="onPaginateChange($event)" showFirstLastButtons></mat-paginator>
      </div>
      <div fxLayout fxLayoutAlign="center center">
      </div>
      <mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="select">
          <mat-header-cell *matHeaderCellDef>
            Select
            <mat-checkbox (change)="$event ? toggleSelectAll() : null"
              [checked]="selection.hasValue() && isAllSelected()" [disabled]="!hasAnyExpandedGroups()"
              [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-checkbox [disabled]="!canEdit(element)" (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(element) : null" [checked]="selection.isSelected(element)">
            </mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="accordion">
          <mat-expansion-panel *matCellDef="let parser" (opened)="getRawMessage(parser)" >
            <mat-expansion-panel-header>
                <mat-panel-title class="bold"><img [src]="utilities.getFlag(parser?.unit)" height="16"><span class="ml-5">{{parser?.unit?.fullName()}}</span></mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
              <div>{{parser.rawMessage?.message}}</div>
              <div>{{parser.rawMessage?.ctc}}</div>
              <div *ngIf="parser?.dataSource">
              <mat-table [dataSource]="parser?.dataSource" matSort>
                <ng-container matColumnDef="select">
                  <mat-header-cell *matHeaderCellDef>
                    Select
                    <mat-checkbox></mat-checkbox>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <mat-checkbox  (click)="$event.stopPropagation()"></mat-checkbox>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="type">
                  <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
                  <mat-cell *matCellDef="let element">{{element.type}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="decimal">
                  <mat-header-cell *matHeaderCellDef>decimal</mat-header-cell>
                  <mat-cell *matCellDef="let element">{{element.decimal}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="date1">
                  <mat-header-cell *matHeaderCellDef>date1</mat-header-cell>
                  <mat-cell *matCellDef="let element">{{element.date1}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="ll">
                  <mat-header-cell *matHeaderCellDef>ll</mat-header-cell>
                  <mat-cell *matCellDef="let element">{{element.ll}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="source">
                  <mat-header-cell *matHeaderCellDef>source</mat-header-cell>
                  <mat-cell *matCellDef="let element">{{element.source}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="track">
                  <mat-header-cell *matHeaderCellDef>track</mat-header-cell>
                  <mat-cell *matCellDef="let element">{{element.track}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="track2">
                  <mat-header-cell *matHeaderCellDef>track2</mat-header-cell>
                  <mat-cell *matCellDef="let element">{{element.track2}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="track3">
                  <mat-header-cell *matHeaderCellDef>track3</mat-header-cell>
                  <mat-cell *matCellDef="let element">{{element.track3}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="source2">
                  <mat-header-cell *matHeaderCellDef>source2</mat-header-cell>
                  <mat-cell *matCellDef="let element">{{element.source2}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="source3">
                  <mat-header-cell *matHeaderCellDef>source3</mat-header-cell>
                  <mat-cell *matCellDef="let element">{{element.source3}}</mat-cell>
                </ng-container>
                <mat-row class="table-row" *matRowDef="let row; columns: trackColumns;"></mat-row>
                <mat-header-row *matHeaderRowDef="trackColumns; sticky: true"></mat-header-row>
              </mat-table>
              </div>
            </ng-template>
          </mat-expansion-panel>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row class="table-row" *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
      <mat-accordion [togglePosition]="'before'" multi="true">
        <mat-expansion-panel *ngFor="let parser of allParserQueueEntries" (opened)="getRawMessage(parser)" >
            <mat-expansion-panel-header>
                <mat-panel-title class="bold"><img [src]="utilities.getFlag(parser?.unit)" height="16"><span class="ml-5">{{parser?.unit?.fullName()}}</span></mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
              <div>{{parser.rawMessage?.message}}</div>
              <div>{{parser.rawMessage?.ctc}}</div>
              <div *ngIf="parser?.dataSource">
              <mat-table [dataSource]="parser?.dataSource" matSort>
                <ng-container matColumnDef="select">
                  <mat-header-cell *matHeaderCellDef>
                    Select
                    <mat-checkbox></mat-checkbox>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <mat-checkbox  (click)="$event.stopPropagation()"></mat-checkbox>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="type">
                  <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
                  <mat-cell *matCellDef="let element">{{element.type}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="decimal">
                  <mat-header-cell *matHeaderCellDef>decimal</mat-header-cell>
                  <mat-cell *matCellDef="let element">{{element.decimal}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="date1">
                  <mat-header-cell *matHeaderCellDef>date1</mat-header-cell>
                  <mat-cell *matCellDef="let element">{{element.date1}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="ll">
                  <mat-header-cell *matHeaderCellDef>ll</mat-header-cell>
                  <mat-cell *matCellDef="let element">{{element.ll}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="source">
                  <mat-header-cell *matHeaderCellDef>source</mat-header-cell>
                  <mat-cell *matCellDef="let element">{{element.source}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="track">
                  <mat-header-cell *matHeaderCellDef>track</mat-header-cell>
                  <mat-cell *matCellDef="let element">{{element.track}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="track2">
                  <mat-header-cell *matHeaderCellDef>track2</mat-header-cell>
                  <mat-cell *matCellDef="let element">{{element.track2}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="track3">
                  <mat-header-cell *matHeaderCellDef>track3</mat-header-cell>
                  <mat-cell *matCellDef="let element">{{element.track3}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="source2">
                  <mat-header-cell *matHeaderCellDef>source2</mat-header-cell>
                  <mat-cell *matCellDef="let element">{{element.source2}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="source3">
                  <mat-header-cell *matHeaderCellDef>source3</mat-header-cell>
                  <mat-cell *matCellDef="let element">{{element.source3}}</mat-cell>
                </ng-container>
                <mat-row class="table-row" *matRowDef="let row; columns: trackColumns;"></mat-row>
                <mat-header-row *matHeaderRowDef="trackColumns; sticky: true"></mat-header-row>
              </mat-table>
              </div>
            </ng-template>
        </mat-expansion-panel>
    </mat-accordion>
    </div>
  </mat-card> -->