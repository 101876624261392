<app-classification-bar class="top-modal-classification-bar"></app-classification-bar>
<app-fdtm-modal-header [headerString]="headerString"></app-fdtm-modal-header>
<div mat-dialog-content>
    <form [formGroup]="editForm" #dtForm>
        <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px"
            fxLayoutGap.xs="0">
            <div fxFlex="30%" fxFlex.md="40%" fxFlex.lg="30%">
                <mat-form-field class="groupSelect">
                    <mat-select formControlName="category" [(ngModel)]="selectedCategory.classMarkingCategoryId"
                        required [disabled]="!this.isNew" placeholder="Marking Category"
                        (selectionChange)="onCategoryChange($event)">
                        <mat-option *ngFor="let option of categoryOptions" [value]="option.classMarkingCategoryId">
                            {{option.classMarkingName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px"
            fxLayoutGap.xs="0">
            <div fxFlex="50%">
                <h5>Primary Marking:</h5>
            </div>
            <div fxFlex="=50%">
                <h5>Related Marking: </h5>
            </div>
        </div>
        <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px"
            fxLayoutGap.xs="0">
            <div fxFlex="50%">
                <ng-container *ngIf="selectedCategory.classMarkingName === 'Classifications'; else primary">
                    <mat-radio-group class="bordered" fxLayout="column" formControlName="primaryMarkingId"
                        [disabled]="!this.isNew" placeholder="Primary Marking">
                        <mat-radio-button validation="required" class="classification-option smallRadio"
                            *ngFor="let option of primaryMarkingOptions" [value]="option.classificationId">
                            {{option.classificationName}}
                        </mat-radio-button>
                    </mat-radio-group>
                </ng-container>
                <ng-template #primary>
                    <ng-container *ngIf="selectedCategory.classMarkingName === 'SCI Controls'; else primary2">
                        <mat-radio-group class="bordered" fxLayout="column" formControlName="primaryMarkingId"
                            [disabled]="!this.isNew" placeholder="Primary Marking">
                            <mat-radio-button validation="required" class="classification-option smallRadio"
                                *ngFor="let option of primaryMarkingOptions" [value]="option.sciControlId">
                                {{option.controlMark}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </ng-container>
                </ng-template>
                <ng-template #primary2>
                    <ng-container *ngIf="selectedCategory.classMarkingName === 'FGIs'; else primary3">
                        <mat-radio-group class="bordered" fxLayout="column" formControlName="primaryMarkingId"
                            [disabled]="!this.isNew" placeholder="Primary Marking">
                            <mat-radio-button validation="required" class="classification-option smallRadio"
                                *ngFor="let option of primaryMarkingOptions" [value]="option.fgiId">
                                {{option.name}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </ng-container>
                </ng-template>
                <ng-template #primary3>
                    <ng-container *ngIf="selectedCategory.classMarkingName === 'Other Controls'; else primary4">
                        <mat-radio-group class="bordered" fxLayout="column" formControlName="primaryMarkingId"
                            [disabled]="!this.isNew" placeholder="Primary Marking">
                            <mat-radio-button validation="required" class="classification-option smallRadio"
                                *ngFor="let option of primaryMarkingOptions" [value]="option.controlId">
                                {{option.description}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </ng-container>
                </ng-template>
                <ng-template #primary4>
                    <ng-container *ngIf="selectedCategory.classMarkingName === 'Releasability'">
                        <mat-radio-group class="bordered" fxLayout="column" formControlName="primaryMarkingId"
                            [disabled]="!this.isNew" placeholder="Primary Marking">
                            <mat-radio-button validation="required" class="classification-option smallRadio"
                                *ngFor="let option of primaryMarkingOptions" [value]="option.releasibilityId">
                                {{option.releasabilityName}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </ng-container>
                </ng-template>
            </div>
            <div fxFlex="50%">
                <ng-container *ngIf="selectedCategory.classMarkingName === 'Classifications'; else next">
                    <mat-selection-list class="bordered" fxLayout="column" formControlName="relatedMarkings">
                        <mat-list-option class="shrink" checkboxPosition="before"
                            *ngFor="let option of relatedMarkingOptions" [value]="option.classificationId">
                            {{option.classificationName}}
                        </mat-list-option>
                    </mat-selection-list>
                </ng-container>
                <ng-template #next>
                    <ng-container *ngIf="selectedCategory.classMarkingName === 'SCI Controls'; else next2">
                        <mat-selection-list class="bordered" fxLayout="column" formControlName="relatedMarkings">
                            <mat-list-option class="shrink" checkboxPosition="before"
                                *ngFor="let option of relatedMarkingOptions" [value]="option.sciControlId">
                                {{option.controlMark}}
                            </mat-list-option>
                        </mat-selection-list>
                    </ng-container>
                </ng-template>
                <ng-template #next2>
                    <ng-container *ngIf="selectedCategory.classMarkingName === 'FGIs'; else next3">
                        <mat-selection-list class="bordered" fxLayout="column" formControlName="relatedMarkings">
                            <mat-list-option class="shrink" checkboxPosition="before"
                                *ngFor="let option of relatedMarkingOptions" [value]="option.fgiId">
                                {{option.name}}
                            </mat-list-option>
                        </mat-selection-list>
                    </ng-container>
                </ng-template>
                <ng-template #next3>
                    <ng-container *ngIf="selectedCategory.classMarkingName === 'Other Controls'; else next4">
                        <mat-selection-list class="bordered" fxLayout="column" formControlName="relatedMarkings">
                            <mat-list-option class="shrink" checkboxPosition="before"
                                *ngFor="let option of relatedMarkingOptions" [value]="option.controlId">
                                {{option.description}}
                            </mat-list-option>
                        </mat-selection-list>
                    </ng-container>
                </ng-template>
                <ng-template #next4>
                    <ng-container *ngIf="selectedCategory.classMarkingName === 'Releasability'">
                        <mat-selection-list class="bordered" fxLayout="column" formControlName="relatedMarkings">
                            <mat-list-option class="shrink" checkboxPosition="before"
                                *ngFor="let option of relatedMarkingOptions" [value]="option.releasibilityId">
                                {{option.releasabilityName}}
                            </mat-list-option>
                        </mat-selection-list>
                    </ng-container>
                </ng-template>

            </div>
        </div>
        <br>
        <div *ngIf="!user.isSystemAdmin()">
            <span class="red">You are not a system admin. You will not be able to create or update Classification Marking Relationships.</span>
        </div>
        <div mat-dialog-actions>
            <button [disabled]="!editForm?.valid || !user.isSystemAdmin()" mat-raised-button color="primary" (click)="submit()">
                Submit
            </button>
            <button mat-raised-button type="reset" (click)="cancel()">
                Cancel
            </button>
        </div>
    </form>
</div>
<app-classification-bar class="bottom-modal-classification-bar"></app-classification-bar>