<app-classification-bar class="top-modal-classification-bar"></app-classification-bar>
<app-fdtm-modal-header [headerString]="headerString"></app-fdtm-modal-header>
<div mat-dialog-content>
    <form [formGroup]="editForm">
        <div fxLayout="row" fxLayoutAlign="start center">
            <span matPrefix>
                <mat-select class="country-filter" [(value)]="filterByCountry"
                    (selectionChange)="countryFilterChange($event)">
                    <mat-select-trigger *ngIf="filterByCountry.name"
                        matTooltip="Find Units for {{filterByCountry.name}}">
                        <img [src]="getFlag(filterByCountry)" height="16">&nbsp;
                    </mat-select-trigger>
                    <mat-select-trigger *ngIf="!filterByCountry.name" matTooltip="Select a Country to Filter Search">
                        <mat-icon style="vertical-align:middle;">flag</mat-icon>
                    </mat-select-trigger>
                    <mat-option [value]="noCountry">
                        <mat-icon style="vertical-align:middle;">flag</mat-icon> (clear)
                    </mat-option>
                    <mat-option *ngFor="let country of countries" [value]="country">
                        <img [src]="getFlag(country)" height="16">&nbsp;{{country.name}}
                    </mat-option>
                </mat-select>
            </span>
            <mat-form-field class="unit-input-field">
                <span matPrefix *ngIf="utilities.getFlag(editForm.controls?.unit?.value)"><img
                        style="vertical-align:middle;" [src]="utilities.getFlag(editForm.controls?.unit?.value)"
                        height="20" />&nbsp;</span>
                <input matInput type="text" formControlName="unit" placeholder="Unit" required
                    [matAutocomplete]="unitAutocomplete" tabindex="1">
            </mat-form-field>
            <mat-autocomplete #unitAutocomplete="matAutocomplete" [displayWith]="displayUnit"
                (optionsScroll)="onUnitOptionsScroll()">
                <mat-option *ngFor="let option of filteredUnits$ | async" [value]="option">
                    <span matTooltip="{{option.country?.name}}">
                        <img style="vertical-align:middle;" [src]="utilities.getFlag(option)" height="20" />&nbsp;
                    </span>
                    <span matTooltip="{{option.fullName()}}">{{option.fullName()}}</span>
                </mat-option>
                <mat-option *ngIf="isLoading" class="is-loading">
                    <mat-spinner diameter="30"></mat-spinner>
                </mat-option>
            </mat-autocomplete>
            <mat-form-field class="spacer" fxFlex="40%">
                <mat-select placeholder="Classification" formControlName="classification" required>
                    <mat-option *ngFor="let classification of classifications" [value]="classification">
                        {{classification.classificationName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
            <mat-form-field fxFlex="90%">
                <textarea matInput formControlName="remarks" rows="4" maxlength="1000" placeholder="Remarks" required>
                    </textarea>
            </mat-form-field>
        </div>
        <div *ngIf="!isDelete" fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
            <div class="mat-select-placeholder">File (optional)</div>
            <div>
                <input #fileInput type="file" (change)="onFileChanged($event)" (cancel)="cancelEvent()" />
            </div>
        </div>
        <br />
        <div mat-dialog-actions>
            <button *ngIf="!isDelete" mat-raised-button color="primary"
                [disabled]="!editForm.valid || !editForm.controls?.unit?.value?.unitId" (click)="add()">Add
            </button>
            <button *ngIf="isDelete" mat-raised-button color="warn" (click)="delete(cpage)">Delete</button>
            <button mat-raised-button (click)="cancel()">Cancel</button>
        </div>

    </form>
</div>
<app-classification-bar class="bottom-modal-classification-bar"></app-classification-bar>
