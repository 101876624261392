import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AdminComponent } from './admin.component';
import { AdminTasksComponent } from './admin-tasks/admin-tasks.component';
//import { EditListsComponent } from './edit-lists/edit-lists.component';
import { UnitsComponent } from '@fom-module/units/units.component';
import { UserComponent } from '@fom-module/user/user.component';
import { NotifierComponent } from '@fom-module/notifier/notifier.component';
import { CommandsComponent } from '@fom-module/commands/commands.component';
import { GeoRefsComponent } from '@fom-module/geo-refs/geo-refs.component';
import { AuthorizationGuardService } from '@services/data-services/authorization-guard.service';
import { SlideMaintenanceComponent } from '@fom-module/slide-maintenance/slide-maintenance.component';
import { LookupMaintenanceComponent } from '@fom-module/lookup-maintenance/lookup-maintenance.component';
import { OwnerTypeComponent } from '@fom-module/owner-type/owner-type.component';
import { PicklistTypeComponent } from '@fom-module/picklist-type/picklist-type.component';
import { CommandGraphComponent } from '@fom-module/command-graph/command-graph.component';
import { DataTagsComponent } from '@fom-module/data-tags/data-tags.component';
import { ServiceDashboardComponent } from '@fom-module/service-dashboard/service-dashboard.component';
import { ParametersComponent } from '@fom-module/parameters/parameters.component';
import { PocComponent } from '@fom-module/point-of-contacts/point-of-contact.component';
import { AccountRequestComponent } from '@fom-module/account-request/account-request.component'
import { ClassificationMaintenanceComponent } from '@fom-module/classification-maintenance/classification-maintenance.component';
import { TrackMaintenanceComponent } from '@fom-module/track-maintenance/track-maintenance.component';
import { ConnectionsComponent } from '@fom-module/connections/connections.component';
import { EditMessageConfigModalComponent } from '@fom-module/edit-modals/edit-message-config-modal/edit-message-config-modal.component';
import { PreferencesComponent } from './admin-screens/preferences/preferences.component';
import { FDOMapComponent } from '@fom-module/fdo-map/fdo-map.component';
import { ClassificationMarkingRelationship } from '@fom-module/classification-marking-relationship/classification-marking-relationship.component';
import { ClassifyMenuComponent } from './classify-menu/classify-menu.component';
import { ServiceMenuComponent } from './service-menu/service-menu.component';
import { AppSystemAdminComponent } from './app-system-admin-menu/app-system-admin-menu.component';

const adminRoutes: Routes = [
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthorizationGuardService],
    children: [
      {
        path: 'tasks',
        component: AdminTasksComponent, canActivate: [AuthorizationGuardService],
        children: [
          {
            path: 'units',
            component: UnitsComponent, canActivate: [AuthorizationGuardService]
          },
          {
            path: 'users',
            component: UserComponent, canActivate: [AuthorizationGuardService]
          },
          {
            path: 'slide-maintenance',
            component: SlideMaintenanceComponent, canActivate: [AuthorizationGuardService]
          },
          {
            path: 'point-of-contacts',
            component: PocComponent, canActivate: [AuthorizationGuardService]
          },
          {
            path: 'account-requests',
            component: AccountRequestComponent, canActivate: [AuthorizationGuardService]
          },
          {
            path: 'lookup-maintenance',
            component: LookupMaintenanceComponent, canActivate: [AuthorizationGuardService]
          },
          {
            path: 'command-graph',
            component: CommandGraphComponent, canActivate: [AuthorizationGuardService]
          },
          {
            path: 'commands',
            component: CommandsComponent, canActivate: [AuthorizationGuardService]
          },
          {
            path: 'geo-refs',
            component: GeoRefsComponent, canActivate: [AuthorizationGuardService]
          },
          {
            path: 'track-maintenance',
            component: TrackMaintenanceComponent,
            canActivate: [AuthorizationGuardService]
          },
          {
            path: 'message-configs',
            component: EditMessageConfigModalComponent,
            canActivate: [AuthorizationGuardService]
          }
        ]
      },
      {
        path: 'classifymenu',
        component: ClassifyMenuComponent, canActivate: [AuthorizationGuardService],
        children: [
          {
            path: 'classification-markings',
            component: ClassificationMaintenanceComponent, canActivate: [AuthorizationGuardService]
          },
          {
            path: 'fdo-map',
            component: FDOMapComponent, canActivate: [AuthorizationGuardService]
          },
          {
            path: 'classification-marking-relationship',
            component: ClassificationMarkingRelationship, canActivate: [AuthorizationGuardService]
          },
          {
            path: 'data-tags',
            component: DataTagsComponent, canActivate: [AuthorizationGuardService]
          }
        ]
      },
      {
        path: 'servicemenu',
        component: ServiceMenuComponent, canActivate: [AuthorizationGuardService],
        children: [
          {
            path: 'service-dashboard',
            component: ServiceDashboardComponent, canActivate: [AuthorizationGuardService]
          },
          {
            path: 'system-connections',
            component: ConnectionsComponent, canActivate: [AuthorizationGuardService]
          },
          {
            path: 'notifier',
            component: NotifierComponent, canActivate: [AuthorizationGuardService]
          },
        ]
      },
      {
        path: 'appsystemadminmenu',
        component: AppSystemAdminComponent, canActivate: [AuthorizationGuardService],
        children: [
          
          {
            path: 'owner-type',
            component: OwnerTypeComponent, canActivate: [AuthorizationGuardService]
          },
          {
            path: 'picklist-type',
            component: PicklistTypeComponent, canActivate: [AuthorizationGuardService]
          },
          {
            path: 'parameters',
            component: ParametersComponent, canActivate: [AuthorizationGuardService]
          },
          {
            path: 'preferences',
            component: PreferencesComponent, canActivate: [AuthorizationGuardService]
          },
        ]
      }
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(adminRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class AdminRoutingModule { }
