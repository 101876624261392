import { Component, OnInit} from '@angular/core';
import { User } from '@models/user.model';
import { JsonConverter } from '@services/global/json-converter';
import { Router, NavigationEnd } from '@angular/router';
import { CurrentUserService } from '@services/current-user-service';
import { AccountRequestService } from '@services/data-services/account-request.service';

@Component({
  selector: 'app-classifymenu-tasks',
  templateUrl: './classify-menu.component.html',
  styleUrls: ['./classify-menu.component.css']
})
export class ClassifyMenuComponent implements OnInit {
  private jsonConvert: JsonConverter;
  user: User;
  selection: string;
  badgeCount: number = 0;

  constructor(private route: Router, private currentUserService: CurrentUserService, private accReqService: AccountRequestService) {
    this.jsonConvert = new JsonConverter();
    this.route.events.subscribe(event => {
      if (event instanceof NavigationEnd) {   // grab the first part
        const parts = event.url.split('/');
        this.selection = parts.length >= 3 ? parts[3] : '';
      }
    });
  }

  ngOnInit() {
    this.user = this.currentUserService.getCurrentUser();
  }

  ngAfterContentInit(){
    this.updateBadgeCount()
  }

  updateBadgeCount(){
    this.accReqService.getAllAccountRequestsCount().subscribe( count => {
      this.badgeCount = count
    })
  }

  onActivate(componentReference) {
    if(componentReference.numberOfRequests){
      componentReference.numberOfRequests.subscribe((data) => {
        this.badgeCount = data
      })
    }
  }
}
