import { FormBuilder } from "@angular/forms";
import { EditModalBaseComponent } from "@fom-module/base-classes/edit-modal-base.component";
import { VesselTypeService } from "@services/data-services/vessel-type.service";
import { MessageBusService } from "@services/global/message-bus/messaging-bus.service";
import { Component, OnInit } from "@angular/core";
import { LookUpService } from "@services/data-services/lookup.service";
import { Command } from "@models/command.model";
import { MatDialogRef } from "@angular/material/dialog";
import { take } from "rxjs/operators";
import { UtilityService } from "@services/utility.service";
import { CommandHierarchyService } from "@services/data-services/command-hierarchy.service";
import { User } from "@models/user.model";
import { CurrentUserService } from "@services/current-user-service";
import { CommandHierarchy } from "@models/command-hierarchy.model";
import { ScanTypeService } from "@services/data-services/scan-type.service";

@Component({
  selector: "app-edit-scan-type-modal",
  templateUrl: "./edit-scan-type-modal.component.html",
  styleUrls: ["./edit-scan-type-modal.component.css"],
})
export class EditScanTypeModalComponent
  extends EditModalBaseComponent
  implements OnInit
{
  commandOptions: Command[];
  user: User;
  commandHierarchy: CommandHierarchy;

  flag: string = "";

  constructor(
    public scanTypeService: ScanTypeService,
    public lookUpService: LookUpService,
    public utilities: UtilityService,
    public formBuilder: FormBuilder,
    private commandHierarchyService: CommandHierarchyService,
    public dialogRef: MatDialogRef<EditScanTypeModalComponent>,
    public messageBusService: MessageBusService,
    private currentUserService: CurrentUserService
  ) {
    super(scanTypeService, dialogRef);
  }

  ngOnInit() {
    this.headerString = this.isNew
      ? "Add Scan Type"
      : this.isDelete
      ? "Delete Scan Type"
      : "Edit Scan Type";
    this.user = this.currentUserService.getCurrentUser();
    this.editForm = this.formBuilder.group({
      scan_type_code: [
        { value: this.entity.scan_type_code, disabled: this.isDelete },
      ],
      scan_type_description: [
        { value: this.entity.scan_type_description, disabled: this.isDelete },
      ],
    });
  }
}
