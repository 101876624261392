import { LookUpService } from '@services/data-services/lookup.service';
import { Component, OnInit } from '@angular/core';
import { Unit } from '@models/unit.model';
import { User } from '@models/user.model';
import { Command } from '@models/command.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { count, take } from 'rxjs/operators';
import { UtilityService } from '@services/utility.service';
import { CurrentUserService } from '@services/current-user-service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { UnitOtherName } from '@models/unit-other-name.model';
import { EditModalBaseComponent } from '@fom-module/base-classes/edit-modal-base.component';
import { UnitOtherNameService } from '@services/data-services/unit-other-name-service';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { CommandHierarchy } from '@models/command-hierarchy.model';
import { CommandHierarchyService } from '@services/data-services/command-hierarchy.service';
import { CommandService } from '@services/data-services/command.service';

@Component({
  selector: 'app-edit-other-name-modal',
  templateUrl: './edit-other-name-modal.component.html',
  styleUrls: ['./edit-other-name-modal.component.css']
})
export class EditOtherNameModalComponent extends EditModalBaseComponent implements OnInit {

  public unitOtherName: UnitOtherName;
  public setName: string;
  public setCommand: string;
  unit: Unit;
  unitId: string;
  isNew: boolean;
  isDelete: boolean;
  user: User
  initialUnit: any;
  commandOptions: Command[];
  commandHierarchy: CommandHierarchy;
  formCommandId = new FormControl('');
  formOtherName = new FormControl('');
  headerString: string;

  constructor(
    public unitOtherNameService: UnitOtherNameService,
    public lookUpService: LookUpService,
    // private picklistPrefService: PicklistPrefService,
    public formBuilder: FormBuilder, public utilities: UtilityService,
    public dialogRef: MatDialogRef<EditOtherNameModalComponent>,
    public currentUserService: CurrentUserService,
    public messageBusService: MessageBusService,
    public commandService: CommandService,
    public commandHierarchyService: CommandHierarchyService) {
      super(unitOtherNameService, dialogRef);
    }

  ngOnInit() {
    this.user = this.currentUserService.getCurrentUser();
    this.headerString = this.isNew ? `Add Other Name for UNIT` : `Edit Other Name for UNIT`;
    // since we're concerned about the user's JFMCC (id) let's set it here explicitly rather than complex tests
    this.user.JFMCCId = this.user.currentJFMCC ? this.user.currentJFMCC.commandId : this.user.commandId;
    // in case of cancel
    this.initialUnit = { ... this.unit };
    //
    if (this.isNew){
      this.unitOtherName = new UnitOtherName;
      this.unitOtherName.unitId = this.unitId;
    }
    this.editForm = this.formBuilder.group({
      formCommandId: [this.unitOtherName.commandId],
      formOtherName: [this.unitOtherName.otherName]
    });
    //
    Promise.resolve().then(() => {
      this.loadLookups();
    });
  }

  loadLookups() {
    // this.lookUpService.getLookupByType(Command, true).pipe(take(1)).subscribe(result => {
    this.commandService.getAll().pipe(take(1)).subscribe(result => {
      this.commandHierarchyService.getAll({ sort: 'version_num', limit: 1 }).pipe(take(1)).subscribe(json => {
        if (json && Array.isArray(json) && json.length) {
          this.commandHierarchy = json[0];

          let jfmccs;
          if (this.user.isSystemAdmin()) {
            jfmccs = this.commandHierarchy.getJFMCCs();
          } else {
            jfmccs = this.commandHierarchy.getJFMCCIds(this.user.command);
          }
          this.commandOptions = result.filter(item => {
            return jfmccs.includes(item.commandId);
          });
        } else {
          this.commandOptions = result;
        }
      });
    });
  }

  onSelectionChange(command) {
    // if (fleet?.remarks !== this.unit?.country?.name) {
    //   this.mismatchedFleet = true;
    // }
    this.unitOtherName.commandId = command.commandId;
  }

  disableCommandControl() {
    // don't allow changes to the borrowerCommand if a new unit (cannot set)
    // or if this user's command is just borrowing the Unit
    return this.isNew || (this.user && !this.user.isSystemAdmin() && this.user.JFMCCId === this.initialUnit.borrowerCommandId);
  }

  isDirty() {
    return JSON.stringify(this.unit) != JSON.stringify(this.initialUnit)
  }

  // updateEntity() {
  //   console.log(this.formOtherName)
  //   // this.unitOtherName.otherName = this.formOtherName;
  //   // for (const key in this.editForm.controls) {
  //   //   if (this.unitOtherName.hasOwnProperty(key.toString())) {
  //   //     this.unitOtherName[key.toString()] = this.editForm.controls[key]['value'];
  //   //   }
  //   // }
  // }

  submit() {
    // this.updateEntity()
    if (this.isNew) {
      // really awkward -- the API data-tag controller will get the source name 
      // and build the classification_string before it creates the record
      // but the update function merely calls standardMethods to do the updaet --
      // which means the source name and classification string must be set aheadof time....
      // update: nevermind, modifed the update endpoint in API to build it using the 
      // same code as create endpoint
      this.unitOtherNameService.create(this.unitOtherName).pipe(take(1)).subscribe(response =>  {
          this.dialogRef.close(response)
        })
      }
      else {
        this.unitOtherNameService.update(this.unitOtherName).pipe(take(1)).subscribe(response =>  {
            this.dialogRef.close(response)
        })
      }
  }

  cancel() {
    try{
      this.dialogRef.close()
    }catch(e){
      console.log(e)
    }
    
  }
}
