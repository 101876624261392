import { Injectable } from '@angular/core';
import { JsonConverter } from '@services/global/json-converter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SystemConnection } from '@models/system-connection.model';
import { ConnectionApiService } from '@services/api-services/connection-api.service';

@Injectable()
export class ConnectionsService {
  private jsonConvert: JsonConverter;

  constructor(private connectorApiService: ConnectionApiService) {
    this.jsonConvert = new JsonConverter();
  }

  public getAll(): Observable<SystemConnection[]> {
    return this.connectorApiService.getAllSystemConnections()
      .pipe(
        map((data) => {
          return this.jsonConvert.deserializeArray(data, SystemConnection);
        })
      );
  }

  public create(conn: SystemConnection): Observable<SystemConnection> {
    return this.connectorApiService.createSystemConnection(this.jsonConvert.serializeObject(conn))
      .pipe(
        map((data) => {
          return this.jsonConvert.deserializeObject(data, SystemConnection);
        })
      );
  }

  public update(connection: SystemConnection): Observable<SystemConnection> {
    return this.connectorApiService.updateSystemConnection(this.jsonConvert.serializeObject(connection))
      .pipe(
        map((data) => {
          return this.jsonConvert.deserializeObject(data, SystemConnection);
        })
      );
  }

  public delete(connection: SystemConnection): Observable<SystemConnection> {
    return this.connectorApiService.deleteSystemConnection(this.jsonConvert.serializeObject(connection))
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
}