<app-classification-bar class="top-modal-classification-bar"></app-classification-bar>
<app-fdtm-modal-header [headerString]="'Approve Account Request'"></app-fdtm-modal-header>
<div mat-dialog-content class="grid-container">
  <div id="account-request-form" class="request-account-form left">
    <h3>Requested Changes</h3>
    <!-- REQUESTED CHANGES -->
    <mat-form-field class="make-even">
      <mat-label>Username</mat-label>
      <input matInput name="username" required [(ngModel)]="accreq.user_name" matTooltip="Format: firstName.lastName">
    </mat-form-field>

    <mat-form-field class="make-even">
      <mat-label>First Name</mat-label>
      <input matInput name="firstName" required [(ngModel)]="accreq.first_name">
    </mat-form-field>

    <mat-form-field class="make-even">
      <mat-label>Last Name</mat-label>
      <input matInput name="lastName" required [(ngModel)]="accreq.last_name">
    </mat-form-field>

    <mat-form-field class="make-even">
      <mat-label>Email</mat-label>
      <input matInput name="email" required [(ngModel)]="accreq.email">
    </mat-form-field>

    <mat-form-field class="make-even">
      <mat-label>Justification</mat-label>
      <input matInput name="justification" required [(ngModel)]="accreq.justification">
    </mat-form-field>

    <mat-form-field class="make-even">
      <mat-select placeholder="Permission level" [(ngModel)]="accreq.permission.permission_description" required>
          <mat-option *ngFor="let option of permissionOptions" [value]="option.permissionDescription">{{option.permissionDescription}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="make-even">
      <mat-select placeholder="Command" [(ngModel)]="accreq.command.command_name" required>
          <mat-option *ngFor="let option of commandOptions" [value]="option.name" >{{option.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <!-- ORIGINAL USER ACCOUNT -->
  <div class="right">
    <h3>Current Account</h3>
    <mat-form-field class="make-even">
      <mat-label>Username</mat-label>
      <input matInput disabled [(ngModel)]="original.user_name">
    </mat-form-field>

      <mat-form-field class="make-even">
        <mat-label>First Name</mat-label>
        <input matInput disabled [(ngModel)]="original.first_name">
      </mat-form-field>

      <mat-form-field class="make-even">
        <mat-label>Last Name</mat-label>
        <input matInput disabled [(ngModel)]="original.last_name">
      </mat-form-field>

      <mat-form-field class="make-even">
        <mat-label>Email</mat-label>
        <input matInput disabled [(ngModel)]="original.email">
      </mat-form-field>

      <mat-form-field class="make-even">
        <mat-label>Justification</mat-label>
        <input matInput disabled [(ngModel)]="original.justification">
      </mat-form-field>

      <mat-form-field class="make-even">
        <mat-label>Permission level</mat-label>
        <input matInput disabled [(ngModel)]="original.permission.permission_description">
      </mat-form-field>

      <mat-form-field class="make-even">
        <mat-label>Command</mat-label>
        <input matInput disabled [(ngModel)]="original.command.command_name">
      </mat-form-field>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="apply()">Approve</button>
    <button mat-raised-button (click)="cancel()">Cancel</button>
</div>

<app-classification-bar class="bottom-modal-classification-bar"></app-classification-bar>