import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { User } from '@models/user.model';
import { Command } from '@models/command.model';
import { LookUpService } from '@services/data-services/lookup.service';
import { take } from 'rxjs/operators';
import { AccountRequestService } from '@services/data-services/account-request.service';
import { CommandService } from '@services/data-services/command.service';
import { PermissionService } from '@services/data-services/permission.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommandHierarchyService } from '@services/data-services/command-hierarchy.service';
import { CommandHierarchy } from '@models/command-hierarchy.model';
import { CurrentUserService } from '@services/current-user-service';


@Component({
  selector: 'edit-account-request-modal',
  templateUrl: './edit-account-request.component.html',
  styleUrls: ['./edit-account-request.component.css']
})
export class EditAccountRequestModalComponent implements OnInit {
  accreq: any;
  original: any;
  commands: Command[];
  commandOptions: Command[];
  commandHierarchy: CommandHierarchy;
  permissionOptions: any;
  currentUser: User;

  constructor(
    public dialogRef: MatDialogRef<EditAccountRequestModalComponent>,
    private lookUpService: LookUpService,
    private accountReqService: AccountRequestService,
    private commandService: CommandService,
    private permissionService: PermissionService,
    private commandHierarchyService: CommandHierarchyService,
    private currentUserService: CurrentUserService,
    private matSnack: MatSnackBar) {

  }

  ngOnInit() {
    this.findRequester(this.accreq);
    this.currentUser = this.currentUserService.getCurrentUser();

    Promise.resolve().then(() => {
      this.commandHierarchyService.getAll({ sort: 'version_num', limit: 1 }).pipe(take(1)).subscribe(result => {
        if (result.length > 0) {
          this.commandHierarchy = result[0];
        } else {
          // set hierarchy to blank list.
          this.commandHierarchy = new CommandHierarchy();
          this.commandHierarchy.treeObject = '[]';
        }
        this.filterCommandOptions();
      });

      this.commandService.getAll().subscribe(result => {
        this.commands = result;
        this.filterCommandOptions();
      });

      this.permissionService.getAll().subscribe(result => {
        this.permissionOptions = result;
      });
    });
  }

  findRequester(accreq) {
    this.original = accreq.user;
  }

  apply() {
    this.accountReqService.applyAccountRequest(this.accreq).subscribe(response => {
      // response = { done: boolean, message: string , error: any}
      if (response['error'] !== undefined) {
        this.matSnack.open('Request Approved, Changes Applied', 'Close');
        this.dialogRef.close(response);
      } else {
        this.dialogRef.close(response);
      }
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  // Filters the commands based on the command hierachy. Called twice
  filterCommandOptions() {
    if (this.commandHierarchy && this.commands) {
      if (this.currentUser.isSystemAdmin()) {
        this.commandOptions = this.commands;
      } else {
        const supportingCommands = this.commandHierarchy.getSupportingCommandIds(this.currentUser);
        this.commandOptions = this.commands.filter(c => {
          return supportingCommands.includes(c.commandId) || c.commandId === this.currentUser.commandId;
        });
      }
    }
  }
}
