import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {

    private _config;
    private _configURL = '../assets/config/ui-config.json';
    constructor(private http: HttpClient) {
    }

    Init() {
        this._config = {classification: 'CANNOT DETERMINE CLASSIFICATION'};
        return this.http.get(this._configURL).toPromise()
          .then(data => {
            this._config = data;
          })
          .catch(err => {
            return Promise.reject(err.error || 'Unable to read configuration file');
          })
    }
    public getConfig() {
      return this._config;
    }
}
