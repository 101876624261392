<mat-card class="form-card">
  <div fxLayoutAlign="center" class="nob-header">
    <div class="card">
      <img class="fomImage" height="74px" width="74px" src="assets/images/FDT-M.png" />
    </div>
    <div class="nob-header-middle">
      <div class="grid-title nob-title">
        <span>{{initService.getConfig().titlePrefix}} Force Disposition Tracker - Maritime</span>
      </div>
      <div>
        <app-nob-ticker [startTicker]="startTicker" (unitClickEvent)="unitSelected($event)"
          (refreshClickEvent)="refreshNOB()"></app-nob-ticker>
      </div>
    </div>
    <div class="card" fxLayoutAlign="right">
      <img class="fomImage" height="74px" width="74px" src="assets/images/FDT-M.png" />
    </div>
  </div>

  <div class="action-area" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center">
    <button *ngIf="user.isSystemAdmin()" mat-raised-button class="fdt-m-primary" (click)="$event.stopPropagation()"
      (click)="handleBulkSend()">
      COP Bulk Update
    </button>&nbsp;

    <button mat-raised-button class="fdt-m-primary" [disabled]="NetworkLinkKMLPending"
      (click)="$event.stopPropagation()" (click)="getNetworkLinkKML()">
      NOB KML
    </button>&nbsp;

    <button mat-raised-button class="fdt-m-primary" [disabled]="NOBKMLPending" (click)="$event.stopPropagation()"
      (click)="generateNOBKML()">
      PFIF S.A. KML
    </button>

    <mat-form-field class="groupSelect">
      <mat-select [(ngModel)]="grouping" #groupingSelect placeholder="Group By"
        (selectionChange)="groupingChanged($event, grouping)">
        <mat-option *ngFor="let groupItem of groupItems" [value]="groupItem">
          {{groupItem.display}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="groupSelect">
      <input matInput type="text" [(ngModel)]="filterValue" (ngModelChange)="onFilterChange($event)" autocomplete="off"
        placeholder="Unit Name Filter">
      <span class="hyper" (click)="clearFilter()" matSuffix>X</span>
    </mat-form-field>

    <div class="table-actions" fxFlexAlign="end" fxFlexOffset="5%">
      <div class="collapse-actions" *ngIf="hasMultipleGroups()">
        <button mat-raised-button class="fdt-m-primary" *ngIf="hasAnyExpandedGroups()" (click)="changeAllGroups(false)" matTooltip="Collapse all groupings in table">
          <mat-icon>unfold_less</mat-icon> Collapse All
        </button>

        <button mat-raised-button class="fdt-m-primary" *ngIf="!hasAnyExpandedGroups()" (click)="changeAllGroups(true)" matTooltip="Expand all groupings in table">
          <mat-icon>unfold_more</mat-icon> Expand All
        </button>
      </div>
    </div>
  </div>

  <br>
  <div fxLayoutGap="10px" fxLayoutGap.xs="0">
    <cdk-virtual-scroll-viewport tvsItemSize="12" headerHeight="56">
      <table class="nob-table" mat-table matSort [dataSource]="dataSource" (matSortChange)="sortData($event)">
        <!-- Show Info Column -->
        <ng-container matColumnDef="info">
          <th mat-header-cell *matHeaderCellDef>
            <mat-icon aria-hidden="false" aria-label="Unit Info">info</mat-icon>
          </th>
          <td class="w-button" mat-cell *matCellDef="let row" matTooltip="Display Unit Location information">
            <span class="hyper" (click)="showInfo(row)">
              <mat-icon class="nob-icon" aria-hidden="false" aria-label="Unit Info">info</mat-icon>
            </span>
          </td>
        </ng-container>
        <!-- Data Columns -->
        <!-- <ng-container matColumnDef="rank">
              <th mat-header-cell mat-sort-header *matHeaderCellDef> Rank </th>
              <td mat-cell *matCellDef="let element">
                {{element.rank}}
              </td>
            </ng-container> -->

        <ng-container matColumnDef="classification_string">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Classification </th>
          <td mat-cell
            matTooltip="Activity {{element.activity_category_name}} Category {{element.category_description}}"
            matTooltipPosition="right" *matCellDef="let element">
            {{element.classification_string}}
          </td>
        </ng-container>

        <ng-container matColumnDef="fleet_code">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Fleet </th>
          <td mat-cell *matCellDef="let element">
            {{element.fleet_code}}
          </td>
        </ng-container>

        <ng-container matColumnDef="el_not_code">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> ELNOT </th>
          <td mat-cell *matCellDef="let element">
            {{element.el_not_code}}
          </td>
        </ng-container>

        <ng-container matColumnDef="final_name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element">
            <img [src]="getFlag(element.country_name)" height="12">
            {{ element.confidence_code ? element.confidence_code + ' ' : '' }}{{ element.final_name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="new_days">
          <th mat-header-cell class="w-numbers" mat-sort-header *matHeaderCellDef> Days Out </th>
          <td mat-cell class="w-numbers" *matCellDef="let element">
            {{element.new_days}}
          </td>
        </ng-container>

        <ng-container matColumnDef="dtg_imaged_ts">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> U/W DTG </th>
          <td mat-cell *matCellDef="let element">
            {{element.dtg_imaged_ts | zulu1Pipe }}
          </td>
        </ng-container>

        <ng-container matColumnDef="last_loc">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Last Location </th>
          <td mat-cell *matCellDef="let element">
            {{element.last_loc}}
          </td>
        </ng-container>

        <ng-container matColumnDef="latitude" >
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Lat </th>
          <td mat-cell *matCellDef="let element">
            {{getLat(element)}}
          </td>
        </ng-container>

        <ng-container matColumnDef="longitude">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Lon </th>
          <td mat-cell *matCellDef="let element">
            {{getLon(element)}}
          </td>
        </ng-container>

        <ng-container matColumnDef="source">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Int Source </th>
          <td mat-cell *matCellDef="let element">
            {{element.source}}
          </td>
        </ng-container>

        <ng-container matColumnDef="originator">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Originator </th>
          <td mat-cell *matCellDef="let element">
            {{element.originator}}
          </td>
        </ng-container>

        <ng-container matColumnDef="loc_time_ts">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Location Time </th>
          <td mat-cell *matCellDef="let element">
            {{element.loc_time_ts | zulu1Pipe }}
          </td>
        </ng-container>

        <ng-container matColumnDef="latency">
          <th mat-header-cell class="w-numbers" mat-sort-header *matHeaderCellDef> TimeLate (Hrs) </th>
          <td mat-cell class="w-numbers" [ngClass]="element.latencyClass" *matCellDef="let element">
            <span>{{element.latency}}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="port_name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Home Port </th>
          <td mat-cell *matCellDef="let element">
            {{element.port_name}}
          </td>
        </ng-container>

        <ng-container matColumnDef="category_description">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Category </th>
          <td mat-cell *matCellDef="let element">
            {{element.category_description}}
          </td>
        </ng-container>

        <ng-container matColumnDef="activity">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Activity </th>
          <td mat-cell *matCellDef="let element">
            {{element.activity}}
          </td>
        </ng-container>

        <ng-container matColumnDef="papa_case">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Case No</th>
          <td mat-cell *matCellDef="let element">
            {{element.papa_case}}
          </td>
        </ng-container>

        <ng-container matColumnDef="op_area_title">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Op Area</th>
          <td mat-cell *matCellDef="let element">
            {{element.op_area_title}}
          </td>
        </ng-container>


        <!-- Send Action Column -->
        <ng-container matColumnDef="send">
          <th mat-header-cell *matHeaderCellDef> COP</th>
          <td class="w-button" mat-cell *matCellDef="let row" matTooltip="Send Unit location to COP">
            <span [ngClass]="{'hyper': !sendActive(row)}" (click)="$event.stopPropagation(); handleSend(row)">
              <mat-icon class="nob-icon" aria-hidden="false" aria-label="Unit Info">send</mat-icon>
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="groupHeader">
          <td colspan="999" mat-cell *matCellDef="let group; grouping" class="groupHeader hyper">
            <span title="click to show/hide group items">
              {{group.name}}: {{group.itemCount}} total Unit(s)
            </span>
          </td>
        </ng-container>

        <tr class="nob-header-row" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr class="nob-row" [ngClass]="row.rowClass" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup" (click)="groupHeaderClick(row)"
          class="grouping-row"></tr>
      </table>
    </cdk-virtual-scroll-viewport>
  </div>
</mat-card>