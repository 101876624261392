<app-classification-bar class="top-modal-classification-bar"></app-classification-bar>
<app-fdtm-modal-header [headerString]="headerString"></app-fdtm-modal-header>
<div mat-dialog-content>
<form [formGroup]="editForm">
  <mat-form-field class="input-full-width">
    <input matInput (input)="checkDups($event, 'classificationName')" autocomplete="off"
      placeholder="Classification" required formControlName="classificationName">
    <mat-error *ngIf="editForm?.controls?.classificationName?.hasError('duplicate entry')">duplicate entry</mat-error>
  </mat-form-field>
  <br>
  <mat-form-field class="input-full-width">
    <input matInput (input)="checkDups($event, 'shortName')"  autocomplete="off"
      placeholder="Short Name" required maxlength="2" formControlName="shortName">
    <mat-error *ngIf="editForm?.controls?.shortName?.hasError('duplicate entry')">duplicate entry</mat-error>
  </mat-form-field>
  <br>
  <mat-form-field class="input-full-width">
    <input matInput (input)="checkDups($event, 'sacc')"  autocomplete="off" 
      placeholder="SACC" maxlength="1" required formControlName="sacc">
    <mat-error *ngIf="editForm?.controls?.sacc?.hasError('duplicate entry')">duplicate entry</mat-error>
  </mat-form-field>
  <br>
  <mat-form-field class="input-full-width">
    <input matInput placeholder="Source Code" required  autocomplete="off" maxlength="2" formControlName="sourceCode">
  </mat-form-field>
  <br>
  <mat-form-field class="input-full-width">
    <input min="1" type="number" matInput (input)="checkDups($event, 'sortBy', true)" (change)="checkDups($event, 'sortBy', true)" placeholder="Sort Sequence" required formControlName="sortBy">
    <mat-error *ngIf="editForm?.controls?.sortBy?.hasError('duplicate entry')">duplicate entry</mat-error>
  </mat-form-field>
  <div mat-dialog-actions>
    <p *ngIf="isDelete"  class="mat-color-warn">
      Deleting a Classification Marking may cause serious issues with existing records!
      If you choose to delete this record the system will search for possible errors and may reject the request. 
      This is no guarantee that data corruption may still be present.  
    </p>
    <p *ngIf="!isNew && !isDelete"  class="mat-color-warn">
      Editing a Classification Marking may cause serious issues with existing records!
      If you choose to edit this marking the changes will be applied to all existing records in the database.  
    </p>
    <button *ngIf="!isDelete" [disabled]="!editForm.valid" mat-raised-button color="primary" (click)="handleSubmit()">Submit</button>
    <button *ngIf="isDelete" mat-raised-button color="warn" (click)="localDelete()">Delete</button>
    <button mat-raised-button (click)="handleCancel()">Cancel</button>
  </div>
</form>
</div>
<app-classification-bar class="bottom-modal-classification-bar"></app-classification-bar>
