import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Subscription, timer } from 'rxjs';
import { AppInitService } from '../../../../config/init.service';
import { Router }from '@angular/router'
import { AuthenticationService } from '@services/data-services/authentication.service';
import { take } from 'rxjs/operators';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { GlobalStore } from '@services/global/message-bus/global-store.model';

@Component({
  selector: 'app-timeout-warning',
  templateUrl: './timeout-warning.component.html',
  styleUrls: ['./timeout-warning.component.css']
})
export class TimeoutWarningComponent implements OnInit, OnDestroy {

  private tickerSubscription: Subscription;
  timeLeft: number
  timeout: number = 0
  globalStore: GlobalStore;
  
  constructor(public initService: AppInitService, private authenticationService: AuthenticationService,
    private messageBusService: MessageBusService, private route: Router,
    public dialogRef: MatDialogRef<TimeoutWarningComponent>, private matDialog: MatDialog) {
  }

  ngOnInit() {
    // show max idle in UI
    this.timeout = this.initService.getConfig().sessionTimeoutMinutes || 30;
    // how long are we hanging around this modal waiting for input
    const maxWaiting = this.initService.getConfig().sessionWarningSeconds || 120
    // get the time when dialog opened, compare to current time every step in the countdown timer
    const initTime = new Date().getTime()
    // create a timer and subscribe
    let dataTimer = timer(0, 1000)
    // odd assignment here, but did see where ngInot called again after waking up from a locked computer...
    this.tickerSubscription = this.tickerSubscription || dataTimer.subscribe(x => {
      // check for a difference greater than configured interval, show on UI
      const timeWaiting = Math.floor((new Date().getTime() - initTime) / 1000)
      this.timeLeft = maxWaiting - timeWaiting
      if (this.timeLeft < 1) {
        this.logout()
      }
    })
  }

  ngOnDestroy() {
    // gotta make the subscriptions go away
    if (this.tickerSubscription) {
      this.tickerSubscription.unsubscribe()
    }
  }

  clearAndGoHome() {
    // pass true to logout
    this.matDialog.closeAll()
    // close all open modals
    this.dialogRef.close(true)
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('currentUserId')
    sessionStorage.removeItem('user')
    sessionStorage.removeItem('ackAgreement')
    this.messageBusService.updateStore(new GlobalStore({isLoggedIn: false}));
    this.route.navigate(['home']);
  }
  
  logout() {
    this.authenticationService.logout('Session Timeout').pipe(take(1)).subscribe(result => {
      this.clearAndGoHome()
    }, error => {
      this.clearAndGoHome()
    })
  }

  remain() {
    // just return, they hit the button so they must be active
    this.dialogRef.close();
  }
}
