import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from './api.config';

@Injectable()
export class PocApiService {
  SERVER_URL;

  constructor(
    private http: HttpClient,
    apiConfig: APIConfig) {
      this.SERVER_URL = apiConfig.TARGET_URL;
  }

  public getAllPocs(): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/point-of-contacts`);
  }

  public getPocById(pocId: string): Observable<any> {
    return this.http.get(`${this.SERVER_URL}/point-of-contacts/${pocId}`);
  }

  public updatePoc(pocId: any, data: object): Observable<any> {
    return this.http.put(`${this.SERVER_URL}/point-of-contacts/${pocId}`, data);
  }

  public createPoc(data: object): Observable<any> {
    return this.http.post(`${this.SERVER_URL}/point-of-contacts`, data);
  }

  public deletePoc(poc: any): Observable<any> {
    return this.http.delete(`${this.SERVER_URL}/point-of-contacts/${poc.pocId}`);
  }
}
