import { MessageApiService } from '@services/api-services/message-api.service';
import { Injectable } from '@angular/core';
// import { JsonConverter } from '@services/global/json-converter';
import { Observable, empty } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class MessageService {
    // private jsonConvert: JsonConverter;

    constructor(private messageApiService: MessageApiService) {
        // this.jsonConvert = new JsonConverter();
    }

    public send2Cop(ship_location_id: string) {
        return this.messageApiService.send2Cop(ship_location_id)
            .pipe(
                map((data) => {
                    return data
                })
            );
    }

    public bulkSend2Cop() {
        return this.messageApiService.bulkSend2Cop()
            .pipe(
                map((data) => {
                    return data
                })
            );
    }

}
