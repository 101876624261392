import { Injectable } from '@angular/core';
import { JsonConverter } from '../global/json-converter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ElnotApiService } from '@services/api-services/elnot-api.service';
import { Elnot } from '@models/elnot.model';


@Injectable()
export class ElnotService {
    private jsonConvert: JsonConverter;

    constructor(private apiService: ElnotApiService) {
        this.jsonConvert = new JsonConverter();
    }

    public getAll(): Observable<Elnot[]> {
        return this.apiService.getAllElnots()
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, Elnot);
                })
            );
    }

    public getCategoriesByCatId(id): Observable<Elnot> {
        return this.apiService.getElnotByElnotId(id)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Elnot);
                })
            );
    }

    public update(elnot: Elnot): Observable<Elnot> {
        return this.apiService.updateElnot(this.jsonConvert.serializeObject(elnot))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Elnot);
                })
            );
    }

    public create(elnot: Elnot): Observable<Elnot> {
        return this.apiService.createElnot(this.jsonConvert.serializeObject(elnot))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Elnot);
                })
            );
    }

    public delete(elnot: Elnot): Observable<Elnot> {
        return this.apiService.deleteElnot(this.jsonConvert.serializeObject(elnot))
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

}
