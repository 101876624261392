import { JsonObject, JsonProperty, Any } from 'json2typescript';
import { Command } from '@models/command.model';

@JsonObject('Source')
export class Source {
  @JsonProperty('source_id', String, true)
  sourceId: string = undefined;
  @JsonProperty('source_name', String, true)
  sourceName: string = undefined;
  @JsonProperty('nob_code', String, true)
  nobCode: string = undefined;
  @JsonProperty('short_code', String, true)
  shortCode: string = undefined;
  @JsonProperty('command_id', String, true)
  commandId: string = undefined;
  @JsonProperty('command', Command, true)
  command: Command = undefined;
  @JsonProperty('created_by', String, true)
  createdBy: string = undefined;
  @JsonProperty('lookup_filter_id', String, true)
  lookupFilterId: string = undefined;
  @JsonProperty('sort_sequence', String, true)
  sortSequence: number = undefined;

  static lookupName(): string {
    return 'sources';
  }

  static primaryKey(): string {
    return 'sourceId'
  }
  groupProperty(): string {
    return this.sourceName
  }
}
