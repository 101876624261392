import { Component, Output, EventEmitter, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { HistoricalService } from '@services/data-services/historical.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FomPageBaseComponent } from '@fom-module/base-classes/fom-page-base.component';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { Router } from '@angular/router';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';
import { LookUpService } from '@services/data-services/lookup.service';
import { Activity } from '@models/activity.model';
import { Country } from '@models/country.model';
import { Unit } from '@models/unit.model';
import { Historical } from '@models/historical.model';
import { LocationService } from '@services/data-services/location.service';
import { debounceTime, exhaustMap, filter, finalize, last, scan, startWith, switchMap, takeWhile, tap } from 'rxjs/operators';
import { MatSort, Sort } from '@angular/material/sort';
import { ReportingService } from '@services/data-services/reporting.service';
import { SelectionModel } from '@angular/cdk/collections';
import { take } from 'rxjs/operators';
import { UnitService } from '@services/data-services/unit.service';
import { UtilityService } from '@services/utility.service';
import { CountryService } from '@services/data-services/country.service';
import { SortingService } from '@services/sorting.service';
import { Classification } from '@models/classification.model';
import { AppInitService } from '../../../../config/init.service';
import { PicklistPrefService } from '@services/data-services/picklist-pref.service';
import { User } from '@models/user.model';
import { Area } from '@models/area.model';
import { CurrentUserService } from '@services/current-user-service';
import { Zulu1Pipe } from '@core-module/pipes/zulu1.pipe';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { Observable, Subject } from 'rxjs';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

const DEFAULT_SORT: Sort = {
  active: 'dtgImaged',
  direction: 'asc'
};

// Sort by underway start then locTime after active sort column.
const FALLBACK_SORTS = ['dtgImaged', 'locTime'];

// grouping courtesy of https://stackblitz.com/edit/angular-mattable-with-collapsible-groupheader
export class Group {
  level = 0;
  parent: Group;
  expanded = true;
  name = '';
  get visible(): boolean {
    return !this.parent || (this.parent.visible && this.parent.expanded);
  }
}

@Component({
  selector: 'app-unit-search',
  templateUrl: './unit-search.component.html',
  styleUrls: ['./unit-search.component.css'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ],
})
export class UnitSearchComponent extends FomPageBaseComponent implements OnInit, OnDestroy {
  editForm: FormGroup;

  entity: Historical;

  lastSort: Sort = {
    active: 'finalName',
    direction: 'asc'
  };

  user: User;
  // lookup values in the new shared lookup select component
  country: Country;
  activityCategoryOptions: Activity[];
  classifications: Classification[];
  // special filter for Country on the search/type-ahead
  countries: Country[] = [];
  filterByCountry: any = {};
  noCountry = {};

  items: Historical[] = [];
  opAreas: Area[] = [];

  @ViewChild(MatAutocompleteTrigger, { static: true }) unitAutocomplete: MatAutocompleteTrigger;
  unitsPerPage: number;
  filteredUnits$: Observable<Unit[]> | undefined;
  private nextUnitPage$ = new Subject();

  // these objects represent the columns that can be grouped
  groupItems: any[] = [
    {
      display: 'Underway Period',
      groupAttribute: 'uwCode',
      getName: function (context, rows) {
        const startDate: Date = new Date(rows[0].dtgImaged);
        const endDate: Date = new Date(rows.map(e => e.locTime).reduce((a, b) => a > b ? a : b));
        return `Underway: ${context.zulu1Pipe.transform(startDate)} - ${context.zulu1Pipe.transform(endDate)}`;
      }
    },
    {
      display: 'Activity Category',
      groupAttribute: 'activityCategoryName',
      typeName: 'Activity',
      getName: function (_context, rows) {
        return `Activity Category: ${rows[0].activityCategoryName}`;
      }
    }
  ];
  // the current (default) subgroup will be the first one in the list
  grouping: any = this.groupItems[0];
  // Will always group by the unit's final name then a selection from groupItems
  groupByColumns: any[] = [
    {
      groupAttribute: 'finalName',
      getName: function (_context, rows) {
        return rows[0].finalName;
      }
    },
    this.grouping
  ];

  // need to retain the filtering value for the refresh hack
  filterValue = '';
  lastFilter = null;
  filterColumns = [
    'finalName'
  ];

  dataSource = new TableVirtualScrollDataSource<Historical | Group>([]);
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  selection = new SelectionModel<Historical>(true, []);

  isLoading = false;

  now = new Date();
  date1: Date;
  displayedColumns = [
    'select',
    'classification',
    'fleet',
    'finalName',
    'daysOut',
    'dtgImaged',
    'lastLoc',
    'portName',
    'latitude',
    'longitude',
    'locTime',
    'countryName',
    'opArea',
    'sourceName',
    'srcOrig',
    'activityCategoryCode'
  ];
  columnHeaders = {
    'classification_string': { columnName: 'Classification' },
    'fleet_code': { columnName: 'Fleet' },
    'final_name': { columnName: 'Unit Name' },
    'new_days': { columnName: 'Days Out', type: 'number' },
    'dtg_imaged_ts': { columnName: 'Start Date', type: 'date' },
    'last_loc': { columnName: 'Location' },
    'port_name': { columnName: 'Home Port' },
    'latitude': { columnName: 'Latitude' },
    'longitude': { columnName: 'Longitude' },
    'loc_time_ts': { columnName: 'DTG', type: 'date' },
    'country_name': { columnName: 'Country' },
    'op_area_with_remarks': { columnName: 'Op Area' },
    'source_name': { columnName: 'Source' },
    'src_orig': { columnName: 'Originator' },
    'activity_category_name': { columnName: 'Activity' }
  };
  countryMap = {};

  @Output() startDate: EventEmitter<any> = new EventEmitter<any>();
  unitSearchForm = new FormGroup({
    archive: new FormControl(moment([])),
    toDate: new FormControl(moment([])),
  });

  constructor(public historicalService: HistoricalService,
    private reportingService: ReportingService,
    public locationService: LocationService,
    public messageBusService: MessageBusService,
    public lookUpService: LookUpService,
    public unitService: UnitService,
    public utilities: UtilityService,
    public formBuilder: FormBuilder,
    private countryService: CountryService,
    public route: Router,
    private picklistPrefService: PicklistPrefService,
    private currentUserService: CurrentUserService,
    public initService: AppInitService,
    private zulu1Pipe: Zulu1Pipe,
    private lookupService: LookUpService,
    private sorter: SortingService) {
    super(messageBusService, route);
  }

  ngOnInit() {
    this.user = this.currentUserService.getCurrentUser();
    this.filterByCountry = this.noCountry;
    this.entity = new Historical();

    this.unitsPerPage = this.initService.getConfig().unitPageSize || 20;

    const filters = Object.assign({}, JSON.parse(sessionStorage.getItem('unitFilters')));
    this.initializeForm(filters);

    this.lookUpService.getLookupByType(Classification, true).pipe(take(1)).subscribe(results => {
      this.classifications = results;
      // descending order fomng-840
      this.classifications.sort((a, b) => {
        return a.sortBy < b.sortBy ? 1 : -1
      })
    });

    this.dataSource.filterPredicate = this.customFilterPredicate.bind(this);

    this.loadLookups()

    super.ngOnInit();
  }

  ngOnDestroy() {
  }

  async loadLookups() {
    // use the user instance function, will be changed to JFMCC
    const filter = { owner_id: this.user.unitOwnerId(null) }
    this.activityCategoryOptions = await this.lookUpService.getLookupByType(Activity).pipe(take(1)).toPromise()
    this.countries = await this.lookupService.getLookupByType(Country, true).pipe(take(1)).toPromise()
    this.opAreas = await this.lookupService.getLookupByType(Area, true).pipe(take(1)).toPromise()
    this.picklistPrefService.getAll(filter).pipe(take(1)).subscribe(preferences => {
      this.sorter.sortList(this.activityCategoryOptions, preferences, 'code', 'activityId')
      this.sorter.sortList(this.countries, preferences, 'name', 'countryId')
      this.sorter.sortList(this.opAreas, preferences, 'opArea', 'opAreaId')
    })
  }

  async filterChange(event) {
    const filter = {}
    if (this.editForm.controls.opArea.value) {
      filter['op_area_with_remarks'] = this.editForm.controls.opArea.value;
    }
    if (this.filterByCountry && this.filterByCountry.countryId) {
      filter['country_id'] = this.filterByCountry.countryId
    }

    this.initializeForm(filter, false)
  }

  initializeForm(filters = {}, clear = true) {
    if (clear) {
      this.editForm = this.formBuilder.group({
        activity: [''],
        country: [''],
        archive: new FormControl(moment()),
        toDate: new FormControl(moment()),
        unit: [''], // drop down index
        classificationNames: [[]],
        opArea: ['']
      });
    }

    // Sort units by name fields.
    filters['sort'] = 'name' ;

    const unitFilters$ = this.editForm.get('unit').valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      filter(q => typeof q === 'string')
    );

    this.filteredUnits$ = unitFilters$.pipe(
      switchMap(filter => {
        // Reset current page whenever name filter changes.
        let currentPage = 1;
        return this.nextUnitPage$.pipe(
          tap(() => this.isLoading = true),
          startWith(currentPage),
          // Note: Until the backend responds, ignore NextPage requests.
          exhaustMap(_ => this.searchUnits(filter, filters, currentPage)),
          tap(() => currentPage++),
          takeWhile(p => p.length > 0, true),
          scan((allUnits: any, newUnits: any) => allUnits.concat(newUnits), []),
          tap(units => {
            const unit = this.editForm.get('unit')?.value || '';

            if(unit?.length) {
              if (units.length === 1 && unit.trim().toLocaleLowerCase() === units[0].fullName().toLocaleLowerCase()) {
                this.unitAutocomplete.closePanel();
                this.editForm.controls.unit.setValue(units[0])
              }
            }

            this.isLoading = false;
          })
        );
      })
    );
  }

  unitFiltersChanged(filters) {
    // clear the results
    this.dataSource.data = [];
    // but don't clear the input fields
    this.initializeForm(filters, false);
  }

  public badUnit() {
    // if the user overtypes the autocomplete then the unit will exist but be a string instead of an object
    // we stil want the user to be able to search on 'no unit', so allow empty string or object
    const unit = this.editForm.controls.unit.value;
    if (typeof unit === 'string' && unit.length > 0) {
      return true;
    }
    return false;
  }

  public search() {
    const filters = JSON.parse(sessionStorage.getItem('unitFilters'));
    // reset the selected items and the counts
    this.selection.clear();

    const params = {};

    const unit = this.editForm.controls.unit.value;
    if (unit && unit.unitId) {
      params['unit_id'] = unit.unitId;
    }

    if (this.editForm.controls.activity && this.editForm.controls.activity.value) {
      params['activity_category_id'] = this.editForm.controls.activity.value;
    }

    // check flag drop down
    if (this.country && this.country.countryId) {
      params['country_id'] = this.country.countryId;
      if (filters && filters['country_id']) {
        delete filters['country_id'];
      }
    }

    // check country drop down
    if (this.editForm.controls.country && this.editForm.controls.country.value) {
      params['country_id'] = this.editForm.controls.country.value;
    }

    // check op area drop down
    if (this.editForm.controls.opArea && this.editForm.controls.opArea.value) {
      params['op_area_with_remarks'] = this.editForm.controls.opArea.value;
    }

    const archive = (this.editForm.controls.archive.value).format('YYYY-MM-DD');
    params['start_time'] = archive;

    // adding a day behind the scene since the search starts and ends at midnight
    const toDate = (this.editForm.controls.toDate.value).clone().add('days', 1).format('YYYY-MM-DD');
    params['end_time'] = toDate;

    if (this.editForm.controls.classificationNames && this.editForm.controls.classificationNames.value
      && this.editForm.controls.classificationNames.value.length) {
      params['classifications'] = this.editForm.controls.classificationNames.value;
    }

    this.historicalService.getAll(params, filters).pipe(take(1)).subscribe(data => {
      this.items = data;

      this.groupData(DEFAULT_SORT);
      // Force filter refresh to collapse all groupings...
      this.triggerFilter();

      // start with all selected
      this.dataSource.data.forEach(row => {
        if (row instanceof Historical) {
          this.selection.select(row);
        }
      });
    });
  }

  // only export the selected rows
  exportKML() {
    this.reportingService.getSearchResultsKML(this.selection.selected).pipe(take(1)).subscribe(results => {
      this.download(results.body, 'Search Results.KML');
    });
  }

  // ok, this is sorta overkill to have the microservice also
  // do the sorting and grouping since code was added here to get the user preferences
  // there is also an option in the microservice to accept data already grouped and sorted
  // but the data is not quite in the proper json format
  exportExcel() {
    // sort selection based on index in the sorted+grouped data since removing and re-add doesn't
    // maintain the order displayed.
    const sortedSelection = this.selection.selected.sort((a, b) => this.dataSource.data.indexOf(a) - this.dataSource.data.indexOf(b));

    // grab the sorted and grouped data
    this.reportingService.getSearchResultsExcel(sortedSelection, null, null, this.columnHeaders).pipe(take(1)).subscribe(results => {
      this.download(results.body, 'Search Results.xlsx');
    });
  }

  download(report: any, filename: string) {
    const file = new Blob([report]);
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(file, filename);
    } else {
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(file); // xhr.response is a blob
      a.style.display = 'none';
      a.download = filename;
      document.body.appendChild(a);
      a.click();
    }
  }

  // if there is a lookup type associated, get the preferences
  // otherwise return the on screen group name order
  async getGroupOrder(groupBy: string) {
    if (groupBy && groupBy.length) {
      const lookupType = this.utilities.getTypeFromName(groupBy);
      const primaryKey = (lookupType as any).primaryKey();
      const picklistPreferences = await this.picklistPrefService.getAll({
        owner_id: this.user.currentJFMCC ? this.user.currentJFMCC.commandId : null
      }).toPromise();
      const lookups = await this.lookupService.getLookupByType(lookupType, true).toPromise();
      const groupOrder = [];
      lookups.forEach(element => {
        const found = picklistPreferences.find(x => x.sourcePicklistId === element[primaryKey]);
        groupOrder.push({ value: (element as any).groupProperty(), sortSequence: found ? found.sortSequence : 0 });
      });
      groupOrder.sort((a, b) => {
        return a.sortSequence < b.sortSequence ? -1 : 1;
      });
      return groupOrder.map(x => x.value);
    } else {
      const groups = [];
      this.dataSource.data.filter(row => {
        return (row instanceof Group);
      }).forEach(group => {
        groups.push(group[this.grouping.value]);
      });
      return groups;
    }
  }

  change(dateEvent) {
    this.startDate.emit(dateEvent.value);
  }

  catchDate1(event) {
    this.date1 = event;
  }

  // autocomplete or type ahead on the unit search form.
  displayUnit(unit?: Unit): string | undefined {
    return unit ? unit.fullName() : undefined;
  }

  handleUnitChange(event) {
    const unit = event.option.value;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const groupRows = this.dataSource.data.filter(row => {
      return row instanceof Group;
    });
    const numRows = this.dataSource.data.length - groupRows.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => {
        if (row instanceof Historical) {
          this.selection.select(row);
        }
      });
  }

  customFilterPredicate(data: any | Group, filter: string): boolean {
    if (data instanceof Group) {
      return data.visible;
    } else {
      // show data row only if group is visible and expanded
      if (data.group.visible && data.group.expanded) {
        return true;
      } else {
        return false;
      }
    }
  }

  // grouping
  groupingChanged(event, grouping) {
    // Replace the lowest level grouping, not entire list.
    this.groupByColumns.pop();
    this.groupByColumns.push(grouping);

    // when grouping changes, sort by grouping and then finalName
    this.groupData(DEFAULT_SORT);
  }

  groupHeaderClick(row) {
    row.expanded = !row.expanded;
    this.triggerFilter();
  }

  // Forces filter refresh
  triggerFilter() {
    this.lastFilter = performance.now().toString();
    // hack to trigger filter refresh, but test if a bogus within the filter
    this.dataSource.filter = this.filterValue.length ? this.filterValue : this.lastFilter;
  }

  addGroups(data: any[], groupByColumns: any[]): any[] {
    const rootGroup = new Group();
    return this.getSublevel(data, 0, groupByColumns, rootGroup);
  }

  getSublevel(data: any[], level: number, groupByColumns: any[], parent: Group): any[] {
    // Recursive function, stop when there are no more levels.
    if (level >= groupByColumns.length) {
      // add the parent group as the group on the data elements since there is no further grouping to do.
      data.forEach(e => e.group = parent);
      return data;
    }

    const currentColumn = groupByColumns[level];

    const groups = this.uniqueBy(
      data.map(row => {
        const group = new Group();
        group.level = level + 1;
        group.parent = parent;

        // Add values from parent groupings to the group
        for (let i = 0; i < level; i++) {
          group[groupByColumns[i].groupAttribute] = row[groupByColumns[i].groupAttribute];
        }

        if (level > 0) {
          group.expanded = false;
        }

        // Add current level grouping data
        group[currentColumn.groupAttribute] = row[currentColumn.groupAttribute];
        return group;
      }),
      JSON.stringify);


    let subGroups = [];
    groups.forEach(group => {
      const rowsInGroup = data.filter(row => group[currentColumn.groupAttribute] === row[currentColumn.groupAttribute]);
      group.count = rowsInGroup.length;
      // Add the unit name after determining the rows in case name requires all grouped items (underway date range)
      group.name = currentColumn.getName(this, rowsInGroup);
      const subGroup = this.getSublevel(rowsInGroup, level + 1, groupByColumns, group);
      subGroup.unshift(group);
      subGroups = subGroups.concat(subGroup);
    });

    return subGroups;
  }

  uniqueBy(a, key) {
    const seen = {};
    return a.filter(function (item) {
      const k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
  }

  isGroup(index, item): boolean {
    return item.level;
  }

  groupData(sort: Sort) {
    const sorted = this.sortData(sort);

    this.dataSource.data = this.addGroups(sorted, this.groupByColumns);
  }

  sortData(sort: Sort): Historical[] {
    if (!sort.active || sort.direction === '') {
      sort = DEFAULT_SORT;
    }

    this.lastSort = sort;
    const sorted = this.items.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      return this.compare(a, b, isAsc, sort.active);
    });

    return sorted;
  }

  compare(a, b, isAsc, active) {
    const direction = isAsc ? 1 : -1;

    // List of attribute names to iterate over and sort by
    const sortAttributes = [active, ...FALLBACK_SORTS];

    let lastCompare;
    for (const attribute of sortAttributes) {
      lastCompare = this.compareAttribute(a, b, attribute);

      // if values for an attribute are not equal return early
      if (lastCompare !== 0) {
        return lastCompare * direction;
      }
    }

    // if finished looping and all attributes are the same return.
    return lastCompare * direction;
  }

  // Returns comparision numeric for the attribute value of a and b.
  compareAttribute(a, b, attribute): number {
    if (a[attribute] === b[attribute]) {
      return 0;
    } else {
      return a[attribute] < b[attribute] ? -1 : 1;
    }
  }

  getFlagFromRow(row) {
    const country = this.countryMap[row.countryName];
    return country ? this.countryService.getFlagUrl(country) : null;
  }

  getFlag(country) {
    return this.countryService.getFlagUrl(country);
  }

  searchUnits(nameFilter: string, filters: any, page: number): Observable<Unit[]> {
    const offset = page > 0 ? (page - 1) * this.unitsPerPage : 0;

    return this.unitService.findUnits(nameFilter, filters, this.unitsPerPage, offset);
  }

  onUnitOptionsScroll() {
    this.nextUnitPage$.next();
  }
}
