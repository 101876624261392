<mat-card class="form-card" >
<mat-card-content>
<mat-sidenav-container class="admin-container">
  <mat-sidenav  #drawer mode="side" opened class="admin-sidenav">
      <mat-nav-list>
          <a *ngIf="user.isSystemAdmin()" [ngClass]="{'current' : selection == 'classification-markings'}"
          mat-list-item routerLink="/admin/classifymenu/classification-markings"> Classification Markings</a>
          <a *ngIf="user.isAdmin()" [ngClass]="{'current' : selection == 'fdo-map'}"
          mat-list-item routerLink="/admin/classifymenu/fdo-map"> Classification FDO Map</a>
          <a *ngIf="user.isAdmin()" [ngClass]="{'current' : selection == 'classification-marking-relationship'}"
          mat-list-item routerLink="/admin/classifymenu/classification-marking-relationship"> Classification Relationships</a>
          <a *ngIf="user.isAdmin()" [ngClass]="{'current' : selection == 'data-tags'}"
          mat-list-item routerLink="/admin/classifymenu/data-tags"> Tags</a>
      </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <!-- <router-outlet></router-outlet> -->
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
</mat-card-content>
</mat-card>
