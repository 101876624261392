import { FeaturePreference } from '@models/feature-preference.model';
import { FeaturePreferenceApiService } from '@services/api-services/feature-preference-api.service';
import { Injectable } from '@angular/core';
import { JsonConverter } from '@services/global/json-converter';
import { Observable, empty } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Form } from '@angular/forms';

@Injectable({
    providedIn: 'root'
  })
export class FeaturePreferenceService {
    private jsonConvert: JsonConverter;

    constructor(private preferenceApiService: FeaturePreferenceApiService) {
        this.jsonConvert = new JsonConverter();
    }

    public getAll(): Observable<FeaturePreference[]> {
        return this.preferenceApiService.getAll()
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, FeaturePreference);
                })
            );
    }
    
    public getById(featurePrefsId: string): Observable<FeaturePreference> {
        return this.preferenceApiService.getById(featurePrefsId)
        .pipe(
            map((data) => {
                return this.jsonConvert.deserializeObject(data, FeaturePreference);
            })
            );
        }

    public getAllByType(featurePrefsTypeId: string): Observable<FeaturePreference[]> {
        return this.preferenceApiService.getAllByType(featurePrefsTypeId)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, FeaturePreference);
                })
            );
    }
        
    public update(featurePref: FeaturePreference): Observable<FeaturePreference> {
        return this.preferenceApiService.update(this.jsonConvert.serializeObject(featurePref))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, FeaturePreference);
                })
            );
    }

    public create(featurePref: FeaturePreference): Observable<FeaturePreference> {
        return this.preferenceApiService.create(this.jsonConvert.serializeObject(featurePref))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, FeaturePreference);
                })
            );
    }

    public delete(featurePref: FeaturePreference): Observable<FeaturePreference> {
        return this.preferenceApiService.delete(this.jsonConvert.serializeObject(featurePref))
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }
}
