import { Activity } from '@models/activity.model';
import { ActivityApiService } from '@services/api-services/activity-api.service';
import { Injectable } from '@angular/core';
import { JsonConverter } from '@services/global/json-converter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ActivityService {
    private jsonConvert: JsonConverter;

    constructor(private activityApiService: ActivityApiService) {
        this.jsonConvert = new JsonConverter();
    }

    public getAll(filters = {}): Observable<Activity[]> {
        return this.activityApiService.getAllActivities(filters)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, Activity);
                })
            );
    }

    public getActivityByActivityId(activityId: string): Observable<Activity> {
        return this.activityApiService.getActivityByActivityId(activityId)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Activity);
                })
            );
    }

    public update(activity: Activity): Observable<Activity> {
        return this.activityApiService.updateActivity(this.jsonConvert.serializeObject(activity))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Activity);
                })
            );
    }

    public create(activity: Activity): Observable<Activity> {
        return this.activityApiService.createActivity(this.jsonConvert.serializeObject(activity))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Activity);
                })
            );
    }

    public delete(activity: Activity): Observable<Activity> {
        return this.activityApiService.deleteActivity(this.jsonConvert.serializeObject(activity))
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }
}
