<mat-card class="form-card">
<div class="grid-container">
  <div fxLayout fxLayoutAlign="center center" class="simple-grid-title">
    <span class="grid-title">{{initService.getConfig().titlePrefix}} FDT-M OPTEMPO</span>
  </div>
  <div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="groupName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Group Name </th>
      <td mat-cell *matCellDef="let element"> {{element.groupName}} </td>
    </ng-container>

    <ng-container matColumnDef="underwayPercent">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Percent U/W </th>
      <td mat-cell *matCellDef="let element"> {{element.percentUnderway().toFixed(2)}}% </td>
    </ng-container>

    <ng-container matColumnDef="underwayCount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> # U/W</th>
      <td mat-cell *matCellDef="let element"> {{element.countUnderway}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  </div>
</div>
</mat-card>
