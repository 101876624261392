import { JsonObject, JsonProperty, PropertyConvertingMode } from 'json2typescript';
import { DateConverter } from '../services/global/date-converter';
import { Command } from '@models/command.model';
import { Elnot } from './elnot.model';

@JsonObject('Elint')
export class Elint {
  @JsonProperty('parser_id', String, true)
  parserId: string = undefined;
  @JsonProperty('parser_elint_id', String, true) //the API uses this
  parser_elint_id: string = undefined;
  @JsonProperty('provider', String, true)
  provider: string = undefined;
  @JsonProperty('toi_ts', DateConverter, true)
  toiTs: Date = undefined;
  @JsonProperty('el_not', String, true)
  el_not: string = undefined;
  @JsonProperty('el_not_id', String, true)
  elnotId: string = undefined;
  @JsonProperty('freq', String, true)
  freq: string = undefined;
  @JsonProperty('freq_type_id', String, true)
  freqTypeId: string = undefined;
  @JsonProperty('band', String, true)
  band: string = undefined;
  @JsonProperty('func', String, true)
  func: string = undefined;
  @JsonProperty('elint_function_id', String, true)
  elint_function_id: string = undefined;
  @JsonProperty('pul_dur', Number, true)
  pulDur: number = undefined;
  @JsonProperty('pri', Number, true)
  pri: number = undefined;
  @JsonProperty('prf', Number, true)
  prf: number = undefined;
  @JsonProperty('scan_rate', Number, true)
  scanRate: number = undefined;
  @JsonProperty('scan_measure', String, true)
  scanMeasure: string = undefined;
  @JsonProperty('scan_type', String, true)
  scanType: string = undefined;
  @JsonProperty('scan_type_id', String, true)
  scanTypeId: string = undefined;
  @JsonProperty('leg0', Number, true)
  leg0: number = undefined;
  @JsonProperty('leg1', Number, true)
  leg1: number = undefined;
  @JsonProperty('leg2', Number, true)
  leg2: number = undefined;
  @JsonProperty('leg3', Number, true)
  leg3: number = undefined;
  @JsonProperty('leg4', Number, true)
  leg4: number = undefined;
  @JsonProperty('leg5', Number, true)
  leg5: number = undefined;
  @JsonProperty('leg6', Number, true)
  leg6: number = undefined;
  @JsonProperty('leg7', Number, true)
  leg7: number = undefined;
  @JsonProperty('create_ts', DateConverter, true)
  createTs: Date = undefined;
  @JsonProperty('created_by', String, true)
  createdBy: string = undefined;
  @JsonProperty('update_ts', DateConverter, true)
  dateUpdated: Date = undefined;
  @JsonProperty('updated_by', String, true)
  updatedBy: string = undefined;
  @JsonProperty('delete_ts', DateConverter, true)
  deleteTs: Date = undefined;
  @JsonProperty('deleted_by', String, true)
  deletedBy: string = undefined;
  @JsonProperty('legacy_id', Number, true)
  legacyId: number = undefined;
  @JsonProperty('classification_description', String, true)
  classificationDescription: string = undefined;
}
