import { Slide } from '@models/slide.model';
import { SlideApiService } from '../api-services/slide-api.service';
import { Injectable } from '@angular/core';
import { JsonConverter } from '../global/json-converter';
import { Observable, empty } from 'rxjs';
import { map, catchError } from 'rxjs/operators';


@Injectable()
export class SlideService {
    private jsonConvert: JsonConverter;

    constructor(private slideApiService: SlideApiService) {
        this.jsonConvert = new JsonConverter();
    }

    public getAll(): Observable<Slide[]> {
        return this.slideApiService.getAllSlides()
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, Slide);
                })
            );
    }

    public getSlideBySlideId(slideId): Observable<Slide> {
        return this.slideApiService.getSlideBySlideId(slideId)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Slide);
                })
            );
    }

    public getImage(slideId): Observable<Slide> {
        return this.slideApiService.getImage(slideId)
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }
    public update(slide: Slide): Observable<Slide> {
        const fd = new FormData();
        if (slide.image) {
            fd.append('image', slide.image['contents'], slide.image['name']);
        }
        fd.append('slide_name', slide.slideName);
        fd.append('lat1', slide.lat1.toString());
        fd.append('lon1', slide.lon1.toString());
        fd.append('lat2', slide.lat2.toString());
        fd.append('lon2', slide.lon2.toString());
        fd.append('fleet_id', slide.fleetId);
       // fd.append('assoc_fleet_id', slide.assocFleetId);
        fd.append('link', slide.link);
        fd.append('country_id', slide.countryId);
        //console.log(fd);
        return this.slideApiService.updateSlide(slide.slideId, fd)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Slide);
                })
            );
    }

    public create(slide: Slide): Observable<Slide> {
        const fd = new FormData();
        fd.append('image', slide.image['contents'], slide.image['name']);
        fd.append('slide_name', slide.slideName);
        fd.append('lat1', slide.lat1.toString());
        fd.append('lon1', slide.lon1.toString());
        fd.append('lat2', slide.lat2.toString());
        fd.append('lon2', slide.lon2.toString());
        fd.append('fleet_id', slide.fleetId);
       // fd.append('assoc_fleet_id', slide.assocFleetId);
        fd.append('link', slide.link);
        fd.append('country_id', slide.countryId);
        //console.log(fd);
        return this.slideApiService.createSlide(fd)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Slide);
                })
            );
    }

    public delete(slide: Slide): Observable<Slide> {
        return this.slideApiService.deleteSlide(this.jsonConvert.serializeObject(slide))
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

}
