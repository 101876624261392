<app-classification-bar class="top-modal-classification-bar"></app-classification-bar>
<app-fdtm-modal-header [headerString]="headerString"></app-fdtm-modal-header>
<div mat-dialog-content style="overflow-x: hidden;">
  <form [formGroup]="editForm" novalidate>
    <mat-form-field>
      <input matInput [formControl]="formOtherName" placeholder="Other Name" required [(ngModel)]="unitOtherName.otherName">
    </mat-form-field>
    
    <mat-form-field flex="33">
      <mat-select [(ngModel)]="unitOtherName.commandId" placeholder="Command" required [formControl]="formCommandId">
        <mat-option *ngFor="let option of commandOptions" [value]="option.commandId" (click)="onSelectionChange(option)">{{option.name}}</mat-option>
      </mat-select>

      <!-- <app-lookup-select (selectedValueChanged)="entity.commandId = $event.commandId" [selectClass]="'default-width'"
        [itemPK]="'commandId'" [itemId]="entity.commandId"
        [typeName]="'Command'" [keyProperty]="'name'" >
      </app-lookup-select> -->

      <!-- <mat-select placeholder="Command" formControlName="formCommandId" required [(ngModel)]="unitOtherName.commandId" (selectionChange)="onSelectionChange()">
        <mat-option *ngFor="let command of commandOptions" [value]="command.commandId">{{command.name}}</mat-option>
      </mat-select> -->
    </mat-form-field>
  </form>
  
  <div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="submit()">Submit
    </button>
    <button mat-raised-button (click)="cancel()">Cancel</button>
  </div>
  <!-- <div *ngIf="commandAlert()" fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
    <button [disabled]="needFields()"
      color="warn" mat-button (click)="submit()">Acknowledge
    </button>
    <span style="color: red;">This unit is borrowed from Command {{unit.port.command.name}}. Please coordinate your change with them. Please press 'Acknowledge' to process the change.</span>
   </div> -->
</div>
<app-classification-bar class="bottom-modal-classification-bar"></app-classification-bar>