import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { User } from "@models/user.model";
import { Command } from '@models/command.model';
import { Permission } from '@models/permission.model';
import { LookUpService } from '@services/data-services/lookup.service';
import { take } from 'rxjs/operators'
import { AccountRequestService } from '@services/data-services/account-request.service';
import { CommandService } from '@services/data-services/command.service';
import { PermissionService } from "@services/data-services/permission.service";
import { MatSnackBar } from '@angular/material/snack-bar';
import { CdkStepperNext } from '@angular/cdk/stepper';

@Component({
  selector: 'app-request-account-modal',
  templateUrl: './request-account-modal.component.html',
  styleUrls: ['./request-account-modal.component.css']
})
export class RequestAccountModalComponent implements OnInit {
  user: any;
  commandOptions: any;
  permissionOptions: any;
  currentUser: boolean = false;
  message: string;
  success: boolean;
  errorMessages: any;
  headerString: string;

  constructor(
    public dialogRef: MatDialogRef<RequestAccountModalComponent>,
    private lookUpService: LookUpService,
    private AccReqService: AccountRequestService,
    private commandService: CommandService,
    private permissionService: PermissionService,
    public snackBar: MatSnackBar) {

  }

  ngOnInit() {
    this.isLoggedIn()
    this.headerString = this.currentUser ? 'Request Account/Permission Changes' :
      'Request Account'
    Promise.resolve().then(() => {
      this.loadLookups();
    })
  }

  isLoggedIn(){
    if(sessionStorage.getItem('token') !== null){
      this.currentUser = true
      this.user = JSON.parse(sessionStorage.user)
    }else{
      this.currentUser = false
      this.user = new User
      this.user.permission = {}
      this.user.command = {}
    }
  }

  loadLookups() {
    this.commandService.getAll().subscribe(result => {
      this.commandOptions = result;
    });

    this.permissionService.getAll().subscribe(result => {
      this.permissionOptions = result;
    });
  }

  createErrorMessage(data) {
    this.errorMessages = []
    if(data.requestedChanges.user_name === undefined ){
      this.errorMessages.push("Username is Required")
    }

    if(data.requestedChanges.first_name === undefined ){
      this.errorMessages.push("First Name is Required")
    }

    if(data.requestedChanges.last_name === undefined ){
      this.errorMessages.push("Last Name is Required")
    }

    if(data.requestedChanges.email === undefined){
      this.errorMessages.push("Email is Required")
    }

    if(data.requestedChanges.justification === undefined){
      this.errorMessages.push("Justification is Required")
    }

    // check for empty object
    if(Object.keys(data.requestedChanges.permission).length === 0 && data.  requestedChanges.permission.constructor === Object){
      this.errorMessages.push("Permission Level is Required")
    }

    if(Object.keys(data.requestedChanges.command).length === 0 && data. requestedChanges.command.constructor === Object){
      this.errorMessages.push("Command is Required")

    }
  }

  submit(){
    let data = {
      requestedChanges: this.user
    }

    this.createErrorMessage(data)
    if (this.errorMessages.length > 1) {
      return
    }

    // new user will get Read Only Permissions
    // new user properties need to be sent for email
    if(this.currentUser){
      this.AccReqService.requestUpdateAccount(data).subscribe( response => {
        this.message = response.message
        this.success = !this.message.includes("Error")
        setTimeout(() => {
          this.dialogRef.close()
        });
        this.snackBar.open(this.message, 'OK', { duration: 5000, panelClass: this.success ? ['green-snackbar'] : ['red-snackbar']});
      })
    }else{
      this.AccReqService.requestNewAccount(data).subscribe( response => {
        this.message = response.message
        this.success = !this.message.includes("Error")
        setTimeout(() => {
          this.dialogRef.close()
        });
        this.snackBar.open(this.message, 'OK', { duration: 5000, panelClass: this.success ? ['green-snackbar'] : ['red-snackbar']});
      }
      )
    }
  }
  cancel(){
    this.dialogRef.close()
  }
}