<app-classification-bar class="top-modal-classification-bar"></app-classification-bar>
<app-fdtm-modal-header [headerString]="headerString"></app-fdtm-modal-header>
<div mat-dialog-content id="pocForm">

  <div fxLayout="column" fxLayoutAlign="center stretch" class="row">
    <div fxLayout="row">

      <input hidden aria-hidden="" matInput aria-disabled="true" disabled placeholder="Point of Contact Command" [(ngModel)]="poc.commandId">

      <mat-form-field>
        <mat-select placeholder="Command" [(ngModel)]="poc.command.commandId">
           <mat-option *ngFor="let command of commandOptions" [value]="command.commandId">{{command.name}}</mat-option>
        </mat-select>
     </mat-form-field>
    </div>
  </div>
  <div fxLayout="row">
    <mat-form-field class="poc-input">
      <mat-label>Supporting Commands</mat-label>
      <input matInput autocomplete="off"
       placeholder="List of supporting commands if more than one" [(ngModel)]="poc.supportingCommands">
    </mat-form-field>
  </div>
  <div fxLayout="column" fxLayoutAlign="center stretch" class="row">
    <div fxLayout="row">
      <mat-form-field class="poc-input">
        <mat-label>Point of Contact's Name</mat-label>
        <input matInput placeholder="John Doe" autocomplete="off"
               matTooltip="You can list more than one person with semi-colon( ; ) seperation. 'Jon Doe;Jane Doe;Some Person'"
               [(ngModel)]="poc.contactName">
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="column" fxLayoutAlign="center stretch" class="row">
    <div fxLayout="row">
      <mat-form-field class="poc-input">
        <mat-label>Contact's Position</mat-label>
        <input matInput placeholder="Help Desk" autocomplete="off"
         matTooltip="The Job, Role, Office, or Position" [(ngModel)]="poc.contactPosition">
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row">
      <mat-form-field class="poc-input">
        <mat-label>Full Telephone Number</mat-label>
        <input type="tel" matInput placeholder="1 (000) 000-0000" autocomplete="off"
         matTooltip="You can list more than one number with semi-colon( ; ) seperation." [(ngModel)]="poc.phone">
      </mat-form-field>
  </div>

  <div fxLayout="row">
    <mat-form-field class="poc-input">
      <mat-label>Email</mat-label>
      <input type="email"
             matInput autocomplete="off"
             placeholder="Email@navy.mil"
             matTooltip="You can list emails with semi-colon( ; ) seperation. email@navy.mil;email2@navy.mil"
             [(ngModel)]="poc.email">
    </mat-form-field>
  </div>

  <div fxLayout="row">
    <mat-form-field class="poc-input">
      <mat-label>Link to Support Webpage</mat-label>
      <input type="url" matInput autocomplete="off"
       placeholder=" a URL to a web page or form" [(ngModel)]="poc.link">
    </mat-form-field>
  </div>

  <!-- <div fxLayout="row">
    <mat-form-field class="poc-input">
      <input matInput placeholder="logo_location" [(ngModel)]="poc.logo_location">
    </mat-form-field>
  </div> -->
  <div fxLayout="row">
    <mat-form-field class="poc-input">
      <mat-label>Support Hours</mat-label>
      <input matInput autocomplete="off" placeholder="0900 - 1700 EST" matToolTip="Please include time zone" [(ngModel)]="poc.supportHours">
    </mat-form-field>
  </div>

  <div mat-dialog-actions>
    <button color="primary" mat-raised-button (click)="submit()">Submit</button>
    <button mat-raised-button (click)="cancel()">Cancel</button>
    <span class="mat-color-warn">{{requiredMessage}}</span>
  </div>
</div>
<app-classification-bar class="bottom-modal-classification-bar"></app-classification-bar>
