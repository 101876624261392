import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from './api.config';

@Injectable()
export class MessageApiService {
    SERVER_URL;

    constructor(
        private http: HttpClient, apiConfig: APIConfig) {
        this.SERVER_URL = apiConfig.TARGET_URL;
    }

    public send2Cop(ship_location_id: string): Observable<any> {
        let params = new HttpParams().set('ship_location_id', ship_location_id)
        return this.http.get(
            `${this.SERVER_URL}/messages/send2Cop`,
            { params: params }
        );
    }

    public bulkSend2Cop(): Observable<any> {
        return this.http.get(
            `${this.SERVER_URL}/messages/bulkSend2Cop`, {}
        );
    }
}
