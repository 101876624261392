// See https://medium.com/@shelkarvijay94/angular-mat-autocomplete-with-infinite-scroll-1caca9aebccf
// for original source.

import { Directive, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

export interface IAutoCompleteScrollEvent {
  autoComplete: MatAutocomplete;
  scrollEvent: Event;
}

@Directive({
  selector: 'mat-autocomplete[optionsScroll]'
})
export class OptionsScrollDirective implements OnDestroy {
  // Percentage of the scroll panel when more data should be loaded
  @Input() thresholdPercentage = 0.8;

  @Output('optionsScroll') scroll = new EventEmitter<IAutoCompleteScrollEvent>();

  _onDestroy = new Subject();

  constructor(public autoComplete: MatAutocomplete) {
    this.autoComplete.opened.pipe(
      tap(() => {
        /** Note: When autocomplete raises opened, panel is not yet created (by Overlay)
            Note: The panel will be available on next tick
            Note: The panel wil NOT open if there are no options to display
        */
       setTimeout(() => {
          /** Note: remove listener just for safety, in case the close event is skipped. */
          this.removeScrollEventListener();
          this.autoComplete.panel.nativeElement.addEventListener('scroll', this.onScroll.bind(this));
       });
      }),
      takeUntil(this._onDestroy)
    ).subscribe();

    this.autoComplete.closed.pipe(
      tap(() => this.removeScrollEventListener()),
      takeUntil(this._onDestroy)
    ).subscribe();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.removeScrollEventListener();
  }

  private removeScrollEventListener() {
    if (this.autoComplete?.panel?.nativeElement) {
      this.autoComplete.panel.nativeElement.removeEventListener('scroll', this.onScroll);
    }
  }

  onScroll(event: any) {
    if (this.thresholdPercentage === undefined) {
      this.scroll.next({ autoComplete: this.autoComplete, scrollEvent: event });
    } else {
      const threshold = this.thresholdPercentage * 100 * event.target.scrollHeight / 100;
      const current = event.target.scrollTop + event.target.clientHeight;

      if (current > threshold) {
        this.scroll.next({ autoComplete: this.autoComplete, scrollEvent: event });
      }
    }
  }
}
