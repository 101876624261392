import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { APIConfig } from './api.config';

@Injectable()
export class SlideGeneratorApiService {
    SERVER_URL;
    RESOURCE_URI = 'powerpoints';

    constructor(private http: HttpClient, apiConfig: APIConfig) {
        this.SERVER_URL = apiConfig.TARGET_URL;
    }

    public getAllSlideChoices(): Observable<any> {
        return this.http.get(`${this.SERVER_URL}/${this.RESOURCE_URI}`);
    }

    public generateSlides(slideChoices: any): Observable<any> {
        return this.http.post(`${this.SERVER_URL}/${this.RESOURCE_URI}`,
                              slideChoices,
                              { responseType: 'blob', reportProgress: true, observe: 'events' });
    }
}
