import { JsonObject, JsonProperty, Any, PropertyConvertingMode } from 'json2typescript';
import { DateConverter } from '../services/global/date-converter';

@JsonObject('UnitOtherName')
export class UnitOtherName {
  @JsonProperty('unit_other_name_id', String, true)
  unitOtherNameId: string = undefined;
  @JsonProperty('unit_id', String, true)
  unitId: string = undefined;
  @JsonProperty('other_name', String, true)
  otherName: string = undefined;
  @JsonProperty('command_id', String, true)
  commandId: string = undefined;
  @JsonProperty('created_by', String, true)
  createdBy: string = undefined;
  @JsonProperty('create_ts', DateConverter, true)
  createTs: Date = undefined;
  @JsonProperty('updated_by', String, true)
  updatedBy: String = undefined;
  @JsonProperty('update_ts', DateConverter, true)
  updateTs: Date = undefined;
  @JsonProperty('deleted_by', String, true)
  deletedBy: String = undefined;
  @JsonProperty('delete_ts', DateConverter, true)
  deleteTs: Date = undefined;



  public static readonly READABLE_ATTRIBUTE_MAP = {
    unit_other_name_id: 'Other Name ID',
    unit_id: 'Unit',
    unit_other_name: 'Other Name',
    command_id: 'Command',
    created_by: 'Created By',
    create_ts: 'Creation Timestamp',
    updated_by: 'Updated By',
    update_ts: 'Updated Timestamp',
    deleted_by: 'Deleted By',
    delete_ts: 'Deletion Timestamp'
  }
}
