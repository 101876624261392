import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from './api.config';
import {of} from  'rxjs' 
import { BlueUnit } from '@models/blue.model';

@Injectable()
export class BlueApiService {

  SERVER_URL;

  constructor(
    private http: HttpClient, apiConfig: APIConfig) {
    this.SERVER_URL = apiConfig.TARGET_URL;
  }

  // the query string will be partials of the unit name
  public findBlueUnits(value, filters = {}): Observable<any> {
    // so... the ui control wants to send another find when user selects... 
    // could not find how to prevent, just eat it
    if (typeof value != 'string') {
      return of<Response>();
    }
    let httpParams = new HttpParams();

    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        httpParams = httpParams.set(key, filters[key]);
      }
    }
    return this.http.get(
      `${this.SERVER_URL}/blue/findBlueUnits?queryString=${value}&limit=20`, {params: httpParams});
  }  
  
  public getAll(filters = {}): Observable<any> {
    let httpParams = new HttpParams();

    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        httpParams = httpParams.set(key, filters[key]);
      }
    }
    return this.http.get(
      `${this.SERVER_URL}/blue`, {params: httpParams});
  }

}
