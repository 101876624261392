import { Component, OnInit } from '@angular/core';
import { EditModalBaseComponent } from '@fom-module/base-classes/edit-modal-base.component';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { LookUpService } from '@services/data-services/lookup.service';
import { FormBuilder } from '@angular/forms';
import { FleetService } from '@services/data-services/fleet.service';
import { Country } from '@models/country.model';
import { Command } from '@models/command.model';
import { MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { UtilityService } from '@services/utility.service';
import { CommandHierarchyService } from '@services/data-services/command-hierarchy.service';
import { User } from '@models/user.model';
import { CurrentUserService } from '@services/current-user-service';
import { CommandHierarchy } from '@models/command-hierarchy.model';

@Component({
  selector: 'app-edit-fleet-modal',
  templateUrl: './edit-fleet-modal.component.html',
  styleUrls: ['./edit-fleet-modal.component.css']
})
export class EditFleetModalComponent extends EditModalBaseComponent implements OnInit {
  // Dropdown options
  commandOptions: Command[];
  countryOptions: Country[];
  user: User;
  commandHierarchy: CommandHierarchy;

  flag: string = '';

  constructor(public fleetService: FleetService,
    public lookUpService: LookUpService,
    public formBuilder: FormBuilder, public utilities: UtilityService,
    public dialogRef: MatDialogRef<EditFleetModalComponent>,
    private commandHierarchyService: CommandHierarchyService,
    public messageBusService: MessageBusService,
    private currentUserService: CurrentUserService) {
    super(fleetService, dialogRef);
  }

  ngOnInit() {
    this.headerString = this.isNew ? 'Add Fleet' :
    this.isDelete ? 'Delete Fleet' :
    'Edit Fleet'
    this.user = this.currentUserService.getCurrentUser();
    if (this.isNew) {
      this.entity.commandId = this.user.currentJFMCC ? this.user.currentJFMCC.commandId : this.user.commandId;
    }
    this.editForm = this.formBuilder.group({
      commandId: [{ value: this.entity.commandId, disabled: this.isDelete }],
      fleetCode: [{ value: this.entity.fleetCode, disabled: this.isDelete }],
      //remarks: [this.entity.remarks]
    });
    this.flag = this.entity.remarks;
    Promise.resolve().then(() => {
      this.loadLookups();
    });
  }

  loadLookups() {
    this.lookUpService.getLookupByType(Command, true).pipe(take(1)).subscribe(result => {
      this.commandHierarchyService.getAll({ sort: 'version_num', limit: 1 }).pipe(take(1)).subscribe(json => {
        if (json && Array.isArray(json) && json.length) {
          this.commandHierarchy = json[0];

          let jfmccs;
          if (this.user.isSystemAdmin()) {
            jfmccs = this.commandHierarchy.getJFMCCs();
          } else {
            jfmccs = this.commandHierarchy.getJFMCCIds(this.user.command);
          }

          this.commandOptions = result.filter(item => {
            return jfmccs.includes(item.commandId);
          });
        } else {
          this.commandOptions = result;
        }
      });
    });
  }

  onCountryChange(country) {
    this.entity.remarks = this.flag = country.name
  }

}
