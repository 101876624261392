<div class="admin-work-area">
    <div fxLayout fxLayoutAlign="center center" class="simple-grid-title">
      <span class="grid-title">{{initService.getConfig().titlePrefix}} FDT-M Picklist Types</span>
      <!-- Add Unit Floating Action Button -->
      <button mat-mini-fab class="add-button" (click)="add()">
        <img class="light-icon" src="./assets/icons/ic_add_white_24px.svg" matTooltip="Add Owner Type" />
      </button>
    </div>
    <div class="mat-elevation-z8">
      <!-- Paginator -->
      <mat-paginator [pageSizeOptions]="[10, 20, 50, 100, 1000]" [pageSize]="20" [length]="dataSource?.data?.length" showFirstLastButtons></mat-paginator>
      <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Picklist Type Name</th>
          <td mat-cell *matCellDef="let element">{{element.description}}</td>
        </ng-container>
        <ng-container matColumnDef="tableName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Table Name</th>
            <td mat-cell *matCellDef="let element">{{element.tableName}}</td>
          </ng-container>
          <ng-container matColumnDef="primaryKey">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Primary Key Name</th>
            <td mat-cell *matCellDef="let element">{{element.primaryKeyName}}</td>
          </ng-container>

        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef>
            <div>
              Delete
            </div>
          </th>
          <td mat-cell *matCellDef="let row">
            <button title="Delete Owner Type" mat-button class="delete-button" (click)="$event.stopPropagation()" (click)="handleDelete(row)">
              <img src="./assets/icons/ic_delete_gray_24px.svg" />
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr class="hyper" matTooltip="Edit Owner Type" matTooltipPosition="before" mat-row *matRowDef="let row; columns: displayedColumns;" (click)="edit(row)"></tr>
      </table>
    </div>
  </div>
