import { Releasability } from '@models/releasability.model';
import { ReleasabilityApiService } from '../api-services/releasability-api.service';
import { Injectable } from '@angular/core';
import { JsonConverter } from '../global/json-converter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable()
export class ReleasabilityService {
    private jsonConvert: JsonConverter;

    constructor(private apiService: ReleasabilityApiService) {
        this.jsonConvert = new JsonConverter();
    }

    public getAll(): Observable<Releasability[]> {
        return this.apiService.getAllControls()
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, Releasability);
                })
            );
    }

    public getControlByControlId(id): Observable<Releasability> {
        return this.apiService.getControlByControlId(id)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Releasability);
                })
            );
    }

    public update(item: Releasability): Observable<Releasability> {
        return this.apiService.updateControl(this.jsonConvert.serializeObject(item))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Releasability);
                })
            );
    }

    public create(item: Releasability): Observable<Releasability> {
        return this.apiService.createControl(this.jsonConvert.serializeObject(item))
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeObject(data, Releasability);
                })
            );
    }

    public delete(item: Releasability): Observable<Releasability> {
        return this.apiService.deleteControl(this.jsonConvert.serializeObject(item))
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

}
