import { Pipe, PipeTransform } from '@angular/core';
import { UnitOtherName } from '@models/unit-other-name.model';

@Pipe({
  name: 'unitOtherNamePipe'
})
// simple pipe to use in html {{}} evaluations
export class UnitOtherNamePipe implements PipeTransform {

  transform(value: any): any {
    if (!value || !(value instanceof UnitOtherName)) {
      return ''
    }
    return value.otherName;
    //return null;
  }

}
