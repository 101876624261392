import { Component, OnInit } from '@angular/core';
import { Command } from '@models/command.model';
import { Parameter } from '@models/parameter.model';
import { ParameterService } from '@services/data-services/parameter.service';
import { LookUpService } from '@services/data-services/lookup.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { JsonConverter } from '@services/global/json-converter';
import { take } from 'rxjs/operators'
import { UserNotificationService } from '@services/notification.service';

@Component({
  selector: 'app-edit-parameter-modal',
  templateUrl: './edit-parameter-modal.component.html',
  styleUrls: ['./edit-parameter-modal.component.css']
})
export class EditParameterModalComponent implements OnInit {
  private jsonConvert: JsonConverter;
  isNew: boolean
  parameter: Parameter = new Parameter();
  commandOptions: Command[]
  requiredMessage: string = ''
  fileToUpload: any
  headerString: string;

  constructor(public lookUpService: LookUpService, 
    private parameterService: ParameterService,
    private notificationService: UserNotificationService,
    public dialogRef: MatDialogRef<EditParameterModalComponent>) { 
      this.jsonConvert = new JsonConverter();
    }

  ngOnInit() {
    this.headerString = this.isNew ? 'Add Parameter' : `Edit Parameter (${this.parameter.name})`
    // if (this.parameter.datetimeValue) {
    //   this.parameter.datetimeValue = new Date(this.parameter.datetimeValue)
    // } 
    Promise.resolve().then(() => {
      this.lookUpService.getLookupByType(Command, true).pipe(take(1)).subscribe(result => {
        this.commandOptions = [{commandId: null, name: ' -- Select / Clear --', noSelection: true}, ...result];
      });
    })
  }

  submit() {
    let formData = new FormData()
    let data = this.jsonConvert.serializeObject(this.parameter)
    formData.append('json', JSON.stringify(data))
    if (this.fileToUpload) {
      formData.append('file', this.fileToUpload)
    }
    if (this.isNew) {
      this.parameterService.create(formData).pipe(take(1)).subscribe(response =>  {
        this.dialogRef.close(response)
        this.notificationService.showSuccess('Entry accepted.', 3000)
      })
    }
    else {
      this.parameterService.update(this.parameter.parameterId, formData).pipe(take(1)).subscribe(response =>  {
          this.dialogRef.close(response)
          this.notificationService.showSuccess('Entry updated.', 3000)
        })
    }
  }
  cancel() {
    this.dialogRef.close()
  }


  watchFields() {
    if (this.parameter && this.parameter.level == 'System') {
      // make sure undefined, not null to avoid ExpressionChanged Validation .. pia
      this.parameter.commandId = undefined
    }
    if (this.parameter && this.parameter.level == 'Command' && this.parameter.commandId == null) {
      this.requiredMessage = 'Select a Command'
      return true
    }
    if (!this.parameter || !this.parameter.level || !this.parameter.level.length) {
      return true
    }
    return false
  }

  onFileInput(event) {
    console.log(event)
    this.fileToUpload = (event.target.files && event.target.files.length) ? event.target.files[0] : null
    if (this.fileToUpload) {
      this.parameter.blobName = this.fileToUpload.name
    }
    else {
      this.parameter.blobName = 'select file'
    }
  }
}
