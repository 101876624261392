import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConfig } from './api.config';

@Injectable()
export class ShipClassApiService {
    SERVER_URL;

    constructor(
        private http: HttpClient, apiConfig: APIConfig) {
        this.SERVER_URL = apiConfig.TARGET_URL;
    }

    public getShipClass(params: any): Observable<any> {
        return this.http.post(
            `${this.SERVER_URL}/ship-classes/ship-class`, params);
    }

    public getAllShipClasses(): Observable<any> {
        return this.http.get(
            `${this.SERVER_URL}/ship-classes`);
    }

    public getShipClassByShipClassId(shipClassId: string): Observable<any> {
        return this.http.get(
            `${this.SERVER_URL}/ship-classes/${shipClassId}`);
    }

    public updateShipClass(shipClass: any): Observable<any> {
        return this.http.put(
            `${this.SERVER_URL}/ship-classes/${shipClass.ship_class_id}`, shipClass);
    }

    public createShipClass(shipClass: any): Observable<any> {
        return this.http.post(
            `${this.SERVER_URL}/ship-classes`, shipClass);
    }

    public deleteShipClass(shipClass: any): Observable<any> {
        return this.http.delete(
            `${this.SERVER_URL}/ship-classes/${shipClass.ship_class_id}`);
    }
}
