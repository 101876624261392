import { BlueUnit } from '@models/blue.model';
import { BlueApiService } from './../api-services/blue-api.service';
import { Injectable } from '@angular/core';
import { JsonConverter } from '../global/json-converter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable()
export class BlueService {
    private jsonConvert: JsonConverter;

    constructor(private blueApiService:BlueApiService) {
        this.jsonConvert = new JsonConverter();
    }
  
    // partial for drop downs
    public findBlueUnits(value, filters = {}): Observable<BlueUnit[]> {
        return this.blueApiService.findBlueUnits(value, filters)
            .pipe(
                map((data) => {
                    return this.jsonConvert.deserializeArray(data, BlueUnit);
                })
            );
    }

    public getAll(filters = {}): Observable<BlueUnit[]> {
        return this.blueApiService.getAll(filters)
            .pipe(
                map((data) => {
                    // console.log(data);
                    return this.jsonConvert.deserializeArray(data, BlueUnit);
                })
            );
    }

}
