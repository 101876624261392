<div class="container">
  <div class="review-header">
    <h3>Review System Connection</h3>
  </div>

  <div class="attributes">
    <div class="attribute" *ngFor="let attribute of review">
      <div class="message-config" *ngIf="isArray(attribute.value); else field">
        <div *ngFor="let subobject of attribute.value; let i = index">
          <h4 class="message-config-header">Message Config #{{i + 1}}</h4>
          <div class="message-config-attribute" *ngFor="let subattribute of entries(subobject)">
            <b>{{ toHumanReadable(subattribute.key) }}: </b>
            <span class="value">{{subattribute.value}}</span>
          </div>
        </div>
      </div>

      <ng-template #field>
        <div class="connection-attribute">
          <b>{{ toHumanReadable(attribute.key) }}: </b>
          <span class="value">{{ attribute.value }}</span>
        </div>
      </ng-template>

    </div>
  </div>
</div>
