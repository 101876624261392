// shared.service.ts
import { Injectable } from '@angular/core';
import { Category } from '@models/category.model';
import { Country } from '@models/country.model';
import { Fleet } from '@models/fleet.model';
import { Area } from '@models/area.model';
import { Port } from '@models/port.model';
import { Source } from '@models/source.model';
import { ShipType } from '@models/ship-type.model';
import { Activity } from '@models/activity.model';
import { Elnot } from '@models/elnot.model';
import { VesselType } from '@models/vessel-type.model';
import { ShipClass } from '@models/ship-class.model';
import { FreqType } from '@models/freq-type.model';
import { EditSourceModalComponent } from '@fom-module/edit-modals/edit-source-modal/edit-source-modal.component';
import { EditActivityModalComponent } from '@fom-module/edit-modals/edit-activity-modal/edit-activity-modal.component';
import { EditCategoryModalComponent } from '@fom-module/edit-modals/edit-category-modal/edit-category-modal.component';
import { EditCountryModalComponent } from '@fom-module/edit-modals/edit-country-modal/edit-country-modal.component';
import { EditFleetModalComponent } from '@fom-module/edit-modals/edit-fleet-modal/edit-fleet-modal.component';
import { EditAreaModalComponent } from '@fom-module/edit-modals/edit-area-modal/edit-area-modal.component';
import { EditPortModalComponent } from '@fom-module/edit-modals/edit-port-modal/edit-port-modal.component';
import { EditShipTypeModalComponent } from '@fom-module/edit-modals/edit-ship-type-modal/edit-ship-type-modal.component';
import { EditElnotModalComponent } from '@fom-module/edit-modals/edit-elnot-modal/edit-elnot-modal.component';
import { EditShipClassModalComponent } from '@fom-module/edit-modals/edit-ship-class-modal/edit-ship-class-modal.component';
import { EditVesselTypeModalComponent } from '@fom-module/edit-modals/edit-vessel-type-modal/edit-vessel-type-modal.component';
import { EditFreqTypeModalComponent } from '@fom-module/edit-modals/edit-freq-type-modal/edit-freq-type-modal.component';
import { ScanType } from '@models/scan-type.model';
import { ElintFunctionType } from '@models/elint-function-type.model';
import { EditScanTypeModalComponent } from '@fom-module/edit-modals/edit-scan-type-modal/edit-scan-type-modal.component';
import { EditElintFunctionTypeModalComponent } from '@fom-module/edit-modals/edit-elint-function-type-modal/edit-elint-function-type-modal.component';
import { EditConfidenceCodeModalComponent } from '@fom-module/edit-modals/edit-confidence-code-modal/edit-confidence-code-modal.component';
import { ConfidenceCode } from '@models/confidence-code.model';

@Injectable({
  providedIn: 'root'
})
export class LookoupValuesMapService {
  // Array of configuraiton for lookup types to be displayed in the UI
  // type: the Model type for the lookup
  // editor: edit modal component used to created/edit the lookup
  // display: the friendly name for the lookup used in the drop down
  // attributes: array of configuration for the lookup's attributes used for the columns of the data table
  // attributes.*.columnName: friendly name for the column header
  // attributes.*.property: lookup property name that holds the value for the attribute
  // attributes.*.sortable: optional boolean that marks whether sorting should be enabled for the attribute
  // attributes.*.showFlag: optional boolean that marks whether to show a flag image in the data table for this attribute
  // keyProperty: the property used in the Preview and in the drag-n-drop element
  // defaultSort: the property used to sort data table without picklist prefs.
  // flagProperty: optional property that will trigger a flag image lookup for the preview changes dropdown
  // lookupId: property name to retrieve the lookup's ID
  // tableName: used to link up and determine PickistType objects' types
  lookups = [
    {
      type: Activity,
      editor: EditActivityModalComponent,
      display: 'Activity Categories',
      attributes: [
        {
          columnName: 'Activity Description',
          property: 'description',
          sortable: true
        },
        {
          columnName: 'Message Format',
          property: 'code',
          sortable: true
        },
        {
          columnName: 'Slide Format',
          property: 'slideDescription',
          sortable: true
        }
      ],
      keyProperty: 'description',
      defaultSort: 'description',
      lookupId: 'activityId',
      tableName: 'activity_category'
    },
    {
      type: Category,
      editor: EditCategoryModalComponent,
      display: 'Categories',
      attributes: [
        {
          columnName: 'Category',
          property: 'category',
          sortable: true
        }
      ],
      keyProperty: 'category',
      defaultSort: 'category',
      table: 'category',
      lookupId: 'categoryId',
      tableName: 'category'
    },
    {
      type: Country,
      editor: EditCountryModalComponent,
      display: 'Countries',
      attributes: [
        {
          columnName: 'Flag Name',
          property: 'name',
          sortable: true,
          showFlag: true
        },
        {
          columnName: 'Trigraph',
          property: 'triGraph',
          sortable: true
        }
      ],
      keyProperty: 'name',
      defaultSort: 'name',
      flagProperty: 'name',
      lookupId: 'countryId',
      tableName: 'country'
    },
    {
      type: Elnot,
      editor: EditElnotModalComponent,
      display: 'ELNOT',
      attributes: [
        {
          columnName: 'ELNOT',
          property: 'el_not_code',
          sortable: true
        }
      ],
      keyProperty: 'el_not_code',
      defaultSort: 'el_not_code',
      lookupId: 'el_not_id',
      tableName: 'el_not'
    },
    {
      type: Fleet,
      editor: EditFleetModalComponent,
      display: 'Fleets',
      attributes: [
        {
          columnName: 'Fleet Code',
          property: 'fleetCode',
          sortable: true
        },
        {
          columnName: 'Associated Country',
          property: 'remarks',
          sortable: true,
          showFlag: true
        }
      ],
      keyProperty: 'fleetCode',
      defaultSort: 'fleetCode',
      flagProperty: 'remarks',
      lookupId: 'fleetId',
      tableName: 'fleet'
    },
    {
      type: Area,
      editor: EditAreaModalComponent,
      display: 'OpAreas',
      attributes: [
        {
          columnName: 'OpArea Name',
          property: 'opArea',
          sortable: true
        },
        {
          columnName: 'Report Group',
          property: 'reportGroup',
          sortable: true
        },
        {
          columnName: 'Country Code',
          property: 'remarks',
          sortable: true,
          showFlag: true
        }
      ],
      keyProperty: 'opArea',
      defaultSort: 'opArea',
      flagProperty: 'remarks',
      lookupId: 'opAreaId',
      tableName: 'op_area'
    },
    {
      type: Port,
      editor: EditPortModalComponent,
      display: 'Homeports',
      attributes: [
        {
          columnName: 'Command',
          property: 'commandName',
          sortable: true
        },
        {
          columnName: 'Name',
          property: 'name',
          sortable: true
        },
        {
          columnName: 'Code',
          property: 'code',
          sortable: true
        },
        {
          columnName: 'Fleet',
          property: 'fleetName',
          sortable: true
        },
        {
          columnName: 'Decimal Latitude',
          property: 'decLat'
        },
        {
          columnName: 'Decimal Longitude',
          property: 'decLon'
        }
      ],
      keyProperty: 'name',
      defaultSort: 'name',
      lookupId: 'portId',
      tableName: 'port'
    },
    {
      type: Source,
      editor: EditSourceModalComponent,
      display: 'Sources',
      attributes: [
        {
          columnName: 'Source Name',
          property: 'sourceName',
          sortable: true
        },
        {
          columnName: 'OOB Code',
          property: 'nobCode',
          sortable: true
        },
        {
          columnName: 'Message Code',
          property: 'shortCode',
          sortable: true
        }
      ],
      keyProperty: 'sourceName',
      defaultSort: 'sourceName',
      lookupId: 'sourceId',
      tableName: 'source'
    },
    {
      type: ShipType,
      editor: EditShipTypeModalComponent,
      display: 'Ship Types',
      attributes: [
        {
          columnName: 'Rank',
          property: 'legacyRank',
          sortable: true
        },
        {
          columnName: 'Ship Type Name',
          property: 'shipTypeName',
          sortable: true
        },
        {
          columnName: 'Ship Class',
          property: 'shipClassName',
          sortable: true
        },
        {
          columnName: 'Vessel Type',
          property: 'vesselTypeName',
          sortable: true
        },
        {
          columnName: 'Country Code',
          property: 'remarks',
          sortable: true,
          showFlag: true
        }
      ],
      keyProperty: 'shipTypeName',
      defaultSort: 'legacyRank',
      flagProperty: 'remarks',
      lookupId: 'shipTypeId',
      tableName: 'ship_type'
    },
    {
      type: ShipClass,
      editor: EditShipClassModalComponent,
      display: 'Ship Classes',
      attributes: [
        {
          columnName: 'Ship Class',
          property: 'shipClass',
          sortable: true
        },
        {
          columnName: 'Ship Class Descriptions',
          property: 'shipClassDescription',
          sortable: true
        }
      ],
      keyProperty: 'shipClass',
      defaultSort: 'shipClass',
      lookupId: 'shipClassId',
      tableName: 'ship_class'
    },
    {
      type: VesselType,
      editor: EditVesselTypeModalComponent,
      display: 'Vessel Types',
      attributes: [
        {
          columnName: 'Priority',
          property: 'priority',
          sortable: true
        },
        {
          columnName: 'Vessel Type',
          property: 'vesselType',
          sortable: true
        },
        {
          columnName: 'Vessel Type Description',
          property: 'vesselTypeDescription',
          sortable: true
        }
      ],
      keyProperty: 'vesselType',
      defaultSort: 'priority',
      flagProperty: 'country_id',
      lookupId: 'vesselTypeId',
      tableName: 'vessel_type'
    },
    {
      type: FreqType,
      editor: EditFreqTypeModalComponent,
      display: 'Frequency Types',
      attributes: [
        {
          columnName: 'Frequency Type Description',
          property: 'freq_type_description',
          sortable: true
        }
      ],
      keyProperty: 'freqType',
      defaultSort: 'freq_type_description',
      lookupId: 'freqTypeId',
      tableName: 'freq_type'
    },
    {
      type: ScanType,
      editor: EditScanTypeModalComponent,
      display: 'Scan Types',
      attributes: [
        {
          columnName: 'Scan Type Code',
          property: 'scan_type_code',
          sortable: true
        },
        {
          columnName: 'Scan Type Description',
          property: 'scan_type_description',
          sortable: true
        }
      ],
      keyProperty: 'scanType',
      defaultSort: 'scan_type_code',
      lookupId: 'scanTypeId',
      tableName: 'scan_type'
    },
    {
      type: ElintFunctionType,
      editor: EditElintFunctionTypeModalComponent,
      display: 'Function Types',
      attributes: [
        {
          columnName: 'Function Code',
          property: 'elint_function_code',
          sortable: true
        },
        {
          columnName: 'Function Name',
          property: 'elint_function_name',
          sortable: true
        }
      ],
      keyProperty: 'ElintFunctionType',
      defaultSort: 'elint_function_code',
      lookupId: 'elint_function_id',
      tableName: 'elint_function'
    },
    {
      type: ConfidenceCode,
      editor: EditConfidenceCodeModalComponent,
      display: 'Confidence Codes',
      attributes: [
        {
          columnName: 'Confidence Code',
          property: 'confidenceCode',
          sortable: true
        },
        {
          columnName: 'Confidence Code Description',
          property: 'confidenceCodeDescription',
          sortable: true
        }
      ],
      keyProperty: 'confidenceCode',
      defaultSort: 'confidenceCode',
      lookupId: 'confidenceCodeId',
      tableName: 'confidence_code'
    }
  ];

  constructor() {}

  setLookupMap(value: any) {
    this.lookups = value;
  }

  getLookupMap() {
    return this.lookups;
  }
}
