import { Injectable } from '@angular/core';
import { JsonConverter } from '@services/global/json-converter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ParametersTemplate } from '@models/parameters-template.model';
import { ParametersTemplateApiService } from '@services/api-services/parameters-template-api.service';

@Injectable()
export class ParametersTemplateService {
  private jsonConvert: JsonConverter;

  constructor(private parametersTemplateApiService: ParametersTemplateApiService) {
    this.jsonConvert = new JsonConverter();
  }

  public getAll(): Observable<ParametersTemplate[]> {
    return this.parametersTemplateApiService.getAll()
      .pipe(
        map((data) => {
          return this.jsonConvert.deserializeArray(data, ParametersTemplate);
        })
      );
  }

}