import { EditUserModalComponent } from '@edit-modals/edit-user-modal/edit-user-modal.component';
import { UserService } from '@services/data-services/user.service';
import { User } from '@models/user.model';
import { MessageBusService } from '@services/global/message-bus/messaging-bus.service';
import { Router } from '@angular/router';
import { Component, OnInit, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalMessageTriggers } from '@services/global/message-bus/global-message-triggers.enum';
import { GridBaseComponent } from '@fom-module/base-classes/grid-base.component';
import { CurrentUserService } from '@services/current-user-service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, Sort } from '@angular/material/sort';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UtilityService } from '@services/utility.service';
import { CommandHierarchyService } from '@services/data-services/command-hierarchy.service';
import { CommandHierarchy } from '@models/command-hierarchy.model';
import { Zulu1Pipe } from '@core-module/pipes/zulu1.pipe';
import { AppInitService } from '../../../config/init.service';
import { AccountRequestService } from '@services/data-services/account-request.service';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { Subject, Subscription } from 'rxjs';
import { GridGroupingService, Group } from '@services/grid-grouping.service';
import { ReportingService } from '@services/data-services/reporting.service';

export interface ChangePasswordData {
  username: string;
  password: string;
  headerString: string;
}

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css', '../base-classes/grid-base.component.css']
})
export class UserComponent extends GridBaseComponent implements OnInit {

  allColumns = ['edit', 'command.name', 'firstName', 'lastName', 'username', 'permission.permissionDescription',
    'disabled', 'whitelisted', 'email', 'lastActivity', 'reset', 'delete'];
  displayedColumns: any;

  // need to retain the filtering value for the refresh hack
  filterValue = '';
  lastFilter = null;
  // let's filter the data on these columns
  filterColumns = [
    'username', 'lastName', 'firstName'
  ];
  // to debounce the filter input
  filterTextChanged: Subject<string> = new Subject<string>();

  // these objects represent the columns that can be grouped
  groupItems = [
    { display: 'No Grouping', value: 'All', column_name: null },
    { display: 'Command', value: 'command.name', column_name: 'command.name' },
    { display: 'Role', value: 'permission.permissionDescription', column_name: 'permission.permissionDescription' },
    // grrrr.... switch it on the data load
    { display: 'Enabled', value: 'enabled', column_name: 'enabled' }
  ];
  // the current (default) group will be the first one in the list
  public grouping = this.groupItems[0];
  groupByColumns: string[] = [this.grouping.value];
  groupCount = 0;

  users: User[];
  currentUser: User;
  daysUntilInactive: Number;

  public dataSource = new TableVirtualScrollDataSource<any | Group>();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  username: string;
  password: string;

  hierarchy: CommandHierarchy;

  readyForExport: boolean = true;
  exportSubscription: Subscription;

  constructor(public userService: UserService,
    public messageBusService: MessageBusService,
    public dialog: MatDialog,
    public route: Router,
    public snackBar: MatSnackBar,
    private currentUserService: CurrentUserService,
    private accountRequestService: AccountRequestService,
    private commandHierarchyService: CommandHierarchyService,
    public init: AppInitService,
    private zulu1Pipe: Zulu1Pipe,
    private gridGroupingService: GridGroupingService,
    private reportingService: ReportingService) {
    super(messageBusService, userService, route);

    this.RELOAD_MESSAGE = GlobalMessageTriggers.RELOAD_USER_DATA;
    this.EDIT_MODAL_COMPONENT = EditUserModalComponent;

    this.filterTextChanged
      .pipe(debounceTime(1000), // wait 1 sec after the last event before emitting last event
        distinctUntilChanged()) // only emit if value is different from previous value
      .subscribe(model => {
        // pull in the ngModel value to the dataSourceFilter
        this.dataSource.filter = model.trim().toLocaleLowerCase();
      });
  }

  ngOnInit() {
    this.currentUser = this.currentUserService.getCurrentUser();
    this.daysUntilInactive = this.init.getConfig().daysUntilInactive || 30;
    this.displayedColumns = this.allColumns.filter(value => value !== this.grouping.column_name);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = this.dataAccessor;
    this.dataSource.filterPredicate = this.customFilterPredicate.bind(this);

    this.commandHierarchyService.getAll({ sort: 'version_num', limit: 1 }).pipe(take(1)).subscribe(result => {
      if (result.length > 0) {
        this.hierarchy = result[0];
      } else {
        // If no Command Hierarchy is configured, sets hierarchy to a blank tree.
        this.hierarchy = new CommandHierarchy();
        this.hierarchy.treeObject = '[]';
      }

      // Nested to ensure command hierarchy is loaded before attempting to check any constraints for editing
      this.loadEntityData();
    });

    // super.ngOnInit();
  }

  // when the user is typing the filter action will wait a sec before applying
  onFilterChange(event) {
    this.filterTextChanged.next(event);
  }

  dataAccessor = (item, property) => {
    if (property.includes('.')) {
      return property.split('.').reduce((o, p) => o && o[p], item);
    }
    return item ? item[property] : null;
  }

  setDataSource(entities) {
    // this.displayedColumns = this.allColumns.filter(value => value !== this.grouping.column_name);
    entities.forEach(element => {
      element.enabled = !element.disabled;
    });
    //this.entities = entities;
    this.users = entities;

    const sort: Sort = {
      active: 'command.name',
      direction: 'asc'
    };
    this.sortData(sort);
  }

  loadEntityData() {
    this.userService.getAllWithPkis().pipe(take(1)).subscribe(entities => {
      // so... sort in the client (ugh)
      entities.sort((a, b) => {
        if (a.command.name < b.command.name) { return -1; }
        if (a.command.name > b.command.name) { return 1; }
        if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) { return -1; }
        if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) { return 1; }
        if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) { return -1; }
        if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) { return 1; }
        return 0;
      });
      this.setDataSource(entities);
      // let's see if there is a borrower, display that in the grid
    });
  }

  edit(user: any) {
    if (!this.canEdit(user)) {
      return;
    }

    const dialogRef = this.dialog.open(EditUserModalComponent, {
      width: '560px'
    });
    dialogRef.componentInstance.entity = user;
    dialogRef.componentInstance.isNew = false;

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      if (result) {
        this.loadEntityData();
      }
    });
  }

  add() {
    const dialogRef = this.dialog.open(EditUserModalComponent, {
      width: '560px'
    });
    dialogRef.componentInstance.entity = new User();
    dialogRef.componentInstance.isNew = true;

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      // in order to get complete data for the new Unit, go back to the DB
      if (result) {
        this.loadEntityData();
      }
    });
  }

  clearFilter(filter) {
    this.dataSource.filter = '';
  }

  // grouping
  customFilterPredicate(data: any | Group, filter: string): boolean {
    const triggered = filter === this.lastFilter ? true : false;
    if (data instanceof Group) {
      if (triggered && !data.expanded) {
        // If a group row was clicked, and the group was collapsed, show the group header row
        return true;
      } else {
        // Otherwise, show the group only if it contains any items matching the filterValue.
        return this.containsFilterMatch(data.items, this.filterValue);
      }
    } else {
      if (triggered && !data.group.expanded) {
        // If a group row was clicked and group is collapsed, always hide the data rows
        return false;
      } else {
        // Otherwise only show the data row if it matches the filterValue.
        return this.matchesFilter(data, this.filterValue);
      }
    }
  }

  matchesFilter(item: any, filter: string): boolean {
    return this.filterColumns.some(column => {
      return this.dataAccessor(item, column).toLocaleLowerCase().indexOf(filter.toLowerCase()) > -1;
    });
  }

  containsFilterMatch(items: any[], filter: string): boolean {
    return items.some((element) => {
      return this.matchesFilter(element, filter);
    });
  }

  groupingChanged(event, grouping) {
    this.groupByColumns = [grouping.value];
    this.displayedColumns = this.allColumns.filter(function (value, index, arr) {
      return value !== grouping.column_name;
    });

    // when grouping changes, sort by grouping and then isActive
    const sort: Sort = {
      active: 'isActive',
      direction: 'asc'
    };
    this.sortData(sort);
  }

  groupHeaderClick(row) {
    row.expanded = !row.expanded;
    this.lastFilter = performance.now().toString();
    this.dataSource.filter = this.lastFilter;
  }

  isGroup(index, item): boolean {
    return item.isGroup;
  }

  sortData(sort: Sort) {
    // console.log(sort)
    let data;
    if (!sort.active || sort.direction === '') {
      data = this.users;
    } else {
      data = this.users.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        return this.compare(a, b, isAsc, sort.active);
      });
    }
    this.dataSource.data = this.gridGroupingService.buildDataSourceData(data, this.groupByColumns[0]);
  }

  compare(a, b, isAsc, active) {
    if (this.grouping.value) {

      // sort by grouped column first, then the active sort column
      if (this.dataAccessor(a, this.grouping.value) > this.dataAccessor(b, this.grouping.value)) {
        return 1;
      }
      if (this.dataAccessor(a, this.grouping.value) < this.dataAccessor(b, this.grouping.value)) {
        return -1;
      }
    }
    return (this.dataAccessor(a, active) < this.dataAccessor(b, active) ? -1 : 1) * (isAsc ? 1 : -1);
  }

  canResetPassword(user): boolean {
    return this.currentUser.isSystemAdmin() ||
      (this.isAuthorizedByCommand(user) && this.currentUser.permission.permissionLevel >= user.permission.permissionLevel);
  }

  resetPassword(user): void {
    if (!this.canResetPassword(user)) {
      return;
    }

    this.username = user.username;
    const dialogRef = this.dialog.open(ChangePasswordDialog, {
      width: '350px',
      data: { username: this.username, password: this.password, headerString: `Change Password for: ${this.username}` }
    });

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      if (result && result.length) {
        user.password = result;
        this.userService.changePassword(user).pipe(take(1)).subscribe(response => {
          this.snackBar.open('Password reset.', 'OK', { duration: 3000 });
        });

        user.password = undefined; // clear password after reset so that updating after doesn't break.
      }
    });
  }

  canEdit(user): boolean {
    return this.currentUser.isSystemAdmin() ||
      (this.isAuthorizedByCommand(user) && this.currentUser.permission.permissionLevel >= user.permission.permissionLevel);
  }

  canDelete(user): boolean {
    return this.currentUser.isSystemAdmin() ||
      (this.isAuthorizedByCommand(user) && this.currentUser.permission.permissionLevel >= user.permission.permissionLevel);
  }

  handleDelete(user, message) {
    if (!this.canDelete(user)) {
      return;
    }

    super.handleDelete(user, message);
  }

  // let's check the user being deleted and see if there is an account request
  // so that the admin will know in the confirmation box that the account request will also
  // be deleted
  checkBeforeDelete(row) {
    this.accountRequestService.findAccountRequstByUserId(row.userId).pipe(take(1)).subscribe(accountRequest => {
      this.handleDelete(row,
        accountRequest != null ? 'An Account Request exists for this user. It will also be deleted.' : undefined);
    });
  }

  // Returns whether the current user is authorized to interact with the passed in user object based on:
  //   * Both users belong to commands supporting the same JFMCC in the command hierarchy.
  //   * Both users belong to the same command.
  isAuthorizedByCommand(user): boolean {
    const currentUserJFMCCS = this.hierarchy.getJFMCCIds(this.currentUser.command);
    const userJFMCCS = this.hierarchy.getJFMCCIds(user.command);

    // Determines the intersection of JFMCCs between current user and selected user.
    const sharedJFMCCs = currentUserJFMCCS.filter(e => {
      return userJFMCCS.indexOf(e) !== -1;
    });

    return sharedJFMCCs.length > 0 || this.currentUser.commandId === user.commandId;
  }

  formatLastActivity(user: User): String {
    const lastActivity = user.lastLoginActivity();
    if (lastActivity.date) {
      return `${this.zulu1Pipe.transform(lastActivity.date)} (${lastActivity.message})`;
    } else {
      return lastActivity.message;
    }
  }

  exportToExcel(): void {
    this.readyForExport = false;

    this.exportSubscription = this.reportingService.getUserExcel().pipe(take(1)).subscribe(excel => {
      this.exportSubscription.unsubscribe();
      this.readyForExport = true;
      this.download(excel.body, 'FDT-M Users Export.xlsx');

    }, err => {
      this.exportSubscription.unsubscribe();
      this.readyForExport = true;

      // pass error up to global ErrorHandler
      throw err;
    });
  }

  download(report: any, filename: string): void {
    const file = new Blob([report]);

    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(file); // xhr.response is a blob
    a.style.display = 'none';
    a.download = filename;
    document.body.appendChild(a);
    a.click();
  }
  
  hasAnyExpandedGroups(): boolean {
    return this.dataSource.filteredData.filter((e: any, index) => this.isGroup(index, e) && e.expanded).length > 0;
  }

  changeAllGroups(expanded: boolean): void {
    this.dataSource.filteredData.filter((e, index) => this.isGroup(index, e)).forEach((e: any) => e.expanded = expanded);

    // force filter to run again
    this.lastFilter = performance.now().toString();
    this.dataSource.filter = this.lastFilter;
  }
}

@Component({
  selector: 'change-password-dialog',
  templateUrl: 'change-password-dialog.html',
})
export class ChangePasswordDialog implements OnInit {
  editForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ChangePasswordDialog>,
    @Inject(MAT_DIALOG_DATA) public data: ChangePasswordData,
    public utilities: UtilityService,
    public formBuilder: FormBuilder) { }

  ngOnInit() {
    this.editForm = this.formBuilder.group({
      password: [this.data.password, this.utilities.passwordValidator]
    },
      {
        updateOn: 'change'
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
